import { StyleSheet } from 'aphrodite';

import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';
import magnifyingGlassActive from '../../img/icons/magnifying-glass-active.svg';

const { TABLET_MEDIA_QUERY } = WINDOW_DIMENSIONS;

export const styles = StyleSheet.create({
  SetSearchBar_tokenSearch: {
    position: 'relative',
    width: '100%',
    border: '1px solid #E1E1EF',
    backgroundColor: COLORS.lightGray,
    transition: '0.2s',
  },
  SetSearchBar_polygonTokenSearch: {
    position: 'relative',
    width: '100%',
    border: `1px solid ${COLORS.purple}`,
    backgroundColor: COLORS.lightPurple,
    transition: '0.2s',
  },
  SetSearchBar_searchInput: {
    paddingTop: '18px',
    paddingBottom: '15px',
    height: '56px',
    border: 'none',
    background: `url(${magnifyingGlassActive}) no-repeat scroll 10px 10px`,
    backgroundPosition: '15px 20px',
    paddingLeft: '40px',
    fontSize: '16px',
    width: '100%',
  },
  SetSearchBar_searchInput_small: {
    height: '50px',
    fontSize: '14px',
    backgroundPosition: '15px 17px',
  },
  SetSearchBar_dropdownContainer: {
    position: 'absolute',
    zIndex: 1000,
    width: 'calc(100% + 2px)',
    left: '-1px',
    border: `1px solid ${COLORS.gray}`,
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    backgroundColor: COLORS.white,
  },
  SetSearchBar_dropdownOverflow: {
    maxHeight: '500px',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  SetSearchBar_dropdownTable: {
    width: '100%',
    alignItems: 'center',
  },
  SetSearchBar_dropdownElement: {
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: COLORS.white,
    textAlign: 'left',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: `${COLORS.lightGray}`,
    },
  },
  SetSearchBar_dropdownElement_small: {
    height: '75px',
  },
  SetSearchBar_noResultsElement: {
    cursor: 'initial',
    paddingLeft: '20px',
    minWidth: '350px',
  },
  SetSearchBar_setIcon: {
    height: '15px',
    width: '15px',
    margin: '15px',
    borderRadius: '50%',
  },
  SetSearchBar_address: {
    paddingRight: '25px',
    [TABLET_MEDIA_QUERY]: {
      maxWidth: '80vw',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  SetSearchBar_clearSearch: {
    position: 'absolute',
    height: '56px',
    lineHeight: '56px',
    textAlign: 'center',
    width: '50px',
    top: '1px',
    right: '0px',
    paddingRight: '20px',
    cursor: 'pointer',
  },
  SetSearchBar_clearSearch_small: {
    height: '50px',
    lineHeight: '50px',
  },
  SetSearchBar_nameElement: {
    fontWeight: 500,
    color: COLORS.darkBlue,
  },
  SetSearchBar_symbolElement: {
    fontWeight: 400,
  },
  SetSearchBar_addressElement: {
    fontSize: '14px',
  },
  SetSearchBar_topSearchSeparator: {
    textAlign: 'left',
    borderBottom: `1px solid ${COLORS.lightBlue2}`,
    backgroundColor: COLORS.lightGray,
    color: COLORS.darkBlue,
    padding: '8px 15px',
    fontWeight: 500,
  },
  SetSearchBar_searchSeparator: {
    textAlign: 'left',
    borderBottom: `1px solid ${COLORS.lightBlue2}`,
    borderTop: `1px solid ${COLORS.lightBlue2}`,
    backgroundColor: COLORS.lightGray,
    color: COLORS.darkBlue,
    padding: '8px 15px',
    fontWeight: 500,
  },
});
