import { StyleSheet, css } from 'aphrodite';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Container, Grid } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';
import { IWindowDimensions } from '../../typings/index';

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  Integrations_container: {
    padding: '20px',
    webkitAnimation: 'fade-in-up 2s ease-out' /* Safari, Chrome and Opera > 12.1 */,
    mozAnimation: 'fade-in-up 2s ease-out' /* Firefox < 16 */,
    msAnimation: 'fade-in-up 2s ease-out' /* Internet Explorer */,
    oAnimation: 'fade-in-up 2s ease-out' /* Opera < 12.1 */,
    animation: 'fade-in-up 2s ease-out',
  },
  Integrations_title: {
    color: COLORS.darkBlue,
    fontSize: '52px',
    fontWeight: 600,
    lineHeight: '57px',
    marginTop: '40px',
  },
  Integrations_subtitle: {
    position: 'relative',
    marginTop: '23px',
    lineHeight: '42px',
    fontSize: '32px',
    color: COLORS.darkGray,
    marginBottom: '15.3px',
    fontWeight: 300,
  },
  Integrations_providerHeading: {
    color: COLORS.darkBlue,
    fontSize: '32px',
    fontWeight: 500,
    lineHeight: '42px',
    marginTop: '30px',
    [MOBILE_MEDIA_QUERY]: {
      marginTop: '28px',
    },
  },
  Integrations_providerImage: {
    width: '60px',
    height: '60px',
    marginBottom: '25.5px',
  },
  Integrations_providerLabel: {
    color: COLORS.darkBlue,
    fontSize: '22px',
    fontWeight: 500,
    lineHeight: '18px',
  },
  Integrations_providerDescription: {
    color: COLORS.darkGray,
    fontSize: '18px',
    lineHeight: '24px',
    marginTop: '10px',
    paddingRight: '20px',
    [MOBILE_MEDIA_QUERY]: {
      paddingRight: '0',
    },
  },
  Integrations_providerLink: {
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
  },
  Integrations_linkRightArrow: {
    height: '15px',
    marginLeft: '10px',
  },
  Integrations_providerCell: {
    padding: '0px',
  },
  Integrations_providerLastRow: {
    marginBottom: '60px',
  },
});

interface IIntegrationsProps {
  windowDimension: IWindowDimensions;
}

class Integrations extends PureComponent<IIntegrationsProps & WithTranslation> {
  componentDidMount() {
    document.title = 'Integrations | TokenSets';
  }

  render() {
    const { t } = this.props;
    return (
      <Container className={css(styles.Integrations_container)}>
        <Grid stackable>
          <Grid.Row>
            <div className={css(styles.Integrations_title)}>{t('title')}</div>
            <div className={css(styles.Integrations_subtitle)}>{t('description')}</div>
          </Grid.Row>
          <Grid.Row>
            <div className={css(styles.Integrations_providerHeading)}>{t('wallets.title')}</div>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5} className={css(styles.Integrations_providerCell)}>
              <div>
                <img
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/integrations/coinbase-wallet.svg"
                  className={css(styles.Integrations_providerImage)}
                />
                <a href="https://wallet.coinbase.com/" target="_blank">
                  <div className={css(styles.Integrations_providerLabel)}>
                    {t('wallets.coinbase-wallet')}
                  </div>
                </a>
                <div className={css(styles.Integrations_providerDescription)}>
                  {t('wallets.coinbase-wallet-description')}
                </div>
                <a
                  className={css(styles.Integrations_providerLink)}
                  href="https://wallet.coinbase.com/"
                  target="_blank"
                >
                  {t('view')}
                  <img
                    className={css(styles.Integrations_linkRightArrow)}
                    src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/blue-arrow-right.svg"
                    alt="Arrow pointing right"
                  />
                </a>
              </div>
            </Grid.Column>
            <Grid.Column width={5} className={css(styles.Integrations_providerCell)}>
              <img
                src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/integrations/argent.svg"
                className={css(styles.Integrations_providerImage)}
              />
              <a href="https://www.argent.xyz/" target="_blank">
                <div className={css(styles.Integrations_providerLabel)}>{t('wallets.argent')}</div>
              </a>
              <div className={css(styles.Integrations_providerDescription)}>
                {t('wallets.argent-description')}
              </div>
              <a
                className={css(styles.Integrations_providerLink)}
                href="https://www.argent.xyz/"
                target="_blank"
              >
                {t('view')}
                <img
                  className={css(styles.Integrations_linkRightArrow)}
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/blue-arrow-right.svg"
                  alt="Arrow pointing right"
                />
              </a>
            </Grid.Column>
            <Grid.Column width={5} className={css(styles.Integrations_providerCell)}>
              <div>
                <img
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/integrations/trust-wallet.svg"
                  className={css(styles.Integrations_providerImage)}
                />
                <a
                  href="https://link.trustwallet.com/open_url?coin_id=60&url=https://www.tokensets.com"
                  target="_blank"
                >
                  <div className={css(styles.Integrations_providerLabel)}>
                    {t('wallets.trust-wallet')}
                  </div>
                </a>
                <div className={css(styles.Integrations_providerDescription)}>
                  {t('wallets.trust-wallet-description')}
                </div>
                <a
                  className={css(styles.Integrations_providerLink)}
                  href="https://link.trustwallet.com/open_url?coin_id=60&url=https://www.tokensets.com"
                  target="_blank"
                >
                  {t('view')}
                  <img
                    className={css(styles.Integrations_linkRightArrow)}
                    src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/blue-arrow-right.svg"
                    alt="Arrow pointing right"
                  />
                </a>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5} className={css(styles.Integrations_providerCell)}>
              <div>
                <img
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/integrations/fortmatic.svg"
                  className={css(styles.Integrations_providerImage)}
                />
                <a href="https://fortmatic.com/" target="_blank">
                  <div className={css(styles.Integrations_providerLabel)}>
                    {t('wallets.fortmatic')}
                  </div>
                </a>
                <div className={css(styles.Integrations_providerDescription)}>
                  {t('wallets.fortmatic-description')}
                </div>
                <a
                  className={css(styles.Integrations_providerLink)}
                  href="https://fortmatic.com/"
                  target="_blank"
                >
                  {t('view')}
                  <img
                    className={css(styles.Integrations_linkRightArrow)}
                    src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/blue-arrow-right.svg"
                    alt="Arrow pointing right"
                  />
                </a>
              </div>
            </Grid.Column>
            <Grid.Column width={5} className={css(styles.Integrations_providerCell)}>
              <div>
                <img
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/integrations/imtoken.svg"
                  className={css(styles.Integrations_providerImage)}
                />
                <a href="https://token.im/" target="_blank">
                  <div className={css(styles.Integrations_providerLabel)}>
                    {t('wallets.imtoken')}
                  </div>
                </a>
                <div className={css(styles.Integrations_providerDescription)}>
                  {t('wallets.imtoken-description')}
                </div>
                <a
                  className={css(styles.Integrations_providerLink)}
                  href="https://token.im/"
                  target="_blank"
                >
                  {t('view')}
                  <img
                    className={css(styles.Integrations_linkRightArrow)}
                    src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/blue-arrow-right.svg"
                    alt="Arrow pointing right"
                  />
                </a>
              </div>
            </Grid.Column>
            <Grid.Column width={5} className={css(styles.Integrations_providerCell)}>
              <div>
                <img
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/integrations/multis.svg"
                  className={css(styles.Integrations_providerImage)}
                />
                <a href="https://multis.co/" target="_blank">
                  <div className={css(styles.Integrations_providerLabel)}>
                    {t('open-finance-portals.multis')}
                  </div>
                </a>
                <div className={css(styles.Integrations_providerDescription)}>
                  {t('open-finance-portals.multis-description')}
                </div>
                <a
                  className={css(styles.Integrations_providerLink)}
                  href="https://multis.co/"
                  target="_blank"
                >
                  {t('view')}
                  <img
                    className={css(styles.Integrations_linkRightArrow)}
                    src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/blue-arrow-right.svg"
                    alt="Arrow pointing right"
                  />
                </a>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <div className={css(styles.Integrations_providerHeading)}>
              {t('open-finance-portals.title')}
            </div>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5} className={css(styles.Integrations_providerCell)}>
              <div>
                <img
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/integrations/zerion.svg"
                  className={css(styles.Integrations_providerImage)}
                />
                <a href="https://zerion.io/" target="_blank">
                  <div className={css(styles.Integrations_providerLabel)}>
                    {t('open-finance-portals.zerion')}
                  </div>
                </a>
                <div className={css(styles.Integrations_providerDescription)}>
                  {t('open-finance-portals.zerion-description')}
                </div>
                <a
                  className={css(styles.Integrations_providerLink)}
                  href="https://zerion.io/"
                  target="_blank"
                >
                  {t('view')}
                  <img
                    className={css(styles.Integrations_linkRightArrow)}
                    src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/blue-arrow-right.svg"
                    alt="Arrow pointing right"
                  />
                </a>
              </div>
            </Grid.Column>
            <Grid.Column width={5} className={css(styles.Integrations_providerCell)}>
              <div>
                <img
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/integrations/defiprime.svg"
                  className={css(styles.Integrations_providerImage)}
                />
                <a href="https://portfolio.defiprime.com/" target="_blank">
                  <div className={css(styles.Integrations_providerLabel)}>
                    {t('open-finance-portals.defi-prime')}
                  </div>
                </a>
                <div className={css(styles.Integrations_providerDescription)}>
                  {t('open-finance-portals.defi-prime-description')}
                </div>
                <a
                  className={css(styles.Integrations_providerLink)}
                  href="https://portfolio.defiprime.com/"
                  target="_blank"
                >
                  {t('view')}
                  <img
                    className={css(styles.Integrations_linkRightArrow)}
                    src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/blue-arrow-right.svg"
                    alt="Arrow pointing right"
                  />
                </a>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <div className={css(styles.Integrations_providerHeading)}>{t('liquidity.title')}</div>
          </Grid.Row>
          <Grid.Row className={css(styles.Integrations_providerLastRow)}>
            <Grid.Column width={5} className={css(styles.Integrations_providerCell)}>
              <img
                src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/integrations/kyber.svg"
                className={css(styles.Integrations_providerImage)}
              />
              <a href="https://kyber.network/" target="_blank">
                <div className={css(styles.Integrations_providerLabel)}>{t('liquidity.kyber')}</div>
              </a>
              <div className={css(styles.Integrations_providerDescription)}>
                {t('liquidity.kyber-description')}
              </div>
              <a
                className={css(styles.Integrations_providerLink)}
                href="https://kyber.network/"
                target="_blank"
              >
                {t('view')}
                <img
                  className={css(styles.Integrations_linkRightArrow)}
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/blue-arrow-right.svg"
                  alt="Arrow pointing right"
                />
              </a>
            </Grid.Column>
            <Grid.Column width={5} className={css(styles.Integrations_providerCell)}>
              <img
                src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/integrations/0x.svg"
                className={css(styles.Integrations_providerImage)}
              />
              <a href="https://0x.org/" target="_blank">
                <div className={css(styles.Integrations_providerLabel)}>{t('liquidity.0x')}</div>
              </a>
              <div className={css(styles.Integrations_providerDescription)}>
                {t('liquidity.0x-description')}
              </div>
              <a
                className={css(styles.Integrations_providerLink)}
                href="https://0x.org/"
                target="_blank"
              >
                {t('view')}
                <img
                  className={css(styles.Integrations_linkRightArrow)}
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/blue-arrow-right.svg"
                  alt="Arrow pointing right"
                />
              </a>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <div className={css(styles.Integrations_providerHeading)}>
              {t('prices-and-analytics.title')}
            </div>
          </Grid.Row>
          <Grid.Row className={css(styles.Integrations_providerLastRow)}>
            <Grid.Column width={5} className={css(styles.Integrations_providerCell)}>
              <img
                src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/integrations/coingecko.png"
                className={css(styles.Integrations_providerImage)}
              />
              <a href="https://www.coingecko.com/en/exchanges/token_sets" target="_blank">
                <div className={css(styles.Integrations_providerLabel)}>
                  {t('prices-and-analytics.coin-gecko')}
                </div>
              </a>
              <div className={css(styles.Integrations_providerDescription)}>
                {t('prices-and-analytics.coin-gecko-description')}
              </div>
              <a
                className={css(styles.Integrations_providerLink)}
                href="https://www.coingecko.com/en/exchanges/token_sets"
                target="_blank"
              >
                {t('view')}
                <img
                  className={css(styles.Integrations_linkRightArrow)}
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/blue-arrow-right.svg"
                  alt="Arrow pointing right"
                />
              </a>
            </Grid.Column>
            <Grid.Column width={5} className={css(styles.Integrations_providerCell)}>
              <img
                src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/integrations/etherscan.svg"
                className={css(styles.Integrations_providerImage)}
              />
              <a href="https://etherscan.io/accounts/label/set-protocol" target="_blank">
                <div className={css(styles.Integrations_providerLabel)}>
                  {t('prices-and-analytics.etherscan')}
                </div>
              </a>
              <div className={css(styles.Integrations_providerDescription)}>
                {t('prices-and-analytics.etherscan-description')}
              </div>
              <a
                className={css(styles.Integrations_providerLink)}
                href="https://etherscan.io/accounts/label/set-protocol"
                target="_blank"
              >
                {t('view')}
                <img
                  className={css(styles.Integrations_linkRightArrow)}
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/blue-arrow-right.svg"
                  alt="Arrow pointing right"
                />
              </a>
            </Grid.Column>
          </Grid.Row>
          {'>'}
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state: any) => ({
  windowDimension: state.windowDimension,
});

const translated = withTranslation('integrations')(Integrations);

export default connect(mapStateToProps)(translated);
