import { connect } from 'react-redux';

import SetSearchBar from './SetSearchBar';
import {
  accountSelector,
  isFetchingManagerTokensSelector,
  managerTokensSelector,
  setDetailsSelector,
} from '../../selectors/baseSelectors';
import { customV2SetPathPrefixSelector, isPolygonSelector } from '../../selectors/web3Selectors';
import { tokenSetsTokenListByAddressSelector } from '../../selectors/tokenListsSelectors';
import { fetchManagerTokens } from '../../actions/tokenListsActions';

const mapStateToProps = (state: any) => {
  const setDetails = setDetailsSelector(state);
  const account = accountSelector(state);
  const isPolygon = isPolygonSelector(state);
  const tokenSetsListMapping = tokenSetsTokenListByAddressSelector(state);
  const managerTokens = managerTokensSelector(state);
  const isFetchingManagerTokens = isFetchingManagerTokensSelector(state);
  const customV2SetPathPrefix = customV2SetPathPrefixSelector(state);

  return {
    customV2SetPathPrefix,
    managerTokens,
    isFetchingManagerTokens,
    isPolygon,
    account,
    setDetails,
    tokenSetsListMapping,
  };
};

const mapDispatchToProps = {
  fetchManagerTokens,
};

export default connect(mapStateToProps, mapDispatchToProps)(SetSearchBar);
