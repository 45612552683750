import React from 'react';
import { Header } from 'semantic-ui-react';
import { css, StyleSheet } from 'aphrodite';

import TermsOfServiceText from './TermsOfServiceText';
import ContentBox from '../../components/ContentBox';

const styles = StyleSheet.create({
  heroHeader: {
    maxWidth: '950px',
    lineHeight: '42px',
  },
  marginBottomSmall: {
    marginBottom: '2rem',
  },
});

const TermsOfService = () => (
  <ContentBox isBordered={false} isFullWidth={true} isInnerContainerWide={true} textAlign="left">
    <Header as="h1" textAlign="center" className={css(styles.heroHeader, styles.marginBottomSmall)}>
      Terms of Service
    </Header>
    <TermsOfServiceText />
  </ContentBox>
);

export default TermsOfService;
