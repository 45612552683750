import keyMirror from 'keymirror';

const DATA_SELECTS = keyMirror({
  // Token Table
  TOKEN_TABLE_BODY: null,
  TOKEN_TABLE_TABLET_PRICE_PERCENTAGE_TOGGLE: null,
  TOKEN_TABLE_TABLET_SHOW_PERCENTAGE_BTN: null,
  TOKEN_TABLE_TABLET_SHOW_PRICE_BTN: null,
  TOKEN_TABLE_HEADER_ROW: null,
  TOKEN_TABLE_INNER_BODY: null,
  TOKEN_TABLE_ZERO_STATE: null,
  // Token Table Row
  TOKEN_TABLE_ROW_BODY: null,
  TOKEN_TABLE_ROW_ICON: null,
  TOKEN_TABLE_ROW_NAME: null,
  TOKEN_TABLE_ROW_TABLET_DATA: null,
  TOKEN_TABLE_ROW_PERCENT_ALLOCATION: null,
  TOKEN_TABLE_ROW_PRICE_USD: null,
  TOKEN_TABLE_ROW_QUANTITY: null,
  TOKEN_TABLE_ROW_TOTAL_PRICE: null,
  TOKEN_TABLE_ROW_PERCENT_CHANGE: null,
  TOKEN_TABLE_ROW_ALLOCATION_BAR: null,
});

export default DATA_SELECTS;
