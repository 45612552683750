import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Icon, Popup } from 'semantic-ui-react';

import { Text } from '../../components/index';
import { COLORS } from '../../constants/index';
import { IIconInfo } from '../../typings/index';

const styles = StyleSheet.create({
  InfoIcon_iconContainer: {
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '50%',
    position: 'relative',
    padding: '9px',
    height: '15px',
    width: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '2px',
    transition: '0.2s',
    ':hover': {
      border: `1px solid ${COLORS.darkBlue}`,
    },
  },
  InfoIcon_iconText: {
    color: COLORS.darkBlue,
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '0',
    userSelect: 'none',
    position: 'relative',
    top: '1px',
  },
  InfoIcon_header: {
    display: 'inline-block',
    marginBottom: '5px',
  },
  InfoIcon_description: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
  },
  InfoIcon_popUp: {
    boxShadow: `0 6px 12px 0 ${COLORS.lightBlueAlpha25}`,
    zIndex: 980,
  },
  InfoIcon_innerContainer: {
    maxWidth: '240px',
  },
  InfoIcon_topZIndex: {
    zIndex: 100000,
  },
  InfoIcon_link: {
    color: COLORS.blue,
    display: 'inline-block',
    marginTop: '10px',
  },
});

interface IInfoIconProps {
  className?: string;
  data: IIconInfo;
  loggingInfo: string;
  hasHighZIndex: boolean;
  pointerPosition?: any;
  hoverable?: boolean;
}

class InfoIcon extends PureComponent<IInfoIconProps> {
  state = {
    timeout: 0,
  };

  static get defaultProps() {
    return {
      data: {
        header: '',
        description: '',
        url: '',
      },
      hasHighZIndex: false,
      pointerPosition: 'top center',
      loggingInfo: '',
      hoverable: true,
    };
  }

  render() {
    const { className = '', data, hasHighZIndex, pointerPosition, hoverable } = this.props;
    const highZIndexStyle = hasHighZIndex ? styles.InfoIcon_topZIndex : undefined;
    const position = pointerPosition;

    return (
      <Popup
        hoverable={hoverable}
        hideOnScroll
        position={position}
        className={css(styles.InfoIcon_popUp, highZIndexStyle)}
        on="hover"
        trigger={
          <div className={css(styles.InfoIcon_iconContainer).concat(` ${className}`)}>
            <span className={css(styles.InfoIcon_iconText)}>i</span>
          </div>
        }
      >
        <div className={css(styles.InfoIcon_innerContainer)}>
          <Text color="darkBlue" fontWeight={500} className={css(styles.InfoIcon_header)}>
            {data.header}
          </Text>
          <p className={css(styles.InfoIcon_description)}>{data.description}</p>
          {data.url ? (
            <a href={data.url} className={css(styles.InfoIcon_link)} target="_blank" rel="noopener">
              Link to more information <Icon name="external square" />
            </a>
          ) : null}
        </div>
      </Popup>
    );
  }
}

export default InfoIcon;
