import { css, StyleSheet } from 'aphrodite';
import BigNumber from 'bignumber.js';
import { round } from 'lodash';
import React, { PureComponent } from 'react';
import FontAwesome from 'react-fontawesome';
import { Table } from 'semantic-ui-react';

import { Text } from '../../components/index';
import { COLORS, COMPONENT_TOKEN_COLORS, DATA_SELECTS } from '../../constants/index';
import { IToken } from '../../typings/index';
import { validateCreateSetTokensQuantity } from '../../utils/index';

const {
  TOKEN_TABLE_ROW_BODY,
  TOKEN_TABLE_ROW_ICON,
  TOKEN_TABLE_ROW_NAME,
  TOKEN_TABLE_ROW_TABLET_DATA,
  TOKEN_TABLE_ROW_PERCENT_ALLOCATION,
  TOKEN_TABLE_ROW_PRICE_USD,
  TOKEN_TABLE_ROW_QUANTITY,
  TOKEN_TABLE_ROW_TOTAL_PRICE,
  TOKEN_TABLE_ROW_PERCENT_CHANGE,
  TOKEN_TABLE_ROW_ALLOCATION_BAR,
} = DATA_SELECTS;

const styles = StyleSheet.create({
  allocationBar: {
    height: '5px',
    borderRadius: '4px',
    position: 'absolute',
  },
  priceChangeArrow: {
    marginRight: '5px',
  },
  tableCell: {
    position: 'relative',
  },
});

export interface ITokenTableRowProps {
  readonly isMobile?: boolean;
  readonly isTablet?: boolean;
  readonly showPrice?: boolean;
  readonly coinIcon?: string;
  readonly token: IToken;
}

class TokenTableRow extends PureComponent<ITokenTableRowProps> {
  static get defaultProps() {
    return {
      coinIcon: '',
      isMobile: false,
      isTablet: false,
      showPrice: false,
      token: {
        id: '',
        symbol: '',
        name: '',
        price_usd: '0',
        percent_of_set: '0',
        quantity: '0',
        daily_percent_change: '0',
      },
    };
  }

  render() {
    const { token, coinIcon, isMobile, isTablet, showPrice } = this.props;
    const tokenInfo = token.info ? token.info : token;
    const {
      id = '',
      symbol = '',
      name = '',
      price_usd = '0',
      percent_of_set = '0',
      quantity = '0',
      daily_percent_change = '0',
    } = tokenInfo;
    const priceUSDNumber = new BigNumber(price_usd).toNumber();
    const priceUSD = new BigNumber(round(priceUSDNumber, 2)).toFormat(2);
    const isPriceUp = daily_percent_change.indexOf('-') === -1;
    const percentChangeColor = isPriceUp ? 'green' : 'red';
    const arrowDirection = isPriceUp ? 'up' : 'down';

    return (
      <Table.Row
        key={symbol}
        className={!validateCreateSetTokensQuantity([token]) ? 'error' : ''}
        style={{ height: isMobile ? '80px' : '70px' }}
        data-select={TOKEN_TABLE_ROW_BODY}
      >
        <Table.Cell className={css(styles.tableCell)} textAlign="left" collapsing>
          <img src={coinIcon} alt={symbol} data-select={TOKEN_TABLE_ROW_ICON} />
        </Table.Cell>
        <Table.Cell
          className={css(styles.tableCell)}
          textAlign="left"
          width={isMobile ? '10' : '4'}
        >
          <Text color="darkBlue" fontWeight={600} data-select={TOKEN_TABLE_ROW_NAME}>
            {name}
          </Text>
        </Table.Cell>
        {isTablet && (
          <Table.Cell className={css(styles.tableCell)} textAlign={isTablet ? 'right' : 'left'}>
            <Text
              color={isTablet ? 'darkGray' : 'darkBlue'}
              data-select={TOKEN_TABLE_ROW_TABLET_DATA}
            >
              {showPrice ? `$${priceUSD.toString()}` : `${percent_of_set}%`}
            </Text>
          </Table.Cell>
        )}
        {!isTablet && (
          <Table.Cell className={css(styles.tableCell)}>
            <Text color="darkBlue" data-select={TOKEN_TABLE_ROW_PERCENT_ALLOCATION}>
              {percent_of_set}%
            </Text>
          </Table.Cell>
        )}
        {!isTablet && (
          <Table.Cell className={css(styles.tableCell)}>
            <Text color="darkBlue" data-select={TOKEN_TABLE_ROW_PRICE_USD}>
              ${priceUSD.toString()}
            </Text>
          </Table.Cell>
        )}
        {!isTablet && (
          <Table.Cell className={css(styles.tableCell)}>
            <Text color="darkBlue" data-select={TOKEN_TABLE_ROW_QUANTITY}>
              {new BigNumber(quantity).toFormat(3).toString()}
            </Text>
          </Table.Cell>
        )}
        {!isTablet && (
          <Table.Cell className={css(styles.tableCell)}>
            <Text color="darkBlue" data-select={TOKEN_TABLE_ROW_TOTAL_PRICE}>
              $9
              {new BigNumber(priceUSDNumber.toFixed(6)).times(quantity).toFormat(2).toString()}
            </Text>
          </Table.Cell>
        )}
        {!isTablet && (
          <Table.Cell className={css(styles.tableCell)}>
            <FontAwesome
              name={`arrow-${arrowDirection}`}
              className={css(styles.priceChangeArrow)}
              style={{ color: COLORS[percentChangeColor] }}
            />
            <Text
              color={percentChangeColor}
              fontWeight={600}
              data-select={TOKEN_TABLE_ROW_PERCENT_CHANGE}
            >
              {daily_percent_change}
            </Text>
          </Table.Cell>
        )}
        <Table.Cell>
          <div
            data-select={TOKEN_TABLE_ROW_ALLOCATION_BAR}
            className={css(styles.allocationBar)}
            style={{
              backgroundColor: COMPONENT_TOKEN_COLORS[id],
              width: `${percent_of_set}%`,
              marginTop: '27px',
              left: isMobile ? '0.6rem' : '1rem',
            }}
          />
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default TokenTableRow;
