import {
  APP_LOADING,
  UPDATE_LOGIN_STATUS,
  LOG_OUT,
  OPEN_ONBOARDING_MODAL,
  CLOSE_ONBOARDING_MODAL,
  OPEN_LOGIN_MODAL,
  CLOSE_LOGIN_MODAL,
  UPDATE_ACCOUNT,
  SELECT_PROVIDER_TYPE,
  SET_CURRENT_NETWORK_ID,
  SET_EMAIL_CONNECTED,
  SET_CURRENT_EMAIL,
  SET_CURRENT_EMAIL_FETCHING,
  SET_IS_SOCIAL_TRADER,
  SET_AFTER_LOGIN_ACTION,
  SET_USERNAME,
  SET_WEB3_INSTANCE,
  SET_TERMS_OF_SERVICE_CONFIRMED,
  SET_LEDGER_PATH,
  CLEAR_AFTER_LOGIN_ACTION,
  SET_CURRENT_CHAIN,
  OPEN_CHAIN_SWITCH_MODAL,
  CLOSE_CHAIN_SWITCH_MODAL,
} from '../actions/web3Actions';
import Web3 from 'web3';

export interface IWeb3State {
  account: string;
  email: string;
  username: string;
  isEmailFetching: false;
  isLoggedIn: boolean;
  isSocialTrader: boolean;
  isOnboardingModalOpen: boolean;
  isLoginModalOpen: boolean;
  hasFetchedAccounts: boolean;
  hasSeenOnboardingModal: boolean;
  emailConnected: boolean;
  isOptedOutOfEmail: boolean;
  isTermsOfServiceConfirmed: boolean;
  providerType: string;
  afterLoginAction: string;
  networkId: string;
  web3Instance: Web3;
  ledgerPath: string;
  currentChain: string;
  isChainSwitchModalOpen: boolean;
  appLoading: boolean;
}

const initialState: IWeb3State = {
  account: '',
  email: '',
  username: '',
  ledgerPath: '',
  isEmailFetching: false,
  isLoggedIn: false,
  isSocialTrader: false,
  isOnboardingModalOpen: false,
  isLoginModalOpen: false,
  isChainSwitchModalOpen: false,
  hasFetchedAccounts: false,
  hasSeenOnboardingModal: false,
  emailConnected: false,
  isOptedOutOfEmail: false,
  isTermsOfServiceConfirmed: false,
  providerType: undefined,
  web3Instance: undefined,
  afterLoginAction: undefined,
  networkId: undefined,
  currentChain: undefined,
  appLoading: false,
};

const web3Reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_LOGIN_STATUS:
      if (!action.payload) {
        return {
          ...state,
          isLoggedIn: false,
          account: '',
          hasFetchedAccounts: false,
          providerType: undefined,
          web3Instance: undefined,
        };
      }
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case UPDATE_ACCOUNT:
      return {
        ...state,
        account: action.payload,
        hasFetchedAccounts: true,
      };
    case SET_TERMS_OF_SERVICE_CONFIRMED:
      return {
        ...state,
        isTermsOfServiceConfirmed: action.payload,
      };
    case SELECT_PROVIDER_TYPE:
      return {
        ...state,
        providerType: action.payload,
      };
    case SET_LEDGER_PATH:
      return {
        ...state,
        ledgerPath: action.payload,
      };
    case SET_CURRENT_NETWORK_ID:
      return {
        ...state,
        networkId: action.payload,
      };
    case LOG_OUT:
      return {
        ...initialState,
        currentChain: state.currentChain,
      };
    case OPEN_ONBOARDING_MODAL:
      return {
        ...state,
        isOnboardingModalOpen: true,
        hasSeenOnboardingModal: true,
      };
    case CLOSE_ONBOARDING_MODAL:
      return {
        ...state,
        isOnboardingModalOpen: false,
      };
    case OPEN_LOGIN_MODAL:
      return {
        ...state,
        isLoginModalOpen: true,
        afterLoginAction: action.payload ? action.payload : undefined,
      };
    case CLOSE_LOGIN_MODAL:
      return {
        ...state,
        isLoginModalOpen: false,
      };
    case OPEN_CHAIN_SWITCH_MODAL:
      return {
        ...state,
        isChainSwitchModalOpen: true,
      };
    case CLOSE_CHAIN_SWITCH_MODAL:
      return {
        ...state,
        isChainSwitchModalOpen: false,
      };
    case CLEAR_AFTER_LOGIN_ACTION:
      return {
        ...state,
        afterLoginAction: undefined,
      };
    case SET_EMAIL_CONNECTED:
      return {
        ...state,
        emailConnected: action.payload,
      };
    case SET_CURRENT_EMAIL_FETCHING:
      return {
        ...state,
        isEmailFetching: true,
      };
    case SET_CURRENT_EMAIL:
      return {
        ...state,
        email: action.payload,
        isEmailFetching: false,
      };
    case SET_AFTER_LOGIN_ACTION:
      return {
        ...state,
        afterLoginAction: action.payload,
      };
    case SET_USERNAME:
      return {
        ...state,
        username: action.payload,
      };
    case SET_WEB3_INSTANCE:
      return {
        ...state,
        web3Instance: action.payload,
      };
    case SET_IS_SOCIAL_TRADER:
      return {
        ...state,
        isSocialTrader: action.payload,
      };
    case SET_CURRENT_CHAIN:
      return {
        ...state,
        currentChain: action.payload,
      };
    case APP_LOADING:
      return {
        ...state,
        appLoading: true,
      };
    default:
      return state;
  }
};

export default web3Reducer;
