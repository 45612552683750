import Web3 from 'web3';
import {
  IBalances,
  IDebtComponents,
  IEnabledMapping,
  IExploreRebalancingSet,
  ISetConfirmedWarningsList,
  IListToken,
  IRebalancingSet,
  ISetDetails,
  ISetModule,
  ITokenBalances,
  IV2SetModule,
  IWindowDimensions,
  TokenApprovalStatus,
  CustomSetDetailsResponse,
  ISetDetailsHydrated,
  GraphSetToken,
  IFeeResponse,
  IPerpPositionUnitInfo,
  IPerpAccountInfo,
} from '../typings/index';
import { IFilters } from '../reducers/exploreReducer';
import { TransactionStep } from '../containers/TransactionWatcher/enums';
import { VAssetDisplayInfo } from 'set.js/dist/types/src/types';

// approvalsReducer.ts
export const allApprovalsSelector = (state: any): { [key: string]: TokenApprovalStatus } =>
  state.approvals;

// balanceReducer.ts
export const accountBalanceSelector = (state: any): string => state.balance.accountBalance;
export const setBalancesSelector = (state: any): ITokenBalances => state.balance.setBalances;
export const erc20BalancesSelector = (state: any): ITokenBalances => state.balance.erc20Balances;

export const isFetchingApiBalancesSelector = (state: any): boolean =>
  state.balance.isFetchingBalance;
export const balancesSelector = (state: any): IBalances => state.balance.apiBalances;

// deployedSetsReducer.ts
export const isFetchingDeployedSetAddressesSelector = (state: any): boolean =>
  state.deployedSets.isFetching;
export const deployedSetAddressesSelector = (state: any): string[] =>
  state.deployedSets.deployedSetAddresses;
export const isFetchingDeployedSetDetailsSelector = (state: any): boolean =>
  state.deployedSets.isFetchingDeployedSetDetails;
export const deployedSetDetailsHydratedSelector = (
  state: any,
): { [address: string]: ISetDetailsHydrated } => state.deployedSets.deployedSetDetails;

// exploreReducer.ts
export const filtersSelector = (state: any): IFilters => state.explore.filters;

// fundFeeControlsReducer.ts
export const isAccruingStreamingFeesSelector = (state: any): boolean =>
  state.fundFeeControls.isAccruingStreamingFees;

// gasPriceReducer.ts
export const slowGasPriceSelector = (state: any): string =>
  Math.round(Number(state.gasPrice.standardGasPrice)).toString();
export const averageGasPriceSelector = (state: any): string =>
  Math.round(Number(state.gasPrice.fastGasPrice)).toString();
export const fastGasPriceSelector = (state: any): string =>
  Math.round(Number(state.gasPrice.rapidGasPrice)).toString();
export const baseFeeSelector = (state: any): string =>
  Math.round(Number(state.gasPrice.baseFee)).toString();
export const maxPriorityFeePerGasSelector = (state: any): string =>
  Math.round(Number(state.gasPrice.maxPriorityFeePerGas)).toString();

// ipAddressReducer.ts
export const isFetchingIpAddressSelector = (state: any): boolean =>
  state.ipAddress.isFetchingIpAddress;
export const ipAddressSelector = (state: any): null | string => state.ipAddress.ipAddress;
export const isRestrictedIpAddressSelector = (state: any): boolean =>
  state.ipAddress.isRestrictedIpAddress;

// issuanceV2Reducer.ts
export const issuanceInputQuantityV2Selector = (state: any): string =>
  state.issuanceV2.issuanceInputQuantity;
export const isSubmittingIssuanceTransactionV2Selector = (state: any): boolean =>
  state.issuanceV2.isSubmittingIssuanceTransaction;
export const isFetchingDebtIssuanceComponentsSelector = (state: any): boolean =>
  state.issuanceV2.isFetchingDebtIssuanceComponents;
export const requiredDebtIssuanceComponentsSelector = (state: any): IDebtComponents =>
  state.issuanceV2.requiredDebtIssuanceComponents;
export const isFetchingPerpIssuanceComponentsSelector = (state: any): boolean =>
  state.issuanceV2.isFetchingPerpIssuanceComponents;
export const requiredPerpIssuanceComponentsSelector = (state: any): IDebtComponents =>
  state.issuanceV2.requiredPerpIssuanceComponents;
export const maxSlippagePercentageAllowedSelector = (state: any): string =>
  state.issuanceV2.maxSlippagePercentageAllowed;
export const isFetchingPerpIssuanceFeesSelector = (state: any): boolean =>
  state.issuanceV2.isFetchingPerpIssuanceFees;
export const perpIssuanceFeesSelector = (state: any): IFeeResponse =>
  state.issuanceV2.perpIssuanceFees;
export const isFetchingDebtIssuanceFeesSelector = (state: any): boolean =>
  state.issuanceV2.isFetchingDebtIssuanceFees;
export const debtIssuanceFeesSelector = (state: any): IFeeResponse =>
  state.issuanceV2.debtIssuanceFees;
export const maxTokenPerpIssueAmountByLeverageSelector = (state: any): string =>
  state.issuanceV2.maxTokenPerpIssueAmountByLeverage;

// perpLeverageReducer.ts
export const isFetchingPerpLeverageDataSelector = (state: any): boolean =>
  state.perpLeverage.isFetchingPerpLeverageData;
export const perpCollateralTokenSelector = (state: any): string =>
  state.perpLeverage.collateralToken;
export const perpPositionUnitInfosSelector = (state: any): IPerpPositionUnitInfo[] =>
  state.perpLeverage.positionUnitInfos;
export const perpAccountInfoSelector = (state: any): IPerpAccountInfo =>
  state.perpLeverage.accountInfo;
export const vAssetDisplayInfosSelector = (state: any): VAssetDisplayInfo[] =>
  state.perpLeverage.vAssetDisplayInfos;
export const totalCollateralUnitsSelector = (state: any): string =>
  state.perpLeverage.totalCollateralUnits;

// onboardingReducer.ts
export const currentOnboardingSlideIndexSelector = (state: any): number =>
  state.onboarding.currentOnboardingSlideIndex;

// rebalancingReducer.ts
export const currentRebalancingSetSelector = (state: any): IRebalancingSet =>
  state.rebalancing.currentRebalancingSet;
export const currentRebalancingSetBalanceSelector = (state: any): string =>
  state.rebalancing.currentRebalancingSetBalance;
export const currentRebalancingSetDetailsSelector = (state: any): {} =>
  state.rebalancing.currentRebalancingSetDetails;
export const loadingRebalancingSetBalanceSelector = (state: any): false =>
  state.rebalancing.loadingRebalancingSetBalance;
export const rebalancingSetsFromApiSelector = (state: any): any[] =>
  state.rebalancing.rebalancingSetsFromApi;
export const isFetchingRebalancingSetSelector = (state: any): {} =>
  state.rebalancing.isFetchingRebalancingSet;
export const isFetchingRebalancingSetDetailsSelector = (state: any): {} =>
  state.rebalancing.isFetchingRebalancingSetDetails;
export const isFetchingRebalancingSetsSelector = (state: any): false =>
  state.rebalancing.isFetchingRebalancingSets;
export const isFetchingRebalanceAuctionsSelector = (state: any): false =>
  state.rebalancing.isFetchingRebalanceAuctions;
export const isFetchingRoboSetsSelector = (state: any): false =>
  state.rebalancing.isFetchingRoboSets;
export const isFetchingTraderSetsSelector = (state: any): false =>
  state.rebalancing.isFetchingTraderSets;
export const isFetchingLegacyTraderSetsSelector = (state: any): false =>
  state.rebalancing.isFetchingLegacyTraderSets;
export const setReceivedAtSelector = (state: any): {} => state.rebalancing.setReceivedAt;
export const strategiesSelector = (state: any): any[] => state.rebalancing.strategies;
export const popularSetsSelector = (state: any): any[] => state.rebalancing.popularSets;
export const rebalanceAuctionsSelector = (state: any): any[] => state.rebalancing.rebalanceAuctions;
export const receivedAtSelector = (state: any): Date => state.rebalancing.receivedAt;
export const traderSetsSelector = (state: any): IExploreRebalancingSet[] =>
  state.rebalancing.traderSets;
export const roboSetsSelector = (state: any): IExploreRebalancingSet[] =>
  state.rebalancing.roboSets;

// redemptionV2Reducer.ts
export const redemptionInputQuantityV2Selector = (state: any): string =>
  state.redemptionV2.redemptionInputQuantity;
export const isSubmittingRedemptionTransactionV2Selector = (state: any): boolean =>
  state.redemptionV2.isSubmittingRedemptionTransaction;
export const isFetchingDebtRedemptionComponentsSelector = (state: any): boolean =>
  state.redemptionV2.isFetchingDebtRedemptionComponents;
export const requiredDebtRedemptionComponentsSelector = (state: any): IDebtComponents =>
  state.redemptionV2.requiredDebtRedemptionComponents;
export const isFetchingPerpRedemptionComponentsSelector = (state: any): boolean =>
  state.redemptionV2.isFetchingPerpRedemptionComponents;
export const requiredPerpRedemptionComponentsSelector = (state: any): IDebtComponents =>
  state.redemptionV2.requiredPerpRedemptionComponents;
export const isFetchingPerpRedemptionFeesSelector = (state: any): boolean =>
  state.redemptionV2.isFetchingPerpRedemptionFees;
export const perpRedemptionFeesSelector = (state: any): IFeeResponse =>
  state.redemptionV2.perpRedemptionFees;
export const isFetchingDebtRedemptionFeesSelector = (state: any): boolean =>
  state.redemptionV2.isFetchingDebtRedemptionFees;
export const debtRedemptionFeesSelector = (state: any): IFeeResponse =>
  state.redemptionV2.debtRedemptionFees;

// selectLanguageReducer.ts
export const isSelectLanguageModalOpenSelector = (state: any): boolean =>
  state.selectLanguage.isSelectLanguageModalOpen;

// setDetailsReducer.ts
export const confirmedWarningsListSelector = (state: any): ISetConfirmedWarningsList =>
  state.setDetails.confirmedWarningsList;
export const setDetailsSelector = (state: any): { [address: string]: ISetDetails } =>
  state.setDetails.setDetails;
export const isFetchingSetDetailsSelector = (state: any): boolean =>
  state.setDetails.isFetchingSetDetails;
export const isInvalidSetDetailsSelector = (state: any): boolean =>
  state.setDetails.isInvalidSetDetails;
export const customSetDetailsSelector = (
  state: any,
): { [address: string]: CustomSetDetailsResponse } => state.setDetails.customSetDetails;
export const isFetchingCustomSetDetailsSelector = (state: any): boolean =>
  state.setDetails.isFetchingCustomSetDetails;
export const isInvalidCustomSetDetailsSelector = (state: any): boolean =>
  state.setDetails.isInvalidCustomSetDetails;
export const setDetailsCurrentSetAddressSelector = (state: any): string =>
  state.setDetails.currentlyViewingSetAddress;
export const isFetchingModuleEnabledSelector = (state: any): boolean =>
  state.setDetails.moduleEnabledLoading;
export const issuanceModuleEnabledSelector = (state: any): IEnabledMapping =>
  state.setDetails.isIssuanceModuleEnabled;
export const debtIssuanceModuleEnabledSelector = (state: any): IEnabledMapping =>
  state.setDetails.isDebtIssuanceModuleEnabled;
export const debtIssuanceModuleV2EnabledSelector = (state: any): IEnabledMapping =>
  state.setDetails.isDebtIssuanceModuleV2Enabled;
export const compoundLeverageModuleEnabledSelector = (state: any): IEnabledMapping =>
  state.setDetails.isCompoundLeverageModuleEnabled;
export const aaveLeverageModuleEnabledSelector = (state: any): IEnabledMapping =>
  state.setDetails.isAaveLeverageModuleEnabled;
export const perpV2LeverageModuleEnabledSelector = (state: any): IEnabledMapping =>
  state.setDetails.isPerpV2LeverageModuleEnabled;
export const perpV2BasisTradingModuleEnabledSelector = (state: any): IEnabledMapping =>
  state.setDetails.isPerpV2BasisTradingModuleEnabled;
export const perpIssuanceModuleEnabledSelector = (state: any): IEnabledMapping =>
  state.setDetails.isPerpIssuanceModuleEnabled;
export const isFetchingApyCalculationsSelector = (state: any): boolean =>
  state.setDetails.isFetchingApyCalculations;
export const primaryApySelector = (state: any): { [address: string]: string } =>
  state.setDetails.primaryApy;

// setManagerReducer.ts
export const isFetchingSetManagerSelector = (state: any): boolean =>
  state.setManager.isFetchingSetManager;
export const isFetchingDelegatedSetManagerSelector = (state: any): boolean =>
  state.setManager.isFetchingDelegatedManager;
export const setManagerIdSelector = (state: any): string => state.setManager.v1.id;
export const setManagerVersionSelector = (state: any): string => state.setManager.v1.version;
export const setManagerImageSelector = (state: any): string => state.setManager.v1.image;
export const setManagerNameSelector = (state: any): string => state.setManager.v1.name;
export const enabledModulesSelector = (state: any): ISetModule[] =>
  state.setManager.v1.enabledModules;
export const disabledModulesSelector = (state: any): ISetModule[] =>
  state.setManager.v1.disabledModules;
export const setManagerV2NameSelector = (state: any): string => state.setManager.v2.name;
export const enabledV2ModulesSelector = (state: any): IV2SetModule[] =>
  state.setManager.v2.enabledModules;
export const disabledV2ModulesSelector = (state: any): IV2SetModule[] =>
  state.setManager.v2.disabledModules;
export const setManagerOperatorAddressesSelector = (state: any): { [address: string]: string[] } =>
  state.setManager.delegatedManager.operatorAddresses;
export const setManagerAssetAllowlistAddressesSelector = (
  state: any,
): { [address: string]: string[] } => state.setManager.delegatedManager.assetAllowlistAddresses;
export const setManagerUseAllowlistSelector = (state: any): { [address: string]: boolean } =>
  state.setManager.delegatedManager.useAllowlist;
export const setManagerOwnerAddressSelector = (state: any): { [address: string]: string } =>
  state.setManager.delegatedManager.ownerAddress;

// setJSReducer.ts
// TODO: add proper typing once set.js is fixed
export const setJSInstanceSelector = (state: any): any => {
  return state.setJS.setJSInstance;
};
export const readOnlySetJSInstanceSelector = (state: any): any => state.setJS.readOnlySetJSInstance;

// tokensReducer.ts
export const isFetchingTokensSelector = (state: any): false => state.tokens.isFetchingTokens;

// tokenListsReducer.ts
export const isFetchingTokenListSelector = (state: any): any => state.tokenLists.isFetching;
export const tokenListsSelector = (state: any): any => state.tokenLists;
export const tokenSetsTokenListSelector = (state: any): IListToken[] =>
  state.tokenLists.tokenLists['tokenSets'];
export const featuredTokenSetsTokenListSelector = (state: any): IListToken[] =>
  state.tokenLists.tokenLists['featuredTokenSets'];
export const coingeckoEthereumTokenListSelector = (state: any): IListToken[] =>
  state.tokenLists.tokenLists['coingecko-ethereum'];
export const coingeckoPolygonTokenListSelector = (state: any): IListToken[] =>
  state.tokenLists.tokenLists['coingecko-polygon'];
export const coingeckoArbitrumTokenListSelector = (state: any): IListToken[] =>
  state.tokenLists.tokenLists['coingecko-arbitrum'];
export const coingeckoOptimismTokenListSelector = (state: any): IListToken[] =>
  state.tokenLists.tokenLists['coingecko-optimism'];
export const coingeckoAvalancheTokenListSelector = (state: any): IListToken[] =>
  state.tokenLists.tokenLists['coingecko-avalanche'];
export const geminiEthereumTokenListSelector = (state: any): IListToken[] =>
  state.tokenLists.tokenLists['gemini-ethereum'];
export const geminiPolygonTokenListSelector = (state: any): IListToken[] =>
  state.tokenLists.tokenLists['gemini-polygon'];
export const geminiArbitrumTokenListSelector = (state: any): IListToken[] =>
  state.tokenLists.tokenLists['gemini-arbitrum'];
export const geminiOptimismTokenListSelector = (state: any): IListToken[] =>
  state.tokenLists.tokenLists['gemini-optimism'];
export const geminiAvalancheTokenListSelector = (state: any): IListToken[] =>
  state.tokenLists.tokenLists['gemini-avalanche'];
export const isFetchingManagerTokensSelector = (state: any): boolean =>
  state.tokenLists.isFetchingManagerTokens;
export const managerTokensSelector = (state: any): GraphSetToken[] =>
  state.tokenLists.managerTokens;
export const fetchManagerTokensErrorSelector = (state: any): any =>
  state.tokenLists.fetchManagerTokensError;

// transactionsReducer.ts
export const allTransactionsSelector = (state: any): any[] => state.transactions.allTransactions;
export const showTransactionsSelector = (state: any): false => state.transactions.showTransactions;

// transactionWatcherReducer.ts
export const transactionStepSelector = (state: any): TransactionStep =>
  state.transactionWatcher.transactionStep;
export const transactionIdSelector = (state: any): string | undefined =>
  state.transactionWatcher.transactionId;

// uniswapReducer.ts
export const uniswapInstanceSelector = (state: any): any => state.uniswap.uniswapRouter;
export const sushiswapInstanceSelector = (state: any): any => state.uniswap.sushiswapRouter;

// web3Reducer.ts
export const appLoadingSelector = (state: any): boolean => state.web3.appLoading;
export const accountSelector = (state: any): string => state.web3.account;
export const emailSelector = (state: any): string => state.web3.email;
export const usernameSelector = (state: any): string => state.web3.username;
export const isEmailFetchingSelector = (state: any): false => state.web3.isEmailFetching;
export const isLoggedInSelector = (state: any): boolean => state.web3.isLoggedIn;
export const isSocialTraderSelector = (state: any): boolean => state.web3.isSocialTrader;
export const isOnboardingModalOpenSelector = (state: any): boolean =>
  state.web3.isOnboardingModalOpen;
export const isLoginModalOpenSelector = (state: any): boolean => state.web3.isLoginModalOpen;
export const isChainSwitchModalOpenSelector = (state: any): boolean =>
  state.web3.isChainSwitchModalOpen;
export const hasFetchedAccountsSelector = (state: any): boolean => state.web3.hasFetchedAccounts;
export const hasSeenOnboardingModalSelector = (state: any): boolean =>
  state.web3.hasSeenOnboardingModal;
export const emailConnectedSelector = (state: any): boolean => state.web3.emailConnected;
export const isOptedOutOfEmailSelector = (state: any): boolean => state.web3.isOptedOutOfEmail;
export const isTermsOfServiceConfirmedSelector = (state: any): boolean =>
  state.web3.isTermsOfServiceConfirmed;
export const providerTypeSelector = (state: any): string => state.web3.providerType;
export const afterLoginActionSelector = (state: any): string => state.web3.afterLoginAction;
export const networkIdSelector = (state: any): string => state.web3.networkId;
export const web3InstanceSelector = (state: any): Web3 => state.web3.web3Instance;
export const ledgerPathSelector = (state: any): string => state.web3.ledgerPath;
export const currentChainSelector = (state: any): string => state.web3.currentChain;

// windowDimensionReducer.ts
export const windowDimensionSelector = (state: any): IWindowDimensions => state.windowDimension;
export const widthSelector = (state: any): string => state.windowDimension.width;
export const heightSelector = (state: any): string => state.windowDimension.height;
export const scrollPercentSelector = (state: any): number => state.windowDimension.scrollPercent;
export const shouldRevealStickyNavBarSelector = (state: any): boolean =>
  state.windowDimension.shouldRevealStickyNavBar;
export const hasScrolledBelowFoldSelector = (state: any): boolean =>
  state.windowDimension.hasScrolledBelowFold;
