import { connect } from 'react-redux';
import Pagination from './Pagination';
import { TableDataSource, tableDisplayQuantityByDataSource } from '../../reducers/tableReducers';
import {
  tableSelector,
  maxPageCountSelector,
  paginationActionsSelector,
  filteredTableDataSourceSelector,
} from './selectors';

const mapStateToProps = (state: any, ownProps: any) => {
  const { tableDataSource, elementType } = ownProps;

  const { currentPageNumber, totalCount } = tableSelector(state, tableDataSource);
  const maxPageCount = maxPageCountSelector(state, tableDataSource);
  const maxElementsPerPageCount =
    tableDisplayQuantityByDataSource[tableDataSource as TableDataSource] || 1;
  const dataSource = filteredTableDataSourceSelector(state, tableDataSource);
  const totalElements = totalCount || dataSource?.length || 0;

  return {
    currentPageNumber,
    maxPageCount,
    maxElementsPerPageCount,
    totalElements,
    elementType,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  const { tableDataSource } = ownProps;

  const { incrementPageNumber, decrementPageNumber, setPageNumber } = paginationActionsSelector(
    tableDataSource,
  );

  return {
    onIncrementPageNumber: () => dispatch(incrementPageNumber()),
    onDecrementPageNumber: () => dispatch(decrementPageNumber()),
    onSetPageNumber: (pageNumber: number) => dispatch(setPageNumber(pageNumber)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);
