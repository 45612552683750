import { BigNumber as EthersBigNumber } from 'ethers';

import { submitSetJSTransaction } from './transactionWatcherActions';
import {
  setJSInstanceSelector,
  setDetailsCurrentSetAddressSelector,
  accountSelector,
} from '../selectors/baseSelectors';
import { getWeb3Instance } from '../utils/index';
import streamingFeeModuleABI from '../constants/abis/streamingFeeModuleABI';
import streamingFeeSplitExtensionABI from '../constants/abis/streamingFeeSplitExtensionABI';
import { toast } from 'react-toastify';
import { streamingFeeModuleAddressSelector } from '../selectors/setDetailsSelectors';
import { feeSplitExtensionAddressSelector } from '../selectors/protocolAddressSelector';
import { gasPriceTransactionOptionsSelector } from '../selectors/transactionSelectors';
import { isCurrentSetDelegatedManagerSelector } from '../selectors/setManagerSelectors';

export const accrueStreamingFee = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const setAddress = setDetailsCurrentSetAddressSelector(state);
  const userAddress = accountSelector(state);
  const gasPriceTransactionOptions = gasPriceTransactionOptionsSelector(state);
  const setJSInstance = setJSInstanceSelector(state);
  const streamingFeeModuleAddress = streamingFeeModuleAddressSelector(state);
  const streamingFeeSplitExtensionAddress = feeSplitExtensionAddressSelector(state);
  const web3Instance = await getWeb3Instance();
  const isDelegatedManager = isCurrentSetDelegatedManagerSelector(state);

  if (web3Instance) {
    let initializeStreamingFeeRequest;

    // If using a delegated manager contract, use streaming fee split extension contract
    if (isDelegatedManager) {
      const streamingFeeExtensionContract = new web3Instance.eth.Contract(
        streamingFeeSplitExtensionABI as any,
        streamingFeeSplitExtensionAddress,
      );
      const gasLimitDelegatedManager = await streamingFeeExtensionContract.methods
        .accrueFeesAndDistribute(setAddress)
        .estimateGas({ from: userAddress });
      const txOpts = {
        ...gasPriceTransactionOptions,
        gasLimit: gasLimitDelegatedManager,
      };

      initializeStreamingFeeRequest = submitSetJSTransaction(() => {
        return setJSInstance.extensions.streamingFeeExtension.accrueFeesAndDistributeAsync(
          setAddress,
          undefined,
          txOpts,
        );
      });
    } else {
      const feeModuleContract = new web3Instance.eth.Contract(
        streamingFeeModuleABI as any,
        streamingFeeModuleAddress,
      );
      const gasLimit = await feeModuleContract.methods
        .accrueFee(setAddress)
        .estimateGas({ from: userAddress });
      const txOpts = {
        ...gasPriceTransactionOptions,
        gasLimit: EthersBigNumber.from(gasLimit),
      };

      initializeStreamingFeeRequest = submitSetJSTransaction(() =>
        setJSInstance.fees.accrueStreamingFeesAsync(setAddress, undefined, txOpts),
      );
    }

    try {
      await dispatch(initializeStreamingFeeRequest);
    } catch (e) {
      console.log('could not submit initialization request');
      toast.warn('Could not accrue streaming fee. Please try again later.', {
        toastId: 'accrue-streaming-fee',
      });
    }
  } else {
    toast.warn('Could not accrue streaming fee. Please login to an eligible wallet first.', {
      toastId: 'accrue-streaming-fee',
    });
  }
};
