import _ from 'lodash';
import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { toast } from 'react-toastify';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Accordion, Container, Icon, Menu, Tab } from 'semantic-ui-react';
import queryString from 'query-string';

import SetTokens from './SetTokens';
import ManagedSets from './ManagedSets';
import AccountHeader from './AccountHeader';
import history from '../../utils/history';
import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';
import {
  GraphSetToken,
  IListToken,
  IListTokenWithBalance,
  ISetDetailsHydrated,
  ITokenBalances,
  IUser,
  IWindowDimensions,
} from '../../typings/index';
import { acceptAccountWarning, getAccountWarningStatus } from '../../utils/localStorageUtils';
import AccountChainWarningBanner from './AccountChainWarningBanner';
import { Text } from '../../components/index';
import ManagedSetsHeader from './ManagedSetsHeader';
import { blockExplorerName, makeBlockExplorerContractLink } from '../../utils/web3Utils';
import { fetchAllOnchainBalances } from '../../actions/balanceActions';

const { MOBILE_MEDIA_QUERY, TABLET_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const listItemAnimation = {
  '0%': {
    opacity: 0,
    top: '20px',
  },
  '75%': {
    opacity: 0.5,
    top: '0px',
  },
  '100%': {
    opacity: 1,
  },
};

export const styles = StyleSheet.create({
  Account_outerContainer: {
    marginBottom: '60px',
  },
  Account_container: {
    border: `1px solid ${COLORS.lightBlue2}`,
    borderRadius: '4px',
    boxShadow: '0 6px 12px 0 rgba(35, 1, 221, 0.05)',
    padding: '40px',
    paddingBottom: '64px',
    marginBottom: '20px',
    transition: '0.2s',
    [MOBILE_MEDIA_QUERY]: {
      border: 'none',
      boxShadow: 'none',
      padding: '20px',
      marginBottom: '80px',
    },
  },
  Account_polygonContainer: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  Account_networkBadge: {
    width: '90px',
    height: '20px',
    float: 'left',
    [MOBILE_MEDIA_QUERY]: {
      float: 'none',
      marginBottom: '10px',
    },
  },
  Account_button: {
    backgroundColor: COLORS.white,
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '4px',
    color: COLORS.darkBlue,
    cursor: 'pointer',
    padding: '12px 10px',
    transition: '0.2s',
    marginLeft: '10px',
    ':hover': {
      border: `1px solid ${COLORS.blue}`,
      color: COLORS.blue,
    },
    ':active': {
      border: `1px solid ${COLORS.darkBlue}`,
      color: COLORS.darkBlue,
      transform: 'scale(0.98)',
    },
    [TABLET_MEDIA_QUERY]: {
      marginLeft: '5px',
      marginRight: '5px',
    },
  },
  Account_headerContainer: {
    marginBottom: '50px',
    [TABLET_MEDIA_QUERY]: {
      marginBottom: '20px',
    },
  },
  Account_totalBalance: {
    fontSize: '76px',
    marginTop: '10px',
    marginBottom: '10px',
    textAlign: 'left',
    [TABLET_MEDIA_QUERY]: {
      textAlign: 'center',
    },
    [MOBILE_MEDIA_QUERY]: {
      fontSize: '36px',
    },
  },
  Account_totalBalanceText: {
    display: 'inline-block',
    textAlign: 'left',
    width: '100%',
    [TABLET_MEDIA_QUERY]: {
      textAlign: 'center',
    },
  },
  Account_setIcon: {
    display: 'inline-block',
    width: '28px',
    height: '28px',
    minWidth: '28px',
    verticalAlign: 'middle',
  },
  Account_setIconContainer: {
    display: 'inline-block',
    marginRight: '15px',
    borderRadius: '50px',
  },
  Account_setIconPlaceholderContainer: {
    overflow: 'hidden',
  },
  Account_setListRow: {
    paddingBottom: '25px',
    paddingTop: '25px',
  },
  Account_setListRowCondensed: {
    paddingBottom: '5px',
    paddingTop: '5px',
    [TABLET_MEDIA_QUERY]: {
      paddingTop: '30px',
    },
  },
  Account_setListRowBorder: {
    borderBottom: `1px solid ${COLORS.lightBlue2}`,
  },
  Account_setNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  Account_setName: {
    fontWeight: 500,
    display: 'inline-block',
  },
  Account_setBadgeContainer: {
    marginTop: '5px',
  },
  Account_cellContentContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  Account_cellContentContainerLeft: {
    paddingLeft: '0px',
  },
  Account_cellContentContainerRight: {
    paddingRight: '0px',
  },
  Account_innerCellContentContainerForAmount: {
    left: '8px',
    [MOBILE_MEDIA_QUERY]: {
      left: 0,
      paddingRight: 0,
    },
  },
  Account_innerCellContentContainer: {
    left: 0,
    [MOBILE_MEDIA_QUERY]: {
      paddingRight: 0,
    },
  },
  Account_setTableText: {
    verticalAlign: 'middle',
    width: '100%',
  },
  Account_sectionHeader: {
    fontSize: '20px',
    marginBottom: '20px',
    [MOBILE_MEDIA_QUERY]: {
      fontSize: '20px',
    },
  },
  Account_animatedRow: {
    animationName: [listItemAnimation],
    animationDuration: '0.75s',
    animationIterationCount: 'once',
    animationFillMode: 'backwards',
    animationTimingFunction: 'ease',
    position: 'relative',
  },
  // Transactions
  Account_transactionHeader: {
    marginBottom: '40px',
    [MOBILE_MEDIA_QUERY]: {
      marginLeft: '-16px',
    },
  },
  Account_transactionRow: {
    borderTop: `1px solid ${COLORS.gray}`,
    paddingBottom: '25px',
    paddingTop: '25px',
  },
  Account_transactionDescription: {
    marginRight: '15px',
  },
  Account_transactionStatus: {
    fontSize: '12px',
    fontWeight: 500,
  },
  Account_rightArrow: {
    paddingLeft: '5px',
  },
  Account_viewTransactionColumn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  Account_transactionIconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  Account_transactionsZeroStateText: {
    fontSize: '20px',
  },
  Account_setsZeroStateText: {
    width: '100%',
    textAlign: 'center',
  },
  Account_transactionsEndColumn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  Account_setExtraOptionsMenu: {
    color: COLORS.darkGray,
    height: '20px',
    marginLeft: '25px',
    [TABLET_MEDIA_QUERY]: {
      marginLeft: '5px',
    },
  },
  Account_transactionTitle: {
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
  Account_actionButtons: {
    position: 'absolute',
    right: '0',
    [TABLET_MEDIA_QUERY]: {
      position: 'relative',
    },
  },
  Account_toggleLabel: {
    marginRight: '10px',
    marginTop: '4px',
    color: COLORS.darkGray,
  },
  Account_toggleContainer: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  Account_setBalancesGrid: {
    marginBottom: '-40px',
  },
  Account_expandedDetails: {
    paddingTop: '8px',
    paddingBottom: '8px',
    borderLeft: `1px solid ${COLORS.gray}`,
    marginLeft: '44px',
  },
  Account_expandedDetailsGrid: {
    width: '100%',
    marginTop: '15px',
    marginBottom: 0,
    zIndex: -1,
    color: COLORS.darkGray,
  },
  Account_priceChangeBadgeContainer: {
    display: 'inline-flex',
    alignItems: 'center',
  },

  // Welcome Styles
  Account_welcomeContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
    [TABLET_MEDIA_QUERY]: {
      marginTop: '20px',
    },
  },
  Account_welcomeTitle: {
    fontSize: '24px',
    fontWeight: 500,
    color: COLORS.lightBlue,
  },
  Account_welcomeSubtitle: {
    color: COLORS.darkBlue,
  },
  Account_welcomeOptionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    [MOBILE_MEDIA_QUERY]: {
      flexDirection: 'column',
    },
  },
  Account_welcomeOption: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '48%',
    padding: '40px',
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '4px',
    ':hover': {
      borderColor: COLORS.grayDarkened,
    },
    [MOBILE_MEDIA_QUERY]: {
      width: '100%',
      padding: '20px',
      marginBottom: '10px',
    },
  },
  Account_welcomeOptionIcon: {
    width: '40px',
    height: '40px',
    marginBottom: '20px',
  },
  Account_welcomeOptionHeader: {
    display: 'flex',
    flexDirection: 'column',
  },
  Account_welcomeOptionTitleText: {
    fontSize: '20px',
    fontWeight: 500,
    color: COLORS.darkBlue,
    marginBottom: '20px',
  },
  Account_welcomeOptionBodyText: {
    fontSize: '14px',
    color: COLORS.darkBlue,
    marginBottom: '20px',
  },
  Account_welcomeOptionButton: {
    fontSize: '14px',
    fontWeight: 500,
    minWidth: '150px',
    width: '40%',
    padding: '14px',
    textAlign: 'center',
    borderRadius: '4px',
    backgroundColor: COLORS.blue,
    color: COLORS.white,
    ':hover': {
      backgroundColor: COLORS.blueDarkened,
    },
  },
  Account_detailsDimmer: {
    opacity: '50%',
    pointerEvents: 'none',
  },
  Account_tab: {
    borderRadius: '0 4px 4px 0',
  },
  Account_menuItem: {
    color: COLORS.darkGray,
    fontWeight: 500,
    marginLeft: '40px',
    paddingLeft: '5px',
    paddingRight: '5px',
    [TABLET_MEDIA_QUERY]: {
      marginLeft: '20px',
    },
  },
  Account_menuItemFirst: {
    marginLeft: '0',
  },
  Account_menuItemActive: {
    borderColor: COLORS.blue,
    color: COLORS.blue,
    fontWeight: 600,
  },
  Account_accordion: {
    marginTop: '100px',
    marginBottom: '30px',
  },
  Account_accordionQuestion: {
    marginTop: '10px',
    marginBottom: '20px',
    paddingLeft: '22px',
    fontSize: '14px',
    color: COLORS.darkBlue,
  },
  Account_link: {
    fontSize: '14px',
  },
  Account_textAlignLeft: {
    textAlign: 'left',
  },
});

interface IAccountProps {
  chainName: string;
  networkBadge: string;
  currentChain: string;
  portfolioPathPrefix: string;
  customV2SetPathPrefix: string;
  isPolygon: boolean;
  providerType: string;
  account: string;
  networkId: string;
  location?: any;
  windowDimension: IWindowDimensions;
  isFetchingDeployedSetDetails: boolean;
  isFetchingApiBalance: boolean;
  erc20Balances: ITokenBalances;
  deployedSetDetailsHydrated: { [address: string]: ISetDetailsHydrated };
  tokenSetsTokenListByAddress: { [address: string]: IListToken };
  geminiTokenListByAddress: { [address: string]: IListToken };
  accountBalance: string;
  networkCurrencyPrice: string;
  networkListTokenWithBalance: IListTokenWithBalance;
  managerTokens: GraphSetToken[];
  isFetchingManagerTokens: boolean;
  tokenSetsTokenListUrl: string;
  isWrongNetwork: boolean;
  communityAndOwnedAndFeaturedSets: string[];
  fetchFeaturedTokenSetsTokenList: (...args: any[]) => any;
  fetchAllOnchainBalances: (...args: any[]) => any;
  fetchDeployedSetsWithLogos: (...args: any[]) => any;
  fetchOwnedSetAddresses: (...args: any[]) => any;
  fetchTokenSetsTokenList: (...args: any[]) => any;
  fetchManagerTokens: (...args: any[]) => any;
}

interface IAccountState {
  expandView: boolean;
  isInitializing: boolean;
  user: IUser;
  hideWarning: boolean;
  activeTabIndex: number;
  accordionIndex: number;
}

enum QueryParams {
  MyPortfolioView = 'portfolio',
  MyManagedSetsView = 'managed-sets',
}

/**
 * @title Account
 * @author Set Labs
 *
 * The Account page allows users to see their total balance including their individual Set balances.
 */
class Account extends PureComponent<IAccountProps & WithTranslation, IAccountState> {
  mounted = false;

  constructor(props: any) {
    super(props);

    const activeIndex = this.getActiveIndexFromQueryParams();

    this.state = {
      expandView: false,
      isInitializing: true,
      user: {
        pending_transactions_value_usd: '',
        sets: [],
        transactions: [],
      } as IUser,
      hideWarning: false,
      activeTabIndex: activeIndex,
      accordionIndex: undefined,
    };
  }

  public async componentDidMount() {
    const {
      fetchAllOnchainBalances,
      fetchDeployedSetsWithLogos,
      fetchManagerTokens,
      fetchFeaturedTokenSetsTokenList,
      communityAndOwnedAndFeaturedSets,
    } = this.props;

    this.mounted = true;

    await fetchFeaturedTokenSetsTokenList();

    // Add short delay to let setJS initialize
    setTimeout(async () => {
      await fetchManagerTokens();
      await fetchAllOnchainBalances();
      await fetchDeployedSetsWithLogos(communityAndOwnedAndFeaturedSets);

      this.setState({ isInitializing: false });
    }, 1000);

    document.title = 'Account - TokenSets';
  }

  componentDidUpdate = async (prevProps: IAccountProps) => {
    const { communityAndOwnedAndFeaturedSets, fetchDeployedSetsWithLogos } = this.props;

    if (
      !_.isEqual(prevProps.communityAndOwnedAndFeaturedSets, communityAndOwnedAndFeaturedSets) &&
      communityAndOwnedAndFeaturedSets?.length
    ) {
      await fetchAllOnchainBalances();
      await fetchDeployedSetsWithLogos(communityAndOwnedAndFeaturedSets);
    }
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  public getActiveIndexFromQueryParams = () => {
    const { location } = this.props;

    const defaultValue = 0;

    if (!location || !location.search) return defaultValue;

    const params = queryString.parse(location.search);

    switch (params.show) {
      case QueryParams.MyPortfolioView:
        return 0;
      case QueryParams.MyManagedSetsView:
        return 1;
    }
    return defaultValue;
  };

  public setActiveTabIndex = (index: number) => {
    if (this.state.activeTabIndex === index) return;

    switch (index) {
      case 0:
        window.history.replaceState({}, '', `?show=${QueryParams.MyPortfolioView}`);
        break;
      case 1:
        window.history.replaceState({}, '', `?show=${QueryParams.MyManagedSetsView}`);
        break;
    }

    this.setState({ activeTabIndex: index });
  };

  public setAccordionIndex = (index: number) => {
    const { accordionIndex } = this.state;

    if (accordionIndex !== index) {
      this.setState({ accordionIndex: index });
    } else {
      this.setState({ accordionIndex: undefined });
    }
  };

  /**
   * Toggles the viewing of the individual component tokens for given sets owned by user
   */
  public toggleExpandedView = () => {
    this.setState({ expandView: !this.state.expandView });
  };

  public isLoadingContent = () => {
    const {
      isFetchingApiBalance,
      isFetchingManagerTokens,
      isFetchingDeployedSetDetails,
    } = this.props;
    const { isInitializing } = this.state;

    return (
      isFetchingApiBalance ||
      isFetchingManagerTokens ||
      isFetchingDeployedSetDetails ||
      isInitializing
    );
  };

  public renderAccountPanes = () => {
    const { managerTokens } = this.props;
    const { activeTabIndex } = this.state;

    const panes = [
      {
        menuItem: (
          <Menu.Item
            className={css(
              styles.Account_menuItem,
              styles.Account_menuItemFirst,
              activeTabIndex === 0 && styles.Account_menuItemActive,
            )}
            key="portfolio"
            onClick={() => this.setActiveTabIndex(0)}
          >
            My Portfolio
          </Menu.Item>
        ),
        render: () => {
          const {
            portfolioPathPrefix,
            networkCurrencyPrice,
            accountBalance,
            networkBadge,
            isPolygon,
            account,
            erc20Balances,
            tokenSetsTokenListByAddress,
            geminiTokenListByAddress,
            deployedSetDetailsHydrated,
            windowDimension,
            networkListTokenWithBalance,
            chainName,
            currentChain,
            customV2SetPathPrefix,
            tokenSetsTokenListUrl,
            isWrongNetwork,
            communityAndOwnedAndFeaturedSets,
          } = this.props;
          const { expandView, user, hideWarning, accordionIndex } = this.state;

          const isLoading = this.isLoadingContent();

          const showChainWarning = !hideWarning && !getAccountWarningStatus(currentChain);

          const dimmerStyle = showChainWarning && styles.Account_detailsDimmer;

          if (isWrongNetwork) {
            toast.warn('Sorry, you cannot view your Account while on an unsupported network.');
            // Uses router history so that toast still shows up without a page reload
            history.push('/');
          }

          return (
            <div className={css(styles.Account_outerContainer)}>
              {showChainWarning && (
                <AccountChainWarningBanner
                  chainName={chainName}
                  onAccept={() => {
                    acceptAccountWarning(currentChain);
                    this.setState({ hideWarning: true });
                  }}
                />
              )}
              <Container
                style={{
                  ...(isPolygon && {
                    borderTop: '5px solid',
                    borderImageSlice: '1 0 0',
                    borderImageSource: COLORS.polygonGradient,
                  }),
                }}
              >
                <div
                  className={css(
                    styles.Account_container,
                    dimmerStyle,
                    isPolygon && styles.Account_polygonContainer,
                  )}
                >
                  {!isLoading && (
                    <AccountHeader
                      networkBadge={networkBadge}
                      deployedSetDetailsHydrated={deployedSetDetailsHydrated}
                      geminiTokenListByAddress={geminiTokenListByAddress}
                      tokenSetsTokenListByAddress={tokenSetsTokenListByAddress}
                      erc20Balances={erc20Balances}
                      windowDimension={windowDimension}
                      networkListTokenWithBalance={networkListTokenWithBalance}
                      networkCurrencyPrice={networkCurrencyPrice}
                    />
                  )}
                  <SetTokens
                    setAddresses={communityAndOwnedAndFeaturedSets}
                    portfolioPathPrefix={portfolioPathPrefix}
                    customV2SetPathPrefix={customV2SetPathPrefix}
                    networkListTokenWithBalance={networkListTokenWithBalance}
                    networkCurrencyPrice={networkCurrencyPrice}
                    accountBalance={accountBalance}
                    deployedSetDetailsHydrated={deployedSetDetailsHydrated}
                    isFetchingApiBalances={isLoading}
                    erc20Balances={erc20Balances}
                    tokenSetsTokenListByAddress={tokenSetsTokenListByAddress}
                    geminiTokenListByAddress={geminiTokenListByAddress}
                    expandView={expandView}
                    user={user}
                    windowDimension={windowDimension}
                    onToggleExpandedView={this.toggleExpandedView}
                  />
                  {!isLoading && (
                    <Accordion
                      className={css(styles.Account_textAlignLeft, styles.Account_accordion)}
                    >
                      <Accordion.Title
                        active={accordionIndex === 0}
                        index={0}
                        onClick={() => this.setAccordionIndex(0)}
                      >
                        <Icon name="dropdown" />
                        <Text color="darkBlue" type="medium" fontWeight={600}>
                          Where are my legacy Sets?
                        </Text>
                      </Accordion.Title>
                      <Accordion.Content
                        className={css(styles.Account_accordionQuestion)}
                        active={accordionIndex === 0}
                      >
                        Set Protocol V1 tokens are no longer tracked on TokenSets including all
                        social trader and robo Sets. To see those balances, check{' '}
                        <a
                          className={css(styles.Account_link)}
                          href={`https://app.zerion.io/${account}/overview`}
                          target="_blank"
                        >
                          Zerion.
                        </a>
                      </Accordion.Content>
                      <Accordion.Title
                        active={accordionIndex === 1}
                        index={1}
                        onClick={() => this.setAccordionIndex(1)}
                      >
                        <Icon name="dropdown" />
                        <Text color="darkBlue" type="medium" fontWeight={600}>
                          How come I don’t see all of my Sets?
                        </Text>
                      </Accordion.Title>
                      <Accordion.Content
                        className={css(styles.Account_accordionQuestion)}
                        active={accordionIndex === 1}
                      >
                        Only Sets that have their{' '}
                        <a
                          className={css(styles.Account_link)}
                          href="https://docs.tokensets.com/set-customisation"
                          target="_blank"
                        >
                          descriptions
                        </a>{' '}
                        filled out by their managers will appear in this list. The current list of
                        Sets with details can be found{' '}
                        <a
                          className={css(styles.Account_link)}
                          href={tokenSetsTokenListUrl}
                          target="_blank"
                        >
                          here
                        </a>
                        .
                      </Accordion.Content>
                      <Accordion.Title
                        active={accordionIndex === 2}
                        index={2}
                        onClick={() => this.setAccordionIndex(2)}
                      >
                        <Icon name="dropdown" />
                        <Text color="darkBlue" type="medium" fontWeight={600}>
                          How come I don’t see all of my Coins?
                        </Text>
                      </Accordion.Title>
                      <Accordion.Content
                        className={css(styles.Account_accordionQuestion)}
                        active={accordionIndex === 2}
                      >
                        We currently populate our coin list using Gemini’s official{' '}
                        <a
                          className={css(styles.Account_link)}
                          href={`https://www.gemini.com/uniswap/manifest.json`}
                          target="_blank"
                        >
                          coin list
                        </a>
                        , so not all of your coins will show up here. To see all of your coins’
                        balances, check{' '}
                        <a
                          className={css(styles.Account_link)}
                          href={`https://app.zerion.io/${account}/overview`}
                          target="_blank"
                        >
                          Zerion
                        </a>{' '}
                        or{' '}
                        <a
                          className={css(styles.Account_link)}
                          href={makeBlockExplorerContractLink(account)}
                          target="_blank"
                        >
                          {blockExplorerName()}
                        </a>{' '}
                      </Accordion.Content>
                    </Accordion>
                  )}
                </div>
              </Container>
            </div>
          );
        },
      },
    ];

    if (managerTokens?.length) {
      panes.push({
        menuItem: (
          <Menu.Item
            className={css(
              styles.Account_menuItem,
              activeTabIndex === 1 && styles.Account_menuItemActive,
            )}
            key="managed-sets"
            onClick={() => this.setActiveTabIndex(1)}
          >
            My Managed Sets
          </Menu.Item>
        ),
        render: () => {
          const {
            isPolygon,
            networkBadge,
            customV2SetPathPrefix,
            windowDimension,
            managerTokens,
            deployedSetDetailsHydrated,
            tokenSetsTokenListByAddress,
          } = this.props;

          const isLoading = this.isLoadingContent();

          return (
            <div className={css(styles.Account_outerContainer)}>
              <Container
                style={{
                  ...(isPolygon && {
                    borderTop: '5px solid',
                    borderImageSlice: '1 0 0',
                    borderImageSource: COLORS.polygonGradient,
                  }),
                }}
              >
                <div
                  className={css(
                    styles.Account_container,
                    isPolygon && styles.Account_polygonContainer,
                  )}
                >
                  {!isLoading && (
                    <ManagedSetsHeader
                      networkBadge={networkBadge}
                      deployedSetDetailsHydrated={deployedSetDetailsHydrated}
                      windowDimension={windowDimension}
                      managerTokens={managerTokens}
                    />
                  )}
                  <ManagedSets
                    customV2SetPathPrefix={customV2SetPathPrefix}
                    managerTokens={managerTokens}
                    tokenSetsTokenListByAddress={tokenSetsTokenListByAddress}
                    deployedSetDetailsHydrated={deployedSetDetailsHydrated}
                    isFetchingApiBalances={isLoading}
                    windowDimension={windowDimension}
                    onToggleExpandedView={this.toggleExpandedView}
                  />
                </div>
              </Container>
            </div>
          );
        },
      });
    }

    return panes;
  };

  render() {
    const { activeTabIndex } = this.state;

    return (
      <Container>
        <Tab
          className={css(styles.Account_tab)}
          activeIndex={activeTabIndex}
          menu={{ secondary: true, pointing: true }}
          panes={this.renderAccountPanes()}
        />
      </Container>
    );
  }
}

export default withTranslation('account')(Account);
