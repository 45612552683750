import BigNumber from 'bignumber.js';
import { TableDataSource, tableDisplayQuantityByDataSource } from '../../reducers/tableReducers';
import {
  incrementPageNumber,
  decrementPageNumber,
  setPageNumber,
  resetPagination,
  customSetsResetPagination,
  portfoliosIncrementPageNumber,
  portfoliosDecrementPageNumber,
  portfoliosSetPageNumber,
  portfoliosResetPagination,
  incrementCustomSetsPageNumberWithQuery,
  decrementCustomSetsPageNumberWithQuery,
  setCustomSetsPageNumberWithQuery,
} from '../../actions/tableActions';
import { applyAllFilters } from '../../containers/Explore/selectors';
import { tokenSetsTokenListSelector } from '../../selectors/baseSelectors';

export const tableDataSourceSelector = (state: any, tableDataSource: TableDataSource) => {
  const {
    explore: { portfolios },
  } = state;
  const { setTokenPages } = tableSelector(state, TableDataSource.customSets);
  const tokenSetsTokenList = tokenSetsTokenListSelector(state);

  if (tableDataSource === TableDataSource.portfolios) return portfolios;
  if (tableDataSource === TableDataSource.communitySets) return tokenSetsTokenList;
  if (tableDataSource === TableDataSource.customSets) return setTokenPages;

  return [];
};

export const filteredTableDataSourceSelector = (state: any, tableDataSource: TableDataSource) => {
  const dataList = tableDataSourceSelector(state, tableDataSource);
  const { currentPageNumber } = tableSelector(state, TableDataSource.customSets);

  if (tableDataSource === TableDataSource.customSets) {
    return dataList[currentPageNumber];
  }
  if (tableDataSource === TableDataSource.communitySets) {
    return dataList;
  }
  return applyAllFilters(state, dataList);
};

export const maxPageCountSelector = (state: any, tableDataSource: TableDataSource) => {
  const dataList = filteredTableDataSourceSelector(state, tableDataSource);
  const maxDisplayCount = tableDisplayQuantityByDataSource[tableDataSource];

  let totalSetCount = dataList?.length || 0;
  if (tableDataSource === TableDataSource.customSets) {
    const { totalCount } = tableSelector(state, TableDataSource.customSets);
    totalSetCount = totalCount;
  }

  const pageCount = new BigNumber(totalSetCount)
    .div(maxDisplayCount)
    .toFixed(0, BigNumber.ROUND_UP);

  return Number(pageCount);
};

/**
 * Returns table pagination + sorting state depending on the target data source.
 */
export const tableSelector = (state: any, tableDataSource?: TableDataSource) => {
  const { tables } = state;

  if (!tableDataSource || typeof tableDataSource !== 'string') return tables.default;

  return tables[tableDataSource] || tables.default;
};

/**
 * Returns actions to update state, depending on target table data source.
 */
export const paginationActionsSelector = (tableDataSource?: TableDataSource) => {
  if (tableDataSource === TableDataSource.customSets) {
    return {
      incrementPageNumber: incrementCustomSetsPageNumberWithQuery,
      decrementPageNumber: decrementCustomSetsPageNumberWithQuery,
      setPageNumber: setCustomSetsPageNumberWithQuery,
      resetPagination: customSetsResetPagination,
    };
  }

  if (tableDataSource === TableDataSource.portfolios) {
    return {
      incrementPageNumber: portfoliosIncrementPageNumber,
      decrementPageNumber: portfoliosDecrementPageNumber,
      setPageNumber: portfoliosSetPageNumber,
      resetPagination: portfoliosResetPagination,
    };
  }

  return {
    incrementPageNumber,
    decrementPageNumber,
    setPageNumber,
    resetPagination,
  };
};
