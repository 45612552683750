import { currentChainOrDefaultSelector } from '../selectors/web3Selectors';
import { NETWORK_CONSTANTS } from '../constants/index';
import setJSConfig from '../constants/setJSConfig';

/* Module Addresses */
export const debtIssuanceModuleV1AddressSelector = (state: any): string => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case NETWORK_CONSTANTS.POLYGON_CHAIN:
    case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
    case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
    case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
      return setJSConfig[currentChain].debtIssuanceModuleAddress;
    default:
      return setJSConfig[NETWORK_CONSTANTS.ETHEREUM_CHAIN].debtIssuanceModuleAddress;
  }
};

export const debtIssuanceModuleV2AddressSelector = (state: any): string => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case NETWORK_CONSTANTS.POLYGON_CHAIN:
    case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
    case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
    case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
      return setJSConfig[currentChain].debtIssuanceModuleV2Address;
    default:
      return setJSConfig[NETWORK_CONSTANTS.ETHEREUM_CHAIN].debtIssuanceModuleV2Address;
  }
};

export const perpIssuanceModuleAddressSelector = (state: any): string => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case NETWORK_CONSTANTS.POLYGON_CHAIN:
    case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
    case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
    case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
      return setJSConfig[currentChain].slippageIssuanceModuleAddress;
    default:
      return setJSConfig[NETWORK_CONSTANTS.ETHEREUM_CHAIN].slippageIssuanceModuleAddress;
  }
};

export const streamingFeeModuleAddressSelector = (state: any) => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case NETWORK_CONSTANTS.POLYGON_CHAIN:
    case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
    case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
    case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
      return setJSConfig[currentChain].streamingFeeModuleAddress;
    default:
      return setJSConfig[NETWORK_CONSTANTS.ETHEREUM_CHAIN].streamingFeeModuleAddress;
  }
};

/* Extensions Addresses */
export const issuanceExtensionAddressSelector = (state: any) => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case NETWORK_CONSTANTS.POLYGON_CHAIN:
    case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
    case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
    case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
      return setJSConfig[currentChain].issuanceExtensionAddress;
    default:
      return setJSConfig[NETWORK_CONSTANTS.ETHEREUM_CHAIN].issuanceExtensionAddress;
  }
};

export const tradeExtensionAddressSelector = (state: any) => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case NETWORK_CONSTANTS.POLYGON_CHAIN:
    case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
    case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
    case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
      return setJSConfig[currentChain].tradeExtensionAddress;
    default:
      return setJSConfig[NETWORK_CONSTANTS.ETHEREUM_CHAIN].tradeExtensionAddress;
  }
};

export const batchTradeExtensionAddressSelector = (state: any) => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case NETWORK_CONSTANTS.POLYGON_CHAIN:
    case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
    case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
    case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
      return setJSConfig[currentChain].batchTradeExtensionAddress;
    default:
      return setJSConfig[NETWORK_CONSTANTS.ETHEREUM_CHAIN].batchTradeExtensionAddress;
  }
};

export const feeSplitExtensionAddressSelector = (state: any) => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case NETWORK_CONSTANTS.POLYGON_CHAIN:
    case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
    case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
    case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
      return setJSConfig[currentChain].streamingFeeExtensionAddress;
    default:
      return setJSConfig[NETWORK_CONSTANTS.ETHEREUM_CHAIN].streamingFeeExtensionAddress;
  }
};

/* Perpiherpy Contract Addresses */
export const delegatedManagerFactoryAddressSelector = (state: any) => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case NETWORK_CONSTANTS.POLYGON_CHAIN:
    case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
    case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
    case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
      return setJSConfig[currentChain].delegatedManagerFactoryAddress;
    default:
      return setJSConfig[NETWORK_CONSTANTS.ETHEREUM_CHAIN].delegatedManagerFactoryAddress;
  }
};
