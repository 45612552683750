import _ from 'lodash';
import { toast } from 'react-toastify';

import i18n from '../i18n';
import { updateAccountBalanceAction } from '../actions/balanceActions';
import { GO_TO_NEXT_ONBOARDING_SLIDE, goToNextOnboardingSlide } from '../actions/onboardingActions';
import { NETWORK_CONSTANTS, WEB3_PROVIDERS } from '../constants/index';
import { fm, magic, getAccount, login, getWeb3Instance, routerHistory } from '../utils/index';
import { emptyActionGenerator, payloadActionGenerator } from '../utils/reduxHelpers';
import {
  accountSelector,
  afterLoginActionSelector,
  providerTypeSelector,
  setDetailsCurrentSetAddressSelector,
  isLoggedInSelector,
  isChainSwitchModalOpenSelector,
} from '../selectors/baseSelectors';
import {
  currentChainOrDefaultSelector,
  customSetPathPrefixSelector,
} from '../selectors/web3Selectors';
import { SUPPORTED_NETWORKS } from '../constants/index';

export const APP_LOADING = 'APP_LOADING';
export const CLOSE_LOGIN_MODAL = 'CLOSE_LOGIN_MODAL';
export const CLOSE_ONBOARDING_MODAL = 'CLOSE_ONBOARDING_MODAL';
export const GO_TO_ACCOUNT_PAGE = 'GO_TO_ACCOUNT_PAGE';
export const GO_TO_REBALANCE_NOTIFICATIONS_SETTINGS = 'GO_TO_REBALANCE_NOTIFICATIONS_SETTINGS';
export const GO_TO_CUSTOM_SET_AND_PORTFOLIO_ISSUANCE_PAGE =
  'GO_TO_CUSTOM_SET_AND_PORTFOLIO_ISSUANCE_PAGE';
export const GO_TO_CUSTOM_SET_AND_PORTFOLIO_REDEMPTION_PAGE =
  'GO_TO_CUSTOM_SET_AND_PORTFOLIO_REDEMPTION_PAGE';
export const LOG_OUT = 'LOG_OUT';
export const OPEN_LOGIN_MODAL = 'OPEN_LOGIN_MODAL';
export const OPEN_ONBOARDING_MODAL = 'OPEN_ONBOARDING_MODAL';
export const OPT_OUT_OF_EMAIL_SIGNUP = 'OPT_OUT_OF_EMAIL_SIGNUP';
export const SELECT_PROVIDER_TYPE = 'SELECT_PROVIDER_TYPE';
export const SET_AFTER_LOGIN_ACTION = 'SET_AFTER_LOGIN_ACTION';
export const SET_CURRENT_EMAIL = 'SET_CURRENT_EMAIL';
export const SET_CURRENT_EMAIL_FETCHING = 'SET_CURRENT_EMAIL_FETCHING';
export const SET_CURRENT_NETWORK_ID = 'SET_CURRENT_NETWORK_ID';
export const SET_EMAIL_CONNECTED = 'SET_EMAIL_CONNECTED';
export const SET_IS_SOCIAL_TRADER = 'SET_IS_SOCIAL_TRADER';
export const SET_LEDGER_PATH = 'SET_LEDGER_PATH';
export const SET_TERMS_OF_SERVICE_CONFIRMED = 'SET_TERMS_OF_SERVICE_CONFIRMED';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_WEB3_INSTANCE = 'SET_WEB3_INSTANCE';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UPDATE_LOGIN_STATUS = 'UPDATE_LOGIN_STATUS';
export const CLEAR_AFTER_LOGIN_ACTION = 'CLEAR_AFTER_LOGIN_ACTION';
export const SET_CURRENT_CHAIN = 'SET_CURRENT_CHAIN';
export const CLOSE_CHAIN_SWITCH_MODAL = 'CLOSE_CHAIN_SWITCH_MODAL';
export const OPEN_CHAIN_SWITCH_MODAL = 'OPEN_CHAIN_SWITCH_MODAL';

export const appLoading = emptyActionGenerator(APP_LOADING);
export const closeChainSwitchModalAction = emptyActionGenerator(CLOSE_CHAIN_SWITCH_MODAL);
export const openChainSwitchModalAction = emptyActionGenerator(OPEN_CHAIN_SWITCH_MODAL);
export const closeLoginModal = emptyActionGenerator(CLOSE_LOGIN_MODAL);
export const closeOnboardingModal = emptyActionGenerator(CLOSE_ONBOARDING_MODAL);
export const logOutAction = emptyActionGenerator(LOG_OUT);
export const openLoginModal = payloadActionGenerator(OPEN_LOGIN_MODAL);
export const clearAfterLoginAction = emptyActionGenerator(CLEAR_AFTER_LOGIN_ACTION);
export const openOnboardingModal = emptyActionGenerator(OPEN_ONBOARDING_MODAL);
export const optOutOfEmailSignup = emptyActionGenerator(OPT_OUT_OF_EMAIL_SIGNUP);
export const selectProviderType = payloadActionGenerator(SELECT_PROVIDER_TYPE);
export const setAfterLoginAction = payloadActionGenerator(SET_AFTER_LOGIN_ACTION);
export const setCurrentEmail = payloadActionGenerator(SET_CURRENT_EMAIL);
export const setCurrentEmailFetching = emptyActionGenerator(SET_CURRENT_EMAIL_FETCHING);
export const setCurrentNetworkId = payloadActionGenerator(SET_CURRENT_NETWORK_ID);
export const setEmailConnected = payloadActionGenerator(SET_EMAIL_CONNECTED);
export const setIsSocialTrader = payloadActionGenerator(SET_IS_SOCIAL_TRADER);
export const setLedgerPath = payloadActionGenerator(SET_LEDGER_PATH);
export const setTermsOfServiceConfirmed = payloadActionGenerator(SET_TERMS_OF_SERVICE_CONFIRMED);
export const setUsername = payloadActionGenerator(SET_USERNAME);
export const updateAccount = payloadActionGenerator(UPDATE_ACCOUNT);
export const updateLoginStatus = payloadActionGenerator(UPDATE_LOGIN_STATUS);

export const setCurrentChain = payloadActionGenerator(SET_CURRENT_CHAIN);

export const openChainSwitchModal = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const isChainSwitchModalOpen = isChainSwitchModalOpenSelector(state);

  if (!isChainSwitchModalOpen) {
    dispatch(openChainSwitchModalAction());
  }
};

export const closeChainSwitchModal = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const isChainSwitchModalOpen = isChainSwitchModalOpenSelector(state);

  if (isChainSwitchModalOpen) {
    dispatch(closeChainSwitchModalAction());
  }
};

export const metamaskSwitchChains = (
  newChain: string,
  redirectTo: string,
  chainId: string,
  account: string,
) => async (dispatch: any) => {
  const { CHAIN_PARAMS } = NETWORK_CONSTANTS;

  try {
    await (window.ethereum as any).request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: CHAIN_PARAMS[chainId].chainId }, account],
    });
    window.location.href = redirectTo ? redirectTo : '/explore';
    await dispatch(appLoading());
    await dispatch(setCurrentChain(newChain));
    window.location.reload();
  } catch (error) {
    console.log(error.code);
    // This error code indicates that the chain has not been added to MetaMask.
    if (error.code === 4902) {
      try {
        await (window.ethereum as any).request({
          method: 'wallet_addEthereumChain',
          params: [CHAIN_PARAMS[chainId], account],
        });
        window.location.href = redirectTo ? redirectTo : '/explore';
        await dispatch(appLoading());
        await dispatch(setCurrentChain(newChain));
        window.location.reload();
      } catch (addError) {
        if (addError.code === 4001) {
          console.log(addError.message);
        }
      }
    } else if (error.code === 4001) {
      console.log(error.message);
    }
  }
};

export const handleSwitchChain = (newChain: string, redirectTo: string) => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const currentChain = currentChainOrDefaultSelector(state);
  const account = accountSelector(state);
  const isLoggedIn = isLoggedInSelector(state);
  const providerType = providerTypeSelector(state);
  const supportedNetworks = SUPPORTED_NETWORKS;

  const {
    MAIN_NET_ID,
    POLYGON_MAINNET_ID,
    ARBITRUM_MAINNET_ID,
    OPTIMISM_MAINNET_ID,
    AVALANCHE_MAINNET_ID,
    POLYGON_CHAIN,
    ETHEREUM_CHAIN,
    ARBITRUM_CHAIN,
    OPTIMISM_CHAIN,
    AVALANCHE_CHAIN,
  } = NETWORK_CONSTANTS;

  if (supportedNetworks.includes(newChain)) {
    if (
      isLoggedIn &&
      providerType === WEB3_PROVIDERS.METAMASK &&
      window.ethereum &&
      currentChain !== newChain
    ) {
      if (newChain === ETHEREUM_CHAIN && currentChain !== ETHEREUM_CHAIN) {
        await dispatch(metamaskSwitchChains(newChain, redirectTo, MAIN_NET_ID, account));
      } else if (newChain === POLYGON_CHAIN && currentChain !== POLYGON_CHAIN) {
        await dispatch(metamaskSwitchChains(newChain, redirectTo, POLYGON_MAINNET_ID, account));
      } else if (newChain === ARBITRUM_CHAIN && currentChain !== ARBITRUM_CHAIN) {
        await dispatch(metamaskSwitchChains(newChain, redirectTo, ARBITRUM_MAINNET_ID, account));
      } else if (newChain === OPTIMISM_CHAIN && currentChain !== OPTIMISM_CHAIN) {
        await dispatch(metamaskSwitchChains(newChain, redirectTo, OPTIMISM_MAINNET_ID, account));
      } else if (newChain === AVALANCHE_CHAIN && currentChain !== AVALANCHE_CHAIN) {
        await dispatch(metamaskSwitchChains(newChain, redirectTo, AVALANCHE_MAINNET_ID, account));
      }
    } else if (currentChain !== newChain) {
      // Log out for now and switch chains. Later can make it so this triggers network ID change for MetaMask
      toast(`Switching over to ${_.upperFirst(newChain)} chain...`);
      setTimeout(async () => {
        await dispatch(logOut());
        await dispatch(appLoading());
        await dispatch(setCurrentChain(newChain));
        setTimeout(() => {
          window.location.href = redirectTo ? redirectTo : '/explore';
          window.location.reload();
        }, 500);
      }, 1000);
    } else {
      window.location.href = redirectTo ? redirectTo : '/explore';
      window.location.reload();
    }
  }
};

export const handleActionAfterLogin = () => async (dispatch: Function, getState: Function) => {
  const state = getState();
  const afterLoginAction = afterLoginActionSelector(state);
  const currentSetDetailsAddress = setDetailsCurrentSetAddressSelector(state);
  const customSetPathPrefix = customSetPathPrefixSelector(state);

  dispatch(clearAfterLoginAction());

  switch (afterLoginAction) {
    case GO_TO_NEXT_ONBOARDING_SLIDE:
      dispatch(goToNextOnboardingSlide());
      break;
    case GO_TO_ACCOUNT_PAGE:
      routerHistory.push('/account');
      break;
    case GO_TO_REBALANCE_NOTIFICATIONS_SETTINGS:
      routerHistory.push('/rebalance-notifications');
      break;
    case GO_TO_CUSTOM_SET_AND_PORTFOLIO_ISSUANCE_PAGE:
      routerHistory.push(`${customSetPathPrefix}/${currentSetDetailsAddress}/issue`);
      break;
    case GO_TO_CUSTOM_SET_AND_PORTFOLIO_REDEMPTION_PAGE:
      routerHistory.push(`${customSetPathPrefix}/${currentSetDetailsAddress}/redeem`);
      break;

    default:
      break;
  }
};

export const receiveAccount = (account: string) => async (dispatch: Function) => {
  dispatch(updateAccount(account));

  dispatch(handleActionAfterLogin());
};

/**
 * Logs user out of Web3. Logs the user out of Fortmatic, and flushes the store of Fortmatic and MetaMask
 * account data when user logs out.
 *
 * @returns {boolean}
 */
export const logOut = () => async (dispatch: Function, getState: Function) => {
  const {
    web3: { providerType, isLoggedIn },
  } = getState();
  if (providerType === WEB3_PROVIDERS.FORTMATIC_PHONE) {
    fm.user.logout();
  } else if (providerType === WEB3_PROVIDERS.FORTMATIC_EMAIL) {
    magic.user.logout();
  }
  if (isLoggedIn) {
    toast(i18n.t('components:toasts.standard.logged-out'));
  }
  dispatch(closeChainSwitchModal());
  dispatch(updateAccountBalanceAction('0'));
  dispatch(logOutAction());
};

/**
 * Checks if the user is logged into Fortmatic or MetaMask.
 *
 * @returns {boolean}
 */
export const checkLoginStatus = () => async (dispatch: Function, getState: Function) => {
  const {
    web3: { providerType },
  } = getState();
  const web3Instance = await getWeb3Instance();

  if (providerType === WEB3_PROVIDERS.FORTMATIC_PHONE) {
    const isLoggedIn = await fm.user.isLoggedIn();
    dispatch(updateLoginStatus(isLoggedIn));
    if (isLoggedIn) {
      const account = await getAccount(web3Instance);
      dispatch(receiveAccount(account));
    }
    return;
  } else if (providerType === WEB3_PROVIDERS.FORTMATIC_EMAIL) {
    const isLoggedIn = await magic.user.isLoggedIn();
    dispatch(updateLoginStatus(isLoggedIn));
    if (isLoggedIn) {
      const account = await getAccount(web3Instance);
      dispatch(receiveAccount(account));
    }
    return;
  }
  if (web3Instance) {
    try {
      const account = await getAccount(web3Instance);
      if (account) {
        dispatch(updateLoginStatus(true));
        dispatch(receiveAccount(account));
      } else {
        dispatch(updateLoginStatus(false));
      }
      return;
    } catch (error) {
      // User denied account access
      console.log(`Error connecting with ${providerType}: `, error);
      dispatch(updateLoginStatus(false));
    }
  }

  dispatch(updateLoginStatus(false));
};

/**
 * Accepts Terms of Service for logged in User
 */
export const acceptTermsOfService = () => async (dispatch: Function) => {
  try {
    dispatch(setTermsOfServiceConfirmed(true));

    toast(i18n.t('components:toasts.standard.accepted-terms-of-service'));

    return true;
  } catch (error) {
    if (!error) return;

    toast(i18n.t('components:toasts.errors.accept-terms-of-service-failed'));

    return false;
  }
};

/**
 * Handles login action for the user depending on if their browser is a mobile web3 wallet
 * or a web3 wallet on a desktop browser.
 */
export const handleLogin = (nextAction: string) => async (
  dispatch: Function,
  getState: Function,
) => {
  const {
    windowDimension: { isMobile },
    web3: { account },
  } = getState();

  // If mobile web3 wallet
  if (isMobile && (window.ethereum || window.web3)) {
    dispatch(setAfterLoginAction(nextAction));

    if (!account) {
      await login();
    }
    if (nextAction) {
      dispatch(handleActionAfterLogin());
      return;
    }
    // If desktop
  } else {
    dispatch(openLoginModal(nextAction));
  }
};
