import React, { PureComponent, Fragment } from 'react';
import { css } from 'aphrodite';

interface IHTMLSectionsProps {
  text: string;
  customStyles: any;
}

export default class HTMLSections extends PureComponent<IHTMLSectionsProps> {
  public render() {
    const { text, customStyles } = this.props;

    // Remove carriage returns so we can do empty string comparison below
    const cleanedText = text.replace(/\r/g, '');
    const textSections = cleanedText.split('\n');

    return (
      <Fragment>
        {textSections.map((section, i) => {
          if (section === '') {
            return <br key={i} />;
          }
          return (
            <p key={i} className={css(customStyles)}>
              {section}
            </p>
          );
        })}
      </Fragment>
    );
  }
}
