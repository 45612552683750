import { connect } from 'react-redux';
import DebtIssuanceFormWrapper from './DebtIssuanceFormWrapper';
import { customV2SetPathPrefixSelector, transactionIdSelector } from '../../selectors/index';
import { resetTransactionWatcher } from '../../actions/transactionWatcherActions';
import { currentSetDetailsSelector } from '../../selectors/setDetailsSelectors';

const mapStateToProps = (state: any) => {
  const customV2SetPathPrefix = customV2SetPathPrefixSelector(state);
  const currentTransactionId = transactionIdSelector(state);
  const currentSet = currentSetDetailsSelector(state);

  return {
    customV2SetPathPrefix,
    name: currentSet?.name,
    symbol: currentSet?.symbol,
    currentTransactionId,
  };
};

const mapDispatchToProps = {
  resetTransactionWatcher,
};

export default connect(mapStateToProps, mapDispatchToProps)(DebtIssuanceFormWrapper);
