import { readOnlySetJSInstanceSelector } from '../selectors/baseSelectors';
import { payloadActionGenerator } from '../utils/index';
import { toast } from 'react-toastify';
import { transactionCallerSelector } from '../selectors/transactionSelectors';
import { PerpLeverageData } from '../typings/index';

export const SET_FETCHING_PERP_LEVERAGE_DATA = 'SET_FETCHING_PERP_LEVERAGE_DATA';
export const RECEIVE_COLLATERAL_TOKEN = 'RECEIVE_COLLATERAL_TOKEN';
export const RECEIVE_TOTAL_COLLATERAL_UNITS = 'RECEIVE_TOTAL_COLLATERAL_UNITS';
export const RECEIVE_POSITION_UNIT_INFO = 'RECEIVE_POSITION_UNIT_INFO';
export const RECEIVE_ACCOUNT_INFO = 'RECEIVE_ACCOUNT_INFO';
export const RECEIVE_VIRTUAL_ASSET_DISPLAY_INFOS = 'RECEIVE_VIRTUAL_ASSET_DISPLAY_INFOS';

export const setFetchingPerpLeverageData = payloadActionGenerator(SET_FETCHING_PERP_LEVERAGE_DATA);
export const receiveCollateralToken = payloadActionGenerator(RECEIVE_COLLATERAL_TOKEN);
export const receiveTotalCollateralUnits = payloadActionGenerator(RECEIVE_TOTAL_COLLATERAL_UNITS);
export const receivePositionUnitInfo = payloadActionGenerator(RECEIVE_POSITION_UNIT_INFO);
export const receiveAccountInfo = payloadActionGenerator(RECEIVE_ACCOUNT_INFO);
export const receiveVirtualAssetDisplayInfos = payloadActionGenerator(
  RECEIVE_VIRTUAL_ASSET_DISPLAY_INFOS,
);

/**
 * Fetch all information relevant for perpetual leverage module information
 */
export const fetchAllPerpLeverageData = (checksummedAddress: string, useBasis: boolean) => async (
  dispatch: any,
  getState: any,
): Promise<PerpLeverageData> => {
  const state = getState();
  const readOnlySetJSInstance = readOnlySetJSInstanceSelector(state);
  const caller = transactionCallerSelector(state);

  dispatch(setFetchingPerpLeverageData(true));

  let collateralToken;
  try {
    if (useBasis) {
      collateralToken = await readOnlySetJSInstance.perpV2BasisTrading.getCollateralTokenAsync(
        caller,
      );
    } else {
      collateralToken = await readOnlySetJSInstance.perpV2Leverage.getCollateralTokenAsync(caller);
    }
    dispatch(receiveCollateralToken(collateralToken));
  } catch (e) {
    console.log(e);
    toast.warn('Unable to fetch perpetual collateral token info', {
      toastId: 'fetch-collateral-token',
    });
  }

  let totalCollateralUnitsWithToken;
  try {
    if (useBasis) {
      totalCollateralUnitsWithToken = await readOnlySetJSInstance.perpV2BasisTradingViewer.getTotalCollateralUnitAsync(
        checksummedAddress,
        caller,
      );
    } else {
      totalCollateralUnitsWithToken = await readOnlySetJSInstance.perpV2LeverageViewer.getTotalCollateralUnitAsync(
        checksummedAddress,
        caller,
      );
    }
    dispatch(receiveTotalCollateralUnits(totalCollateralUnitsWithToken));
  } catch (e) {
    console.log(e);
    toast.warn('Unable to fetch perpetual total collateral units', {
      toastId: 'fetch-total-collateral-units',
    });
  }

  let positionUnitInfos;
  try {
    if (useBasis) {
      positionUnitInfos = await readOnlySetJSInstance.perpV2BasisTrading.getPositionUnitInfoAsync(
        checksummedAddress,
        caller,
      );
    } else {
      positionUnitInfos = await readOnlySetJSInstance.perpV2Leverage.getPositionUnitInfoAsync(
        checksummedAddress,
        caller,
      );
    }
    dispatch(receivePositionUnitInfo(positionUnitInfos));
  } catch (e) {
    console.log(e);
    toast.warn('Unable to fetch perpetual position unit info', {
      toastId: 'fetch-position-unit-info',
    });
  }

  let vAssetDisplayInfos;
  try {
    if (useBasis) {
      vAssetDisplayInfos = await readOnlySetJSInstance.perpV2BasisTradingViewer.getVirtualAssetsDisplayInfoAsync(
        checksummedAddress,
        caller,
      );
    } else {
      vAssetDisplayInfos = await readOnlySetJSInstance.perpV2LeverageViewer.getVirtualAssetsDisplayInfoAsync(
        checksummedAddress,
        caller,
      );
    }
    dispatch(receiveVirtualAssetDisplayInfos(vAssetDisplayInfos));
  } catch (e) {
    console.log(e);
    toast.warn('Unable to fetch virtual asset display info', {
      toastId: 'fetch-virtual-asset-info',
    });
  }

  let accountInfo;
  try {
    if (useBasis) {
      accountInfo = await readOnlySetJSInstance.perpV2BasisTrading.getAccountInfoAsync(
        checksummedAddress,
        caller,
      );
    } else {
      accountInfo = await readOnlySetJSInstance.perpV2Leverage.getAccountInfoAsync(
        checksummedAddress,
        caller,
      );
    }
    dispatch(receiveAccountInfo(accountInfo));
  } catch (e) {
    console.log(e);
    toast.warn('Unable to fetch perpetual account info', { toastId: 'fetch-account-info' });
  }

  dispatch(setFetchingPerpLeverageData(false));

  return {
    collateralToken,
    totalCollateralUnitsWithToken,
    positionUnitInfos,
    vAssetDisplayInfos,
    accountInfo,
  };
};
