import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { WINDOW_DIMENSIONS, COLORS } from '../../constants/index';

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  Container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  GrayBar: {
    height: '10px',
    backgroundColor: COLORS.blueGray2,
    borderRadius: '5px ',
    display: 'flex',
    [MOBILE_MEDIA_QUERY]: {
      width: '75%',
    },
  },
  BlueBar: {
    backgroundColor: COLORS.royalBlue,
    borderRadius: '5px ',
  },
});

interface ProgressTrackerProps {
  width?: string;
  progressPercent: number;
  style?: any;
}

export default function ProgressTracker({
  width = '40vw',
  style,
  progressPercent,
}: ProgressTrackerProps) {
  return (
    <div style={style} className={css(styles.Container)}>
      <div style={{ width }} className={css(styles.GrayBar)}>
        <span style={{ width: `${progressPercent}%` }} className={css(styles.BlueBar)} />
      </div>
    </div>
  );
}
