import { toast } from 'react-toastify';

import { utils, BigNumber as EthersBigNumber } from 'ethers';
import { submitSetJSTransaction } from '../actions/transactionWatcherActions';
import { batchFetchPerpIssuanceAllowances } from '../actions/approvalActions';
import {
  fetchPerpRedemptionComponents,
  receivePerpRedemptionComponents,
  receivePerpRedemptionFees,
  requestPerpRedemptionFees,
  setIsSubmittingRedemptionTransaction,
  updateRedemptionQuantity,
} from '../actions/redemptionV2Actions';
import {
  setJSInstanceSelector,
  setDetailsCurrentSetAddressSelector,
  redemptionInputQuantityV2Selector,
} from '../selectors/baseSelectors';
import {
  allPerpComponentsSelector,
  createPerpIssueRedeemTransactionArgs,
  maxPerpRedeemableTokenQuantitySelector,
} from '../selectors/perpIssuanceRedemptionSelectors';
import { currentSetDetailsAsListTokenSelector } from '../selectors/setDetailsSelectors';
import { BigNumber, userRejectedMetamaskTransaction } from '../utils/index';
import { IDebtComponents, IFeeResponse } from '../typings/index';
import { transactionCallerSelector } from '../selectors/transactionSelectors';
import { TYPE_REDEEM } from '../constants/index';

export const getPerpRedemptionRequiredComponents = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const setJSInstance = setJSInstanceSelector(state);
  const caller = transactionCallerSelector(state);
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);
  const redemptionQuantity = EthersBigNumber.from(10).pow(18);

  try {
    dispatch(fetchPerpRedemptionComponents());
    const requiredComponents = await setJSInstance.slippageIssuance.getRequiredComponentRedemptionUnits(
      currentSetAddress,
      redemptionQuantity,
      caller,
    );

    const componentAddresses = requiredComponents?.[0];
    const equityValues = requiredComponents?.[1];
    const debtValues = requiredComponents?.[2];
    const perpComponents: IDebtComponents = {
      componentAddresses,
      equityValues,
      debtValues,
    };
    dispatch(receivePerpRedemptionComponents(perpComponents));
  } catch (e) {
    toast.warn('Could not fetch required perp redemption components', {
      toastId: 'fetch-perp-redemption-components',
    });
    console.log('Could not fetch required perp redemption components', e);
    dispatch(receivePerpRedemptionComponents({}));
  }
};

/* Fetch token allowances for perp redemption tokens */
export const fetchPerpRedemptionAllowancesForCurrentSet = () => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const currentSet: any = currentSetDetailsAsListTokenSelector(state);
  const redemptionComponents = allPerpComponentsSelector(TYPE_REDEEM, state);

  dispatch(batchFetchPerpIssuanceAllowances(redemptionComponents.concat(currentSet)));
};

export const perpRedeemCurrentSet = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const setJSInstance = setJSInstanceSelector(state);

  try {
    const perpRedeemTransactionArguments = await createPerpIssueRedeemTransactionArgs(
      TYPE_REDEEM,
      state,
    );

    if (!perpRedeemTransactionArguments) return;

    const perpRedemptionRequest = submitSetJSTransaction(() => {
      return setJSInstance.slippageIssuance.redeemWithSlippageAsync(
        ...perpRedeemTransactionArguments,
      );
    });

    dispatch(setIsSubmittingRedemptionTransaction(true));
    dispatch(perpRedemptionRequest);
  } catch (e) {
    console.log(e);

    if (userRejectedMetamaskTransaction(e)) {
      toast.error(`RPC Error: ${e.message}`, {
        toastId: 'redemption-request',
      });
      return;
    }

    toast.error('Something went wrong with your redemption request. Please try again later.', {
      toastId: 'redemption-request',
    });
  } finally {
    dispatch(setIsSubmittingRedemptionTransaction(false));
  }
};

/**
 * Set the max issuable quantity the user can mint of the currently shown Fund.
 */
export const setMaxPerpRedeemableQuantity = () => (dispatch: any, getState: any) => {
  const state = getState();
  const maxRedeemableQuantity = maxPerpRedeemableTokenQuantitySelector(state);

  dispatch(updateRedemptionQuantity(maxRedeemableQuantity || '0'));
  dispatch(fetchFeesForRedeemQuantity());
};

/**
 * Fetch the fees for the given set redemption quantity
 */
export const fetchFeesForRedeemQuantity = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const setJSInstance = setJSInstanceSelector(state);
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);
  const rawRedemptionQuantity = redemptionInputQuantityV2Selector(state);
  const formattedRedemptionQuantity = utils.parseEther(
    rawRedemptionQuantity?.length ? rawRedemptionQuantity : '0',
  );

  dispatch(requestPerpRedemptionFees());

  try {
    const totalFees: IFeeResponse = await setJSInstance.slippageIssuance.calculateTotalFeesAsync(
      currentSetAddress,
      formattedRedemptionQuantity,
      false,
    );

    dispatch(
      receivePerpRedemptionFees({
        totalQuantity: totalFees.totalQuantity,
        managerFee: totalFees.managerFee,
        managerFeePercentage: new BigNumber(totalFees.managerFee.toString() || 0)
          .div(formattedRedemptionQuantity.toString())
          .mul(100)
          .toString(),
        protocolFee: totalFees.protocolFee,
        protocolFeePercentage: new BigNumber(totalFees.protocolFee.toString() || 0)
          .div(formattedRedemptionQuantity.toString())
          .mul(100)
          .toString(),
      }),
    );

    return totalFees;
  } catch (e) {
    console.log(e);
    toast.warn('Something went wrong with fetching fee values. Please try again later.', {
      toastId: 'fee-request',
    });
  }
};
