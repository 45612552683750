import { FETCH_TOKEN_COLORS, RECEIVE_TOKEN_COLORS } from '../actions/tokenColorsActions';

interface ITokenColorsState {
  isFetching: boolean;
  tokenColors: { [tokenAddress: string]: string };
}

const initialState: ITokenColorsState = {
  isFetching: false,
  tokenColors: {},
};

const tokenListsReducer = (state = initialState, action: any): ITokenColorsState => {
  switch (action.type) {
    case FETCH_TOKEN_COLORS:
      return {
        ...state,
        isFetching: true,
      };

    case RECEIVE_TOKEN_COLORS:
      return {
        isFetching: false,
        tokenColors: {
          ...state.tokenColors,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};

export default tokenListsReducer;
