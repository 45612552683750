import { connect } from 'react-redux';
import IssuanceForm from './IssuanceForm';
import {
  updateIssuanceQuantity,
  setMaxIssuableQuantity,
  issueCurrentSet,
} from '../../actions/issuanceV2Actions';
import { resetTransactionWatcher } from '../../actions/transactionWatcherActions';
import {
  currentSetComponentsSelector,
  currentSetDetailsSelector,
} from '../../selectors/setDetailsSelectors';
import { userHasSufficientFundsForIssuanceQuantity } from '../../selectors/issuanceV2Selectors';
import {
  isSubmittingIssuanceTransactionV2Selector,
  issuanceInputQuantityV2Selector,
  erc20BalancesSelector,
  transactionIdSelector,
  windowDimensionSelector,
  customV2SetPathPrefixSelector,
  setDetailsCurrentSetAddressSelector,
} from '../../selectors/index';

const mapStateToProps = (state: any) => {
  const isIssuancePermitted = userHasSufficientFundsForIssuanceQuantity(state);
  const isSubmittingIssuanceTransaction = isSubmittingIssuanceTransactionV2Selector(state);
  const issuanceQuantity = issuanceInputQuantityV2Selector(state);
  const requiredComponents = currentSetComponentsSelector(state);
  const erc20BalancesByAddress = erc20BalancesSelector(state);
  const currentTransactionId = transactionIdSelector(state);
  const windowDimensions = windowDimensionSelector(state);
  const setDetails = currentSetDetailsSelector(state);
  const customV2SetPathPrefix = customV2SetPathPrefixSelector(state);
  const setAddress = setDetailsCurrentSetAddressSelector(state);

  return {
    setAddress,
    customV2SetPathPrefix,
    setDetails,
    isIssuancePermitted,
    issuanceQuantity,
    requiredComponents,
    erc20BalancesByAddress,
    currentTransactionId,
    isSubmittingIssuanceTransaction,
    windowDimensions,
  };
};

const mapDispatchToProps = {
  updateIssuanceQuantity,
  setMaxIssuableQuantity,
  issueCurrentSet,
  resetTransactionWatcher,
};

export default connect(mapStateToProps, mapDispatchToProps)(IssuanceForm);
