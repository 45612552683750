import { combineReducers } from 'redux';
import { TableDataSource, tableDisplayQuantityByDataSource } from './enums';

import defaultReducer from './defaultTableReducer';
import portfoliosReducer from './portfoliosTableReducer';
import customSetsReducer from './customSetsTableReducer';

const combinedReducers = combineReducers({
  [TableDataSource.default]: defaultReducer,
  [TableDataSource.portfolios]: portfoliosReducer,
  [TableDataSource.customSets]: customSetsReducer,
});

export { combinedReducers as default, TableDataSource, tableDisplayQuantityByDataSource };
