import React from 'react';

import { COLORS } from '../../constants/index';

interface IBundleIconProps {
  readonly className?: string;
  readonly color?: string;
}

const BundleIcon: React.SFC<IBundleIconProps> = ({ className, color }) => (
  <svg
    className={className}
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <desc>Set Labs. Bundle Icon.</desc>
    <g id="Detail-/-Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-8" transform="translate(-40.792969, -45.000000)">
        <g id="Group" transform="translate(21.539062, 24.031250)">
          <g id="Group-6" transform="translate(20.425781, 20.929688)">
            <g id="Page-1" transform="translate(1.645508, 0.500000)" fill={color}>
              <polygon
                id="Fill-1"
                points="5.0004 3.3912 5.0004 8.5542 9.94049219 13.414 11.3544922 12 7.0004 7.7262 7.0004 3.3912"
              />
              <polygon
                id="Fill-2"
                points="0.940492188 11.586 2.35449219 13 6.854 8.7009 5.44 7.2869"
              />
              <path
                d="M6.9998,3.4139 L8.8288,5.2429 L10.2428,3.8279 L6.7068,0.2929 C6.3168,-0.0971 5.6828,-0.0971 5.2928,0.2929 L1.7578,3.8279 L3.1718,5.2429 L4.9998,3.4139"
                id="Fill-3"
              />
            </g>
            <g
              id="Group-3"
              transform="translate(0.000000, 11.500000)"
              stroke={color}
              strokeWidth="2"
            >
              <circle id="Oval-2" cx="13" cy="3" r="2.16748047" />
              <circle id="Oval-2-Copy" cx="2.36743164" cy="3" r="2.16748047" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

BundleIcon.defaultProps = {
  className: '',
  color: COLORS.white,
};

export default BundleIcon;
