import { ApolloClient, InMemoryCache } from '@apollo/client';

export const setProtocolV2ApolloClient = new ApolloClient({
  uri:
    'https://api.thegraph.com/subgraphs/name/justinkchen/set-protocol-v2',
  cache: new InMemoryCache(),
});

export const setProtocolV2PolygonApolloClient = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/justinkchen/set-protocol-v2-matic',
  cache: new InMemoryCache(),
});

export const setProtocolV2ArbitrumApolloClient = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/justinkchen/set-protocol-v2-arbitrum',
  cache: new InMemoryCache(),
});

export const setProtocolV2OptimismApolloClient = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/justinkchen/set-protocol-v2-optimism',
  cache: new InMemoryCache(),
});

export const setProtocolV2AvalancheApolloClient = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/justinkchen/set-protocol-v2-avalanche',
  cache: new InMemoryCache(),
});
