import { StyleSheet } from 'aphrodite';

import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';

const {
  MOBILE_MEDIA_QUERY,
  TABLET_MEDIA_QUERY,
  TABLET_LANDSCAPE_MEDIA_QUERY,
  DESKTOP_MEDIA_QUERY,
} = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  /* ------------- CONTAINERS ------------- */
  Home_smContainer: {
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  Home_link: {
    color: COLORS.blue,
  },
  Home_iconRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  Home_rebalancingSetTable: {
    marginBottom: '64px',
  },
  Home_boxContainer: {
    overflow: 'hidden',
    padding: '28px',
    position: 'relative',
    borderRadius: '4px',
    boxShadow: `0 6px 12px 0 ${COLORS.lightBlueAlpha25}`,
    height: '110%',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      boxShadow: `0 6px 8px 0 ${COLORS.lightBlueAlpha25}`,
      height: '100%',
      marginBottom: '10px',
    },
  },
  Home_buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '25px',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: '15px',
    },
  },
  Home_checkmarkContainer: {
    background: COLORS.green,
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '15px',
    width: '15px',
  },
  /* ------------- PADDING ------------- */
  Home_paddingTopLarge: {
    paddingTop: '80px',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      paddingTop: '30px',
    },
  },
  Home_paddingBottomXLarge: {
    paddingBottom: '150px',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      paddingBottom: '90px',
    },
  },
  Home_paddingBottomLarge: {
    paddingBottom: '80px',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      paddingBottom: '70px',
    },
  },
  Home_paddingBottomMed: {
    paddingBottom: '50px',
  },
  Home_paddingBottomNone: {
    paddingBottom: '0',
  },
  Home_paddingRightSmall: {
    paddingRight: '50px',
  },
  /* ------------- MARGIN ------------- */
  Home_marginBottomXsmall: {
    marginBottom: '5px',
  },
  Home_marginBottomSmall: {
    marginBottom: '15px',
  },
  Home_marginBottomMedium: {
    marginBottom: '30px',
  },
  Home_heroMargins: {
    marginTop: '15px',
    marginBottom: '45px',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      marginTop: '46px',
      marginBottom: '34px',
    },
  },
  /* ------------- BORDERS ------------- */
  Home_borderBlueBottom: {
    borderBottom: `7px solid ${COLORS.blueAlpha25}`,
  },
  /* ------------- HEADERS ------------- */
  Home_header: {
    fontSize: '84px',
    fontWeight: 600,
    lineHeight: '88px',
    [TABLET_MEDIA_QUERY]: {
      fontSize: '36px',
      lineHeight: '36px',
    },
  },
  Home_secondaryHeader: {
    fontSize: '34px',
    fontWeight: 400,
    lineHeight: '40px',
    marginBottom: '10px',
    [TABLET_MEDIA_QUERY]: {
      fontSize: '24px',
      lineHeight: '26px',
      marginBottom: '4px',
    },
  },
  Home_heroHeader: {
    width: '100%',
  },
  Home_heroSubHeader: {
    color: COLORS.darkGray,
    lineHeight: '1.65',
    fontSize: '24px',
    width: '100%',
    marginTop: '20px',
    [TABLET_MEDIA_QUERY]: {
      fontSize: '18px',
      lineHeight: '1.45',
      marginTop: '10px',
      marginBottom: '25px',
    },
  },
  Home_benefitHeader: {
    marginTop: '25px',
    marginBottom: '25px',
    fontSize: '24px',
  },
  Home_sectionSubHeader: {
    fontSize: '38px',
    fontWeight: 400,
    [TABLET_MEDIA_QUERY]: {
      fontSize: '24px',
    },
  },
  Home_sectionHeader: {
    display: 'inline-block',
    fontSize: '54px',
    lineHeight: '60px',
    marginBottom: '18px',
    paddingBottom: '5px',
    width: '100%',
    [TABLET_MEDIA_QUERY]: {
      fontSize: '38px',
      lineHeight: '50px',
    },
  },
  Home_setTableHeader: {
    display: 'inline-block',
    fontSize: '26px',
    fontWeight: 500,
    [TABLET_MEDIA_QUERY]: {
      fontSize: '20px',
    },
  },
  Home_columnHeader: {
    fontSize: '24px',
    marginBottom: '20px',
  },
  Home_columnSubHeader: {
    color: COLORS.lightBlue,
    fontSize: '16px',
    marginBottom: '5px',
  },
  Home_stepInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    padding: '12px',
  },
  Home_stepIcon: {
    marginBottom: '10px',
  },
  Home_stepDescription: {
    color: COLORS.darkBlue,
    fontSize: '18px',
    lineHeight: '1.6',
    marginBottom: '20px',
  },
  Home_stepCta: {
    fontWeight: 600,
  },
  /* ------------- TEXT ------------- */
  Home_textAlignLeft: {
    textAlign: 'left',
  },
  Home_comingSoon: {
    color: COLORS.blue,
    backgroundColor: COLORS.blueAlpha25,
    borderRadius: '2px',
    padding: '4px 6px',
    marginLeft: '15px',
    fontWeight: 600,
  },
  Home_sectionDescription: {
    fontSize: '24px',
    fontWeight: 400,
    [TABLET_MEDIA_QUERY]: {
      fontSize: '20px',
    },
  },
  /* ------------- BUTTON ------------- */
  Home_button: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    boxShadow: `0 6px 12px 0 ${COLORS.lightBlueAlpha25}`,
    color: COLORS.white,
    cursor: 'pointer',
    padding: '20px 32px',
    background: `linear-gradient(90deg, ${COLORS.blue}, ${COLORS.lightBlue3})`,
    borderRadius: '40px',
    border: '0 solid transparent',
    fontWeight: 500,
    transition: '0.2s',
    width: '190px',
    ':hover': {
      transform: 'scale(1.03)',
      boxShadow: `0 6px 18px 0 ${COLORS.lightBlueAlpha25}`,
    },
    ':active': {
      transform: 'scale(1.01)',
      boxShadow: `0 3px 3px 0 ${COLORS.lightBlueAlpha25}`,
    },
  },
  Home_buttonSecondary: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    boxShadow: `0 6px 12px 0 ${COLORS.lightBlueAlpha25}`,
    color: COLORS.white,
    cursor: 'pointer',
    padding: '20px 32px',
    background: COLORS.darkBlue,
    borderRadius: '30px',
    border: '0 solid transparent',
    fontWeight: 500,
    marginLeft: '20px',
    transition: '0.2s',
    width: '190px',
    ':hover': {
      transform: 'scale(1.03)',
      boxShadow: `0 6px 18px 0 ${COLORS.lightBlueAlpha25}`,
    },
    ':active': {
      transform: 'scale(1.01)',
      boxShadow: `0 3px 3px 0 ${COLORS.lightBlueAlpha25}`,
    },
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      marginLeft: '0',
      marginTop: '20px',
    },
  },
  Home_mainCtaButton: {
    fontSize: '20px',
    width: '296px',
    height: '70px',
    justifyContent: 'center',
  },
  Home_wideButton: {
    width: '250px',
    fontSize: '20px',
    justifyContent: 'center',
  },
  Home_extraWideButton: {
    width: '280px',
    justifyContent: 'center',
  },
  /* ------------- STICKY CTA ------------- */
  Home_stickyCtaButtonOuterContainer: {
    background: COLORS.white,
    borderBottom: `1px solid ${COLORS.gray}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    transition: '0.2s',
    zIndex: 990,
    height: '80px',
    top: '-120px',
    width: '100%',
    marginLeft: '0',
    marginRight: '0',
    opacity: 0,
    [TABLET_MEDIA_QUERY]: {
      height: '64px',
      opacity: 1,
    },
  },
  Home_stickyCtaButtonRevealed: {
    [TABLET_MEDIA_QUERY]: {
      top: '52px',
    },
  },
  Home_stickyCtaButton: {
    padding: '14px 22px',
    width: '150px',
  },
  /* ------------- IMAGES ------------- */
  Home_benefitImage: {
    height: '3.5rem',
  },
  Home_downArrowContainer: {
    marginTop: '45px',
  },
  Home_startInMinutesImg1: {
    height: '100px',
    width: 'auto',
  },
  Home_sectionImage: {
    [TABLET_MEDIA_QUERY]: {
      width: '90%',
      maxWidth: '400px',
    },
  },
  /* ------------- HOMEBANNER ------------- */
  HomeBanner_container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '45px 20px',
    marginTop: '0px',
    position: 'relative',
    backgroundColor: COLORS.lightGray,
    [MOBILE_MEDIA_QUERY]: {
      marginTop: '0px',
      borderRadius: '4px',
      padding: '20px',
    },
  },
  HomeBanner_newIcon: {
    width: 'fit-content',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '15px',
    padding: '6px 15px',
    marginBottom: '10px',
    fontSize: '18px',
    color: COLORS.blue,
    border: `1px solid ${COLORS.blue}`,
  },
  HomeBanner_bannerText: {
    fontSize: '38px',
    lineHeight: '40px',
    marginBottom: '8px',
    fontWeight: 500,
    position: 'relative',
    zIndex: 5,
    borderRadius: '4px',
    boxShadow: `0 0 5px 5px ${COLORS.lightGray}`,
    backgroundColor: COLORS.lightGray,
    [TABLET_MEDIA_QUERY]: {
      boxShadow: 'none',
      fontSize: '24px',
      lineHeight: '30px',
      marginBottom: '4px',
    },
  },
  HomeBanner_bannerSubText: {
    color: COLORS.black,
    marginBottom: '10px',
    position: 'relative',
    zIndex: 5,
    backgroundColor: COLORS.lightGray,
    borderRadius: '4px',
    boxShadow: `0 0 5px 5px ${COLORS.lightGray}`,
    [TABLET_MEDIA_QUERY]: {
      boxShadow: 'none',
    },
  },
  HomeBanner_traderCTA: {
    marginBottom: '10px',
  },
  HomeBanner_leftImage: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
  },
  HomeBanner_rightImage: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
  },
  /* ------------- SOCIAL TRADER CTA ------------- */
  HomeSocialTrader_container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [TABLET_MEDIA_QUERY]: {
      flexDirection: 'column',
    },
  },
  HomeSocialTrader_bodyContainer: {
    flex: 1,
    [TABLET_MEDIA_QUERY]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      textAlign: 'center',
      marginBottom: '40px',
    },
  },
  HomeSocialTrader_imageContainer: {
    flex: 1,
    paddingLeft: '40px',
    [TABLET_MEDIA_QUERY]: {
      width: '70%',
      paddingLeft: '0px',
    },
    [MOBILE_MEDIA_QUERY]: {
      width: '95%',
      marginBottom: '20px',
    },
  },
  HomeSocialTrader_image: {
    width: '100%',
  },
  HomeSocialTrader_titleText: {
    fontSize: '45px',
    fontWeight: 600,
    marginBottom: '30px',
  },
  HomeSocialTrader_subtitleText: {
    fontSize: '20px',
    lineHeight: 1.6,
    marginBottom: '20px',
    [MOBILE_MEDIA_QUERY]: {
      textAlign: 'left',
    },
  },
  HomeSocialTrader_buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [MOBILE_MEDIA_QUERY]: {
      flexDirection: 'column',
    },
  },
  HomeSocialTrader_exploreButton: {
    width: 'fit-content',
    marginRight: '40px',
    [MOBILE_MEDIA_QUERY]: {
      marginRight: '0px',
      marginBottom: '15px',
    },
  },
  /* ------------- SETEXPLANATIONSECTION ------------- */
  HomeSetExplanation_mobileContainer: {
    marginBottom: '70px',
    textAlign: 'center',
  },
  HomeSetExplanation_outerContainer: {
    backgroundColor: COLORS.darkBlue,
    position: 'relative',
    height: '810px',
    marginBottom: '150px',
  },
  HomeSetExplanation_innerContainer: {
    position: 'relative',
    height: '100%',
  },
  HomeSetExplanation_leftSide: {
    display: 'inline-block',
    width: '50%',
    position: 'relative',
    height: '100%',
  },
  HomeSetExplanation_rightSide: {
    position: 'absolute',
    marginLeft: '180px',
    display: 'inline-block',
    width: '45%',
    height: '100%',
    paddingTop: '170px',
    [DESKTOP_MEDIA_QUERY]: {
      marginLeft: '40px',
    },
  },
  HomeSetExplanation_leftTopContainer: {
    position: 'absolute',
    backgroundColor: COLORS.white,
    height: '67%',
    width: '50%',
  },
  HomeSetExplanation_rightPanel: {
    position: 'absolute',
    width: '50%',
    height: '100%',
    backgroundColor: COLORS.lightGray,
    right: '0',
  },
  HomeSetExplanation_header1: {
    fontSize: '30px',
    letterSpacing: '-0.3px',
    lineHeight: '30px',
    color: COLORS.darkBlue,
    marginTop: '130px',
    [MOBILE_MEDIA_QUERY]: {
      marginTop: '20px',
      fontSize: '28px',
    },
  },
  HomeSetExplanation_header2: {
    fontSize: '70px',
    fontWeight: 600,
    letterSpacing: '-0.3px',
    lineHeight: '70px',
    color: COLORS.darkBlue,
    [MOBILE_MEDIA_QUERY]: {
      fontSize: '48px',
    },
  },
  HomeSetExplanation_title: {
    marginBottom: '84px',
    [MOBILE_MEDIA_QUERY]: {
      marginBottom: '64px',
    },
  },
  HomeSetExplanation_bars: {
    position: 'absolute',
    right: '10px',
    bottom: '-7px',
  },
  HomeSetExplanation_setRow: {
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      width: '50%',
    },
    [MOBILE_MEDIA_QUERY]: {
      marginTop: '20px',
      width: '75%',
    },
  },
  HomeSetExplanation_tokenBullet: {
    height: '50px',
    width: '50px',
  },
  HomeSetExplanation_bulletSection: {
    marginTop: '12px',
    marginBottom: '24px',
    [MOBILE_MEDIA_QUERY]: {
      marginBottom: '36px',
    },
  },
  HomSetExplanation_bulletIcon: {
    [MOBILE_MEDIA_QUERY]: {
      textAlign: 'center',
    },
  },
  HomeSetExplanation_bulletHeader: {
    color: COLORS.darkBlue,
    fontSize: '24px',
    lineHeight: '24px',
    letterSpacing: '-0.2px',
    fontWeight: 500,
    marginBottom: '10px',
    [MOBILE_MEDIA_QUERY]: {
      marginTop: '30px',
      textAlign: 'center',
    },
  },
  HomeSetExplanation_bulletText: {
    width: '360px',
    color: COLORS.darkGray,
    letterSpacing: '-0.15px',
    fontSize: '17px',
    lineHeight: '26px',
    marginTop: '18px',
    [DESKTOP_MEDIA_QUERY]: {
      margin: '10px auto 40px',
      width: '100%',
    },
    [TABLET_MEDIA_QUERY]: {
      width: '60%',
    },
    [MOBILE_MEDIA_QUERY]: {
      width: '100%',
    },
  },
  HomeSetExplanation_buttonContainer: {
    textAlign: 'center',
  },
  /* ------------- HOMEWHYSECTION ------------- */
  HomeWhySection_token: {
    height: '46px',
    width: 'auto',
    position: 'absolute',
  },
  HomeWhySection_blueToken: {
    top: 0,
    left: '28px',
    transform: 'translateY(-50%)',
  },
  HomeWhySection_yellowToken: {
    top: '28px',
    right: 0,
    transform: 'translateX(50%) rotate(-90deg)',
  },
  HomeWhySection_redGreenToken: {
    bottom: 0,
    right: '28px',
    transform: 'translate(-50%, 50%)',
  },
  Home_checkmarkRow: {
    paddingTop: '7px',
  },
  /* ------------- HOMESTARTINMINUTESSECTION ------------- */
  HomeStartInMinutesSection_sectionHeader: {
    width: 'fit-content',
  },
  HomeStartInMinutesSection_imgContainer: {
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  HomeStartInMinutesSection_img1: {
    height: '95px',
  },
  HomeStartInMinutesSection_img2: {
    height: '80px',
  },
  HomeStartInMinutesSection_columnDivider: {
    borderBottom: `1px solid ${COLORS.darkGray}`,
    width: '100%',
  },
  HomeStartInMinutesSection_columnDividerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  /* ------------- HOMEEXPLORESECTION ------------- */
  HomeExploreSection_container: {
    display: 'flex',
    flexDirection: 'row',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      flexDirection: 'column',
    },
  },
  HomeExploreSection_buttonContainer: {
    border: `1px solid ${COLORS.gray}`,
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '50px',
    boxShadow: `0 6px 12px 0 ${COLORS.lightBlueAlpha25}`,
    padding: '30px 30px',
    transition: '0.2s',
    width: 'auto',
    ':hover': {
      boxShadow: `0 8px 16px 0 ${COLORS.lightBlueAlpha25}`,
      transform: 'scale(1.02)',
    },
    ':active': {
      boxShadow: `0 7px 14px 0 ${COLORS.lightBlueAlpha25}`,
      transform: 'scale(1.01)',
    },
    [TABLET_MEDIA_QUERY]: {
      width: '100%',
    },
  },
  HomeExploreSection_buttonIcon: {
    marginRight: '12px',
  },
  HomeExploreSection_buttonText: {
    color: COLORS.darkBlue,
    fontWeight: 500,
  },
  HomeExploreSection_buttonContentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  HomeExploreSection_buttonRightArrow: {
    marginLeft: '36px',
  },
  HomeExploreSection_exploreDescription: {
    color: COLORS.darkBlue,
    marginBottom: '25px',
  },
  HomeExploreSection_columnLeft: {
    paddingRight: '60px',
    paddingBottom: '50px',
    width: '50%',
    [TABLET_MEDIA_QUERY]: {
      paddingBottom: '100px',
      paddingRight: '0',
      width: '100%',
    },
  },
  HomeExploreSection_columnRight: {
    borderLeft: `1px solid ${COLORS.lightGray}`,
    paddingLeft: '60px',
    paddingBottom: '50px',
    width: '50%',
    [TABLET_MEDIA_QUERY]: {
      borderLeft: 'none',
      paddingLeft: '0',
      width: '100%',
    },
  },
  /* ------------- HOMESTRATEGIESNOW ---------- */
  HomeStrategiesNow_headerContainer: {
    textAlign: 'center',
  },
  HomeStrategiesNow_header1: {
    fontSize: '30px',
    letterSpacing: '-0.3px',
    lineHeight: '48px',
    color: COLORS.darkBlue,
    marginTop: '170px',
    [TABLET_MEDIA_QUERY]: {
      marginTop: '70px',
      fontSize: '24px',
      lineHeight: '18px',
      textAlign: 'center',
    },
  },
  HomeStrategiesNow_header2: {
    display: 'inline-block',
    fontSize: '70px',
    fontWeight: 600,
    letterSpacing: '-0.3px',
    lineHeight: '70px',
    color: COLORS.darkBlue,
    borderBottom: `10px solid ${COLORS.lightBlueAlpha25}`,
    marginBottom: '40px',
    [TABLET_MEDIA_QUERY]: {
      fontSize: '38px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  HomeStrategiesNow_strategyColumn: {
    width: '46% !important',
    borderRadius: '4px',
    overflow: 'hidden',
    marginRight: '15px',
    marginBottom: '15px',
    display: 'inline-block',
    height: '250px',
    boxShadow: `0 6px 12px 0 ${COLORS.lightBlueAlpha25}`,
    transition: '0.2s',
    [TABLET_MEDIA_QUERY]: {
      width: '100%',
      height: 'auto',
    },
    ':hover': {
      boxShadow: `0 8px 16px 0 ${COLORS.lightBlueAlpha25}`,
      transform: 'scale(1.02)',
    },
    ':active': {
      boxShadow: `0 7px 14px 0 ${COLORS.lightBlueAlpha25}`,
      transform: 'scale(1.01)',
    },
  },
  HomeStrategiesNow_strategyInfo: {
    width: '50%',
    display: 'inline-block',
    paddingLeft: '30px',
    paddingRight: '30px',
    verticalAlign: 'middle',
    [TABLET_MEDIA_QUERY]: {
      padding: '30px',
      width: '100%',
    },
  },
  HomeStrategiesNow_strategyTitle: {
    fontSize: '22px',
    fontWeight: 500,
    lineHeight: '24px',
    color: COLORS.darkBlue,
    marginBottom: '18px',
    [TABLET_MEDIA_QUERY]: {
      textAlign: 'center',
    },
  },
  HomeStrategiesNow_strategyDescription: {
    fontSize: '18px',
    lineHeight: '28px',
    color: COLORS.darkGray,
    [DESKTOP_MEDIA_QUERY]: {
      fontSize: '16px',
      lineHeight: '22px',
    },
  },
  HomeStrategiesNow_strategyIconContainer: {
    borderRadius: '8px',
    marginBottom: '20px',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'hidden',
    width: '50px',
    height: '50px',
  },
  HomeStrategiesNow_strategyIcon: {
    width: '50px',
    height: '50px',
  },
  HomeStrategiesNow_strategyImageContainer: {
    width: '50%',
    display: 'inline-block',
    verticalAlign: 'middle',
    height: '100%',
    [TABLET_MEDIA_QUERY]: {
      display: 'none',
    },
  },
  HomeStrategiesNow_strategyImage: {
    height: '100%',
    width: '100%',
  },
  /* ------------- HOMEONETOKENSSECTION ------------- */
  HomeOneTokenSection_textContainer: {
    [TABLET_MEDIA_QUERY]: {
      paddingBottom: '50px',
    },
  },
  HomeOneTokenSection_imageContainer: {
    [TABLET_MEDIA_QUERY]: {
      order: 2,
    },
  },

  /* ------------- HOMEWALLETSSECTION ------------- */
  HomeWallets_container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0px',
    [TABLET_MEDIA_QUERY]: {
      flexDirection: 'column',
      paddingBottom: '80px',
    },
  },
  HomeWallets_textContainer: {
    width: '45%',
    paddingRight: '50px',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      padding: '0px',
    },
    [TABLET_MEDIA_QUERY]: {
      width: '90%',
      padding: '0px',
      paddingBottom: '50px',
    },
  },
  HomeWallets_titleText: {
    [TABLET_MEDIA_QUERY]: {
      textAlign: 'center',
    },
  },
  HomeWallets_descriptionText: {
    marginBottom: '20px',
  },
  HomeWallets_imagesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.lightGray,
    width: '50%',
    padding: '40px',
    [TABLET_MEDIA_QUERY]: {
      width: '100%',
      padding: '40px 0',
      order: 2,
    },
    [MOBILE_MEDIA_QUERY]: {
      padding: '20px',
    },
  },
  HomeWallets_walletCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${COLORS.gray}`,
    width: '50%',
    height: '150px',
    backgroundColor: COLORS.white,
    [TABLET_MEDIA_QUERY]: {
      width: '30%',
    },
    [MOBILE_MEDIA_QUERY]: {
      width: '50%',
    },
  },
  HomeWallets_image: {
    width: '33%',
    maxHeight: '90%',
    objectFit: 'cover',
    [TABLET_MEDIA_QUERY]: {
      width: '50%',
    },
  },
  HomeWallets_coinbaseImage: {
    width: '50%',
    [TABLET_MEDIA_QUERY]: {
      width: '70%',
    },
  },
  HomeWallets_buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [MOBILE_MEDIA_QUERY]: {
      flexDirection: 'column',
    },
  },
  /* ------------- HOMEFAQSECTION ------------- */
  HomeFAQSection_accordionQuestion: {
    marginTop: '10px',
    marginBottom: '30px',
    paddingLeft: '22px',
  },
  HomeFAQSection_sectionContainer: {
    marginBottom: '90px',
    marginTop: '20px',
  },
  HomeFAQSection_sectionTitle: {
    marginBottom: '30px',
  },
  HomeFAQSection_textAlignLeft: {
    textAlign: 'left',
  },
  HomeFAQSection_header: {
    display: 'inline-block',
    fontSize: '52px',
    width: '100%',
    margin: 'auto',
    marginBottom: '50px',
    textAlign: 'center',
    fontWeight: 600,
    [TABLET_MEDIA_QUERY]: {
      fontSize: '38px',
      marginBottom: '36px',
      textAlign: 'left',
    },
  },
  HomeFAQSection_question: {
    fontSize: '22px',
    fontWeight: 600,
    lineHeight: '31px',
    display: 'inline-block',
    width: '95%',
  },
  HomeFAQSection_questionTitle: {
    paddingTop: '20px',
    paddingBottom: '20px',
    borderBottom: `1px solid ${COLORS.gray}`,
    position: 'relative',
  },
  HomeFAQSection_dropdownIcon: {
    fontSize: '20px',
    position: 'absolute',
    right: '5px',
    top: '26px',
  },
  HomeFAQSection_buttonContainer: {
    border: `1px solid ${COLORS.gray}`,
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '50px',
    boxShadow: `0 6px 12px 0 ${COLORS.lightBlueAlpha25}`,
    padding: '25px 25px',
    marginTop: '25px',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      marginTop: '15px',
    },
    transition: '0.2s',
    width: 'auto',
    ':hover': {
      boxShadow: `0 8px 16px 0 ${COLORS.lightBlueAlpha25}`,
      transform: 'scale(1.02)',
    },
    ':active': {
      boxShadow: `0 7px 14px 0 ${COLORS.lightBlueAlpha25}`,
      transform: 'scale(1.01)',
    },
    [TABLET_MEDIA_QUERY]: {
      width: '100%',
    },
  },
  HomeFAQSection_buttonIcon: {
    marginRight: '25px',
    width: '36px',
    height: '36px',
  },
  HomeFAQSection_buttonText: {
    color: COLORS.darkBlue,
    fontWeight: 500,
  },
  HomeFAQSection_buttonContentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginRight: '36px',
  },

  /* ------------- HOMEPOPULARSETSTABLE ------------- */
  HomePopularSetsTable_footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60px',
    width: '100%',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    backgroundColor: COLORS.lightGray2,
    ':hover': {
      backgroundColor: COLORS.lightGray,
    },
  },
  HomePopularSetsTable_footerTextWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  HomePopularSetsTable_footerText: {
    color: COLORS.blue,
    fontWeight: 500,
  },
  HomePopularSetsTable_rightArrow: {
    marginLeft: '10px',
  },
  /* ------------- HOMEEXPLAINERSECTION ------------- */
  HomeExplainerSection_container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      flexDirection: 'column',
      alignItems: 'left',
    },
  },
  HomeExplainerSection_leftColumn: {
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      marginBottom: '30px',
    },
  },
  HomeExplainerSection_subTitle: {
    color: COLORS.lightBlue4,
    fontSize: '24px',
    fontWeight: 600,
    marginBottom: '10px',
  },
  HomeExplainerSection_titleContainer: {
    marginBottom: '30px',
  },
  HomeExplainerSection_title: {
    color: COLORS.darkBlue,
    display: 'inline',
    fontSize: '40px',
    fontWeight: 600,
  },
  HomeExplainerSection_titleHighlighted: {
    color: COLORS.lightBlue4,
  },
  HomeExplainerSection_description: {
    color: COLORS.darkGray,
    fontSize: '28px',
    marginBottom: '44px',
  },
  HomeExplainerSection_imageContainer: {
    textAlign: 'center',
    width: '100%',
  },
  HomeExplainerSection_defiPulseImage: {
    width: '80%',
    transition: '0.2s',
    ':hover': {
      transform: 'scale(1.02)',
    },
    [TABLET_MEDIA_QUERY]: {
      width: '100%',
    },
  },
  HomeExplainerSection_simpleInterfaceImage: {
    width: '100%',
  },
  /* ------------- HOMETRUSTEDBYSECTION ------------- */
  HomeTrustedBy_container: {
    marginBottom: '80px',
    paddingBottom: '40px',
    paddingTop: '40px',
    textAlign: 'center',
  },
  HomeTrustedBy_header: {
    fontSize: '16px',
    marginBottom: '30px',
    color: COLORS.darkGray,
    letterSpacing: '1px',
  },
  HomeTrustedBy_logosContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    opacity: '70%',
  },
  HomeTrustedBy_logosMarginBottom: {
    marginBottom: '60px',
  },
  HomeTrustedBy_logosRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    [TABLET_MEDIA_QUERY]: {
      justifyContent: 'space-around',
    },
  },
  HomeTrustedBy_logosLastRow: {
    width: '75%',
  },
  HomeTrustedBy_logo: {
    width: '150px',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      width: '150px',
    },
  },
});

export default styles;
