import React, { PureComponent } from 'react';
import SearchInput, { createFilter } from 'react-search-input';
import { StyleSheet, css } from 'aphrodite';

import { COLORS } from '../../constants/index';
import magnifyingGlassActive from '../../img/icons/magnifying-glass-active.svg';
import { IListToken } from '../../typings/index';
import { getListTokenImage } from '../../utils/index';
import { tokenListNameOverride } from '../../constants/tokenListConstants';
import { sortListTokensInputFirst } from '../../utils/formatUtils';

const KEYS_TO_FILTERS = ['symbol', 'name'];

const styles = StyleSheet.create({
  searchInput: {
    paddingTop: '20px',
    paddingBottom: '15px',
    height: '76px',
    border: '1px solid #E1E1EF',
    borderRadius: '4px',
    boxShadow: '0 2px 4px 0 rgba(10,9,50,0.11)',
    background: `url(${magnifyingGlassActive}) no-repeat scroll 20px 20px`,
    backgroundPosition: '15px 30px',
    paddingLeft: '40px',
  },
  searchInputAlt: {
    paddingTop: '20px',
    paddingBottom: '15px',
    border: '1px solid #E1E1EF',
    borderRadius: '4px',
    boxShadow: '0 2px 4px 0 rgba(10,9,50,0.11)',
    background: `url(${magnifyingGlassActive}) no-repeat scroll 15px 15px`,
    backgroundPosition: '15px 16px',
    paddingLeft: '40px',
  },
  dropdown: {
    position: 'absolute',
    zIndex: 100,
    width: 'inherit',
    maxHeight: '400px',
    overflowY: 'scroll',
  },
  dropdownTable: {
    width: '100%',
    alignItems: 'center',
  },
  dropdownElement: {
    height: '76px',
    display: 'flex',
    alignItems: 'center',
    borderLeft: `1px solid ${COLORS.gray}`,
    borderRight: `1px solid ${COLORS.gray}`,
    borderBottom: `1px solid ${COLORS.gray}`,
    backgroundColor: COLORS.white,
    textAlign: 'left',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: `${COLORS.lightGray}`,
    },
  },
  tokenIcon: {
    height: '30px',
    width: '30px',
    margin: '15px',
  },
  tokenName: {
    width: '90%',
    fontWeight: 'bold',
    color: COLORS.darkBlue,
  },
  tokenSymbol: {
    paddingRight: '25px',
  },
  tokenSearch: {
    position: 'relative',
    width: '100%',
    marginRight: '15px',
  },
  clearSearch: {
    position: 'absolute',
    height: '76px',
    lineHeight: '76px',
    width: '50px',
    top: '0px',
    right: '0px',
    paddingRight: '20px',
    cursor: 'pointer',
  },
});

interface ITokenSearchProps {
  centered: boolean;
  width: string;
  height?: string;
  tokens: IListToken[];
  unavailableTokens?: IListToken[];
  onClick?: (...args: any[]) => any;
}

interface ITokenSearchState {
  searchTerm: string;
}

class TokenSearch extends PureComponent<ITokenSearchProps, ITokenSearchState> {
  static get defaultProps() {
    return {
      centered: true,
      width: '100%',
      unavailableTokens: [{}],
      onClick: () => {},
    };
  }

  constructor(props: ITokenSearchProps) {
    super(props);
    this.state = {
      searchTerm: '',
    };
  }

  searchUpdated = (term: string) => {
    this.setState({ searchTerm: term });
  };

  clickHandler = (token: IListToken) => {
    const { onClick } = this.props;

    onClick(token);
    this.setState({ searchTerm: '' });
  };

  render() {
    const { searchTerm } = this.state;
    const { tokens, unavailableTokens, centered, width, height } = this.props;

    const unavailableTokenNames = unavailableTokens.map(token => token.name);
    const availableTokens = tokens.filter(token => !unavailableTokenNames.includes(token.name));
    const filteredTokens = sortListTokensInputFirst(
      searchTerm,
      availableTokens.filter(createFilter(searchTerm, KEYS_TO_FILTERS)),
    );

    return (
      <div className={css(styles.tokenSearch)}>
        <SearchInput
          fuzzy
          sortResults
          style={{ width, height }}
          inputClassName={css(height ? styles.searchInputAlt : styles.searchInput)}
          placeholder="Search for a token"
          onChange={this.searchUpdated}
          value={searchTerm}
        />
        <div className={css(styles.dropdown)}>
          {searchTerm.length > 0 &&
            filteredTokens.map((token: any, index: number) => (
              <div className={css(styles.dropdownTable)} key={`search-${token.symbol}-${index}`}>
                <div
                  className={css(styles.dropdownElement)}
                  style={{ width, margin: centered ? 'auto' : 0 }}
                  onClick={() => this.clickHandler(token)}
                  onKeyPress={() => this.clickHandler(token)}
                  role="checkbox"
                  aria-checked="false"
                  tabIndex={0}
                >
                  <span>
                    <img
                      className={css(styles.tokenIcon)}
                      src={getListTokenImage(token)}
                      alt="icon"
                    />
                  </span>
                  <span className={css(styles.tokenName)}>
                    {tokenListNameOverride[token.address.toLowerCase()] || token.name}
                  </span>
                  <span className={css(styles.tokenSymbol)}>{token.symbol}</span>
                </div>
              </div>
            ))}
        </div>
        {searchTerm.length > 0 && (
          <span
            className={css(styles.clearSearch)}
            style={{ width, textAlign: centered ? 'center' : 'right' }}
            onClick={() => this.searchUpdated('')}
          >
            <div>ⓧ</div>
          </span>
        )}
      </div>
    );
  }
}

export default TokenSearch;
