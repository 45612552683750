import { StyleSheet } from 'aphrodite';

import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';

const { MOBILE_MEDIA_QUERY, TABLET_MEDIA_QUERY, TABLET_LANDSCAPE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  Onboarding_wrapper: {
    height: '90vh',
    overflowY: 'hidden',
  },
  Onboarding_container: {
    width: '70%',
    height: '88vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
    overflowX: 'hidden',
    overflowY: 'hidden',
    [TABLET_MEDIA_QUERY]: {
      width: '70%',
      height: 'auto',
    },
    [MOBILE_MEDIA_QUERY]: {
      width: '100%',
    },
  },
  Onboarding_slide: {
    display: 'flex',
    [TABLET_MEDIA_QUERY]: {
      flexDirection: 'column',
    },
  },
  Onboarding_slideSection: {
    width: '45%',
    maxWidth: '45%',
    height: '100%',
    minHeight: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 20px',
    [TABLET_MEDIA_QUERY]: {
      width: '90%',
      maxWidth: '90%',
      minHeight: '0px',
      margin: 'auto',
    },
  },
  Onboarding_sectionTitle: {
    lineHeight: 1.1,
    marginBottom: '20px',
    textAlign: 'center',
    [TABLET_MEDIA_QUERY]: {
      fontSize: '32px',
      marginBottom: '10px',
    },
  },
  Onboarding_sectionSubtitle: {
    display: 'inline-block',
    textAlign: 'center',
    fontSize: '20px',
    lineHeight: 1.4,
    marginBottom: '20px',
    [TABLET_MEDIA_QUERY]: {
      fontSize: '18px',
      marginBottom: '10px',
    },
  },
  Onboarding_text: {
    fontSize: '18px',
    lineHeight: 1.2,
    marginBottom: '20px',
  },
  Onboarding_buttonWrapper: {
    backgroundColor: COLORS.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    [MOBILE_MEDIA_QUERY]: {
      position: 'fixed',
      bottom: '0',
      width: '100%',
      paddingBottom: '10px',
      paddingTop: '10px',
    },
  },
  Onboarding_image: {
    height: '280px',
    [TABLET_MEDIA_QUERY]: {
      height: '200px',
    },
  },
  Onboarding_slideButton: {
    width: '50%',
    maxWidth: '240px',
    margin: '0 20px',
  },
  Onboarding_slideButtonFinish: {
    backgroundColor: COLORS.green,
  },

  /* Slide 2 */
  SetProducts_image: {
    width: '90%',
    [TABLET_MEDIA_QUERY]: {
      width: '85%',
    },
  },

  /* Slide 3 */
  WalletSetup_list: {
    margin: '0px',
    textAlign: 'left',
  },
  WalletSetup_listItem: {
    fontSize: '20px',
    lineHeight: 1.5,
    marginBottom: '20px',
    color: COLORS.darkGray,
  },
  WalletSetup_boldText: {
    fontWeight: 500,
    color: COLORS.darkBlue,
  },
  WalletSetup_image: {
    height: 'auto',
    width: '220px',
    [MOBILE_MEDIA_QUERY]: {
      height: '160px',
    },
  },

  /* Slide 4 */
  FundYourWallet_subtitle: {
    alignSelf: 'flex-start',
  },
  FundYourWallet_image: {
    height: '90px',
    marginBottom: '20px',
    marginRight: '50px',
  },
  FundYourWallet_ethAddressContainer: {
    width: '100%',
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    marginBottom: '7px',
    padding: '12px',
  },
  FundYourWallet_ethereumAddressText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    [MOBILE_MEDIA_QUERY]: {
      fontSize: '12px',
    },
  },
  FundYourWallet_copyAddressButton: {
    color: COLORS.darkBlue,
    padding: '7px',
    marginLeft: '10px',
    borderRadius: '4px',
    border: `1px solid ${COLORS.gray}`,
    ':hover': {
      cursor: 'pointer',
      backgroundColor: COLORS.lightGray,
    },
  },
  FundYourWallet_walletContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  FundYourWallet_walletBalance: {
    display: 'inline-block',
    fontSize: '24px',
    fontWeight: 500,
    color: COLORS.darkBlue,
  },
  FundYourWallet_walletCurrency: {
    display: 'inline-block',
    fontSize: '16px',
    fontWeight: 300,
  },
  FundYourWallet_qrLabel: {
    color: COLORS.darkBlue,
    alignSelf: 'flex-start',
    marginBottom: '7px',
    fontSize: '14px',
    lineHeight: 1.2,
  },
  FundYourWallet_toggleQRCodeButton: {
    alignSelf: 'flex-end',
    border: 'none',
    textDecoration: 'underline',
    marginBottom: '10px',
    color: COLORS.darkGray,
    backgroundColor: 'transparent',
    ':hover': {
      color: COLORS.blue,
      cursor: 'pointer',
      boxShadow: 'none',
    },
  },
  FundYourWallet_qrCodeContainer: {
    textAlign: 'center',
  },
  FundYourWallet_qrImage: {
    width: '100%',
    maxWidth: '200px',
    height: '100%',
    maxHeight: '200px',
    [MOBILE_MEDIA_QUERY]: {
      marginBottom: '50px',
      marginTop: '50px',
    },
  },
  FundYourWallet_footerText: {
    width: '80%',
    textAlign: 'center',
    margin: '0 auto',
  },

  /* Slide 5 */
  FinishOnboarding_subtitle: {
    textAlign: 'left',
  },
  FinishOnboarding_setTableContainer: {
    width: '100%',
    marginBottom: '20px',
  },
  FinishOnboarding_setTableLabel: {
    color: COLORS.darkBlue,
    fontWeight: 500,
    marginBottom: '5px',
  },
  FinishOnboarding_setTableHeaderCell: {
    paddingTop: '5px',
    paddingBottom: '10px',
    [MOBILE_MEDIA_QUERY]: {
      padding: '0px',
    },
  },
  FinishOnboarding_setTableMarketCap: {
    textAlign: 'right',
  },
  FinishOnboarding_setTableRowCell: {
    height: '70px',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      height: '50px',
    },
  },
  FinishOnboarding_image: {
    width: '90%',
    [MOBILE_MEDIA_QUERY]: {
      marginTop: '15px',
      width: '100%',
    },
  },
});

export default styles;
