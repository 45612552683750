import { css } from 'aphrodite';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import arrowRightWhite from '../../img/icons/right-arrow-white.svg';
import styles from '../../containers/Home/homeStyles';

export interface ILinkButtonProps {
  readonly customStyle?: any;
  readonly isSecondary?: boolean;
  readonly disableArrow?: boolean;
  readonly to: string;
  readonly children: any;
  readonly log: {
    category: string;
    label: string;
  };
}

class LinkButton extends PureComponent<ILinkButtonProps> {
  public static get defaultProps() {
    return {
      to: '',
      children: '',
      log: {},
    };
  }

  public render() {
    const { to, children, customStyle, disableArrow } = this.props;

    return (
      <div>
        <Link to={to}>
          <button className={css(styles.Home_button, customStyle)}>
            {children}
            {!disableArrow && <img src={arrowRightWhite} alt="White arrow right" />}
          </button>
        </Link>
      </div>
    );
  }
}

export default LinkButton;
