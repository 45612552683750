import { utils, BigNumber as EthersBigNumber } from 'ethers';
import Web3 from 'web3';
import { toChecksumAddress } from 'tochecksum';
import { ApprovalStatus } from '../containers/IssuanceApprovalV2/enums';
import {
  accountSelector,
  allApprovalsSelector,
  erc20BalancesSelector,
  issuanceInputQuantityV2Selector,
  setDetailsCurrentSetAddressSelector,
} from './baseSelectors';
import { IListTokenWithPosition } from '../typings/index';
import { BigNumber, getWeb3Instance } from '../utils/index';
import { currentSetComponentsSelector } from './setDetailsSelectors';
import basicIssuanceModuleABI from '../constants/abis/basicIssuanceModuleABI';
import { issuanceModuleEnabledSelector } from './baseSelectors';
import { currentChainOrDefaultSelector, gasPriceTransactionOptionsSelector } from '.';
import { NETWORK_CONSTANTS } from '../constants/index';
import setJSConfig from '../constants/setJSConfig';

interface IApprovalStatuses {
  [tokenId: string]: ApprovalStatus;
}

export const isBasicIssuanceModuleEnabledForCurrentSetSelector = (state: any): boolean => {
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);
  const basicIssuanceEnabledMap = issuanceModuleEnabledSelector(state);

  return basicIssuanceEnabledMap?.[currentSetAddress] || false;
};

export const allApprovalStatusByTokenIdSelector = (state: any): IApprovalStatuses => {
  const requiredComponents = currentSetComponentsSelector(state);
  const allApprovals = allApprovalsSelector(state);

  if (!requiredComponents || requiredComponents.length == 0) return {};

  const approvalStatuses: IApprovalStatuses = {};

  requiredComponents.forEach((token: IListTokenWithPosition) => {
    const currentTokenApproval = allApprovals[toChecksumAddress(token.address)];

    if (currentTokenApproval?.isApproved) {
      approvalStatuses[toChecksumAddress(token.address)] = ApprovalStatus.APPROVED;
      return;
    }

    if (currentTokenApproval?.isPending) {
      approvalStatuses[toChecksumAddress(token.address)] = ApprovalStatus.PENDING;
      return;
    }

    approvalStatuses[toChecksumAddress(token.address)] = ApprovalStatus.UNAPPROVED;
  });

  return approvalStatuses;
};

export const allUnapprovedListTokensSelector = (state: any): IListTokenWithPosition[] => {
  const requiredComponents = currentSetComponentsSelector(state);
  const allApprovalStatusesByTokenId = allApprovalStatusByTokenIdSelector(state);

  return requiredComponents?.filter((token: IListTokenWithPosition) => {
    return (
      allApprovalStatusesByTokenId[toChecksumAddress(token.address)] === ApprovalStatus.UNAPPROVED
    );
  });
};

export const hasAllApprovalsSelector = (state: any): boolean => {
  const requiredComponents = currentSetComponentsSelector(state);
  const allApprovalStatusesByTokenId = allApprovalStatusByTokenIdSelector(state);

  return requiredComponents?.every((token: IListTokenWithPosition) => {
    return (
      allApprovalStatusesByTokenId[toChecksumAddress(token.address)] === ApprovalStatus.APPROVED
    );
  });
};

export const isAnyApprovalPendingSelector = (state: any): boolean => {
  const requiredComponents = currentSetComponentsSelector(state);
  const allApprovalStatusesByTokenId = allApprovalStatusByTokenIdSelector(state);

  return requiredComponents?.some((token: IListTokenWithPosition) => {
    return (
      allApprovalStatusesByTokenId[toChecksumAddress(token.address)] === ApprovalStatus.PENDING
    );
  });
};

export const userHasSufficientFundsForIssuanceQuantity = (state: any): boolean => {
  const requiredComponents = currentSetComponentsSelector(state);
  const userERC20Balances = erc20BalancesSelector(state);
  const rawIssuanceQuantity = issuanceInputQuantityV2Selector(state);

  return requiredComponents.every((component: IListTokenWithPosition) => {
    const requiredQuantity = new BigNumber(component.unit).mul(rawIssuanceQuantity || 0);
    const userBalance = userERC20Balances[Web3.utils.toChecksumAddress(component.address)];

    return requiredQuantity.lte(userBalance || 0);
  });
};

/**
 * Returns the list of required components annotated with the max issuable quantity
 * based on user balances.
 */
export const requiredComponentsWithMaxIssuableQuantitySelector = (state: any) => {
  const userBalances = erc20BalancesSelector(state);
  const requiredComponentsForOneToken = currentSetComponentsSelector(state);

  return requiredComponentsForOneToken.map((component: IListTokenWithPosition) => {
    const requiredComponentQuantityPerSet = component.unit;
    const checksumAddress = Web3.utils.toChecksumAddress(component.address);
    const userBalance = userBalances[checksumAddress] || new BigNumber(0);

    const maxIssuableQuantity = userBalance.div(requiredComponentQuantityPerSet);

    return {
      ...component,
      maxIssuableQuantity,
    };
  });
};

export const maxIssuableTokenQuantitySelector = (state: any): string => {
  const requiredComponents = requiredComponentsWithMaxIssuableQuantitySelector(state);

  if (!requiredComponents?.length) return;

  const tokenWithLowestIssuableQuantity = requiredComponents.reduce(
    (lowestToken: any, currentToken: any) => {
      if (currentToken.maxIssuableQuantity.lt(lowestToken.maxIssuableQuantity)) return currentToken;

      return lowestToken;
    },
    requiredComponents[0],
  );

  return tokenWithLowestIssuableQuantity?.maxIssuableQuantity?.toFixed(18, BigNumber.ROUND_DOWN);
};

export const createIssueFundTransactionArgs = async (state: any) => {
  const setAddress = setDetailsCurrentSetAddressSelector(state);
  const userAddress = accountSelector(state);
  const rawIssuanceQuantity = issuanceInputQuantityV2Selector(state);
  const formattedIssuanceQuantity = utils.parseEther(
    rawIssuanceQuantity?.length ? rawIssuanceQuantity : '0',
  );
  const gasPriceTransactionOptions = gasPriceTransactionOptionsSelector(state);
  const basicIssuanceModuleAddress = basicIssuanceModuleAddressSelector(state);
  const web3Instance = await getWeb3Instance();
  const basicIssuanceModuleContract = new web3Instance.eth.Contract(
    basicIssuanceModuleABI as any,
    basicIssuanceModuleAddress,
  );
  const gasLimit = await basicIssuanceModuleContract.methods
    .issue(setAddress, formattedIssuanceQuantity, userAddress)
    .estimateGas({ from: userAddress });
  const transactionOpts = {
    ...gasPriceTransactionOptions,
    gasLimit: EthersBigNumber.from(gasLimit),
  };

  if (!setAddress || !userAddress || formattedIssuanceQuantity.lte(0)) return;

  return [setAddress, formattedIssuanceQuantity, userAddress, undefined, transactionOpts];
};

export const basicIssuanceModuleAddressSelector = (state: any) => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case NETWORK_CONSTANTS.POLYGON_CHAIN:
    case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
    case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
    case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
      return setJSConfig[currentChain].basicIssuanceModuleAddress;
    default:
      return setJSConfig[NETWORK_CONSTANTS.ETHEREUM_CHAIN].basicIssuanceModuleAddress;
  }
};

export const navIssuanceModuleAddressSelector = (state: any) => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case NETWORK_CONSTANTS.POLYGON_CHAIN:
      return '0xb795Ef471e31610739FE9dab06E2D91024f4048E';
    case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
      return '0x0000000000000000000000000000000000000000';
    default:
      return '0xCd34F1b92C6d0d03430ec4A410F758F7776a3504';
  }
};
