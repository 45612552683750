import { css, StyleSheet } from 'aphrodite';
import React, { PureComponent } from 'react';
import { Icon } from 'semantic-ui-react';

import { WINDOW_DIMENSIONS, COLORS } from '../../constants/index';
import { CustomSetDetailsResponse } from '../../typings/index';
import RichTextEditor from '../../components/RichTextEditor';
import { withTranslation, WithTranslation } from 'react-i18next';

const { MOBILE_MEDIA_QUERY, TABLET_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  CustomSetManagerProfile_container: {
    width: '100%',
    marginTop: '25px',
    [TABLET_MEDIA_QUERY]: {},
    [MOBILE_MEDIA_QUERY]: {},
  },
  CustomSetManagerProfile_header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
    borderBottom: `1px solid ${COLORS.gray}`,
    padding: '12px 0',
  },
  CustomSetManagerProfile_titleText: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: 1.2,
    color: COLORS.darkBlue,
  },
  CustomSetManagerProfile_userProfile: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0 10px 0',
  },
  CustomSetManagerProfile_usernames: {
    display: 'flex',
    flexDirection: 'column',
  },
  CustomSetManagerProfile_usernameText: {
    fontSize: '20px',
    fontWeight: 500,
    color: COLORS.darkBlue,
    marginBottom: '5px',
  },
  CustomSetManagerProfile_firstLastNameText: {
    fontSize: '14px',
    lineHeight: '14px',
    color: COLORS.darkGray,
  },
  CustomSetManagerProfile_profilePhoto: {
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    marginRight: '15px',
    objectFit: 'cover',
  },
  CustomSetManagerProfile_section: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
    [MOBILE_MEDIA_QUERY]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: '0px',
    },
  },
  CustomSetManagerProfile_activityText: {
    color: COLORS.darkGray,
    marginRight: '15px',
    fontSize: '14px',
    [MOBILE_MEDIA_QUERY]: {
      marginBottom: '12px',
    },
  },
  CustomSetManagerProfile_websiteText: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1,
    color: COLORS.blue,
    marginRight: '15px',
    padding: '6px 8px',
    border: `1px solid ${COLORS.gray}`,
    boxShadow: `0 1px 2px 0 ${COLORS.blackAlpha20}`,
    borderRadius: '4px',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: COLORS.lightGray,
    },
    [MOBILE_MEDIA_QUERY]: {
      marginBottom: '12px',
    },
  },
  CustomSetManagerProfile_twitterLogo: {
    height: '15px',
    width: '15px',
    marginRight: '8px',
  },
  CustomSetManagerProfile_twitterText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: COLORS.black,
    ':hover': {
      cursor: 'pointer',
    },
  },
  CustomSetManagerProfile_descriptionContainer: {
    marginBottom: '15px',
  },
  CustomSetManagerProfile_descriptionText: {
    color: COLORS.darkBlue,
    fontSize: '16px',
    lineHeight: 1.6,
    wordBreak: 'break-word',
  },
});

interface ICustomSetManagerProfileProps {
  customSetDetails: CustomSetDetailsResponse;
}

/**
 * @title CustomSetManagerProfile
 * @author Set Labs
 *
 * CustomSetManagerProfile displays information about the trader managing the set.
 */
class CustomSetManagerProfile extends PureComponent<
  ICustomSetManagerProfileProps & WithTranslation
> {
  public render() {
    const { customSetDetails, t } = this.props;

    if (!customSetDetails?.setDetails) {
      return null;
    }

    const { managerIcon, managerTwitter, managerDescription, managerName, website } =
      customSetDetails?.setDetails || {};

    return (
      <div className={css(styles.CustomSetManagerProfile_container)}>
        {/* Title  */}
        <div className={css(styles.CustomSetManagerProfile_header)}>
          <span className={css(styles.CustomSetManagerProfile_titleText)}>
            {t('social-trader-set.created-by')} {managerName}
          </span>
        </div>

        {/* Profile Picture and Username */}
        <div className={css(styles.CustomSetManagerProfile_userProfile)}>
          <img src={managerIcon} className={css(styles.CustomSetManagerProfile_profilePhoto)} />
          <div className={css(styles.CustomSetManagerProfile_usernames)}>
            <span className={css(styles.CustomSetManagerProfile_firstLastNameText)}>
              {managerName}
            </span>
          </div>
        </div>

        {/* Social Media */}
        <div className={css(styles.CustomSetManagerProfile_section)}>
          {website && (
            <a
              href={website}
              target="_blank"
              className={css(styles.CustomSetManagerProfile_websiteText)}
            >
              <Icon name="chain" />
              Website
            </a>
          )}

          {managerTwitter && (
            <a
              href={`https://twitter.com/${managerTwitter}`}
              target="_blank"
              className={css(
                styles.CustomSetManagerProfile_websiteText,
                styles.CustomSetManagerProfile_twitterText,
              )}
            >
              <img
                className={css(styles.CustomSetManagerProfile_twitterLogo)}
                src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/twitter-light-blue.svg"
              />
              {managerTwitter}
            </a>
          )}
        </div>

        {/* Description */}
        <div className={css(styles.CustomSetManagerProfile_descriptionContainer)}>
          <RichTextEditor
            readOnly
            customStyles={styles.CustomSetManagerProfile_descriptionText}
            initialRawEditorState={managerDescription}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation('set-details')(CustomSetManagerProfile);
