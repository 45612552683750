import _ from 'lodash';
import { createSelector } from 'reselect';
import { toChecksumAddress } from 'tochecksum';

import {
  coingeckoEthereumTokenListSelector,
  coingeckoPolygonTokenListSelector,
  tokenSetsTokenListSelector,
  geminiEthereumTokenListSelector,
  geminiPolygonTokenListSelector,
  coingeckoArbitrumTokenListSelector,
  geminiArbitrumTokenListSelector,
  coingeckoOptimismTokenListSelector,
  geminiOptimismTokenListSelector,
  managerTokensSelector,
  featuredTokenSetsTokenListSelector,
} from './baseSelectors';
import { GraphSetToken, IListToken, OmittedAddressMapping } from '../typings/index';
import { currentChainOrDefaultSelector, currentMainnetChainIdSelector } from './web3Selectors';
import {
  omittedArbitrumAddressesList,
  omittedEthereumAddressesList,
  omittedPolygonAddressesList,
  omittedOptimismAddressesList,
  omittedAvalancheAddressesList,
} from '../constants/omittedAddressLists';
import { NETWORK_CONSTANTS } from '../constants/index';
import { coingeckoAvalancheTokenListSelector, geminiAvalancheTokenListSelector } from '.';

export const omittedAddressesListSelector = createSelector(
  currentChainOrDefaultSelector,
  (currentChain: string) => {
    switch (currentChain) {
      case NETWORK_CONSTANTS.POLYGON_CHAIN:
        return omittedPolygonAddressesList;
      case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
        return omittedArbitrumAddressesList;
      case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
        return omittedOptimismAddressesList;
      case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
        return omittedAvalancheAddressesList;
      default:
        return omittedEthereumAddressesList;
    }
  },
);

export const tokenSetsTokenListUrlSelector = (state: any): string => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case NETWORK_CONSTANTS.POLYGON_CHAIN:
      return `https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/set.polygon.tokenlist.json`;
    case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
      return `https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/set.arbitrum.tokenlist.json`;
    case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
      return `https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/set.optimism.tokenlist.json`;
    case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
      return `https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/set.avalanche.tokenlist.json`;
    default:
      return `https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/set.tokenlist.json`;
  }
};

export const geminiTokenListUrlSelector = createSelector(
  currentChainOrDefaultSelector,
  (currentChain: string) => {
    switch (currentChain) {
      case NETWORK_CONSTANTS.POLYGON_CHAIN:
        return 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/gemini/polygon.tokenlist.json';
      case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
        return 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/gemini/arbitrum.tokenlist.json';
      case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
        return 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/gemini/optimism.tokenlist.json';
      case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
        return 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/gemini/avalanche.tokenlist.json';
      default:
        return 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/gemini/ethereum.tokenlist.json';
    }
  },
);

export const geminiTokenListSelector = createSelector(
  currentChainOrDefaultSelector,
  geminiEthereumTokenListSelector,
  geminiPolygonTokenListSelector,
  geminiArbitrumTokenListSelector,
  geminiOptimismTokenListSelector,
  geminiAvalancheTokenListSelector,
  (
    currentChain: string,
    geminiEthereumTokenList: IListToken[],
    geminiPolygonTokenList: IListToken[],
    geminiArbitrumTokenList: IListToken[],
    geminiOptimismTokenList: IListToken[],
    geminiAvalancheTokenList: IListToken[],
  ) => {
    switch (currentChain) {
      case NETWORK_CONSTANTS.POLYGON_CHAIN:
        return geminiPolygonTokenList;
      case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
        return geminiArbitrumTokenList;
      case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
        return geminiOptimismTokenList;
      case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
        return geminiAvalancheTokenList;
      default:
        return geminiEthereumTokenList;
    }
  },
);

export const geminiTokenListKeySelector = createSelector(
  currentChainOrDefaultSelector,
  (currentChain: string) => {
    switch (currentChain) {
      case NETWORK_CONSTANTS.POLYGON_CHAIN:
        return 'gemini-polygon';
      case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
        return 'gemini-arbitrum';
      case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
        return 'gemini-optimism';
      case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
        return 'gemini-avalanche';
      default:
        return 'gemini-ethereum';
    }
  },
);

export const coingeckoTokenListSelector = createSelector(
  currentChainOrDefaultSelector,
  coingeckoEthereumTokenListSelector,
  coingeckoPolygonTokenListSelector,
  coingeckoArbitrumTokenListSelector,
  coingeckoOptimismTokenListSelector,
  coingeckoAvalancheTokenListSelector,
  omittedAddressesListSelector,
  (
    currentChain: string,
    coingeckoEthereumTokenList: IListToken[],
    coingeckoPolygonTokenList: IListToken[],
    coingeckoArbitrumTokenList: IListToken[],
    coingeckoOptimismTokenList: IListToken[],
    coingeckoAvalancheTokenList: IListToken[],
    omittedAddressesList: OmittedAddressMapping,
  ) => {
    let tokenList;
    switch (currentChain) {
      case NETWORK_CONSTANTS.POLYGON_CHAIN:
        tokenList = coingeckoPolygonTokenList;
        break;
      case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
        tokenList = coingeckoArbitrumTokenList;
        break;
      case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
        tokenList = coingeckoOptimismTokenList;
        break;
      case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
        tokenList = coingeckoAvalancheTokenList;
        break;
      default:
        tokenList = coingeckoEthereumTokenList;
        break;
    }

    return tokenList?.filter(
      token =>
        !omittedAddressesList[toChecksumAddress(token.address)] &&
        !omittedAddressesList[token.address],
    );
  },
);

export const coingeckoTokenListByAddressSelector = createSelector(
  coingeckoTokenListSelector,
  (coinlistTokens: IListToken[]) => {
    const tokensByAddress: { [address: string]: IListToken } = {};

    coinlistTokens?.forEach((token: IListToken) => {
      tokensByAddress[token.address.toLowerCase()] = token;
    });

    return tokensByAddress;
  },
);

export const coingeckoTokenListBySymbolSelector = createSelector(
  coingeckoTokenListSelector,
  (coinlistTokens: IListToken[]) => {
    const tokensBySymbol: { [symbol: string]: IListToken } = {};

    coinlistTokens?.forEach((token: IListToken) => {
      tokensBySymbol[token.symbol.toLowerCase()] = token;
    });

    return tokensBySymbol;
  },
);

export const geminiTokenListByAddressSelector = createSelector(
  geminiTokenListSelector,
  (coinlistTokens: IListToken[]) => {
    const tokensByAddress: { [address: string]: IListToken } = {};

    coinlistTokens?.forEach((token: IListToken) => {
      tokensByAddress[token.address.toLowerCase()] = token;
    });

    return tokensByAddress;
  },
);

export const tokenSetsTokenListByAddressSelector = createSelector(
  tokenSetsTokenListSelector,
  featuredTokenSetsTokenListSelector,
  (tokenSetsTokens: IListToken[], featuredTokenSetsTokens: IListToken[]) => {
    const tokensByAddress: { [address: string]: IListToken } = {};

    tokenSetsTokens?.forEach((token: IListToken) => {
      tokensByAddress[token.address.toLowerCase()] = token;
    });

    featuredTokenSetsTokens?.forEach((token: IListToken) => {
      tokensByAddress[token.address.toLowerCase()] = token;
    });

    return tokensByAddress;
  },
);

export const communityAndOwnedAndFeaturedSetsSelector = createSelector(
  tokenSetsTokenListSelector,
  managerTokensSelector,
  featuredTokenSetsTokenListSelector,
  currentMainnetChainIdSelector,
  (
    tokenSetsTokens: IListToken[],
    managerTokens: GraphSetToken[],
    featuredTokenSets: IListToken[],
    currentChainId,
  ) => {
    return _.uniq(
      (tokenSetsTokens || [])
        .map(t => t.address)
        .concat((managerTokens || []).map(t => t.address))
        .concat(
          (featuredTokenSets || [])
            .filter(token => String(token.chainId) === currentChainId)
            .map(t => t.address),
        ),
    );
  },
);

export const coingeckoTokenListUrlSelector = createSelector(
  currentChainOrDefaultSelector,
  (currentChain: string) => {
    switch (currentChain) {
      case NETWORK_CONSTANTS.POLYGON_CHAIN:
        return 'https://tokens.coingecko.com/polygon-pos/all.json';
      case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
        return 'https://tokens.coingecko.com/arbitrum-one/all.json';
      case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
        return 'https://tokens.coingecko.com/optimistic-ethereum/all.json';
      case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
        return 'https://tokens.coingecko.com/avalanche/all.json';
      default:
        return 'https://tokens.coingecko.com/uniswap/all.json';
    }
  },
);

export const coingeckoTokenListKeySelector = createSelector(
  currentChainOrDefaultSelector,
  (currentChain: string) => {
    switch (currentChain) {
      case NETWORK_CONSTANTS.POLYGON_CHAIN:
        return 'coingecko-polygon';
      case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
        return 'coingecko-arbitrum';
      case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
        return 'coingecko-optimism';
      case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
        return 'coingecko-avalanche';
      default:
        return 'coingecko-ethereum';
    }
  },
);
