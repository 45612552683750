import React from 'react';
import { ContentState, ContentBlock } from 'draft-js';
import ImageComponent from './ImageComponent';
import { CUSTOM_MEDIA_RENDER_TYPES } from '../enums';

export interface IMediaSelectorProps {
  contentState: ContentState;
  block: ContentBlock;
}

/**
 * Renders a BLOCK component based on passed in component Type. Specific to Draft.js editor interface.
 * Can be used to render images, videos, music etc...
 * @param props - Props passed in from Draft.js editor's blockRendererFn.
 */
const RenderMediaType = (props: IMediaSelectorProps) => {
  const { contentState, block } = props;

  const entity = contentState.getEntity(block.getEntityAt(0));
  const { url } = entity.getData();
  const type = entity.getType();

  switch (type) {
    case CUSTOM_MEDIA_RENDER_TYPES.SIMPLE_IMAGE:
      return <ImageComponent imageUrl={url} />;
    default:
      return <span>Error rendering the loaded data.</span>;
  }
};

export default RenderMediaType;
