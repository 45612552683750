import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite';

import { Button } from '..';
import { COLORS } from '../../constants/index';
import { WINDOW_DIMENSIONS } from '../../constants/index';

const { TABLET_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  StepButtons_stepButtonContainer: {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    textAlign: 'center',
    backgroundColor: COLORS.white,
    height: '100px',
    display: 'table',
  },
  StepButtons_stepButtonContainerBorder: {
    borderTop: `1px solid ${COLORS.gray}`,
  },
  StepButtons_buttonHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    height: '100%',
    margin: 'auto',
    [TABLET_MEDIA_QUERY]: {
      width: '100%',
    },
  },
  StepButtons_progressBar: {
    position: 'absolute',
    height: '3px',
    backgroundColor: COLORS.blue,
    transition: '0.2s',
  },
});
const buttonStyles = {
  backButton: {
    container: {
      width: '45%',
      height: '60px',
      borderRadius: '5px',
      backgroundColor: COLORS.white,
      border: `1px solid ${COLORS.blue}`,
      margin: '10px',
      ':hover': {
        backgroundColor: COLORS.white,
      },
    },
    text: {
      color: COLORS.blue,
    },
  },
  nextButton: {
    container: {
      width: '45%',
      height: '60px',
      borderRadius: '5px',
    },
    text: {
      color: COLORS.white,
    },
  },
};

interface IStepButtonsProps {
  progressPercent: number;
  onBack: (...args: any[]) => any;
  onNext: (...args: any[]) => any;
  disableNext: boolean;
  showProgressBar?: boolean;
}

class StepButtons extends PureComponent<IStepButtonsProps> {
  render() {
    const { onBack, onNext, progressPercent, disableNext, showProgressBar = true } = this.props;

    return (
      <div
        className={css(
          styles.StepButtons_stepButtonContainer,
          showProgressBar && styles.StepButtons_stepButtonContainerBorder,
        )}
      >
        {showProgressBar && (
          <div
            className={css(styles.StepButtons_progressBar)}
            style={{ width: `${progressPercent}%` }}
          />
        )}
        <div className={css(styles.StepButtons_buttonHolder)}>
          <Button text="Back" style={buttonStyles.backButton} onClick={onBack} />
          <Button
            text="Continue"
            style={buttonStyles.nextButton}
            onClick={onNext}
            isDisabled={disableNext}
          />
        </div>
      </div>
    );
  }
}

export default StepButtons;
