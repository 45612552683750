import { connect } from 'react-redux';

import Explore from './Explore';
import { changeNetworkFilter } from '../../actions/exploreActions';
import { setCustomSetsPageNumberWithQuery } from '../../actions/tableActions';
import { setCategoryFilter } from '../../actions/exploreActions';
import {
  paginatedDeployedSetsSelector,
  paginatedCommunitySetsSelector,
  setCategoryFilterSelector,
  selectedNetworksSelector,
} from './selectors';
import {
  isFetchingDeployedSetDetailsSelector,
  isFetchingTokenListSelector,
  featuredTokenSetsTokenListSelector,
} from '../../selectors/baseSelectors';
import { tableSelector } from '../../containers/Pagination/selectors';
import { TableDataSource } from '../../reducers/tableReducers';
import {
  fetchTokenSetsTokenList,
  fetchFeaturedTokenSetsTokenList,
} from '../../actions/tokenListsActions';
import { tokenSetsTokenListByAddressSelector } from '../../selectors/tokenListsSelectors';
import { fetchDeployedSetsWithLogos } from '../../actions/deployedSetsActions';
import { currentMainnetChainIdSelector } from '../../selectors/web3Selectors';

const mapStateToProps = (state: any) => {
  const {
    web3: { account },
    windowDimension,
  } = state;

  const featuredTokenSets = featuredTokenSetsTokenListSelector(state);
  const { setTokenPages, isFetchingNextPage, currentPageNumber } = tableSelector(
    state,
    TableDataSource.customSets,
  );
  const paginatedDeployedSetAddresses = paginatedDeployedSetsSelector(state);
  const paginatedCommunitySets = paginatedCommunitySetsSelector(state);
  const isFetchingDeployedSetDetails = isFetchingDeployedSetDetailsSelector(state);
  const tokenSetsTokenListByAddress = tokenSetsTokenListByAddressSelector(state);
  const isFetchingTokenList = isFetchingTokenListSelector(state);
  const setCategoryFilter = setCategoryFilterSelector(state);
  const selectedNetworks = selectedNetworksSelector(state);
  const currentMainnetChainId = currentMainnetChainIdSelector(state);

  return {
    currentMainnetChainId,
    account,
    featuredTokenSets,
    paginatedDeployedSetAddresses,
    paginatedCommunitySets,
    filteredSetCategory: setCategoryFilter,
    isFetchingDeployedSetDetails,
    windowDimension,
    isFetchingNextPage,
    currentPageNumber,
    setTokenPages,
    tokenSetsTokenListByAddress,
    isFetchingTokenList,
    selectedNetworks,
  };
};

const mapDispatchToProps = {
  setCustomSetsPageNumberWithQuery: setCustomSetsPageNumberWithQuery,
  fetchTokenSetsTokenList: fetchTokenSetsTokenList,
  fetchFeaturedTokenSetsTokenList: fetchFeaturedTokenSetsTokenList,
  fetchDeployedSetsWithLogos: fetchDeployedSetsWithLogos,
  onSetCategoryFilter: setCategoryFilter,
  changeNetworkFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(Explore);
