import { css } from 'aphrodite';
import React, { PureComponent } from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { withTranslation, WithTranslation } from 'react-i18next';

import WhatIsASet from './WhatIsASet';
import SetProducts from './SetProducts';
import WalletSetup from './WalletSetup';
import FundYourWallet from './FundYourWallet';
import FinishOnboarding from './FinishOnboarding';
import styles from './OnboardingStyles';
import { Button, MinimalNavBar } from '../../components/index';
import { IWindowDimensions, IHistory } from '../../typings/index';
import { handleLogin as handleLoginAction } from '../../actions/web3Actions';
import {
  GO_TO_NEXT_ONBOARDING_SLIDE,
  goToNextOnboardingSlide,
  goToPreviousOnboardingSlide,
  goToTargetOnboardingSlide,
} from '../../actions/onboardingActions';
import { updateAccountBalance as updateAccountBalanceAction } from '../../actions/balanceActions';

interface IOnboardingProps {
  readonly isLoggedIn: boolean;
  readonly currentSliderIndex: number;
  readonly currentWalletAddress?: string;
  readonly walletEthBalance: string;
  readonly history: IHistory;
  readonly windowDimension: IWindowDimensions;
  handleLogin: (...args: any[]) => any;
  goToNextSlide: (...args: any[]) => any;
  goToPreviousSlide: (...args: any[]) => any;
  goToTargetSlide: (...args: any[]) => any;
  updateAccountBalance: (...args: any[]) => any;
}

class Onboarding extends PureComponent<IOnboardingProps & WithTranslation> {
  slider: any = {
    slickNext: () => {},
    slickPrev: () => {},
    slickGoTo: () => {},
  };

  fetchEthBalanceInterval: any = undefined;

  /**
   * Lifecycle methods
   */
  componentDidMount() {
    document.title = 'Get Started | TokenSets';
    const { currentSliderIndex, updateAccountBalance } = this.props;
    this.slider.slickGoTo(currentSliderIndex, false);
    this.fetchEthBalanceInterval = setInterval(() => updateAccountBalance(), 5000);
  }

  componentDidUpdate() {
    const { isLoggedIn, currentSliderIndex, goToTargetSlide } = this.props;

    /* Set Slider State + Redux State to index 2 if user is not logged in */
    if (!isLoggedIn && currentSliderIndex > 2) {
      this.slider.slickGoTo(2);
      goToTargetSlide(2);
      return;
    }

    this.slider.slickGoTo(currentSliderIndex);
  }

  componentWillUnmount() {
    clearInterval(this.fetchEthBalanceInterval);
  }

  /* Called after Slider changes. Ensures Redux is synced with Slider State */
  public updateReduxSliderIndex = (newSlideIndex: number) => {
    const { goToTargetSlide } = this.props;

    goToTargetSlide(newSlideIndex);
  };

  public nextSlide = debounce(
    () => {
      const { isLoggedIn, handleLogin, currentSliderIndex, goToNextSlide } = this.props;

      if (!isLoggedIn && currentSliderIndex === 2) {
        handleLogin(GO_TO_NEXT_ONBOARDING_SLIDE);
        return;
      }

      goToNextSlide();
    },
    500,
    { leading: true },
  );

  public previousSlide = debounce(
    () => {
      const { goToPreviousSlide } = this.props;
      goToPreviousSlide();
    },
    500,
    { leading: true },
  );

  public goToExplorePage = () => {
    const { history } = this.props;
    history.push('/explore');
  };

  public renderNextButton = () => {
    const { isLoggedIn, currentSliderIndex, t } = this.props;
    if (currentSliderIndex === 4) {
      return (
        <Button
          className={css(styles.Onboarding_slideButton, styles.Onboarding_slideButtonFinish)}
          text={t('finish-onboarding.explore-more-sets')}
          onClick={this.goToExplorePage}
        />
      );
    }

    if (currentSliderIndex === 2 && !isLoggedIn) {
      return (
        <Button
          className={css(styles.Onboarding_slideButton)}
          text={t('common.sign-up-or-login')}
          onClick={this.nextSlide}
        />
      );
    }

    return (
      <Button
        className={css(styles.Onboarding_slideButton)}
        text={t('buttons.next')}
        onClick={this.nextSlide}
      />
    );
  };

  render() {
    const {
      currentWalletAddress,
      currentSliderIndex,
      walletEthBalance,
      windowDimension,
      history,
      t,
    } = this.props;
    const { isMobile } = windowDimension;

    return (
      <>
        <div className={css(styles.Onboarding_wrapper)}>
          <MinimalNavBar view="Onboarding" />
          <div className={css(styles.Onboarding_container)}>
            <Slider
              dots
              draggable={isMobile}
              infinite={false}
              ref={c => (this.slider = c)}
              afterChange={this.updateReduxSliderIndex}
            >
              <WhatIsASet />
              <SetProducts />
              <WalletSetup />
              <FundYourWallet
                walletAddress={currentWalletAddress}
                ethBalance={walletEthBalance}
                windowDimension={windowDimension}
              />
              <FinishOnboarding history={history} windowDimension={windowDimension} />
            </Slider>
          </div>
        </div>
        <div className={css(styles.Onboarding_buttonWrapper)}>
          <Button
            isDisabled={currentSliderIndex === 0}
            className={css(styles.Onboarding_slideButton)}
            color="darkBlue"
            text={t('buttons.back')}
            onClick={this.previousSlide}
          />
          {this.renderNextButton()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    walletEthBalance: state.balance.ethBalance,
    currentWalletAddress: state.web3.account,
    currentSliderIndex: state.onboarding.currentOnboardingSlideIndex,
    isLoggedIn: state.web3.isLoggedIn,
  };
};

const mapDispatchToProps = {
  handleLogin: handleLoginAction,
  goToNextSlide: goToNextOnboardingSlide,
  goToPreviousSlide: goToPreviousOnboardingSlide,
  goToTargetSlide: goToTargetOnboardingSlide,
  updateAccountBalance: updateAccountBalanceAction,
};

const translated = withTranslation('onboarding')(Onboarding);

export default connect(mapStateToProps, mapDispatchToProps)(translated);
