import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import x from '../../img/icons/x-close.svg';

const styles = StyleSheet.create({
  Container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    ':hover': {
      opacity: '50%',
    },
  },
  X: {
    width: '25px',
    height: '25px',
  },
});

interface CloseButtonProps {
  handleClick: (...args: any[]) => void;
}

export default function CloseButton({ handleClick }: CloseButtonProps) {
  return (
    <div className={css(styles.Container)} onClick={handleClick}>
      <img className={css(styles.X)} src={x} />
    </div>
  );
}
