import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { COLORS } from '../../constants/index';

const styles = StyleSheet.create({
  Button: {
    height: '36px',
    width: '150px',
    padding: 0,
    border: `1px solid ${COLORS.darkBlue2}`,
    backgroundColor: COLORS.white,
    fontWeight: 500,
    color: COLORS.darkBlue2,
  },
  Hover: {
    ':hover': {
      backgroundColor: COLORS.darkBlue2,
      color: COLORS.white,
    },
  },
  Flex: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '16px',
    marginTop: '1px', // Vertically center button text by 1 px
  },
  ImgContainer: {
    width: '40%',
    height: '100%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    paddingRight: '10px',
  },
  LabelContainer: {
    width: '60%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid red',
  },
  Img: {
    width: '20px',
    marginRight: '10px',
    color: `${COLORS.darkBlue2}`,
    ':hover': {
      textColor: `${COLORS.white}`,
    },
  },
});

interface TertiartyButtonProps {
  disabled?: boolean;
  label?: string;
  img?: string;
  onClick?: (...args: any[]) => any;
}

export default function TertiartyButton({ disabled, label, img, onClick }: TertiartyButtonProps) {
  return (
    <button disabled={disabled} onClick={onClick} className={css(styles.Button, styles.Hover)}>
      <div className={css(styles.Flex)}>
        {img && <img className={css(styles.Img)} src={img} />}
        {label}
      </div>
    </button>
  );
}
