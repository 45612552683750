import { NETWORK_CONSTANTS } from '../constants/index';
import { baseFeeSelector } from '../selectors/baseSelectors';
import { currentChainOrDefaultSelector } from '../selectors/web3Selectors';
import axios from 'axios';
import { toast } from 'react-toastify';
import { payloadActionGenerator } from '../utils/reduxHelpers';
import { tokenFromBaseUnits } from '../utils/formatUtils';
import BigNumber from '../utils/bigNumber';

export const USE_DEFAULT = 'USE_DEFAULT';
export const OPTIMISM_FIXED_OVERHEAD = 2100;
export const OPTIMISM_FEE_SCALAR = 1.24;
export const UPDATE_GAS_PRICE = 'UPDATE_AVERAGE_GAS_PRICE';
export const UPDATE_FEE_VALUES = 'UPDATE_FEE_VALUES';

export const updateGasPrice = payloadActionGenerator(UPDATE_GAS_PRICE);
export const updateFeeValues = payloadActionGenerator(UPDATE_FEE_VALUES);

// NOTE (from optimism docs): The L1 gas price used to charge the data fee is automatically updated
// when new data is received from Ethereum. Spikes in Ethereum gas prices may result in users paying
// a higher or lower than estimated L1 data fee, by up to 25%.
//
// Layer 1 Gas Fee = Fee Scalar * L1 Gas Price * (Calldata + Fixed Overhead)
// Fixed overhead = 2100
// Fee scalar = 1.24X
//
// These costs are configurable by Optimism and may change. See Optimism Docs:
// https://community.optimism.io/docs/developers/build/transaction-fees/#the-l1-data-fee
export const getOptimismL1GasCost = (l1GasPrice: string, calldata: string): BigNumber => {
  if (!calldata) return new BigNumber(0);

  let total = 0;

  // String hex-prefixed, 1 byte = 2 hex chars
  for (let i = 2; i < calldata.length; i++) {
    if (i % 2 === 0) {
      total = calldata[i] === '0' && calldata[i + 1] === '0' ? total + 4 : total + 16;
    }
  }

  // Add 68 * 16 to the end to account for the 68 bytes for r, s, v and their respective
  // RLP prefixes all of which are assumed to be non-zero
  const rsvCost = 1088;
  const estimate = Math.floor(
    (total + rsvCost + OPTIMISM_FIXED_OVERHEAD) * OPTIMISM_FEE_SCALAR,
  ).toString();
  const formattedEstimate = tokenFromBaseUnits(estimate);

  return formattedEstimate.mul(l1GasPrice || '0');
};

// Fetches the Ethereum L1 gas price, captures, and then resets state to the current network gas price
export function getEthereumL1GasPrice() {
  return async (dispatch: Function, getState: Function) => {
    const state = getState();

    await dispatch(getGasPrice(true));
    const l1GasPrice = baseFeeSelector(state);
    await dispatch(getGasPrice());
    return l1GasPrice;
  };
}

// Passing in true allows you to force a gas price fetch for the Ethereum network
// This is necessary when calculating Optimism gas fees which are composites of L1 & L2 gas usage
export function getGasPrice(useDefault?: boolean) {
  return async (dispatch: Function, getState: Function) => {
    const state = getState();
    const currentChain = useDefault ? USE_DEFAULT : currentChainOrDefaultSelector(state);

    switch (currentChain) {
      case NETWORK_CONSTANTS.POLYGON_CHAIN:
        try {
          const gasPriceResponse = await axios.get('https://gasstation-mainnet.matic.network/', {
            transformRequest: (_: any, headers) => {
              delete headers['X-SET-USER'];
            },
          });

          if (gasPriceResponse?.data) {
            const payload = {
              standard_gwei: gasPriceResponse.data.standard,
              fast_gwei: gasPriceResponse.data.fast,
              rapid_gwei: gasPriceResponse.data.fastest,
            };
            dispatch(updateGasPrice(payload));

            return payload;
          }
          toast.warn('Gas fetching returned an unexpected response. Please try again later.', {
            toastId: 'fetch-gas',
          });
          return;
        } catch (err) {
          if (!err) return;
          toast.warn('Unable to fetch a proper gas price right now. Please try again later.', {
            toastId: 'fetch-gas',
          });
        }
        break;
      case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
        try {
          const ALCHEMY_API_KEY = 'GPaQryWLFnCJtzbE1Xiz093i5nLllCmO';
          const gasPriceResponse = await axios.post(
            `https://opt-mainnet.g.alchemy.com/v2/${ALCHEMY_API_KEY}`,
            {
              jsonrpc: '2.0',
              method: 'eth_gasPrice',
              params: [],
              id: 0,
            },
            {
              transformRequest: (data: any, headers: any) => {
                delete headers['X-SET-USER'];
                return JSON.stringify(data);
              },
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            },
          );

          if (gasPriceResponse?.data?.result) {
            const gasPrice = parseInt(gasPriceResponse.data.result, 16);
            const payload = {
              standard_wei: gasPrice,
              fast_wei: gasPrice,
              rapid_wei: gasPrice,
            };
            dispatch(updateGasPrice(payload));

            return payload;
          }
          toast.warn('Gas fetching returned an unexpected response. Please try again later.', {
            toastId: 'fetch-gas',
          });
          return;
        } catch (err) {
          if (!err) return;
          toast.warn('Unable to fetch a proper gas price right now. Please try again later.', {
            toastId: 'fetch-gas',
          });
        }
        break;
      default:
        try {
          const apiKeys = [
            'T6KC6WN3XSQTDQ9SGMRUX9QRRRJ4GIVJJ5',
            'FBIZUN35X8VYBCR7XC88U6MT934ZPECBD7',
            'N3TP167B1YHKG7NHXEGQ859VQG3T7PXJ6C',
          ];
          const randomApiKey = apiKeys[Math.floor(Math.random() * apiKeys.length)];

          const gasPriceResponse = await axios.get(
            `https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=${randomApiKey}`,
            {
              transformRequest: (data: any, headers) => {
                delete headers['X-SET-USER'];
                return JSON.stringify(data);
              },
            },
          );

          if (gasPriceResponse?.data) {
            const payload = {
              standard_gwei: gasPriceResponse.data.result.SafeGasPrice,
              fast_gwei: gasPriceResponse.data.result.FastGasPrice,
              rapid_gwei: gasPriceResponse.data.result.FastGasPrice,
            };
            dispatch(updateGasPrice(payload));

            dispatch(
              updateFeeValues({
                baseFee: parseInt('10', gasPriceResponse.data.result.suggestBaseFee || 16),
                maxPriorityFeePerGas: 2,
              }),
            );
            return payload;
          }
          toast.warn('Gas fetching returned an unexpected response. Please try again later.', {
            toastId: 'fetch-gas',
          });
        } catch (error) {
          // Unable to get base fee. Should not affect getting gas price though
          toast.warn(
            'There were some issues fetching the gas fees. Gas calculation might be off.',
            {
              toastId: 'fetch-base-fee',
            },
          );
        }
    }
  };
}
