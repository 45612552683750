import { connect } from 'react-redux';

import DebtRedemptionForm from './DebtRedemptionForm';
import { resetTransactionWatcher } from '../../actions/transactionWatcherActions';
import { updateRedemptionQuantity } from '../../actions/redemptionV2Actions';
import {
  setMaxDebtRedeemableQuantity,
  debtRedeemCurrentSet,
  fetchFeesForRedeemQuantity,
} from '../../actions/debtRedemptionActions';
import { currentSetDetailsSelector } from '../../selectors/setDetailsSelectors';
import {
  userHasSufficientFundsForDebtRedemptionQuantity,
  isDebtRedemptionReadySelector,
  debtRedemptionInputComponentsSelector,
  debtRedemptionOutputComponentsSelector,
} from '../../selectors/debtRedemptionSelectors';
import {
  redemptionInputQuantityV2Selector,
  erc20BalancesSelector,
  transactionIdSelector,
  windowDimensionSelector,
  customV2SetPathPrefixSelector,
  setDetailsCurrentSetAddressSelector,
  isFetchingDebtRedemptionFeesSelector,
  debtRedemptionFeesSelector,
} from '../../selectors/index';

const mapStateToProps = (state: any) => {
  const isDebtRedemptionReady = isDebtRedemptionReadySelector(state);
  const userHasSufficientFunds = userHasSufficientFundsForDebtRedemptionQuantity(state);
  const setDetails = currentSetDetailsSelector(state);
  const inputComponents = debtRedemptionInputComponentsSelector(state);
  const outputComponents = debtRedemptionOutputComponentsSelector(state);
  const redemptionQuantity = redemptionInputQuantityV2Selector(state);
  const currentTransactionId = transactionIdSelector(state);
  const erc20BalancesByAddress = erc20BalancesSelector(state);
  const windowDimensions = windowDimensionSelector(state);
  const customV2SetPathPrefix = customV2SetPathPrefixSelector(state);
  const setAddress = setDetailsCurrentSetAddressSelector(state);
  const debtRedemptionFees = debtRedemptionFeesSelector(state);
  const isFetchingDebtRedemptionFees = isFetchingDebtRedemptionFeesSelector(state);

  return {
    debtRedemptionFees,
    isFetchingDebtRedemptionFees,
    setAddress,
    customV2SetPathPrefix,
    isDebtRedemptionReady,
    userHasSufficientFunds,
    setDetails,
    inputComponents,
    outputComponents,
    redemptionQuantity,
    currentTransactionId,
    erc20BalancesByAddress,
    windowDimensions,
  };
};

const mapDispatchToProps = {
  updateRedemptionQuantity,
  setMaxRedeemableQuantity: setMaxDebtRedeemableQuantity,
  debtRedeemCurrentSet,
  resetTransactionWatcher,
  fetchFeesForRedeemQuantity,
};

export default connect(mapStateToProps, mapDispatchToProps)(DebtRedemptionForm);
