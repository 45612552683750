import { StyleSheet, css } from 'aphrodite';
import React from 'react';
import { Link } from 'react-router-dom';

import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';
import { withTranslation, WithTranslation } from 'react-i18next';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'left',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '15px 15px 0 15px',
    [WINDOW_DIMENSIONS.MOBILE_MEDIA_QUERY]: {
      padding: '15px 0 0 0',
    },
  },
  link: {
    color: COLORS.darkGray,
    marginBottom: '10px',
    transition: '0.2s',
    cursor: 'pointer',
    ':hover': {
      color: COLORS.darkBlue,
    },
  },
  langIcon: {
    position: 'relative',
    top: '3px',
    cursor: 'pointer',
    colors: COLORS.darkGray,
    marginRight: '10px',
    height: '16px',
    width: 'auto',
  },
});

const FooterLinks: React.SFC<WithTranslation> = ({ t }) => (
  <div className={css(styles.container)}>
    <a href="https://docs.tokensets.com" target="_blank" className={css(styles.link)}>
      {t('docs')}
    </a>
    <Link to="/terms" href="/" className={css(styles.link)}>
      {t('terms-of-service')}
    </Link>
    <Link to="/privacy" href="/" className={css(styles.link)}>
      {t('privacy-policy')}
    </Link>
    <Link to="/ccpa" href="/" className={css(styles.link)}>
      {t('ccpa')}
    </Link>
  </div>
);

export default withTranslation('footer')(FooterLinks);
