import {
  REQUEST_SET_MANAGER,
  RECEIVE_V2_SET_MANAGER,
  REQUEST_DELEGATED_SET_MANAGER_FAILED,
  RECEIVE_DELEGATED_SET_MANAGER,
  REQUEST_DELEGATED_SET_MANAGER,
} from '../actions/setManagerActions';
import { ISetModule, IV2SetModule } from '../typings/index';

const initialState = {
  isFetchingSetManager: false,
  isFetchingDelegatedManager: false,
  v1: {
    id: '',
    version: '',
    image: '',
    name: '',
    enabledModules: [] as ISetModule[],
    disabledModules: [] as ISetModule[],
  },
  v2: {
    name: '',
    enabledModules: [] as IV2SetModule[],
    disabledModules: [] as IV2SetModule[],
  },
  delegatedManager: {
    useAssetAllowlist: {},
    ownerAddress: {},
    operatorAddresses: {},
    assetAllowlistAddresses: {},
  },
};

const setManagerReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case REQUEST_SET_MANAGER:
      return {
        ...state,
        isFetchingSetManager: true,
      };

    case RECEIVE_V2_SET_MANAGER:
      return {
        v1: state.v1,
        v2: {
          name: action.payload?.name,
          enabledModules: action.payload?.enabledModules || [],
          disabledModules: action.payload?.disabledModules || [],
        },
        delegatedManager: state.delegatedManager,
        isFetchingSetManager: false,
      };

    case REQUEST_DELEGATED_SET_MANAGER:
      return {
        ...state,
        delegatedManager: initialState.delegatedManager,
        isFetchingDelegatedManager: true,
      };

    case RECEIVE_DELEGATED_SET_MANAGER:
      return {
        v1: state.v1,
        v2: state.v2,
        // TODO: This is the wrong abstraction to use for delegated manager data.
        // Move this to another reducer, index all properties by a single key, or remove
        // address indexing altogether.
        delegatedManager: {
          useAllowlist: {
            [action.payload.setAddress]: action.payload.useAssetAllowlist,
          },
          ownerAddress: {
            [action.payload.setAddress]: action.payload.ownerAddress,
          },
          operatorAddresses: {
            ...state.delegatedManager.operatorAddresses,
            [action.payload.setAddress]: action.payload.operatorAddresses,
          },
          assetAllowlistAddresses: {
            ...state.delegatedManager.assetAllowlistAddresses,
            [action.payload.setAddress]: action.payload.assetAllowlistAddresses,
          },
        },
        isFetchingDelegatedManager: false,
      };

    case REQUEST_DELEGATED_SET_MANAGER_FAILED:
      return {
        ...state,
        isFetchingDelegatedManager: false,
      };

    default:
      return state;
  }
};

export default setManagerReducer;
