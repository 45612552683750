import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Icon } from 'semantic-ui-react';

import { SOCIAL_MEDIA_ICONS, WINDOW_DIMENSIONS } from '../../constants/index';

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  paddingHorizontal: {
    paddingLeft: '0.8rem',
    paddingRight: '0.8rem',
  },
  icon: {
    transition: '0.2s ease-out',
    ':hover': {
      transform: 'scale(1.15)',
    },
  },
  semanticIcon: {
    color: '#a2a2c3',
    fontSize: '37px',
    [MOBILE_MEDIA_QUERY]: {
      width: '38px',
    },
  },
});

interface ISocialMediaIconProps {
  path: string;
  icon: string;
  alt: string;
  index: number;
}

class SocialMediaIcon extends PureComponent<ISocialMediaIconProps, {}> {
  render() {
    const { path, icon, alt } = this.props;
    return (
      <a
        className={css(styles.paddingHorizontal)}
        href={path}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={icon} className={css(styles.icon)} alt={alt} />
      </a>
    );
  }
}

interface SocialMediaIconsProps {
  className?: string;
}

class SocialMediaIcons extends PureComponent<SocialMediaIconsProps> {
  static defaultProps() {
    return {
      className: '',
    };
  }

  render() {
    const { className } = this.props;
    return (
      <div className={className}>
        <a href="https://discord.gg/x3PGVfP" target="_blank" rel="noopener noreferrer">
          <Icon
            name="discord"
            className={css(styles.semanticIcon, styles.icon)}
            alt="discord icon"
          />
        </a>
        {SOCIAL_MEDIA_ICONS.map((constant, index) => (
          <SocialMediaIcon
            key={constant.name}
            path={constant.path}
            icon={constant.icon}
            alt={constant.alt}
            index={index}
          />
        ))}
      </div>
    );
  }
}

export default SocialMediaIcons;
