import { css, StyleSheet } from 'aphrodite';
import React, { PureComponent } from 'react';
import { Header, Icon, Modal, TextArea } from 'semantic-ui-react';

import { Button } from '../../components/index';
import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';
import { getMessageSignature, getWeb3Instance } from '../../utils/index';
import CloseIcon from '../../components/CloseIcon';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CustomSetDetailsResponse } from '../../typings/index';

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  EditSetDetailsModal_container: {
    transition: '0.2s',
    textAlign: 'center',
    backgroundColor: COLORS.white,
    borderRadius: '4px',
    padding: '0px',
    [MOBILE_MEDIA_QUERY]: {
      padding: '12px',
      top: '20px',
    },
  },
  EditSetDetailsModal_header: {
    display: 'flex',
    marginBottom: '20px',
  },
  EditSetDetailsModal_title: {
    color: COLORS.darkBlue,
    fontWeight: 500,
    fontSize: '24px',
  },
  EditSetDetailsModal_text: {
    color: COLORS.darkBlue,
  },
  EditSetDetailsModal_textArea: {
    fontSize: '14px',
    fontFamily: 'Graphik, Arial, sans-serif',
    color: COLORS.darkBlue,
    width: '100%',
    height: '100px',
    padding: '20px',
    marginBottom: '20px',
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '4px',
  },
  EditSetDetailsModal_cta: {
    margin: 'auto',
    marginTop: '40px',
  },
  EditSetDetailsModal_headerIcon: {
    height: '55px',
    width: '55px',
    fontSize: '55px',
    marginBottom: '20px',
    color: COLORS.blue,
  },
  EditSetDetailsModal_headerText: {
    color: COLORS.darkBlue,
    marginBottom: '32px',
    marginTop: '20px',
    width: '100%',
    fontWeight: 500,
  },
});

interface IEditSetDetailsModalProps {
  account: string;
  isModalOpen: boolean;
  setAddress: string;
  isFetchingCustomSetDetails: boolean;
  isInvalidCustomSetDetails: boolean;
  customSetDetails: CustomSetDetailsResponse;
  onCloseModal: (...args: any[]) => any;
}

interface IEditSetDetailsModalState {
  signature: string;
}

/**
 * @title EditSetDetailsModal
 * @author Set Labs
 *
 * The EditSetDetailsModal allows users to sign a version number to generate a signature for their custom set details entry
 */
class EditSetDetailsModal extends PureComponent<
  IEditSetDetailsModalProps & WithTranslation,
  IEditSetDetailsModalState
> {
  state: IEditSetDetailsModalState = {
    signature: undefined,
  };

  public render() {
    const {
      isModalOpen,
      isFetchingCustomSetDetails,
      customSetDetails,
      onCloseModal,
      account,
      t,
    } = this.props;
    const { signature } = this.state;
    const version = customSetDetails?.version || 1;

    return (
      <Modal
        closeIcon={<CloseIcon onClick={onCloseModal} />}
        open={isModalOpen}
        onClose={onCloseModal}
        size="tiny"
        className={`${css(styles.EditSetDetailsModal_container)} scrolling`}
      >
        <Modal.Content>
          <div>
            <Icon name="pencil" className={css(styles.EditSetDetailsModal_headerIcon)} />
            <Header as="h3" className={css(styles.EditSetDetailsModal_headerText)}>
              {t('edit-set-details-modal.title')}
            </Header>
            <div className={css(styles.EditSetDetailsModal_text)}>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('edit-set-details-modal.description', {
                    repositoryLink: `
                    <a
                      href="https://docs.tokensets.com/set-customisation"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Set Customization Guide
                    </a>
                  `,
                  }),
                }}
              />
            </div>
            <div className={css(styles.EditSetDetailsModal_cta)}>
              {signature ? (
                <TextArea
                  className={css(styles.EditSetDetailsModal_textArea)}
                  rows={3}
                  value={signature}
                />
              ) : (
                <Button
                  text="Generate Signature"
                  iconType="icon"
                  isDisabled={isFetchingCustomSetDetails}
                  onClick={async () => {
                    const web3Instance = await getWeb3Instance();
                    const signature = await getMessageSignature(
                      web3Instance,
                      `Version ${version}`,
                      account,
                    );

                    this.setState({ signature });
                  }}
                />
              )}
            </div>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

export default withTranslation('components')(EditSetDetailsModal);
