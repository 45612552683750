import React, { PureComponent } from 'react';
import SearchInput, { createFilter } from 'react-search-input';
import { StyleSheet, css } from 'aphrodite';
import { Link } from 'react-router-dom';

import { COLORS } from '../../constants/index';
import magnifyingGlassActive from '../../img/icons/magnifying-glass-active.svg';
import { ISet } from '../../typings/index';

const KEYS_TO_FILTERS = ['symbol', 'name'];

const styles = StyleSheet.create({
  SetExplorer_searchInput: {
    height: '35px',
    width: '100%',
    paddingTop: '20px',
    paddingBottom: '18px',
    border: '1px solid #E1E1EF',
    borderRadius: '15px',
    boxShadow: '0 2px 4px 0 rgba(10,9,50,0.11)',
    background: `linear-gradient(
         rgba(0, 0, 0, 0.05),
         rgba(0, 0, 0, 0.05)
       ),
       url(${magnifyingGlassActive})
       no-repeat scroll 20px 20px`,
    backgroundPosition: '10px 10px',
    paddingLeft: '40px',
    fontSize: '12px',
    ':focus': {
      background: `url(${magnifyingGlassActive}) no-repeat scroll 10px 10px`,
    },
  },
  SetExplorer_dropdown: {
    position: 'absolute',
    zIndex: 100,
    width: 'inherit',
  },
  SetExplorer_dropdownTable: {
    width: '100%',
    alignItems: 'center',
  },
  SetExplorer_dropdownElement: {
    width: '100%',
    height: '76px',
    display: 'flex',
    alignItems: 'center',
    borderLeft: `1px solid ${COLORS.gray}`,
    borderRight: `1px solid ${COLORS.gray}`,
    borderBottom: `1px solid ${COLORS.gray}`,
    backgroundColor: COLORS.white,
    textAlign: 'left',
    ':hover': {
      backgroundColor: `${COLORS.lightGray}`,
    },
  },
  SetExplorer_tokenIcon: {
    height: '30px',
    width: '30px',
    margin: '15px',
  },
  SetExplorer_tokenName: {
    width: '90%',
    fontWeight: 'bold',
    fontSize: '12px',
    color: COLORS.darkBlue,
  },
  SetExplorer_tokenSymbol: {
    paddingRight: '25px',
    fontSize: '12px',
  },
  SetExplorer_tokenSearch: {
    position: 'relative',
    height: '125px',
    display: 'table-cell',
    verticalAlign: 'middle',
    width: '100%',
  },
  SetExplorer_clearSearch: {
    position: 'absolute',
    height: '35px',
    lineHeight: '40px',
    top: '0px',
    paddingRight: '20px',
    cursor: 'pointer',
    textAlign: 'right',
    fontSize: '12px',
    width: '100%',
  },
});

interface ISetExplorerProps {
  sets: ISet[];
  onClick?: (...args: any[]) => any;
}

interface ISetExplorerState {
  searchTerm: string;
}

class SetExplorer extends PureComponent<ISetExplorerProps, ISetExplorerState> {
  static get defaultProps() {
    return {
      onClick: () => {},
    };
  }

  constructor(props: ISetExplorerProps) {
    super(props);
    this.state = {
      searchTerm: '',
    };
  }

  searchUpdated = (term: string) => {
    this.setState({ searchTerm: term });
  };

  handleClick = (set: ISet) => {
    const { onClick } = this.props;

    this.setState({ searchTerm: '' });
    onClick(set);
  };

  render() {
    const { searchTerm } = this.state;
    const { sets } = this.props;

    const filteredSets = sets.filter(createFilter(searchTerm, KEYS_TO_FILTERS));

    return (
      <div className={css(styles.SetExplorer_tokenSearch)}>
        <SearchInput
          fuzzy
          sortResults
          inputClassName={css(styles.SetExplorer_searchInput)}
          placeholder="Search via Set name or symbol"
          onChange={this.searchUpdated}
        />
        <div className={css(styles.SetExplorer_dropdown)}>
          {searchTerm.length > 0 &&
            filteredSets.map(set => (
              <div className={css(styles.SetExplorer_dropdownTable)} key={`search-${set.id}`}>
                <Link to={`/set/${set.id}`}>
                  <div
                    className={css(styles.SetExplorer_dropdownElement)}
                    onClick={() => this.handleClick(set)}
                    onKeyPress={() => this.handleClick(set)}
                    role="checkbox"
                    aria-checked="false"
                    tabIndex={0}
                  >
                    <span>
                      <img
                        className={css(styles.SetExplorer_tokenIcon)}
                        src={
                          set.image.length === 0
                            ? 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/placeholder_icon.svg'
                            : set.image
                        }
                        alt="icon"
                      />
                    </span>
                    <span className={css(styles.SetExplorer_tokenName)}>{set.name}</span>
                    <span className={css(styles.SetExplorer_tokenSymbol)}>{set.symbol}</span>
                  </div>
                </Link>
              </div>
            ))}
        </div>
        {searchTerm.length > 0 && (
          <span className={css(styles.SetExplorer_clearSearch)}>
            <div onClick={() => this.searchUpdated('')}>X</div>
          </span>
        )}
      </div>
    );
  }
}

export default SetExplorer;
