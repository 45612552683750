const streamingFeeModuleABI = [
  {
    inputs: [
      {
        internalType: 'contract IController',
        name: '_controller',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: '_setToken',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: '_managerFee',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: '_protocolFee',
        type: 'uint256',
      },
    ],
    name: 'FeeActualized',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: '_setToken',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: '_newFeeRecipient',
        type: 'address',
      },
    ],
    name: 'FeeRecipientUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: '_setToken',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: '_newStreamingFee',
        type: 'uint256',
      },
    ],
    name: 'StreamingFeeUpdated',
    type: 'event',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
    ],
    name: 'accrueFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'controller',
    outputs: [
      {
        internalType: 'contract IController',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '',
        type: 'address',
      },
    ],
    name: 'feeStates',
    outputs: [
      {
        internalType: 'address',
        name: 'feeRecipient',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'maxStreamingFeePercentage',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'streamingFeePercentage',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'lastStreamingFeeTimestamp',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
    ],
    name: 'getFee',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        components: [
          {
            internalType: 'address',
            name: 'feeRecipient',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'maxStreamingFeePercentage',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'streamingFeePercentage',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'lastStreamingFeeTimestamp',
            type: 'uint256',
          },
        ],
        internalType: 'struct StreamingFeeModule.FeeState',
        name: '_settings',
        type: 'tuple',
      },
    ],
    name: 'initialize',
    outputs: [] as any[],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'removeModule',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_newFeeRecipient',
        type: 'address',
      },
    ],
    name: 'updateFeeRecipient',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_newFee',
        type: 'uint256',
      },
    ],
    name: 'updateStreamingFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

export default streamingFeeModuleABI;
