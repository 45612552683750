import { StyleSheet, css } from 'aphrodite';
import React from 'react';
import { Header } from 'semantic-ui-react';

import { Text } from '..';
import { COLORS } from '../../constants/index';
import { IAmounts, IColorMapping, IListToken } from '../../typings/index';
import { BigNumber, getListTokenImage } from '../../utils/index';
import { generateColorFromString } from '../../utils/colorUtils';

const styles = StyleSheet.create({
  SetCard_allocationsContainer: {
    backgroundColor: COLORS.lightGray2,
    overflowY: 'scroll',
    maxHeight: '300px',
    borderTop: `1px solid ${COLORS.gray}`,
  },
  SetCard_allocationBarContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  SetCard_allocationBar: {
    borderRadius: '25px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '7px',
    marginRight: '7px',
    minWidth: '100px',
  },
  SetCard_allocationBarImg: {
    width: '35px',
    height: '35px',
    borderRadius: '50%',
  },
  SetCard_allocationBarSymbol: {
    color: COLORS.white,
    fontSize: '12px',
    fontWeight: 800,
    marginLeft: '10px',
  },
  SetCard_allocationPercentage: {
    color: COLORS.darkBlue,
  },
  SetCard_container: {
    background: 'white',
    borderRadius: '4px',
    overflow: 'hidden',
    border: `1px solid ${COLORS.gray}`,
    width: '100%',
    height: 'auto',
    minHeight: '285px',
    padding: '0',
    boxShadow: '0 2px 4px 0 rgba(186, 182, 229, 0.5)',
    transition: '0.2s',
    ':active': {
      outline: 0,
      '-moz-outline-style': 'none',
    },
    ':focus': {
      outline: 0,
      '-moz-outline-style': 'none',
    },
  },
  SetCard_contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    textAlign: 'left',
  },
  SetCard_contentContainerPadding: {
    padding: '30px',
  },
  SetCard_description: {
    textAlign: 'left',
    wordBreak: 'break-word',
  },
  SetCard_descriptionContainer: {},
  SetCard_header: {
    marginTop: '5px',
    marginBottom: '10px',
    lineHeight: '24px',
  },
  SetCard_price: {
    marginTop: '15px',
    lineHeight: '24px',
  },
  SetCard_headerContainer: {
    marginLeft: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'start',
  },
});

export interface ISetCardProps {
  setName?: string;
  setSymbol?: string;
  setPrice?: number;
  tokenColors?: IColorMapping;
  tokens?: IListToken[];
  tokenAmounts?: IAmounts;
}

/**
 * @title SetCard
 * @author Set Protocol
 *
 * The SetCard class functions like a thumbnail summarizing important information about a given set.
 * When used to preview a not yet created set, setName, setIcon, and setDescription are used.
 * When used to display an existing set, the set param is used.
 */
const SetCard: React.SFC<ISetCardProps> = ({
  setName,
  setSymbol,
  setPrice,
  tokens,
  tokenAmounts,
  tokenColors,
}) => {
  return (
    <div className={css(styles.SetCard_container)}>
      <div className={css(styles.SetCard_contentContainer, styles.SetCard_contentContainerPadding)}>
        <div className={css(styles.SetCard_headerContainer)}>
          <Header as="h3" className={css(styles.SetCard_header)}>
            {setName}
          </Header>
          <div className={css(styles.SetCard_descriptionContainer)}>
            <Text tag="p" className={css(styles.SetCard_description)}>
              {setSymbol}
            </Text>
          </div>
          <Header as="h3" className={css(styles.SetCard_price)}>
            ${new BigNumber(setPrice).toFixed(2)}
          </Header>
        </div>
      </div>
      <div
        className={css(styles.SetCard_allocationsContainer, styles.SetCard_contentContainerPadding)}
      >
        {tokens.map(token => {
          const tokenAmount = tokenAmounts[token.symbol].mul(100);
          return (
            <div className={css(styles.SetCard_allocationBarContainer)} key={token.symbol}>
              <div
                className={css(styles.SetCard_allocationBar)}
                key={token.symbol}
                style={{
                  width: `calc(100px + ${tokenAmount.toFixed(2)}%)`,
                  backgroundColor: tokenColors[token.symbol]
                    ? tokenColors[token.symbol]
                    : `#${generateColorFromString(token.address)}`,
                }}
              >
                <img
                  src={getListTokenImage(token)}
                  className={css(styles.SetCard_allocationBarImg)}
                  alt={token.name}
                />
                <span className={css(styles.SetCard_allocationBarSymbol)}>{token.symbol}</span>
              </div>
              <span className={css(styles.SetCard_allocationPercentage)}>
                {tokenAmount.toFixed(2)}%
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SetCard;
