import { IAction } from '../../typings/index';
import {
  INCREMENT_PAGE_NUMBER,
  DECREMENT_PAGE_NUMBER,
  SET_PAGE_NUMBER,
  SET_SORT_COLUMN,
} from '../../actions/tableActions';
import { SortType } from './enums';

export interface ITableState {
  currentPageNumber: number;
  sortColumn: SortType;
  isAscending: boolean;
}

const initialState: ITableState = {
  currentPageNumber: 1,
  sortColumn: SortType.marketCap,
  isAscending: false,
};

const paginationReducer = (state = initialState, action: IAction): ITableState => {
  switch (action.type) {
    case INCREMENT_PAGE_NUMBER:
      return {
        ...state,
        currentPageNumber: state.currentPageNumber + 1,
      };
    case DECREMENT_PAGE_NUMBER:
      return {
        ...state,
        currentPageNumber: state.currentPageNumber - 1,
      };
    case SET_PAGE_NUMBER:
      return {
        ...state,
        currentPageNumber: action.payload,
      };
    case SET_SORT_COLUMN:
      if (state.sortColumn === action.payload) {
        return {
          ...state,
          isAscending: !state.isAscending,
        };
      }
      return {
        ...state,
        sortColumn: action.payload,
        isAscending: false,
      };
    default:
      return state;
  }
};

export default paginationReducer;
