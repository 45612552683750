import React from 'react';
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
  Container: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    width: '26px',
    height: '26px',
    marginRight: '0.5em',
  },
  title: {
    fontWeight: 600,
  },
});

interface SetTitleProps {
  title?: any;
  setLogo?: any;
}

export default function SetTitle({ title, setLogo }: SetTitleProps) {
  // TODO: fetch the Set Token's custom image if available, default to generic icon
  return (
    <div className={css(styles.Container)}>
      <img src={setLogo} alt="Set icon" className={css(styles.logo)} />
      <p className={css(styles.title)}>{title}</p>
    </div>
  );
}
