import { Magic } from 'magic-sdk';
import Fortmatic from 'fortmatic';

const FORTMATIC_API_KEY = 'pk_live_59D0D65036D8554C';
export const fm = new Fortmatic(FORTMATIC_API_KEY);
export const fmProvider = fm.getProvider();

const FORTMATIC_MAGIC_API_KEY = 'pk_live_D4FA47BDA0BDF38C';
export const magic = new Magic(FORTMATIC_MAGIC_API_KEY);
export const magicProvider = magic.rpcProvider;
