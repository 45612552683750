import React from 'react';
import { StyleSheet, css } from 'aphrodite';

import { COLORS } from '../../constants/index';

const styles = StyleSheet.create({
  accentedTextContainer: {
    padding: '4px 8px',
    display: 'inline-block',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.08)',
    marginRight: 'auto',
    marginLeft: 'auto',
    borderRadius: '4px',
    border: '1px solid #EEEDF1',
    textAlign: 'center',
    color: COLORS.gray,
    fontSize: '14px',
    transition: '0.2s',
    whiteSpace: 'nowrap',
    ':hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.08)',
    },
  },
  unaccentedText: {
    fontSize: '14px',
  },
  accentedText: {
    fontWeight: 600,
    color: COLORS.blue,
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
});

const PoweredBySetProtocol = () => (
  <a href="https://www.setprotocol.com" target="_blank" rel="noopener noreferrer">
    <div className={css(styles.accentedTextContainer)}>
      <span className={css(styles.unaccentedText)}>Powered by </span>
      <span className={css(styles.accentedText)}>Set Protocol</span>
    </div>
  </a>
);

export default PoweredBySetProtocol;
