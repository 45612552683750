import { NETWORK_CONSTANTS } from '../constants/index';
import {
  setProtocolV2ApolloClient,
  setProtocolV2ArbitrumApolloClient,
  setProtocolV2OptimismApolloClient,
  setProtocolV2PolygonApolloClient,
  setProtocolV2AvalancheApolloClient,
} from '../utils/apollo';
import { currentChainOrDefaultSelector } from './web3Selectors';

export const apolloClientSelector = (state: any) => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case NETWORK_CONSTANTS.POLYGON_CHAIN:
      return setProtocolV2PolygonApolloClient;
    case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
      return setProtocolV2ArbitrumApolloClient;
    case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
      return setProtocolV2OptimismApolloClient;
    case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
      return setProtocolV2AvalancheApolloClient;
    default:
      return setProtocolV2ApolloClient;
  }
};
