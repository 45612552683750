import { connect } from 'react-redux';

import IssuanceApproval from '../../containers/IssuanceApprovalV2/IssuanceApproval';
import {
  approveTokenForDebtIssuance,
  approveAllRequiredTokensForDebtRedemption,
} from '../../actions/approvalActions';
import {
  approvalRequiredDebtRedemptionComponents,
  allApprovalStatusesByIdForDebtRedemption,
  isAnyApprovalPendingForDebtRedemptionSelector,
} from '../../selectors/debtRedemptionSelectors';
import {
  setDetailsCurrentSetAddressSelector,
  windowDimensionSelector,
} from '../../selectors/baseSelectors';
import { customV2SetPathPrefixSelector } from '../../selectors/web3Selectors';

const mapStateToProps = (state: any) => {
  const isAnyApprovalPending = isAnyApprovalPendingForDebtRedemptionSelector(state);
  const allApprovalStatusesByTokenId = allApprovalStatusesByIdForDebtRedemption(state);
  const requiredComponents = approvalRequiredDebtRedemptionComponents(state);
  const windowDimensions = windowDimensionSelector(state);
  const customV2SetPathPrefix = customV2SetPathPrefixSelector(state);
  const setAddress = setDetailsCurrentSetAddressSelector(state);

  return {
    customV2SetPathPrefix,
    setAddress,
    isAnyApprovalPending,
    allApprovalStatusesByTokenId,
    requiredComponents,
    windowDimensions,
  };
};

const mapDispatchToProps = {
  approveAllListTokensForCurrentSet: approveAllRequiredTokensForDebtRedemption,
  approveListTokenAllowance: approveTokenForDebtIssuance,
};

export default connect(mapStateToProps, mapDispatchToProps)(IssuanceApproval);
