import { css, StyleSheet } from 'aphrodite';
import React, { PureComponent } from 'react';
import { Container } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button } from '../../components/index';
import { WINDOW_DIMENSIONS, COLORS } from '../../constants/index';
import { makeBlockExplorerContractLink } from '../../utils/web3Utils';

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  CustomSetMissing_container: {
    width: '100%',
    margin: 'auto',
    minHeight: '600px',
    textAlign: 'center',
    padding: '100px',
    [MOBILE_MEDIA_QUERY]: {
      padding: '20px',
    },
  },
  CustomSetMissing_icon: {
    height: '75px',
    width: '75px',
    [MOBILE_MEDIA_QUERY]: {
      height: '50px',
      width: '50px',
    },
  },
  CustomSetMissing_buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '300px',
    margin: 'auto',
  },
  CustomSetMissing_header: {
    fontSize: '72px',
    fontFamily: 'Graphik',
    fontWeight: 'bold',
    textAlign: 'center',
    paddingTop: '60px',
    position: 'relative',
    lineHeight: '1',
    color: COLORS.darkBlue,
    [MOBILE_MEDIA_QUERY]: {
      fontSize: '30px',
      paddingTop: '30px',
    },
  },
  CustomSetMissing_description: {
    color: COLORS.darkGray,
    fontFamily: 'Graphik',
    fontSize: '24px',
    textAlign: 'center',
    paddingTop: '30px',
    paddingBottom: '30px',
    lineHeight: '30px',
    margin: 'auto',
    [MOBILE_MEDIA_QUERY]: {
      fontSize: '18px',
      width: '90%',
      paddingTop: '30px',
    },
  },
});

interface ICustomSetMissingProps {
  setAddress: string;
}

class CustomSetMissing extends PureComponent<ICustomSetMissingProps & WithTranslation> {
  render() {
    const { t, setAddress } = this.props;

    return (
      <Container>
        <div className={css(styles.CustomSetMissing_container)}>
          <img
            className={css(styles.CustomSetMissing_icon)}
            src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/magnifying-glass.png"
          />
          <div className={css(styles.CustomSetMissing_header)}>
            {t('missing.portfolio-does-not-exist')}
          </div>
          <div className={css(styles.CustomSetMissing_description)}>
            {t('missing.if-portfolio-recently-made')}
          </div>
          <div className={css(styles.CustomSetMissing_buttons)}>
            <Button
              onClick={() => window.location.reload()}
              style={{
                container: {
                  backgroundColor: COLORS.blue,
                },
              }}
              text="Refresh"
            />
            <Button
              externalHref={makeBlockExplorerContractLink(setAddress)}
              style={{
                container: {
                  backgroundColor: COLORS.darkBlue,
                },
              }}
              text="View Contract"
            />
          </div>
        </div>
      </Container>
    );
  }
}

export default withTranslation('set-details-v2')(CustomSetMissing);
