import { BigNumber as EthersBigNumber } from 'ethers';

import { ExtensionConstants, Extension } from '../typings/index';
import {
  accountSelector,
  currentRebalancingSetSelector,
  setJSInstanceSelector,
} from './baseSelectors';
import {
  averageGasPriceSelector,
  setManagerOperatorAddressesSelector,
  setDetailsCurrentSetAddressSelector,
  setManagerOwnerAddressSelector,
} from '../selectors/baseSelectors';
import {
  currentSetManagerSelector,
  currentSetDetailsSelector,
} from '../selectors/setDetailsSelectors';
import { getWeb3Instance } from '../utils/index';
import {
  batchTradeExtensionAddressSelector,
  tradeExtensionAddressSelector,
} from '../selectors/protocolAddressSelector';

const batchTradingExtensionABI = require('../constants/abis/batchTradingExtensionABI').default;
const tradeExtensionABI = require('../constants/abis/tradeExtensionABI').default;
const extensions: ExtensionConstants = require('../constants/extensions').extensions;

/** Delegated Manager Selectors */
export const isCurrentSetDelegatedManagerSelector = (state: any) => {
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);
  const operatorAddresses = setManagerOperatorAddressesSelector(state)?.[currentSetAddress] || [];

  // TODO: This is a weak assocation to test if Set is Delegated Manager Set.
  // Replace with a direct fetch to `factory` field, or wait for Graph Integration.
  return operatorAddresses !== undefined && operatorAddresses.length > 0;
};

/**
 * Returns the owner address of the delegated manager contract, if available.
 */
export const getCurrentSetDelegatedManagerOwnerSelector = (state: any): string => {
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);
  const ownerAddresses = setManagerOwnerAddressSelector(state);

  return ownerAddresses?.[currentSetAddress];
};

/**
 * Returns the "admin" or "owner" address of a Set.
 * In DelegatedManager Sets, this is the 'owner' field.
 */
export const getCurrentSetOwnerSelector = (state: any): string => {
  const delegatedManagerOwner = getCurrentSetDelegatedManagerOwnerSelector(state);
  const setDetails = currentSetDetailsSelector(state);

  return delegatedManagerOwner || setDetails?.manager;
};

// Returns true if currently logged in user is an operator for this set.
export const isUserDelegatedManagerSetOperator = (state: any) => {
  const account = accountSelector(state);
  const isDelegatedManagerSet = isCurrentSetDelegatedManagerSelector(state);
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);
  const operatorAddresses = setManagerOperatorAddressesSelector(state)?.[currentSetAddress];

  if (isDelegatedManagerSet && operatorAddresses && operatorAddresses.includes(account))
    return true;

  return false;
};

/** Legacy Set Selectors */
export const userHasEditAccessToCurrentSet = (state: any) => {
  const userAccount = accountSelector(state);
  const currentSet = currentRebalancingSetSelector(state);

  return Boolean(userAccount) && currentSet?.operator?.address === userAccount;
};

export const userHasEditAccessToCurrentSetOrFund = (state: any) => {
  const setAccess = userHasEditAccessToCurrentSet(state);

  return setAccess;
};

export const initializeMethodForTargetExtensionSelector = async (
  state: any,
  extension: Extension,
) => {
  const delegatedManagerAddress = currentSetManagerSelector(state);
  const web3Instance = await getWeb3Instance();
  const account = accountSelector(state);
  const setJSInstance = setJSInstanceSelector(state);
  const averageGasPrice = averageGasPriceSelector(state);

  switch (extension.key) {
    case extensions.ethereum.BATCH_TRADE_EXTENSION.key:
    case extensions.optimism.BATCH_TRADE_EXTENSION.key:
    case extensions.polygon.BATCH_TRADE_EXTENSION.key: {
      const batchTradeExtensionAddress = batchTradeExtensionAddressSelector(state);

      const batchTradingExtensionContract = new web3Instance.eth.Contract(
        batchTradingExtensionABI as any,
        batchTradeExtensionAddress,
      );

      const gasLimit = await batchTradingExtensionContract.methods
        .initializeExtension(delegatedManagerAddress)
        .estimateGas({ from: account });

      return () =>
        setJSInstance.extensions.batchTradeExtension.initializeExtensionAsync(
          delegatedManagerAddress,
          account,
          {
            gasLimit,
            gasPrice: averageGasPrice,
          },
        );
    }

    case extensions.ethereum.TRADE_EXTENSION.key:
    case extensions.optimism.TRADE_EXTENSION.key:
    case extensions.polygon.TRADE_EXTENSION.key: {
      const tradeExtensionAddress = tradeExtensionAddressSelector(state);

      const tradeExtensionContract = new web3Instance.eth.Contract(
        tradeExtensionABI as any,
        tradeExtensionAddress,
      );

      const gasLimit = await tradeExtensionContract.methods
        .initializeExtension(delegatedManagerAddress)
        .estimateGas({ from: account });

      const txOpts = {
        gasPrice: EthersBigNumber.from(averageGasPrice).toString(),
        gasLimit: EthersBigNumber.from(gasLimit).toString(),
        from: account,
      };

      return () =>
        tradeExtensionContract.methods.initializeExtension(delegatedManagerAddress).send(txOpts);
    }
  }
};
