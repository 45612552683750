import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Placeholder } from 'semantic-ui-react';

const styles = StyleSheet.create({
  SetIcon_container: {
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    minWidth: '50px',
    minHeight: '50px',
    height: '50px',
    width: '50px',
  },
  SetIcon_containerOverflow: {
    overflow: 'visible',
    borderRadius: '0',
  },
  SetIcon_img: {
    height: '100%',
  },
  SetIcon_placeHolder: {
    height: '100px',
    width: '100px',
  },
});

interface ISetIconProps {
  isLoading?: boolean;
  alt: string;
  className?: string;
  src: string;
}

const SetIcon: React.SFC<ISetIconProps> = ({ alt, className, src, isLoading }) => (
  <div
    className={css(
      styles.SetIcon_container,
      isLoading ? undefined : styles.SetIcon_containerOverflow,
    ).concat(` ${className}`)}
  >
    {src && !isLoading ? (
      <img
        src={
          src.length === 0
            ? 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/placeholder_icon.svg'
            : src
        }
        alt={alt}
        className={css(styles.SetIcon_img)}
      />
    ) : (
      <Placeholder>
        <Placeholder.Image square className={css(styles.SetIcon_placeHolder)} />
      </Placeholder>
    )}
  </div>
);

SetIcon.defaultProps = {
  alt: 'Set Icon',
  className: '',
  src: 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/placeholder_icon.svg',
};

export default SetIcon;
