import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Container } from 'semantic-ui-react';

import leftArrowDark from '../../img/icons/left-arrow-dark.svg';
import { IMatch, ISetDetails, IHistory } from '../../typings/index';
import { WINDOW_DIMENSIONS, COLORS } from '../../constants/index';
import { Button } from '../../components/index';
import { WithTranslation, withTranslation } from 'react-i18next';

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  IssuanceDisabled_container: {
    position: 'relative',
    width: '60vw',
    maxWidth: '600px',
    margin: '40px auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '50px',
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '4px',
    [MOBILE_MEDIA_QUERY]: {
      width: '90vw',
      padding: '20px',
    },
  },
  IssuanceDisabled_innerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  IssuanceDisabled_backButtonStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '50px',
    cursor: 'pointer',
    transition: '0.2s',
    height: '40px',
    width: '40px',
    ':hover': {
      boxShadow: `0 2px 6px ${COLORS.blueAlpha25}`,
      transform: 'scale(1.02)',
    },
  },
  IssuanceDisabled_buttonText: {
    color: COLORS.white,
    fontWeight: 500,
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  IssuanceDisabled_header: {
    fontSize: '32px',
    color: COLORS.darkBlue,
    paddingTop: '30px',
    paddingBottom: '30px',
    fontWeight: 600,
    textAlign: 'center',
    margin: 'auto',
    lineHeight: '1.1',
  },
  IssuanceDisabled_description: {
    fontSize: '16px',
    margin: 'auto',
    textAlign: 'center',
    paddingBottom: '30px',
    lineHeight: '1.5',
  },
  IssuanceDisabled_icon: {
    height: '80px',
    width: '80px',
  },
});

type IssuanceDisabledProps = {
  setAddress: string;
  customV2SetPathPrefix: string;
  setDetails: ISetDetails;
  account: string;
  match: IMatch;
  history: IHistory;
};

/**
 * @title IssuanceDisabled
 * @author Set Protocol
 *
 * Message showing the user that the Issuance module is not yet enabled for this Set.
 */
class IssuanceDisabled extends PureComponent<IssuanceDisabledProps & WithTranslation> {
  componentDidMount() {
    document.title = 'Issue Set | TokenSets';
  }

  render() {
    const { customV2SetPathPrefix, setDetails, account, setAddress, history, t } = this.props;

    const buttonStyle = {
      container: {
        border: `1px solid ${COLORS.blue}`,
        width: '300px',
        padding: '20px 22px',
        backgroundColor: COLORS.blue,
        borderRadius: '4px',
        color: COLORS.white,
        ':hover': {
          color: COLORS.white,
          backgroundColor: COLORS.blueDarkened,
        },
      },
      text: {
        color: COLORS.white,
      },
    };

    const isManager = account === setDetails?.manager;

    return (
      <Container>
        <div className={css(styles.IssuanceDisabled_container)}>
          <div
            onClick={() => history?.push(`${customV2SetPathPrefix}/${setAddress}`)}
            className={css(styles.IssuanceDisabled_backButtonStyle)}
          >
            <div className={css(styles.IssuanceDisabled_buttonText)}>
              <img src={leftArrowDark} alt="back button" />
            </div>
          </div>
          <div className={css(styles.IssuanceDisabled_innerContainer)}>
            <img
              className={css(styles.IssuanceDisabled_icon)}
              src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/issue.svg"
            />
            <div>
              <div className={css(styles.IssuanceDisabled_header)}>
                {t(
                  isManager
                    ? 'issuance-disabled.manager-issue-header'
                    : 'issuance-disabled.issue-header',
                  { setName: setDetails?.name },
                )}
              </div>
              <div className={css(styles.IssuanceDisabled_description)}>
                {t(
                  isManager
                    ? 'issuance-disabled.manager-issue-redeem-description'
                    : 'issuance-disabled.issue-description',
                )}
              </div>
            </div>
            <Button
              onClick={async () => {
                window.location.href = `${customV2SetPathPrefix}/${setAddress}`;
                window.location.reload();
              }}
              style={buttonStyle}
            >
              <div className={css(styles.IssuanceDisabled_buttonText)}>
                {t(isManager ? 'issuance-disabled.manager-back' : 'issuance-disabled.back-button')}
              </div>
            </Button>
          </div>
        </div>
      </Container>
    );
  }
}
export default withTranslation('issue-and-redeem')(IssuanceDisabled);
