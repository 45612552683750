import { createSelector } from 'reselect';

import {
  setDetailsCurrentSetAddressSelector,
  setDetailsSelector,
  isRestrictedIpAddressSelector,
  customSetDetailsSelector,
  aaveLeverageModuleEnabledSelector,
  compoundLeverageModuleEnabledSelector,
  perpV2LeverageModuleEnabledSelector,
  perpV2BasisTradingModuleEnabledSelector,
} from '../selectors/baseSelectors';
import { tokenFromBaseUnits } from '../utils/formatUtils';
import BigNumber from '../utils/bigNumber';
import {
  IPosition,
  IListToken,
  ISetDetails,
  IListTokenWithPosition,
  CustomSetDetailsResponse,
} from '../typings/index';
import { tokenListNameOverride } from '../constants/tokenListConstants';
import { DEFAULT_TOKEN_LIST_ENTRY } from '../constants/defaultParameters';
import { truncateEthAddress } from '../utils/formatUtils';
import {
  coingeckoTokenListByAddressSelector,
  tokenSetsTokenListByAddressSelector,
} from './tokenListsSelectors';
import { currentChainOrDefaultSelector } from './web3Selectors';
import { NETWORK_CONSTANTS } from '../constants/index';
import { debtIssuanceModuleAddressSelector } from './debtIssuanceSelectors';
import { basicIssuanceModuleAddressSelector } from './issuanceV2Selectors';
import { perpIssuanceModuleAddressSelector } from './protocolAddressSelector';
import setJSConfig from '../constants/setJSConfig';

export const allModulesSelector = (state: any): string[] => {
  const basicIssuanceModuleAddress = basicIssuanceModuleAddressSelector(state);
  const streamingFeeModuleAddress = streamingFeeModuleAddressSelector(state);

  const debtIssuanceModuleAddress = debtIssuanceModuleAddressSelector(state);
  const perpIssuanceModuleAddress = perpIssuanceModuleAddressSelector(state);

  // Leverage Modules
  const perpV2BasisTradingModuleAddress = perpV2BasisTradingModuleAddressSelector(state);
  const perpV2LeverageModuleAddress = perpV2LeverageModuleAddressSelector(state);
  const compoundLeverageModuleAddress = compoundLeverageModuleAddressSelector(state);
  const aaveLeverageModuleAddress = aaveLeverageModuleAddressSelector(state);

  // Note this order is important. See currentSetModuleStatuses in setDetailsSelectors.ts
  return [
    basicIssuanceModuleAddress,
    streamingFeeModuleAddress,
    debtIssuanceModuleAddress,
    perpIssuanceModuleAddress,
    perpV2LeverageModuleAddress,
    perpV2BasisTradingModuleAddress,
    compoundLeverageModuleAddress,
    aaveLeverageModuleAddress,
  ].filter(address => address !== undefined);
};

export const currentSetDetailsSelector = (state: any): ISetDetails => {
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);
  const setDetails = setDetailsSelector(state);

  return setDetails?.[currentSetAddress] || setDetails?.[currentSetAddress?.toLowerCase()];
};

export const currentSetDetailsAsListTokenSelector = (state: any): IListToken => {
  const currentSet = currentSetDetailsSelector(state);
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);
  const currentCustomSetDetails = currentCustomSetDetailsSelector(state);
  const tokenSetsTokenListByAddress = tokenSetsTokenListByAddressSelector(state);

  return {
    address: currentSetAddress,
    chainId: '1',
    decimals: '18',
    logoURI:
      currentCustomSetDetails?.setDetails?.setIcon ||
      tokenSetsTokenListByAddress?.[currentSetAddress?.toLowerCase()]?.logoURI ||
      'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-set-token-icon.svg',
    name: currentSet?.name,
    symbol: currentSet?.symbol,
  };
};

export const currentCustomSetDetailsSelector = (state: any): CustomSetDetailsResponse => {
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);
  const setDetails = customSetDetailsSelector(state);

  return setDetails?.[currentSetAddress] || setDetails?.[currentSetAddress?.toLowerCase()];
};

export const currentSetPositionsSelector = (state: any): IPosition[] => {
  const currentSetDetails = currentSetDetailsSelector(state);

  return currentSetDetails?.positions;
};

export const currentSetTotalSupplySelector = (state: any): BigNumber => {
  const currentSetDetails = currentSetDetailsSelector(state);

  return currentSetDetails?.totalSupply;
};

export const currentSetComponentsSelector = createSelector(
  currentSetPositionsSelector,
  coingeckoTokenListByAddressSelector,
  currentSetTotalSupplySelector,
  (currentSetPositions, tokenListByAddress, totalSupply): IListTokenWithPosition[] => {
    const normalTotalSupply = tokenFromBaseUnits(totalSupply?.toString() || '0');

    const components = currentSetPositions?.map((position: IPosition) => {
      const positionComponentAddress = position.component;
      const annotatedDefaultToken = {
        ...DEFAULT_TOKEN_LIST_ENTRY,
        address: positionComponentAddress,
        symbol: truncateEthAddress(positionComponentAddress),
        name: truncateEthAddress(positionComponentAddress),
      };

      const tokenListEntry = tokenListByAddress[positionComponentAddress.toLowerCase()];

      const perTokenPosition = tokenFromBaseUnits(
        position?.unit?.toString() || '0',
        tokenListEntry?.decimals,
      );
      const totalPosition = perTokenPosition.mul(normalTotalSupply || 0);

      return {
        ...position,
        ...(tokenListEntry ? tokenListEntry : annotatedDefaultToken),
        totalPosition,
        perTokenPosition,
      };
    });

    return components;
  },
);

export const currentSetComponentsByAddressSelector = createSelector(
  currentSetComponentsSelector,
  (setComponents: IListTokenWithPosition[]): { [tokenAddress: string]: IListTokenWithPosition } => {
    return setComponents?.reduce(
      (
        allComponents: { [tokenAddress: string]: IListTokenWithPosition },
        component: IListTokenWithPosition,
      ) => {
        return {
          ...allComponents,
          [component.address]: component,
        };
      },
      {},
    );
  },
);

export const formattedSetComponentsWithColorsSelector = createSelector(
  currentSetComponentsSelector,
  currentSetComponents => {
    return currentSetComponents?.map((token: IListTokenWithPosition) => {
      const tokenAddress = token?.component?.toLowerCase();
      return {
        id: tokenAddress,
        address: tokenAddress,
        name: tokenListNameOverride[tokenAddress] || token?.name,
        symbol: token?.symbol,
        image_url:
          token?.logoURI || 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-token-icon.svg',
        image:
          token?.logoURI || 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-token-icon.svg',
        decimals: token?.decimals,
        colors: ['#000000', '#000000'],
        units: token?.perTokenPosition?.toString(),
      };
    });
  },
);

// formattedSetComponentsByAddress

export const currentSetModulesSelector = (state: any) => {
  const currentSet = currentSetDetailsSelector(state);

  return currentSet?.modules;
};

export const currentSetModuleStatusesSelector = (state: any) => {
  const currentSet = currentSetDetailsSelector(state);

  return currentSet?.moduleStatuses;
};

export const currentSetManagerSelector = (state: any) => {
  const currentSet = currentSetDetailsSelector(state);

  return currentSet?.manager;
};

export const isFeeModuleInitializedForCurrentSetSelector = (state: any) => {
  const currentSetModules = currentSetModulesSelector(state);
  const currentSetModuleStatuses = currentSetModuleStatusesSelector(state);
  const streamingFeeModuleAddress = streamingFeeModuleAddressSelector(state);
  const moduleAddresses = allModulesSelector(state);

  const feeModuleOnSet = currentSetModules?.indexOf(streamingFeeModuleAddress);
  const feeModuleIndex = moduleAddresses?.indexOf(streamingFeeModuleAddress);

  return feeModuleOnSet && currentSetModuleStatuses?.[feeModuleIndex] === 2;
};

export const isDebtIssuanceV1ModuleEnabledForCurrentSetSelector = (state: any) => {
  const currentSetModules = currentSetModulesSelector(state);
  const currentSetModuleStatuses = currentSetModuleStatusesSelector(state);
  const debtIssuanceModuleAddress = debtIssuanceModuleAddressSelector(state);
  const moduleAddresses = allModulesSelector(state);

  const debtIssuanceModuleOnSet = currentSetModules?.includes(debtIssuanceModuleAddress);
  const debtIssuanceModuleIndex = moduleAddresses?.indexOf(debtIssuanceModuleAddress);

  return debtIssuanceModuleOnSet && currentSetModuleStatuses?.[debtIssuanceModuleIndex] === 2;
};

export const isDebtIssuanceV2ModuleEnabledForCurrentSetSelector = (state: any) => {
  const currentSetModules = currentSetModulesSelector(state);
  const currentSetModuleStatuses = currentSetModuleStatusesSelector(state);
  const debtIssuanceModuleAddress = debtIssuanceModuleAddressSelector(state);
  const moduleAddresses = allModulesSelector(state);

  const debtIssuanceModuleOnSet = currentSetModules?.includes(debtIssuanceModuleAddress);
  const debtIssuanceModuleIndex = moduleAddresses?.indexOf(debtIssuanceModuleAddress);

  return debtIssuanceModuleOnSet && currentSetModuleStatuses?.[debtIssuanceModuleIndex] === 2;
};

/* Used for all Sets */
export const isGeofencedSetSelector = (state: any): boolean => {
  const hasEnabledLeverageModule = isLeverageModuleEnabledSelector(state);
  const isRestrictedIpAddress = isRestrictedIpAddressSelector(state);
  const isGeofencingEnabled = true;

  if (!isGeofencingEnabled) return false;

  if (!hasEnabledLeverageModule) return false;

  return isRestrictedIpAddress;
};

export const isLeverageModuleEnabledSelector = (state: any): boolean => {
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);

  const compoundLeverageEnabledMap = compoundLeverageModuleEnabledSelector(state);
  const aaveLeverageEnabledMap = aaveLeverageModuleEnabledSelector(state);
  const perpV2LeverageEnabledMap = perpV2LeverageModuleEnabledSelector(state);
  const perpV2BasisTradingLeverageEnabledMap = perpV2BasisTradingModuleEnabledSelector(state);

  return (
    compoundLeverageEnabledMap?.[currentSetAddress] ||
    aaveLeverageEnabledMap?.[currentSetAddress] ||
    perpV2LeverageEnabledMap?.[currentSetAddress] ||
    perpV2BasisTradingLeverageEnabledMap?.[currentSetAddress]
  );
};

export const isPerpLeverageModuleEnabledSelector = (state: any): boolean => {
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);

  const perpV2LeverageEnabledMap = perpV2LeverageModuleEnabledSelector(state);

  return perpV2LeverageEnabledMap?.[currentSetAddress];
};

export const isPerpBasisTradingModuleEnabledSelector = (state: any): boolean => {
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);

  const perpV2BasisTradingEnabledMap = perpV2BasisTradingModuleEnabledSelector(state);

  return perpV2BasisTradingEnabledMap?.[currentSetAddress];
};

export const perpV2LeverageModuleAddressSelector = (state: any): string => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case NETWORK_CONSTANTS.POLYGON_CHAIN:
      return setJSConfig[NETWORK_CONSTANTS.POLYGON_CHAIN].perpV2LeverageModuleAddress;
    case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
      return setJSConfig[NETWORK_CONSTANTS.ARBITRUM_CHAIN].perpV2LeverageModuleAddress;
    case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
      return setJSConfig[NETWORK_CONSTANTS.OPTIMISM_CHAIN].perpV2LeverageModuleAddress;
    case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
      return setJSConfig[NETWORK_CONSTANTS.AVALANCHE_CHAIN].perpV2LeverageModuleAddress;
    default:
      return setJSConfig[NETWORK_CONSTANTS.ETHEREUM_CHAIN].perpV2LeverageModuleAddress;
  }
};

export const perpV2BasisTradingModuleAddressSelector = (state: any): string => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case NETWORK_CONSTANTS.POLYGON_CHAIN:
      return setJSConfig[NETWORK_CONSTANTS.POLYGON_CHAIN].perpV2BasisTradingModuleAddress;
    case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
      return setJSConfig[NETWORK_CONSTANTS.ARBITRUM_CHAIN].perpV2BasisTradingModuleAddress;
    case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
      return setJSConfig[NETWORK_CONSTANTS.OPTIMISM_CHAIN].perpV2BasisTradingModuleAddress;
    case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
      return setJSConfig[NETWORK_CONSTANTS.AVALANCHE_CHAIN].perpV2BasisTradingModuleAddress;
    default:
      return setJSConfig[NETWORK_CONSTANTS.ETHEREUM_CHAIN].perpV2BasisTradingModuleAddress;
  }
};

export const compoundLeverageModuleAddressSelector = (state: any): string => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case NETWORK_CONSTANTS.POLYGON_CHAIN:
      return '0x0000000000000000000000000000000000000000';
    case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
      return '0x0000000000000000000000000000000000000000';
    case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
      return '0x0000000000000000000000000000000000000000';
    case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
      return '0x0000000000000000000000000000000000000000';
    default:
      return '0x8d5174eD1dd217e240fDEAa52Eb7f4540b04F419';
  }
};

export const aaveLeverageModuleAddressSelector = (state: any): string => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case NETWORK_CONSTANTS.POLYGON_CHAIN:
      return '0xB7F72e15239197021480EB720E1495861A1ABdce';
    case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
      return '0x0000000000000000000000000000000000000000';
    case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
      return '0x0000000000000000000000000000000000000000';
    case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
      return '0xa13FD9Fcc60877c40f0249DE015443Bf1F700567';
    default:
      return '0x0000000000000000000000000000000000000000';
  }
};

export const streamingFeeModuleAddressSelector = (state: any) => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case NETWORK_CONSTANTS.POLYGON_CHAIN:
      return setJSConfig[NETWORK_CONSTANTS.POLYGON_CHAIN].streamingFeeModuleAddress;
    case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
      return setJSConfig[NETWORK_CONSTANTS.ARBITRUM_CHAIN].streamingFeeModuleAddress;
    case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
      return setJSConfig[NETWORK_CONSTANTS.OPTIMISM_CHAIN].streamingFeeModuleAddress;
    case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
      return setJSConfig[NETWORK_CONSTANTS.AVALANCHE_CHAIN].streamingFeeModuleAddress;
    default:
      return setJSConfig[NETWORK_CONSTANTS.ETHEREUM_CHAIN].streamingFeeModuleAddress;
  }
};
