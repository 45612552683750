import React from 'react';
import { ContentState } from 'draft-js';
import { StyleSheet, css } from 'aphrodite';

import { COLORS } from '../../../constants/index';

const styles = StyleSheet.create({
  LinkComponent_link: {
    textDecoration: 'underline',
    color: COLORS.blue,
  },
});

export interface ILinkComponentProps {
  decoratedText: string;
  contentState: ContentState;
  entityKey: string;
  children: any;
}

/** Custom INLINE Rendering Component. See Decorator Composite Decorator in utils. */
const LinkComponent = (props: ILinkComponentProps) => {
  const { contentState, entityKey, children } = props;
  const { url } = contentState.getEntity(entityKey).getData();

  return (
    <a
      href={url}
      className={css(styles.LinkComponent_link)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

export default LinkComponent;
