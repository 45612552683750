import { StyleSheet, css } from 'aphrodite';
import React, { PureComponent } from 'react';
import { Header, Icon, Grid } from 'semantic-ui-react';

import FaqSection from './FaqSection';
import { ContentBox, LinkButton, Text } from '../../components/index';
import { COLORS, SOCIAL_MEDIA_ICONS, FAQ_CONTENT } from '../../constants/index';
import faqIcon from '../../img/icons/faq.svg';
import { ISocialMediaIcon } from '../../typings/index';
import { titleCase } from '../../utils/index';
import { withTranslation, WithTranslation } from 'react-i18next';

const twitterIcon = SOCIAL_MEDIA_ICONS[1];

const styles = StyleSheet.create({
  heroHeader: {
    maxWidth: '950px',
    lineHeight: '42px',
  },
  marginBottomSmall: {
    marginBottom: '1rem',
  },
  marginBottomMed: {
    marginBottom: '4rem',
  },
  marginTopSmall: {
    marginTop: '1rem',
  },
  faqIcon: {
    marginBottom: '25px',
  },
  footerHeader: {
    maxWidth: '950px',
    lineHeight: '42px',
  },
  container: {
    background: 'white',
    borderRadius: '5px',
    border: `1px solid ${COLORS.gray}`,
    cursor: 'pointer',
    width: '100%',
    height: 'auto',
    minHeight: '285px',
    padding: '0',
    boxShadow: `0 6px 12px 0 ${COLORS.lightBlueAlpha25}`,
    transition: '0.2s',
    ':hover': {
      transform: 'scale(1.03)',
      boxShadow: `0 6px 18px 0 ${COLORS.lightBlueAlpha25}`,
    },
  },
  contentContainer: {
    maxWidth: '450px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '70px 50px',
    textAlign: 'center',
    minHeight: '285px',
  },
  icon: {
    color: COLORS.blue,
    minHeight: '60px',
    marginBottom: '30px',
  },
  discordIcon: {
    fontSize: '72px',
    lineHeight: '72px',
  },
});

interface IContactUsContainerProps {
  iconData: ISocialMediaIcon;
  text: string;
}

const ContactUsContainer: React.SFC<IContactUsContainerProps> = props => {
  const { iconData, text } = props;
  return (
    <a href={iconData.path} target="_blank" rel="noopener noreferrer">
      <div role="button" className={css(styles.container)}>
        <div className={css(styles.contentContainer)}>
          <img src={iconData.iconLarge} className={css(styles.icon)} alt={iconData.alt} />
          <Header as="h5">{text}</Header>
        </div>
      </div>
    </a>
  );
};

class Faq extends PureComponent<WithTranslation> {
  componentDidMount() {
    document.title = 'FAQ | TokenSets';
  }

  render() {
    const { t } = this.props;

    return (
      <ContentBox hasMargin={false} isFullWidth isInnerContainerWide isBordered={false}>
        <img className={css(styles.faqIcon)} src={faqIcon} alt="Question mark Set" />
        <Header
          as="h1"
          textAlign="center"
          className={css(styles.heroHeader, styles.marginBottomMed)}
        >
          {t('faq')}
        </Header>
        {Object.keys(FAQ_CONTENT).map(sectionTitle => (
          <FaqSection
            key={sectionTitle}
            sectionTitle={titleCase(sectionTitle)}
            sectionContent={Object.keys(FAQ_CONTENT[sectionTitle]).map(
              key => FAQ_CONTENT[sectionTitle][key],
            )}
          />
        ))}
        <div className={css(styles.marginBottomMed)}>
          <LinkButton
            to="/explore"
            log={{ category: 'FAQ Page', label: 'Explore Sets Sets FAQ Page' }}
          >
            {t('footer.explore-sets')}
          </LinkButton>
        </div>
        <Header
          as="h2"
          textAlign="center"
          className={css(styles.footerHeader, styles.marginBottomSmall)}
        >
          {t('footer.more-questions')}
        </Header>
        <Text>{t('footer.reach-out')}</Text>
        <Grid columns={2} stackable className={css(styles.marginTopSmall)}>
          <Grid.Column>
            <ContactUsContainer iconData={twitterIcon} text={t('footer.tweet-us')} />
          </Grid.Column>
          <Grid.Column>
            <a href="https://discord.gg/x3PGVfP" target="_blank" rel="noopener noreferrer">
              <div role="button" className={css(styles.container)}>
                <div className={css(styles.contentContainer)}>
                  <Icon
                    name="discord"
                    className={css(styles.discordIcon, styles.icon)}
                    alt="discord icon"
                  />
                  <Header as="h5">{t('footer.join-community')}</Header>
                </div>
              </div>
            </a>
          </Grid.Column>
        </Grid>
      </ContentBox>
    );
  }
}

export default withTranslation('faq')(Faq);
