import { connect } from 'react-redux';

import IssuanceApproval from '../../containers/IssuanceApprovalV2/IssuanceApproval';
import {
  setDetailsCurrentSetAddressSelector,
  windowDimensionSelector,
} from '../../selectors/baseSelectors';
import {
  approveTokenForPerpIssuance,
  approveAllRequiredTokensForPerpIssuance,
} from '../../actions/approvalActions';
import {
  allPerpComponentsSelector,
  isAnyApprovalPendingForPerpSelector,
  allApprovalStatusesByIdForPerpIssuanceRedemption,
} from '../../selectors/perpIssuanceRedemptionSelectors';
import { customV2SetPathPrefixSelector } from '../../selectors/web3Selectors';
import { TYPE_ISSUE } from '../../constants/index';

const mapStateToProps = (state: any) => {
  const isAnyApprovalPending = isAnyApprovalPendingForPerpSelector(TYPE_ISSUE, state);
  const allApprovalStatusesByTokenId = allApprovalStatusesByIdForPerpIssuanceRedemption(
    TYPE_ISSUE,
    state,
  );
  const requiredComponents = allPerpComponentsSelector(TYPE_ISSUE, state);
  const customV2SetPathPrefix = customV2SetPathPrefixSelector(state);
  const setAddress = setDetailsCurrentSetAddressSelector(state);

  const windowDimensions = windowDimensionSelector(state);

  return {
    customV2SetPathPrefix,
    setAddress,
    isAnyApprovalPending,
    allApprovalStatusesByTokenId,
    requiredComponents,
    windowDimensions,
  };
};

const mapDispatchToProps = {
  approveAllListTokensForCurrentSet: approveAllRequiredTokensForPerpIssuance,
  approveListTokenAllowance: approveTokenForPerpIssuance,
};

export default connect(mapStateToProps, mapDispatchToProps)(IssuanceApproval);
