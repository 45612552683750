import React, { useRef, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { COLORS } from '../../constants/index';
import useOutsideClick from '../../hooks/UseOutsideClick';

const styles = StyleSheet.create({
  Backdrop: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    backgroundColor: COLORS.black,
    opacity: '50%',
    zIndex: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  Modal: {
    background: COLORS.white,
    position: 'fixed',
    opacity: '100%',
    borderRadius: '5px',
    zIndex: 100,
    border: `1px solid ${COLORS.blueGray2}`,
    borderTop: `5px solid ${COLORS.royalBlue2}`,
  },
  Container: {
    top: 0,
    zIndex: 10,
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
});

interface ModalProps {
  children?: JSX.Element | JSX.Element[];
  onClickOutside: (...args: any[]) => void;
  width?: string;
  height?: string;
}

export default function Modal({
  children,
  onClickOutside,
  width = '28em',
  height = '36em',
}: ModalProps) {
  const ref = useRef();

  useOutsideClick(ref, () => onClickOutside());

  /*
    Locks scrolling while modal is open, then when modal is closed, unlock scrolling
    otherwise users would be able to scroll down on the base page.
    This keeps the modal centered.
  */
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return function cleanup() {
      document.body.style.overflow = 'auto';
    };
  });

  return (
    <div className={css(styles.Container)}>
      <div
        ref={ref}
        className={css(styles.Modal)}
        style={{
          width,
          height,
        }}
      >
        {children}
      </div>
      <div className={css(styles.Backdrop)} />
    </div>
  );
}
