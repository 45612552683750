import approvalReducer from './approvalReducer';
import balanceReducer from './balanceReducer';
import deployedSetsReducer from './deployedSetsReducer';
import exploreReducer from './exploreReducer';
import gasPriceReducer from './gasPriceReducer';
import ipAddressReducer from './ipAddressReducer';
import issuanceV2Reducer from './issuanceV2Reducer';
import onboardingReducer from './onboardingReducer';
import tableReducers from './tableReducers';
import perpLeverageReducer from './perpLeverageReducer';
import redemptionV2Reducer from './redemptionV2Reducer';
import selectLanguageReducer from './selectLanguageReducer';
import setDetailsReducer from './setDetailsReducer';
import setManagerReducer from './setManagerReducer';
import setJSReducer from './setJSReducer';
import uniswapReducer from './uniswapReducer';
import tokenColorsReducer from './tokenColorsReducer';
import tokenListsReducer from './tokenListsReducer';
import transactionWatcherReducer from './transactionWatcherReducer';
import web3Reducer from './web3Reducer';
import windowDimensionReducer from './windowDimensionReducer';

export default {
  approvalReducer,
  balanceReducer,
  deployedSetsReducer,
  exploreReducer,
  gasPriceReducer,
  ipAddressReducer,
  issuanceV2Reducer,
  onboardingReducer,
  tableReducers,
  perpLeverageReducer,
  redemptionV2Reducer,
  selectLanguageReducer,
  setDetailsReducer,
  setManagerReducer,
  setJSReducer,
  uniswapReducer,
  tokenColorsReducer,
  tokenListsReducer,
  transactionWatcherReducer,
  web3Reducer,
  windowDimensionReducer,
};
