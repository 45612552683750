import React from 'react';

interface IRadarRelayIconProps {
  readonly className: string;
}

const RadarRelayIcon: React.SFC<IRadarRelayIconProps> = ({ className }) => (
  <svg
    className={className}
    width="22px"
    height="27px"
    viewBox="0 0 22 27"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <desc>Set Labs. Radar Relay Icon.</desc>
    <defs />
    <g id="radar-relay" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Buy-Modal" transform="translate(-621.000000, -467.000000)" fill="#FFFFFF">
        <g id="Group-12" transform="translate(390.000000, 224.578125)">
          <g id="Group-18" transform="translate(191.000000, 226.000000)">
            <path
              d="M43.08,23.6320571 L50.0144,23.6320571 C52.7414571,23.6320571 54.96,25.8493429 54.96,28.5751429 C54.96,30.4460857 53.9156286,32.0766 52.3775143,32.9166857 L43.08,23.6320571 Z M61.23,28.6556 C61.23,22.2284571 56.1065143,17 49.8082286,17 L40,17 L40,18.1003143 L49.8082286,18.1097429 C55.5062286,18.1097429 60.1419429,22.841 60.1419429,28.6556 C60.1419429,32.0706286 58.5431714,35.1116571 56.0691143,37.0398 L54.9037429,35.8518 C57.0880286,34.2363714 58.5117429,31.6130286 58.5117429,28.6556 C58.5117429,23.7590286 54.6073714,19.7751429 49.8091714,19.7751429 L40,19.7839429 L40,20.8852 L49.8088571,20.8852 C54.0074,20.8852 57.4236857,24.3715714 57.4236857,28.6556 C57.4236857,31.3075429 56.1162571,33.6533714 54.1230571,35.0560286 L52.9441714,33.8538857 C54.652,32.7774571 55.7919143,30.8499429 55.7919143,28.6556 C55.7919143,25.2896 53.1076,22.5506 49.8091714,22.5506 L40.3174286,22.5506 L51.0474571,33.4896286 C50.6514571,33.5949143 50.2369143,33.6511714 49.8088571,33.6511714 L40,33.6511714 L40,34.7590286 L49.8088571,34.7612286 C50.5496286,34.7612286 51.2580286,34.6235714 51.9136286,34.3721429 L53.1534857,35.6362 C52.1156656,36.1570299 50.9703489,36.4277255 49.8091714,36.4266286 L40,36.4234857 L40,37.5363714 L49.8088571,37.5363714 C51.2620629,37.5377195 52.6913919,37.1668081 53.9605714,36.459 L55.1554857,37.6774857 C53.5502938,38.6747562 51.6979874,39.2027735 49.8082286,39.2017714 L40,39.2017714 L40,40.3055429 L49.8082286,40.3118286 C52.0654286,40.3118286 54.1720857,39.6395714 55.9449714,38.4826857 L59.3719429,41.9756571 L40,41.97 L40,43.0857143 L62,43.0857143 L56.8447714,37.8302286 C59.5130571,35.6946571 61.23,32.3764286 61.23,28.6556 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

RadarRelayIcon.defaultProps = {
  className: '',
};

export default RadarRelayIcon;
