import React from 'react';
import { Grid } from 'semantic-ui-react';
import { css } from 'aphrodite';
import { withTranslation, WithTranslation } from 'react-i18next';

import {
  IListToken,
  IListTokenWithBalance,
  ISetDetailsHydrated,
  ITokenBalances,
  IWindowDimensions,
} from '../../typings/index';
import { styles } from './Account';

interface IAccountHeader {
  windowDimension: IWindowDimensions;
  networkBadge: string;
  deployedSetDetailsHydrated: { [address: string]: ISetDetailsHydrated };
  erc20Balances: ITokenBalances;
  tokenSetsTokenListByAddress: { [address: string]: IListToken };
  geminiTokenListByAddress: { [address: string]: IListToken };
  networkCurrencyPrice: string;
  networkListTokenWithBalance: IListTokenWithBalance;
}

const AccountHeader = (props: IAccountHeader & WithTranslation) => {
  const {
    networkBadge,
    windowDimension: { isTablet },
  } = props;
  return (
    <Grid>
      <Grid.Row columns={isTablet ? 1 : 2}>
        <Grid.Column width={16} textAlign="center">
          <div className={css(styles.Account_headerContainer)}>
            <img className={css(styles.Account_networkBadge)} src={networkBadge} style={{}} />
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default withTranslation('account')(AccountHeader);
