import { css, StyleSheet } from 'aphrodite';
import React, { PureComponent, Fragment } from 'react';
import { Container, Divider, Grid } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { InfoIcon, Text } from '../../components/index';
import {
  IListToken,
  IListTokenWithPosition,
  IPerpAccountInfo,
  IPerpPositionUnitInfo,
  IRebalancingSet,
  IToken,
  IWindowDimensions,
} from '../../typings/index';
import {
  COLORS,
  NETWORK_CONSTANTS,
  POSITION_STATE,
  WINDOW_DIMENSIONS,
} from '../../constants/index';
import { BigNumber, truncateTokenAmount } from '../../utils/index';
import PerpLeverageDetailsPlaceholder from './PerpLeverageDetailsPlaceholder';
import { makeBlockExplorerContractLink, urlIntendedChain } from '../../utils/web3Utils';
import { tokenFromBaseUnits } from '../../utils/formatUtils';
import { tokenLogoOverrides } from '../../constants/tokenListConstants';
import { VAssetDisplayInfo } from 'set.js/dist/types/src/types';

const { MOBILE_MEDIA_QUERY, TABLET_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  PerpLeverageDetails_centerAlignBlock: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  PerpLeverageDetails_divider: {
    width: '100%',
    margin: '15px auto 0',
  },
  PerpLeverageDetails_grid: {
    marginLeft: '0',
    marginRight: '0',
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '4px',
  },
  PerpLeverageDetails_polygonGrid: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  PerpLeverageDetails_gridPaddingBottom: {
    paddingBottom: '15px',
  },
  PerpLeverageDetails_gridHeader: {
    fontSize: '12px',
    lineHeight: '14px',
    display: 'block',
    verticalAlign: 'middle',
    paddingTop: '30px',
  },
  PerpLeverageDetails_totalPriceFloat: {
    float: 'right',
  },
  PerpLeverageDetails_gridRow: {
    padding: '10px 30px',
  },
  PerpLeverageDetails_gridRowSubHeader: {
    paddingTop: '5px',
    paddingBottom: '5px',
    marginTop: '15px',
    marginBottom: '15px',
    paddingLeft: '30px',
    backgroundColor: COLORS.lightGray,
    fontWeight: 500,
    color: COLORS.darkGray,
  },
  PerpLeverageDetails_gridColumn: {
    paddingLeft: '0',
    paddingRight: '0',
    display: 'table',
  },
  PerpLeverageDetails_header: {
    marginBottom: '20px',
    textAlign: 'left',
  },
  PerpLeverageDetails_tokenNameHolder: {
    display: 'flex',
    alignItems: 'center',
  },
  PerpLeverageDetails_tokenName: {
    color: COLORS.darkBlue,
    fontWeight: 500,
  },
  PerpLeverageDetails_iconHolder: {
    display: 'inline-block',
    marginRight: '15px',
    borderRadius: '100%',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingRight: '5px',
  },
  PerpLeverageDetails_setIcon: {
    display: 'inline-block',
    maxWidth: '20px',
    height: '20px',
    verticalAlign: 'middle',
    borderRadius: '100px',
  },
  PerpLeverageDetails_setSymbol: {
    fontSize: '12px',
    marginLeft: '3px',
  },
  PerpLeverageDetails_currentLeverageRatio: {
    fontSize: '12px',
  },
  PerpLeverageDetails_setDescription: {
    marginTop: '30px',
    maxWidth: '500px',
  },
  PerpLeverageDetails_setTitle: {
    fontSize: '46px',
    maxWidth: '500px',
    [MOBILE_MEDIA_QUERY]: {
      fontSize: '48px',
      marginTop: '15px',
    },
  },
  PerpLeverageDetails_container: {
    [TABLET_MEDIA_QUERY]: {
      marginLeft: '0',
      marginRight: '0',
    },
  },
  PerpLeverageDetails_marginRight: {
    marginRight: '10px',
  },
  PerpLeverageDetails_setPercentChange: {
    borderRadius: '5px',
    display: 'flex',
    minWidth: '30px',
    marginLeft: '5px',
    marginRight: '5px',
  },
  PerpLeverageDetails_priceChangeArrow: {
    marginRight: '5px',
  },
  PerpLeverageDetails_seeMoreRow: {
    backgroundColor: COLORS.lightGray,
    cursor: 'pointer',
    borderRadius: '0 0 4px 4px',
    transition: '0.2s',
    ':hover': {
      backgroundColor: COLORS.lightGrayDarkened,
    },
  },
  PerpLeverageDetails_seeMoreText: {
    color: COLORS.blue,
    fontWeight: 500,
  },
  PerpLeverageDetails_infoIcon: {
    marginLeft: '10px',
  },
  PerpLeverageDetails_gridColumnIntro: {
    paddingLeft: '0',
    paddingRight: '0',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '100%',
  },
});

interface IPerpLeverageDetailsProps {
  currentSet: IRebalancingSet;
  windowDimension?: IWindowDimensions;
  isLoading?: boolean;
  tokenSetsTokenListByAddress?: { [address: string]: IListToken };
  coingeckoTokenListByAddress: { [address: string]: IListToken };
  coingeckoTokenListBySymbol: { [symbol: string]: IListToken };
  perpCollateralToken: string;
  perpPositionUnitInfos: IPerpPositionUnitInfo[];
  perpAccountInfo: IPerpAccountInfo;
  perpSetTotalCollateralUnits?: string;
  vAssetDisplayInfo?: VAssetDisplayInfo[];
  currentSetComponents?: IListTokenWithPosition[];
  formattedCurrentSetComponentsWithColors?: IToken[];
}

/**
 * @title PerpLeverageDetails
 * @author Set Labs
 *
 * The PerpLeverageDetails displays breakdown of assets and pricing for a perpetual set.
 */
class PerpLeverageDetails extends PureComponent<IPerpLeverageDetailsProps & WithTranslation> {
  static get defaultProps() {
    return {
      isLoading: false,
      currentSet: {} as IRebalancingSet,
    };
  }

  /**
   * Customizes styles per allocation bar based on component token colors and percentage allocation of Set
   */
  public handleAllocationBarStyles(token: IToken, percentChange: string) {
    const colors = token.colors ? token.colors : ['000', 'FFF'];

    return StyleSheet.create({
      // Outer container of the allocation bar. Shows the target allocation of the component token
      PerpLeverageDetails_tokenAllocationContainer: {
        borderRadius: '20px',
        marginBottom: '10px',
        width: this.handleAllocationBarPercentages(percentChange || '0').concat('%'),
      },
      // Inner container of the allocation bar. Shows the actual allocation of the component token
      PerpLeverageDetails_tokenAllocationInnerContainer: {
        background: `linear-gradient(to right, #${colors[0]}, #${colors[1]})`,
        borderRadius: '20px',
        color: COLORS.white,
        fontWeight: 600,
        height: '8px',
        display: 'flex',
        justifyContent: 'flext-start',
        alignItems: 'center',
        paddingLeft: '15px',
        width: '100%',
      },
    });
  }

  /**
   * Calculates the relative percentage of each allocation bar based on the maximum percentage value of the
   * component tokens
   */
  public handleAllocationBarPercentages(percent: string) {
    const percentBN = new BigNumber(percent);
    const maxPercentBN = new BigNumber(100);
    const percentage = Math.round(percentBN.div(maxPercentBN).times(100).toNumber()).toString();

    return percentage;
  }

  /**
   * Calculates the current token allocation percentage based on the set value and the current token value.
   */
  public calculateTokenAllocation = (
    currentTokenValue: BigNumber,
    currentSetValue: BigNumber,
  ): number => {
    const allocationPercentage = new BigNumber(currentTokenValue).div(currentSetValue).mul(100);

    // Mainly catches NaN results.
    if (!allocationPercentage.isFinite()) {
      return 0;
    }

    return allocationPercentage.toNumber();
  };

  public renderDefaultPositionsHeader = () => {
    const {
      currentSet,
      tokenSetsTokenListByAddress,
      currentSetComponents,
      windowDimension: { isTabletLandscape },
      t,
    } = this.props;
    const defaultPositionInfo = {
      header: 'Underlying Tokens',
      description: 'These are the tokens in the Set not being used in any external positions.',
    };

    let displayedComponents = currentSetComponents;

    const hasDefaultPositions = displayedComponents.some(c => {
      return c.positionState === POSITION_STATE['DEFAULT'];
    });

    if (isTabletLandscape) {
      return (
        <Fragment>
          {/* Table Header */}
          <Grid.Row columns={2} className={css(styles.PerpLeverageDetails_gridRow)}>
            <Grid.Column
              className={css(styles.PerpLeverageDetails_gridColumn)}
              textAlign="left"
              width={10}
            >
              <Text className={css(styles.PerpLeverageDetails_gridHeader)} color="darkGray">
                {t('price-chart.token')}
              </Text>
            </Grid.Column>
            <Grid.Column textAlign="right" width={6}>
              <Text
                className={css(
                  styles.PerpLeverageDetails_gridHeader,
                  styles.PerpLeverageDetails_totalPriceFloat,
                )}
                color="darkGray"
              >
                {t('price-chart.total-price')}
              </Text>
            </Grid.Column>
            <Divider className={css(styles.PerpLeverageDetails_divider)} />
          </Grid.Row>

          {/* Set Summary */}
          <Grid.Row columns={2} className={css(styles.PerpLeverageDetails_gridRow)}>
            <Grid.Column
              className={css(styles.PerpLeverageDetails_gridColumn)}
              textAlign="left"
              width={10}
            >
              <div className={css(styles.PerpLeverageDetails_iconHolder)}>
                <img
                  className={css(styles.PerpLeverageDetails_setIcon)}
                  src={
                    currentSet.image ||
                    tokenSetsTokenListByAddress[currentSet.address.toLowerCase()]?.logoURI ||
                    'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-set-token-icon.svg'
                  }
                  alt={currentSet.name}
                />
              </div>
              <Text color="darkBlue" fontWeight={500}>
                {currentSet.symbol}
              </Text>
            </Grid.Column>

            <Grid.Column textAlign="right" width={6}></Grid.Column>
          </Grid.Row>

          {/* Divider */}
          {hasDefaultPositions && (
            <Grid.Row columns={2} className={css(styles.PerpLeverageDetails_gridRowSubHeader)}>
              <Grid.Column
                className={css(styles.PerpLeverageDetails_gridColumnIntro)}
                textAlign="left"
                width={16}
              >
                Underlying Tokens
                <InfoIcon
                  className={css(styles.PerpLeverageDetails_infoIcon)}
                  data={defaultPositionInfo}
                  hasHighZIndex
                  pointerPosition="top center"
                  loggingInfo="defaultPositions"
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Fragment>
      );
    }

    return (
      <Fragment>
        {/* Table Header */}
        <Grid.Row columns={2} className={css(styles.PerpLeverageDetails_gridRow)}>
          <Grid.Column
            className={css(styles.PerpLeverageDetails_gridColumn)}
            textAlign="left"
            width={4}
          >
            <Text className={css(styles.PerpLeverageDetails_gridHeader)} color="darkGray">
              {t('price-chart.token')}
            </Text>
          </Grid.Column>
          <Grid.Column textAlign="right" width={3}>
            <Text className={css(styles.PerpLeverageDetails_gridHeader)} color="darkGray">
              {t('price-chart.quantity-per-set')}
            </Text>
          </Grid.Column>
          <Divider className={css(styles.PerpLeverageDetails_divider)} />
        </Grid.Row>

        {/*  Set Summary */}
        <Grid.Row columns={2} className={css(styles.PerpLeverageDetails_gridRow)}>
          <Grid.Column
            className={css(styles.PerpLeverageDetails_gridColumn)}
            textAlign="left"
            width={12}
          >
            <div className={css(styles.PerpLeverageDetails_iconHolder)}>
              <img
                className={css(styles.PerpLeverageDetails_setIcon)}
                src={
                  currentSet.image ||
                  tokenSetsTokenListByAddress[currentSet.address.toLowerCase()]?.logoURI ||
                  'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-set-token-icon.svg'
                }
                alt={currentSet.name}
              />
            </div>
            <Text color="darkBlue" fontWeight={500}>
              {currentSet.name}
            </Text>
          </Grid.Column>
          <Grid.Column textAlign="right" width={4}></Grid.Column>
        </Grid.Row>

        {/* Divider */}
        {hasDefaultPositions && (
          <Grid.Row columns={2} className={css(styles.PerpLeverageDetails_gridRowSubHeader)}>
            <Grid.Column
              className={css(styles.PerpLeverageDetails_gridColumnIntro)}
              textAlign="left"
              width={16}
            >
              Underlying Tokens
              <InfoIcon
                className={css(styles.PerpLeverageDetails_infoIcon)}
                data={defaultPositionInfo}
                hasHighZIndex
                pointerPosition="top center"
                loggingInfo="defaultPositions"
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Fragment>
    );
  };

  public renderDefaultPositionsBody = () => {
    const {
      currentSetComponents,
      formattedCurrentSetComponentsWithColors,
      windowDimension: { isTabletLandscape },
    } = this.props;

    let displayedComponentsWithIndex = currentSetComponents.map((c, i) => {
      return { ...c, index: i };
    });

    return displayedComponentsWithIndex.map(tokenWithIndex => {
      const listTokenWithPosition = currentSetComponents[tokenWithIndex.index];
      if (!tokenWithIndex || listTokenWithPosition?.positionState === POSITION_STATE['EXTERNAL']) {
        return null;
      }
      const tokenWithColor = formattedCurrentSetComponentsWithColors.find(
        c => c.address.toLowerCase() === tokenWithIndex.address.toLowerCase(),
      );

      const currentTokenQuantity =
        (tokenWithIndex as IListTokenWithPosition).perTokenPosition?.toString() || '0';

      const isDebtPosition = new BigNumber(currentTokenQuantity).lt(0);
      const tokenName = tokenWithIndex.name + (isDebtPosition ? ' (Debt)' : '');
      const symbolName = tokenWithIndex.symbol;

      if (isTabletLandscape) {
        return (
          <React.Fragment key={`${tokenWithIndex.name}-${tokenWithIndex.index}`}>
            <Grid.Row columns={2} className={css(styles.PerpLeverageDetails_gridRow)}>
              <Grid.Column
                className={css(styles.PerpLeverageDetails_gridColumn)}
                textAlign="left"
                width={10}
              >
                <div className={css(styles.PerpLeverageDetails_iconHolder)}>
                  <img
                    className={css(styles.PerpLeverageDetails_setIcon)}
                    src={
                      tokenWithColor.image ||
                      'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-token-icon.svg'
                    }
                    alt={tokenWithIndex.name}
                  />
                </div>
                <Text color="darkBlue" fontWeight={500}>
                  {symbolName} {isDebtPosition ? ' (Debt)' : ''}
                </Text>
              </Grid.Column>
              <Grid.Column textAlign="right" width={6}>
                <Text color="darkBlue" fontWeight={500}>
                  {currentTokenQuantity?.toString()}
                </Text>
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>
        );
      }

      return (
        <React.Fragment key={`${tokenWithIndex.name}-${tokenWithIndex.index}`}>
          <Grid.Row columns={2} className={css(styles.PerpLeverageDetails_gridRow)}>
            <Grid.Column
              className={css(styles.PerpLeverageDetails_gridColumn)}
              textAlign="left"
              width={4}
            >
              <div className={css(styles.PerpLeverageDetails_tokenNameHolder)}>
                <div className={css(styles.PerpLeverageDetails_iconHolder)}>
                  <img
                    className={css(styles.PerpLeverageDetails_setIcon)}
                    src={
                      tokenWithColor.image ||
                      'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-token-icon.svg'
                    }
                    alt={tokenWithIndex.name}
                  />
                </div>
                <a
                  className={css(styles.PerpLeverageDetails_tokenName)}
                  href={makeBlockExplorerContractLink(tokenWithIndex?.address)}
                  target="_blank"
                >
                  {tokenName}
                </a>
              </div>
            </Grid.Column>
            <Grid.Column textAlign="right" width={3}>
              <Text color="darkBlue">{truncateTokenAmount(currentTokenQuantity)}</Text>
              <Text color="darkGray" className={css(styles.PerpLeverageDetails_setSymbol)}>
                {symbolName}
              </Text>
            </Grid.Column>
          </Grid.Row>
        </React.Fragment>
      );
    });
  };

  public renderCollateralHeader = () => {
    const {
      windowDimension: { isTabletLandscape },
    } = this.props;
    const collateralInfo = {
      header: 'Collateral',
      description:
        'This is the amount deposited into Perpetual Protocol, net of realized PnL and funding rates.',
    };

    if (isTabletLandscape) {
      return (
        <Fragment>
          {/* Divider */}
          <Grid.Row columns={2} className={css(styles.PerpLeverageDetails_gridRowSubHeader)}>
            <Grid.Column
              className={css(styles.PerpLeverageDetails_gridColumnIntro)}
              textAlign="left"
              width={16}
            >
              Collateral on Perpetual Protocol
              <InfoIcon
                className={css(styles.PerpLeverageDetails_infoIcon)}
                data={collateralInfo}
                hasHighZIndex
                pointerPosition="top left"
                loggingInfo="collateral"
              />
            </Grid.Column>
          </Grid.Row>
        </Fragment>
      );
    }

    return (
      <Fragment>
        {/* Divider */}
        <Grid.Row columns={2} className={css(styles.PerpLeverageDetails_gridRowSubHeader)}>
          <Grid.Column
            className={css(styles.PerpLeverageDetails_gridColumnIntro)}
            textAlign="left"
            width={16}
          >
            Collateral on Perpetual Protocol
            <InfoIcon
              className={css(styles.PerpLeverageDetails_infoIcon)}
              data={collateralInfo}
              hasHighZIndex
              pointerPosition="top left"
              loggingInfo="collateral"
            />
          </Grid.Column>
        </Grid.Row>
      </Fragment>
    );
  };

  public renderCollateralBody = () => {
    const {
      coingeckoTokenListByAddress,
      perpCollateralToken,
      perpSetTotalCollateralUnits,
      windowDimension: { isTabletLandscape },
    } = this.props;

    const collateralTokenAddress = perpCollateralToken?.toLowerCase();
    const collateralToken = coingeckoTokenListByAddress[collateralTokenAddress];

    if (!collateralToken) return null;

    const currentTokenQuantity = perpSetTotalCollateralUnits || 0;

    const isDebtPosition = new BigNumber(currentTokenQuantity).lt(0);
    const tokenName = collateralToken.name + (isDebtPosition ? ' (Debt)' : '');
    const symbolName = collateralToken.symbol;

    if (isTabletLandscape) {
      return (
        <React.Fragment key={collateralToken.name}>
          <Grid.Row columns={2} className={css(styles.PerpLeverageDetails_gridRow)}>
            <Grid.Column
              className={css(styles.PerpLeverageDetails_gridColumn)}
              textAlign="left"
              width={10}
            >
              <div className={css(styles.PerpLeverageDetails_iconHolder)}>
                <img
                  className={css(styles.PerpLeverageDetails_setIcon)}
                  src={
                    collateralToken.logoURI ||
                    'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-token-icon.svg'
                  }
                  alt={collateralToken.name}
                />
              </div>
              <Text color="darkBlue" fontWeight={500}>
                {symbolName} {isDebtPosition ? ' (Debt)' : ''}
              </Text>
            </Grid.Column>
            <Grid.Column textAlign="right" width={6}>
              <Text color="darkBlue" fontWeight={500}>
                {currentTokenQuantity?.toString()}
              </Text>
            </Grid.Column>
          </Grid.Row>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment key={collateralToken.name}>
        <Grid.Row columns={2} className={css(styles.PerpLeverageDetails_gridRow)}>
          <Grid.Column
            className={css(styles.PerpLeverageDetails_gridColumn)}
            textAlign="left"
            width={4}
          >
            <div className={css(styles.PerpLeverageDetails_tokenNameHolder)}>
              <div className={css(styles.PerpLeverageDetails_iconHolder)}>
                <img
                  className={css(styles.PerpLeverageDetails_setIcon)}
                  src={
                    collateralToken.logoURI ||
                    'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-token-icon.svg'
                  }
                  alt={collateralToken.name}
                />
              </div>
              <a
                className={css(styles.PerpLeverageDetails_tokenName)}
                href={makeBlockExplorerContractLink(collateralToken?.address)}
                target="_blank"
              >
                {tokenName}
              </a>
            </div>
          </Grid.Column>
          <Grid.Column textAlign="right" width={3}>
            <Text color="darkBlue">
              {truncateTokenAmount(perpSetTotalCollateralUnits?.toString())}
            </Text>
            <Text color="darkGray" className={css(styles.PerpLeverageDetails_setSymbol)}>
              {symbolName}
            </Text>
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    );
  };

  public renderPerpLeverageInfoHeader = () => {
    const {
      windowDimension: { isTabletLandscape },
    } = this.props;
    const leverageInfo = {
      header: 'Virtual Tokens',
      description:
        'These are the virtual positions that Perpetual Protocol creates to generate synthetic exposure to spot assets.',
    };

    if (isTabletLandscape) {
      return (
        <Fragment>
          {/* Divider */}
          <Grid.Row columns={2} className={css(styles.PerpLeverageDetails_gridRowSubHeader)}>
            <Grid.Column
              className={css(styles.PerpLeverageDetails_gridColumnIntro)}
              textAlign="left"
              width={16}
            >
              Virtual Tokens on Perpetual Protocol
              <InfoIcon
                className={css(styles.PerpLeverageDetails_infoIcon)}
                data={leverageInfo}
                hasHighZIndex
                pointerPosition="top left"
                loggingInfo="virtualTokens"
              />
            </Grid.Column>
          </Grid.Row>
        </Fragment>
      );
    }

    return (
      <Fragment>
        {/* Divider */}
        <Grid.Row columns={2} className={css(styles.PerpLeverageDetails_gridRowSubHeader)}>
          <Grid.Column
            className={css(styles.PerpLeverageDetails_gridColumnIntro)}
            textAlign="left"
            width={16}
          >
            Virtual Tokens on Perpetual Protocol
            <InfoIcon
              className={css(styles.PerpLeverageDetails_infoIcon)}
              data={leverageInfo}
              hasHighZIndex
              pointerPosition="top left"
              loggingInfo="virtualTokens"
            />
          </Grid.Column>
        </Grid.Row>
      </Fragment>
    );
  };

  public renderPerpLeverageInfo() {
    const {
      coingeckoTokenListBySymbol,
      vAssetDisplayInfo,
      windowDimension: { isTabletLandscape },
    } = this.props;

    return vAssetDisplayInfo.map(vAsset => {
      if (!vAsset) return null;

      // Base token values
      const currentBaseToken = vAsset.vAssetAddress;
      const currentBaseTokenQuantity = tokenFromBaseUnits(vAsset.positionUnit.toString());
      const currentBaseTokenValue = new BigNumber(
        +tokenFromBaseUnits(vAsset.indexPrice?.toString())
          ? tokenFromBaseUnits(vAsset.indexPrice.toString())
          : 0,
      ).mul(currentBaseTokenQuantity || 0);
      const formattedCurrentBaseTokenValue = currentBaseTokenValue.gte(0)
        ? `$${currentBaseTokenValue.toFixed(2)}`
        : `-$${currentBaseTokenValue.abs().toFixed(2)}`;

      const isBaseDebtPosition = currentBaseTokenValue.lt(0);
      const baseTokenName = vAsset.symbol + (isBaseDebtPosition ? ' (Debt)' : '');
      const baseSymbolName = vAsset.symbol;
      const baseSymbolRoot =
        vAsset.symbol === 'vUSD' ? 'usdc' : vAsset.symbol?.slice(1)?.toLowerCase();
      // Will probably need to replace coingeckoTokenList lookup with a hardcoded list of symbols since Coingecko
      // doesn't have all tokens in certain chain tokenlists
      const baseLogoURI = (
        coingeckoTokenListBySymbol[baseSymbolRoot] ||
        coingeckoTokenListBySymbol['w' + baseSymbolRoot]
      )?.logoURI;

      if (isTabletLandscape) {
        return (
          <React.Fragment key={currentBaseToken}>
            <Grid.Row columns={2} className={css(styles.PerpLeverageDetails_gridRow)}>
              <Grid.Column
                className={css(styles.PerpLeverageDetails_gridColumn)}
                textAlign="left"
                width={10}
              >
                <div className={css(styles.PerpLeverageDetails_iconHolder)}>
                  <img
                    className={css(styles.PerpLeverageDetails_setIcon)}
                    src={
                      baseLogoURI ||
                      tokenLogoOverrides[currentBaseToken.toLowerCase()] ||
                      'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-token-icon.svg'
                    }
                    alt={currentBaseToken}
                  />
                </div>
                <Text color="darkBlue" fontWeight={500}>
                  {baseSymbolName} {isBaseDebtPosition ? ' (Debt)' : ''}
                </Text>
                <br />
                {tokenFromBaseUnits(vAsset.currentLeverageRatio?.toString())?.toNumber() !== 0 && (
                  <Text color="darkGray" className={css(styles.PerpLeverageDetails_setSymbol)}>
                    Current Leverage Ratio:{' '}
                    {tokenFromBaseUnits(vAsset.currentLeverageRatio.toString()).toFixed(3)}x
                  </Text>
                )}
              </Grid.Column>
              <Grid.Column textAlign="right" width={6}>
                <Text color="darkBlue" fontWeight={500}>
                  {formattedCurrentBaseTokenValue}
                </Text>
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>
        );
      }

      return (
        <React.Fragment key={currentBaseToken}>
          <Grid.Row columns={2} className={css(styles.PerpLeverageDetails_gridRow)}>
            <Grid.Column
              className={css(styles.PerpLeverageDetails_gridColumn)}
              textAlign="left"
              width={4}
            >
              <div className={css(styles.PerpLeverageDetails_tokenNameHolder)}>
                <div className={css(styles.PerpLeverageDetails_iconHolder)}>
                  <img
                    className={css(styles.PerpLeverageDetails_setIcon)}
                    src={
                      baseLogoURI ||
                      tokenLogoOverrides[currentBaseToken.toLowerCase()] ||
                      'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-token-icon.svg'
                    }
                    alt={currentBaseToken}
                  />
                </div>
                <div>
                  <a
                    className={css(styles.PerpLeverageDetails_tokenName)}
                    href={makeBlockExplorerContractLink(currentBaseToken)}
                    target="_blank"
                  >
                    {baseTokenName}
                  </a>
                  <br />
                  {tokenFromBaseUnits(vAsset.currentLeverageRatio?.toString())?.toNumber() !==
                    0 && (
                    <Text color="darkGray" className={css(styles.PerpLeverageDetails_setSymbol)}>
                      Current Leverage Ratio:{' '}
                      {tokenFromBaseUnits(vAsset.currentLeverageRatio.toString()).toFixed(3)}x
                    </Text>
                  )}
                </div>
              </div>
            </Grid.Column>
            <Grid.Column textAlign="right" width={3}>
              <Text color="darkBlue">
                {truncateTokenAmount(currentBaseTokenQuantity.toString())}
              </Text>
              <Text color="darkGray" className={css(styles.PerpLeverageDetails_setSymbol)}>
                {baseSymbolName}
              </Text>
            </Grid.Column>
            <Grid.Column textAlign="right" width={3}>
              <Text color="darkBlue">
                ${Number(tokenFromBaseUnits(vAsset.indexPrice?.toString())).toFixed(2)}
              </Text>
            </Grid.Column>
          </Grid.Row>
        </React.Fragment>
      );
    });
  }

  public render() {
    const { isLoading, currentSet, windowDimension } = this.props;
    const { isTablet } = windowDimension;
    const { components } = currentSet;

    if (!isLoading && !components?.length) return null;

    const intendedChain = urlIntendedChain();
    const ContainerElement = isTablet ? 'div' : Container;
    const paddingBottom = styles.PerpLeverageDetails_gridPaddingBottom;

    return isLoading ? (
      <PerpLeverageDetailsPlaceholder windowDimension={windowDimension} />
    ) : (
      <ContainerElement
        className={css(styles.PerpLeverageDetails_container)}
        style={{
          ...(intendedChain === NETWORK_CONSTANTS.POLYGON_CHAIN && {
            borderTop: '5px solid',
            borderImageSlice: '1 0 0',
            borderImageSource: COLORS.polygonGradient,
            paddingTop: '10px',
          }),
        }}
      >
        <div className={css(styles.PerpLeverageDetails_centerAlignBlock)}>
          <Grid
            className={css(
              styles.PerpLeverageDetails_grid,
              intendedChain === NETWORK_CONSTANTS.POLYGON_CHAIN &&
                styles.PerpLeverageDetails_polygonGrid,
              paddingBottom,
            )}
          >
            {this.renderDefaultPositionsHeader()}
            {this.renderDefaultPositionsBody()}
            {this.renderCollateralHeader()}
            {this.renderCollateralBody()}
            {this.renderPerpLeverageInfoHeader()}
            {this.renderPerpLeverageInfo()}
          </Grid>
        </div>
      </ContainerElement>
    );
  }
}

export default withTranslation('set-details')(PerpLeverageDetails);
