import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Button, Input } from 'semantic-ui-react';
import { DraftInlineStyle } from 'draft-js';

import { COLORS } from '../../../constants/index';
import { INLINE_STYLE_OPTIONS, CUSTOM_MEDIA_OPTIONS, customMediaOptionToLabelMap } from '../enums';
import { withTranslation, WithTranslation } from 'react-i18next';

const styles = StyleSheet.create({
  EditorControls_container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '12px',
    borderBottom: `1px solid ${COLORS.gray}`,
  },
  EditorControls_controlsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  EditorControls_activeControlOption: {
    color: COLORS.darkBlue,
  },
  EditorControls_controlOption: {
    display: 'inline-block',
    margin: '6px',
    fontSize: '20px',
    ':hover': {
      cursor: 'pointer',
    },
  },
  EditorControls_inputContainer: {
    width: '100%',
    marginBottom: '12px',
  },
  EditorControls_input: {
    fontSize: '16px',
    width: '100%',
    marginBottom: '12px',
  },
  EditorControls_buttonContainer: {
    display: 'flex',
  },
  EditorControls_button: {
    color: COLORS.white,
    backgroundColor: COLORS.darkBlue,
    ':hover': {
      backgroundColor: COLORS.darkBlueDarkened,
    },
  },
  EditorControls_secondaryButton: {
    color: COLORS.darkBlue,
    backgroundColor: COLORS.white,
    border: `1px solid ${COLORS.darkBlue}`,
    ':hover': {
      backgroundColor: COLORS.lightGray,
    },
  },
});

export interface IEditorControlsProps {
  inlineStyleOptions: INLINE_STYLE_OPTIONS[];
  dataInputOptions: CUSTOM_MEDIA_OPTIONS[];
  activeDataInputType?: string;
  activeInlineStyles?: DraftInlineStyle;
  linkText?: string;
  onSelectInlineStyleControl: (...args: any[]) => any;
  onSelectDataInputType: (...args: any[]) => any;
  onUpdateImageInput: (...args: any[]) => any;
  onSubmitImage: (...args: any[]) => any;
  onUpdateLinkTextInput: (...args: any[]) => any;
  onUpdateLinkURLInput: (...args: any[]) => any;
  onSubmitLink: (...args: any[]) => any;
}

class EditorControls extends PureComponent<IEditorControlsProps & WithTranslation> {
  public renderInlineStyleControlOption = (decoratorName: INLINE_STYLE_OPTIONS) => {
    const { activeInlineStyles, onSelectInlineStyleControl } = this.props;

    return (
      <span
        key={decoratorName}
        className={css(
          styles.EditorControls_controlOption,
          activeInlineStyles.has(decoratorName.toUpperCase()) &&
            styles.EditorControls_activeControlOption,
        )}
        onMouseDown={(e: any) => onSelectInlineStyleControl(e, decoratorName)}
      >
        {decoratorName.toLowerCase()}
      </span>
    );
  };

  public renderBlockControlOption = (blockType: CUSTOM_MEDIA_OPTIONS) => {
    const { activeDataInputType, onSelectDataInputType } = this.props;

    return (
      <span
        key={blockType}
        className={css(
          styles.EditorControls_controlOption,
          activeDataInputType == blockType && styles.EditorControls_activeControlOption,
        )}
        onMouseDown={(e: any) => onSelectDataInputType(e, blockType)}
      >
        {customMediaOptionToLabelMap[blockType]}
      </span>
    );
  };

  public renderImageInput = () => {
    const {
      activeDataInputType,
      onSelectDataInputType,
      onUpdateImageInput,
      onSubmitImage,
      t,
    } = this.props;

    if (activeDataInputType !== CUSTOM_MEDIA_OPTIONS.IMAGE) return;

    return (
      <div className={css(styles.EditorControls_inputContainer)}>
        <Input
          className={css(styles.EditorControls_input)}
          placeholder={t('rich-text-editor.add-image-url-placeholder')}
          onChange={onUpdateImageInput}
        />
        <div className={css(styles.EditorControls_buttonContainer)}>
          <Button className={css(styles.EditorControls_button)} onClick={onSubmitImage}>
            {t('buttons.submit')}
          </Button>
          <Button
            className={css(styles.EditorControls_secondaryButton)}
            onClick={(e: any) => onSelectDataInputType(e, '')}
          >
            {t('buttons.cancel')}
          </Button>
        </div>
      </div>
    );
  };

  public renderURLInput = () => {
    const {
      activeDataInputType,
      linkText,
      onSelectDataInputType,
      onUpdateLinkURLInput,
      onUpdateLinkTextInput,
      onSubmitLink,
      t,
    } = this.props;

    if (activeDataInputType !== CUSTOM_MEDIA_OPTIONS.LINK) return;

    return (
      <div className={css(styles.EditorControls_inputContainer)}>
        <Input
          className={css(styles.EditorControls_input)}
          placeholder={t('rich-text-editor.add-link-text-placeholder')}
          value={linkText}
          onChange={onUpdateLinkTextInput}
        />
        <Input
          className={css(styles.EditorControls_input)}
          placeholder={t('rich-text-editor.add-link-url-placeholder')}
          onChange={onUpdateLinkURLInput}
        />
        <div className={css(styles.EditorControls_buttonContainer)}>
          <Button className={css(styles.EditorControls_button)} onClick={onSubmitLink}>
            {t('buttons.submit')}
          </Button>
          <Button
            className={css(styles.EditorControls_secondaryButton)}
            onClick={(e: any) => onSelectDataInputType(e, '')}
          >
            {t('buttons.cancel')}
          </Button>
        </div>
      </div>
    );
  };

  public render() {
    const { inlineStyleOptions, dataInputOptions } = this.props;

    return (
      <div className={css(styles.EditorControls_container)}>
        <div className={css(styles.EditorControls_controlsContainer)}>
          {inlineStyleOptions.map(this.renderInlineStyleControlOption)}
          {dataInputOptions.map(this.renderBlockControlOption)}
        </div>
        {this.renderImageInput()}
        {this.renderURLInput()}
      </div>
    );
  }
}

export default withTranslation('components')(EditorControls);
