import React, { Fragment } from 'react';
import { Header, List } from 'semantic-ui-react';
import { css, StyleSheet } from 'aphrodite';

import Text from '../../components/Text';

const styles = StyleSheet.create({
  marginBottomSmall: {
    marginBottom: '2rem',
  },
  header: {
    paddingTop: '16px',
    paddingBottom: '10px',
  },
  subheader: {
    fontSize: '14px',
    textDecoration: 'underline',
    paddingTop: '16px',
    paddingBottom: '10px',
  },
  contentPadding: {
    paddingTop: '10px',
    paddingBop: '10px',
  },
  bullet: {
    fontSize: '18px',
  },
});

const TermsOfServiceText = () => (
  <Fragment>
    <Text tag="p" className={css(styles.contentPadding)}>
      This document was last updated on January 21, 2020.
    </Text>
    <Text tag="p" className={css(styles.contentPadding)}>
      Please read these Terms of Service (this “Agreement”) carefully. Your use or access of the
      Site (as defined below) constitutes your consent to this Agreement
    </Text>
    <Text tag="p" className={css(styles.contentPadding)}>
      This Agreement is between you and Set Labs Inc. (“Company”, “Set”, “we”, “our” or “us”)
      concerning your use of (including any access to) Company’s websites, including but not limited
      to setprotocol.com, tokensets.com and each of their subdomains, our mobile applications, and
      our web applications (collectively with any other materials and services available therein,
      and successor site(s) or application(s) thereto, the “Site”). This Agreement hereby
      incorporates by this reference any additional terms and conditions with respect to the Site
      posted by Company to the Site, or otherwise made available to you by Company, including
      without limitation, during the creation, acquisition, transfer, or modification of certain
      digital assets, our online and/or mobile services, and software provided on or in connection
      with those services.
    </Text>

    <Header as="h4" className={css(styles.header)}>
      Acceptance
    </Header>
    <Text tag="p" className={css(styles.contentPadding)}>
      By clicking or tapping any button or box marked “accept,” “agree” or “OK” (or a similar term)
      in connection with this Agreement, or by accessing or using the Site, you agree to be bound by
      this Agreement and affirm that you are of legal age to enter into this Agreement where you
      live and have the legal capacity to enter into this Agreement.
    </Text>
    <Text tag="p" className={css(styles.contentPadding)}>
      If you are an individual accessing or using the Site on behalf of, or for the benefit of, any
      corporation, partnership or other entity with which you are associated (an “Organization”),
      then you are agreeing to this Agreement on behalf of yourself and such Organization, and you
      represent and warrant that you have the legal authority to bind such Organization to this
      Agreement. References to “you” and “your” in this Agreement will refer to both the individual
      using the Site and to any such Organization.
    </Text>

    <Header as="h4" className={css(styles.header)}>
      Eligibility
    </Header>
    <Text tag="p" className={css(styles.contentPadding)}>
      This site is offered and available to users who are 18 years of age or older, or, if the
      registered user is an Organization, you have the right, power and authority to enter into this
      agreement on behalf of the registered user and bind the registered user to its terms. If the
      registered user does not agree to the terms of this agreement, we will not provide our
      services and you must not use the Site or any of our services. Without limiting the foregoing,
      by using our Site, you acknowledge and understand that laws regarding financial instruments,
      which sometimes include Crypto Assets (as defined below), may vary from jurisdiction to
      jurisdiction, and it is your obligation alone to ensure that you fully comply with any law,
      regulation or directive, relevant to your jurisdiction with regard to the use of our Site. For
      the avoidance of doubt, the ability to access our Site does not necessarily mean that our
      Site, or your activities through it, are legal under the laws, regulations or directives
      relevant to your jurisdiction. All of our Site or the services made available through our Site
      may not be available to all users, and we reserve the right to assess or reassess at any time
      your eligibility to use all or part of our Site. Our Site does not constitute, and may not be
      used for the purposes of, an offer or solicitation to anyone in any jurisdiction in which such
      offer or solicitation is not authorized, or to any person to whom it is unlawful to make such
      an offer or solicitation.
    </Text>

    <Header as="h4" className={css(styles.header)}>
      Services
    </Header>

    <Header as="h5" className={css(styles.subheader)}>
      Token Sets
    </Header>
    <Text tag="p" className={css(styles.contentPadding)}>
      Through use of the Site, individuals can interact with the Ethereum blockchain to create
      collateralized baskets of Crypto Assets (“Sets”) and to acquire, mint, redeem and
      programmatically realign weights on Sets without intermediaries while remaining in control of
      their Crypto Assets. These Sets’ rebalance signals are generated through predefined,
      algorithmic logic encoded into smart contracts. Certain signals have been encoded and made
      available by default by the Company, and others may come from third party signal providers
      (“Traders”). “Crypto Assets” as used in this Agreement, refers to ERC20 tokens, implemented on
      the Ethereum blockchain using smart contracts, which tokens include any Set created using the
      Set Protocol. Set does not endorse any particular Set or otherwise officially advise you with
      respect to a particular Set, and any predefined Sets made available on the Site are not
      intended to be personalized financial advice to you.
    </Text>
    <Text tag="p" className={css(styles.contentPadding)}>
      To access the services on the Site, including to create a Set, you must have access to a
      compatible virtual currency wallet (a “Wallet”) that supports ERC20 tokens. Any Crypto Assets
      you acquire through the Site will be held and administered solely by you through your Wallet,
      and we shall have no access to or responsibility in regard to your Wallet or any Crypto Asset
      held in your Wallet. It is solely your responsibility to determine what Wallet software to use
      in connection with the Site, and your use of such Wallets are subject to any governing terms
      of use or privacy policy of such Wallet. While the Company has added support for certain
      Wallets and Crypto Assets on the site, you acknowledge and agree that we are under no
      obligation to add additional support for other Wallets or other Crypto Assets, and that the
      inclusion of any particular Wallet or Crypto Asset is not an endorsement by us.
    </Text>
    <Text tag="p" fontWeight={500} color="black" className={css(styles.contentPadding)}>
      Set will not create a hosted Wallet for you or otherwise custody Crypto Assets on your behalf,
      and it is your sole responsibility to maintain the security of your Wallet. In the event that
      you lose access to your Wallet, a private key, password, or other method of securing your
      Wallet, any funds may be irretrievable, and Set will be unable to assist you in any way. You
      hereby irrevocably waive, release and discharge all claims, whether known or unknown to you,
      against Set, its affiliates and their respective shareholders, members, directors, officers,
      employees, agents and representatives related to your use of any Wallet software, associated
      loss of funds, transaction failures, or any other defects that arise in the course of your use
      of your Wallet, including any losses that may obtain as a result of any failure in smart
      contracts made available on the Site.
    </Text>
    <Text tag="p" className={css(styles.contentPadding)}>
      You may need or be offered the opportunity to register for an account (an “Account”) in order
      to access some or all of the services on the Site. When you register for an Account, you must
      provide accurate information and promptly update this information if it changes. You must also
      maintain the security of your Account and promptly notify us if you discover or suspect that
      someone has accessed your Account without your permission. If you permit others to use your
      Account credentials, you are responsible for the activities of such users that occur in
      connection with your Account. We reserve the right to reclaim usernames, including on behalf
      of businesses or individuals that hold legal claim, including trademark rights, in those
      usernames. We reserve the right to require you to submit information confirming and verifying
      your identity in connection with your registration of an Account or to otherwise use certain
      services on the Site, and by accepting this Agreement, you agree to our verification policy as
      determined by us from time-to-time.
    </Text>
    <Text tag="p" fontWeight={500} color="black" className={css(styles.contentPadding)}>
      We reserve the right to modify, suspend or discontinue, temporarily or permanently, all or any
      part of our Site with or without notice. You agree that we will not be liable to you or to any
      third party for any modification, suspension or discontinuance of all or any part of our Site.
    </Text>

    <Text tag="p" color="black" className={css(styles.contentPadding)}>
      THE SITE IS AN ADMINISTRATIVE PLATFORM ONLY. WE ARE NOT A BROKER, DEALER, FINANCIAL
      INSTITUTION, EXCHANGE, CUSTODIAN, ROBO-ADVISOR, INTERMEDIARY, OR CREDITOR. THE SITE DOES NOT
      FACILITATE TRANSACTIONS BETWEEN BUYERS AND SELLERS, INCLUDING WITH RESPECT TO ANY TRANSACTIONS
      THAT OCCUR DURING A REBALANCE, WHICH TRANSACTIONS OCCUR ON THIRD-PARTY, UNAFFILIATED
      PLATFORMS. THE COMPANY IS NOT A COUNTERPARTY TO ANY TRANSACTION ON THE SITE OR FOR ANY USER OF
      THE SITE. NEITHER THE SITE NOR THE COMPANY PROVIDES FINANCIAL ADVISORY, LEGAL, REGULATORY, OR
      TAX SERVICES DIRECLTY, INDIRECTLY, IPLICITLY, OR IN ANY OTHER MANNER, AND YOU SHOULD NOT
      CONSIDER ANY CONTENT CONTAINED IN THIS AGREEMENT OR OTHERWISE POSTED ON THE SITE TO BE A
      SUBSTITUTE FOR PROFESSIONAL FINANCIAL, LEGAL, REGULATORY, TAX OR OTHER ADVICE. THE COMPANY
      DOES NOT SUPPORT OR ENDORSE ANY TRADER, AND EACH TRADER IS AN INDEPENDENT AGENT WITH NO
      EMPLOYMENT RELATIONSHIP WITH THE COMPANY.
    </Text>

    <Header as="h5" className={css(styles.subheader)}>
      Social Trading Services
    </Header>
    <Text tag="p" className={css(styles.contentPadding)}>
      As part of the services offered on the Site, you have the ability to follow and interact with
      Traders by utilizing the social trading features made available on the Site (collectively,
      “Social Trading”). Social Trading allows you to subscribe to and copy certain Traders’ Sets by
      providing you with the ability to execute code on the Ethereum blockchain that will
      automatically mirror a Traders’ activity on the Site. You acknowledge and agree that, once
      initiated, Social Trading will automatically result in the execution of certain transactions
      in accordance with predetermined strategies, and hereby consent to such automatic execution.
      Social Trading services also include tools to test, evaluate and select your rebalance
      strategy by providing you with detailed Trader information, trading history and other
      pertinent information (“Information”) you may want to consider before electing to subscribe to
      a Trader maintained Set. We may also provide to you information about your transactions,
      including procedures, risks and other factual market information. However, we are under no
      obligation to provide such Information to you and if we do, it will not constitute investment
      advice and is intended solely for informational purposes. In the event that we are ever acting
      as Traders, we will specifically identify any Sets as being affiliated with the Company.
    </Text>
    <Text tag="p" className={css(styles.contentPadding)}>
      Social Trading is a new feature and should be considered a “beta” product. In making a
      decision to utilize Social Trading, you hereby represent and warrant that you have considered
      your entire financial situation including financial commitments and you understand that using
      Social Trading is highly speculative and that you could sustain significant losses, including
      as a result of the failure of underlying software. We do not provide any guarantee as to the
      performance of any particular transaction or set of transactions, portfolio or strategy, and
      we do not in any manner endorse, support, sanction, encourage, verify or agree with any
      Trader, portfolios or strategies. Execution of any instructions generated by our Social
      Trading services will occur exclusively on the Ethereum blockchain, and in no way shall we be
      responsible for any failure to execute any instructions or code on the Ethereum blockchain
      network. You hereby agree that we will not be liable to you or to any third party for any
      failure to execute instructions transmitted on the Ethereum blockchain network or any other
      defect that arises in connection with your use of the Social Trading features that are caused
      by a failure of the underlying blockchain network or smart contract code. You further
      represent and warrant that you have reviewed and acknowledged all of the risks attendant to
      using the Site, including the risks related to Social Trading described in this Agreement.
    </Text>
    <Text tag="p" className={css(styles.contentPadding)}>
      The Company is not providing any portfolio management services in connection with the Site.
      You should carefully review all claims and representations made by Traders before making a
      transaction decision. If you choose to engage in transactions based on Information or other
      content on the website or applications or elect to copy specific Traders, then such decisions
      and transactions and any consequences flowing therefrom are your sole responsibility.
    </Text>
    <Text tag="p" className={css(styles.contentPadding)}>
      You should use any content or Information gathered from the Site only as a starting point for
      your own independent research and transactional decision making, and you must rely on your own
      judgment in entering into or refraining from using the Site or conducting (or not conducting)
      any transaction. In no event shall the Company be responsible or liable to you or anyone else,
      directly or indirectly, for any damage or loss arising from or relating to any use, continued
      use or reliance on any Information or tools, including, without limitation, directly or
      indirectly resulting from errors in, omissions of or alterations to any such Information.
    </Text>
    <Text tag="p" className={css(styles.contentPadding)}>
      We reserve the right to suspend or terminate a Trader for any reason, particularly for
      violations of the Company’s policies and applicable laws. You agree that we will not be liable
      to you or to any third party for any suspension or termination of a Trader.
    </Text>
    <Text tag="p" fontWeight={500} color="black" className={css(styles.contentPadding)}>
      By using the Site, you agree to be fully, independently and personally liable for each
      transaction made on the Site by you, including, without limitation, any position automatically
      opened as a result of using our Social Trading features. As such, you must make sure that you
      are the only person with access to your Wallet and/or Account at all times. You must make sure
      that no minors have access to your Wallet and/or Account. In the unlikely event that a
      contract is entered to acquire or sell Crypto Assets at a price that does not reflect the
      market price (such as an event where a technical error such as a bug or defect has caused a
      malfunction that has affected the price of the transaction), we reserve the right to terminate
      and cancel any such transaction to the extent technically feasible through blockchain
      networks. We will notify you of our decision to cancel the transaction and explain our reasons
      to you. On the other hand, you are required to inform us of any such malfunction should you
      experience such a malfunction whilst using the Site.
    </Text>

    <Header as="h4" className={css(styles.header)}>
      Fees
    </Header>
    <Text tag="p" className={css(styles.contentPadding)}>
      Some services on the site involve the use of the Ethereum blockchain network, which may
      require that you pay a fee, commonly known as “Ethereum Gas Charges,” for the computational
      resources required to perform a transaction. You acknowledge and agree that the Company has no
      control over: (a) any Ethereum blockchain transactions; (b) the method of payment of any
      Ethereum Gas Charges; or (c) any actual payments of Ethereum Gas Charges. Accordingly, you
      must ensure that you have a sufficient balance of Ether stored at your Wallet to complete any
      transaction on the Ethereum blockchain network before initiating such transactions. We will
      make reasonable efforts to notify you of any Ethereum Gas Charges before initiating any
      services that require the use of the Ethereum blockchain network.
    </Text>
    <Text tag="p" className={css(styles.contentPadding)}>
      You may be subject to certain additional fees and commissions, including fees imposed by
      Traders. While we do not currently charge for the use of any of our services or access to the
      Site, we reserve the right to levy additional fees in the future, including fees for access to
      Information or service fees to support the operation of the Site. You agree to promptly pay
      such fees and commissions, as set forth on the Site and as applicable to your Account and the
      transactions and services you receive, which we may modify from time to time. In the event
      that we add fees to the Site or any of our services, we will alert you in writing prior to any
      such fees going into effect.
    </Text>
    <Text tag="p" className={css(styles.contentPadding)}>
      Notwithstanding anything in this Agreement to the contrary, it is your sole responsibility to
      determine whether, and to what extent, any taxes apply to any transactions you conduct through
      the Site, and to withhold, collect, report and remit the correct amount of taxes to the
      appropriate tax authorities.
    </Text>

    <Header as="h4" className={css(styles.header)}>
      Content
    </Header>
    <Text tag="p" className={css(styles.contentPadding)}>
      By acceptance of this Agreement, you agree and acknowledge that the following information and
      content shall be considered non-confidential and non-proprietary information and shall be
      publicly shown on the Site (the “Content”): your (a) username, (b) picture or avatar (if
      provided), (c) networks, (d) list of users who follow you (in the event you are a Trader), (e)
      any Traders you follow or Sets you own, and (f) posts, blogs and any other content options
      that enable our users to interact amongst themselves, including, without limitation, content
      and information you post on our community, comments, feedback, postings, “likes,” blogs and
      all other information that you provide to us via our website, our mobile apps, the Site, or by
      email, chat, fax or telephone or any other means. In addition, if you have elected to use one
      of our Site in connection with a social network (such as LinkedIn, Twitter, etc.), the Site
      will access your social network account general information, which includes your name and
      username in such social network, profile picture, gender, networks, user ID, list of friends,
      and any other information you have shared publicly on the relevant social network. All Set,
      Information, and trading information performance results shall be considered non-confidential
      and nonproprietary information and as the Company’s property. By providing such Content, you
      specifically grant us a non-exclusive, irrevocable, transferable, sub-licensable,
      royalty-free, worldwide license to use, copy, duplicate store, present and publish all or any
      part of your Content, and we shall be free to use such Content in any manner or media
      whatsoever, on an unrestricted basis and without any attribution or royalties or other
      compensation to you, including, without limitation, within or outside our website, mobile
      apps, the Site, advertisements, in printed media, and newspapers.
    </Text>
    <Text tag="p" className={css(styles.contentPadding)}>
      The Site may contain links and references to non-Site websites and resources (“Linked
      Websites”) and are provided for convenience only. If you decide to leave the Site and access
      Linked Websites, you do so at your own risk, and Site will have no liability arising out of or
      related to such Linked Websites and/or their content or any damages or loss caused or alleged
      to be caused by or in connection of any purchase or use of any such content, goods or services
      available on or through any such Linked Website. Your use of any Linked Websites shall be
      subject to such Linked Websites terms of use and privacy policy. Site has not reviewed the
      Linked Websites and we are not responsible for the content, accuracy or opinions expressed on
      these websites. Inclusion of these links on our Websites does not apply approval or
      endorsement by Site of the Linked Websites, their entities or products and services. Please
      understand that the Linked Websites, even if they contain the Company’s logo, are independent
      websites, and the Company does not control the content on that Linked Website. Additionally,
      the Company is not a party to or responsible for any transaction you may enter into with any
      such third party, even if you learn of such third party from the Company, by way of reference
      or link provided on the Company’s Websites.
    </Text>
    <Text tag="p" className={css(styles.contentPadding)}>
      You acknowledge that you are responsible for any Content that you submit or transmit through
      any of our websites, applications or any posts, blogs and any other communications and content
      options available to us, including your responsibility as to the legality, reliability,
      appropriateness, originality and copyright of any such information or material. Additionally,
      you represent and warrant that: (i) you own all right title and interest in any Content
      provided by you, (ii) such Content does not violate any applicable laws, and (iii) the posting
      of your Content by us (in any manner or media whatsoever, on an unrestricted basis) does not
      (and will not) violate the privacy rights, publicity rights, copyright, contract rights or any
      other rights of any individual or make derogatory remarks regarding, defame or otherwise
      criticize any person or entity. You shall be solely liable for any damage resulting from any
      infringement or other violation of the copyright, trademarks or other proprietary rights of
      any individual or entity, and for any other harm or losses resulting from any Content.
    </Text>

    <Header as="h4" className={css(styles.header)}>
      Rules of Conduct
    </Header>
    <Text tag="p" className={css(styles.contentPadding)}>
      In connection with the use of the Site, you must not:
    </Text>
    <List ordered className={css(styles.marginBottomSmall)}>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Post, transmit or otherwise make available through or in connection with the Site any
          materials that are or may be: (a) threatening, harassing, degrading, hateful or
          intimidating, or otherwise fail to respect the rights and dignity of others; (b)
          defamatory, libelous, fraudulent or otherwise tortious; (c) obscene, indecent,
          pornographic or otherwise objectionable; or (d) protected by copyright, trademark, trade
          secret, right of publicity or privacy or any other proprietary right, without the express
          prior written consent of the applicable owner.
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Post, transmit or otherwise make available through or in connection with the Site any
          virus, worm, Trojan horse, Easter egg, time bomb, spyware, scareware, malware or other
          computer code, file or program that is or is potentially harmful or invasive or intended
          to damage or hijack the operation of, or to monitor the use of, any hardware, software or
          equipment (each, a “Virus”).
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Use the Site for any commercial purpose except as explicitly provided by this Agreement or
          any other terms agreed to in writing by us, or for any purpose that is fraudulent or
          otherwise tortious or unlawful
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Harvest or collect information about users of the Site except as explicitly provided by
          this Agreement and our Privacy Policy.
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Use the Site to transmit through or in connection with the Site, any spam, chain letters
          or other unsolicited communications.
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Interfere with or disrupt the operation of the Site or the servers or networks used to
          make the Site available, including by hacking or defacing any portion of the Site
          (including any content available thereby); or violate any requirement, procedure or policy
          of such servers or networks.
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>Restrict or inhibit any other person from legal use of the Site.</Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Reproduce, modify, adapt, translate, create derivative works of, sell, rent, lease, loan,
          timeshare, distribute or otherwise exploit any portion of (or any use of) the Site except
          as expressly authorized herein, without Company’s express prior written consent.
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Reverse engineer, decompile or disassemble any portion of the Site, except to the extent
          such restriction is expressly prohibited by applicable law.
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Remove any copyright, trademark or other proprietary rights notice from the Site.
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Frame or mirror any portion of the Site, or otherwise incorporate any portion of the Site
          into any product or service, without Company’s express prior written consent.
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>Systematically download and store Site content.</Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Use any robot, spider, site search/retrieval application or other manual or automatic
          device to retrieve, index, “scrape,” “data mine” or otherwise gather Site content
          (including Submissions), or reproduce or circumvent the navigational structure or
          presentation of the Site, without Company’s express prior written consent. Notwithstanding
          the foregoing, and subject to compliance with applicable law and any instructions posted
          in the robots.txt file located in the Site’s root directory, Company grants to the
          operators of public search engines permission to use spiders to copy materials from the
          Site for the sole purpose of (and solely to the extent necessary for) creating publicly
          available, searchable indices of such materials, but not caches or archives of such
          materials. Company reserves the right to revoke such permission either generally or in
          specific cases, at any time and without notice.
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Activity which would violate, or assist in violation of, any law, statute, ordinance, or
          regulation, sanctions programs administered in any jurisdiction the Site operates,
          including but not limited to the U.S. Department of Treasury's Office of Foreign Assets
          Control ("OFAC"), or which would involve proceeds of any unlawful activity; publish,
          distribute or disseminate any unlawful material or information.
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Use the Site for any purpose that could be construed as gambling or facilitating such,
          including but not limited to lotteries, sports forecasting or odds making, fantasy sports
          leagues with cash prizes, internet gaming, contests, sweepstakes or games of chance.
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Using trading strategies aimed at exploiting errors in prices, conducting transactions at
          off-market prices, or taking advantage of Internet issues, blockchain network failures,
          connectivity delays, market events, price feed errors, or other clear defects where prices
          displayed on the Site do not actually reflect the market rates, or entering into
          transactions or combinations of transactions which, taken together or separately, are for
          the purpose of manipulating the Site.
        </Text>
      </List.Item>
    </List>

    <Header as="h4" className={css(styles.header)}>
      Access to the Site and the Services
    </Header>
    <List ordered className={css(styles.marginBottomSmall)}>
      <List.Item className={css(styles.bullet)}>
        <Text color="black" fontWeight={500}>
          License.{' '}
        </Text>
        <Text>
          Subject to this Agreement, Set grants you a non-transferable, non-exclusive, revocable,
          limited license to use and access the Site and related content, materials, information
          (collectively, the "Site Content") solely for your personal use. Any other use of the Site
          or Site Content is expressly prohibited and all other right, title, and interest in the
          Site or Site Content is exclusively the property of Set and its licensors.
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text color="black" fontWeight={500}>
          Certain Restrictions.{' '}
        </Text>
        <Text>
          The rights granted to you in this Agreement are subject to the following restrictions: (a)
          you shall not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise
          commercially exploit the Site, whether in whole or in part, or any content displayed on
          the Site; (b) you shall not modify, make derivative works of, disassemble, reverse compile
          or reverse engineer any part of the Site; (c) you shall not access the Site in order to
          build a similar or competitive website, product, or service; and (d) except as expressly
          stated herein, no part of the Site may be copied, reproduced, distributed, republished,
          downloaded, displayed, posted or transmitted in any form or by any means. Unless otherwise
          indicated, any future release, update, or other addition to functionality of the Site
          shall be subject to this Agreement. All copyright and other proprietary notices on the
          Site (or on any Site Content) must be retained on all copies thereof.
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text color="black" fontWeight={500}>
          Site and Service Availability.{' '}
        </Text>
        <Text>
          Set shall use commercially reasonable efforts to attempt to provide the Site on a
          twenty-four (24) hours a day, seven (7) days a week basis. You acknowledge and agree that
          from time to time this site may be inaccessible or inoperable for any reason including,
          but not limited to, equipment malfunctions; periodic maintenance, repairs or replacements
          that Set undertakes from time to time; or causes beyond our reasonable control or that are
          not reasonably foreseeable including, but not limited to, interruption or failure of
          telecommunication or digital transmission links, failure on or congestion of the Ethereum
          blockchain network, hostile network attacks, network congestion or other failures. You
          acknowledge and agree that Set has no control over the availability of this Site on a
          continuous or uninterrupted basis, and that Set assumes no liability to You or any other
          party with regard to such, including but not limited to loss of revenue.
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text color="black" fontWeight={500}>
          Customer Support.{' '}
        </Text>
        <Text>
          The Site and any related services are provided “as-is” and Set does not commit to
          providing any Customer Support in connection with the Site and is under no obligation to
          do so. However, we are happy to discuss any challenges or questions you may have about the
          Site and may offer assistance on a case-by-case basis. If you have questions about any
          aspect of the Site, you can send an email to{' '}
          <a href="mailto:hello@setprotocol.com">{' hello@setprotocol.com'}</a>.
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text color="black" fontWeight={500}>
          Ownership.{' '}
        </Text>
        <Text>
          You acknowledge that all the intellectual property rights, including copyrights, patents,
          trademarks, and trade secrets, in the Site and Site Content are owned by Set or Set’s
          suppliers. Neither this Agreement nor your access to the Site transfers to you or any
          third party any rights, title or interest in or to such intellectual property rights,
          except for the limited access rights expressly set forth in this Agreement. Set and its
          suppliers reserve all rights not granted in this Agreement. There are no implied licenses
          granted under this Agreement.
        </Text>
      </List.Item>
    </List>

    <Header as="h4" className={css(styles.header)}>
      Risks and Disclaimers
    </Header>
    <Text tag="p" className={css(styles.contentPadding)}>
      Use of the Site and participation in Transactions may carry financial risk. “Transactions” as
      used in this Agreement are defined as direct interactions with Set Protocol smart contracts on
      the Ethereum blockchain. You acknowledge and agree that you are aware of such risks, including
      the following:
    </Text>
    <List bulleted className={css(styles.marginBottomSmall)}>
      <List.Item className={css(styles.bullet)}>
        <List.Description>
          <Text>
            Transactions in Crypto Assets can be very risky. Crypto Assets are, by their nature,
            highly experimental, risky, volatile and Transactions are generally irreversible. You
            should not make any transactional decision without first conducting your own research.
            You are solely and exclusively responsible for determining whether any transaction, or
            strategy, or any other product or service is appropriate or suitable for you based on
            your own objectives and personal and financial situation. You acknowledge and agree that
            you will access and use the Site and participate in Transactions at your own risk.
          </Text>
        </List.Description>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <List.Description>
          <Text>
            Understanding Crypto Assets and Transactions may require advanced technical knowledge.
            Crypto Assets are often described in exceedingly technical language that requires a
            comprehensive understanding of applied cryptography and computer science in order to
            appreciate the inherent risks of transacting Crypto Assets. Any reference to a type of
            Token on the Site does not indicate our approval or disapproval of the underlying
            technology regarding such type of Token, and should not be used as a substitute for your
            own understanding of the risks specific to each type of Token. We make no warranty as to
            the suitability of the Crypto Assets referenced on the Site and assume no fiduciary duty
            in our relations with you.
          </Text>
        </List.Description>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <List.Description>
          <Text>
            In entering into any Transaction, you represent that you have been, are and will be
            solely responsible for making your own independent appraisal and investigations into the
            risks of the Transaction and the underlying Crypto Assets. You represent that you have
            sufficient knowledge, market sophistication, professional advice and experience to make
            your own evaluation of the merits and risks of any Transaction or any underlying Token.
            You accept all consequences of participating in Transactions, including the risk that
            you may lose access to your Crypto Assets indefinitely. All Transaction decisions are
            made solely by you. Notwithstanding anything in this Agreement, we accept no
            responsibility whatsoever for and will in no circumstances be liable to you in
            connection with Transactions. Under no circumstances will the operation of all or any
            portion of the Site be deemed to create a relationship that includes the provision or
            tendering of investment advice.
          </Text>
        </List.Description>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <List.Description>
          <Text>
            The prices of Crypto Assets are extremely volatile. Fluctuations in the price of other
            digital assets could materially and adversely affect the Crypto Assets, which may also
            be subject to significant price volatility.
          </Text>
        </List.Description>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <List.Description>
          <Text>
            Our Service does not store, send, or receive Crypto Assets. Any transfer of Crypto
            Assets occurs within the supporting blockchain and not on this Service, as such
            transfers are generally not reversible or cancelable.
          </Text>
        </List.Description>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <List.Description>
          <Text>
            You use the Social Trading features at your own risk, and the Company, and our
            affiliates, employees, clients and agents, will not be liable to you for any losses that
            you may sustain as a result of your use of such features. You should not make any
            transactional decision without first conducting your own research. You are solely and
            exclusively responsible for determining whether any transaction, or strategy, or any
            other product or service is appropriate or suitable for you based on your own objectives
            and personal and financial situation.
          </Text>
        </List.Description>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <List.Description>
          <Text>
            All positions taken using a Social Trading feature shall be modified or closed
            automatically if and when modified or closed by the copied Trader, for whatsoever
            reason, without providing any further notice and without any action on your part. You
            should be able and prepared to bear the loss of the entire investment you made in such
            copied Trader or fund. You are fully responsible for any losses you may sustain as a
            result of your automatic execution of instructions generated as a result of the
            utilization of any of the Social Trading features. If you place additional trades or you
            modify or cancel an order generated by a Social Trading feature, you may achieve a
            materially different result than the Trader that you copied.
          </Text>
        </List.Description>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <List.Description>
          <Text>
            Neither the Company nor any Trader, portfolio or strategy guarantees the future
            performance of your Crypto Asset, any specific level of performance, the success of any
            transaction strategy or the success of your overall management of the Account. When
            reviewing the Information, portfolio, financial performance information, opinions of
            Traders, you should not assume that the user is unbiased, independent or qualified to
            provide financial information or opinions. Past performance and risk scores have many
            inherent limitations and are not indicative of future results. No representation or
            guarantee is being made that any Trader will or is likely to achieve gains or losses
            similar to the past performance or risk score shown. The actual percentage gains or
            losses experienced by investors will vary depending on many factors
          </Text>
        </List.Description>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <List.Description>
          <Text fontWeight={600} color="black">
            To the maximum extent permissible under applicable law, neither we nor any of our
            affiliates will be liable for (a) any loss arising from following your written or oral
            instructions, (b) any loss that you may suffer by reason of any decision made or other
            action taken by an account elected to be copied by you, including without limitation, a
            Company Trader account; or (c) any loss arising from any investment decision made or
            other action taken or omitted in good faith by any copied account, strategy or
            portfolio, including, without limitation, a Company Trader account. Notwithstanding the
            foregoing, nothing in these Terms will waive or limit any rights that you may have under
            any applicable laws which may not be waived or limited.
          </Text>
        </List.Description>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <List.Description>
          <Text>
            You are responsible for complying with applicable law. You agree that we are not
            responsible for determining whether or which laws may apply to your Transactions,
            including tax laws. You are advised to consult an attorney regarding of the legality of
            any activities on the Site. You are solely responsible for reporting and paying any
            taxes arising from your use of the Site and participation in any Transaction.
          </Text>
        </List.Description>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <List.Description>
          <Text>
            You are aware of and accept the risk of operational challenges. The Site may experience
            sophisticated cyber-attacks, unexpected surges in activity or other operational or
            technical difficulties that may cause interruptions to or delays on the Site. You agree
            to accept the risk of a Transaction failure resulting from unanticipated or heightened
            technical difficulties, including those resulting from sophisticated attacks, and you
            agree not to hold us accountable for any related losses. We will not bear any liability,
            whatsoever, for any damage or interruptions caused by any Viruses that may affect your
            computer or other equipment, or any phishing, spoofing or any other type of similar
            activity.
          </Text>
        </List.Description>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <List.Description>
          <Text>
            There are risks associated with using an Internet based currency, including but not
            limited to, the risk of hardware, software and Internet connections, the risk of
            malicious software introduction, and the risk that third parties may obtain unauthorized
            access to information stored within your wallet. You accept and acknowledge that the
            Company will not be responsible for any communication failures, disruptions, errors,
            distortions or delays you may experience when using the Site, however caused.
          </Text>
        </List.Description>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <List.Description>
          <Text>
            The regulatory regime governing blockchain technologies, cryptocurrencies, and Crypto
            Assets is uncertain. New regulations or policies may materially adversely affect the
            development of the Auction and/or Service and the utility of Crypto Assets. You are
            advised to consult with legal counsel regarding the Site’s contents in your
            jurisdiction.
          </Text>
        </List.Description>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <List.Description>
          <Text>
            You accept and confirm that a conflict of interest may arise when the interest of the
            Company and its affiliates competes or may appear to compete with your interests under
            this Agreement. Specifically, you hereby acknowledge and confirm that: (a) we may
            participate in certain transactions or auctions during a rebalancing period in an
            individual capacity ; (b) we may execute at the same time our own orders which may be
            opposite a user; (c) we may establish business, including without limitation, trading
            relationships with other issuers of financial instruments and we may have a financial
            interest in such instruments; (d) we may seek to act as a market maker and in this
            context there may be inherent conflicts of interest; and (e) we may compensate or share
            our revenues from your activity with our affiliates, partners or other similar parties
            performing marketing activities on our behalf. You hereby further acknowledge that we
            also may compensate Traders who you have elected to follow or copy. Set does not
            currently charge fees or compensate individuals in a manner that would cause such
            conflict of interest to arise, but we reserve the right to do so, and you hereby
            acknowledge and agree that you understand the risks attendant to this potential conflict
            of interest.
          </Text>
        </List.Description>
      </List.Item>
    </List>

    <Text tag="p" className={css(styles.contentPadding)}>
      The Service will rely on third-party platforms such as MetaMask and Fortmatic to perform the
      transactions for the Auction of Crypto Assets. If we are unable to maintain a good
      relationship with such platform providers; if the terms and conditions or pricing of such
      platform providers change; if we violate or cannot comply with the terms and conditions of
      such platforms; or if any of such platforms loses market share or falls out of favor or is
      unavailable for a prolonged period of time, access to and use of the Site will suffer. The
      terms and conditions of MetaMask and/or Fortmatic will govern your use of MetaMask and/or
      Fortmatic and may be subject to update from time to time.
    </Text>
    <Text tag="p" className={css(styles.contentPadding)}>
      The Company does not own or control the underlying software protocols which govern the
      operation of Crypto Assets available through the Site. In general, the underlying protocols
      are open source and anyone can use, copy, modify, and distribute them. The Company is not
      responsible for operation of the underlying protocols, and the Company makes no guarantee of
      their functionality, security, or availability. The underlying protocols are subject to sudden
      changes in operating rules (“Forks”), and such Forks may materially affect the value,
      function, or even the name of a Crypto Asset available on the Site. In the event of a Fork, we
      may temporarily suspend the Site’s operations (with or without advance notice to you) and we
      may (a) configure or reconfigure its systems or (b) decide not to support (or cease
      supporting) the Forked protocol entirely.
    </Text>

    <Header as="h4" className={css(styles.header)}>
      Privacy
    </Header>
    <Text tag="p" className={css(styles.contentPadding)}>
      Our privacy policy is a part of this Agreement. Please review our Privacy Policy, which also
      governs the Site and informs users of our data collection practices.
    </Text>

    <Header as="h4" className={css(styles.header)}>
      Warranties and Disclaimers
    </Header>
    <Text tag="p" color="black" className={css(styles.contentPadding)}>
      We have put in a great deal of effort to deliver you the Site and we hope you find it
      valuable, but there are certain things we can’t promise about them. YOU EXPRESSLY ACKNOWLEDGE
      AND AGREE THAT YOUR ACCESS TO AND USE OF THE SITE AND ANY SITE CONTENT IS AT YOUR SOLE RISK.
      OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS, NEITHER THE COMPANY NOR ITS AFFILIATES,
      SUPPLIERS OR DISTRIBUTORS (“SET LABS INC. PARTIES”) MAKE ANY WARRANTY, EITHER EXPRESS OR
      IMPLIED, ABOUT THE SITE OR SITE CONTENT. THE SITE AND SITE CONTENT ARE PROVIDED “AS IS.” THE
      COMPANY DOES NOT WARRANT THAT: (1) THE OPERATION OF THE SITE OR SITE CONTENT WILL BE
      UNINTERRUPTED, ERROR-FREE, OR VIRUS-FREE; (2) THE FUNCTIONS CONTAINED IN THE SITE OR SITE
      CONTENT WILL BE ACCURATE OR MEET YOUR REQUIREMENTS; OR (3) ANY DEFECTS IN THE SITE OR SITE
      CONTENT WILL BE CORRECTED. TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE ALSO DISCLAIM ANY
      WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. SOME
      STATES OR JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF IMPLIED WARRANTIES, SO SOME DISCLAIMERS
      IN THIS SECTION MAY NOT APPLY TO YOU.
    </Text>

    <Header as="h4" className={css(styles.header)}>
      Limitation of Liability
    </Header>
    <Text tag="p" color="black" className={css(styles.contentPadding)}>
      TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE COMPANY BE RESPONSIBLE TO YOU FOR
      ANY: (1) LOSS OF USE, DATA, BUSINESS OR PROFITS; (2) FINANCIAL LOSSES; OR (3) INDIRECT,
      SPECIAL CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES. THIS WILL BE WHETHER OR NOT THE COMPANY
      PARTIES HAVE BEEN WARNED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE
      CONTRARY IN THESE TERMS, NOTHING IN THESE TERMS EXCLUDES OR LIMITS LIABILITY FOR FRAUD,
      FRAUDULENT MISREPRESENTATION, OR FOR DEATH OR PERSONAL INJURY CAUSED BY GROSS NEGLIGENCE.
    </Text>

    <Header as="h4" className={css(styles.header)}>
      Indemnification
    </Header>
    <Text tag="p" className={css(styles.contentPadding)}>
      You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors and
      service providers, and its and their respective officers, directors, employees, contractors,
      agents, licensors, suppliers, successors and assigns from and against any claims, liabilities,
      damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys'
      fees) arising out of or relating to your violation of this Agreement or your use of the Site,
      including, but not limited to any use of the Site's content, services and products other than
      as expressly authorized in this Agreement or your use of any information obtained from the
      Site.
    </Text>

    <Header as="h4" className={css(styles.header)}>
      Governing Law and Venue
    </Header>
    <Text tag="p" className={css(styles.contentPadding)}>
      This Agreement and your access to and use of the Site will be governed by the laws of the
      State of California, U.S.A., without regard to conflict of law rules or principles (whether of
      the State of California or any other jurisdiction) that would cause the application of the
      laws of any other jurisdiction. Any dispute between the parties that is not subject to
      arbitration or cannot be heard in small claims court shall be resolved in the state or federal
      courts of the State of California and the United States, respectively, sitting in San
      Francisco County, California.
    </Text>

    <Header as="h4" className={css(styles.header)}>
      Designated Countries Privacy Rights
    </Header>
    <Text tag="p" className={css(styles.contentPadding)}>
      This Section only applies to users and customers of the Site that are located in the European
      Economic Area, United Kingdom and/or Switzerland (collectively, the “Designated Countries”) at
      the time of data collection. We may ask you to identify which country you are located in when
      you use some of the Site, or we may rely on your IP address to identify which country you are
      located in. Where we rely only on your IP address, we cannot apply the terms of this Section
      to any User or Customer that masks or otherwise obfuscates their location information so as
      not to appear located in the Designated Countries. If any terms in this Section conflict with
      other terms contained in this Agreement, the terms in this section shall apply to Users and
      Customers in the Designated Countries.
    </Text>
    <Text tag="p" className={css(styles.contentPadding)}>
      We are a data controller with regard to any personal information collected from Customers or
      Users of its Site. A “data controller” is an entity that determines the purposes for which and
      the manner in which any personal information is processed. Any third parties that act as our
      service providers are “data processors” that handle your personal information in accordance
      with our instructions.
    </Text>
    <Text tag="p" className={css(styles.contentPadding)}>
      You may object to our processing at any time and as permitted by applicable law if we process
      your personal information on the legal basis of consent, contract or legitimate interests. We
      can continue to process your personal information if it is necessary for the defense of legal
      claims, or for any other exceptions permitted by applicable law.
    </Text>
    <Text tag="p" className={css(styles.contentPadding)}>
      If we process your personal information based on a contract with you or based on your consent,
      or the processing is carried out by automated means, you may request to receive your personal
      information in a structured, commonly used and machine-readable format, and to have us
      transfer your personal information directly to another “controller”, where technically
      feasible, unless exercise of this right adversely affects the rights and freedoms of others.
    </Text>
    <Text tag="p" className={css(styles.contentPadding)}>
      If you believe we have infringed or violated your privacy rights, please contact us at
      <a href="mailto:hello@setprotocol.com">{' hello@setprotocol.com'}</a>
      so that we can work to resolve your concerns. You also have a right to lodge a complaint with
      a competent supervisory authority situated in a Member State of your habitual residence, place
      of work, or place of alleged infringement.
    </Text>

    <Header as="h4" className={css(styles.header)}>
      Changes to this Agreement
    </Header>
    <Text tag="p" className={css(styles.contentPadding)}>
      We reserve the right to change this Agreement at any time upon notice. We may give notice by
      posting the updated Agreement on the Site or by any other reasonable means. You can review the
      most current version of this Agreement at any time. This Agreement in effect at the time of
      your use of the Site apply. Updated versions of the Agreement are binding on you with respect
      to your use of the Site on or after the date indicated in the updated Agreement. If you do not
      agree to the updated Agreement, you must stop using the Site. Your continued use of the Site
      after the date of the updated Agreement will constitute your acceptance of the updated
      Agreement.
    </Text>

    <Header as="h4" className={css(styles.header)}>
      Entire Agreement
    </Header>
    <Text tag="p" className={css(styles.contentPadding)}>
      This Agreement and Privacy Policy constitute the sole and entire agreement between you and the
      Company with respect to the Site and supersede all prior and contemporaneous understandings,
      agreements, representations and warranties, both written and oral, with respect to the Site.
    </Text>
    <Text tag="p" className={css(styles.contentPadding)}>
      This Agreement does not, and will not be construed to, create any partnership, joint venture,
      employer-employee, agency or franchisor-franchisee relationship between you and the Company.
    </Text>
  </Fragment>
);

export default TermsOfServiceText;
