import Web3 from 'web3';
import BigNumber from './bigNumber';
import { COLORS, REBALANCING_STATUSES, TRANSACTION_STATUSES } from '../constants/index';
import { IListToken, IListTokenWithPosition } from '../typings/index';

const web3 = new Web3();

const isZero = (value: number) => value === 0;

export const getEthFromWei = (amount = '0') => {
  return web3.utils.fromWei(amount, 'ether').toString();
};

export const truncateEthAddress = (address: string) => {
  if (!address) {
    return '';
  }

  return address
    .slice(0, 6)
    .concat('...')
    .concat(address.slice(address.length - 4, address.length));
};

/**
 * truncateTokenAmount
 * Truncates token amount to 6 decimal places for DISPLAY PURPOSES ONLY. Uses parseFloat to remove trailing zeros.
 */
export const truncateTokenAmount = (tokenAmount: string | number = '0', roundTo = 6) => {
  // Parse float to determine if tokenAmount is a number
  const parsedTokenAmount = parseFloat(tokenAmount as string);
  const isParsedTokenAmountNaN = isNaN(parsedTokenAmount);
  const tokenAmountString = parsedTokenAmount.toFixed(roundTo);
  // If it is a number, use the parsedTokenAmount. If not, default to '0'.
  const amountBigNumber = new BigNumber(isParsedTokenAmountNaN ? '0' : tokenAmountString);
  return parseFloat(amountBigNumber.toFixed(roundTo, BigNumber.ROUND_DOWN));
};

export const truncateString = (string: string, maxLength: number) => {
  if (string.length > maxLength) {
    return string.slice(0, maxLength).concat('...');
  }
  return string;
};

export const formatToDecimals = (value = '0', decimals = '0') => {
  const decimalBN = new BigNumber(decimals).toNumber();
  const decimalDivider = new BigNumber(10).toPower(decimalBN);
  return new BigNumber(value).div(decimalDivider);
};

export const formatTokenUnit = (quantity = '0', decimals: string | number = '18', precision = 2) =>
  new BigNumber(quantity || 0)
    .div(10 ** new BigNumber(decimals).toNumber())
    .toFormat(isZero(new BigNumber(quantity).toNumber()) ? 0 : precision)
    .toString();

export const tokenToBaseUnits = (quantity: string, decimals: string | number = '18'): BigNumber => {
  const bigQuantity = new BigNumber(quantity || '0');
  const tokenBaseMultiple = new BigNumber(10).pow(Number(decimals) ?? 18);

  return bigQuantity.mul(tokenBaseMultiple);
};

export const tokenFromBaseUnits = (
  quantity: string,
  decimals: string | number = '18',
): BigNumber => {
  const bigQuantity = new BigNumber(quantity || '0');
  const tokenBaseMultiple = new BigNumber(10).pow(Number(decimals) ?? 18);

  return bigQuantity.div(tokenBaseMultiple);
};

export const calculateComponentsRequired = (
  quantity: string | number | BigNumber = '0',
  naturalUnit: string | number | BigNumber = 0,
  componentUnits: string | number | BigNumber = '0',
) =>
  new BigNumber(quantity || 0)
    .times(10 ** 18)
    .div(naturalUnit)
    .times(componentUnits)
    .toString();

export const getTransactionStatusFormat = (status: string) => {
  if (status === TRANSACTION_STATUSES.CONFIRMED) {
    return {
      text: 'Confirmed',
      color: COLORS.green,
    };
  } else if (status === TRANSACTION_STATUSES.PROCESSING) {
    return {
      text: 'Processing',
      color: COLORS.blue,
    };
  } else if (status === TRANSACTION_STATUSES.FAILED) {
    return {
      text: 'Failed',
      color: COLORS.red,
    };
  }
  // Default to processing
  return {
    text: 'Processing',
    color: COLORS.blue,
  };
};

export const chunkArray = (myArray: any[], chunkSize: number) => {
  const results = [];
  const arrayCopy = [...myArray];

  while (arrayCopy.length) {
    results.push(arrayCopy.splice(0, chunkSize));
  }

  return results;
};

export const getRebalancingStatusProperties = (status: string) => {
  if (status === REBALANCING_STATUSES.DEFAULT) {
    return {
      color: 'green',
      text: 'Normal',
    };
  } else if (status === REBALANCING_STATUSES.INITIAL_CONFIRMATION) {
    return {
      color: 'blue',
      text: 'Initial Crossover',
    };
  } else if (status === REBALANCING_STATUSES.PROPOSAL) {
    return {
      color: 'blue',
      text: 'Proposal',
    };
  } else if (status === REBALANCING_STATUSES.REBALANCE) {
    return {
      color: 'blue',
      text: 'Rebalancing',
    };
  } else if (status === REBALANCING_STATUSES.DRAWDOWN) {
    return {
      color: 'red',
      text: 'Drawdown',
    };
  }
  return {
    color: 'green',
    text: status,
  };
};

export const trimLessThanSign = (val: string) => {
  if (val && val[0] === '<') {
    return val.slice(1);
  }
  return val;
};

export const trimDollarSign = (val: string) => {
  if (val && val[0] === '$') {
    return val.slice(1);
  }

  // For negative numbers. e.g. -$4.50
  if (val && val[1] === '$') {
    return val[0].concat(val.slice(2));
  }

  return val;
};

export const trimPercentSign = (val: string) => {
  if (val && val.length && val[val.length - 1] === '%') {
    return val.slice(0, val.length - 1);
  }
  return val;
};

export const shortenedDollarAmount = (val: string) => {
  const dollarValue = new BigNumber(extractNumberFromDollarAmount(val));

  if (dollarValue.gte(1e9)) {
    return `$${dollarValue.div(1e9).toFixed(1)}b`;
  } else if (dollarValue.gte(1e6)) {
    return `$${dollarValue.div(1e6).toFixed(1)}m`;
  } else if (dollarValue.gte(1e3)) {
    return `$${dollarValue.div(1e3).toFixed(1)}k`;
  }
  return val;
};

export const extractNumberFromDollarAmount = (val: string) => {
  if (val) {
    return trimDollarSign(val.replace(/,/g, ''));
  }
  return '0';
};

export const titleCase = (str: string) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

export const trimDecimals = (val: string, _decimalPlaces = 2) => {
  if (val && val.split('.').length === 2) {
    const splitVal = val.slice().split('.');
    splitVal[1] = splitVal[1].slice(0, _decimalPlaces);
    return parseFloat(splitVal.join('.')).toFixed(_decimalPlaces).toString();
  }
  return val;
};

const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export const convertNumberToDollars = (quantity: number) => {
  if (typeof quantity !== 'number' && !quantity) return;

  return formatter.format(quantity);
};

/**
 * Truncate a string stopping at spaces or "seperator" values.
 * Prevents truncating a string in the middle of a word.
 * @param input String to truncate
 * @param maxLen Maximum length of words
 * @param separator seperator to truncate by
 */
export const truncateStringByWords = (input = '', maxLength = 200, separator = ' ') => {
  if (input.length <= maxLength) return input;
  return input.substr(0, input.lastIndexOf(separator, maxLength));
};

export const toFixed2 = (convertNumber: BigNumber) =>
  new BigNumber((Math.round((convertNumber?.toNumber() || 0) * 100) / 100).toFixed(2));

export const toFixed2Locale = (convertNumber: BigNumber) =>
  Number(toFixed2(convertNumber)).toLocaleString('en', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const convertTextToJsonString = (str: string) => {
  return str
    .replace(/[\\]/g, '\\\\')
    .replace(/[\/]/g, '\\/')
    .replace(/[\b]/g, '\\b')
    .replace(/[\f]/g, '\\f')
    .replace(/[\n]/g, '\\n')
    .replace(/[\r]/g, '\\r')
    .replace(/[\t]/g, '\\t')
    .replace(/\\n\s+\"/g, '"')
    .replace(/\\n\s+}/g, '}')
    .replace(/}\\n$/g, '}')
    .replace(/\\n}$/g, '}');
};

export const getListTokenImage = (token: IListTokenWithPosition | IListToken) => {
  return token?.logoURI || 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-token-icon.svg';
};

export const sortListTokensInputFirst = (input: string, data: IListToken[]) => {
  let first = [];
  let others = [];

  if (!input?.length) {
    return data;
  }

  for (let i = 0; i < data.length; i++) {
    if (
      data[i].name.toLowerCase().startsWith(input.toLowerCase()) ||
      data[i].symbol.toLowerCase().startsWith(input.toLowerCase())
    ) {
      first.push(data[i]);
    } else {
      others.push(data[i]);
    }
  }
  first.sort();
  others.sort();

  return first.concat(others);
};

/**
 * Formats allocation percentage for display.
 */
export const formatAllocationPercentage = (allocation: number) => {
  if (allocation === 0 || allocation === 100) return `${allocation}%`;

  return `${allocation.toFixed(2)}%`;
};

export const getPaymentCurrencyTokenSymbol = (
  token: { id: string; symbol: string },
  isETHCurrencyChain: boolean,
): string => {
  if (!isETHCurrencyChain && token.id === 'weth') {
    return 'WETH';
  } else if (isETHCurrencyChain && token.id === 'weth') {
    return 'ETH';
  } else {
    return token.symbol;
  }
};
