import React, { PureComponent } from 'react';
import { Table } from 'semantic-ui-react';
import TokenTableRow from './TokenTableRow';

import { Text, Button } from '..';
import { COLORS, DATA_SELECTS } from '../../constants/index';
import { IToken } from '../../typings/index';

const {
  TOKEN_TABLE_BODY,
  TOKEN_TABLE_TABLET_PRICE_PERCENTAGE_TOGGLE,
  TOKEN_TABLE_TABLET_SHOW_PERCENTAGE_BTN,
  TOKEN_TABLE_TABLET_SHOW_PRICE_BTN,
  TOKEN_TABLE_HEADER_ROW,
  TOKEN_TABLE_INNER_BODY,
  TOKEN_TABLE_ZERO_STATE,
} = DATA_SELECTS;

const styles = {
  container: {
    backgroundColor: COLORS.white,
    border: 'none',
  },
  toggleContainer: {
    textAlign: 'center' as 'center',
  },
  toggleButtonActive: {
    container: {
      width: '125px',
      borderRadius: '0',
    },
    text: {
      color: COLORS.white,
    },
  },
  toggleButtonInactive: {
    container: {
      width: '125px',
      borderRadius: '0',
      backgroundColor: COLORS.lightGray,
      ':hover': {
        backgroundColor: COLORS.lightGrayDarkened,
      },
    },
    text: {
      color: COLORS.darkGray,
    },
  },
};

export interface ITokenTableProps {
  readonly isMobile?: boolean;
  readonly isTablet?: boolean;
  readonly components?: IToken[];
}

export interface ITokenTableState {
  readonly showPrice: boolean;
}

class TokenTable extends PureComponent<ITokenTableProps, ITokenTableState> {
  constructor(props: ITokenTableProps) {
    super(props);
    this.state = {
      showPrice: false,
    };
  }

  static get defaultProps() {
    return {
      isMobile: false,
      isTablet: false,
      components: [{}],
    };
  }

  togglePriceView = () => {
    this.setState((prevState: ITokenTableState) => ({ showPrice: !prevState.showPrice }));
  };

  render() {
    const { components, isMobile, isTablet } = this.props;
    const { showPrice } = this.state;
    return (
      <div data-select={TOKEN_TABLE_BODY}>
        {isTablet && (
          <div
            style={styles.toggleContainer}
            data-select={TOKEN_TABLE_TABLET_PRICE_PERCENTAGE_TOGGLE}
          >
            <Button
              style={showPrice ? styles.toggleButtonInactive : styles.toggleButtonActive}
              onClick={this.togglePriceView}
              text="Percentage"
              data-select={TOKEN_TABLE_TABLET_SHOW_PERCENTAGE_BTN}
            />
            <Button
              style={showPrice ? styles.toggleButtonActive : styles.toggleButtonInactive}
              onClick={this.togglePriceView}
              text="Price"
              data-select={TOKEN_TABLE_TABLET_SHOW_PRICE_BTN}
            />
          </div>
        )}
        <Table basic unstackable={isTablet} compact={isTablet} style={styles.container}>
          {!isTablet && (
            <Table.Header data-select={TOKEN_TABLE_HEADER_ROW}>
              <Table.Row>
                <Table.HeaderCell>
                  <Text type="small">Token</Text>
                </Table.HeaderCell>
                <Table.HeaderCell />
                <Table.HeaderCell>
                  <Text type="small">Percent Allocation</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text type="small">Token Price</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text type="small">Quantity</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text type="small">Total Price</Text>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Text type="small">24H Price Change</Text>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          )}
          <Table.Body data-select={TOKEN_TABLE_INNER_BODY}>
            {components.map((token: IToken) => {
              const tokenInfo = token.info ? token.info : token;
              const { symbol } = tokenInfo;
              return (
                <TokenTableRow
                  key={`token-table-row-${symbol}`}
                  token={token}
                  coinIcon={token.image}
                  isMobile={isMobile}
                  isTablet={isTablet}
                  showPrice={showPrice}
                />
              );
            })}
          </Table.Body>
          <Table.Footer>
            {components.length === 0 && (
              <Table.Row data-select={TOKEN_TABLE_ZERO_STATE}>
                <Table.Cell colSpan={5} textAlign="center">
                  - No tokens added yet -
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Footer>
        </Table>
      </div>
    );
  }
}

export default TokenTable;
