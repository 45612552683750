import React, { PureComponent } from 'react';
import { Container, Grid, Header, Table } from 'semantic-ui-react';
import { css } from 'aphrodite';
import { Link } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';

import styles from './CustomSetExploreTableStyles';
import CustomSetExploreTableShimmer from './CustomSetExploreTableShimmer';
import { IWindowDimensions, IHistory, IListToken, ISetDetailsHydrated } from '../../typings/index';
import { Button } from '../../components/index';
import { COLORS, SUPPORTED_NETWORKS } from '../../constants/index';
import SetSearchBar from '../../containers/SetSearchBar';
import _ from 'lodash';
import CustomSetExploreTableRow from './CustomSetExploreTableRow';
import { VAssetDisplayInfo } from 'set.js/dist/types/src/types';
import CustomSetExploreTableNetworkSwitcher from './CustomSetExploreTableNetworkSwitcher';

interface ICustomSetExploreTableProps {
  hideHeader?: boolean;
  isPolygon: boolean;
  isFetchingTokenList?: boolean;
  customV2SetPathPrefix?: string;
  exploreHeader?: string;
  account?: string;
  history?: IHistory;
  isFetchingDeployedSetDetails?: boolean;
  isShowingAdvancedData?: boolean;
  isTruncatedList: boolean;
  windowDimension: IWindowDimensions;
  setAddresses?: string[];
  tokenSetsTokenListByAddress?: { [address: string]: IListToken };
  setDetails?: { [address: string]: ISetDetailsHydrated };
  coingeckoTokenList: { [tokenAddress: string]: IListToken };
  hideSearchBar?: boolean;
  vAssetDisplayInfo?: VAssetDisplayInfo[];
  coingeckoTokenListBySymbol?: { [symbol: string]: IListToken };
  currentChain?: string;
  handleSwitchChain: (...args: any[]) => any;
  fetchDeployedSetsWithLogos: (...args: any[]) => any;
  fetchAllPerpLeverageData: (...args: any[]) => any;
  onFetchEnabledModules: (...args: any[]) => any;
}

class CustomSetExploreTable extends PureComponent<ICustomSetExploreTableProps & WithTranslation> {
  constructor(props: any) {
    super(props);
  }

  static get defaultProps() {
    return {
      setAddresses: [] as string[],
    };
  }

  componentDidMount = async () => {
    const { setAddresses } = this.props;

    await this.props.fetchDeployedSetsWithLogos(setAddresses);
  };

  componentDidUpdate = async (prevProps: ICustomSetExploreTableProps) => {
    if (!_.isEqual(prevProps.setAddresses, this.props.setAddresses)) {
      await this.props.fetchDeployedSetsWithLogos(this.props.setAddresses);
    }
  };

  public onCreateSetCTAClick = () => {
    const { history } = this.props;

    history.push('/create');
  };

  public renderExploreMoreSetsFooter = () => {
    const { t } = this.props;

    return (
      <Link className={css(styles.CustomSetExploreTable_footer)} to="/explore">
        <div className={css(styles.CustomSetExploreTable_footerTextWrapper)}>
          <span className={css(styles.CustomSetExploreTable_footerText)}>
            {t('set-table.explore-more-sets')}
          </span>
          <img
            className={css(styles.CustomSetExploreTable_rightArrow)}
            src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/blue-arrow-right-thick.svg"
            alt="Right Arrow"
          />
        </div>
      </Link>
    );
  };

  public renderError = () => {
    const { t } = this.props;
    return (
      <div className={css(styles.CustomSetExploreTable_errorContainer)}>
        <Header as="h2">🙈</Header>
        <Header as="h2">{t('set-table.load-sets-error')}</Header>
        <p className={css(styles.CustomSetExploreTable_errorDescription)}>
          {t('set-table.load-sets-error-description')}
        </p>
        <Button onClick={() => window.location.reload()} text={t('buttons.retry')} />
      </div>
    );
  };

  public renderNoFilteredSets = () => {
    const { t } = this.props;

    return (
      <>
        <div className={css(styles.CustomSetExploreTable_errorContainer)}>
          <Header as="h2">🧐</Header>
          <Header as="h2">{t('set-table.no-filtered-sets')}</Header>
          <p className={css(styles.CustomSetExploreTable_errorDescription)}>
            {t('set-table.no-filtered-sets-description')}
          </p>
        </div>
      </>
    );
  };

  render() {
    const {
      hideHeader,
      exploreHeader,
      customV2SetPathPrefix,
      isPolygon,
      isFetchingDeployedSetDetails,
      history,
      isShowingAdvancedData,
      isTruncatedList,
      setAddresses,
      setDetails,
      coingeckoTokenList,
      windowDimension,
      tokenSetsTokenListByAddress,
      hideSearchBar,
      vAssetDisplayInfo,
      coingeckoTokenListBySymbol,
      currentChain,
      isFetchingTokenList,
      handleSwitchChain,
      fetchAllPerpLeverageData,
      onFetchEnabledModules,
      t,
    } = this.props;

    const { isTabletLandscape } = windowDimension;

    if (isFetchingDeployedSetDetails || isFetchingTokenList) {
      return (
        <Container>
          <CustomSetExploreTableShimmer
            exploreHeader={''}
            isTabletLandscape={isTabletLandscape}
            isTruncatedList={isTruncatedList}
            isShowingAdvancedData={isShowingAdvancedData}
          />
        </Container>
      );
    }

    if (isTabletLandscape) {
      return (
        <Container>
          <div className={css(styles.CustomSetExploreTable_filters)}>
            {!hideHeader && (
              <Header as="h3" className={css(styles.CustomSetExploreTable_headerText)}>
                {exploreHeader}
              </Header>
            )}
            {!hideSearchBar && (
              <div className={css(styles.CustomSetExploreTable_searchBarContainer)}>
                <SetSearchBar maxWidth="350px" history={history} />
              </div>
            )}
            <CustomSetExploreTableNetworkSwitcher
              currentChain={currentChain}
              supportedNetworks={SUPPORTED_NETWORKS}
              handleSwitchChain={handleSwitchChain}
            />
          </div>
          {setAddresses?.length > 0 ? (
            <div
              style={{
                ...(isPolygon && {
                  borderTop: '5px solid',
                  borderImageSlice: '1 0 0',
                  borderImageSource: COLORS.polygonGradient,
                }),
              }}
            >
              <Grid.Row className={css(styles.CustomSetExploreTable_header)}>
                <div className={css(styles.CustomSetExploreTable_mobileHeaderNameCell)}>
                  {t('sets.name')}
                </div>
              </Grid.Row>
              {setAddresses.map((address: string, i: number) => {
                return (
                  setDetails[address] && (
                    <CustomSetExploreTableRow
                      key={`${address}-${i}-mobile`}
                      customV2SetPathPrefix={customV2SetPathPrefix}
                      address={address}
                      history={history}
                      isShowingAdvancedData={isShowingAdvancedData}
                      windowDimension={windowDimension}
                      setDetails={setDetails}
                      tokenList={coingeckoTokenList}
                      tokenSetsTokenListByAddress={tokenSetsTokenListByAddress}
                      vAssetDisplayInfo={vAssetDisplayInfo}
                      coingeckoTokenListBySymbol={coingeckoTokenListBySymbol}
                      fetchAllPerpLeverageData={fetchAllPerpLeverageData}
                      onFetchEnabledModules={onFetchEnabledModules}
                    />
                  )
                );
              })}
            </div>
          ) : (
            this.renderNoFilteredSets()
          )}
        </Container>
      );
    }

    return (
      <Container>
        <div
          className={css(styles.CustomSetExploreTable_settingsBar)}
          style={{
            alignItems: 'center',
          }}
        >
          <div className={css(styles.CustomSetExploreTable_filters)}>
            {!hideHeader && (
              <Header as="h3" className={css(styles.CustomSetExploreTable_headerText)}>
                {exploreHeader}
              </Header>
            )}
            {!hideSearchBar && (
              <div className={css(styles.CustomSetExploreTable_searchBarContainer)}>
                <SetSearchBar maxWidth="485px" history={history} />
              </div>
            )}
          </div>
          <CustomSetExploreTableNetworkSwitcher
            currentChain={currentChain}
            supportedNetworks={SUPPORTED_NETWORKS}
            handleSwitchChain={handleSwitchChain}
          />
        </div>
        {setAddresses?.length > 0 ? (
          <div
            className={css(styles.CustomSetExploreTable_container)}
            style={{
              ...(isPolygon && {
                borderTop: '5px solid',
                borderImageSlice: '1 0 0',
                borderImageSource: COLORS.polygonGradient,
              }),
            }}
          >
            <Table
              className={css(styles.CustomSetExploreTable_innerContainer)}
              style={{
                ...(isPolygon && {
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                }),
              }}
            >
              {/* Table Header */}

              <Table.Header className={css(styles.CustomSetExploreTable_header)}>
                <Table.Row>
                  <Table.HeaderCell
                    className={css(styles.CustomSetExploreTable_headerCell)}
                    style={{ width: '25%' }}
                  >
                    {t('sets.name')}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className={css(styles.CustomSetExploreTable_headerCell)}
                    style={{ width: '25%' }}
                  >
                    {t('sets.symbol')}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className={css(styles.CustomSetExploreTable_headerCell)}
                    style={{ width: '25%' }}
                  >
                    Total Supply
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className={css(styles.CustomSetExploreTable_headerCell)}
                    style={{ width: '25%' }}
                  >
                    Manager
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              {/* Table Body Content */}

              {setAddresses.map((address: string, i: number) => {
                return (
                  setDetails[address] && (
                    <CustomSetExploreTableRow
                      key={`${address}-${i}-desktop`}
                      customV2SetPathPrefix={customV2SetPathPrefix}
                      address={address}
                      history={history}
                      isShowingAdvancedData={isShowingAdvancedData}
                      windowDimension={windowDimension}
                      setDetails={setDetails}
                      tokenList={coingeckoTokenList}
                      tokenSetsTokenListByAddress={tokenSetsTokenListByAddress}
                      vAssetDisplayInfo={vAssetDisplayInfo}
                      coingeckoTokenListBySymbol={coingeckoTokenListBySymbol}
                      fetchAllPerpLeverageData={fetchAllPerpLeverageData}
                      onFetchEnabledModules={onFetchEnabledModules}
                    />
                  )
                );
              })}
            </Table>
          </div>
        ) : (
          this.renderNoFilteredSets()
        )}
      </Container>
    );
  }
}

export default withTranslation('components')(CustomSetExploreTable);
