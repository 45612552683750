import { StyleSheet, css } from 'aphrodite';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchBalances as fetchBalancesAction } from '../../actions/balanceActions';
import {
  closeOnboardingModal as closeOnboardingModalAction,
  closeLoginModal as closeLoginModalAction,
  logOut as logOutAction,
  acceptTermsOfService as acceptTermsOfServiceAction,
  setLedgerPath as setLedgerPathAction,
  closeChainSwitchModal,
  handleSwitchChain,
} from '../../actions/web3Actions';
import { closeEditSetDetailsModal as closeEditSetDetailsModalAction } from '../../actions/setDetailsActions';
import { CloseIcon, OnboardingModal, LoginOptionModal } from '../../components/index';
import { COLORS } from '../../constants/index';
import { CustomSetDetailsResponse, IWindowDimensions } from '../../typings/index';
import TermsOfServiceModal from '../../components/TermsOfServiceModal';
import SelectLanguageModal from '../../components/SelectLanguageModal';
import EditSetDetailsModal from '../../components/EditSetDetailsModal';
import { closeSelectLanguageModal as closeSelectLanguageModalAction } from '../../actions/selectLanguageActions';
import {
  accountSelector,
  isChainSwitchModalOpenSelector,
  isFetchingCustomSetDetailsSelector,
  isInvalidCustomSetDetailsSelector,
  providerTypeSelector,
} from '../../selectors/baseSelectors';
import { currentCustomSetDetailsSelector } from '../../selectors/setDetailsSelectors';
import { isETHL1Selector, currentChainOrDefaultSelector } from '../../selectors/web3Selectors';
import ChainSwitchModal from '../../components/ChainSwitchModal';
import { urlIntendedChain } from '../../utils/web3Utils';

const styles = StyleSheet.create({
  Modals_toastProgressBar: {
    background: COLORS.blue,
  },
  Modals_toastBody: {
    fontFamily: 'Graphik, Arial, sans-serif',
    fontSize: '16px',
    color: COLORS.darkBlue,
    marginRight: '10px',
  },
  Modals_innerToast: {
    padding: '15px',
    paddingBottom: '20px',
    borderRadius: '4px',
  },
  Modals_toast: {
    zIndex: 1000000,
  },
  Modals_toastCloseButton: {
    position: 'initial',
  },
});

interface IToastCloseIconProps {
  closeToast?: (...args: any[]) => any;
}

const ToastCloseIcon = ({ closeToast }: IToastCloseIconProps) => (
  <CloseIcon customStyles={styles.Modals_toastCloseButton} onClick={closeToast} />
);

export interface IModalsProps {
  currentChain: string;
  isETHL1: boolean;
  isChainSwitchModalOpen: boolean;
  isOnboardingModalOpen: boolean;
  isLoginModalOpen: boolean;
  isTermsOfServiceConfirmed: boolean;
  account: string;
  providerType: string;
  ethBalance: string;
  path: string;
  currentlyViewingSetAddress: string;
  isSelectLanguageModalOpen: boolean;
  isEditSetDetailsModalOpen: boolean;
  windowDimension: IWindowDimensions;
  isFetchingCustomSetDetails: boolean;
  isInvalidCustomSetDetails: boolean;
  customSetDetails: CustomSetDetailsResponse;
  closeOnboardingModal: (...args: any[]) => any;
  closeLoginModal: (...args: any[]) => any;
  fetchBalances: (...args: any[]) => any;
  logOut: (...args: any[]) => any;
  acceptTermsOfService: (...args: any[]) => any;
  closeSelectLanguageModal: (...args: any[]) => any;
  closeEditSetDetailsModal: (...args: any[]) => any;
  setLedgerPath: (...args: any[]) => any;
  closeChainSwitchModal: (...args: any[]) => any;
  handleSwitchChain: (...args: any[]) => any;
}

interface IModalState {
  isEmailSignupModalClosed: boolean;
  hasFetchedEmail: boolean;
}

class Modals extends Component<IModalsProps, IModalState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isEmailSignupModalClosed: false,
      hasFetchedEmail: false,
    };
  }

  isOnValidTermsOfServicePath = () => {
    return (
      this.props.path === '/terms' || this.props.path === '/privacy' || this.props.path !== '/start'
    );
  };

  render() {
    const {
      currentChain,
      isETHL1,
      account,
      providerType,
      closeOnboardingModal,
      closeLoginModal,
      ethBalance,
      isLoginModalOpen,
      isOnboardingModalOpen,
      fetchBalances,
      windowDimension: { isTabletLandscape, isMobile },
      logOut,
      isTermsOfServiceConfirmed,
      acceptTermsOfService,
      isSelectLanguageModalOpen,
      closeSelectLanguageModal,
      isEditSetDetailsModalOpen,
      closeEditSetDetailsModal,
      setLedgerPath,
      currentlyViewingSetAddress,
      isFetchingCustomSetDetails,
      isInvalidCustomSetDetails,
      isChainSwitchModalOpen,
      customSetDetails,
      closeChainSwitchModal,
      handleSwitchChain,
    } = this.props;

    const { hasFetchedEmail } = this.state;

    const isTermsOfServiceModalOpen =
      !!account &&
      hasFetchedEmail &&
      !isTermsOfServiceConfirmed &&
      this.isOnValidTermsOfServicePath();

    const intendedChain = urlIntendedChain();

    return (
      <Fragment>
        <OnboardingModal
          isModalOpen={isOnboardingModalOpen}
          account={account}
          ethBalance={ethBalance}
          isTabletLandscape={isTabletLandscape}
          onClose={closeOnboardingModal}
          fetchBalances={fetchBalances}
        />
        <LoginOptionModal
          setLedgerPath={setLedgerPath}
          currentChain={currentChain}
          isETHL1={isETHL1}
          isModalOpen={isLoginModalOpen}
          isMobile={isMobile}
          isTabletLandscape={isTabletLandscape}
          onClose={closeLoginModal}
        />
        <ToastContainer
          className={css(styles.Modals_toast)}
          closeButton={<ToastCloseIcon />}
          toastClassName={css(styles.Modals_innerToast)}
          bodyClassName={css(styles.Modals_toastBody)}
          progressClassName={css(styles.Modals_toastProgressBar)}
          transition={Slide}
          position="bottom-left"
        />
        <ChainSwitchModal
          account={account}
          location={location}
          isModalOpen={isChainSwitchModalOpen}
          onSubmit={async () => {
            await handleSwitchChain(intendedChain, window.location.href);
          }}
          onClose={() => {
            closeChainSwitchModal();
          }}
          providerType={providerType}
          isMobile={isMobile}
          isTabletLandscape={isTabletLandscape}
        />
        <TermsOfServiceModal
          account={account}
          isModalOpen={isTermsOfServiceModalOpen}
          onLogout={logOut}
          onAccept={acceptTermsOfService}
        />
        <SelectLanguageModal
          isModalOpen={isSelectLanguageModalOpen}
          onCloseModal={closeSelectLanguageModal}
        />
        <EditSetDetailsModal
          account={account}
          setAddress={currentlyViewingSetAddress}
          isFetchingCustomSetDetails={isFetchingCustomSetDetails}
          isInvalidCustomSetDetails={isInvalidCustomSetDetails}
          customSetDetails={customSetDetails}
          isModalOpen={isEditSetDetailsModalOpen}
          onCloseModal={closeEditSetDetailsModal}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  const isFetchingCustomSetDetails = isFetchingCustomSetDetailsSelector(state);
  const isInvalidCustomSetDetails = isInvalidCustomSetDetailsSelector(state);
  const customSetDetails = currentCustomSetDetailsSelector(state);
  const isChainSwitchModalOpen = isChainSwitchModalOpenSelector(state);
  const providerType = providerTypeSelector(state);
  const account = accountSelector(state);
  const currentChain = currentChainOrDefaultSelector(state);
  const isETHL1 = isETHL1Selector(state);

  return {
    providerType,
    currentChain,
    isETHL1,
    isChainSwitchModalOpen,
    account,
    isTermsOfServiceConfirmed: state.web3.isTermsOfServiceConfirmed,
    ethBalance: state.balance.apiBalances.ethBalance,
    isOnboardingModalOpen: state.web3.isOnboardingModalOpen,
    isLoginModalOpen: state.web3.isLoginModalOpen,
    windowDimension: state.windowDimension,
    isSelectLanguageModalOpen: state.selectLanguage.isSelectLanguageModalOpen,
    isEditSetDetailsModalOpen: state.setDetails.isEditDetailsModalOpen,
    currentlyViewingSetAddress: state.setDetails.currentlyViewingSetAddress,
    isFetchingCustomSetDetails,
    isInvalidCustomSetDetails,
    customSetDetails,
  };
};

const mapDispatchToProps = {
  closeOnboardingModal: closeOnboardingModalAction,
  closeLoginModal: closeLoginModalAction,
  closeEditSetDetailsModal: closeEditSetDetailsModalAction,
  fetchBalances: fetchBalancesAction,
  logOut: logOutAction,
  acceptTermsOfService: acceptTermsOfServiceAction,
  closeSelectLanguageModal: closeSelectLanguageModalAction,
  setLedgerPath: setLedgerPathAction,
  closeChainSwitchModal,
  handleSwitchChain,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)((Modals as any) as React.SFC<IModalsProps>);
