import React, { useState } from 'react';
import info from '../../img/create/info.svg';
import { StyleSheet, css } from 'aphrodite';
import COLORS from '../../constants/colors';

const styles = StyleSheet.create({
  Img: {
    width: '15px',
    cursor: 'pointer',
  },
  TextBox: {
    position: 'absolute',
    width: '255px',
    height: '58px',
    background: COLORS.white,
    border: `1px solid ${COLORS.lightGray6}`,
    boxSizing: 'border-box',
    boxShadow: `0px 6px 20px ${COLORS.grayShadow}`,
    borderRadius: '4px',
    padding: '5px',
  },
  TriangleLeft: {
    width: '0',
    height: '0',
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderRight: `10px solid ${COLORS.lightGray6}`,
    zIndex: 200,
  },
  TriangleRight: {
    width: '0',
    height: '0',
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderLeft: `10px solid ${COLORS.lightGray6}`,
    zIndex: 200,
  },
  InnerTriangleLeft: {
    position: 'relative',
    top: '-16px',
    left: '1px',
    width: 0,
    height: 0,
    borderTop: '16px solid transparent',
    borderBottom: '16px solid transparent',
    borderRight: `16px solid ${COLORS.white}`,
    zIndex: 300,
  },
  InnerTriangleRight: {
    position: 'relative',
    top: '-18px',
    left: '-19px',
    width: 0,
    height: 0,
    borderTop: '18px solid transparent',
    borderBottom: '18px solid transparent',
    borderLeft: `18px solid ${COLORS.white}`,
    zIndex: 300,
  },
  TriangleWrapperLeft: {
    marginLeft: '15px',
    marginTop: '-6px',
  },
  TriangleWrapperRight: {
    marginLeft: '-25px',
    marginTop: '-6px',
  },
  ContentWrapper: {
    marginTop: '-23px',
    marginLeft: '10px',
  },
  TooltipWrapper: {
    display: 'inline-block',
    position: 'relative',
  },
  TooltipTip: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '6px',
    paddingLeft: '10px',
    paddingRight: '10px',
    background: COLORS.white,
    fontSize: '14px',
    lineHeight: 1,
    zIndex: 100,
    whiteSpace: 'nowrap',
    border: `1px solid ${COLORS.lightGray6}`,
    boxSizing: 'border-box',
    boxShadow: `0px 3px 6px ${COLORS.grayShadow}`,
    borderRadius: '4px',
  },
  Right: {
    left: 'calc(100%)',
    top: '17%',
    transform: 'translateX(0) translateY(-45%)',
  },
  Left: {
    left: 'auto',
    right: 'calc(100% + 15px)',
    top: '50%',
    transform: 'translateX(0) translateY(-50%)',
  },
});

interface ToolTipProps {
  delay?: number;
  children: JSX.Element | JSX.Element[];
  width?: string;
  direction?: 'LEFT' | 'RIGHT';
}

export default function ToolTip({ delay, direction, width, children }: ToolTipProps) {
  let timeout: NodeJS.Timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className={css(styles.TooltipWrapper)}
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      <img src={info} className={css(styles.Img)} />
      {active && (
        <div className={css(styles.ContentWrapper)}>
          <div
            style={{ width: width ? width : '265px' }}
            className={css(styles.TooltipTip, direction === 'LEFT' ? styles.Left : styles.Right)}
          >
            {children}
          </div>
          <div
            className={css(
              direction === 'LEFT' ? styles.TriangleWrapperRight : styles.TriangleWrapperLeft,
            )}
          >
            <div className={css(direction === 'LEFT' ? styles.TriangleRight : styles.TriangleLeft)}>
              <div
                className={css(
                  direction === 'LEFT' ? styles.InnerTriangleRight : styles.InnerTriangleLeft,
                )}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
