import React, { FunctionComponent } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { WINDOW_DIMENSIONS, COLORS } from '../../constants/index';
import { Select } from 'semantic-ui-react';
import _ from 'lodash';
import NetworkBadge from '../../components/NetworkBadge';

const { TABLET_LANDSCAPE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

type ICustomSetExploreTableNetworkSwitcherProps = {
  currentChain: string;
  supportedNetworks: string[];
  handleSwitchChain: (...args: any[]) => any;
  width?: string;
  redirectTo?: string;
};

const styles = StyleSheet.create({
  CustomSetExploreTableNetworkSwitcher_selectDropdown: {
    fontWeight: 400,
    fontSize: '12px',
    zIndex: 10,
    height: '36px',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      width: '100%',
    },
  },
  CustomSetExploreTableNetworkSwitcher_selectDropdownOptions: {
    fontWeight: 400,
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    color: COLORS.darkBlue,
  },
  CustomSetExploreTableNetworkSwitcher_networkName: {
    marginLeft: '6px',
  },
  CustomSetExploreTableNetworkSwitcher_createASetButton: {
    border: `1px solid ${COLORS.darkBlue}`,
    backgroundColor: COLORS.white,
    borderRadius: '4px',
    color: COLORS.darkBlue,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 500,
    padding: '5px 10px',
    transition: '0.2s',
    ':hover': {
      backgroundColor: COLORS.darkBlue,
      color: COLORS.white,
      boxShadow: '0 4px 6px 0 rgba(35, 1, 221, 0.05)',
      transform: 'scale(1.05)',
    },
  },
});

const CustomSetExploreTableNetworkSwitcher: FunctionComponent<ICustomSetExploreTableNetworkSwitcherProps> = ({
  currentChain,
  supportedNetworks,
  handleSwitchChain,
  width = '118px',
  redirectTo,
}: ICustomSetExploreTableNetworkSwitcherProps) => {
  const networkOptions = supportedNetworks.map(network => {
    return {
      key: network,
      value: network,
      text: (
        <span className={css(styles.CustomSetExploreTableNetworkSwitcher_selectDropdownOptions)}>
          <NetworkBadge network={network} />
          <div className={css(styles.CustomSetExploreTableNetworkSwitcher_networkName)}>
            {_.capitalize(network)}
          </div>
        </span>
      ),
      className: css(styles.CustomSetExploreTableNetworkSwitcher_selectDropdownOptions),
    };
  });

  return (
    <Select
      className={css(styles.CustomSetExploreTableNetworkSwitcher_selectDropdown)}
      style={{
        width,
      }}
      options={networkOptions}
      value={currentChain}
      onChange={async (_, data) => {
        await handleSwitchChain(data.value, redirectTo);
      }}
    />
  );
};

export default CustomSetExploreTableNetworkSwitcher;
