import { css, StyleSheet } from 'aphrodite';
import React, { PureComponent } from 'react';

import closeIcon from '../../img/icons/x-close.svg';

const styles = StyleSheet.create({
  CloseIcon_container: {
    height: '40px',
    width: '40px',
    cursor: 'pointer',
    position: 'absolute',
    right: '12px',
    top: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  CloseIcon_icon: {
    height: '24px',
    width: '24px',
    zIndex: 2000,
  },
});

interface ICloseIconProps {
  customStyles?: any;
  onClick: (...args: any[]) => any;
}

class CloseIcon extends PureComponent<ICloseIconProps> {
  render() {
    const { customStyles, onClick } = this.props;

    return (
      <div className={css(styles.CloseIcon_container)} onClick={onClick}>
        <img
          alt="Close icon"
          className={css(styles.CloseIcon_icon, customStyles && customStyles)}
          src={closeIcon}
        />
      </div>
    );
  }
}

export default CloseIcon;
