import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import { web3Transform } from './utils/reduxTransforms';
import reducers from './reducers';

const {
  approvalReducer,
  balanceReducer,
  deployedSetsReducer,
  exploreReducer,
  gasPriceReducer,
  ipAddressReducer,
  issuanceV2Reducer,
  onboardingReducer,
  tableReducers,
  redemptionV2Reducer,
  perpLeverageReducer,
  selectLanguageReducer,
  setDetailsReducer,
  setManagerReducer,
  setJSReducer,
  tokenColorsReducer,
  tokenListsReducer,
  transactionWatcherReducer,
  uniswapReducer,
  web3Reducer,
  windowDimensionReducer,
} = reducers;

const web3PersistConfig = {
  key: 'web3',
  storage,
  blacklist: [
    'appLoading',
    'isLoginModalOpen',
    'isChainSwitchModalOpen',
    'web3Instance',
    'afterLoginAction',
  ],
};

const rootReducer = combineReducers({
  approvals: approvalReducer,
  balance: balanceReducer,
  deployedSets: deployedSetsReducer,
  explore: exploreReducer,
  gasPrice: gasPriceReducer,
  ipAddress: ipAddressReducer,
  issuanceV2: issuanceV2Reducer,
  onboarding: onboardingReducer,
  tables: tableReducers,
  perpLeverage: perpLeverageReducer,
  redemptionV2: redemptionV2Reducer,
  routing: routerReducer,
  selectLanguage: selectLanguageReducer,
  setDetails: setDetailsReducer,
  setManager: setManagerReducer,
  setJS: setJSReducer,
  tokenColors: tokenColorsReducer,
  tokenLists: tokenListsReducer,
  transactionWatcher: transactionWatcherReducer,
  uniswap: uniswapReducer,
  web3: persistReducer(web3PersistConfig, web3Reducer),
  windowDimension: windowDimensionReducer,
});

// Redux persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['approvals', 'windowDimension'],
  blacklist: ['setDetails'],
  transforms: [web3Transform],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
