import React, { Component } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import { css, StyleSheet } from 'aphrodite';

import { Text } from '../../components/index';
import { IFaqSectionContent } from '../../typings/index';

const styles = StyleSheet.create({
  accordion: {
    marginBottom: '30px',
  },
  accordionQuestion: {
    marginTop: '10px',
    marginBottom: '50px',
    paddingLeft: '22px',
  },
  sectionContainer: {
    marginBottom: '45px',
    marginTop: '20px',
  },
  sectionTitle: {
    marginBottom: '30px',
  },
  textAlignLeft: {
    textAlign: 'left',
  },
});

interface IFaqSectionProps {
  sectionTitle: string;
  sectionContent: IFaqSectionContent[];
}

interface IFaqSectionState {
  activeIndex: number;
}

class FaqSection extends Component<IFaqSectionProps, IFaqSectionState> {
  state = { activeIndex: -1 };

  handleClick = (_e: any, titleProps: any) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  renderQAndA = (content: IFaqSectionContent, index: number) => {
    const { activeIndex } = this.state;
    return (
      <Accordion className={css(styles.textAlignLeft, styles.accordion)} key={index}>
        <Accordion.Title active={activeIndex === index} index={index} onClick={this.handleClick}>
          <Icon name="dropdown" />
          <Text color="darkBlue" type="large" fontWeight={600}>
            {content.question()}
          </Text>
        </Accordion.Title>
        <Accordion.Content className={css(styles.accordionQuestion)} active={activeIndex === index}>
          {content.answer()}
        </Accordion.Content>
      </Accordion>
    );
  };

  render() {
    const { sectionTitle, sectionContent } = this.props;
    return (
      <div className={css(styles.sectionContainer, styles.textAlignLeft)}>
        <Text
          className={css(styles.sectionTitle)}
          color="darkGray"
          fontWeight={600}
          type="large"
          tag="p"
        >
          {sectionTitle}
        </Text>
        {sectionContent.map((content: IFaqSectionContent, index: number) =>
          this.renderQAndA(content, index),
        )}
      </div>
    );
  }
}

export default FaqSection;
