const MAIN_NET_ID = '1';
const ROPSTEN_ID = '3';
const KOVAN_ID = '42';
const POLYGON_MAINNET_ID = '137';
const POLYGON_MUMBAI_ID = '80001';
const ARBITRUM_MAINNET_ID = '42161';
const ARBITRUM_RINKEBY_ID = '421611';
const OPTIMISM_MAINNET_ID = '10';
const OPTIMISM_KOVAN_ID = '69';
const AVALANCHE_MAINNET_ID = '43114';
const AVALANCHE_FUJI_ID = '43113';

const ETHEREUM_ENV_NETWORK = MAIN_NET_ID;
const POLYGON_ENV_NETWORK = POLYGON_MAINNET_ID;
const ARBITRUM_ENV_NETWORK = ARBITRUM_MAINNET_ID;
const OPTIMISM_ENV_NETWORK = OPTIMISM_MAINNET_ID;
const AVALANCHE_ENV_NETWORK = AVALANCHE_MAINNET_ID;

const ETHEREUM_CHAIN = 'ethereum';
const POLYGON_CHAIN = 'polygon';
const ARBITRUM_CHAIN = 'arbitrum';
const OPTIMISM_CHAIN = 'optimism';
const AVALANCHE_CHAIN = 'avalanche';
const UNSUPPORTED_CHAIN = 'unsupported';

const CHAIN_PARAMS: {
  [chainId: string]: {
    chainId: string;
    chainName: string;
    nativeCurrency: {
      name: string;
      symbol: string;
      decimals: number;
    };
    rpcUrls: string[];
    blockExplorerUrls: string[];
  };
} = {
  [MAIN_NET_ID]: {
    chainId: '0x1',
    chainName: 'Ethereum',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.infura.io/v3'],
    blockExplorerUrls: ['https://etherscan.com'],
  },
  [ROPSTEN_ID]: {
    chainId: '0x3',
    chainName: 'Ethereum (Ropsten)',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://ropsten.infura.io/v3'],
    blockExplorerUrls: ['https://ropsten.etherscan.io'],
  },
  [KOVAN_ID]: {
    chainId: '0x2A',
    chainName: 'Ethereum (Kovan)',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://kovan.infura.io/v3'],
    blockExplorerUrls: ['https://kovan.etherscan.io'],
  },
  [POLYGON_MAINNET_ID]: {
    chainId: '0x89',
    chainName: 'Matic Network',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://rpc-mainnet.maticvigil.com'],
    blockExplorerUrls: ['https://polygonscan.com'],
  },
  [POLYGON_MUMBAI_ID]: {
    chainId: '0x13881',
    chainName: 'Matic (Mumbai) Testnet Network',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://rpc-mumbai.maticvigil.com'],
    blockExplorerUrls: ['https://mumbai.polygonscan.com'],
  },
  [ARBITRUM_MAINNET_ID]: {
    chainId: '0xA4B1',
    chainName: 'Arbitrum One',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'AETH',
      decimals: 18,
    },
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://arbiscan.io'],
  },
  [ARBITRUM_RINKEBY_ID]: {
    chainId: '0x66EEB',
    chainName: 'Arbitrum Testnet',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'AETH',
      decimals: 18,
    },
    rpcUrls: ['https://rinkeby.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://testnet.arbiscan.io'],
  },
  [OPTIMISM_MAINNET_ID]: {
    chainId: '0xA',
    chainName: 'Optimistic Ethereum',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'OETH',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.optimism.io'],
    blockExplorerUrls: ['https://optimistic.etherscan.io'],
  },
  [OPTIMISM_KOVAN_ID]: {
    chainId: '0x45',
    chainName: 'Optimistic Kovan',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'KOR',
      decimals: 18,
    },
    rpcUrls: ['https://kovan.optimism.io'],
    blockExplorerUrls: ['https://kovan-optimistic.etherscan.io'],
  },
  [AVALANCHE_MAINNET_ID]: {
    chainId: '0xA86A',
    chainName: 'Avalanche C-Chain',
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://snowtrace.io'],
  },
  [AVALANCHE_FUJI_ID]: {
    chainId: '0xA869',
    chainName: 'Avalanche FUJI C-Chain',
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://testnet.snowtrace.io'],
  },
};

export default {
  CHAIN_PARAMS,
  MAIN_NET_ID,
  ROPSTEN_ID,
  KOVAN_ID,
  ETHEREUM_ENV_NETWORK,
  POLYGON_ENV_NETWORK,
  ARBITRUM_ENV_NETWORK,
  OPTIMISM_ENV_NETWORK,
  AVALANCHE_ENV_NETWORK,
  POLYGON_MAINNET_ID,
  POLYGON_MUMBAI_ID,
  ARBITRUM_MAINNET_ID,
  ARBITRUM_RINKEBY_ID,
  OPTIMISM_MAINNET_ID,
  OPTIMISM_KOVAN_ID,
  AVALANCHE_MAINNET_ID,
  AVALANCHE_FUJI_ID,
  UNSUPPORTED_CHAIN,
  ETHEREUM_CHAIN,
  POLYGON_CHAIN,
  ARBITRUM_CHAIN,
  OPTIMISM_CHAIN,
  AVALANCHE_CHAIN,
};
