import { IAction } from '../typings/index';
import {
  GO_TO_NEXT_ONBOARDING_SLIDE,
  GO_TO_PREVIOUS_ONBOARDING_SLIDE,
  GO_TO_TARGET_ONBOARDING_SLIDE,
} from '../actions/onboardingActions';

const MAX_SLIDE_COUNT = 5;

export interface IOnboardingsState {
  currentOnboardingSlideIndex: number;
}

const initialState: IOnboardingsState = {
  currentOnboardingSlideIndex: 0,
};

const onboardingReducer = (state = initialState, action: IAction): IOnboardingsState => {
  switch (action.type) {
    case GO_TO_NEXT_ONBOARDING_SLIDE:
      if (state.currentOnboardingSlideIndex + 1 === MAX_SLIDE_COUNT) return state;

      return {
        currentOnboardingSlideIndex: state.currentOnboardingSlideIndex + 1,
      };

    case GO_TO_PREVIOUS_ONBOARDING_SLIDE:
      if (state.currentOnboardingSlideIndex === 0) return state;

      return {
        currentOnboardingSlideIndex: state.currentOnboardingSlideIndex - 1,
      };

    case GO_TO_TARGET_ONBOARDING_SLIDE:
      if (action.payload < 0 || action.payload >= MAX_SLIDE_COUNT) return state;

      return {
        currentOnboardingSlideIndex: action.payload,
      };

    default:
      return state;
  }
};

export default onboardingReducer;
