import tinycolor from 'tinycolor2';

import { IColors } from '../typings/index';

/* COLOR PALETTE */
const blueHex = '#2D2CE5';
const lightBlueHex = '#1A53F0';
const lightBlue2Hex = '#E0D8F4'; // Used for container borders
const lightBlue3Hex = '#6BB1FF';
const lightBlue4Hex = '#837AFF';
const lightBlue5Hex = '#687DE3';
const lightBlue6Hex = '#627EEA';
const mediumBlueHex = '#1C1BBC';
const darkBlueHex = '#1D144F';
const darkBlue2Hex = '#2B2C61';
const royalBlue = '#2D2CE5';
const royalBlue2 = '#343BD5';
const blueGray = '#9B97B3';
const blueGray2 = '#D9D7E1';
const darkGrayHex = '#9B97B3';
const darkGrayHex2 = '#555555';
const grayHex = '#DAD7E2';
const lightGrayHex = '#F4F4FA';
const lightGrayHex2 = '#FCFCFF';
const lightGrayHex3 = 'rgba(34,36,38,.15)'; // This is the exact border color of semantic-ui-react Tables
const lightGrayHex4 = '#C4C4C4';
const lightGrayHex5 = '#F6F6F6';
const lightGrayHex6 = '#E5E2FF';
const lightGrayHex7 = '#FAFAFA';
const lightGrayHex8 = '#ECEBF0';
const lightGrayHex9 = '#E0DCF4';
const lightGrayHex10 = '#ECEBF0';
const greenHex = '#2BB160';
const lightGreenHex = '#E5FFEF';
const redHex = '#DF4857';
const redHex2 = '#DA1D14';
const pinkHex = '#F444AC';
const pinkHex2 = '#FFF5F5';
const yellowHex = '#F1CD41';
const yellow2Hex = '#F3B731';
const whiteHex = '#FFFFFF';
const blackHex = '#000000';
const darkPurpleHex = '#8F5AE8';
const purpleHex = '#D4BAFF';
const lightPurpleHex = '#F8F5FF';
const blueShadow = 'rgba(98, 126, 234, 0.5)';
const redShadow = 'rgba(213, 43, 30, 0.3)';
const grayShadow = 'rgba(45, 44, 229, 0.2))';
const blueAlphaBackground = 'rgba(45, 44, 229, 0.08)'; // royalBlueTransparent
const blueContrastBackground = 'rgba(131, 122, 255, 0.08)';
const polygonGradient =
  'linear-gradient(100.27deg, #8F5AE8 22.1%, #B72BB2 106.65%, rgba(203, 20, 151, 0) 145.85%, rgba(143, 90, 232, 0) 157.53%)';
const blueGradient =
  'linear-gradient(326.31deg, rgba(0, 0, 247, 0.12) 33.39%, rgba(63, 152, 255, 0.12) 86.79%);';
const borderBlueGradient = 'linear-gradient(328.13deg, #2020E5 -14.86%, #6BB0FF 104.22%)';

/* DARKENED COLORS */
const darken = (color: string, percentage = 5) => tinycolor(color).darken(percentage).toString();
const darkPurpleDarkened: string = darken(darkPurpleHex);
const blueDarkened: string = darken(blueHex, 10);
const mediumBlueDarkened: string = darken(mediumBlueHex);
const darkBlueDarkened: string = darken(darkBlueHex);
const darkGrayDarkened: string = darken(darkGrayHex);
const grayDarkened: string = darken(grayHex);
const lightGrayDarkened: string = darken(lightGrayHex);
const greenDarkened: string = darken(greenHex);
const redDarkened: string = darken(redHex);
const pinkDarkened: string = darken(pinkHex);
const yellowDarkened: string = darken(yellowHex);
const whiteDarkened: string = darken(whiteHex);
/* TRANSPARENT COLORS */
const setAlpha = (color: string, alpha = 0.25) => tinycolor(color).setAlpha(alpha).toString();
const blueAlpha25: string = setAlpha(blueHex);
const darkblueAlpha25: string = setAlpha(darkBlueHex);
const lightBlueAlpha25: string = setAlpha(lightBlueHex);
const lightBlueAlpha15: string = setAlpha(lightBlueHex, 0.15);
const lightBlue4Alpha10: string = setAlpha(lightBlue4Hex, 0.1);
const greenAlpha25: string = setAlpha(greenHex);
const redAlpha25: string = setAlpha(redHex);
const whiteAlpha50: string = setAlpha(whiteHex, 0.5);
const whiteAlpha75: string = setAlpha(whiteHex, 0.75);
const whiteAlpha64: string = setAlpha(whiteHex, 0.64);
const whiteAlpha40: string = setAlpha(whiteHex, 0.4);
const blackAlpha20: string = setAlpha(blackHex, 0.2);
const blackAlpha50: string = setAlpha(blackHex, 0.5);
/* MISC COLORS */
const semanticUIFocusInputBorderColor = '#85b7d9';
const disabledGrayHex = '#787485';

const colors: IColors = {
  // SOLID COLORS
  blue: blueHex,
  lightBlue: lightBlueHex,
  lightBlue2: lightBlue2Hex,
  lightBlue3: lightBlue3Hex,
  lightBlue4: lightBlue4Hex,
  lightBlue5: lightBlue5Hex,
  lightBlue6: lightBlue6Hex,
  mediumBlue: mediumBlueHex,
  darkBlue: darkBlueHex,
  darkBlue2: darkBlue2Hex,
  royalBlue: royalBlue,
  royalBlue2: royalBlue2,
  blueGray: blueGray,
  blueGray2: blueGray2,
  darkGray: darkGrayHex,
  darkGray2: darkGrayHex2,
  gray: grayHex,
  lightGray: lightGrayHex,
  lightGray2: lightGrayHex2,
  lightGray3: lightGrayHex3,
  lightGray4: lightGrayHex4,
  lightGray5: lightGrayHex5,
  lightGray6: lightGrayHex6,
  lightGray7: lightGrayHex7,
  lightGray8: lightGrayHex8,
  lightGray9: lightGrayHex9,
  lightGray10: lightGrayHex10,
  green: greenHex,
  lightGreen: lightGreenHex,
  red: redHex,
  red2: redHex2,
  pink: pinkHex,
  pink2: pinkHex2,
  yellow: yellowHex,
  yellow2: yellow2Hex,
  white: whiteHex,
  black: blackHex,
  darkPurple: darkPurpleHex,
  purple: purpleHex,
  lightPurple: lightPurpleHex,
  disabledGray: disabledGrayHex,
  // DARKED COLORS
  darkPurpleDarkened,
  blueDarkened,
  mediumBlueDarkened,
  darkBlueDarkened,
  darkGrayDarkened,
  grayDarkened,
  lightGrayDarkened,
  greenDarkened,
  redDarkened,
  pinkDarkened,
  yellowDarkened,
  whiteDarkened,
  // TRANSPARENT
  blueAlpha25,
  darkblueAlpha25,
  lightBlueAlpha25,
  lightBlueAlpha15,
  lightBlue4Alpha10,
  greenAlpha25,
  redAlpha25,
  whiteAlpha50,
  whiteAlpha75,
  whiteAlpha64,
  whiteAlpha40,
  blackAlpha20,
  blackAlpha50,
  // MISC
  focusInputColor: semanticUIFocusInputBorderColor,
  blueShadow: blueShadow,
  redShadow: redShadow,
  grayShadow: grayShadow,
  polygonGradient,
  blueGradient,
  borderBlueGradient,
  blueContrastBackground,
  blueAlphaBackground,
};

export default colors;
