import { connect } from 'react-redux';
import PerpIssuanceForm from './PerpIssuanceForm';
import {
  setMaxAllowedSlippagePercentage,
  updateIssuanceQuantity,
} from '../../actions/issuanceV2Actions';
import {
  setMaxPerpIssuableQuantity,
  perpIssueCurrentSet,
  fetchFeesForIssueQuantity,
  getMaximumSetTokenIssueAmount,
} from '../../actions/perpIssuanceActions';
import { resetTransactionWatcher } from '../../actions/transactionWatcherActions';
import { currentSetDetailsSelector } from '../../selectors/setDetailsSelectors';
import {
  doesCurrentInputQuantityExceedAumCapSelector,
  isPerpIssuanceReadySelector,
  perpIssuanceAumCapSelector,
  issuanceInputQuantityAboveAumCap,
  userHasSufficientFundsForPerpIssuanceQuantity,
  perpIssuanceInputComponentsSelector,
  perpIssuanceOutputComponentsSelector,
  maxTokenAmountsInSelector,
} from '../../selectors/perpIssuanceRedemptionSelectors';
import {
  issuanceInputQuantityV2Selector,
  setDetailsCurrentSetAddressSelector,
  erc20BalancesSelector,
  transactionIdSelector,
  windowDimensionSelector,
  customV2SetPathPrefixSelector,
  maxSlippagePercentageAllowedSelector,
  perpIssuanceFeesSelector,
  isFetchingPerpIssuanceFeesSelector,
} from '../../selectors/index';

const mapStateToProps = (state: any) => {
  const isPerpIssuanceReady = isPerpIssuanceReadySelector(state);
  const issuanceQuantity = issuanceInputQuantityV2Selector(state);
  const inputComponents = perpIssuanceInputComponentsSelector(state);
  const outputComponents = perpIssuanceOutputComponentsSelector(state);
  const erc20BalancesByAddress = erc20BalancesSelector(state);
  const currentTransactionId = transactionIdSelector(state);
  const windowDimensions = windowDimensionSelector(state);
  const setDetails = currentSetDetailsSelector(state);
  const setAddress = setDetailsCurrentSetAddressSelector(state);
  const isExceedingAumCap = doesCurrentInputQuantityExceedAumCapSelector(state);
  const currentSetAumCap = perpIssuanceAumCapSelector(state);
  const issuanceQuantityAboveAumCap = issuanceInputQuantityAboveAumCap(state);
  const userHasSufficientFunds = userHasSufficientFundsForPerpIssuanceQuantity(state);
  const customV2SetPathPrefix = customV2SetPathPrefixSelector(state);
  const maxSlippagePercentageAllowed = maxSlippagePercentageAllowedSelector(state);
  const perpIssuanceFees = perpIssuanceFeesSelector(state);
  const isFetchingPerpIssuanceFees = isFetchingPerpIssuanceFeesSelector(state);
  const maxTokenAmountsIn = maxTokenAmountsInSelector(state);

  return {
    perpIssuanceFees,
    isFetchingPerpIssuanceFees,
    customV2SetPathPrefix,
    setDetails,
    setAddress,
    isPerpIssuanceReady,
    isExceedingAumCap,
    userHasSufficientFunds,
    issuanceQuantity,
    inputComponents,
    outputComponents,
    erc20BalancesByAddress,
    currentTransactionId,
    currentSetAumCap,
    issuanceQuantityAboveAumCap,
    windowDimensions,
    maxSlippagePercentageAllowed,
    maxTokenAmountsIn,
  };
};

const mapDispatchToProps = {
  getMaximumSetTokenIssueAmount,
  updateIssuanceQuantity,
  setMaxIssuableQuantity: setMaxPerpIssuableQuantity,
  setMaxAllowedSlippagePercentage,
  perpIssueCurrentSet,
  resetTransactionWatcher,
  fetchFeesForIssueQuantity,
};

export default connect(mapStateToProps, mapDispatchToProps)(PerpIssuanceForm);
