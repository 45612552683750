import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import browserLanguageDetector from 'i18next-browser-languagedetector';

import translationResources from './locales/index';

export const supportedLanguages = [
  'en', // english
];

export const supportedLanguagesLabelsMap: { [index: string]: string } = {
  en: 'English',
};

const options = {
  // i18next browser-languagedetector config options
  detection: {
    order: ['cookie', 'localStorage'],
    caches: ['cookie', 'localStorage'],
  },

  // i18next config options
  // Translation strings
  resources: translationResources,

  // Translations in `common.json` will be available as fallback on all namespaces.
  fallbackNS: 'common',
  // Translations in `common.json` will be used if no namespace is passed to translate.
  defaultNS: 'common',

  // English translations will be used if the key is missing in the selected language.
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
};

i18n.use(browserLanguageDetector).use(initReactI18next).init(options);

export default i18n;
