import keyMirror from 'keymirror';

const DATA_SELECTS = keyMirror({
  TRANSACTIONS_BODY: null,
  TRANSACTIONS_ITEM_DESCRIPTION: null,
  TRANSACTIONS_PAGE_ICON: null,
  TRANSACTIONS_BACK_BTN: null,
  TRANSACTIONS_FORWARD_BTN: null,
});

export default DATA_SELECTS;
