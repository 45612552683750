import { BigNumber as EthersBigNumber } from 'ethers';
import { ETHEREUM_ADDRESSES, NETWORK_CONSTANTS } from '../constants/index';
import {
  accountSelector,
  averageGasPriceSelector,
  baseFeeSelector,
  maxPriorityFeePerGasSelector,
} from '../selectors/baseSelectors';
import { currentChainOrDefaultSelector } from '.';
import BigNumber from '../utils/bigNumber';

export const transactionCallerSelector = (state: any): string => {
  const account = accountSelector(state);

  return account && account.length > 0 ? account : ETHEREUM_ADDRESSES.NULL_ADDRESS;
};

export const gasPriceSelector = (state: any) => {
  const currentChain = currentChainOrDefaultSelector(state);
  const baseFee = baseFeeSelector(state);
  const maxPriorityFeePerGas = maxPriorityFeePerGasSelector(state);
  const fastGasPrice = averageGasPriceSelector(state);

  switch (currentChain) {
    case NETWORK_CONSTANTS.ETHEREUM_CHAIN:
      return new BigNumber(baseFee || 0).plus(new BigNumber(maxPriorityFeePerGas || 0)).toString();
    default:
      return fastGasPrice;
  }
};

export const gasPriceTransactionOptionsSelector = (state: any, buffer?: string) => {
  const currentChain = currentChainOrDefaultSelector(state);
  const gasPrice = averageGasPriceSelector(state);

  if (currentChain === NETWORK_CONSTANTS.ETHEREUM_CHAIN) {
    return {
      type: 2,
    };
  } else {
    return {
      gasPrice: EthersBigNumber.from(gasPrice).mul(buffer || 1),
    };
  }
};
