import { Dispatch } from 'redux';
import { fetchSetDetails } from '../actions/setDetailsActions';
import {
  basicIssuanceModuleAddressSelector,
  streamingFeeModuleAddressSelector,
  setDetailsCurrentSetAddressSelector,
} from '../selectors/index';
import { ISetDetails, IV2SetModule } from '../typings/index';
import { faultTolerantPromise, getWeb3Instance } from '../utils/index';
import { emptyActionGenerator, payloadActionGenerator } from '../utils/reduxHelpers';

import {
  aaveLeverageModuleAddressSelector,
  allModulesSelector,
  compoundLeverageModuleAddressSelector,
  currentSetManagerSelector,
  perpV2BasisTradingModuleAddressSelector,
  perpV2LeverageModuleAddressSelector,
} from '../selectors/setDetailsSelectors';
import { debtIssuanceModuleAddressSelector } from '../selectors/debtIssuanceSelectors';
import { perpIssuanceModuleAddressSelector } from '../selectors/protocolAddressSelector';
const managerABI = require('../constants/abis/managerABI').default;

/* Action Types */
export const REQUEST_SET_MANAGER = 'REQUEST_SET_MANAGER';
export const RECEIVE_V2_SET_MANAGER = 'RECEIVE_V2_SET_MANAGER';
export const REQUEST_DELEGATED_SET_MANAGER = 'REQUEST_DELEGATED_SET_MANAGER';
export const RECEIVE_DELEGATED_SET_MANAGER = 'RECEIVE_DELEGATED_SET_MANAGER';
export const REQUEST_DELEGATED_SET_MANAGER_FAILED = 'REQUEST_DELEGATED_SET_MANAGER_FAILED';

/* Action Creators */
const requestSetManager = emptyActionGenerator(REQUEST_SET_MANAGER);
const receiveV2SetManager = payloadActionGenerator(RECEIVE_V2_SET_MANAGER);
const requestDelegatedSetManager = emptyActionGenerator(REQUEST_DELEGATED_SET_MANAGER);
const receiveDelegatedSetManager = payloadActionGenerator(RECEIVE_DELEGATED_SET_MANAGER);
const requestDelegatedSetManagerFailed = emptyActionGenerator(REQUEST_DELEGATED_SET_MANAGER_FAILED);

const getModules = (state: any): IV2SetModule[] => {
  const debtIssuanceModuleAddress = debtIssuanceModuleAddressSelector(state);
  const basicIssuanceModuleAddress = basicIssuanceModuleAddressSelector(state);
  const streamingFeeModuleAddress = streamingFeeModuleAddressSelector(state);
  const perpIssuanceModuleAddress = perpIssuanceModuleAddressSelector(state);

  // Leverage Modules
  const perpV2BasisTradingModuleAddress = perpV2BasisTradingModuleAddressSelector(state);
  const perpV2LeverageModuleAddress = perpV2LeverageModuleAddressSelector(state);
  const compoundLeverageModuleAddress = compoundLeverageModuleAddressSelector(state);
  const aaveLeverageModuleAddress = aaveLeverageModuleAddressSelector(state);

  return [
    {
      address: basicIssuanceModuleAddress,
      description: 'Issue shares of your Set using the underlying components',
      moduleType: 'issuance',
      name: 'Issuance',
      status: 0,
    },
    {
      address: streamingFeeModuleAddress,
      description: 'Earn streaming fees as a percentage of your Set',
      moduleType: 'fees',
      name: 'Fees',
      status: 0,
    },
    {
      address: debtIssuanceModuleAddress,
      description: '(Debt) Issue shares of your Set using the underlying components',
      moduleType: 'issuance',
      name: 'Debt Issuance',
      status: 0,
    },
    {
      address: perpIssuanceModuleAddress,
      description: 'Issue (with slippage) shares of your Set using the underlying components',
      moduleType: 'issuance',
      name: 'Slippage Issuance',
      status: 0,
    },
    {
      address: perpV2LeverageModuleAddress,
      description: 'Go long/short with leverage through Perpetual',
      moduleType: 'leverage',
      name: 'Perpetual Leverage',
      status: 0,
    },
    {
      address: perpV2BasisTradingModuleAddress,
      description: 'Basis trading of assets through Perpetual',
      moduleType: 'leverage',
      name: 'Basis Trading',
      status: 0,
    },
    {
      address: compoundLeverageModuleAddress,
      description: 'Go long/short with leverage through Compound',
      moduleType: 'leverage',
      name: 'Compound Leverage',
      status: 0,
    },
    {
      address: aaveLeverageModuleAddress,
      description: 'Go long/short with leverage through Aave',
      moduleType: 'leverage',
      name: 'Aave Leverage',
      status: 0,
    },
  ];
};

export function fetchV2SetManagerModules(setAddress: string) {
  return async (dispatch: Dispatch<Function>, getState: any) => {
    const state = getState();
    const allModules = allModulesSelector(state);

    dispatch(requestSetManager());
    // @ts-ignore
    await dispatch(fetchSetDetails(setAddress)).then((response: ISetDetails) => {
      const enabledModules: IV2SetModule[] = [];
      const disabledModules: IV2SetModule[] = [];

      // TODO: remove tech debt. Try to not fetch by index if possible
      response?.moduleStatuses.forEach((status: number, index: number) => {
        const currentModule = getModules(state).find(m => m.address === allModules[index]);

        if (!currentModule) {
          return;
        }

        const module = {
          ...currentModule,
          status,
        };
        if (status === 2) {
          enabledModules.push(module);
        } else if (status === 1) {
          disabledModules.push(module);
        } else {
          // Module has not been added to this set so cannot be Enabled
        }
      });
      const setManagerData = {
        ...response,
        enabledModules,
        disabledModules,
      };

      dispatch(receiveV2SetManager(setManagerData));
    });
  };
}

const getDelegatedManagerAllowlistStatus = async (managerAddress: string): Promise<boolean> => {
  const web3Instance = await getWeb3Instance(true);

  const delegatedManagerContract = new web3Instance.eth.Contract(managerABI as any, managerAddress);
  const assetAllowlistAddresses = await delegatedManagerContract.methods.useAssetAllowlist().call();

  return assetAllowlistAddresses;
};

const getDelegatedManagerAssetAllowlistAddresses = async (managerAddress: string): Promise<any> => {
  const web3Instance = await getWeb3Instance(true);

  const delegatedManagerContract = new web3Instance.eth.Contract(managerABI as any, managerAddress);
  const assetAllowlistAddresses = await delegatedManagerContract.methods.getAllowedAssets().call();

  return assetAllowlistAddresses;
};

const getDelegatedManagerOperatorAddresses = async (managerAddress: string): Promise<any> => {
  const web3Instance = await getWeb3Instance(true);

  const delegatedManagerContract = new web3Instance.eth.Contract(managerABI as any, managerAddress);
  const operatorAddresses = await delegatedManagerContract.methods.getOperators().call();

  return operatorAddresses;
};

const getDelegatedManagerOwnerAddresses = async (managerAddress: string): Promise<any> => {
  const web3Instance = await getWeb3Instance(true);

  const delegatedManagerContract = new web3Instance.eth.Contract(managerABI as any, managerAddress);
  const ownerAddress = await delegatedManagerContract.methods.owner().call();

  return ownerAddress;
};

/**
 * Fetches delegated manager contract data, e.g. asset allowlist + operators.
 * This should be updated to a protocol viewer, or Graph query.
 * @returns boolean - True if fetching delegated manager data is successful, false otherwise.
 */
export const fetchDelegatedManagerData = () => async (
  dispatch: any,
  getState: any,
): Promise<boolean> => {
  const state = getState();
  const setAddress = setDetailsCurrentSetAddressSelector(state);
  const managerAddress = currentSetManagerSelector(state);

  try {
    dispatch(requestDelegatedSetManager());

    const useAssetAllowlist = await faultTolerantPromise(
      getDelegatedManagerAllowlistStatus(managerAddress),
      { retries: 2 },
    );
    const assetAllowlistAddresses = await faultTolerantPromise(
      getDelegatedManagerAssetAllowlistAddresses(managerAddress),
      { retries: 2 },
    );
    const operatorAddresses = await faultTolerantPromise(
      getDelegatedManagerOperatorAddresses(managerAddress),
      { retries: 2 },
    );
    const ownerAddress = await faultTolerantPromise(
      getDelegatedManagerOwnerAddresses(managerAddress),
      { retries: 2 },
    );

    dispatch(
      receiveDelegatedSetManager({
        setAddress,
        useAssetAllowlist,
        operatorAddresses,
        assetAllowlistAddresses,
        ownerAddress,
      }),
    );

    return true;
  } catch (error) {
    if (error && typeof error === 'object' && error.message.includes('Out of Gas?')) {
      dispatch(requestDelegatedSetManagerFailed());
      return false;
    }

    console.log('No Delegated Manager Data:', error.message);
    dispatch(requestDelegatedSetManagerFailed());
    return false;
  }
};
