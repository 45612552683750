import NAVBAR from './NavBar';
import { INavBarMapping } from '../../typings/index';

const NAVBAR_MAPPING: INavBarMapping = {
  Explore: NAVBAR.NAVBAR_EXPLORE,
  Creator: NAVBAR.NAVBAR_CREATOR,
  Account: NAVBAR.NAVBAR_ACCOUNT,
};

export default NAVBAR_MAPPING;
