import React, { PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import TransactionWatcher, {
  TransactionCompleted,
  TransactionFailed,
  TransactionLong,
} from '../../containers/TransactionWatcher';
import RedemptionForm from '../../containers/RedemptionFormV2';
import RedemptionPending from '../../containers/RedemptionFormV2/RedemptionPending';
import { IMatch, IHistory } from '../../typings/index';

type RedemptionFormWrapperProps = {
  customV2SetPathPrefix: string;
  name: string;
  currentTransactionId: string;
  history: IHistory;
  match: IMatch;
  resetTransactionWatcher: (...args: any[]) => any;
};

/**
 * @title RedemptionFormWrapper
 * @author Set Protocol
 *
 * Wraps RedemptionForm with TransactionWatcher functionality + supplementary pending, success, fail screens.
 */
class Redemption extends PureComponent<RedemptionFormWrapperProps & WithTranslation> {
  public navigateToSetPage = () => {
    const { customV2SetPathPrefix, history, match, resetTransactionWatcher } = this.props;
    history.push(`${customV2SetPathPrefix}/${match.params.set}`);
    resetTransactionWatcher();
  };

  render() {
    const { name, match, history, currentTransactionId, resetTransactionWatcher, t } = this.props;

    const beginRedemptionComponent = <RedemptionForm history={history} match={match} />;
    const redemptionApproving = beginRedemptionComponent;
    const redemptionPending = (
      <RedemptionPending transactionId={currentTransactionId} name={name} />
    );
    const completedRedemption = (
      <TransactionCompleted
        title={t('redeem-modals.completed-title')}
        text={t('redeem-modals.completed-description', { tokenName: name })}
        transactionHash={currentTransactionId}
        onFinish={this.navigateToSetPage}
      />
    );
    const longRedemption = (
      <TransactionLong
        title={t('redeem-modals.long-title', { tokenName: name })}
        text={t('redeem-modals.long-description')}
        transactionHash={currentTransactionId}
        onRetry={resetTransactionWatcher}
        onClose={this.navigateToSetPage}
      />
    );
    const failedRedemption = (
      <TransactionFailed
        title={t('redeem-modals.failed-title')}
        text={t('redeem-modals.failed-description', { tokenName: name })}
        transactionHash={currentTransactionId}
        onRetry={resetTransactionWatcher}
      />
    );

    return (
      <TransactionWatcher
        startTransactionComponent={beginRedemptionComponent}
        approvingTransactionComponent={redemptionApproving}
        pendingTransactionComponent={redemptionPending}
        completedTransactionComponent={completedRedemption}
        longTransactionComponent={longRedemption}
        failedTransactionComponent={failedRedemption}
      />
    );
  }
}
export default withTranslation('issue-and-redeem')(Redemption);
