import BUTTON from './Button';
import INPUT from './Input';
import SET_DETAIL_HEADER_COL from './SetDetailHeaderCol';
import SET_DETAIL_HOLDINGS_COL from './SetDetailHoldingsCol';
import HOLDINGS_ZERO_STATE from './HoldingsZeroState';
import NAVBAR from './NavBar';
import NAVBAR_MAPPING from './NavBarMapping';
import TEXT from './Text';
import TOKEN_TABLE from './TokenTable';
import TRANSACTIONS from './Transactions';

const DATA_SELECTS = {
  ...BUTTON,
  ...INPUT,
  ...SET_DETAIL_HEADER_COL,
  ...SET_DETAIL_HOLDINGS_COL,
  ...HOLDINGS_ZERO_STATE,
  ...NAVBAR,
  ...TEXT,
  ...TOKEN_TABLE,
  ...TRANSACTIONS,
  NAVBAR_MAPPING,
};

export default DATA_SELECTS;
