import React, { PureComponent } from 'react';
import { Header, Grid } from 'semantic-ui-react';
import { StyleSheet, css } from 'aphrodite';

import { LinkButton, Text } from '../../components/index';
import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';

import styles from './homeStyles';

const { MOBILE_MEDIA_QUERY, TABLET_MEDIA_QUERY, TABLET_LANDSCAPE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const homeHeaderStyles = StyleSheet.create({
  Home_container: {
    textAlign: 'center',
    marginBottom: '40px',
    [TABLET_MEDIA_QUERY]: {
      marginBottom: '50px',
    },
  },
  Home_titleText: {
    fontSize: '90px',
    fontWeight: 600,
    lineHeight: '90px',
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '30px',
    marginBottom: '35px',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      fontSize: '72px',
      textAlign: 'center',
      lineHeight: '72px',
      width: '100%',
    },
    [TABLET_MEDIA_QUERY]: {
      textAlign: 'center',
      fontSize: '52px',
      lineHeight: '52px',
      width: '100%',
    },
    [MOBILE_MEDIA_QUERY]: {
      fontSize: '54px',
      lineHeight: '54px',
      marginBottom: '30px',
    },
  },
  Home_titleTextHighlight: {
    color: COLORS.blue,
    display: 'inline',
  },
  Home_subText: {
    color: COLORS.darkGray,
    lineHeight: '1.5',
    fontSize: '32px',
    width: '80%',
    marginBottom: '58px',
    marginTop: '20px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [TABLET_MEDIA_QUERY]: {
      textAlign: 'center',
      fontSize: '20px',
      marginTop: '10px',
      marginBottom: '25px',
      width: '100%',
    },
    [MOBILE_MEDIA_QUERY]: {
      fontSize: '22px',
      lineHeight: '1.45',
      marginBottom: '30px',
      width: '100%',
    },
  },
  Home_videoButtonInnerContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'inline-block',
  },
  Home_videoPlayButton: {
    color: COLORS.darkBlue,
    marginTop: '25px',
  },
  Home_videoPlayText: {
    cursor: 'pointer',
    marginLeft: '10px',
    display: 'inline-block',
  },
  Home_secondaryButton: {
    background: COLORS.darkBlue,
    marginLeft: '15px',
    justifyContent: 'center',
    width: '220px',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      marginLeft: '0',
      marginTop: '15px',
    },
  },
});

class HomeHeader extends PureComponent {
  public render() {
    return (
      <div className={css(homeHeaderStyles.Home_container)}>
        <div className={css(styles.Home_smContainer, styles.Home_heroMargins)}>
          <Grid>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Header as="h1" className={css(homeHeaderStyles.Home_titleText)}>
                  Asset management for a&nbsp;
                  <span
                    className={css(
                      homeHeaderStyles.Home_titleText,
                      homeHeaderStyles.Home_titleTextHighlight,
                    )}
                  >
                    DeFi world
                  </span>
                </Header>

                <Text tag="p" type="regular" className={css(homeHeaderStyles.Home_subText)}>
                  Bring your crypto strategies to life with Set’s leading portfolio management
                  tools.
                </Text>

                <div className={css(styles.Home_buttonsContainer)}>
                  <LinkButton
                    to="/explore"
                    log={{ category: 'Funnel ExplorePage', label: 'HomePage to ExplorePage' }}
                    customStyle={styles.Home_mainCtaButton}
                  >
                    Explore Sets&nbsp;&nbsp;&nbsp;
                  </LinkButton>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

export default HomeHeader;
