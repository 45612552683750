import {
  ERROR_MANAGER_TOKENS,
  FETCH_TOKEN_LIST,
  RECEIVE_MANAGER_TOKENS,
  RECEIVE_TOKEN_LIST,
  REQUEST_MANAGER_TOKENS,
} from '../actions/tokenListsActions';
import { GraphSetToken } from '../typings/index';

interface ITokenListState {
  isFetching: boolean;
  isFetchingManagerTokens: boolean;
  managerTokens: GraphSetToken[];
  fetchManagerTokensError: any;
  tokenLists: {};
}

const initialState: ITokenListState = {
  isFetching: true,
  tokenLists: {},
  isFetchingManagerTokens: true,
  managerTokens: [] as GraphSetToken[],
  fetchManagerTokensError: undefined,
};

const tokenListsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_TOKEN_LIST:
      return {
        ...state,
        isFetching: true,
      };

    case RECEIVE_TOKEN_LIST:
      return {
        ...state,
        isFetching: false,
        tokenLists: {
          ...state.tokenLists,
          ...action.payload,
        },
      };

    case REQUEST_MANAGER_TOKENS:
      return {
        ...state,
        isFetchingManagerTokens: true,
      };

    case RECEIVE_MANAGER_TOKENS:
      return {
        ...state,
        isFetchingManagerTokens: false,
        managerTokens: action.payload,
      };

    case ERROR_MANAGER_TOKENS:
      return {
        ...state,
        isFetchingManagerTokens: false,
        fetchManagerTokensError: action.payload,
      };

    default:
      return state;
  }
};

export default tokenListsReducer;
