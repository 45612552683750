import { toast } from 'react-toastify';

import { submitSetJSTransaction } from '../actions/transactionWatcherActions';
import { batchFetchDebtIssuanceAllowances } from '../actions/approvalActions';
import {
  fetchDebtIssuanceComponents,
  receiveDebtIssuanceComponents,
  receiveDebtIssuanceFees,
  requestDebtIssuanceFees,
  setIsSubmittingIssuanceTransaction,
  updateIssuanceQuantity,
} from '../actions/issuanceV2Actions';
import {
  setJSInstanceSelector,
  setDetailsCurrentSetAddressSelector,
  issuanceInputQuantityV2Selector,
  debtIssuanceModuleEnabledSelector,
  debtIssuanceModuleV2EnabledSelector,
  readOnlySetJSInstanceSelector,
} from '../selectors/baseSelectors';
import {
  debtIssuanceInputComponentsSelector,
  createDebtIssueTransactionArgs,
  maxDebtIssuableTokenQuantitySelector,
} from '../selectors/debtIssuanceSelectors';
import { BigNumber, userRejectedMetamaskTransaction } from '../utils/index';
import { IDebtComponents, IFeeResponse } from '../typings/index';
import { transactionCallerSelector } from '../selectors/transactionSelectors';
import { utils } from 'ethers';

/* Fetch token allowances for debt issuance */
export const fetchDebtIssuanceAllowancesForCurrentSet = () => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const issuanceComponents = debtIssuanceInputComponentsSelector(state);

  if (!issuanceComponents) return;

  dispatch(batchFetchDebtIssuanceAllowances(issuanceComponents));
};

export const getDebtIssuanceRequiredComponents = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const caller = transactionCallerSelector(state);
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);
  const issuanceQuantity = new BigNumber(10).pow(18);
  const debtIssuanceEnabledMap = debtIssuanceModuleEnabledSelector(state);
  const debtIssuanceV2EnabledMap = debtIssuanceModuleV2EnabledSelector(state);
  const readOnlySetJSInstance = readOnlySetJSInstanceSelector(state);

  try {
    dispatch(fetchDebtIssuanceComponents());

    let requiredComponents;
    if (debtIssuanceV2EnabledMap?.[currentSetAddress]) {
      requiredComponents = await readOnlySetJSInstance.debtIssuanceV2.getRequiredComponentIssuanceUnits(
        currentSetAddress,
        // set.js takes a string or `ethers` package BigNumber
        issuanceQuantity.toString() as any,
        caller,
      );
    } else if (debtIssuanceEnabledMap?.[currentSetAddress]) {
      requiredComponents = await readOnlySetJSInstance.debtIssuance.getRequiredComponentIssuanceUnits(
        currentSetAddress,
        // set.js takes a string or `ethers` package BigNumber
        issuanceQuantity.toString() as any,
        caller,
      );
    }

    const componentAddresses = requiredComponents?.[0];
    const equityValues = requiredComponents?.[1];
    const debtValues = requiredComponents?.[2];
    const debtComponents: IDebtComponents = {
      componentAddresses,
      equityValues,
      debtValues,
    };
    dispatch(receiveDebtIssuanceComponents(debtComponents));
  } catch (e) {
    toast.warn('Could not fetch required debt issuance components', {
      toastId: 'fetch-debt-issuance-components',
    });
    console.log('Could not fetch required debt issuance components', e);
    dispatch(receiveDebtIssuanceComponents({}));
  }
};

export const debtIssueCurrentSet = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const setJSInstance = setJSInstanceSelector(state);
  const debtIssuanceEnabledMap = debtIssuanceModuleEnabledSelector(state);
  const debtIssuanceV2EnabledMap = debtIssuanceModuleV2EnabledSelector(state);
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);

  try {
    const debtIssueTransactionArguments = await createDebtIssueTransactionArgs(state);

    if (!debtIssueTransactionArguments) return;

    const debtIssuanceRequest = submitSetJSTransaction(() => {
      if (debtIssuanceV2EnabledMap?.[currentSetAddress]) {
        return setJSInstance.debtIssuanceV2.issueAsync(...debtIssueTransactionArguments);
      } else if (debtIssuanceEnabledMap?.[currentSetAddress]) {
        return setJSInstance.debtIssuance.issueAsync(...debtIssueTransactionArguments);
      }
    });

    dispatch(setIsSubmittingIssuanceTransaction(true));

    dispatch(debtIssuanceRequest);
  } catch (e) {
    if (userRejectedMetamaskTransaction(e)) {
      if (e.message.includes('execution reverted: 11')) {
        toast.warn(
          'Your issuance request could not be completed. There is not enough collateral to cover a new borrow.',
          { toastId: 'issuance-request' },
        );
      } else {
        toast.warn('Your debt issuance request could not be completed. Please try again.', {
          toastId: 'issuance-request',
        });
      }
      return;
    }

    toast.warn('Something went wrong with your issuance request. Please try again later.', {
      toastId: 'issuance-request',
    });
  } finally {
    dispatch(setIsSubmittingIssuanceTransaction(false));
  }
};

/**
 * Set the max issuable quantity the user can mint of the currently shown Fund.
 */
export const setMaxDebtIssuableQuantity = () => (dispatch: any, getState: any) => {
  const state = getState();
  const maxIssuableQuantity = maxDebtIssuableTokenQuantitySelector(state);

  dispatch(updateIssuanceQuantity(maxIssuableQuantity || '0'));
};

/**
 * Fetch the fees for the given set issuance quantity
 */
export const fetchFeesForIssueQuantity = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const setJSInstance = setJSInstanceSelector(state);
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);
  const rawIssuanceQuantity = issuanceInputQuantityV2Selector(state);
  const debtIssuanceV2EnabledMap = debtIssuanceModuleV2EnabledSelector(state);
  const debtIssuanceEnabledMap = debtIssuanceModuleEnabledSelector(state);
  const formattedIssuanceQuantity = utils.parseEther(
    rawIssuanceQuantity?.length ? rawIssuanceQuantity : '0',
  );

  dispatch(requestDebtIssuanceFees());

  try {
    let totalFees: IFeeResponse;
    if (debtIssuanceV2EnabledMap?.[currentSetAddress]) {
      totalFees = await setJSInstance.debtIssuanceV2.calculateTotalFeesAsync(
        currentSetAddress,
        formattedIssuanceQuantity,
        true,
      );
    } else if (debtIssuanceEnabledMap?.[currentSetAddress]) {
      totalFees = await setJSInstance.debtIssuance.calculateTotalFeesAsync(
        currentSetAddress,
        formattedIssuanceQuantity,
        true,
      );
    }

    dispatch(
      receiveDebtIssuanceFees({
        totalQuantity: totalFees.totalQuantity,
        managerFee: totalFees.managerFee,
        managerFeePercentage: new BigNumber(totalFees.managerFee.toString() || 0)
          .div(formattedIssuanceQuantity.toString())
          .mul(100)
          .toString(),
        protocolFee: totalFees.protocolFee,
        protocolFeePercentage: new BigNumber(totalFees.protocolFee.toString() || 0)
          .div(formattedIssuanceQuantity.toString())
          .mul(100)
          .toString(),
      }),
    );

    return totalFees;
  } catch (e) {
    console.log(e);
    toast.warn('Something went wrong with fetching fee values. Please try again later.', {
      toastId: 'fee-request',
    });
  }
};
