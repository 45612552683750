import React from 'react';

import { IFaqSectionContent } from '../typings/index';
import i18n from '../i18n';

const FAQ_CONTENT: { [sectionTitle: string]: { [contentKey: string]: IFaqSectionContent } } = {
  GENERAL: {
    WHAT_IS_A_SET: {
      question: () => i18n.t('faq:what-is-a-set'),
      answer: () => <span>{i18n.t('faq:what-is-a-set-description')}</span>,
    },
    CUSTODY_OF_SET: {
      question: () => i18n.t('faq:custody-of-set'),
      answer: () => (
        <span>
          <strong>{i18n.t('faq:custody-of-set-title')}</strong>
          <br />
          {i18n.t('faq:custody-of-set-description')}
          <br />
          <br />
          <strong>{i18n.t('faq:custody-of-set-title-2')}</strong>
          <br />
          <div
            dangerouslySetInnerHTML={{
              __html: i18n.t('faq:custody-of-set-description-2', {
                vaultContract: `<a
                    href="https://etherscan.io/address/0x5b67871c3a857de81a1ca0f9f7945e5670d986dc#tokentxns"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Set Protocol vault contract.
                  </a>`,
              }),
            }}
          />
        </span>
      ),
    },
    HOW_TO_BUY_AND_SELL: {
      question: () => i18n.t('faq:how-to-buy-and-sell'),
      answer: () => (
        <span>
          {i18n.t('faq:how-to-buy-and-sell-description')}
          <br />
          <br />
          {i18n.t('faq:how-to-buy-and-sell-step-1')}
          <br />
          <br />
          {i18n.t('faq:how-to-buy-and-sell-step-2')}
          <br />
          <br />
          {i18n.t('faq:how-to-buy-and-sell-step-3')}
          <br />
          <br />
          {i18n.t('faq:how-to-buy-and-sell-liquidity')}
        </span>
      ),
    },
    ARE_THERE_FEES: {
      question: () => i18n.t('faq:are-there-fees'),
      answer: () => (
        <span>
          <strong>{i18n.t('faq:are-there-fees-title')}</strong>
          <br />
          <div
            dangerouslySetInnerHTML={{
              __html: i18n.t('faq:are-there-fees-description', {
                discord: `
                  <a
                    href="https://discordapp.com/invite/x3PGVfP"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Discord channel
                  </a>
                `,
                twitter: `
                  <a
                    href="https://twitter.com/setprotocol"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Twitter,
                  </a>
                `,
                medium: `
                  <a
                    href="https://medium.com/set-protocol"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Medium
                  </a>
                `,
              }),
            }}
          />
          <br />
          <br />
          <strong>{i18n.t('faq:rebalancing-slippage')}</strong>
          <br />
          <div
            dangerouslySetInnerHTML={{
              __html: i18n.t('faq:rebalancing-slippage-description', {
                articleLink: `
                  <a
                    href="http://help.tokensets.com/en/articles/3182014-are-there-fees-on-tokensets"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here.
                  </a>
                `,
              }),
            }}
          />
        </span>
      ),
    },
    HOW_TO_VALUE_SET: {
      question: () => i18n.t('faq:how-to-value-set'),
      answer: () => (
        <span>
          <div
            dangerouslySetInnerHTML={{
              __html: i18n.t('faq:how-to-value-set-description', {
                netAssetValue: `
                  <a
                    href="https://www.investopedia.com/terms/n/nav.asp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    'Net Asset Value'
                  </a>
                `,
                makerDao: `
                  <a
                    href="https://developer.makerdao.com/feeds/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    MakerDAO's oracles,
                  </a>
                `,
              }),
            }}
          />
        </span>
      ),
    },
    WHERE_TO_TRADE: {
      question: () => i18n.t('faq:where-to-trade'),
      answer: () => <span>{i18n.t('faq:where-to-trade-description')}</span>,
    },
    TAX_IMPLICATIONS: {
      question: () => i18n.t('faq:tax-implications'),
      answer: () => <span>{i18n.t('faq:tax-implications-description')}</span>,
    },
  },
  TRADING: {
    MINING: {
      question: () => i18n.t('faq:while-mining'),
      answer: () => <span>{i18n.t('faq:while-mining-description')}</span>,
    },
    TRADE_REVERT: {
      question: () => i18n.t('faq:trade-revert'),
      answer: () => <span>{i18n.t('faq:trade-revert-description')}</span>,
    },
    NETWORK_FEES: {
      question: () => i18n.t('faq:network-fees'),
      answer: () => <span>{i18n.t('faq:network-fees-description')}</span>,
    },
    NETWORK_FEE_TRADE_SIZE: {
      question: () => i18n.t('faq:network-fee-trade-size'),
      answer: () => <span>{i18n.t('faq:network-fee-trade-size-description')}</span>,
    },
    TRADE_CHANGE: {
      question: () => i18n.t('faq:trade-change'),
      answer: () => <span>{i18n.t('faq:trade-change-description')}</span>,
    },
  },
  REBALANCING: {
    WHAT_IS_IT: {
      question: () => i18n.t('faq:what-is-rebalancing'),
      answer: () => (
        <span>
          <strong>{i18n.t('faq:overview')}</strong>
          <br />
          <div
            dangerouslySetInnerHTML={{
              __html: i18n.t('faq:what-is-rebalancing-description', {
                rebalanceLink: `
                <a
                  href="http://help.tokensets.com/en/articles/3153142-how-rebalances-work"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here.
                </a>
              `,
              }),
            }}
          />
          <br />
          <br />
          <strong>{i18n.t('faq:rebalancing-liquidity')}</strong>
          <br />
          <div
            dangerouslySetInnerHTML={{
              __html: i18n.t('faq:what-is-rebalancing-description-2', {
                discord: `
                  <a
                    href="https://discordapp.com/invite/x3PGVfP"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Discord.
                  </a>
                `,
              }),
            }}
          />
        </span>
      ),
    },
    WHO_CAN_REBALANCE: {
      question: () => i18n.t('faq:who-can-rebalance'),
      answer: () => <span>{i18n.t('faq:who-can-rebalance-description')}</span>,
    },
    REBALANCING_PROCESS: {
      question: () => i18n.t('faq:rebalance-progress'),
      answer: () => <span>{i18n.t('faq:rebalance-progress-description')}</span>,
    },
    WHEN_TO_REBALANCE: {
      question: () => i18n.t('faq:when-to-rebalance'),
      answer: () => <span>{i18n.t('faq:when-to-rebalance-description')}</span>,
    },
  },
  STRATEGIES: {
    WHAT_IS_BUY_AND_HOLD: {
      question: () => i18n.t('faq:what-is-buy-and-hold'),
      answer: () => (
        <span
          dangerouslySetInnerHTML={{
            __html: i18n.t('faq:what-is-buy-and-hold-description', {
              moreInfo: `
              <a
                href="https://www.investopedia.com/terms/b/buyandhold.asp"
                target="_blank"
                rel="noopener noreferrer"
              >
                here.
              </a>
            `,
            }),
          }}
        />
      ),
    },
    WHEN_TO_BUY_AND_HOLD: {
      question: () => i18n.t('faq:when-to-buy-and-hold'),
      answer: () => <span>{i18n.t('faq:when-to-buy-and-hold-description')}</span>,
    },
    HOW_TO_BUY_AND_HOLD: {
      question: () => i18n.t('faq:how-to-buy-and-hold'),
      answer: () => <span>{i18n.t('faq:how-to-buy-and-hold-description')}</span>,
    },
    WHAT_IS_RANGE_BOUND: {
      question: () => i18n.t('faq:what-is-range-bound'),
      answer: () => (
        <span
          dangerouslySetInnerHTML={{
            __html: i18n.t('faq:what-is-range-bound-description', {
              moreInfo: `
                <a
                  href="https://www.investopedia.com/terms/r/rangeboundtrading.asp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here.
                </a>
              `,
            }),
          }}
        />
      ),
    },
    WHEN_TO_RANGE_BOUND: {
      question: () => i18n.t('faq:when-to-range-bound'),
      answer: () => <span>{i18n.t('faq:when-to-range-bound-description')}</span>,
    },
    HOW_TO_RANGE_BOUND: {
      question: () => i18n.t('faq:how-to-range-bound'),
      answer: () => <span>{i18n.t('faq:how-to-range-bound-description')}</span>,
    },
    DIFFERENCE_IN_VOLATILITY: {
      question: () => i18n.t('faq:difference-in-volatility'),
      answer: () => (
        <span
          dangerouslySetInnerHTML={{
            __html: i18n.t('faq:difference-in-volatility-description', {
              impliedVolatility: `
                <a
                  href="https://www.investopedia.com/terms/i/iv.asp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  implied volatility.
                </a>
              `,
            }),
          }}
        />
      ),
    },
    WHAT_IS_TREND_TRADING: {
      question: () => i18n.t('faq:what-is-trend-trading'),
      answer: () => (
        <span
          dangerouslySetInnerHTML={{
            __html: i18n.t('faq:what-is-trend-trading-description', {
              moreInfo: `
              <a
                href="https://www.investopedia.com/terms/t/trendtrading.asp"
                target="_blank"
                rel="noopener noreferrer"
              >
                here.
              </a>
            `,
            }),
          }}
        />
      ),
    },
    WHEN_TO_TREND_TRADING: {
      question: () => i18n.t('faq:when-to-trend-trading'),
      answer: () => <span>{i18n.t('faq:when-to-trend-trading-description')}</span>,
    },
    HOW_TO_TREND_TRADING: {
      question: () => i18n.t('faq:how-to-trend-trading'),
      answer: () => <span>{i18n.t('faq:how-to-trend-trading-description')}</span>,
    },
  },
  OTHER: {
    FUTURE_SUPPORT: {
      question: () => i18n.t('faq:future-support'),
      answer: () => (
        <span
          dangerouslySetInnerHTML={{
            __html: i18n.t('faq:future-support-description', {
              discord: `
                <a
                  href="https://discordapp.com/invite/x3PGVfP"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Discord.
                </a>
              `,
            }),
          }}
        />
      ),
    },
    HOW_TO_BUILD_ON_SET: {
      question: () => i18n.t('faq:how-to-build-on-set'),
      answer: () => (
        <span
          dangerouslySetInnerHTML={{
            __html: i18n.t('faq:how-to-build-on-set-description', {
              developerPlatform: `
                <a
                  href="https://docs.setprotocol.com/#/tutorials#create-a-rebalancing-set"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Set Developer Platform
                </a>
              `,
              discord: `
                <a
                  href="https://discordapp.com/invite/x3PGVfP"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Discord
                </a>
              `,
            }),
          }}
        />
      ),
    },
    SECURITY: {
      question: () => i18n.t('faq:how-secure-is-tokensets'),
      answer: () => (
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: i18n.t('faq:how-secure-is-tokensets-description', {
                chainSecurity: `
                <a
                  href="https://www.setprotocol.com/pdf/chain_security_set_protocol_report.pdf?source=post_page---------------------------"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ChainSecurity audit report
                </a>
              `,
                trailOfBits: `
                <a
                  href="https://www.setprotocol.com/pdf/trail_of_bits_set_protocol_report.pdf?source=post_page---------------------------"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Trail of Bits audit report.
                </a>
              `,
              }),
            }}
          />
          <br />
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: i18n.t('faq:how-secure-is-tokensets-description-2', {
                github: `
                  <a
                    href="https://github.com/SetProtocol/set-protocol-contracts"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    (Link to Set Protocol Contracts Github)
                  </a>
                `,
                whitepaper: `
                  <a
                    href="https://www.setprotocol.com/pdf/set_protocol_whitepaper.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Set Whitepaper.
                  </a>
                `,
              }),
            }}
          />
        </div>
      ),
    },
    EMAIL_SUBMISSION: {
      question: () => i18n.t('faq:email-submission'),
      answer: () => <span>{i18n.t('faq:email-submission-description')}</span>,
    },
    SET_CREATOR: {
      question: () => i18n.t('faq:set-creator'),
      answer: () => (
        <span
          dangerouslySetInnerHTML={{
            __html: i18n.t('faq:set-creator-description', {
              email: `
                <a href="mailto:hello@setprotocol.com" rel="noopener noreferrer">
                  hello@setprotocol.com
                </a>
              `,
              discord: `
                <a
                  href="https://discordapp.com/invite/x3PGVfP"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Discord.
                </a>
              `,
            }),
          }}
        />
      ),
    },
    AUCTION_PARTICIPATION: {
      question: () => i18n.t('faq:auction-participation'),
      answer: () => <span>{i18n.t('faq:auction-participation-description')}</span>,
    },
    TOKENSETS_SET_PROTOCOL: {
      question: () => i18n.t('faq:tokensets-set-protocol'),
      answer: () => (
        <span
          dangerouslySetInnerHTML={{
            __html: i18n.t('faq:tokensets-set-protocol-description', {
              setProtocol: `
                <a href="https://www.setprotocol.com" target="_blank" rel="noopener noreferrer">
                  here
                </a>
              `,
            }),
          }}
        />
      ),
    },
    SET_LABS_SET_PROTOCOL: {
      question: () => i18n.t('faq:set-labs-set-protocol'),
      answer: () => (
        <span
          dangerouslySetInnerHTML={{
            __html: i18n.t('faq:set-labs-set-protocol-description', {
              setProtocol: `
                <a href="https://www.setprotocol.com" target="_blank" rel="noopener noreferrer">
                  here
                </a>
              `,
            }),
          }}
        />
      ),
    },
    SET_LABS_SOCIAL_TRADER: {
      question: () => i18n.t('faq:set-labs-social-trader'),
      answer: () => <span>{i18n.t('faq:set-labs-social-trader-description')}</span>,
    },
  },
};

export default FAQ_CONTENT;
