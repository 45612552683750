import { connect } from 'react-redux';

import CustomSetExploreTable from './CustomSetExploreTable';
import { fetchDeployedSetsWithLogos } from '../../actions/deployedSetsActions';
import {
  currentChainOrDefaultSelector,
  customV2SetPathPrefixSelector,
  exploreHeaderSelector,
  isPolygonSelector,
} from '../../selectors/web3Selectors';
import {
  deployedSetDetailsHydratedSelector,
  isFetchingDeployedSetDetailsSelector,
  isFetchingTokenListSelector,
  vAssetDisplayInfosSelector,
} from '../../selectors/baseSelectors';
import {
  coingeckoTokenListByAddressSelector,
  coingeckoTokenListBySymbolSelector,
  tokenSetsTokenListByAddressSelector,
} from '../../selectors/tokenListsSelectors';
import { fetchEnabledModules } from '../../actions/setDetailsActions';
import { fetchAllPerpLeverageData } from '../../actions/perpLeverageActions';
import { handleSwitchChain } from '../../actions/web3Actions';

const mapStateToProps = (state: any) => {
  const {
    web3: { account },
    windowDimension,
  } = state;

  const isPolygon = isPolygonSelector(state);
  const isFetchingDeployedSetDetails = isFetchingDeployedSetDetailsSelector(state);
  const hydratedSetDetails = deployedSetDetailsHydratedSelector(state);
  const tokenSetsTokenListByAddress = tokenSetsTokenListByAddressSelector(state);
  const coingeckoTokenList = coingeckoTokenListByAddressSelector(state);
  const exploreHeader = exploreHeaderSelector(state);
  const customV2SetPathPrefix = customV2SetPathPrefixSelector(state);
  const vAssetDisplayInfo = vAssetDisplayInfosSelector(state);
  const coingeckoTokenListBySymbol = coingeckoTokenListBySymbolSelector(state);
  const currentChain = currentChainOrDefaultSelector(state);
  const isFetchingTokenList = isFetchingTokenListSelector(state);

  return {
    isFetchingTokenList,
    currentChain,
    coingeckoTokenListBySymbol,
    exploreHeader,
    customV2SetPathPrefix,
    isPolygon,
    isFetchingDeployedSetDetails,
    account,
    windowDimension,
    tokenSetsTokenListByAddress,
    setDetails: hydratedSetDetails,
    coingeckoTokenList,
    vAssetDisplayInfo,
  };
};

const mapDispatchToProps = {
  fetchDeployedSetsWithLogos,
  fetchAllPerpLeverageData,
  onFetchEnabledModules: fetchEnabledModules,
  handleSwitchChain,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomSetExploreTable);
