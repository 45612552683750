import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Loader } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { toChecksumAddress } from 'tochecksum';

import { IWindowDimensions, IHistory, IListToken } from '../../typings/index';
import { WINDOW_DIMENSIONS, COLORS } from '../../constants/index';
import { ApprovalStatus } from './enums';
import checkMark from '../../img/icons/check-green-bg.svg';
import darkArrow from '../../img/icons/right-arrow-dark.svg';
import { getListTokenImage } from '../../utils/formatUtils';

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  IssuanceApproval_container: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '40px',
  },
  IssuanceApproval_title: {
    fontSize: '30px',
    fontWeight: 600,
    color: COLORS.darkBlue,
    lineHeight: 1.2,
    textAlign: 'center',
    marginBottom: '20px',
  },
  IssuanceApproval_subTitle: {
    fontSize: '14px',
    lineHeight: 1.5,
    textAlign: 'center',
  },
  IssuanceApproval_allComponentsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    marginTop: '20px',
    [MOBILE_MEDIA_QUERY]: {
      width: '100%',
    },
  },
  IssuanceApproval_componentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 0',
    borderBottom: `1px solid ${COLORS.gray}`,
  },
  IssuanceApproval_lastComponentContainer: {
    borderBottom: 'none',
  },
  IssuanceApproval_componentHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  IssuanceApproval_componentImage: {
    width: '25px',
    height: '25px',
  },
  IssuanceApproval_componentName: {
    color: COLORS.darkBlue,
    fontWeight: 500,
    marginLeft: '10px',
    fontSize: '14px',
  },
  IssuanceApproval_componentFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  IssuanceApproval_componentStatusText: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: 1,
    marginTop: '3px',
    textAlign: 'right',
  },
  IssuanceApproval_componentUnapprovedStatusText: {
    color: COLORS.darkGray,
  },
  IssuanceApproval_componentPendingStatusText: {
    color: COLORS.blue,
  },
  IssuanceApproval_componentApprovedStatusText: {
    color: COLORS.green,
  },
  IssuanceApproval_componentApprovedStatusIcon: {
    width: '20px',
    height: '20px',
    marginLeft: '15px',
  },
  IssuanceApproval_componentPendingStatusIcon: {
    width: '20px',
    height: '20px',
    marginLeft: '15px',
  },
  IssuanceApproval_componentUnapprovedStatusIcon: {
    width: '20px',
    height: '20px',
    marginLeft: '15px',
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '50%',
    ':hover': {
      cursor: 'pointer',
    },
  },
  IssuanceApproval_approveButton: {
    width: '100%',
    fontWeight: 500,
    marginTop: '20px',
  },
  IssuanceApproval_approveButtonDisabled: {
    pointerEvents: 'none',
    backgroundColor: COLORS.gray,
  },
  IssuanceApproval_backButton: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '40px',
    height: '40px',
    transform: 'rotate(180deg)',
    padding: '10px',
    margin: '20px',
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '50%',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: COLORS.lightGray,
    },
  },
});

type IssuanceApprovalProps = {
  customV2SetPathPrefix: string;
  setAddress: string;
  isAnyApprovalPending: boolean;
  requiredComponents: IListToken[];
  allApprovalStatusesByTokenId: { [tokenId: string]: ApprovalStatus };
  history: IHistory;
  windowDimensions: IWindowDimensions;
  approveAllListTokensForCurrentSet: (...args: any[]) => any;
  approveListTokenAllowance: (...args: any[]) => any;
};

/**
 * @title IssuanceApproval
 * @author Set Protocol
 *
 * Approval form for multiple tokens prior to fund issuance.
 */
class IssuanceApproval extends PureComponent<IssuanceApprovalProps & WithTranslation> {
  public getApprovalStatus = (component: IListToken) => {
    const { approveListTokenAllowance, allApprovalStatusesByTokenId, t } = this.props;

    const approvalStatus = allApprovalStatusesByTokenId[toChecksumAddress(component.address)];

    switch (approvalStatus) {
      case ApprovalStatus.APPROVED:
        return (
          <div className={css(styles.IssuanceApproval_componentFooter)}>
            <span
              className={css(
                styles.IssuanceApproval_componentStatusText,
                styles.IssuanceApproval_componentApprovedStatusText,
              )}
            >
              {t('approval-status.approved')}
            </span>
            <img
              className={css(styles.IssuanceApproval_componentApprovedStatusIcon)}
              src={checkMark}
            />
          </div>
        );

      case ApprovalStatus.PENDING:
        return (
          <div className={css(styles.IssuanceApproval_componentFooter)}>
            <span
              className={css(
                styles.IssuanceApproval_componentStatusText,
                styles.IssuanceApproval_componentPendingStatusText,
              )}
            >
              {t('approval-status.pending')}
            </span>
            <Loader
              className={css(styles.IssuanceApproval_componentPendingStatusIcon)}
              active
              inline
              size="tiny"
            />
          </div>
        );

      case ApprovalStatus.UNAPPROVED:
        return (
          <div className={css(styles.IssuanceApproval_componentFooter)}>
            <span
              className={css(
                styles.IssuanceApproval_componentStatusText,
                styles.IssuanceApproval_componentUnapprovedStatusText,
              )}
            >
              {t('approval-status.unstarted')}
            </span>
            <div
              className={css(styles.IssuanceApproval_componentUnapprovedStatusIcon)}
              onClick={() => approveListTokenAllowance(component)}
            />
          </div>
        );

      default:
        return null;
    }
  };

  public renderComponents = (): any => {
    const { requiredComponents } = this.props;

    return requiredComponents?.map(
      (component: IListToken, index: number, collection: IListToken[]) => {
        const isLastElement = index === collection.length - 1;

        return (
          <div
            key={component.address}
            className={css(
              styles.IssuanceApproval_componentContainer,
              isLastElement && styles.IssuanceApproval_lastComponentContainer,
            )}
          >
            <div className={css(styles.IssuanceApproval_componentHeader)}>
              <img
                className={css(styles.IssuanceApproval_componentImage)}
                src={getListTokenImage(component)}
              />
              <span className={css(styles.IssuanceApproval_componentName)}>{component.symbol}</span>
            </div>

            {this.getApprovalStatus(component)}
          </div>
        );
      },
    );
  };

  render() {
    const {
      isAnyApprovalPending,
      customV2SetPathPrefix,
      setAddress,
      history,
      approveAllListTokensForCurrentSet,
      t,
    } = this.props;

    return (
      <div className={css(styles.IssuanceApproval_container)}>
        <img
          src={darkArrow}
          className={css(styles.IssuanceApproval_backButton)}
          onClick={() => history.push(`${customV2SetPathPrefix}/${setAddress}`)}
        />
        <p className={css(styles.IssuanceApproval_title)}>{t('issue-approve.title')}</p>
        <p className={css(styles.IssuanceApproval_subTitle)}>{t('issue-approve.description')}</p>
        <div className={css(styles.IssuanceApproval_allComponentsContainer)}>
          {this.renderComponents()}
        </div>
        <button
          className={css(
            styles.IssuanceApproval_approveButton,
            isAnyApprovalPending && styles.IssuanceApproval_approveButtonDisabled,
          )}
          type="button"
          disabled={isAnyApprovalPending}
          onClick={approveAllListTokensForCurrentSet}
        >
          {t('buttons.approve-all')}
        </button>
      </div>
    );
  }
}

export default withTranslation('issue-and-redeem')(IssuanceApproval);
