import {
  WETH_ADDRESS_ARBITRUM,
  WETH_ADDRESS_ETHEREUM,
  WETH_ADDRESS_OPTIMISM,
  WETH_ADDRESS_POLYGON,
  WMATIC_ADDRESS_POLYGON,
  WAVAX_ADDRESS_AVALANCHE,
} from './tokenListConstants';

export const UNISWAP_ROUTER_ADDRESS = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';
export const SUSHISWAP_ROUTER_ADDRESS = '0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F';
export const UNISWAP_MAX_SLIPPAGE_BEFORE_WARNING = 10; // 10.00% slippage
export const UNISWAP_POOL_TYPE = 'UNISWAP';
export const SUSHISWAP_POOL_TYPE = 'SUSHISWAP';

export const AVALANCHE_EXCHANGE_TRADING_PAIRS = [
  {
    id: 'tether',
    name: 'Tether',
    symbol: 'USDT',
    price_usd: '',
    price_eth: '',
    address: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    decimals: 6,
    image_url:
      'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/usdt.svg',
  },
  {
    id: 'avalanche-2',
    name: 'Avalanche',
    symbol: 'AVAX',
    price_usd: '',
    price_eth: '',
    address: WAVAX_ADDRESS_AVALANCHE,
    decimals: 18,
    image_url: 'https://cryptologos.cc/logos/avalanche-avax-logo.svg',
  },
  {
    id: 'weth',
    name: 'Wrapped Eth',
    symbol: 'WETH',
    price_usd: '',
    price_eth: '',
    address: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
    decimals: 18,
    image_url: 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/eth.svg',
  },
  {
    id: 'usdc',
    name: 'USD Coin',
    symbol: 'USDC.e',
    price_usd: '',
    price_eth: '',
    address: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    decimals: 6,
    image_url:
      'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/usdc.svg',
  },
  {
    id: 'mcd',
    name: 'Dai',
    symbol: 'DAI.e',
    price_usd: '',
    price_eth: '',
    address: '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
    decimals: 18,
    image_url:
      'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/mcd.svg',
  },
];

export const OPTIMISM_EXCHANGE_TRADING_PAIRS = [
  {
    id: 'tether',
    name: 'Tether',
    symbol: 'USDT',
    price_usd: '',
    price_eth: '',
    address: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
    decimals: 6,
    image_url:
      'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/usdt.svg',
  },
  {
    id: 'wrapped_bitcoin',
    name: 'Bitcoin',
    symbol: 'WBTC',
    price_usd: '',
    price_eth: '',
    address: '0x68f180fcCe6836688e9084f035309E29Bf0A2095',
    decimals: 8,
    image_url: 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/btc.svg',
  },
  {
    id: 'weth',
    name: 'Wrapped Eth',
    symbol: 'WETH',
    price_usd: '',
    price_eth: '',
    address: WETH_ADDRESS_OPTIMISM,
    decimals: 18,
    image_url: 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/eth.svg',
  },
  {
    id: 'usdc',
    name: 'USD',
    symbol: 'USDC',
    price_usd: '',
    price_eth: '',
    address: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
    decimals: 6,
    image_url:
      'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/usdc.svg',
  },
  {
    id: 'mcd',
    name: 'Dai',
    symbol: 'DAI',
    price_usd: '',
    price_eth: '',
    address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    decimals: 18,
    image_url:
      'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/mcd.svg',
  },
];

export const ARBITRUM_EXCHANGE_TRADING_PAIRS = [
  {
    id: 'tether',
    name: 'Tether',
    symbol: 'USDT',
    price_usd: '',
    price_eth: '',
    address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    decimals: 6,
    image_url:
      'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/usdt.svg',
  },
  {
    id: 'wrapped-bitcoin',
    name: 'Bitcoin',
    symbol: 'WBTC',
    price_usd: '',
    price_eth: '',
    address: '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
    decimals: 8,
    image_url: 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/btc.svg',
  },
  {
    id: 'weth',
    name: 'Wrapped Eth',
    symbol: 'WETH',
    price_usd: '',
    price_eth: '',
    address: WETH_ADDRESS_ARBITRUM,
    decimals: 18,
    image_url: 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/eth.svg',
  },
  {
    id: 'usd-coin',
    name: 'USD',
    symbol: 'USDC',
    price_usd: '',
    price_eth: '',
    address: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
    decimals: 6,
    image_url:
      'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/usdc.svg',
  },
  {
    id: 'dai',
    name: 'Dai',
    symbol: 'DAI',
    price_usd: '',
    price_eth: '',
    address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    decimals: 18,
    image_url:
      'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/mcd.svg',
  },
];

export const POLYGON_EXCHANGE_TRADING_PAIRS = [
  {
    id: 'tether',
    name: 'Tether',
    symbol: 'USDT',
    price_usd: '',
    price_eth: '',
    address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    decimals: 6,
    image_url:
      'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/usdt.svg',
  },
  {
    id: 'weth',
    name: 'Wrapped Eth',
    symbol: 'WETH',
    price_usd: '',
    price_eth: '',
    address: WETH_ADDRESS_POLYGON,
    decimals: 18,
    image_url: 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/eth.svg',
  },
  {
    id: 'matic-network',
    name: 'Matic',
    symbol: 'MATIC',
    price_usd: '',
    price_eth: '',
    address: WMATIC_ADDRESS_POLYGON,
    decimals: 18,
    image_url: 'https://cryptologos.cc/logos/polygon-matic-logo.svg?v=022',
  },
  {
    id: 'usd-coin',
    name: 'USD',
    symbol: 'USDC',
    price_usd: '',
    price_eth: '',
    address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    decimals: 6,
    image_url:
      'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/usdc.svg',
  },
  {
    id: 'dai',
    name: 'Dai',
    symbol: 'DAI',
    price_usd: '',
    price_eth: '',
    address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    decimals: 18,
    image_url:
      'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/mcd.svg',
  },
];

export const ETHEREUM_EXCHANGE_TRADING_PAIRS = [
  {
    id: 'tether',
    name: 'Tether',
    symbol: 'USDT',
    price_usd: '1.0',
    price_eth: '0.00038617',
    address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    decimals: 6,
    image_url:
      'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/usdt.svg',
  },
  {
    id: 'wrapped-bitcoin',
    name: 'Bitcoin',
    symbol: 'WBTC',
    price_usd: '40511',
    price_eth: '15.638645',
    address: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    decimals: 8,
    image_url: 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/btc.svg',
  },
  {
    id: 'weth',
    name: 'Wrapped Eth',
    symbol: 'WETH',
    price_usd: '2600.39',
    price_eth: '1.002582',
    address: WETH_ADDRESS_ETHEREUM,
    decimals: 18,
    image_url: 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/eth.svg',
  },
  {
    id: 'usd-coin',
    name: 'USD',
    symbol: 'USDC',
    price_usd: '1.0',
    price_eth: '0.00038681',
    address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    decimals: 6,
    image_url:
      'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/usdc.svg',
  },
  {
    id: 'dai',
    name: 'Dai',
    symbol: 'DAI',
    price_usd: '1.0',
    price_eth: '0.00038703',
    address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    decimals: 18,
    image_url:
      'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/mcd.svg',
  },
];
