import NETWORK_CONSTANTS from './networkConstants';
const {
  MAIN_NET_ID,
  ROPSTEN_ID,
  KOVAN_ID,
  POLYGON_MAINNET_ID,
  POLYGON_MUMBAI_ID,
  ARBITRUM_MAINNET_ID,
  ARBITRUM_RINKEBY_ID,
  OPTIMISM_MAINNET_ID,
  OPTIMISM_KOVAN_ID,
  AVALANCHE_FUJI_ID,
  AVALANCHE_MAINNET_ID,
} = NETWORK_CONSTANTS;

const BLOCKEXPLORER_URL = {
  [MAIN_NET_ID]: 'https://www.etherscan.io',
  [ROPSTEN_ID]: 'https://ropsten.etherscan.io',
  [KOVAN_ID]: 'https://kovan.etherscan.io',
  [POLYGON_MAINNET_ID]: 'https://polygonscan.com',
  [POLYGON_MUMBAI_ID]: 'https://explorer-mumbai.maticvigil.com',
  [ARBITRUM_MAINNET_ID]: 'https://arbiscan.io',
  [ARBITRUM_RINKEBY_ID]: 'https://testnet.arbiscan.io',
  [OPTIMISM_MAINNET_ID]: 'https://optimistic.etherscan.io',
  [OPTIMISM_KOVAN_ID]: 'https://kovan-optimistic.etherscan.io',
  [AVALANCHE_FUJI_ID]: 'https://testnet.snowtrace.io',
  [AVALANCHE_MAINNET_ID]: 'https://snowtrace.io',
};

export const BLOCKEXPLORER_NAME = {
  [MAIN_NET_ID]: 'Etherscan',
  [ROPSTEN_ID]: 'Etherscan',
  [KOVAN_ID]: 'Etherscan',
  [POLYGON_MAINNET_ID]: 'PolygonScan',
  [POLYGON_MUMBAI_ID]: 'PolygonScan',
  [ARBITRUM_MAINNET_ID]: 'Arbiscan',
  [ARBITRUM_RINKEBY_ID]: 'Arbiscan',
  [OPTIMISM_MAINNET_ID]: 'Optimistic Etherscan',
  [OPTIMISM_KOVAN_ID]: 'Optimistic Etherscan',
  [AVALANCHE_FUJI_ID]: 'Avalanche C-Chain Snowtrace',
  [AVALANCHE_MAINNET_ID]: 'Avalanche C-Chain Snowtrace',
};

export default BLOCKEXPLORER_URL;
