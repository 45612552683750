import { css } from 'aphrodite';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Loader, Dimmer } from 'semantic-ui-react';

import styles from './SignUpOptionsStyles';
import { MinimalNavBar, LoginOptionContainer } from '../../components/index';
import { IWindowDimensions } from '../../typings/index';
import { setLedgerPath as setLedgerPathAction } from '../../actions/web3Actions';
import { routerHistory } from '../../utils/index';

interface ISignUpOptionsProps {
  readonly windowDimension?: IWindowDimensions;
  readonly setLedgerPath?: (...args: any[]) => any;
}

interface ISignUpOptionsState {
  redirecting: boolean;
  isExpanded: boolean;
  isLedgerExpanded: boolean;
}

class SignUpOptions extends PureComponent<ISignUpOptionsProps, ISignUpOptionsState> {
  constructor(props: ISignUpOptionsProps) {
    super(props);

    this.state = {
      redirecting: false,
      isExpanded: false,
      isLedgerExpanded: false,
    };
  }
  /**
   * Lifecycle methods
   */
  componentDidMount() {
    document.title = 'Sign Up | TokenSets';
  }

  render() {
    const {
      windowDimension: { isMobile },
      setLedgerPath,
    } = this.props;
    const { redirecting, isExpanded, isLedgerExpanded } = this.state;

    return (
      <div>
        <MinimalNavBar view="Sign Up" />
        {
          <div className={css(styles.SignUpOptions_desktopOuterContainer)}>
            <div className={css(styles.SignUpOptions_desktopContainer)}>
              <div className={css(styles.SignUpOptions_centerAlignVertical)}>
                <div>
                  <Dimmer active={redirecting}>
                    <Loader active>Redirecting...</Loader>
                  </Dimmer>
                  <LoginOptionContainer
                    setLedgerPath={setLedgerPath}
                    isExpanded={isExpanded}
                    isLedgerExpanded={isLedgerExpanded}
                    toggleExpanded={() =>
                      this.setState(prevState => {
                        return { isExpanded: !prevState.isExpanded };
                      })
                    }
                    toggleLedgerExpanded={() =>
                      this.setState(prevState => {
                        return { isLedgerExpanded: !prevState.isLedgerExpanded };
                      })
                    }
                    isMobile={isMobile}
                    handleActionAfterLogin={() => {
                      this.setState({ redirecting: true });
                      setTimeout(() => {
                        routerHistory.push('/');
                      }, 2000);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  windowDimension: state.windowDimension,
});

const mapDispatchToProps = {
  setLedgerPath: setLedgerPathAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)((SignUpOptions as any) as React.SFC<ISignUpOptionsProps>);
