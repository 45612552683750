import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite';

import { Button } from '../../components/index';
import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';

const styles = StyleSheet.create({
  notice: {
    paddingTop: '15px',
    paddingBottom: '15px',
    [WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY]: {
      paddingBottom: '10px',
    },
  },
  text: {
    paddingLeft: '5px',
    paddingRight: '15px',
    color: `${COLORS.blue}`,
    fontSize: '14px',
    [WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY]: {
      paddingLeft: '0',
      display: 'block',
    },
  },
});

class V2Notice extends PureComponent {
  render() {
    return (
      <div className={css(styles.notice)}>
        <span className={css(styles.text)}>
          <b>TokenSets V2</b>
        </span>
        <span className={css(styles.text)}>
          Create your own Sets with the new TokenSets V2 coming soon
        </span>
        <span className={css(styles.text)}>
          <Button
            externalHref="https://www.setprotocol.com"
            text="Learn More"
            style={{
              container: {
                padding: '5px 25px',
                [WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY]: {
                  display: 'block',
                  margin: '20px auto 15px',
                },
              },
              text: {
                fontWeight: 'normal',
                fontSize: '14px',
              },
            }}
          />
        </span>
      </div>
    );
  }
}

export default V2Notice;
