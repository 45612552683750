import Web3 from 'web3';
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import { mainnet } from 'wagmi/chains';

import { infuraKey } from '../utils/providers';

/**
 * Attempt to setup wallet connect. Called on the login modal.
 * If wallet connect is not initialized must be awaited to show QR code + allow login.
 * @param chainId - Network Id to connect to.
 */
export const setupWalletConnectAsync = async () => {
  const chains = [mainnet];
  const projectId = '2262d12a95e916dbeb4c378b6c67157b'; // TokenSets WalletConnect Project ID

  const provider = await EthereumProvider.init({
    projectId, // REQUIRED your projectId
    chains: chains.map(c => c.id), // REQUIRED chain ids
    showQrModal: true, // REQUIRED set to "true" to use @web3modal/standalone,
    methods: ['eth_sendTransaction', 'eth_signTransaction'], // OPTIONAL ethereum methods
    events: ['accountsChanged', 'chainChanged'], // OPTIONAL ethereum events
    rpcMap: {
      1: `https://mainnet.infura.io/v3/${infuraKey}`,
    },
  });
  await provider.enable();

  const web3Instance = new Web3(provider as any);

  return {
    provider,
    web3Instance,
  };
};
