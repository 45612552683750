import { css, StyleSheet } from 'aphrodite';
import React, { PureComponent, Fragment } from 'react';
import { Container, Divider, Grid, Placeholder } from 'semantic-ui-react';

import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';
import { IWindowDimensions } from '../../typings/index';

const { TABLET_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  SetAllocationSectionPlaceholder_centerAlignBlock: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  SetAllocationSectionPlaceholder_divider: {
    width: '100%',
    margin: '15px auto 0',
  },
  SetAllocationSectionPlaceholder_grid: {
    marginLeft: '0',
    marginRight: '0',
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '4px',
    paddingTop: '20px',
  },
  SetAllocationSectionPlaceholder_gridRow: {
    padding: '10px 30px',
    margin: '10px auto',
  },
  SetAllocationSectionPlaceholder_gridRowSubHeader: {
    paddingTop: '5px',
    paddingBottom: '5px',
    marginTop: '15px',
    marginBottom: '15px',
    paddingLeft: '30px',
    backgroundColor: COLORS.lightGray,
    fontWeight: 500,
    color: COLORS.darkGray,
  },
  SetAllocationSectionPlaceholder_gridColumn: {
    paddingLeft: '0',
    paddingRight: '0',
    display: 'table',
  },
  SetAllocationSectionPlaceholder_container: {
    [TABLET_MEDIA_QUERY]: {
      marginLeft: '0',
      marginRight: '0',
    },
  },
});

interface ISetAllocationSectionPlaceholderProps {
  windowDimension: IWindowDimensions;
}

/**
 * @title SetAllocationSectionPlaceholder
 * @author Set Labs
 *
 * The SetAllocationSectionPlaceholder is placeholder for SetAllocationSection component
 */
class SetAllocationSectionPlaceholder extends PureComponent<ISetAllocationSectionPlaceholderProps> {
  public renderGridHeader = () => {
    const {
      windowDimension: { isTabletLandscape },
    } = this.props;

    if (isTabletLandscape) {
      return (
        <Fragment>
          {/* Table Header */}
          <Grid.Row columns={2} className={css(styles.SetAllocationSectionPlaceholder_gridRow)}>
            <Grid.Column
              className={css(styles.SetAllocationSectionPlaceholder_gridColumn)}
              textAlign="left"
              width={10}
            >
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line />
                </Placeholder.Header>
              </Placeholder>
            </Grid.Column>
            <Grid.Column textAlign="right" width={6}>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line />
                </Placeholder.Header>
              </Placeholder>
            </Grid.Column>
            <Divider className={css(styles.SetAllocationSectionPlaceholder_divider)} />
          </Grid.Row>

          {/* Set Summary */}
          <Grid.Row columns={2} className={css(styles.SetAllocationSectionPlaceholder_gridRow)}>
            <Grid.Column
              className={css(styles.SetAllocationSectionPlaceholder_gridColumn)}
              textAlign="left"
              width={10}
            >
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line />
                </Placeholder.Header>
              </Placeholder>
            </Grid.Column>

            <Grid.Column textAlign="right" width={6}>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line />
                </Placeholder.Header>
              </Placeholder>
            </Grid.Column>
          </Grid.Row>

          {/* Divider */}
          <Grid.Row
            columns={2}
            className={css(styles.SetAllocationSectionPlaceholder_gridRowSubHeader)}
          >
            <Grid.Column
              className={css(styles.SetAllocationSectionPlaceholder_gridColumn)}
              textAlign="left"
              width={16}
            ></Grid.Column>
          </Grid.Row>
        </Fragment>
      );
    }

    return (
      <Fragment>
        {/* Table Header */}
        <Grid.Row columns={2} className={css(styles.SetAllocationSectionPlaceholder_gridRow)}>
          <Grid.Column
            className={css(styles.SetAllocationSectionPlaceholder_gridColumn)}
            textAlign="left"
            width={4}
          >
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </Grid.Column>
          <Grid.Column textAlign="left" width={2}>
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </Grid.Column>
          <Grid.Column textAlign="left" width={2}>
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </Grid.Column>
          <Grid.Column textAlign="left" width={2}>
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </Grid.Column>
          <Grid.Column textAlign="left" width={2}>
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </Grid.Column>
          <Grid.Column textAlign="left" width={2}>
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </Grid.Column>
          <Grid.Column textAlign="right" width={2}>
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </Grid.Column>
          <Divider className={css(styles.SetAllocationSectionPlaceholder_divider)} />
        </Grid.Row>

        {/*  Set Summary */}
        <Grid.Row columns={2} className={css(styles.SetAllocationSectionPlaceholder_gridRow)}>
          <Grid.Column
            className={css(styles.SetAllocationSectionPlaceholder_gridColumn)}
            textAlign="left"
            width={12}
          >
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </Grid.Column>
          <Grid.Column textAlign="right" width={2}>
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </Grid.Column>
          <Grid.Column textAlign="right" width={2}>
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </Grid.Column>
        </Grid.Row>

        {/* Divider */}
        <Grid.Row
          columns={2}
          className={css(styles.SetAllocationSectionPlaceholder_gridRowSubHeader)}
        >
          <Grid.Column
            className={css(styles.SetAllocationSectionPlaceholder_gridColumn)}
            textAlign="left"
            width={16}
          ></Grid.Column>
        </Grid.Row>
      </Fragment>
    );
  };

  public renderGridBody = () => {
    const {
      windowDimension: { isTabletLandscape },
    } = this.props;

    return Array(5)
      .fill(0)
      .map((_num, i) => {
        if (isTabletLandscape) {
          return (
            <React.Fragment key={`tablet-body-${i}`}>
              <Grid.Row columns={2} className={css(styles.SetAllocationSectionPlaceholder_gridRow)}>
                <Grid.Column
                  className={css(styles.SetAllocationSectionPlaceholder_gridColumn)}
                  textAlign="left"
                  width={10}
                >
                  <Placeholder>
                    <Placeholder.Header>
                      <Placeholder.Line />
                    </Placeholder.Header>
                  </Placeholder>
                </Grid.Column>
                <Grid.Column textAlign="right" width={6}>
                  <Placeholder>
                    <Placeholder.Header>
                      <Placeholder.Line />
                    </Placeholder.Header>
                  </Placeholder>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1} className={css(styles.SetAllocationSectionPlaceholder_gridRow)}>
                <Grid.Column className={css(styles.SetAllocationSectionPlaceholder_gridColumn)}>
                  <Placeholder>
                    <Placeholder.Header>
                      <Placeholder.Line length={'long'} />
                    </Placeholder.Header>
                  </Placeholder>
                </Grid.Column>
              </Grid.Row>
            </React.Fragment>
          );
        }

        return (
          <React.Fragment key={`body-${i}`}>
            <Grid.Row columns={2} className={css(styles.SetAllocationSectionPlaceholder_gridRow)}>
              <Grid.Column
                className={css(styles.SetAllocationSectionPlaceholder_gridColumn)}
                textAlign="left"
                width={4}
              >
                <Placeholder>
                  <Placeholder.Header>
                    <Placeholder.Line />
                  </Placeholder.Header>
                </Placeholder>
              </Grid.Column>
              <Grid.Column textAlign="left" width={2}></Grid.Column>
              <Grid.Column textAlign="left" width={2}>
                <Placeholder>
                  <Placeholder.Header>
                    <Placeholder.Line />
                  </Placeholder.Header>
                </Placeholder>
              </Grid.Column>
              <Grid.Column textAlign="left" width={2}>
                <Placeholder>
                  <Placeholder.Header>
                    <Placeholder.Line />
                  </Placeholder.Header>
                </Placeholder>
              </Grid.Column>
              <Grid.Column textAlign="left" width={2}>
                <Placeholder>
                  <Placeholder.Header>
                    <Placeholder.Line />
                  </Placeholder.Header>
                </Placeholder>
              </Grid.Column>
              <Grid.Column textAlign="right" width={2}>
                <Placeholder>
                  <Placeholder.Header>
                    <Placeholder.Line />
                  </Placeholder.Header>
                </Placeholder>
              </Grid.Column>
              <Grid.Column textAlign="right" width={2}>
                <Placeholder>
                  <Placeholder.Header>
                    <Placeholder.Line />
                  </Placeholder.Header>
                </Placeholder>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1} className={css(styles.SetAllocationSectionPlaceholder_gridRow)}>
              <Grid.Column className={css(styles.SetAllocationSectionPlaceholder_gridColumn)}>
                <Placeholder>
                  <Placeholder.Header>
                    <Placeholder.Line length={'very long'} />
                  </Placeholder.Header>
                </Placeholder>
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>
        );
      });
  };

  render() {
    const {
      windowDimension: { isTablet },
    } = this.props;

    const ContainerElement = isTablet ? 'div' : Container;

    return (
      <ContainerElement className={css(styles.SetAllocationSectionPlaceholder_container)}>
        <div className={css(styles.SetAllocationSectionPlaceholder_centerAlignBlock)}>
          <Grid className={css(styles.SetAllocationSectionPlaceholder_grid)}>
            {this.renderGridHeader()}
            {this.renderGridBody()}
          </Grid>
        </div>
      </ContainerElement>
    );
  }
}

export default SetAllocationSectionPlaceholder;
