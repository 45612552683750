import React, { useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { COLORS } from '../../constants/index';

const White = () => (
  <svg
    aria-hidden="true"
    role="img"
    width="1em"
    height="1em"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 1536 1536"
  >
    <path
      fill={COLORS.white}
      d="M1511 928q0 5-1 7q-64 268-268 434.5T764 1536q-146 0-282.5-55T238 1324l-129 129q-19 19-45 19t-45-19t-19-45V960q0-26 19-45t45-19h448q26 0 45 19t19 45t-19 45l-137 137q71 66 161 102t187 36q134 0 250-65t186-179q11-17 53-117q8-23 30-23h192q13 0 22.5 9.5t9.5 22.5zm25-800v448q0 26-19 45t-45 19h-448q-26 0-45-19t-19-45t19-45l138-138Q969 256 768 256q-134 0-250 65T332 500q-11 17-53 117q-8 23-30 23H50q-13 0-22.5-9.5T18 608v-7q65-268 270-434.5T768 0q146 0 284 55.5T1297 212l130-129q19-19 45-19t45 19t19 45z"
    />
  </svg>
);

const Blue = () => (
  <svg
    aria-hidden="true"
    role="img"
    width="1em"
    height="1em"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 1536 1536"
  >
    <path
      fill={COLORS.darkBlue2}
      d="M1511 928q0 5-1 7q-64 268-268 434.5T764 1536q-146 0-282.5-55T238 1324l-129 129q-19 19-45 19t-45-19t-19-45V960q0-26 19-45t45-19h448q26 0 45 19t19 45t-19 45l-137 137q71 66 161 102t187 36q134 0 250-65t186-179q11-17 53-117q8-23 30-23h192q13 0 22.5 9.5t9.5 22.5zm25-800v448q0 26-19 45t-45 19h-448q-26 0-45-19t-19-45t19-45l138-138Q969 256 768 256q-134 0-250 65T332 500q-11 17-53 117q-8 23-30 23H50q-13 0-22.5-9.5T18 608v-7q65-268 270-434.5T768 0q146 0 284 55.5T1297 212l130-129q19-19 45-19t45 19t19 45z"
    />
  </svg>
);

const styles = StyleSheet.create({
  Button: {
    height: '50px',
    width: '250px',
    padding: 0,
    border: `1px solid ${COLORS.darkBlue2}`,
    backgroundColor: COLORS.white,
  },
  Hover: {
    ':hover': {
      backgroundColor: COLORS.darkBlue2,
    },
  },
  Flex: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '16px',
  },
  ImgContainer: {
    height: '100%',
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  Label: {
    fontWeight: 600,
    color: COLORS.darkBlue2,
    lineHeight: '36px',
  },
  Disabled: {
    backgroundColor: COLORS.darkGray,
    color: COLORS.white,
    cursor: 'not-allowed',
    border: 'none',
    ':hover': {
      backgroundColor: COLORS.darkGray,
    },
  },
  White: {
    color: COLORS.white,
  },
  Blue: {
    color: COLORS.darkBlue2,
  },
});

interface RefreshButtonProps {
  disabled?: boolean;
  label?: string;
  img?: string;
  onClick?: (...args: any[]) => any;
}

export default function RefreshButton({ disabled, onClick }: RefreshButtonProps) {
  const [isHovering, setIsHovering] = useState(false);
  const showHover = () => setIsHovering(true);
  const hideHover = () => setIsHovering(false);

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={css(styles.Button, styles.Hover, disabled && styles.Disabled)}
    >
      <div className={css(styles.Flex)} onMouseEnter={showHover} onMouseLeave={hideHover}>
        <div className={css(styles.ImgContainer)}>
          {isHovering || disabled ? <White /> : <Blue />}
        </div>
        <span className={css(styles.Label, isHovering || disabled ? styles.White : styles.Blue)}>
          Fetch Trade Quotes
        </span>
      </div>
    </button>
  );
}
