import React, { Fragment } from 'react';
import { css } from 'aphrodite';
import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { toChecksumAddress } from 'tochecksum';

import SetTokensPlaceholder from './SetTokensPlaceholder';
import { Text } from '../../components/index';
import {
  IWindowDimensions,
  ISetDetailsHydrated,
  IListToken,
  GraphSetToken,
} from '../../typings/index';
import { createAnimationDelay } from './utils';
import { styles } from './Account';
import { BigNumber, truncateTokenAmount } from '../../utils/index';
import Web3 from 'web3';
import { toFixed2Locale, tokenFromBaseUnits } from '../../utils/formatUtils';
import { tokenListNameOverride } from '../../constants/tokenListConstants';

interface IManagedSetsProps {
  customV2SetPathPrefix: string;
  isFetchingApiBalances: boolean;
  deployedSetDetailsHydrated: { [address: string]: ISetDetailsHydrated };
  tokenSetsTokenListByAddress: { [address: string]: IListToken };
  windowDimension: IWindowDimensions;
  managerTokens: GraphSetToken[];
  onToggleExpandedView: (...args: any[]) => any;
}

class ManagedSets extends React.Component<IManagedSetsProps & WithTranslation> {
  public renderToken = (tokenAddress: string, tableRowIndex: number, isLastRow: boolean) => {
    const {
      windowDimension: { isMobile },
      deployedSetDetailsHydrated,
      tokenSetsTokenListByAddress,
      managerTokens,
      customV2SetPathPrefix,
    } = this.props;
    const checksumAddress = Web3.utils.toChecksumAddress(tokenAddress);
    const address = tokenAddress.toLowerCase();
    const setDetails = deployedSetDetailsHydrated[checksumAddress];
    const token = managerTokens.find(token => token.address === address);
    const marketCap = setDetails?.marketCap || 0;
    const totalSupply = new BigNumber(setDetails?.totalSupply || 0).toString();

    const alignment = isMobile ? 'right' : 'left';
    const borderStyles = isLastRow ? undefined : styles.Account_setListRowBorder;
    const animationDelay = createAnimationDelay(tableRowIndex)[
      `Account_rowAnimationDelay${tableRowIndex}`
    ];

    const path = `${customV2SetPathPrefix}/${tokenAddress}`;

    return (
      <Grid.Row
        className={css(
          styles.Account_setListRow,
          borderStyles,
          styles.Account_animatedRow,
          animationDelay,
        )}
        key={tableRowIndex}
      >
        <Grid.Column
          as={Link}
          className={
            isMobile
              ? css(styles.Account_cellContentContainer, styles.Account_cellContentContainerLeft)
              : css(styles.Account_cellContentContainer)
          }
          width={isMobile ? 9 : 7}
          to={path}
        >
          <div className={css(styles.Account_setIconContainer)}>
            <img
              className={css(styles.Account_setIcon)}
              onError={ev =>
                ((ev.target as any).src =
                  'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-token-icon.svg')
              }
              src={
                setDetails?.logoURI ||
                'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-token-icon.svg'
              }
              alt="token logo"
            />
          </div>
          <Text
            color="darkBlue"
            className={css(styles.Account_setTableText, styles.Account_setName)}
          >
            {tokenListNameOverride[address] ||
              tokenSetsTokenListByAddress[address]?.name ||
              token.name}
          </Text>
        </Grid.Column>

        {!isMobile && (
          <Grid.Column
            className={css(styles.Account_priceChangeBadgeContainer)}
            width={3}
          ></Grid.Column>
        )}

        <Grid.Column
          className={
            isMobile
              ? css(styles.Account_cellContentContainerRight)
              : css(styles.Account_cellContentContainer)
          }
          floated={alignment}
          textAlign={alignment}
          width={isMobile ? 7 : 3}
        >
          <Text
            color="darkBlue"
            fontWeight={isMobile ? 500 : undefined}
            tag="p"
            className={css(styles.Account_setTableText)}
          >
            ${toFixed2Locale(new BigNumber(marketCap))}
          </Text>
          {isMobile && (
            <Fragment>
              <Text className={css(styles.Account_setTableText)} color="darkGray" tag="p">
                {truncateTokenAmount(tokenFromBaseUnits(totalSupply).toString())}
              </Text>
            </Fragment>
          )}
        </Grid.Column>

        {!isMobile && (
          <Grid.Column
            className={css(styles.Account_cellContentContainer)}
            floated={alignment}
            textAlign={alignment}
            width={3}
          >
            <Text color="darkBlue" tag="p" className={css(styles.Account_setTableText)}>
              {truncateTokenAmount(tokenFromBaseUnits(totalSupply).toString())}
            </Text>
          </Grid.Column>
        )}
      </Grid.Row>
    );
  };

  public renderManagedSets = () => {
    const {
      windowDimension: { isMobile },
      tokenSetsTokenListByAddress,
      deployedSetDetailsHydrated,
      managerTokens,
    } = this.props;

    const alignment = isMobile ? 'right' : 'left';
    const tokenAddresses = Object.keys(tokenSetsTokenListByAddress);
    const validTokenAddresses = tokenAddresses.filter(address => {
      return deployedSetDetailsHydrated[toChecksumAddress(address)];
    });

    return (
      <>
        <Grid.Row className={css(styles.Account_setListRowBorder)}>
          <Grid.Column
            className={isMobile ? css(styles.Account_cellContentContainerLeft) : ''}
            width={7}
          >
            <b>Managed Sets</b>
          </Grid.Column>
          {!isMobile && (
            <Grid.Column floated={alignment} textAlign={alignment} width={3}></Grid.Column>
          )}
          <Grid.Column
            className={css(styles.Account_cellContentContainerRight)}
            floated={alignment}
            textAlign={alignment}
            width={isMobile ? 6 : 3}
          >
            <p>Total Managed</p>
          </Grid.Column>
          {!isMobile && (
            <Grid.Column floated={alignment} textAlign={alignment} width={3}>
              <p>Total Supply</p>
            </Grid.Column>
          )}
        </Grid.Row>

        {managerTokens.map((token, index) => {
          const isLastSet = index === validTokenAddresses.length - 1;
          const tableRowIndex = index + 1;
          return this.renderToken(token.address, tableRowIndex, isLastSet);
        })}
      </>
    );
  };

  render() {
    const {
      isFetchingApiBalances,
      windowDimension,
      windowDimension: { isMobile },
    } = this.props;

    if (isFetchingApiBalances) {
      return (
        <div>
          <SetTokensPlaceholder windowDimension={windowDimension} />
        </div>
      );
    }

    return (
      <div>
        <Grid columns={isMobile ? 3 : 4} className={css(styles.Account_setBalancesGrid)}>
          {this.renderManagedSets()}
        </Grid>
      </div>
    );
  }
}

export default withTranslation('account')(ManagedSets);
