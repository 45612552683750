import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { withTranslation, WithTranslation } from 'react-i18next';

import { ITokenBalances, IMatch, IHistory, IListTokenWithPosition } from '../../typings/index';
import { WINDOW_DIMENSIONS, COLORS, STYLES } from '../../constants/index';
import { getListTokenImage, tokenFromBaseUnits } from '../../utils/formatUtils';
import BigNumber from '../../utils/bigNumber';
import darkArrow from '../../img/icons/right-arrow-dark.svg';

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  RedemptionForm_container: {
    maxWidth: '400px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [MOBILE_MEDIA_QUERY]: {},
  },
  RedemptionForm_setImage: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    marginBottom: '20px',
  },
  RedemptionForm_title: {
    fontSize: '30px',
    fontWeight: 600,
    color: COLORS.darkBlue,
    lineHeight: 1.2,
    textAlign: 'center',
    marginBottom: '20px',
  },
  RedemptionForm_subTitle: {
    fontSize: '14px',
    lineHeight: 1.5,
    textAlign: 'center',
    marginBottom: '20px',
  },
  RedemptionForm_balanceTitle: {
    color: COLORS.darkBlue,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1.5,
    textAlign: 'center',
  },
  RedemptionForm_balance: {
    color: COLORS.darkBlue,
    fontFamily: STYLES.monoFont,
    fontSize: '32px',
    fontWeight: 500,
    lineHeight: 1.5,
    marginBottom: '25px',
    textAlign: 'center',
  },

  /* Input Field */
  RedemptionForm_redeemInputContainer: {
    width: '100%',
    position: 'relative',
    marginBottom: '20px',
  },
  RedemptionForm_redeemQuantityInput: {
    width: '100%',
    fontSize: '16px',
    padding: '15px',
    paddingRight: '120px',
    marginBottom: '20px',
    border: `2px solid ${COLORS.gray}`,
    borderRadius: '4px',
    ':focus': {
      borderColor: COLORS.blue,
    },
  },
  RedemptionForm_redeemInputLabelContainer: {
    position: 'absolute',
    top: 15,
    right: 16,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  RedemptionForm_redeemInputSymbol: {
    fontSize: '12px',
    color: COLORS.blue,
    fontWeight: 500,
    lineHeight: 1,
  },
  RedemptionForm_redeemMaxButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '22px',
    fontSize: '9px',
    marginLeft: '10px',
    padding: '11px 12px 10px 12px',
    ':active': {
      backgroundColor: COLORS.blueDarkened,
    },
  },

  /* Redemption Components Table Header */
  RedemptionForm_tableHeader: {
    display: 'flex',
    paddingBottom: '10px',
    borderBottom: `1px solid ${COLORS.gray}`,
  },
  RedemptionForm_tokenLabel: {
    fontSize: '10px',
    lineHeight: 1.5,
    flex: 1,
  },
  RedemptionForm_requiredAmountLabel: {
    fontSize: '10px',
    lineHeight: 1.5,
    flex: 1,
    textAlign: 'right',
  },
  RedemptionForm_userBalanceLabel: {
    fontSize: '10px',
    lineHeight: 1.5,
    flex: 1,
    textAlign: 'right',
  },

  /* Redemption Components */
  RedemptionForm_componentContainer: {
    width: '100%',
    marginBottom: '30px',
  },
  RedemptionForm_component: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
    borderBottom: `1px solid ${COLORS.lightGray}`,
  },
  RedemptionForm_componentLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  RedemptionForm_componentIcon: {
    height: '20px',
    width: '20px',
  },
  RedemptionForm_componentSymbol: {
    fontSize: '14px',
    color: COLORS.darkBlue,
    fontWeight: 600,
    marginLeft: '10px',
  },

  RedemptionForm_componentQuantityRequired: {
    fontSize: '14px',
    color: COLORS.darkBlue,
    flex: 1,
    textAlign: 'right',
  },
  RedemptionForm_componentUserBalance: {
    fontSize: '14px',
    color: COLORS.green,
    flex: 1,
    textAlign: 'right',
  },

  /* Submit Button + Footer */
  RedemptionForm_submitButton: {
    width: '100%',
    padding: '20px',
    fontWeight: 600,
    marginBottom: '30px',
    backgroundColor: COLORS.blue,
    color: COLORS.white,
    ':hover': {
      backgroundColor: COLORS.blueDarkened,
    },
  },
  RedemptionForm_submitButtonDisabled: {
    pointerEvents: 'none',
    backgroundColor: COLORS.gray,
    ':hover': {
      backgroundColor: COLORS.gray,
    },
  },
  RedemptionForm_footerText: {
    textAlign: 'center',
    fontSize: '12px',
    marginBottom: '15px',
    color: COLORS.red,
  },

  RedemptionForm_backButton: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '40px',
    height: '40px',
    transform: 'rotate(180deg)',
    padding: '10px',
    margin: '20px',
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '50%',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: COLORS.lightGray,
    },
  },
});

type RedemptionFormProps = {
  customV2SetPathPrefix: string;
  address: string;
  name: string;
  symbol: string;
  isRedemptionPermitted: boolean;
  isSubmittingRedemptionTransaction: boolean;
  redemptionQuantity: string;
  requiredComponents: IListTokenWithPosition[];
  erc20BalancesByAddress: ITokenBalances;
  match: IMatch;
  history: IHistory;
  fetchERC20Balance: (...args: any[]) => any;
  updateRedemptionQuantity: (...args: any[]) => any;
  setMaxRedeemableQuantity: (...args: any[]) => any;
  redeemCurrentSet: (...args: any[]) => any;
  resetTransactionWatcher: (...args: any[]) => any;
};

/**
 * @title RedemptionForm
 * @author Set Protocol
 *
 * RedemptionForm is the interface users use to input a desired redemption quantity, view the
 * underlying component quantities they'll get back, and to initiate the redemption transaction.
 */
class RedemptionForm extends PureComponent<RedemptionFormProps & WithTranslation> {
  public onUpdateRedemptionQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { updateRedemptionQuantity } = this.props;
    const value = e.target?.value;

    updateRedemptionQuantity(value);
  };

  public renderBalance = () => {
    const { address, symbol, erc20BalancesByAddress, t } = this.props;

    const userBalance = tokenFromBaseUnits(erc20BalancesByAddress[address]?.toString() || '0');

    return (
      <div>
        <p className={css(styles.RedemptionForm_balanceTitle)}>
          {t('redeem-form.your-balance', { tokenSymbol: symbol })}
        </p>
        <p className={css(styles.RedemptionForm_balance)}>{userBalance?.toFixed(8)}</p>
      </div>
    );
  };

  public renderReceivableTokens = () => {
    const { requiredComponents, redemptionQuantity, t } = this.props;

    const redeemedComponentsList = requiredComponents?.map((component: IListTokenWithPosition) => {
      const unitsInOneSet = component?.unit || new BigNumber(0);
      const formattedTokenQuantityPerSet = tokenFromBaseUnits(
        unitsInOneSet.toString(),
        component?.decimals,
      );
      const receiveQuantity = formattedTokenQuantityPerSet?.mul(redemptionQuantity || 0);

      return (
        <div className={css(styles.RedemptionForm_component)} key={component.address}>
          <div className={css(styles.RedemptionForm_componentLabelContainer)}>
            <img
              className={css(styles.RedemptionForm_componentIcon)}
              src={getListTokenImage(component)}
            />
            <span className={css(styles.RedemptionForm_componentSymbol)}>{component.symbol}</span>
          </div>
          <span className={css(styles.RedemptionForm_componentQuantityRequired)}>
            {formattedTokenQuantityPerSet.toFixed(8)}
          </span>
          <span className={css(styles.RedemptionForm_componentUserBalance)}>
            {receiveQuantity.toFixed(8)}
          </span>
        </div>
      );
    });

    return (
      <div className={css(styles.RedemptionForm_componentContainer)}>
        <div className={css(styles.RedemptionForm_tableHeader)}>
          <span className={css(styles.RedemptionForm_tokenLabel)}>{t('table.token')}</span>
          <span className={css(styles.RedemptionForm_requiredAmountLabel)}>
            {t('table.amount-per-set')}
          </span>
          <span className={css(styles.RedemptionForm_userBalanceLabel)}>{t('table.receive')}</span>
        </div>
        {redeemedComponentsList}
      </div>
    );
  };

  render() {
    const {
      address,
      name,
      symbol,
      redemptionQuantity,
      isRedemptionPermitted,
      isSubmittingRedemptionTransaction,
      history,
      redeemCurrentSet,
      setMaxRedeemableQuantity,
      customV2SetPathPrefix,
      t,
    } = this.props;

    const isPositiveRedemptionQuantity = redemptionQuantity && Number(redemptionQuantity) > 0;

    return (
      <div className={css(styles.RedemptionForm_container)}>
        <img
          src={darkArrow}
          className={css(styles.RedemptionForm_backButton)}
          onClick={() => history.push(`${customV2SetPathPrefix}/${address}`)}
        />
        <img
          className={css(styles.RedemptionForm_setImage)}
          src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/redeem.svg"
        />
        <p className={css(styles.RedemptionForm_title)}>
          {t('redeem-form.title', { tokenName: name })}
        </p>
        <p className={css(styles.RedemptionForm_subTitle)}>
          {t('redeem-form.description', { tokenSymbol: symbol })}
        </p>

        {this.renderBalance()}

        <div className={css(styles.RedemptionForm_redeemInputContainer)}>
          <input
            className={css(styles.RedemptionForm_redeemQuantityInput)}
            type="number"
            value={redemptionQuantity}
            onChange={this.onUpdateRedemptionQuantity}
          />
          <div className={css(styles.RedemptionForm_redeemInputLabelContainer)}>
            <span className={css(styles.RedemptionForm_redeemInputSymbol)}>{symbol}</span>
            <button
              className={css(styles.RedemptionForm_redeemMaxButton)}
              type="button"
              onClick={setMaxRedeemableQuantity}
            >
              MAX
            </button>
          </div>
        </div>

        {this.renderReceivableTokens()}

        <button
          className={css(
            styles.RedemptionForm_submitButton,
            (!isRedemptionPermitted ||
              isSubmittingRedemptionTransaction ||
              !isPositiveRedemptionQuantity) &&
              styles.RedemptionForm_submitButtonDisabled,
          )}
          disabled={
            !isRedemptionPermitted ||
            isSubmittingRedemptionTransaction ||
            !isPositiveRedemptionQuantity
          }
          type="button"
          onClick={redeemCurrentSet}
        >
          {t('buttons.redeem')}
        </button>

        {!isRedemptionPermitted && (
          <>
            <span className={css(styles.RedemptionForm_footerText)}>
              {t('redeem-form.error-title')}{' '}
              {t('redeem-form.error-description', { tokenName: name })}
            </span>
          </>
        )}
      </div>
    );
  }
}

export default withTranslation('issue-and-redeem')(RedemptionForm);
