import {
  REQUEST_BALANCES,
  RECEIVE_BALANCES,
  RECEIVE_BALANCES_FAILED,
  UPDATE_ACCOUNT_BALANCE,
  SET_IS_FETCHING_BALANCE_STATUS,
  UPDATE_ERC_20_BALANCE,
  UPDATE_SET_TOKEN_BALANCE,
  REQUEST_ERC20_ALLOWANCE,
  RECEIVE_ERC20_ALLOWANCE,
  SET_IS_APPROVING_ALLOWANCE_STATUS,
  RECEIVE_FUND_ONCHAIN_BALANCE,
  RECEIVE_COIN_ONCHAIN_BALANCE,
} from '../actions/balanceActions';
import { IBalances, ITokenBalances } from '../typings/index';

export interface IBalanceReducerState {
  hasFetchedEthBalance: boolean;
  isFetchingBalance: boolean;
  isFetchingAllowance: boolean;
  isApprovingERC20Token: boolean;
  accountBalance: string;
  apiBalances: IBalances;
  setBalances: ITokenBalances;
  erc20Balances: ITokenBalances;
  erc20Allowances: ITokenBalances;
}

const initialState: IBalanceReducerState = {
  accountBalance: '',
  hasFetchedEthBalance: false,
  isFetchingBalance: true,
  isFetchingAllowance: false,
  isApprovingERC20Token: false,
  apiBalances: {
    rebalancing_set_balances: [],
    fund_balances: [],
    coin_balances: [],
    total_balance_usd: '',
    eth_balance: '',
    eth_balance_usd: '',
  },
  setBalances: {},
  erc20Balances: {},
  erc20Allowances: {},
};

const balanceReducer = (state = initialState, action: any): IBalanceReducerState => {
  const { apiBalances } = state;

  switch (action.type) {
    case REQUEST_BALANCES:
      return {
        ...state,
        isFetchingBalance: true,
      };
    case RECEIVE_BALANCES:
      return {
        ...state,
        apiBalances: action.payload,
        isFetchingBalance: false,
      };
    case RECEIVE_BALANCES_FAILED:
      return {
        ...state,
        isFetchingBalance: false,
      };
    case UPDATE_ACCOUNT_BALANCE:
      return {
        ...state,
        hasFetchedEthBalance: true,
        accountBalance: action.payload,
      };

    // Annotate fetched funds and sets with user onchain balances

    case RECEIVE_FUND_ONCHAIN_BALANCE:
      const withFundOnchainBalances = {
        ...apiBalances,
        fund_balances: action.payload,
      };

      return {
        ...state,
        apiBalances: withFundOnchainBalances,
      };

    case RECEIVE_COIN_ONCHAIN_BALANCE:
      const withCoinOnchainBalances = {
        ...apiBalances,
        coin_balances: action.payload,
      };

      return {
        ...state,
        apiBalances: withCoinOnchainBalances,
      };

    // ERC-20 and Set Token Balances
    case SET_IS_FETCHING_BALANCE_STATUS:
      return {
        ...state,
        isFetchingBalance: action.payload,
      };
    case UPDATE_SET_TOKEN_BALANCE: {
      const setBalances = {
        ...state.setBalances,
        ...action.payload,
      };

      return {
        ...state,
        setBalances,
      };
    }
    case UPDATE_ERC_20_BALANCE: {
      const erc20Balances = {
        ...state.erc20Balances,
        ...action.payload,
      };

      return {
        ...state,
        erc20Balances,
      };
    }

    // ERC-20 Transfer Proxy Allowances
    case REQUEST_ERC20_ALLOWANCE:
      return {
        ...state,
        isFetchingAllowance: true,
      };
    case RECEIVE_ERC20_ALLOWANCE: {
      if (!action.payload) {
        return { ...state, isFetchingAllowance: false };
      }

      const erc20Allowances = {
        ...state.erc20Allowances,
        ...action.payload,
      };

      return {
        ...state,
        isFetchingAllowance: false,
        erc20Allowances,
      };
    }

    case SET_IS_APPROVING_ALLOWANCE_STATUS:
      return {
        ...state,
        isApprovingERC20Token: action.payload,
      };
    default:
      return state;
  }
};

export default balanceReducer;
