import { connect } from 'react-redux';

import { fetchAllOnchainBalances, fetchBalances } from '../../actions/balanceActions';
import {
  fetchDeployedSetsWithLogos,
  fetchOwnedSetAddresses,
} from '../../actions/deployedSetsActions';
import { fetchManagerTokens, fetchTokenSetsTokenList } from '../../actions/tokenListsActions';

import {
  accountSelector,
  windowDimensionSelector,
  networkIdSelector,
  isFetchingDeployedSetDetailsSelector,
  deployedSetDetailsHydratedSelector,
  isPolygonSelector,
  providerTypeSelector,
  erc20BalancesSelector,
  isFetchingApiBalancesSelector,
  accountBalanceSelector,
  isFetchingManagerTokensSelector,
  managerTokensSelector,
  portfolioPathPrefixSelector,
  chainNameSelector,
  currentChainOrDefaultSelector,
  customV2SetPathPrefixSelector,
  networkBadgeSelector,
  isWrongNetworkSelector,
} from '../../selectors/index';
import Account from './Account';
import {
  communityAndOwnedAndFeaturedSetsSelector,
  geminiTokenListByAddressSelector,
  tokenSetsTokenListByAddressSelector,
  tokenSetsTokenListUrlSelector,
} from '../../selectors/tokenListsSelectors';
import { networkListTokenWithBalanceSelector } from '../../selectors/balanceSelectors';
import { fetchFeaturedTokenSetsTokenList } from '../../actions/tokenListsActions';

const mapStateToProps = (state: any) => {
  const account = accountSelector(state);
  const networkId = networkIdSelector(state);
  const windowDimension = windowDimensionSelector(state);
  const isFetchingDeployedSetDetails = isFetchingDeployedSetDetailsSelector(state);
  const deployedSetDetailsHydrated = deployedSetDetailsHydratedSelector(state);
  const portfolioPathPrefix = portfolioPathPrefixSelector(state);
  const isPolygon = isPolygonSelector(state);
  const tokenSetsTokenListByAddress = tokenSetsTokenListByAddressSelector(state);
  const geminiTokenListByAddress = geminiTokenListByAddressSelector(state);
  const providerType = providerTypeSelector(state);
  const erc20Balances = erc20BalancesSelector(state);
  const isFetchingApiBalance = isFetchingApiBalancesSelector(state);
  const accountBalance = accountBalanceSelector(state);
  const networkListTokenWithBalance = networkListTokenWithBalanceSelector(state);
  const isFetchingManagerTokens = isFetchingManagerTokensSelector(state);
  const managerTokens = managerTokensSelector(state);
  const chainName = chainNameSelector(state);
  const currentChain = currentChainOrDefaultSelector(state);
  const tokenSetsTokenListUrl = tokenSetsTokenListUrlSelector(state);
  const customV2SetPathPrefix = customV2SetPathPrefixSelector(state);
  const networkBadge = networkBadgeSelector(state);
  const isWrongNetwork = isWrongNetworkSelector(state);
  const communityAndOwnedAndFeaturedSets = communityAndOwnedAndFeaturedSetsSelector(state);

  return {
    communityAndOwnedAndFeaturedSets,
    isWrongNetwork,
    networkBadge,
    customV2SetPathPrefix,
    tokenSetsTokenListUrl,
    currentChain,
    chainName,
    portfolioPathPrefix,
    isFetchingManagerTokens,
    managerTokens,
    networkListTokenWithBalance,
    accountBalance,
    isPolygon,
    isFetchingApiBalance,
    providerType,
    account,
    networkId,
    windowDimension,
    isFetchingDeployedSetDetails,
    deployedSetDetailsHydrated,
    tokenSetsTokenListByAddress,
    geminiTokenListByAddress,
    erc20Balances,
  };
};

const mapDispatchToProps = {
  fetchManagerTokens,
  fetchAllOnchainBalances,
  fetchDeployedSetsWithLogos,
  fetchOwnedSetAddresses,
  fetchTokenSetsTokenList,
  fetchBalances,
  fetchFeaturedTokenSetsTokenList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
