import { toast } from 'react-toastify';

import { submitSetJSTransaction } from '../actions/transactionWatcherActions';
import { batchFetchDebtIssuanceAllowances } from '../actions/approvalActions';
import {
  fetchDebtRedemptionComponents,
  receiveDebtRedemptionComponents,
  receiveDebtRedemptionFees,
  requestDebtRedemptionFees,
  setIsSubmittingRedemptionTransaction,
  updateRedemptionQuantity,
} from '../actions/redemptionV2Actions';
import {
  setJSInstanceSelector,
  setDetailsCurrentSetAddressSelector,
  redemptionInputQuantityV2Selector,
  debtIssuanceModuleEnabledSelector,
  debtIssuanceModuleV2EnabledSelector,
  readOnlySetJSInstanceSelector,
} from '../selectors/baseSelectors';
import {
  debtRedemptionInputComponentsSelector,
  createDebtRedeemTransactionArgs,
} from '../selectors/debtRedemptionSelectors';
import { currentSetDetailsAsListTokenSelector } from '../selectors/setDetailsSelectors';
import { maxDebtRedeemableTokenQuantitySelector } from '../selectors/debtRedemptionSelectors';
import { BigNumber, userRejectedMetamaskTransaction } from '../utils/index';
import { IDebtComponents, IFeeResponse } from '../typings/index';
import i18n from '../i18n';
import { transactionCallerSelector } from '../selectors/transactionSelectors';
import { utils } from 'ethers';

export const getDebtRedemptionRequiredComponents = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const readOnlySetJSInstance = readOnlySetJSInstanceSelector(state);
  const caller = transactionCallerSelector(state);
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);
  const debtIssuanceEnabledMap = debtIssuanceModuleEnabledSelector(state);
  const debtIssuanceV2EnabledMap = debtIssuanceModuleV2EnabledSelector(state);
  const redemptionQuantity = new BigNumber(10).pow(18);

  try {
    dispatch(fetchDebtRedemptionComponents());
    let requiredComponents;
    if (debtIssuanceV2EnabledMap?.[currentSetAddress]) {
      requiredComponents = await readOnlySetJSInstance.debtIssuanceV2.getRequiredComponentRedemptionUnits(
        currentSetAddress,
        // set.js takes a string or `ethers` package BigNumber
        redemptionQuantity.toString() as any,
        caller,
      );
    } else if (debtIssuanceEnabledMap?.[currentSetAddress]) {
      requiredComponents = await readOnlySetJSInstance.debtIssuance.getRequiredComponentRedemptionUnits(
        currentSetAddress,
        // set.js takes a string or `ethers` package BigNumber
        redemptionQuantity.toString() as any,
        caller,
      );
    }

    const componentAddresses = requiredComponents?.[0];
    const equityValues = requiredComponents?.[1];
    const debtValues = requiredComponents?.[2];
    const debtComponents: IDebtComponents = {
      componentAddresses,
      equityValues,
      debtValues,
    };
    dispatch(receiveDebtRedemptionComponents(debtComponents));
  } catch (e) {
    console.log('Could not fetch required debt issuance components', e);
    dispatch(receiveDebtRedemptionComponents({}));
  }
};

/* Fetch token allowances for debt redemption tokens */
export const fetchDebtRedemptionAllowancesForCurrentSet = () => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const currentSet: any = currentSetDetailsAsListTokenSelector(state);
  const redemptionComponents = debtRedemptionInputComponentsSelector(state);

  dispatch(batchFetchDebtIssuanceAllowances(redemptionComponents.concat(currentSet)));
};

export const debtRedeemCurrentSet = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const setJSInstance = setJSInstanceSelector(state);
  const debtIssuanceEnabledMap = debtIssuanceModuleEnabledSelector(state);
  const debtIssuanceV2EnabledMap = debtIssuanceModuleV2EnabledSelector(state);
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);

  try {
    const debtRedeemTransactionArguments = await createDebtRedeemTransactionArgs(state);

    if (!debtRedeemTransactionArguments) return;

    const debtRedemptionRequest = submitSetJSTransaction(() => {
      if (debtIssuanceV2EnabledMap?.[currentSetAddress]) {
        return setJSInstance.debtIssuanceV2.redeemAsync(...debtRedeemTransactionArguments);
      } else if (debtIssuanceEnabledMap?.[currentSetAddress]) {
        return setJSInstance.debtIssuance.redeemAsync(...debtRedeemTransactionArguments);
      }
    });

    dispatch(setIsSubmittingRedemptionTransaction(true));
    dispatch(debtRedemptionRequest);
  } catch (e) {
    if (userRejectedMetamaskTransaction(e)) return;

    toast(i18n.t('issue-and-redeem:redeem-action.redeem-failed'));
  } finally {
    dispatch(setIsSubmittingRedemptionTransaction(false));
  }
};

/**
 * Set the max issuable quantity the user can mint of the currently shown Fund.
 */
export const setMaxDebtRedeemableQuantity = () => (dispatch: any, getState: any) => {
  const state = getState();
  const maxRedeemableQuantity = maxDebtRedeemableTokenQuantitySelector(state);

  dispatch(updateRedemptionQuantity(maxRedeemableQuantity || '0'));
};

/**
 * Fetch the fees for the given set redemption quantity
 */
export const fetchFeesForRedeemQuantity = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const setJSInstance = setJSInstanceSelector(state);
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);
  const rawRedemptionQuantity = redemptionInputQuantityV2Selector(state);
  const debtIssuanceV2EnabledMap = debtIssuanceModuleV2EnabledSelector(state);
  const debtIssuanceEnabledMap = debtIssuanceModuleEnabledSelector(state);
  const formattedRedemptionQuantity = utils.parseEther(
    rawRedemptionQuantity?.length ? rawRedemptionQuantity : '0',
  );

  dispatch(requestDebtRedemptionFees());

  try {
    let totalFees: IFeeResponse;
    if (debtIssuanceV2EnabledMap?.[currentSetAddress]) {
      totalFees = await setJSInstance.debtIssuanceV2.calculateTotalFeesAsync(
        currentSetAddress,
        formattedRedemptionQuantity,
        false,
      );
    } else if (debtIssuanceEnabledMap?.[currentSetAddress]) {
      totalFees = await setJSInstance.debtIssuance.calculateTotalFeesAsync(
        currentSetAddress,
        formattedRedemptionQuantity,
        false,
      );
    }

    dispatch(
      receiveDebtRedemptionFees({
        totalQuantity: totalFees.totalQuantity,
        managerFee: totalFees.managerFee,
        managerFeePercentage: new BigNumber(totalFees.managerFee.toString() || 0)
          .div(formattedRedemptionQuantity.toString())
          .mul(100)
          .toString(),
        protocolFee: totalFees.protocolFee,
        protocolFeePercentage: new BigNumber(totalFees.protocolFee.toString() || 0)
          .div(formattedRedemptionQuantity.toString())
          .mul(100)
          .toString(),
      }),
    );

    return totalFees;
  } catch (e) {
    console.log(e);
    toast.warn('Something went wrong with fetching fee values. Please try again later.', {
      toastId: 'fee-request',
    });
  }
};
