import {
  REQUEST_IP_ADDRESS,
  RECEIVE_IP_ADDRESS,
  SET_IS_RESTRICTED_IP_ADDRESS,
} from '../actions/ipAddressActions';
import { IAction } from '../typings/index';

export interface IpAddressReducerState {
  isFetchingIpAddress: boolean;
  ipAddress: null | string;
  isRestrictedIpAddress: boolean;
}

const initialState: IpAddressReducerState = {
  isFetchingIpAddress: false,
  ipAddress: null,
  isRestrictedIpAddress: true,
};

const ipAddressReducer = (state = initialState, action: IAction): IpAddressReducerState => {
  switch (action.type) {
    case REQUEST_IP_ADDRESS:
      return {
        ...state,
        isFetchingIpAddress: true,
      };

    case RECEIVE_IP_ADDRESS:
      return {
        ...state,
        isFetchingIpAddress: false,
        ipAddress: action.payload,
      };

    case SET_IS_RESTRICTED_IP_ADDRESS:
      return {
        ...state,
        isRestrictedIpAddress: action.payload,
      };

    default:
      return state;
  }
};

export default ipAddressReducer;
