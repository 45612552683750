import mediumIcon from '../img/socialMedia/medium.svg';
import twitterIcon from '../img/socialMedia/twitter.svg';
import twitterIconBlue from '../img/socialMedia/twitter_blue.svg';

const SOCIAL_MEDIA_ICONS = [
  {
    name: 'Medium',
    path: 'https://medium.com/set-protocol',
    icon: mediumIcon,
    iconLarge: undefined,
    alt: 'Medium icon',
  },
  {
    name: 'Twitter',
    path: 'https://twitter.com/SetProtocol',
    icon: twitterIcon,
    iconLarge: twitterIconBlue,
    alt: 'Twitter icon',
  },
];

export default SOCIAL_MEDIA_ICONS;
