import COLORS from './colors';
import DATA_SELECTS from './dataSelects';
import DEFAULT_PARAMETERS from './defaultParameters';
import COMPONENT_TOKEN_COLORS from './componentTokenColors';
import ETHEREUM_ADDRESSES from './ethereumAddresses';
import NETWORK_CONSTANTS from './networkConstants';
import EXCHANGE_IDS from './exchangeIds';
import FAQ_CONTENT from './faqContent';
import GRADIENTS from './gradients';
import INPUT_TYPES from './inputTypes';
import NAV_ELEMENTS from './navElements';
import SETJS_CONFIG from './setJSConfig';
import REBALANCING_STATUSES from './rebalancingStatuses';
import SOCIAL_MEDIA_ICONS from './socialMediaIcons';
import STYLES from './styles';
import BLOCKEXPLORER_URL from './subdomain';
import TEXT_ELEMENTS from './textElements';
import TRANSACTION_STATUSES from './transactionStatuses';
import { WEB3_PROVIDERS, WEB3_PROVIDER_LOGGING_LABEL, LEDGER_PATHS } from './web3Providers';
import WINDOW_DIMENSIONS from './windowDimensions';
import {
  EMAIL_MESSAGE_STRING,
  PHONE_NUMBER_MESSAGE_STRING,
  UPDATE_PROFILE_MESSAGE_STRING,
  UPDATE_SET_MESSAGE_STRING,
  UPDATE_SET_NOTIFICATIONS,
  CREATE_FEED_POST_MESSAGE_STRING,
} from './messageConstants';
import STRATEGY_TYPES from './strategyTypes';
import ETHEREUM_CONSTANTS from './ethereumConstants';
import * as UNISWAP from './uniswap';
import { BigNumber } from '../utils/index';
import { SetCategory } from '../reducers/exploreReducer';

const DEFAULT_SET_UNITS = '1000000000000000000'; // 10^18

const MAX_SUBGRAPH_SKIP_AMOUNT = 5000;

/* Restricted country codes */
const BANNED_COUNTRIES = ['US'];

const TYPE_ISSUE = 'issue';
const TYPE_REDEEM = 'redeem';

export const YEARLY_INTERVAL = new BigNumber(1);
export const MONTHLY_INTERVAL = new BigNumber(12);
export const WEEKLY_INTERVAL = new BigNumber(52);
export const DAILY_INTERVAL = new BigNumber(365);
export const HOURLY_INTERVAL = new BigNumber(365).mul(24);

export const MODULE_STATE = {
  NONE: 0,
  PENDING: 1,
  INITIALIZED: 2,
};

export const POSITION_STATE = {
  DEFAULT: 0,
  EXTERNAL: 1,
};

export const CATEGORY_TO_SET_TYPE_MAPPING: { [category: string]: string } = {
  [SetCategory.THEMATIC_EXPOSURE]: 'Thematic Exposure',
  [SetCategory.LEVERAGED_EXPOSURE]: 'Leveraged Exposure',
  [SetCategory.YIELD_BEARING]: 'Yield Bearing',
};

export const SUPPORTED_NETWORKS = ['ethereum', 'polygon', 'optimism'];

export {
  COLORS,
  COMPONENT_TOKEN_COLORS,
  DATA_SELECTS,
  DEFAULT_PARAMETERS,
  DEFAULT_SET_UNITS,
  ETHEREUM_ADDRESSES,
  NETWORK_CONSTANTS,
  EXCHANGE_IDS,
  FAQ_CONTENT,
  GRADIENTS,
  INPUT_TYPES,
  LEDGER_PATHS,
  NAV_ELEMENTS,
  REBALANCING_STATUSES,
  SETJS_CONFIG,
  SOCIAL_MEDIA_ICONS,
  BLOCKEXPLORER_URL,
  STRATEGY_TYPES,
  STYLES,
  TEXT_ELEMENTS,
  TRANSACTION_STATUSES,
  TYPE_ISSUE,
  TYPE_REDEEM,
  WEB3_PROVIDERS,
  WEB3_PROVIDER_LOGGING_LABEL,
  WINDOW_DIMENSIONS,
  EMAIL_MESSAGE_STRING,
  PHONE_NUMBER_MESSAGE_STRING,
  UPDATE_PROFILE_MESSAGE_STRING,
  UPDATE_SET_MESSAGE_STRING,
  UPDATE_SET_NOTIFICATIONS,
  CREATE_FEED_POST_MESSAGE_STRING,
  ETHEREUM_CONSTANTS,
  UNISWAP,
  MAX_SUBGRAPH_SKIP_AMOUNT,
  BANNED_COUNTRIES,
};
