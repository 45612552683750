import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Link } from 'react-router-dom';

import { Text } from '../../components/index';
import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';
import { IHistory } from '../../typings/index';
import { GO_TO_ACCOUNT_PAGE } from '../../actions/web3Actions';

const { TABLET_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  Banner_container: {
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    position: 'relative',
    padding: '15px 40px',
    fontSize: '16px',
    background: COLORS.blue,
    color: COLORS.white,
    borderRadius: '0',
    [TABLET_MEDIA_QUERY]: {
      paddingLeft: '25px',
      paddingRight: '25px',
      flexDirection: 'column',
    },
  },
  Banner_closeCircle: {
    position: 'absolute',
    cursor: 'pointer',
    top: '5px',
    right: '10px',
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    display: 'table',
    backgroundColor: 'rgba(218, 215, 226, 0.5)',
  },
  Banner_closeText: {
    display: 'table-cell',
    color: `${COLORS.darkGrayDarkened}`,
    verticalAlign: 'middle',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '1.5',
    textAlign: 'center',
  },
  Banner_button: {
    backgroundColor: COLORS.white,
    border: 'none',
    borderRadius: '4px',
    color: COLORS.blue,
    cursor: 'pointer',
    marginLeft: '12px',
    padding: '8px 16px',
    transition: '0.05s',
    ':hover': {
      transform: 'scale(1.02)',
    },
    ':active': {
      transform: 'scale(0.98)',
    },
    [TABLET_MEDIA_QUERY]: {
      marginTop: '10px',
    },
  },
});

export interface IBannerProps {
  readonly isLoggedIn?: boolean;
  readonly history?: IHistory;
  readonly canClose?: boolean;
  readonly visible?: boolean;
  readonly action: string;
  readonly ctaRoute?: string;
  readonly ctaText?: string;
  readonly content?: React.ReactNode;
  readonly onClick?: (...args: any[]) => any;
  readonly onLogin?: (...args: any[]) => any;
}

export interface IBannerState {
  readonly closed: boolean;
}

class Banner extends PureComponent<IBannerProps, IBannerState> {
  public constructor(props: IBannerProps) {
    super(props);
    this.state = {
      closed: false,
    };
  }

  public static get defaultProps() {
    return {
      action: 'Banner button click',
      ctaRoute: '/',
      ctaText: '',
      onClick: () => {},
    };
  }

  public closeBanner = () => {
    const { onClick } = this.props;
    onClick();
    this.setState({ closed: true });
  };

  public directToAccountOrLogin = () => {
    const { isLoggedIn, history, onLogin } = this.props;

    if (isLoggedIn) {
      history.push('/account');
      return;
    }

    onLogin(GO_TO_ACCOUNT_PAGE);
  };

  public renderCTAButton = () => {
    const { ctaRoute, ctaText } = this.props;

    if (!ctaText || !ctaRoute) return null;

    if (ctaRoute === '/account') {
      return (
        <button
          type="button"
          className={css(styles.Banner_button)}
          onClick={this.directToAccountOrLogin}
        >
          {ctaText}
        </button>
      );
    }

    return (
      <Link to={ctaRoute}>
        <button type="button" className={css(styles.Banner_button)}>
          {ctaText}
        </button>
      </Link>
    );
  };

  public render() {
    const { canClose, content, visible } = this.props;
    const { closed } = this.state;
    return visible && !closed ? (
      <div className={css(styles.Banner_container)}>
        <Text color="white">{content}</Text>
        {this.renderCTAButton()}
        {canClose && (
          <div
            className={css(styles.Banner_closeCircle)}
            onKeyDown={this.closeBanner}
            onClick={this.closeBanner}
            role="button"
            tabIndex={0}
          >
            <div className={css(styles.Banner_closeText)}>X</div>
          </div>
        )}
      </div>
    ) : null;
  }
}

export default Banner;
