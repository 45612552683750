import React, { PureComponent } from 'react';
import { css } from 'aphrodite';
import { Container, Grid, Header } from 'semantic-ui-react';

import styles from './homeStyles';
import { withTranslation, WithTranslation } from 'react-i18next';

interface IHomeStepByStepSectionProps {
  isTablet?: boolean;
}

/**
 * @title HomeStepByStepSection
 * @author Set Labs
 *
 * The HomeStepByStepSection explains how asset managers can create a Set, manage their Set,
 * and grow their Set using different tools provided by Set.
 */
class HomeStepByStepSection extends PureComponent<IHomeStepByStepSectionProps & WithTranslation> {
  render() {
    const { isTablet } = this.props;

    return (
      <Container className={css(styles.Home_paddingBottomXLarge)}>
        <Header as="h3" className={css(styles.HomeExplainerSection_subTitle)}>
          Create, Manage, &amp; Grow
        </Header>
        <Header as="h2" className={css(styles.Home_sectionHeader)}>
          Tools for each approach
        </Header>
        <p className={css(styles.Home_sectionDescription, styles.Home_paddingBottomMed)}>
          Use professional tools for asset managers that help you create, manage, and grow your Set
        </p>
        <Grid>
          <Grid.Row columns={isTablet ? 1 : 3}>
            <Grid.Column className={isTablet ? css(styles.Home_marginBottomMedium) : undefined}>
              <div className={css(styles.Home_boxContainer)}>
                <div className={css(styles.Home_stepInnerContainer)}>
                  <div>
                    <img
                      alt="Step 1 icon"
                      className={css(styles.Home_stepIcon)}
                      src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/home-step-1.svg"
                    />
                    <Header
                      as="h4"
                      className={css(styles.Home_marginBottomSmall, styles.Home_columnHeader)}
                    >
                      Developers
                    </Header>
                    <p className={css(styles.Home_stepDescription)}>
                      Create your own Set directly using our developer documentation and SDKs.
                      Customize it with any ERC20 asset and your own strategy.
                    </p>
                  </div>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column className={isTablet ? css(styles.Home_marginBottomMedium) : undefined}>
              <div className={css(styles.Home_boxContainer)}>
                <div className={css(styles.Home_stepInnerContainer)}>
                  <div>
                    <img
                      alt="Step 2 icon"
                      className={css(styles.Home_stepIcon)}
                      src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/home-step-2.svg"
                    />
                    <Header
                      as="h4"
                      className={css(styles.Home_marginBottomSmall, styles.Home_columnHeader)}
                    >
                      Asset Managers
                    </Header>
                    <p className={css(styles.Home_stepDescription)}>
                      Use Set’s portfolio management tools to rebalance and tap into yield
                      generating opportunities.
                    </p>
                  </div>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column className={isTablet ? css(styles.Home_marginBottomMedium) : undefined}>
              <div className={css(styles.Home_boxContainer)}>
                <div className={css(styles.Home_stepInnerContainer)}>
                  <div>
                    <img
                      alt="Step 3 icon"
                      className={css(styles.Home_stepIcon)}
                      src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/home-step-3.svg"
                    />
                    <Header
                      as="h4"
                      className={css(styles.Home_marginBottomSmall, styles.Home_columnHeader)}
                    >
                      Methodologists
                    </Header>
                    <p className={css(styles.Home_stepDescription)}>
                      Grow your managed assets through Index Coop’s community and liquidity
                      incentives.
                    </p>
                  </div>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default withTranslation('home')(HomeStepByStepSection);
