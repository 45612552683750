import { toast } from 'react-toastify';

import { submitSetJSTransaction } from '../actions/transactionWatcherActions';
import { batchFetchListTokenAllowances } from '../actions/approvalActions';
import { setJSInstanceSelector } from '../selectors/index';
import { currentSetComponentsSelector } from '../selectors/setDetailsSelectors';
import {
  maxIssuableTokenQuantitySelector,
  createIssueFundTransactionArgs,
} from '../selectors/issuanceV2Selectors';
import {
  payloadActionGenerator,
  emptyActionGenerator,
  userRejectedMetamaskTransaction,
} from '../utils/index';
import i18n from '../i18n';

export const SET_IS_SUBMITTING_ISSUANCE_TRANSACTION_V2 =
  'SET_IS_SUBMITTING_ISSUANCE_TRANSACTION_V2';
export const UPDATE_ISSUANCE_INPUT_QUANTITY_V2 = 'UPDATE_ISSUANCE_INPUT_QUANTITY_V2';
export const FETCH_DEBT_ISSUANCE_COMPONENTS = 'FETCH_DEBT_ISSUANCE_COMPONENTS';
export const RECEIVE_DEBT_ISSUANCE_COMPONENTS = 'RECEIVE_DEBT_ISSUANCE_COMPONENTS';
export const FETCH_PERP_ISSUANCE_COMPONENTS = 'FETCH_PERP_ISSUANCE_COMPONENTS';
export const RECEIVE_PERP_ISSUANCE_COMPONENTS = 'RECEIVE_PERP_ISSUANCE_COMPONENTS';
export const SET_MAX_ALLOWED_SLIPPAGE_PERCENTAGE = 'SET_MAX_ALLOWED_SLIPPAGE_PERCENTAGE';
export const REQUEST_PERP_ISSUANCE_FEES = 'REQUEST_PERP_ISSUANCE_FEES';
export const RECEIVE_PERP_ISSUANCE_FEES = 'RECEIVE_PERP_ISSUANCE_FEES';
export const REQUEST_DEBT_ISSUANCE_FEES = 'REQUEST_DEBT_ISSUANCE_FEES';
export const RECEIVE_DEBT_ISSUANCE_FEES = 'RECEIVE_DEBT_ISSUANCE_FEES';
export const SET_MAX_TOKEN_PERP_ISSUE_AMOUNT_BY_LEVERAGE =
  'SET_MAX_TOKEN_PERP_ISSUE_AMOUNT_BY_LEVERAGE';

export const setIsSubmittingIssuanceTransaction = payloadActionGenerator(
  SET_IS_SUBMITTING_ISSUANCE_TRANSACTION_V2,
);
export const updateIssuanceQuantityV2 = payloadActionGenerator(UPDATE_ISSUANCE_INPUT_QUANTITY_V2);
export const fetchDebtIssuanceComponents = emptyActionGenerator(FETCH_DEBT_ISSUANCE_COMPONENTS);
export const receiveDebtIssuanceComponents = payloadActionGenerator(
  RECEIVE_DEBT_ISSUANCE_COMPONENTS,
);
export const fetchPerpIssuanceComponents = emptyActionGenerator(FETCH_PERP_ISSUANCE_COMPONENTS);
export const receivePerpIssuanceComponents = payloadActionGenerator(
  RECEIVE_PERP_ISSUANCE_COMPONENTS,
);
export const setMaxAllowedSlippagePercentage = payloadActionGenerator(
  SET_MAX_ALLOWED_SLIPPAGE_PERCENTAGE,
);
export const requestPerpIssuanceFees = emptyActionGenerator(REQUEST_PERP_ISSUANCE_FEES);
export const receivePerpIssuanceFees = payloadActionGenerator(RECEIVE_PERP_ISSUANCE_FEES);
export const requestDebtIssuanceFees = emptyActionGenerator(REQUEST_DEBT_ISSUANCE_FEES);
export const receiveDebtIssuanceFees = payloadActionGenerator(RECEIVE_DEBT_ISSUANCE_FEES);

export const setMaxTokenPerpIssueAmountByLeverage = payloadActionGenerator(
  SET_MAX_TOKEN_PERP_ISSUE_AMOUNT_BY_LEVERAGE,
);

/**
 * Update issuance quantity
 * @param quantity - target issuance value
 */
export const updateIssuanceQuantity = (quantity: string) => (dispatch: any) => {
  dispatch(updateIssuanceQuantityV2(quantity));
};

/**
 * Fetch token allowances for all required components in the set currently shown
 * in Issuance flow.
 */
export const fetchERC20AllowancesForCurrentlyViewingSet = () => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const issuanceComponents = currentSetComponentsSelector(state);

  dispatch(batchFetchListTokenAllowances(issuanceComponents));
};

/**
 * Call SetJS and issue the desired fund token quantity.
 */
export const issueCurrentSet = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const setJSInstance = setJSInstanceSelector(state);
  const issueTransactionArguments = await createIssueFundTransactionArgs(state);

  if (!issueTransactionArguments) return;

  const issuanceRequest = submitSetJSTransaction(() =>
    setJSInstance.issuance.issueAsync(...issueTransactionArguments),
  );

  dispatch(setIsSubmittingIssuanceTransaction(true));
  try {
    dispatch(issuanceRequest);
  } catch (e) {
    if (userRejectedMetamaskTransaction(e)) return;
    console.log(e);

    toast(i18n.t('issue-and-redeem:issue-action.issuance-failed'));
  } finally {
    dispatch(setIsSubmittingIssuanceTransaction(false));
  }
};

/**
 * Set the max issuable quantity the user can mint of the currently shown Fund.
 */
export const setMaxIssuableQuantity = () => (dispatch: any, getState: any) => {
  const state = getState();
  const maxIssuableQuantity = maxIssuableTokenQuantitySelector(state);

  dispatch(updateIssuanceQuantityV2(maxIssuableQuantity || '0'));
};
