import { toChecksumAddress } from 'tochecksum';
import {
  REQUEST_SET_DETAILS,
  RECEIVE_SET_DETAILS,
  REQUEST_SET_DETAILS_ERROR,
  REQUEST_CUSTOM_SET_DETAILS,
  RECEIVE_CUSTOM_SET_DETAILS,
  REQUEST_CUSTOM_SET_DETAILS_ERROR,
  BATCH_RECEIVE_SET_DETAILS,
  BATCH_REQUEST_SET_DETAILS_ERROR,
  SET_CURRENTLY_VIEWING_SET_ADDRESS,
  OPEN_EDIT_SET_DETAILS_MODAL,
  CLOSE_EDIT_SET_DETAILS_MODAL,
  RECEIVE_EMPTY_SET_DETAILS,
  LOADING_MODULE_STATUSES,
  RECEIVED_MODULE_STATUSES,
  RECEIVE_PRIMARY_APY_CALCULATIONS,
  FETCH_PRIMARY_APY_CALCULATIONS,
  RECEIVE_ISSUANCE_REDEMPTION_FEES,
  ADD_SET_TO_CONFIRMED_WARNING_LIST,
} from '../actions/setDetailsActions';
import {
  CustomSetDetailsResponse,
  IEnabledMapping,
  ISetConfirmedWarningsList,
  ISetDetails,
} from '../typings/index';

export interface ISetDetailsReducerState {
  isFetchingSetDetails: boolean;
  isFetchingCustomSetDetails: boolean;
  isInvalidSetDetails: boolean;
  isInvalidCustomSetDetails: boolean;
  isEditDetailsModalOpen: boolean;
  currentlyViewingSetAddress: string;
  setDetails: { [address: string]: ISetDetails };
  customSetDetails: { [address: string]: CustomSetDetailsResponse };
  moduleEnabledLoading: boolean;
  isIssuanceModuleEnabled: IEnabledMapping;
  isDebtIssuanceModuleEnabled: IEnabledMapping;
  isDebtIssuanceModuleV2Enabled: IEnabledMapping;
  isPerpIssuanceModuleEnabled: IEnabledMapping;
  isCompoundLeverageModuleEnabled: IEnabledMapping;
  isAaveLeverageModuleEnabled: IEnabledMapping;
  isPerpV2LeverageModuleEnabled: IEnabledMapping;
  isPerpV2BasisTradingModuleEnabled: IEnabledMapping;
  isFetchingApyCalculations: boolean;
  confirmedWarningsList: ISetConfirmedWarningsList;
  primaryApy: { [address: string]: string };
}

const initialState: ISetDetailsReducerState = {
  isFetchingSetDetails: false,
  isFetchingCustomSetDetails: false,
  isInvalidSetDetails: false,
  isInvalidCustomSetDetails: false,
  isEditDetailsModalOpen: false,
  currentlyViewingSetAddress: undefined,
  setDetails: {},
  customSetDetails: {},
  moduleEnabledLoading: false,
  isIssuanceModuleEnabled: {} as IEnabledMapping,
  isDebtIssuanceModuleEnabled: {} as IEnabledMapping,
  isDebtIssuanceModuleV2Enabled: {} as IEnabledMapping,
  isPerpIssuanceModuleEnabled: {} as IEnabledMapping,
  isCompoundLeverageModuleEnabled: {} as IEnabledMapping,
  isAaveLeverageModuleEnabled: {} as IEnabledMapping,
  isPerpV2LeverageModuleEnabled: {} as IEnabledMapping,
  isPerpV2BasisTradingModuleEnabled: {} as IEnabledMapping,
  isFetchingApyCalculations: false,
  primaryApy: {},
  confirmedWarningsList: {} as ISetConfirmedWarningsList,
};

const setDetailsReducer = (state = initialState, action: any): ISetDetailsReducerState => {
  switch (action.type) {
    case REQUEST_SET_DETAILS:
      return {
        ...state,
        isFetchingSetDetails: action.payload,
      };

    case RECEIVE_SET_DETAILS:
      return {
        ...state,
        isFetchingSetDetails: false,
        setDetails: {
          ...state.setDetails,
          [action.payload.address]: {
            ...state.setDetails[action.payload.address],
            ...action.payload.setDetails,
          },
        },
      };
    case REQUEST_SET_DETAILS_ERROR:
      return {
        ...state,
        isInvalidSetDetails: action.payload,
        isFetchingSetDetails: false,
      };
    case RECEIVE_EMPTY_SET_DETAILS:
      return {
        ...state,
        setDetails: {},
      };
    case BATCH_RECEIVE_SET_DETAILS:
      const setDetailsMapping: { [address: string]: ISetDetails } = {};
      action.payload.addresses.forEach((address: string, i: number) => {
        setDetailsMapping[address] = {
          ...state.setDetails[address],
          ...action.payload.setDetailsList[i],
        };
      });
      return {
        ...state,
        isFetchingSetDetails: false,
        setDetails: {
          ...state.setDetails,
          ...setDetailsMapping,
        },
      };
    case BATCH_REQUEST_SET_DETAILS_ERROR:
      return {
        ...state,
        isFetchingSetDetails: false,
      };

    case SET_CURRENTLY_VIEWING_SET_ADDRESS:
      return {
        ...state,
        currentlyViewingSetAddress: toChecksumAddress(action.payload),
      };
    case OPEN_EDIT_SET_DETAILS_MODAL:
      return {
        ...state,
        isEditDetailsModalOpen: true,
      };

    case CLOSE_EDIT_SET_DETAILS_MODAL:
      return {
        ...state,
        isEditDetailsModalOpen: false,
      };

    case REQUEST_CUSTOM_SET_DETAILS:
      return {
        ...state,
        isFetchingCustomSetDetails: action.payload,
      };

    case RECEIVE_CUSTOM_SET_DETAILS:
      return {
        ...state,
        isFetchingCustomSetDetails: false,
        customSetDetails: {
          ...state.customSetDetails,
          [action.payload.setAddress]: action.payload.customSetDetails,
        },
      };
    case REQUEST_CUSTOM_SET_DETAILS_ERROR:
      return {
        ...state,
        isInvalidCustomSetDetails: action.payload,
        isFetchingCustomSetDetails: false,
      };
    case LOADING_MODULE_STATUSES:
      return {
        ...state,
        moduleEnabledLoading: action.payload,
      };
    case RECEIVED_MODULE_STATUSES:
      return {
        ...state,
        moduleEnabledLoading: false,
        isIssuanceModuleEnabled: {
          ...state.isIssuanceModuleEnabled,
          [action.payload.setAddress]: action.payload.isBasicIssuanceEnabled,
        },
        isDebtIssuanceModuleEnabled: {
          ...state.isDebtIssuanceModuleEnabled,
          [action.payload.setAddress]: action.payload.isDebtIssuanceEnabled,
        },
        isDebtIssuanceModuleV2Enabled: {
          ...state.isDebtIssuanceModuleV2Enabled,
          [action.payload.setAddress]: action.payload.isDebtIssuanceV2Enabled,
        },
        isPerpIssuanceModuleEnabled: {
          ...state.isPerpIssuanceModuleEnabled,
          [action.payload.setAddress]: action.payload.isPerpIssuanceEnabled,
        },
        isCompoundLeverageModuleEnabled: {
          ...state.isCompoundLeverageModuleEnabled,
          [action.payload.setAddress]: action.payload.isCompoundLeverageEnabled,
        },
        isAaveLeverageModuleEnabled: {
          ...state.isAaveLeverageModuleEnabled,
          [action.payload.setAddress]: action.payload.isAaveLeverageEnabled,
        },
        isPerpV2LeverageModuleEnabled: {
          ...state.isPerpV2LeverageModuleEnabled,
          [action.payload.setAddress]: action.payload.isPerpV2LeverageEnabled,
        },
        isPerpV2BasisTradingModuleEnabled: {
          ...state.isPerpV2BasisTradingModuleEnabled,
          [action.payload.setAddress]: action.payload.isPerpV2BasisTradingEnabled,
        },
      };

    case FETCH_PRIMARY_APY_CALCULATIONS:
      return {
        ...state,
        isFetchingApyCalculations: true,
      };

    case RECEIVE_PRIMARY_APY_CALCULATIONS:
      return {
        ...state,
        isFetchingApyCalculations: false,
        primaryApy: {
          ...state.primaryApy,
          [action.payload.setAddress]: action.payload.primaryApy,
        },
      };

    case RECEIVE_ISSUANCE_REDEMPTION_FEES:
      return {
        ...state,
        setDetails: {
          ...state.setDetails,
          [action.payload.address]: {
            ...state.setDetails[action.payload.address],
            ...action.payload.issuanceRedemptionFees,
          },
        },
      };

    case ADD_SET_TO_CONFIRMED_WARNING_LIST:
      return {
        ...state,
        confirmedWarningsList: {
          ...state.confirmedWarningsList,
          [action.payload]: true,
        },
      };

    default:
      return state;
  }
};

export default setDetailsReducer;
