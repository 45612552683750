import { StyleSheet } from 'aphrodite';

import { COLORS } from '../../constants/index';

export const styles = StyleSheet.create({
  RichTextEditor_container: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '4px',
    padding: '10px',
    height: '400px',
    width: '100%',
    ':focus-within': {
      borderColor: COLORS.focusInputColor,
    },
  },
  RichTextEditor_editorContainer: {
    display: 'flex',
    overflowY: 'scroll',
    height: '100%',
    width: '100%',
  },
  RichTextEditor_readOnlyContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
});
