import {
  REQUEST_DEPLOYED_SET_ADDRESSES,
  RECEIVE_DEPLOYED_SET_ADDRESSES,
  RECEIVE_DEPLOYED_SET_ADDRESSES_ERROR,
  REQUEST_DEPLOYED_SETS_WITH_DETAILS,
  RECEIVE_DEPLOYED_SETS_WITH_DETAILS,
  RECEIVE_DEPLOYED_SETS_WITH_DETAILS_ERROR,
} from '../actions/deployedSetsActions';
import { ISetDetailsHydrated } from '../typings/index';

export interface IDeployedSetsReducerState {
  isFetching: boolean;
  deployedSetAddresses: string[];
  isFetchingDeployedSetDetails: boolean;
  deployedSetDetails: ISetDetailsHydrated[];
  error: any;
}

const initialState: IDeployedSetsReducerState = {
  isFetching: true,
  isFetchingDeployedSetDetails: true,
  deployedSetAddresses: [] as string[],
  deployedSetDetails: [] as ISetDetailsHydrated[],
  error: undefined,
};

const deployedSetsReducer = (state = initialState, action: any): IDeployedSetsReducerState => {
  switch (action.type) {
    case REQUEST_DEPLOYED_SET_ADDRESSES:
      return {
        ...state,
        isFetching: true,
      };

    case RECEIVE_DEPLOYED_SET_ADDRESSES:
      return {
        ...state,
        isFetching: false,
        deployedSetAddresses: action.payload,
      };
    case RECEIVE_DEPLOYED_SET_ADDRESSES_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case REQUEST_DEPLOYED_SETS_WITH_DETAILS:
      return {
        ...state,
        isFetchingDeployedSetDetails: true,
      };

    case RECEIVE_DEPLOYED_SETS_WITH_DETAILS:
      return {
        ...state,
        isFetchingDeployedSetDetails: false,
        deployedSetDetails: {
          ...state.deployedSetDetails,
          ...action.payload,
        },
      };

    case RECEIVE_DEPLOYED_SETS_WITH_DETAILS_ERROR:
      return {
        ...state,
        isFetchingDeployedSetDetails: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default deployedSetsReducer;
