import { UPDATE_GAS_PRICE, UPDATE_FEE_VALUES } from '../actions/gasPriceActions';

const initialState = {
  fastGasPrice: '60000000000',
  baseFee: '10',
  maxPriorityFeePerGas: '2',
};

const gasPriceReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_GAS_PRICE:
      // Units in Wei
      return {
        ...state,
        fastGasPrice: action.payload.fast_wei || action.payload.fast_gwei * 10 ** 9,
        rapidGasPrice: action.payload.rapid_wei || action.payload.rapid_gwei * 10 ** 9,
        standardGasPrice: action.payload.standard_wei || action.payload.standard_gwei * 10 ** 9,
      };
    case UPDATE_FEE_VALUES:
      return {
        ...state,
        baseFee: action.payload.baseFee,
        maxPriorityFeePerGas: action.payload.maxPriorityFeePerGas,
      };
    default:
      return state;
  }
};

export default gasPriceReducer;
