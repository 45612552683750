import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { withTranslation, WithTranslation } from 'react-i18next';
import Web3 from 'web3';
import { utils, BigNumber as EthersBigNumber } from 'ethers';
import {
  ITokenBalances,
  IMatch,
  IHistory,
  ISetDetails,
  IDebtComponentWithToken,
  IFeeResponse,
} from '../../typings/index';
import { formatTokenUnit, BigNumber } from '../../utils/index';
import { generateColorFromString } from '../../utils/colorUtils';
import { getListTokenImage, tokenFromBaseUnits } from '../../utils/formatUtils';
import { COLORS, POSITION_STATE } from '../../constants/index';
import darkArrow from '../../img/icons/right-arrow-dark.svg';
import SlippageInputPopup from '../../components/SlippageInputPopup';
import { InfoIcon } from '../../components/index';

const styles = StyleSheet.create({
  PerpRedemptionForm_container: {
    maxWidth: '400px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  PerpRedemptionForm_setImage: {
    width: '50px',
    height: '50px',
    marginBottom: '20px',
    borderRadius: '50%',
  },
  PerpRedemptionForm_title: {
    fontSize: '30px',
    fontWeight: 600,
    color: COLORS.darkBlue,
    lineHeight: 1.2,
    textAlign: 'center',
    marginBottom: '20px',
  },
  PerpRedemptionForm_subTitle: {
    fontSize: '14px',
    lineHeight: 1.5,
    textAlign: 'center',
    marginBottom: '20px',
  },
  PerpRedemptionForm_perpIssuanceDescription: {
    fontSize: '14px',
    lineHeight: 1.5,
    textAlign: 'left',
    marginBottom: '20px',
  },

  /* Input Field */
  PerpRedemptionForm_issueInputContainer: {
    width: '100%',
    position: 'relative',
    marginBottom: '20px',
  },
  PerpRedemptionForm_issueQuantityInput: {
    width: '100%',
    fontSize: '16px',
    padding: '15px',
    paddingRight: '120px',
    marginBottom: '20px',
    border: `2px solid ${COLORS.gray}`,
    borderRadius: '4px',
    ':focus': {
      borderColor: COLORS.blue,
    },
  },
  PerpRedemptionForm_issueInputLabelContainer: {
    position: 'absolute',
    top: 15,
    right: 16,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  PerpRedemptionForm_issueInputSymbol: {
    fontSize: '12px',
    color: COLORS.blue,
    fontWeight: 500,
    lineHeight: 1,
  },
  PerpRedemptionForm_issueMaxButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '22px',
    fontSize: '9px',
    marginLeft: '10px',
    padding: '11px 12px 10px 12px',
    ':active': {
      backgroundColor: COLORS.blueDarkened,
    },
  },

  /* PerpIssuance Components Table Header */
  PerpRedemptionForm_tableHeader: {
    display: 'flex',
    paddingBottom: '10px',
    borderBottom: `1px solid ${COLORS.gray}`,
  },
  PerpRedemptionForm_tokenLabel: {
    fontSize: '10px',
    lineHeight: 1.5,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  PerpRedemptionForm_inputTokenLabel: {
    fontSize: '10px',
    lineHeight: 1.5,
    flex: 1,
  },
  PerpRedemptionForm_requiredAmountLabel: {
    fontSize: '10px',
    lineHeight: 1.5,
    flex: 1,
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
  PerpRedemptionForm_userBalanceLabel: {
    fontSize: '10px',
    lineHeight: 1.5,
    flex: 1,
    textAlign: 'right',
    alignSelf: 'flex-end',
  },

  /* PerpIssuance Components */
  PerpRedemptionForm_componentContainer: {
    width: '100%',
    marginBottom: '30px',
    padding: '0px 17px',
  },
  PerpRedemptionForm_externalPositionContainer: {
    width: '100%',
    marginBottom: '30px',
    padding: '60px 17px 22px',
    border: `1px solid ${COLORS.lightGray4}`,
    borderRadius: '5px',
    position: 'relative',
  },
  PerpRedemptionForm_component: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
    borderBottom: `1px solid ${COLORS.lightGray}`,
  },
  PerpRedemptionForm_componentLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  PerpRedemptionForm_componentIcon: {
    height: '20px',
    width: '20px',
    borderRadius: '50%',
  },
  PerpRedemptionForm_componentSymbol: {
    fontSize: '14px',
    color: COLORS.darkBlue,
    fontWeight: 600,
    marginLeft: '10px',
  },

  PerpRedemptionForm_componentQuantityRequired: {
    fontSize: '14px',
    color: COLORS.darkBlue,
    flex: 1,
    textAlign: 'right',
  },
  PerpRedemptionForm_componentUserBalance: {
    fontSize: '14px',
    color: COLORS.green,
    flex: 1,
    textAlign: 'right',
  },

  /* Submit Button + Footer */
  PerpRedemptionForm_submitButton: {
    width: '100%',
    padding: '20px',
    fontWeight: 600,
    marginBottom: '30px',
    backgroundColor: COLORS.blue,
    color: COLORS.white,
    ':hover': {
      backgroundColor: COLORS.blueDarkened,
    },
  },
  PerpRedemptionForm_submitButtonDisabled: {
    pointerEvents: 'none',
    backgroundColor: COLORS.gray,
    ':hover': {
      backgroundColor: COLORS.gray,
    },
  },
  PerpRedemptionForm_errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px',
    backgroundColor: COLORS.redAlpha25,
    borderRadius: '4px',
  },
  PerpRedemptionForm_errorText: {
    textAlign: 'center',
    fontSize: '14px',
    color: COLORS.red,
  },

  PerpRedemptionForm_backButton: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '40px',
    height: '40px',
    transform: 'rotate(180deg)',
    padding: '10px',
    margin: '20px',
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '50%',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: COLORS.lightGray,
    },
  },
});

type PerpRedemptionFormProps = {
  setAddress: string;
  customV2SetPathPrefix: string;
  isPerpRedemptionReady: boolean;
  userHasSufficientFunds: boolean;
  setDetails: ISetDetails;
  inputComponents: IDebtComponentWithToken[];
  outputComponents: IDebtComponentWithToken[];
  redemptionQuantity: string;
  erc20BalancesByAddress: ITokenBalances;
  match: IMatch;
  history: IHistory;
  maxSlippagePercentageAllowed: string;
  perpRedemptionFees: IFeeResponse;
  isFetchingPerpRedemptionFees: boolean;
  minTokenAmountsOut: EthersBigNumber[];
  fetchFeesForRedeemQuantity: (...args: any[]) => any;
  setMaxRedeemableQuantity: (...args: any[]) => any;
  updateRedemptionQuantity: (...args: any[]) => any;
  perpRedeemCurrentSet: (...args: any[]) => any;
  setMaxAllowedSlippagePercentage: (...args: any[]) => any;
};

class PerpRedemption extends PureComponent<PerpRedemptionFormProps & WithTranslation> {
  componentDidMount() {
    const { fetchFeesForRedeemQuantity } = this.props;

    fetchFeesForRedeemQuantity();
  }

  public onUpdateRedemptionQuantity = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { updateRedemptionQuantity, fetchFeesForRedeemQuantity } = this.props;
    const value = e.target?.value;

    await updateRedemptionQuantity(value);
    await fetchFeesForRedeemQuantity();
  };

  public renderInputComponents = () => {
    const { inputComponents, erc20BalancesByAddress, redemptionQuantity, t } = this.props;

    if (!inputComponents) return null;

    const requiredComponentsList = inputComponents?.map((component: IDebtComponentWithToken) => {
      const { address } = component;
      const checkSumAddress = Web3.utils.toChecksumAddress(address);
      const userBalance = erc20BalancesByAddress[checkSumAddress]?.toString();
      const formattedDebtValue = tokenFromBaseUnits(
        component?.debtValue?.toString() || '0',
        component?.decimals || 18,
      );
      const requiredQuantity = formattedDebtValue.mul(redemptionQuantity || 0);
      const derivedTokenColor = generateColorFromString(address || '');

      return (
        <div className={css(styles.PerpRedemptionForm_component)} key={component.symbol}>
          <div className={css(styles.PerpRedemptionForm_componentLabelContainer)}>
            <img
              className={css(styles.PerpRedemptionForm_componentIcon)}
              src={getListTokenImage(component)}
              style={{ backgroundColor: `#${derivedTokenColor}` }}
            />
            <span className={css(styles.PerpRedemptionForm_componentSymbol)}>
              {component?.symbol}
            </span>
          </div>
          <span className={css(styles.PerpRedemptionForm_componentQuantityRequired)}>
            {requiredQuantity.toFixed(8, BigNumber.ROUND_DOWN)}
          </span>
          <span className={css(styles.PerpRedemptionForm_componentUserBalance)}>
            {formatTokenUnit(userBalance, component?.decimals, 8)}
          </span>
        </div>
      );
    });

    return (
      <div className={css(styles.PerpRedemptionForm_componentContainer)}>
        <div className={css(styles.PerpRedemptionForm_tableHeader)}>
          <span className={css(styles.PerpRedemptionForm_inputTokenLabel)}>{t('table.token')}</span>
          <span className={css(styles.PerpRedemptionForm_requiredAmountLabel)}>
            {t('table.amount-required')}
          </span>
          <span className={css(styles.PerpRedemptionForm_userBalanceLabel)}>
            {t('table.your-balance')}
          </span>
        </div>
        {requiredComponentsList}
      </div>
    );
  };

  public renderOutputComponents = () => {
    const {
      setDetails,
      outputComponents,
      redemptionQuantity,
      isFetchingPerpRedemptionFees,
      perpRedemptionFees,
      maxSlippagePercentageAllowed,
      setMaxAllowedSlippagePercentage,
      t,
    } = this.props;

    if (!outputComponents) return null;

    const componentsWithOnlyDefaultPosition = outputComponents.filter(
      (component: IDebtComponentWithToken) => {
        // Check that the positions of this address do not have any external positions
        const hasOnlyDefaultPosition = !setDetails.positions
          .filter(p => p.component.toLowerCase() === component.address.toLowerCase())
          .some(p => p.positionState === POSITION_STATE['EXTERNAL']);

        return hasOnlyDefaultPosition;
      },
    );
    const componentsWithExternalPositions = outputComponents.filter(
      (component: IDebtComponentWithToken) => {
        return !componentsWithOnlyDefaultPosition.find(c => c.address === component.address);
      },
    );

    const formattedRedemptionQuantity = utils.parseEther(
      redemptionQuantity?.length ? redemptionQuantity : '0',
    );

    return (
      <>
        {formattedRedemptionQuantity.gt(0) && (
          <p className={css(styles.PerpRedemptionForm_perpIssuanceDescription)}>
            {t('perp-redeem-form.fees', {
              setName: setDetails?.name,
              feePercentage: `${
                !isFetchingPerpRedemptionFees || perpRedemptionFees?.managerFee
                  ? Number(
                      new BigNumber(perpRedemptionFees?.managerFeePercentage || 0).toFixed(
                        8,
                        BigNumber.ROUND_DOWN,
                      ),
                    )
                  : 0
              }%`,
            })}{' '}
            {t('perp-redeem-form.returned-tokens')}
          </p>
        )}
        {componentsWithExternalPositions.length > 0 && (
          <div className={css(styles.PerpRedemptionForm_externalPositionContainer)}>
            <SlippageInputPopup
              maxSlippagePercentage={maxSlippagePercentageAllowed}
              setMaxSlippagePercentage={setMaxAllowedSlippagePercentage}
            />
            <div className={css(styles.PerpRedemptionForm_tableHeader)}>
              <span className={css(styles.PerpRedemptionForm_tokenLabel)}>
                <span style={{ marginRight: '5px', fontSize: '10px' }}>EXTERNAL POSITIONS</span>
                <InfoIcon
                  hasHighZIndex={true}
                  pointerPosition="top right"
                  data={{
                    header: 'External Positions',
                    description:
                      'These positions can have slippage due to the trades required to establish virtual positions.',
                  }}
                />
              </span>
              <span className={css(styles.PerpRedemptionForm_requiredAmountLabel)}>
                {t('table.amount-returned')}
              </span>
            </div>
            {this.componentList(componentsWithExternalPositions)}
          </div>
        )}
        {componentsWithOnlyDefaultPosition.length > 0 && (
          <div className={css(styles.PerpRedemptionForm_componentContainer)}>
            <div className={css(styles.PerpRedemptionForm_tableHeader)}>
              <span className={css(styles.PerpRedemptionForm_tokenLabel)}>
                <span style={{ marginRight: '5px', fontSize: '10px' }}>DEFAULT POSITIONS</span>
                <InfoIcon
                  hasHighZIndex={true}
                  pointerPosition="top right"
                  data={{
                    header: 'Default Positions',
                    description:
                      'These positions do not experience slippage and can be directly supplied.',
                  }}
                />
              </span>
              <span className={css(styles.PerpRedemptionForm_requiredAmountLabel)}>
                {t('table.amount-returned')}
              </span>
            </div>
            {this.componentList(componentsWithOnlyDefaultPosition)}
          </div>
        )}
      </>
    );
  };

  public componentList = (components: IDebtComponentWithToken[]) => {
    const { minTokenAmountsOut, outputComponents } = this.props;

    return components?.map((component: IDebtComponentWithToken) => {
      const { address } = component;

      const componentIndex = outputComponents.findIndex(c => c.address === address);

      const derivedTokenColor = generateColorFromString(address || '');

      return (
        <div className={css(styles.PerpRedemptionForm_component)} key={component.symbol}>
          <div className={css(styles.PerpRedemptionForm_componentLabelContainer)}>
            <img
              className={css(styles.PerpRedemptionForm_componentIcon)}
              src={getListTokenImage(component)}
              style={{ backgroundColor: `#${derivedTokenColor}` }}
            />
            <span className={css(styles.PerpRedemptionForm_componentSymbol)}>
              {component?.symbol}
            </span>
          </div>
          <span className={css(styles.PerpRedemptionForm_componentQuantityRequired)}>
            {tokenFromBaseUnits(
              minTokenAmountsOut[componentIndex].toString(),
              component.decimals,
            ).toFixed(8, BigNumber.ROUND_DOWN)}
          </span>
        </div>
      );
    });
  };

  renderErrorMessage = () => {
    const { userHasSufficientFunds, setDetails, t } = this.props;

    if (!userHasSufficientFunds) {
      return (
        <div className={css(styles.PerpRedemptionForm_errorContainer)}>
          <span className={css(styles.PerpRedemptionForm_errorText)}>
            {t('redeem-form.error-title')}{' '}
            {t('redeem-form.error-description', { tokenName: setDetails?.name })}
          </span>
        </div>
      );
    }

    return null;
  };

  render() {
    const {
      setAddress,
      customV2SetPathPrefix,
      setDetails,
      redemptionQuantity,
      isPerpRedemptionReady,
      history,
      perpRedeemCurrentSet,
      setMaxRedeemableQuantity,
      t,
    } = this.props;

    return (
      <div className={css(styles.PerpRedemptionForm_container)}>
        <img
          src={darkArrow}
          className={css(styles.PerpRedemptionForm_backButton)}
          onClick={() => history.push(`${customV2SetPathPrefix}/${setAddress}`)}
        />
        <img
          className={css(styles.PerpRedemptionForm_setImage)}
          src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/issue.svg"
        />
        <p className={css(styles.PerpRedemptionForm_title)}>
          Redeem {setDetails?.name} with Slippage
        </p>
        <p className={css(styles.PerpRedemptionForm_subTitle)}>
          {t('perp-redeem-form.title', { tokenSymbol: setDetails?.symbol })}
        </p>

        <div className={css(styles.PerpRedemptionForm_issueInputContainer)}>
          <input
            className={css(styles.PerpRedemptionForm_issueQuantityInput)}
            type="number"
            value={redemptionQuantity}
            step="0.01"
            onChange={this.onUpdateRedemptionQuantity}
          />
          <div className={css(styles.PerpRedemptionForm_issueInputLabelContainer)}>
            <span className={css(styles.PerpRedemptionForm_issueInputSymbol)}>
              {setDetails?.symbol}
            </span>
            <button
              className={css(styles.PerpRedemptionForm_issueMaxButton)}
              type="button"
              onClick={setMaxRedeemableQuantity}
            >
              MAX
            </button>
          </div>
        </div>

        {this.renderInputComponents()}
        {this.renderOutputComponents()}

        <button
          className={css(
            styles.PerpRedemptionForm_submitButton,
            !isPerpRedemptionReady && styles.PerpRedemptionForm_submitButtonDisabled,
          )}
          disabled={!isPerpRedemptionReady}
          type="button"
          onClick={perpRedeemCurrentSet}
        >
          {t('buttons.redeem')}
        </button>

        {this.renderErrorMessage()}
      </div>
    );
  }
}
export default withTranslation('issue-and-redeem')(PerpRedemption);
