import SetJS from 'set.js';
import Web3 from 'web3';
import { Provider } from 'web3/providers';
import { BigNumber as EthersBigNumber, ethers } from 'ethers';

import { SETJS_CONFIG, ETHEREUM_CONSTANTS } from '../constants/index';
import { getWeb3Instance, payloadActionGenerator } from '../utils/index';
import {
  accountSelector,
  setJSInstanceSelector,
  currentChainOrDefaultSelector,
  basicIssuanceModuleAddressSelector,
  gasPriceTransactionOptionsSelector,
  navIssuanceModuleAddressSelector,
  httpProviderHostSelector,
} from '../selectors/index';
import { IERC20Token, IListTokenWithPosition, IListToken } from '../typings/index';
import { isEmpty } from 'lodash';
import { debtIssuanceModuleAddressSelector } from '../selectors/debtIssuanceSelectors';
import erc20ABI from '../constants/abis/erc20ABI';
import { perpIssuanceModuleAddressSelector } from '../selectors/protocolAddressSelector';

export const INITIALIZE_SETJS = 'INITIALIZE_SETJS';
export const INITIALIZE_DEFAULT_SETJS = 'INITIALIZE_DEFAULT_SETJS';
export const createSetJSInstance = payloadActionGenerator(INITIALIZE_SETJS);
export const createDefaultSetJSInstance = payloadActionGenerator(INITIALIZE_DEFAULT_SETJS);

/*
 * Initializes SetJS with addresses and stores it in Redux.
 */
export const initializeSetJS = (currentProvider: Provider) => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const currentChain = currentChainOrDefaultSelector(state);

  const httpProviderHost = httpProviderHostSelector(state);
  const defaultProvider = new Web3.providers.HttpProvider(httpProviderHost);

  const setJSConfig = {
    ethersProvider: new ethers.providers.Web3Provider(currentProvider, 'any'),
    ...SETJS_CONFIG[currentChain],
  };
  const setJS = new SetJS(setJSConfig);

  // Use default setJS for read-only operations where actual account not needed
  const readOnlySetJSConfig = {
    ethersProvider: new ethers.providers.Web3Provider(defaultProvider, 'any'),
    ...SETJS_CONFIG[currentChain],
  };
  const readOnlySetJS = new SetJS(readOnlySetJSConfig);

  dispatch(createSetJSInstance(setJS));
  dispatch(createDefaultSetJSInstance(readOnlySetJS));
};

/**
 * Approve the target token to the SetJS controller for proxy transfers.
 * @param token - Token to be approved
 */
export const approveSetJSTransferProxy = (token: IERC20Token | IListToken) => async (
  _: any,
  getState: any,
) => {
  const state = getState();
  const setJSInstance = setJSInstanceSelector(state);
  const userAddress = accountSelector(state);
  const basicIssuanceModuleAddress = basicIssuanceModuleAddressSelector(state);
  const gasPriceTransactionOptions = gasPriceTransactionOptionsSelector(state);
  const web3Instance = await getWeb3Instance();
  const erc20Contract = new web3Instance.eth.Contract(erc20ABI as any, token.address);
  const spenderAddress = basicIssuanceModuleAddress;

  const transferProxyAllowance = ETHEREUM_CONSTANTS.COMPOUND_UNLIMITED_TRANSFER_PROXY_ALLOWANCE;

  if (isEmpty(token?.address) || isEmpty(userAddress) || isEmpty(spenderAddress)) return null;

  const gasLimit = await erc20Contract.methods
    .approve(spenderAddress, EthersBigNumber.from(transferProxyAllowance))
    .estimateGas({ from: userAddress });

  return await setJSInstance.erc20.approveProxyAsync(
    token.address,
    spenderAddress,
    EthersBigNumber.from(transferProxyAllowance),
    userAddress,
    {
      ...gasPriceTransactionOptions,
      gasLimit: EthersBigNumber.from(gasLimit),
    },
  );
};

/**
 * Fetch the user's transfer proxy token allowance for SetJS controller.
 * @param token - Token allowance to be fetched
 */
export const getSetJSTransferProxyAllowance = (
  token: IERC20Token | IListTokenWithPosition,
) => async (_: any, getState: any) => {
  const state = getState();
  const userAddress = accountSelector(state);
  const setJSInstance = setJSInstanceSelector(state);
  const basicIssuanceModuleAddress = basicIssuanceModuleAddressSelector(state);
  const spenderAddress = basicIssuanceModuleAddress;

  if (isEmpty(token?.address) || isEmpty(userAddress) || isEmpty(spenderAddress)) return null;

  return await setJSInstance.erc20.getAllowanceAsync(
    token.address,
    userAddress,
    spenderAddress,
    userAddress,
  );
};

/**
 * Approve the target token to the SetJS Nav Issuance Module
 * @param token - Token to be approved
 */
export const approveToNavIssuanceModule = (token: IERC20Token) => async (_: any, getState: any) => {
  const state = getState();
  const userAddress = accountSelector(state);
  const setJSInstance = setJSInstanceSelector(state);
  const spenderAddress = navIssuanceModuleAddressSelector(state);
  const gasPriceTransactionOptions = gasPriceTransactionOptionsSelector(state);
  const web3Instance = await getWeb3Instance();
  const erc20Contract = new web3Instance.eth.Contract(erc20ABI as any, token.address);

  const navIssuanceAllowance = ETHEREUM_CONSTANTS.COMPOUND_UNLIMITED_TRANSFER_PROXY_ALLOWANCE;

  if (isEmpty(token?.address) || isEmpty(userAddress) || isEmpty(spenderAddress)) return null;

  const gasLimit = await erc20Contract.methods
    .approve(spenderAddress, EthersBigNumber.from(navIssuanceAllowance))
    .estimateGas({ from: userAddress });

  return await setJSInstance.erc20.approveProxyAsync(
    token.address,
    spenderAddress,
    EthersBigNumber.from(navIssuanceAllowance),
    userAddress,
    {
      ...gasPriceTransactionOptions,
      gasLimit: EthersBigNumber.from(gasLimit),
    },
  );
};

/**
 * Fetch the user's nav issuance allowance
 * @param token - Token allowance to be fetched
 */
export const getNavIssuanceModuleAllowance = (token: IERC20Token) => async (
  _: any,
  getState: any,
) => {
  const state = getState();
  const userAddress = accountSelector(state);
  const setJSInstance = setJSInstanceSelector(state);
  const spenderAddress = navIssuanceModuleAddressSelector(state);

  if (isEmpty(token?.address) || isEmpty(userAddress) || isEmpty(spenderAddress)) return null;

  return await setJSInstance.erc20.getAllowanceAsync(
    token.address,
    userAddress,
    spenderAddress,
    userAddress,
  );
};

/**
 * Approve the target token to the SetJS Debt Issuance Module
 * @param token - Token to be approved
 */
export const approveToDebtIssuanceModule = (token: IListToken) => async (_: any, getState: any) => {
  const state = getState();
  const userAddress = accountSelector(state);
  const setJSInstance = setJSInstanceSelector(state);
  const debtIssuanceModuleAddress = debtIssuanceModuleAddressSelector(state);
  const spenderAddress = debtIssuanceModuleAddress;
  const gasPriceTransactionOptions = gasPriceTransactionOptionsSelector(state);
  const web3Instance = await getWeb3Instance();
  const erc20Contract = new web3Instance.eth.Contract(erc20ABI as any, token.address);

  const debtIssuanceAllowance = ETHEREUM_CONSTANTS.COMPOUND_UNLIMITED_TRANSFER_PROXY_ALLOWANCE;

  if (isEmpty(token?.address) || isEmpty(userAddress) || isEmpty(spenderAddress)) return null;

  const gasLimit = await erc20Contract.methods
    .approve(spenderAddress, EthersBigNumber.from(debtIssuanceAllowance))
    .estimateGas({ from: userAddress });

  return await setJSInstance.erc20.approveProxyAsync(
    token.address,
    spenderAddress,
    EthersBigNumber.from(debtIssuanceAllowance),
    userAddress,
    {
      ...gasPriceTransactionOptions,
      gasLimit: EthersBigNumber.from(gasLimit),
    },
  );
};

/**
 * Fetch the user's debt issuance allowance
 * @param token - Token allowance to be fetched
 */
export const getDebtIssuanceModuleAllowance = (token: IListToken) => async (
  _: any,
  getState: any,
) => {
  const state = getState();
  const userAddress = accountSelector(state);
  const setJSInstance = setJSInstanceSelector(state);
  const debtIssuanceModuleAddress = debtIssuanceModuleAddressSelector(state);
  const spenderAddress = debtIssuanceModuleAddress;

  if (isEmpty(token?.address) || isEmpty(userAddress) || isEmpty(spenderAddress)) return null;

  return await setJSInstance.erc20.getAllowanceAsync(
    token.address,
    userAddress,
    spenderAddress,
    userAddress,
  );
};

/**
 * Approve the target token to the SetJS Perp Issuance Module
 * @param token - Token to be approved
 */
export const approveToPerpIssuanceModule = (token: IListToken) => async (_: any, getState: any) => {
  const state = getState();
  const userAddress = accountSelector(state);
  const setJSInstance = setJSInstanceSelector(state);
  const perpIssuanceModuleAddress = perpIssuanceModuleAddressSelector(state);
  const spenderAddress = perpIssuanceModuleAddress;
  const gasPriceTransactionOptions = gasPriceTransactionOptionsSelector(state);
  const web3Instance = await getWeb3Instance();
  const erc20Contract = new web3Instance.eth.Contract(erc20ABI as any, token.address);

  // TODO Check on this value
  const perpIssuanceAllowance = ETHEREUM_CONSTANTS.UNLIMITED_TRANSFER_PROXY_ALLOWANCE;

  if (isEmpty(token?.address) || isEmpty(userAddress) || isEmpty(spenderAddress)) return null;

  const gasLimit = await erc20Contract.methods
    .approve(spenderAddress, EthersBigNumber.from(perpIssuanceAllowance))
    .estimateGas({ from: userAddress });

  return await setJSInstance.erc20.approveProxyAsync(
    token.address,
    spenderAddress,
    EthersBigNumber.from(perpIssuanceAllowance),
    userAddress,
    {
      ...gasPriceTransactionOptions,
      gasLimit: EthersBigNumber.from(gasLimit),
    },
  );
};

/**
 * Fetch the user's perp issuance allowance
 * @param token - Token allowance to be fetched
 */
export const getPerpIssuanceModuleAllowance = (token: IListToken) => async (
  _: any,
  getState: any,
) => {
  const state = getState();
  const userAddress = accountSelector(state);
  const setJSInstance = setJSInstanceSelector(state);
  const perpIssuanceModuleAddress = perpIssuanceModuleAddressSelector(state);
  const spenderAddress = perpIssuanceModuleAddress;

  if (isEmpty(token?.address) || isEmpty(userAddress) || isEmpty(spenderAddress)) return null;

  return await setJSInstance.erc20.getAllowanceAsync(
    token.address,
    userAddress,
    spenderAddress,
    userAddress,
  );
};
