import { css, StyleSheet } from 'aphrodite';
import React, { PureComponent } from 'react';

import { generatePendingAnimationStyle } from '../../utils/index';
import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';

const { TABLET_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  CountdownProgress_container: {
    position: 'relative',
    width: '100%',
    backgroundColor: COLORS.lightGray,
    height: '9px',
    border: `1px solid ${COLORS.gray}`,
    [TABLET_MEDIA_QUERY]: {
      width: '110%',
      left: '-5%',
    },
  },
  CountdownProgress_bar: {
    backgroundColor: COLORS.lightGray,
    height: '9px',
    border: `1px solid ${COLORS.gray}`,
    display: 'block',
    left: '0%',
    position: 'relative',
  },
  CountdownProgress_innerBar: {
    width: '100%',
    height: '9px',
    position: 'relative',
    top: '-1px',
    left: '-1px',
  },
  CountdownProgress_indicator: {
    top: '-3px',
    position: 'absolute',
    width: '15px',
    height: '15px',
    border: `3px solid ${COLORS.blue}`,
    backgroundColor: COLORS.white,
    borderRadius: '12px',
    animation: 'blue-pulse 2.5s infinite',
  },
});

interface ICountdownProgressProps {
  readonly percent?: number;
  readonly className?: string;
}

export default class CountdownProgress extends PureComponent<ICountdownProgressProps> {
  public static get defaultProps() {
    return {
      percent: 50,
    };
  }

  public render() {
    const { percent, className } = this.props;

    const barStyle = {
      width: `${percent}%`,
    };
    const innerBarStyle = {
      ...generatePendingAnimationStyle(COLORS.blue, COLORS.lightBlue3),
    };
    const indicatorStyle = {
      left: `${Math.max(0, percent - 1)}%`,
    };

    return (
      <div className={`${css(styles.CountdownProgress_container)} ${className}`}>
        <div className={css(styles.CountdownProgress_bar)} style={barStyle}>
          <div className={css(styles.CountdownProgress_innerBar)} style={innerBarStyle}>
            &nbsp;
          </div>
        </div>
        <div className={css(styles.CountdownProgress_indicator)} style={indicatorStyle}></div>
      </div>
    );
  }
}
