import React from 'react';
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
  RichTextEditorImage_container: {
    width: '100%',
  },
});

export interface IImageProps {
  imageUrl: string;
}

/** Custom BLOCK rendering component. See Media Selector and BlockRenderFn */
const Image = (props: IImageProps) => {
  const { imageUrl } = props;

  return <img className={css(styles.RichTextEditorImage_container)} src={imageUrl} />;
};

export default Image;
