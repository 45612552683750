import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { withTranslation, WithTranslation } from 'react-i18next';
import Web3 from 'web3';

import {
  ITokenBalances,
  IMatch,
  IHistory,
  IListTokenWithPosition,
  ISetDetails,
} from '../../typings/index';
import { COLORS } from '../../constants/index';
import { formatTokenUnit, BigNumber, getListTokenImage } from '../../utils/index';
import darkArrow from '../../img/icons/right-arrow-dark.svg';

const styles = StyleSheet.create({
  IssuanceForm_container: {
    maxWidth: '400px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  IssuanceForm_setImage: {
    width: '50px',
    height: '50px',
    marginBottom: '20px',
    borderRadius: '50%',
  },
  IssuanceForm_title: {
    fontSize: '30px',
    fontWeight: 600,
    color: COLORS.darkBlue,
    lineHeight: 1.2,
    textAlign: 'center',
    marginBottom: '20px',
  },
  IssuanceForm_subTitle: {
    fontSize: '14px',
    lineHeight: 1.5,
    textAlign: 'center',
    marginBottom: '20px',
  },

  /* Input Field */
  IssuanceForm_issueInputContainer: {
    width: '100%',
    position: 'relative',
    marginBottom: '20px',
  },
  IssuanceForm_issueQuantityInput: {
    width: '100%',
    fontSize: '16px',
    padding: '15px',
    paddingRight: '120px',
    marginBottom: '20px',
    border: `2px solid ${COLORS.gray}`,
    borderRadius: '4px',
    ':focus': {
      borderColor: COLORS.blue,
    },
  },
  IssuanceForm_issueInputLabelContainer: {
    position: 'absolute',
    top: 15,
    right: 16,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  IssuanceForm_issueInputSymbol: {
    fontSize: '12px',
    color: COLORS.blue,
    fontWeight: 500,
    lineHeight: 1,
  },
  IssuanceForm_issueMaxButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '22px',
    fontSize: '9px',
    marginLeft: '10px',
    padding: '11px 12px 10px 12px',
    ':active': {
      backgroundColor: COLORS.blueDarkened,
    },
  },

  /* Issuance Components Table Header */
  IssuanceForm_tableHeader: {
    display: 'flex',
    paddingBottom: '10px',
    borderBottom: `1px solid ${COLORS.gray}`,
  },
  IssuanceForm_tokenLabel: {
    fontSize: '10px',
    lineHeight: 1.5,
    flex: 1,
  },
  IssuanceForm_requiredAmountLabel: {
    fontSize: '10px',
    lineHeight: 1.5,
    flex: 1,
    textAlign: 'right',
  },
  IssuanceForm_userBalanceLabel: {
    fontSize: '10px',
    lineHeight: 1.5,
    flex: 1,
    textAlign: 'right',
  },

  /* Issuance Components */
  IssuanceForm_componentContainer: {
    width: '100%',
    marginBottom: '30px',
  },
  IssuanceForm_component: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
    borderBottom: `1px solid ${COLORS.lightGray}`,
  },
  IssuanceForm_componentLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  IssuanceForm_componentIcon: {
    height: '20px',
    width: '20px',
  },
  IssuanceForm_componentSymbol: {
    fontSize: '14px',
    color: COLORS.darkBlue,
    fontWeight: 600,
    marginLeft: '10px',
  },

  IssuanceForm_componentQuantityRequired: {
    fontSize: '14px',
    color: COLORS.darkBlue,
    flex: 1,
    textAlign: 'right',
  },
  IssuanceForm_componentUserBalance: {
    fontSize: '14px',
    color: COLORS.green,
    flex: 1,
    textAlign: 'right',
  },

  /* Submit Button + Footer */
  IssuanceForm_submitButton: {
    width: '100%',
    padding: '20px',
    fontWeight: 600,
    marginBottom: '30px',
    backgroundColor: COLORS.blue,
    color: COLORS.white,
    ':hover': {
      backgroundColor: COLORS.blueDarkened,
    },
  },
  IssuanceForm_submitButtonDisabled: {
    pointerEvents: 'none',
    backgroundColor: COLORS.gray,
    ':hover': {
      backgroundColor: COLORS.gray,
    },
  },
  IssuanceForm_footerText: {
    textAlign: 'center',
    fontSize: '12px',
    marginBottom: '15px',
    color: COLORS.red,
  },

  IssuanceForm_backButton: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '40px',
    height: '40px',
    transform: 'rotate(180deg)',
    padding: '10px',
    margin: '20px',
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '50%',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: COLORS.lightGray,
    },
  },
});

type IssuanceFormProps = {
  setAddress: string;
  customV2SetPathPrefix: string;
  isIssuancePermitted: boolean;
  isSubmittingIssuanceTransaction: boolean;
  setDetails: ISetDetails;
  issuanceQuantity: string;
  requiredComponents: IListTokenWithPosition[];
  erc20BalancesByAddress: ITokenBalances;
  match: IMatch;
  history: IHistory;
  updateIssuanceQuantity: (...args: any[]) => any;
  setMaxIssuableQuantity: (...args: any[]) => any;
  issueCurrentSet: (...args: any[]) => any;
};

/**
 * @title IssuanceForm
 * @author Set Protocol
 *
 * IssuanceForm is the interface users use to input a desired issuance quantity,
 * The required underlying component quantities, and to initiate the issuance transaction.
 */
class Issuance extends PureComponent<IssuanceFormProps & WithTranslation> {
  public onUpdateIssuanceQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { updateIssuanceQuantity } = this.props;
    const value = e.target?.value;

    updateIssuanceQuantity(value);
  };

  public renderRequiredUnderlying = () => {
    const { requiredComponents, erc20BalancesByAddress, issuanceQuantity, t } = this.props;

    if (!requiredComponents) return null;

    const requiredComponentsList = requiredComponents?.map((component: IListTokenWithPosition) => {
      const { address } = component;
      const checkSumAddress = Web3.utils.toChecksumAddress(address);
      const userBalance = erc20BalancesByAddress[checkSumAddress]?.toString();
      const requiredQuantity = component?.perTokenPosition?.mul(issuanceQuantity || 0);

      return (
        <div className={css(styles.IssuanceForm_component)} key={component.address}>
          <div className={css(styles.IssuanceForm_componentLabelContainer)}>
            <img
              className={css(styles.IssuanceForm_componentIcon)}
              src={getListTokenImage(component)}
            />
            <span className={css(styles.IssuanceForm_componentSymbol)}>{component?.symbol}</span>
          </div>
          <span className={css(styles.IssuanceForm_componentQuantityRequired)}>
            {requiredQuantity.toFixed(8, BigNumber.ROUND_DOWN)}
          </span>
          <span className={css(styles.IssuanceForm_componentUserBalance)}>
            {formatTokenUnit(userBalance, component?.decimals, 8)}
          </span>
        </div>
      );
    });

    return (
      <div className={css(styles.IssuanceForm_componentContainer)}>
        <div className={css(styles.IssuanceForm_tableHeader)}>
          <span className={css(styles.IssuanceForm_tokenLabel)}>{t('table.token')}</span>
          <span className={css(styles.IssuanceForm_requiredAmountLabel)}>
            {t('table.amount-required')}
          </span>
          <span className={css(styles.IssuanceForm_userBalanceLabel)}>
            {t('table.your-balance')}
          </span>
        </div>
        {requiredComponentsList}
      </div>
    );
  };

  render() {
    const {
      setAddress,
      customV2SetPathPrefix,
      setDetails,
      issuanceQuantity,
      isIssuancePermitted,
      isSubmittingIssuanceTransaction,
      history,
      issueCurrentSet,
      setMaxIssuableQuantity,
      t,
    } = this.props;

    return (
      <div className={css(styles.IssuanceForm_container)}>
        <img
          src={darkArrow}
          className={css(styles.IssuanceForm_backButton)}
          onClick={() => history.push(`${customV2SetPathPrefix}/${setAddress}`)}
        />
        <img
          className={css(styles.IssuanceForm_setImage)}
          src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/issue.svg"
        />
        <p className={css(styles.IssuanceForm_title)}>Issue {setDetails?.name}</p>
        <p className={css(styles.IssuanceForm_subTitle)}>
          {t('issue-form.title', { tokenSymbol: setDetails?.symbol })}
        </p>

        <div className={css(styles.IssuanceForm_issueInputContainer)}>
          <input
            className={css(styles.IssuanceForm_issueQuantityInput)}
            type="number"
            value={issuanceQuantity}
            onChange={this.onUpdateIssuanceQuantity}
          />
          <div className={css(styles.IssuanceForm_issueInputLabelContainer)}>
            <span className={css(styles.IssuanceForm_issueInputSymbol)}>{setDetails?.symbol}</span>
            <button
              className={css(styles.IssuanceForm_issueMaxButton)}
              type="button"
              onClick={setMaxIssuableQuantity}
            >
              MAX
            </button>
          </div>
        </div>

        {this.renderRequiredUnderlying()}

        <button
          className={css(
            styles.IssuanceForm_submitButton,
            (!isIssuancePermitted || isSubmittingIssuanceTransaction) &&
              styles.IssuanceForm_submitButtonDisabled,
          )}
          disabled={!isIssuancePermitted || isSubmittingIssuanceTransaction}
          type="button"
          onClick={issueCurrentSet}
        >
          {t('buttons.issue')}
        </button>

        {!isIssuancePermitted && (
          <>
            <span className={css(styles.IssuanceForm_footerText)}>
              {t('issue-form.error-title')}{' '}
              {t('issue-form.error-description', { setName: setDetails?.name })}
            </span>
          </>
        )}
      </div>
    );
  }
}
export default withTranslation('issue-and-redeem')(Issuance);
