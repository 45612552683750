import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { toast } from 'react-toastify';

import IssuanceApproval from '../../containers/IssuanceApprovalV2';
import IssuanceFormWrapper from '../../containers/IssuanceFormWrapperV2';
import IssuanceShimmer from './IssuanceShimmer';
import IssuanceDisabled from './IssuanceDisabled';
import DebtIssuanceApproval from '../../containers/DebtIssuanceApproval';
import DebtIssuanceFormWrapper from '../../containers/DebtIssuanceFormWrapper';
import PerpIssuanceFormWrapper from '../../containers/PerpIssuanceFormWrapper';
import { IHistory, IMatch, ISetDetails } from '../../typings/index';
import { WINDOW_DIMENSIONS, COLORS } from '../../constants/index';
import PerpIssuanceApproval from '../../containers/PerpIssuanceApproval';

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  Issuance_container: {
    position: 'relative',
    width: '60vw',
    maxWidth: '500px',
    margin: '40px auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '40px',
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '4px',
    [MOBILE_MEDIA_QUERY]: {
      width: '90vw',
      padding: '20px',
    },
  },
});

type IssuanceProps = {
  setAddress: string;
  customV2SetPathPrefix: string;
  hasAllApprovals: boolean;
  hasAllDebtIssuanceApprovals: boolean;
  hasAllPerpIssuanceApprovals: boolean;
  isFetchingSetDetails: boolean;
  isFetchingIssuanceModuleEnabled: boolean;
  isFetchingDebtIssuanceComponents: boolean;
  isFetchingPerpIssuanceComponents: boolean;
  isIssuanceModuleEnabled: boolean;
  isDebtIssuanceModuleEnabled: boolean;
  isPerpIssuanceModuleEnabled: boolean;
  history: IHistory;
  match: IMatch;
  account: string;
  setDetails: ISetDetails;
  isWrongNetwork: boolean;
  onFetchEnabledModules: (...args: any[]) => any;
  onFetchDebtIssuanceComponents: (...args: any[]) => any;
  onFetchPerpIssuanceComponents: (...args: any[]) => any;
  onFetchAllowancesForCurrentSet: (...args: any[]) => any;
  onFetchDebtIssuanceAllowancesForCurrentSet: (...args: any[]) => any;
  onFetchPerpIssuanceAllowancesForCurrentSet: (...args: any[]) => any;
  onFetchCurrentSetTokenBalances: (...args: any[]) => any;
  onFetchGasPrice: (...args: any[]) => any;
  onFetchCoingeckoTokenList: (...args: any[]) => any;
  onSetCurrentlyViewingSetAddress: (...args: any[]) => any;
  onFetchSetDetailsByAddress: (...args: any[]) => any;
};

type IssuanceState = {
  fullyLoaded: boolean;
};

/**
 * @title Issuance
 * @author Set Protocol
 *
 * Walks a user through approving and issuing a V2 Set.
 * Top level container which fetches data and renders Approval Form or Issuance Form.
 */
class Issuance extends PureComponent<IssuanceProps, IssuanceState> {
  state = {
    fullyLoaded: false,
  };

  async componentDidMount() {
    document.title = 'Issue Set | TokenSets';
    await this.fetchInitialIssuanceRequirements();
  }

  async fetchInitialIssuanceRequirements() {
    const {
      onFetchEnabledModules,
      onFetchDebtIssuanceComponents,
      onFetchPerpIssuanceComponents,
      onFetchSetDetailsByAddress,
      onSetCurrentlyViewingSetAddress,
      onFetchCoingeckoTokenList,
      onFetchCurrentSetTokenBalances,
      onFetchAllowancesForCurrentSet,
      onFetchDebtIssuanceAllowancesForCurrentSet,
      onFetchPerpIssuanceAllowancesForCurrentSet,
      onFetchGasPrice,
      match,
    } = this.props;

    this.setState({ fullyLoaded: false });
    const setAddress = match.params.set;
    await onFetchCoingeckoTokenList();
    await onSetCurrentlyViewingSetAddress(setAddress);
    const setDetails = await onFetchSetDetailsByAddress(setAddress);

    if (!setDetails) {
      return;
    }

    await onFetchCurrentSetTokenBalances();
    const enabledModules = await onFetchEnabledModules(setAddress);

    if (enabledModules.isDebtIssuanceEnabled || enabledModules.isDebtIssuanceV2Enabled) {
      await onFetchDebtIssuanceComponents();
      onFetchDebtIssuanceAllowancesForCurrentSet();
    }

    if (enabledModules.isPerpIssuanceEnabled) {
      await onFetchPerpIssuanceComponents();
      onFetchPerpIssuanceAllowancesForCurrentSet();
    }

    await onFetchAllowancesForCurrentSet();
    await onFetchGasPrice();
    this.setState({ fullyLoaded: true });
  }

  render() {
    const {
      setAddress,
      customV2SetPathPrefix,
      setDetails,
      hasAllApprovals,
      hasAllDebtIssuanceApprovals,
      hasAllPerpIssuanceApprovals,
      isFetchingSetDetails,
      isFetchingDebtIssuanceComponents,
      isFetchingPerpIssuanceComponents,
      account,
      match,
      history,
      isFetchingIssuanceModuleEnabled,
      isIssuanceModuleEnabled,
      isDebtIssuanceModuleEnabled,
      isWrongNetwork,
      isPerpIssuanceModuleEnabled,
    } = this.props;
    const { fullyLoaded } = this.state;

    if (isWrongNetwork) {
      toast.warn('Sorry, you cannot issue while on an unsupported network.');
      history.push(`${customV2SetPathPrefix}/${match.params.set}`);
    }

    if (
      !fullyLoaded ||
      isFetchingSetDetails ||
      isFetchingIssuanceModuleEnabled ||
      isFetchingDebtIssuanceComponents ||
      isFetchingPerpIssuanceComponents
    ) {
      return <IssuanceShimmer />;
    }

    if (!isIssuanceModuleEnabled && !isDebtIssuanceModuleEnabled && !isPerpIssuanceModuleEnabled) {
      return (
        <IssuanceDisabled
          setAddress={setAddress}
          customV2SetPathPrefix={customV2SetPathPrefix}
          setDetails={setDetails}
          account={account}
          history={history}
          match={match}
        />
      );
    }

    if (isDebtIssuanceModuleEnabled && !hasAllDebtIssuanceApprovals) {
      return (
        <div className={css(styles.Issuance_container)}>
          <DebtIssuanceApproval history={history} />
        </div>
      );
    }

    if (isDebtIssuanceModuleEnabled && hasAllDebtIssuanceApprovals) {
      return (
        <div className={css(styles.Issuance_container)}>
          <DebtIssuanceFormWrapper match={match} history={history} />
        </div>
      );
    }

    if (isPerpIssuanceModuleEnabled && !hasAllPerpIssuanceApprovals) {
      return (
        <div className={css(styles.Issuance_container)}>
          <PerpIssuanceApproval history={history} />
        </div>
      );
    }

    if (isPerpIssuanceModuleEnabled && hasAllPerpIssuanceApprovals) {
      return (
        <div className={css(styles.Issuance_container)}>
          <PerpIssuanceFormWrapper match={match} history={history} />
        </div>
      );
    }

    if (!hasAllApprovals) {
      return (
        <div className={css(styles.Issuance_container)}>
          <IssuanceApproval history={history} />
        </div>
      );
    }

    return (
      <div className={css(styles.Issuance_container)}>
        <IssuanceFormWrapper match={match} history={history} />
      </div>
    );
  }
}
export default Issuance;
