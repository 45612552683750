import { connect } from 'react-redux';

import PerpRedemptionForm from './PerpRedemptionForm';
import { resetTransactionWatcher } from '../../actions/transactionWatcherActions';
import { updateRedemptionQuantity } from '../../actions/redemptionV2Actions';
import {
  setMaxPerpRedeemableQuantity,
  perpRedeemCurrentSet,
  fetchFeesForRedeemQuantity,
} from '../../actions/perpRedemptionActions';
import { currentSetDetailsSelector } from '../../selectors/setDetailsSelectors';
import {
  userHasSufficientFundsForPerpRedemptionQuantity,
  isPerpRedemptionReadySelector,
  perpRedemptionInputComponentsSelector,
  perpRedemptionOutputComponentsSelector,
  minTokenAmountsOutSelector,
} from '../../selectors/perpIssuanceRedemptionSelectors';
import {
  redemptionInputQuantityV2Selector,
  erc20BalancesSelector,
  transactionIdSelector,
  windowDimensionSelector,
  customV2SetPathPrefixSelector,
  setDetailsCurrentSetAddressSelector,
  maxSlippagePercentageAllowedSelector,
  perpRedemptionFeesSelector,
  isFetchingPerpRedemptionFeesSelector,
} from '../../selectors/index';
import { setMaxAllowedSlippagePercentage } from '../../actions/issuanceV2Actions';

const mapStateToProps = (state: any) => {
  const isPerpRedemptionReady = isPerpRedemptionReadySelector(state);
  const userHasSufficientFunds = userHasSufficientFundsForPerpRedemptionQuantity(state);
  const setDetails = currentSetDetailsSelector(state);
  const inputComponents = perpRedemptionInputComponentsSelector(state);
  const outputComponents = perpRedemptionOutputComponentsSelector(state);
  const redemptionQuantity = redemptionInputQuantityV2Selector(state);
  const currentTransactionId = transactionIdSelector(state);
  const erc20BalancesByAddress = erc20BalancesSelector(state);
  const windowDimensions = windowDimensionSelector(state);
  const customV2SetPathPrefix = customV2SetPathPrefixSelector(state);
  const setAddress = setDetailsCurrentSetAddressSelector(state);
  const maxSlippagePercentageAllowed = maxSlippagePercentageAllowedSelector(state);
  const perpRedemptionFees = perpRedemptionFeesSelector(state);
  const isFetchingPerpRedemptionFees = isFetchingPerpRedemptionFeesSelector(state);
  const minTokenAmountsOut = minTokenAmountsOutSelector(state);

  return {
    perpRedemptionFees,
    isFetchingPerpRedemptionFees,
    setAddress,
    customV2SetPathPrefix,
    isPerpRedemptionReady,
    userHasSufficientFunds,
    setDetails,
    inputComponents,
    outputComponents,
    redemptionQuantity,
    currentTransactionId,
    erc20BalancesByAddress,
    windowDimensions,
    maxSlippagePercentageAllowed,
    minTokenAmountsOut,
  };
};

const mapDispatchToProps = {
  updateRedemptionQuantity,
  setMaxRedeemableQuantity: setMaxPerpRedeemableQuantity,
  setMaxAllowedSlippagePercentage,
  perpRedeemCurrentSet,
  resetTransactionWatcher,
  fetchFeesForRedeemQuantity,
};

export default connect(mapStateToProps, mapDispatchToProps)(PerpRedemptionForm);
