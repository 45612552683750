import keyMirror from 'keymirror';

const DATA_SELECTS = keyMirror({
  SET_DETAIL_HOLDINGS_COL_BODY: null,
  SET_DETAIL_HOLDINGS_COL_SWITCH_BTN: null,
  SET_DETAIL_HOLDINGS_COL_SWITCH_BTN_TEXT: null,
  SET_DETAIL_HOLDINGS_COL_HEADER: null,
  SET_DETAIL_HOLDINGS_COL_NETWORK_POPUP: null,
  SET_DETAIL_HOLDINGS_COL_HOLDING_VALUE: null,
});

export default DATA_SELECTS;
