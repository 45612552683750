export enum TableDataSource {
  default = 'default',
  portfolios = 'portfolios',
  customSets = 'customSets',
  communitySets = 'communitySets',
}

export const tableDisplayQuantityByDataSource = {
  [TableDataSource.default]: 20,
  [TableDataSource.portfolios]: 20,
  [TableDataSource.communitySets]: 20,
  [TableDataSource.customSets]: 20,
};

export enum SortType {
  marketCap = 'marketCap',
  maxDrawdown = 'maxDrawdown',
  price = 'price',
  oneDay = 'oneDay',
  oneWeek = 'oneWeek',
  oneMonth = 'oneMonth',
  threeMonth = 'threeMonth',
  cumulativeChange = 'cumulativeChange',
  setHolders = 'setHolders',
  traderMarketCap = 'traderMarketCap',
  currentApy = 'currentApy',
}
