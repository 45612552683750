import React, { FunctionComponent } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { withTranslation, WithTranslation } from 'react-i18next';

import { SetCategory } from '../../reducers/exploreReducer';
import { IWindowDimensions } from '../../typings/index';
import { WINDOW_DIMENSIONS, COLORS } from '../../constants/index';

import thematicFilterIcon from '../../img/icons/thematic-filter.svg';
import leverageFilterIcon from '../../img/icons/leverage-filter.svg';
import yieldFilterIcon from '../../img/icons/yield-filter.svg';
import uncategorizedFilterIcon from '../../img/icons/uncategorized-filter.svg';
import thematicActiveFilterIcon from '../../img/icons/thematic-filter-active.svg';
import leverageActiveFilterIcon from '../../img/icons/leverage-filter-active.svg';
import yieldActiveFilterIcon from '../../img/icons/yield-filter-active.svg';
import uncategorizedActiveFilterIcon from '../../img/icons/uncategorized-filter-active.svg';

const { MOBILE_MEDIA_QUERY, TABLET_LANDSCAPE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

type ICategoryFilterProps = {
  filteredSetCategory?: SetCategory;
  onSetCategoryFilter?: (...args: any[]) => any;
  windowDimension: IWindowDimensions;
} & WithTranslation;

const styles = StyleSheet.create({
  CategoryFilter_categoryContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '50px auto',
    width: '100%',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      width: '100%',
      margin: '40px auto 0px',
    },
  },
  CategoryFilter_categoryOptionsGrid: {
    padding: '0',
    display: 'flex',
    justifyContent: 'space-between',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      flexWrap: 'wrap',
      zIndex: 900,
      position: 'relative',
      display: 'flex',
      width: '100%',
      textAlign: 'center',
      border: 'none',
    },
  },
  CategoryFilter_categoryOptionsCell: {
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '13px',
    textAlign: 'center',
    width: '24%',
    height: '88px',
    background: COLORS.whiteAlpha40,
    color: COLORS.darkBlue,
    cursor: 'pointer',
    border: `1px solid ${COLORS.blueGray2}`,
    borderRadius: '12px',
    padding: '16px',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      width: '48%',
    },
  },
  CategoryFilter_category: {
    width: '100%',
    [MOBILE_MEDIA_QUERY]: {
      display: 'block',
    },
  },
  CategoryFilter_buttonActive: {
    background: COLORS.white,
    color: COLORS.blue,
  },
  CategoryFilter_icon: {
    height: '22px',
    width: '22px',
  },
  CategoryFilter_iconContainer: {
    background: COLORS.whiteAlpha64,
    padding: '16px',
    borderRadius: '8px',
    borderColor: COLORS.blueGray2,
    border: '1px solid',
    marginRight: '16px',
  },
  CategoryFilter_iconContainerActive: {
    background: COLORS.blueGradient,
    borderColor: COLORS.borderBlueGradient,
  },
  CategoryFilter_subtitle: {
    color: COLORS.disabledGrayHex,
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      fontSize: '10px',
      lineHeight: '12px',
    },
  },
  CategoryFilter_labelContainer: {
    textAlign: 'left',
    fontSize: '16px',
    lineHeight: '24px',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
});

const CategoryFilter: FunctionComponent<ICategoryFilterProps> = ({
  // windowDimension,
  filteredSetCategory,
  onSetCategoryFilter,
}: ICategoryFilterProps) => {
  const thematicActive = filteredSetCategory === SetCategory.THEMATIC_EXPOSURE;
  const leverageActive = filteredSetCategory === SetCategory.LEVERAGED_EXPOSURE;
  const yieldActive = filteredSetCategory === SetCategory.YIELD_BEARING;
  const uncategorizedActive = filteredSetCategory === SetCategory.UNCATEGORIZED;

  return (
    <div className={css(styles.CategoryFilter_categoryContainer)}>
      <div className={css(styles.CategoryFilter_category)}>
        <div className={css(styles.CategoryFilter_categoryOptionsGrid)}>
          <div
            className={css(
              styles.CategoryFilter_categoryOptionsCell,
              thematicActive ? styles.CategoryFilter_buttonActive : undefined,
            )}
            onClick={() => onSetCategoryFilter(SetCategory.THEMATIC_EXPOSURE)}
          >
            <div
              className={css(
                styles.CategoryFilter_iconContainer,
                thematicActive ? styles.CategoryFilter_iconContainerActive : undefined,
              )}
            >
              <img
                alt="Thematic Filter icon"
                className={css(styles.CategoryFilter_icon)}
                src={thematicActive ? thematicActiveFilterIcon : thematicFilterIcon}
              />
            </div>
            <div className={css(styles.CategoryFilter_labelContainer)}>
              Thematic Exposure
              <div className={css(styles.CategoryFilter_subtitle)}>Built around a single theme</div>
            </div>
          </div>
          <div
            className={css(
              styles.CategoryFilter_categoryOptionsCell,
              leverageActive ? styles.CategoryFilter_buttonActive : undefined,
            )}
            onClick={() => onSetCategoryFilter(SetCategory.LEVERAGED_EXPOSURE)}
          >
            <div
              className={css(
                styles.CategoryFilter_iconContainer,
                leverageActive ? styles.CategoryFilter_iconContainerActive : undefined,
              )}
            >
              <img
                alt="Leverage Filter icon"
                className={css(styles.CategoryFilter_icon)}
                src={leverageActive ? leverageActiveFilterIcon : leverageFilterIcon}
              />
            </div>
            <div className={css(styles.CategoryFilter_labelContainer)}>
              Leveraged Exposure
              <div className={css(styles.CategoryFilter_subtitle)}>Borrow capital to trade</div>
            </div>
          </div>
          <div
            className={css(
              styles.CategoryFilter_categoryOptionsCell,
              yieldActive ? styles.CategoryFilter_buttonActive : undefined,
            )}
            onClick={() => onSetCategoryFilter(SetCategory.YIELD_BEARING)}
          >
            <div
              className={css(
                styles.CategoryFilter_iconContainer,
                yieldActive ? styles.CategoryFilter_iconContainerActive : undefined,
              )}
            >
              <img
                alt="Yield Filter icon"
                className={css(styles.CategoryFilter_icon)}
                src={yieldActive ? yieldActiveFilterIcon : yieldFilterIcon}
              />
            </div>
            <div className={css(styles.CategoryFilter_labelContainer)}>
              Yield Bearing
              <div className={css(styles.CategoryFilter_subtitle)}>
                Earn by depositing or staking
              </div>
            </div>
          </div>
          <div
            className={css(
              styles.CategoryFilter_categoryOptionsCell,
              uncategorizedActive ? styles.CategoryFilter_buttonActive : undefined,
            )}
            onClick={() => onSetCategoryFilter(SetCategory.UNCATEGORIZED)}
          >
            <div
              className={css(
                styles.CategoryFilter_iconContainer,
                uncategorizedActive ? styles.CategoryFilter_iconContainerActive : undefined,
              )}
            >
              <img
                alt="Uncategorized Filter icon"
                className={css(styles.CategoryFilter_icon)}
                src={uncategorizedActive ? uncategorizedActiveFilterIcon : uncategorizedFilterIcon}
              />
            </div>
            <div className={css(styles.CategoryFilter_labelContainer)}>
              Uncategorized
              <div className={css(styles.CategoryFilter_subtitle)}>Miscellaneous, not audited</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation('explore')(CategoryFilter);
