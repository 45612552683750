import { StyleSheet, css } from 'aphrodite';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Container, Header, Grid, Button, Checkbox } from 'semantic-ui-react';
import { DotDivider } from '../../components/index';
import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';
import { IWindowDimensions } from '../../typings/index';

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  // Animation
  Press_colorSchemeTextHeader: {
    color: COLORS.darkBlue,
    fontSize: '52px',
    fontWeight: 600,
    lineHeight: '57px',
  },
  Press_colorSchemeTextSubheader: {
    position: 'relative',
    marginTop: '23px',
    lineHeight: '42px',
    fontSize: '32px',
    color: COLORS.darkGray,
    marginBottom: '15.3px',
  },
  Press_colorSchemeText: {
    paddingLeft: '0px',
    marginBottom: '6px',
  },
  Press_paletteSectionContainer: {
    marginTop: '113px',
  },
  Press_logoIconAssetPack: {
    marginTop: '40px',
    [MOBILE_MEDIA_QUERY]: {
      marginTop: '60px',
    },
  },
  Press_colorSchemeLogoImg: {
    height: '500px',
  },
  Press_setDarkLogoAssetBackGround: {
    backgroundColor: COLORS.darkBlue,
    border: '1px solid',
    borderColor: COLORS.gray,
    borderRadius: '0 0 4px 4px',
  },
  Press_setLightLogoAssetBackground: {
    border: '1px solid',
    borderColor: COLORS.gray,
    borderRadius: '0 0 4px 4px',
  },
  Press_dosDontCheck: {
    width: '24px',
    height: '24px',
    marginRight: '16px',
    verticalAlign: 'middle',
  },
  Press_doText: {
    fontSize: '24px',
    LineHeight: '42px',
    fontWeight: 500,
    display: 'inline',
    color: COLORS.green,
    verticalAlign: 'middle',
  },
  Press_dontText: {
    fontSize: '24px',
    LineHeight: '42px',
    fontWeight: 500,
    display: 'inline',
    color: COLORS.red,
    verticalAlign: 'middle',
  },
  Press_doDescription: {
    color: COLORS.darkBlue,
    lineHeight: '24px',
    fontSize: '18px',
    marginTop: '20px',
    marginBottom: '24px',
  },
  Press_dontDescription: {
    color: COLORS.darkBlue,
    lineHeight: '24px',
    fontSize: '18px',
    marginTop: '20px',
    marginBottom: '24px',
  },
  Press_doDontImage: {
    width: '360px',
    marginTop: '-20px',
  },
  Press_doDontListItem: {
    marginBottom: '10px',
  },
  Press_setLogoAssetImage: {
    height: '96px',
    width: '196.36px',
    marginTop: '75px',
    marginLeft: '30%',
    [MOBILE_MEDIA_QUERY]: {
      marginTop: '10px',
      marginLeft: '20%',
    },
  },
  Press_acceptTermsTextContainer: {
    textAlign: 'right',
    color: COLORS.darkBlue,
    fontSize: '18px',
    lineHeight: '24px',
  },
  Press_acceptTermsButton: {
    height: '62px',
    background: COLORS.blue,
    [MOBILE_MEDIA_QUERY]: {
      width: '100%',
      marginTop: '16px',
    },
  },
  Press_acceptTermsButtonDisabled: {
    background: COLORS.darkGray,
    height: '62px',
    [MOBILE_MEDIA_QUERY]: {
      width: '100%',
      marginTop: '16px',
    },
  },
  Press_acceptTermsDisabledButtonText: {
    color: COLORS.darkGray,
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    verticalAlign: 'center',
    paddingBottom: '10px',
  },
  Press_acceptTermsButtonText: {
    textAlign: 'center',
    color: COLORS.white,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    verticalAlign: 'center',
    paddingBottom: '10px',
    marginRight: '10px',
    [MOBILE_MEDIA_QUERY]: {
      marginRight: '0px',
    },
  },
  Press_colorSchemeBrandingDarkBlue: {
    height: '125.56px',
    backgroundColor: COLORS.darkBlue,
  },
  Press_colorSchemeBrandingLightBlue: {
    height: '125.56px',
    backgroundColor: COLORS.blue,
  },
  Press_colorSchemeBrandingGreen: {
    height: '125.56px',
    backgroundColor: COLORS.green,
  },
  Press_colorSchemeBrandingRed: {
    height: '125.56px',
    backgroundColor: COLORS.red,
  },
  Press_colorSchemeColorText: {
    textAlign: 'left',
    color: COLORS.white,
    lineHeight: '1.5em',
  },
  Press_colorSchemeColorTextContainer: {
    fontFamily: 'SF Mono',
    fontSize: '16px',
    lineHeight: '16px',
    paddingLeft: '46px',
    paddingTop: '30px',
  },
  Press_colorSchemeColorTextLabel: {
    fontWeight: 600,
    display: 'inline',
  },
  Press_assetDownloadSubmitContainer: {
    marginBottom: '131px',
    [MOBILE_MEDIA_QUERY]: {
      marginTop: '120px',
      marginBottom: '60px',
    },
  },
  Press_setPressReleaseColorSchemeContainer: {
    marginBottom: '161px',
  },
  Press_acceptTermsButtonTextLabel: {
    display: 'inline',
    marginRight: '20px',
    [MOBILE_MEDIA_QUERY]: {
      display: 'block',
      marginTop: '10px',
      textAlign: 'center',
      marginRight: 'auto',
    },
  },
  Press_sectionOuterContainer: {
    position: 'relative',
  },
  Press_sectionContainer: {
    marginBottom: '40px',
    marginTop: '40px',
    position: 'relative',
    [MOBILE_MEDIA_QUERY]: {
      marginBottom: '30px',
      marginTop: '30px',
    },
  },
  Press_subHeader: {
    fontWeight: 400,
    [MOBILE_MEDIA_QUERY]: {
      fontSize: '24px',
    },
  },
  Press_fadeInUp: {
    webkitAnimation: 'fade-in-up 2s ease-out' /* Safari, Chrome and Opera > 12.1 */,
    mozAnimation: 'fade-in-up 2s ease-out' /* Firefox < 16 */,
    msAnimation: 'fade-in-up 2s ease-out' /* Internet Explorer */,
    oAnimation: 'fade-in-up 2s ease-out' /* Opera < 12.1 */,
    animation: 'fade-in-up 2s ease-out',
  },
  Press_header: {
    fontSize: '114px',
    lineHeight: '128px',
    height: '280px',
    [MOBILE_MEDIA_QUERY]: {
      fontSize: '46px',
      lineHeight: '54px',
      height: '120px',
    },
  },
  Press_setTeal: {
    position: 'absolute',
    left: '-120px',
    bottom: '10px',
    height: '200px',
    width: 'auto',
    zIndex: -1,
    [MOBILE_MEDIA_QUERY]: {
      height: '70px',
      left: '-50px',
      bottom: '-20px',
    },
  },
  Press_setBlue: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    height: '100px',
    width: 'auto',
    zIndex: -1,
    [MOBILE_MEDIA_QUERY]: {
      height: '40px',
      bottom: 0,
      right: '-10px',
    },
  },
  Press_setLightBlue: {
    position: 'absolute',
    left: '40px',
    top: '20px',
    height: '30px',
    width: 'auto',
    zIndex: -1,
    [MOBILE_MEDIA_QUERY]: {
      left: '10px',
      top: '10px',
      height: '12px',
    },
  },
  Press_setYellow: {
    position: 'absolute',
    right: '-20px',
    top: '40px',
    height: '50px',
    width: 'auto',
    zIndex: -1,
    [MOBILE_MEDIA_QUERY]: {
      height: '20px',
      right: '-20px',
      top: 0,
    },
  },
  Press_dividerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  Press_resourceDownloadButtonIndicator: {
    transform: 'translateY(5px)',
    marginLeft: '6px',
  },
  Press_resourceDownloadAcceptCheckbox: {
    transform: 'translateY(2px)',
    marginRight: '5px',
  },
});

interface IPressProps {
  windowDimension: IWindowDimensions;
}
interface IPressState {
  isTermsAccepted: boolean;
}

class Press extends PureComponent<IPressProps, IPressState> {
  state = {
    isTermsAccepted: false,
  };

  componentDidMount() {
    document.title = 'Press | TokenSets';
  }

  acceptTermsToggle = () => {
    this.setState({ isTermsAccepted: !this.state.isTermsAccepted });
  };

  render() {
    const { isTermsAccepted } = this.state;
    return (
      <div>
        <div className={css(styles.Press_sectionOuterContainer)}>
          <Container className={css(styles.Press_sectionContainer)} textAlign="center">
            <Header as="h2" className={css(styles.Press_subHeader, styles.Press_fadeInUp)}>
              Brand with our
            </Header>
            <Header as="h1" className={css(styles.Press_header, styles.Press_fadeInUp)}>
              Press Resources
            </Header>
            <img
              alt="Teal Set"
              className={css(styles.Press_setTeal, styles.Press_fadeInUp)}
              src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/set-teal.svg"
            />
            <img
              alt="Blue Set"
              className={css(styles.Press_setBlue, styles.Press_fadeInUp)}
              src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/set-blue.svg"
            />
            <img
              alt="Light Blue Set"
              className={css(styles.Press_setLightBlue, styles.Press_fadeInUp)}
              src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/set-light-blue.svg"
            />
            <img
              alt="Yellow Set"
              className={css(styles.Press_setYellow, styles.Press_fadeInUp)}
              src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/set-yellow.svg"
            />
          </Container>
        </div>
        <div className={css(styles.Press_dividerContainer, styles.Press_fadeInUp)}>
          <DotDivider />
        </div>
        <Container className={css(styles.Press_paletteSectionContainer)}>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={10} className={css(styles.Press_colorSchemeText)}>
                <div className={css(styles.Press_colorSchemeTextHeader)}>Our brand guidelines</div>
                <div className={css(styles.Press_colorSchemeTextSubheader)}>
                  Learn the basics of our branding.
                </div>
                <div>
                  <img
                    src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/green-check.svg"
                    className={css(styles.Press_dosDontCheck)}
                  />
                  <div className={css(styles.Press_doText)}>Dos</div>
                </div>
                <div>
                  <div className={css(styles.Press_doDescription)}>
                    <div className={css(styles.Press_doDontListItem)}>
                      - Maintain shape, proportions, and color.{' '}
                    </div>
                    <div className={css(styles.Press_doDontListItem)}>
                      - Use enough clearspace around the Set logo.
                    </div>
                    <div className={css(styles.Press_doDontListItem)}>
                      - Use a high resolution version of the Set logo, using the SVG format
                      <br /> whenever possible.
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/red-x.svg"
                    className={css(styles.Press_dosDontCheck)}
                  />
                  <div className={css(styles.Press_dontText)}>Don'ts</div>
                  <div>
                    <div className={css(styles.Press_dontDescription)}>
                      <div className={css(styles.Press_doDontListItem)}>
                        - Don’t distort the Set logo.
                      </div>
                      <div className={css(styles.Press_doDontListItem)}>
                        - Don’t misrepresent the Set brand.
                      </div>
                      <div className={css(styles.Press_doDontListItem)}>
                        - Don’t use a low resolution version of the Set logo.
                      </div>
                    </div>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column width={6}>
                <img
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/logos-dos-donts.png"
                  className={css(styles.Press_doDontImage)}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className={css(styles.Press_logoIconAssetPack)}></Grid.Row>
            <Grid.Row>
              <Grid.Column width={16} className={css(styles.Press_colorSchemeText)}>
                <div className={css(styles.Press_colorSchemeTextHeader)}>
                  Logos and Icons Asset Pack
                </div>
                <div className={css(styles.Press_colorSchemeTextSubheader)}>
                  Download our most popular assets and logos.
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className={css(styles.Press_colorSchemeLogoImg)}>
              <Grid.Column width={8} className={css(styles.Press_setLightLogoAssetBackground)}>
                <img
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/set-logo-color.svg"
                  className={css(styles.Press_setLogoAssetImage)}
                />
              </Grid.Column>
              <Grid.Column width={8} className={css(styles.Press_setLightLogoAssetBackground)}>
                <img
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/set-logo-icon-color.svg"
                  className={css(styles.Press_setLogoAssetImage)}
                />
              </Grid.Column>
              <Grid.Column width={8} className={css(styles.Press_setDarkLogoAssetBackGround)}>
                <img
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/set-logo-white.svg"
                  className={css(styles.Press_setLogoAssetImage)}
                />
              </Grid.Column>
              <Grid.Column width={8} className={css(styles.Press_setDarkLogoAssetBackGround)}>
                <img
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/set-logo-icon-white.svg"
                  className={css(styles.Press_setLogoAssetImage)}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className={css(styles.Press_assetDownloadSubmitContainer)}>
              <Grid.Column>
                <div className={css(styles.Press_acceptTermsTextContainer)}>
                  <div className={css(styles.Press_acceptTermsButtonTextLabel)}>
                    <Checkbox
                      onChange={this.acceptTermsToggle}
                      className={css(styles.Press_resourceDownloadAcceptCheckbox)}
                    />{' '}
                    I have read and accept the guidelines for use.
                  </div>
                  <Button
                    className={
                      isTermsAccepted
                        ? css(styles.Press_acceptTermsButton)
                        : css(styles.Press_acceptTermsButtonDisabled)
                    }
                    href="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/set-logo-assets.zip"
                    download
                    disabled={!isTermsAccepted}
                  >
                    <div className={css(styles.Press_acceptTermsButtonText)}>
                      Download Assets{' '}
                      <img
                        src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/download.svg"
                        className={css(styles.Press_resourceDownloadButtonIndicator)}
                      />
                    </div>
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className={css(styles.Press_setPressReleaseColorSchemeContainer)}>
              <Grid.Column width={8} className={css(styles.Press_colorSchemeText)}>
                <div className={css(styles.Press_colorSchemeTextHeader)}>Our brand colors</div>
                <div className={css(styles.Press_colorSchemeTextSubheader)}>
                  Official Set colors used in our branding.
                </div>
              </Grid.Column>
              <Grid.Column width={8} className={css(styles.Press_colorSchemeText)}>
                <Grid.Row className={css(styles.Press_colorSchemeBrandingLightBlue)}>
                  <div className={css(styles.Press_colorSchemeColorTextContainer)}>
                    <div className={css(styles.Press_colorSchemeColorText)}>
                      <div className={css(styles.Press_colorSchemeColorTextLabel)}>cmyk : </div>
                      72/73/0/10
                    </div>
                    <div className={css(styles.Press_colorSchemeColorText)}>
                      <div className={css(styles.Press_colorSchemeColorTextLabel)}>rgb : </div>
                      45/44/229
                    </div>
                    <div className={css(styles.Press_colorSchemeColorText)}>
                      <div className={css(styles.Press_colorSchemeColorTextLabel)}>hex : </div>
                      #2D2CE5
                    </div>
                  </div>
                </Grid.Row>
                <Grid.Row className={css(styles.Press_colorSchemeBrandingDarkBlue)}>
                  <div className={css(styles.Press_colorSchemeColorTextContainer)}>
                    <div className={css(styles.Press_colorSchemeColorText)}>
                      <div className={css(styles.Press_colorSchemeColorTextLabel)}>cmyk : </div>
                      20/23/0/69
                    </div>
                    <div className={css(styles.Press_colorSchemeColorText)}>
                      <div className={css(styles.Press_colorSchemeColorTextLabel)}>rgb : </div>
                      29/20/79
                    </div>
                    <div className={css(styles.Press_colorSchemeColorText)}>
                      <div className={css(styles.Press_colorSchemeColorTextLabel)}>hex : </div>
                      #1D144F
                    </div>
                  </div>
                </Grid.Row>
                <Grid.Row className={css(styles.Press_colorSchemeBrandingGreen)}>
                  <div className={css(styles.Press_colorSchemeColorTextContainer)}>
                    <div className={css(styles.Press_colorSchemeColorText)}>
                      <div className={css(styles.Press_colorSchemeColorTextLabel)}>cmyk : </div>
                      53/0/32/31
                    </div>
                    <div className={css(styles.Press_colorSchemeColorText)}>
                      <div className={css(styles.Press_colorSchemeColorTextLabel)}>rgb : </div>
                      43/177/96
                    </div>
                    <div className={css(styles.Press_colorSchemeColorText)}>
                      <div className={css(styles.Press_colorSchemeColorTextLabel)}>hex : </div>
                      #2BB160
                    </div>
                  </div>
                </Grid.Row>
                <Grid.Row className={css(styles.Press_colorSchemeBrandingRed)}>
                  <div className={css(styles.Press_colorSchemeColorTextContainer)}>
                    <div className={css(styles.Press_colorSchemeColorText)}>
                      <div className={css(styles.Press_colorSchemeColorTextLabel)}>cmyk : </div>
                      0/59/53/13
                    </div>
                    <div className={css(styles.Press_colorSchemeColorText)}>
                      <div className={css(styles.Press_colorSchemeColorTextLabel)}>rgb : </div>
                      223/72/87
                    </div>
                    <div className={css(styles.Press_colorSchemeColorText)}>
                      <div className={css(styles.Press_colorSchemeColorTextLabel)}>hex : </div>
                      #DF4857
                    </div>
                  </div>
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  windowDimension: state.windowDimension,
});

export default connect(mapStateToProps)((Press as any) as React.SFC<IPressProps>);
