import { IComponentTokenColors } from '../typings/index';

/* eslint-disable no-useless-computed-key */
/* ETHX */
const bytom = '#847C7C';
const omisego = '#1C54F4';
const binanceCoin = '#F4BC2C';
const vechain = '#14BCFC';
const icon = '#1CC4CC';
const zilliqa = '#4CC4BC';
const aeternity = '#DC3C6C';
const rchain = '#CC1D45';
const populous = '#3968C5';
const maker = '#1CBC9C';
const augur = '#592E50';
const status = '#5A71E6';
const basicAttentionToken = '#ED5C2A';
const loomNetwork = '#6491C6';
/* STBL */
const trueUsd = '#5CCCBC';
const dai = '#FCA404';
/* DEX */
const zeroEx = '#747171';
const airswap = '#0464FC';
const kyberNetwork = '#1C8C94';

const componentTokenColors: IComponentTokenColors = {
  // ETHX
  bytom,
  omisego,
  'binance-coin': binanceCoin,
  vechain,
  icon,
  zilliqa,
  aeternity,
  rchain,
  populous,
  maker,
  augur,
  status,
  'basic-attention-token': basicAttentionToken,
  'loom-network': loomNetwork,
  // STBL
  'true-usd': trueUsd,
  dai,
  // DEX
  '0x': zeroEx,
  airswap,
  'kyber-network': kyberNetwork,
};

export default componentTokenColors;
