import { css, StyleSheet } from 'aphrodite';
import React, { PureComponent } from 'react';
import { Modal, Button, Loader } from 'semantic-ui-react';
import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  TermsOfServiceModal_container: {
    width: '400px',
    transition: '0.2s',
    textAlign: 'left',
    backgroundColor: COLORS.white,
    borderRadius: '4px',
    padding: '0px',
    [MOBILE_MEDIA_QUERY]: {
      width: '100%',
      padding: '12px',
      top: '20px',
    },
  },
  TermsOfServiceModal_header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
  },
  TermsOfServiceModal_headerImage: {
    width: '50%',
    marginBottom: '12px',
  },
  TermsOfServiceModal_headerText: {
    fontSize: '24px',
    color: COLORS.darkBlue,
    fontWeight: 500,
  },
  TermsOfServiceModal_section: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '18px',
  },
  TermsOfServiceModal_sectionTitle: {
    color: COLORS.darkBlue,
    fontWeight: 500,
    fontSize: '16px',
  },
  TermsOfServiceModal_sectionText: {
    fontSize: '14px',
    lineHeight: 1.4,
  },
  TermsOfServiceModal_footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [MOBILE_MEDIA_QUERY]: {
      flexDirection: 'column-reverse',
    },
  },
  TermsOfServiceModal_acceptButton: {
    width: '46%',
    borderRadius: '4px',
    color: COLORS.white,
    backgroundColor: COLORS.blue,
    ':hover': {
      backgroundColor: COLORS.blueDarkened,
    },
    [MOBILE_MEDIA_QUERY]: {
      width: '100%',
      marginBottom: '12px',
    },
  },
  TermsOfServiceModal_declineButton: {
    width: '46%',
    borderRadius: '4px',
    border: `1px solid ${COLORS.darkBlue}`,
    color: COLORS.darkBlue,
    backgroundColor: COLORS.white,
    ':hover': {
      backgroundColor: COLORS.gray,
    },
    [MOBILE_MEDIA_QUERY]: {
      width: '100%',
    },
  },
  TermsOfServiceModal_pendingBody: {
    height: '100px',
  },
  TermsOfServiceModal_pendingText: {
    textAlign: 'center',
    marginTop: '30px',
  },
  TermsOfServiceModal_pendingLoader: {
    top: '30px',
  },
});

interface ITermsOfServiceModalProps {
  account: string;
  isModalOpen: boolean;
  onAccept: (...args: any[]) => any;
  onLogout: (...args: any[]) => any;
}

interface ITermsOfServiceModalState {
  isSubmitting: boolean;
}

/**
 * @title TermsOfServiceModal
 * @author Set Labs
 *
 * The TermsOfServiceModal prompts the user to accept terms of service + privacy policy before using the app.
 * This modal cannot be skipped. This modal is not displayed on terms of service and privacy policy pages.
 */
class TermsOfServiceModal extends PureComponent<
  ITermsOfServiceModalProps,
  ITermsOfServiceModalState
> {
  constructor(props: ITermsOfServiceModalProps) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  public onAcceptTermsOfService = async () => {
    const { onAccept } = this.props;
    this.setState({
      isSubmitting: true,
    });

    if (await onAccept()) {
      return;
    }

    this.setState({
      isSubmitting: false,
    });
  };

  public onDeclineTermsOfService = () => {
    const { onLogout } = this.props;

    onLogout();
  };

  public renderPendingModal = () => {
    const { isSubmitting } = this.state;

    if (!isSubmitting) return;

    return (
      <div className={css(styles.TermsOfServiceModal_pendingBody)}>
        <Loader
          className={`verification-email-loader ${css(styles.TermsOfServiceModal_pendingLoader)}`}
        />
        <div className={css(styles.TermsOfServiceModal_pendingText)}>Please wait...</div>
      </div>
    );
  };

  public renderTermsOfServiceForm = () => {
    const { isSubmitting } = this.state;

    if (isSubmitting) return;

    return (
      <div>
        <div className={css(styles.TermsOfServiceModal_header)}>
          <img
            className={css(styles.TermsOfServiceModal_headerImage)}
            src="https://www.tokensets.com/static/media/set-and-tokensets-logo.872a2884.svg"
          />
          <span className={css(styles.TermsOfServiceModal_headerText)}>Welcome to TokenSets!</span>
        </div>

        <div className={css(styles.TermsOfServiceModal_section)}>
          <p className={css(styles.TermsOfServiceModal_sectionTitle)}>Before you join</p>
          <p className={css(styles.TermsOfServiceModal_sectionText)}>
            Our mission is to create an open finance community where anyone can access asset
            management services anywhere. In order to ensure this, we ask you to review our terms of
            service and make a commitment to respect everyone on TokenSets.
          </p>
        </div>

        <div className={css(styles.TermsOfServiceModal_section)}>
          <p className={css(styles.TermsOfServiceModal_sectionTitle)}>Things you should know</p>
          <p className={css(styles.TermsOfServiceModal_sectionText)}>
            You are not guaranteed to make money by following any Set offered on TokenSets. Past
            performance is not an indication of future results. You alone are responsible for the
            safety of your funds. Your capital is at risk.
          </p>
        </div>

        <div className={css(styles.TermsOfServiceModal_section)}>
          <p className={css(styles.TermsOfServiceModal_sectionText)}>
            By clicking "Accept" below, I acknowledge the above, the{' '}
            <a
              href="/terms"
              target="_blank"
              rel="noopener"
              className={css(styles.TermsOfServiceModal_sectionText)}
            >
              Terms of Service
            </a>
            , and{' '}
            <a
              href="/privacy"
              target="_blank"
              rel="noopener"
              className={css(styles.TermsOfServiceModal_sectionText)}
            >
              Privacy Policy
            </a>
            .
          </p>
        </div>
        <div className={css(styles.TermsOfServiceModal_footer)}>
          <Button
            className={css(styles.TermsOfServiceModal_declineButton)}
            onClick={this.onDeclineTermsOfService}
          >
            Decline
          </Button>
          <Button
            className={css(styles.TermsOfServiceModal_acceptButton)}
            onClick={this.onAcceptTermsOfService}
          >
            Accept
          </Button>
        </div>
      </div>
    );
  };

  public render() {
    const { isModalOpen } = this.props;

    return (
      <Modal
        open={isModalOpen}
        size="mini"
        className={`${css(styles.TermsOfServiceModal_container)} scrolling`}
      >
        <Modal.Content>
          {this.renderPendingModal()}
          {this.renderTermsOfServiceForm()}
        </Modal.Content>
      </Modal>
    );
  }
}

export default (TermsOfServiceModal as any) as React.SFC<ITermsOfServiceModalProps>;
