const tradeExtensionABI = [
  {
    inputs: [
      { internalType: 'contract IManagerCore', name: '_managerCore', type: 'address' },
      { internalType: 'contract ITradeModule', name: '_tradeModule', type: 'address' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: '_setToken', type: 'address' },
      { indexed: true, internalType: 'address', name: '_delegatedManager', type: 'address' },
    ],
    name: 'ExtensionRemoved',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: '_setToken', type: 'address' },
      { indexed: true, internalType: 'address', name: '_delegatedManager', type: 'address' },
    ],
    name: 'TradeExtensionInitialized',
    type: 'event',
  },
  {
    inputs: [
      { internalType: 'contract IDelegatedManager', name: '_delegatedManager', type: 'address' },
    ],
    name: 'initializeExtension',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IDelegatedManager', name: '_delegatedManager', type: 'address' },
    ],
    name: 'initializeModule',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IDelegatedManager', name: '_delegatedManager', type: 'address' },
    ],
    name: 'initializeModuleAndExtension',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'managerCore',
    outputs: [{ internalType: 'contract IManagerCore', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'removeExtension',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'contract ISetToken', name: '', type: 'address' }],
    name: 'setManagers',
    outputs: [{ internalType: 'contract IDelegatedManager', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract ISetToken', name: '_setToken', type: 'address' },
      { internalType: 'string', name: '_exchangeName', type: 'string' },
      { internalType: 'address', name: '_sendToken', type: 'address' },
      { internalType: 'uint256', name: '_sendQuantity', type: 'uint256' },
      { internalType: 'address', name: '_receiveToken', type: 'address' },
      { internalType: 'uint256', name: '_minReceiveQuantity', type: 'uint256' },
      { internalType: 'bytes', name: '_data', type: 'bytes' },
    ],
    name: 'trade',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'tradeModule',
    outputs: [{ internalType: 'contract ITradeModule', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
];

export default tradeExtensionABI;
