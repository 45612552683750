import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import BackArrow from '../../img/icons/BackArrow.svg';
import { COLORS } from '../../constants/index';

const styles = StyleSheet.create({
  Container: {
    display: 'flex',
    border: `1px solid ${COLORS.lightGray9}`,
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    ':hover': {
      opacity: '50%',
    },
  },
  Arrow: {
    width: '20px',
    height: '20px',
  },
});

interface BackButtonProps {
  handleClick: (...args: any[]) => void;
}

export default function BackButton({ handleClick }: BackButtonProps) {
  return (
    <div className={css(styles.Container)} onClick={handleClick}>
      <img className={css(styles.Arrow)} src={BackArrow} />
    </div>
  );
}
