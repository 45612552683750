import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { withTranslation, WithTranslation } from 'react-i18next';

import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';
import { IWindowDimensions, IToken } from '../../typings/index';
import blueArrow from '../../img/icons/down-arrow-blue.svg';

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  AllocationChange_container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: '500px',
    alignSelf: 'center',
    marginTop: '15px',
    [MOBILE_MEDIA_QUERY]: {
      flexDirection: 'column',
    },
  },
  AllocationChange_allocation: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '200px',
    [MOBILE_MEDIA_QUERY]: {
      width: '90%',
    },
  },
  AllocationChange_allocationMobile: {
    [MOBILE_MEDIA_QUERY]: {
      marginBottom: '20px',
    },
  },
  AllocationChange_label: {
    fontWeight: 500,
    marginBottom: '5px',
    fontSize: '14px',
  },
  AllocationChange_body: {
    width: '100%',
    padding: '5px 10px',
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '4px',
    [MOBILE_MEDIA_QUERY]: {
      padding: '10px',
    },
  },
  AllocationChange_row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '7px',
  },
  AllocationChange_nameContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  AllocationChange_allocationTokenIcon: {
    width: '20px',
    height: '20px',
    marginRight: '10px',
  },
  AllocationChange_tokenNameText: {
    fontSize: '14px',
    fontWeight: 500,
    color: COLORS.black,
  },
  AllocationChange_percentText: {
    color: COLORS.black,
    fontSize: '14px',
  },
  AllocationChange_blueArrow: {
    width: '20px',
    height: '20px',
    transform: 'rotate(-90deg)',
    marginTop: '20px',
    marginLeft: '20px',
    marginRight: '20px',
    [MOBILE_MEDIA_QUERY]: {
      width: '15px',
      height: '15px',
      marginLeft: '5px',
      marginRight: '5px',
    },
  },
});

interface IAllocationChangeProps {
  customStyles?: any;
  previousAllocation: [IToken, IToken];
  nextAllocation: [IToken, IToken];
  windowDimensions: IWindowDimensions;
}

/**
 * @title AllocationChange
 * @author Set Labs
 *
 * Displays the previous and next allocation for a given asset pair and passed in allocations.
 */
class AllocationChange extends PureComponent<IAllocationChangeProps & WithTranslation> {
  public render() {
    const {
      customStyles,
      previousAllocation,
      nextAllocation,
      windowDimensions: { isMobile },
      t,
    } = this.props;

    const previousTokenA = previousAllocation[0];
    const previousTokenB = previousAllocation[1];
    const nextTokenA = nextAllocation[0];
    const nextTokenB = nextAllocation[1];

    return (
      <div className={css(styles.AllocationChange_container)}>
        {/* Previous Allocation */}
        <div
          className={css(
            styles.AllocationChange_allocation,
            styles.AllocationChange_allocationMobile,
            customStyles,
          )}
        >
          <span className={css(styles.AllocationChange_label)}>
            {t('allocation-change.previous')}
          </span>
          <div className={css(styles.AllocationChange_body)}>
            {/* Token A */}
            <div className={css(styles.AllocationChange_row)}>
              <div className={css(styles.AllocationChange_nameContainer)}>
                <img
                  className={css(styles.AllocationChange_allocationTokenIcon)}
                  src={previousTokenA.image_url}
                />
                <span className={css(styles.AllocationChange_tokenNameText)}>
                  {previousTokenA.symbol}
                </span>
              </div>
              <span className={css(styles.AllocationChange_percentText)}>
                {previousTokenA.percent_of_set}
              </span>
            </div>

            {/* Token B */}
            <div className={css(styles.AllocationChange_row)}>
              <div className={css(styles.AllocationChange_nameContainer)}>
                <img
                  className={css(styles.AllocationChange_allocationTokenIcon)}
                  src={previousTokenB.image_url}
                />
                <span className={css(styles.AllocationChange_tokenNameText)}>
                  {previousTokenB.symbol}
                </span>
              </div>
              <span className={css(styles.AllocationChange_percentText)}>
                {previousTokenB.percent_of_set}
              </span>
            </div>
          </div>
        </div>

        {!isMobile && <img className={css(styles.AllocationChange_blueArrow)} src={blueArrow} />}

        {/* Next Allocation */}
        <div className={css(styles.AllocationChange_allocation, customStyles)}>
          <span className={css(styles.AllocationChange_label)}>{t('allocation-change.next')}</span>
          <div className={css(styles.AllocationChange_body)}>
            {/* Token A */}
            <div className={css(styles.AllocationChange_row)}>
              <div className={css(styles.AllocationChange_nameContainer)}>
                <img
                  className={css(styles.AllocationChange_allocationTokenIcon)}
                  src={nextTokenA.image_url}
                />
                <span className={css(styles.AllocationChange_tokenNameText)}>
                  {nextTokenA.symbol}
                </span>
              </div>
              <span className={css(styles.AllocationChange_percentText)}>
                {nextTokenA.percent_of_set}
              </span>
            </div>

            {/* Token B */}
            <div className={css(styles.AllocationChange_row)}>
              <div className={css(styles.AllocationChange_nameContainer)}>
                <img
                  className={css(styles.AllocationChange_allocationTokenIcon)}
                  src={nextTokenB.image_url}
                />
                <span className={css(styles.AllocationChange_tokenNameText)}>
                  {nextTokenB.symbol}
                </span>
              </div>
              <span className={css(styles.AllocationChange_percentText)}>
                {nextTokenB.percent_of_set}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('components')(AllocationChange);
