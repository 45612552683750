import React, { PureComponent } from 'react';
import { Container, Header } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { css, StyleSheet } from 'aphrodite';

import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  CustomSetWarningBanner_container: {
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: `1px solid ${COLORS.lightBlue2}`,
    borderRadius: '4px',
    padding: '20px',
    backgroundColor: COLORS.white,
  },
  CustomSetWarningBanner_headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '15px',
  },
  CustomSetWarningBanner_header: {
    fontSize: '24px',
    color: COLORS.red,
  },
  CustomSetWarningBanner_icon: {
    marginRight: '10px',
  },
  CustomSetWarningBanner_text: {
    color: COLORS.darkBlue,
    marginBottom: '12px',
    lineHeight: '1.5',
  },
  CustomSetWarningBanner_button: {
    display: 'inline',
    width: '180px',
    padding: '10px 12px',
    backgroundColor: COLORS.red,
    [MOBILE_MEDIA_QUERY]: {
      width: '100%',
    },
  },
  CustomSetWarningBanner_learnMoreLink: {
    marginBottom: '15px',
    fontWeight: 500,
  },
});

interface ICustomSetWarningBannerProps {
  setAddress?: string;
  onAgreeToExperimentalWarning: (...args: any[]) => any;
}

/**
 * @title CustomSetWarningBanner
 * @author Set Labs
 *
 * CustomSetWarningBanner displays a warning for users about higher risk Sets before they buy into it.
 */
class CustomSetWarningBanner extends PureComponent<ICustomSetWarningBannerProps & WithTranslation> {
  public render() {
    const { setAddress, onAgreeToExperimentalWarning, t } = this.props;

    return (
      <Container>
        <div className={css(styles.CustomSetWarningBanner_container)}>
          <div className={css(styles.CustomSetWarningBanner_headerContainer)}>
            <img
              className={css(styles.CustomSetWarningBanner_icon)}
              src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/warning.svg"
              alt="Warning icon"
            />
            <Header as="h2" className={css(styles.CustomSetWarningBanner_header)}>
              {t('warning.header')}
            </Header>
          </div>
          <p className={css(styles.CustomSetWarningBanner_text)}>{t('warning.description')}</p>
          <p className={css(styles.CustomSetWarningBanner_text)}>
            <span className={css(styles.CustomSetWarningBanner_text)}>
              {t('warning.description-2')}
            </span>
            <span>
              <a
                className={css(styles.CustomSetWarningBanner_learnMoreLink)}
                href="https://docs.tokensets.com"
                target="_blank"
                rel="noopener"
              >
                {t('warning.documentation')}.
              </a>
            </span>
          </p>
          <a
            className={css(styles.CustomSetWarningBanner_learnMoreLink)}
            href="https://help.tokensets.com/en/articles/4817991-risks-of-buying-user-made-portfolios"
            target="_blank"
            rel="noopener"
          >
            {t('warning.learn-more')}
          </a>
          <button
            onClick={() => onAgreeToExperimentalWarning(setAddress)}
            className={css(styles.CustomSetWarningBanner_button)}
          >
            {t('warning.cta')}
          </button>
        </div>
      </Container>
    );
  }
}

export default withTranslation('set-details-v2')(CustomSetWarningBanner);
