export enum CUSTOM_MEDIA_RENDER_TYPES {
  SIMPLE_IMAGE = 'SIMPLE_IMAGE',
}

export enum INLINE_STYLE_OPTIONS {
  BOLD = 'BOLD',
  ITALIC = 'ITALIC',
  UNDERLINE = 'UNDERLINE',
}

export enum CUSTOM_MEDIA_OPTIONS {
  IMAGE = 'IMAGE',
  LINK = 'LINK',
}

export const customMediaOptionToLabelMap = {
  [CUSTOM_MEDIA_OPTIONS.IMAGE]: 'add image',
  [CUSTOM_MEDIA_OPTIONS.LINK]: 'add link',
};
