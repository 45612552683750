import { INITIALIZE_UNISWAP_ROUTER, INITIALIZE_SUSHISWAP_ROUTER } from '../actions/uniswapActions';

type UniswapReducerState = {
  uniswapRouter: any;
  sushiswapRouter: any;
};

const initialState: UniswapReducerState = {
  uniswapRouter: {} as any,
  sushiswapRouter: {} as any,
};

const uniswapReducerInstance = (state = initialState, action: any): UniswapReducerState => {
  switch (action.type) {
    case INITIALIZE_UNISWAP_ROUTER:
      return {
        ...state,
        uniswapRouter: action.payload,
      };
    case INITIALIZE_SUSHISWAP_ROUTER:
      return {
        ...state,
        sushiswapRouter: action.payload,
      };

    default:
      return state;
  }
};

export default uniswapReducerInstance;
