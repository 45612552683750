import keyMirror from 'keymirror';

const DATA_SELECTS = keyMirror({
  INPUT_BODY: null,
  INPUT_BUTTON: null,
  INPUT_CONTAINER: null,
  INPUT_LABEL: null,
});

export default DATA_SELECTS;
