import { toast } from 'react-toastify';

import { submitSetJSTransaction } from '../actions/transactionWatcherActions';
import {
  setJSInstanceSelector,
  setDetailsCurrentSetAddressSelector,
  erc20BalancesSelector,
} from '../selectors/index';
import { createRedeemFundTransactionV2Args } from '../selectors/redemptionV2Selectors';
import {
  emptyActionGenerator,
  payloadActionGenerator,
  userRejectedMetamaskTransaction,
} from '../utils/index';
import { tokenFromBaseUnits } from '../utils/formatUtils';
import i18n from '../i18n';

export const SET_IS_SUBMITTING_REDEMPTION_TRANSACTION_V2 =
  'SET_IS_SUBMITTING_REDEMPTION_TRANSACTION_V2';
export const UPDATE_REDEMPTION_INPUT_QUANTITY_V2 = 'UPDATE_REDEMPTION_INPUT_QUANTITY_V2';
export const FETCH_DEBT_REDEMPTION_COMPONENTS = 'FETCH_DEBT_REDEMPTION_COMPONENTS';
export const RECEIVE_DEBT_REDEMPTION_COMPONENTS = 'RECEIVE_DEBT_REDEMPTION_COMPONENTS';
export const FETCH_PERP_REDEMPTION_COMPONENTS = 'FETCH_PERP_REDEMPTION_COMPONENTS';
export const RECEIVE_PERP_REDEMPTION_COMPONENTS = 'RECEIVE_PERP_REDEMPTION_COMPONENTS';
export const REQUEST_PERP_REDEMPTION_FEES = 'REQUEST_PERP_REDEMPTION_FEES';
export const RECEIVE_PERP_REDEMPTION_FEES = 'RECEIVE_PERP_REDEMPTION_FEES;';
export const REQUEST_DEBT_REDEMPTION_FEES = 'REQUEST_DEBT_REDEMPTION_FEES';
export const RECEIVE_DEBT_REDEMPTION_FEES = 'RECEIVE_DEBT_REDEMPTION_FEES;';

export const setIsSubmittingRedemptionTransaction = payloadActionGenerator(
  SET_IS_SUBMITTING_REDEMPTION_TRANSACTION_V2,
);
export const updateRedemptionQuantityV2 = payloadActionGenerator(
  UPDATE_REDEMPTION_INPUT_QUANTITY_V2,
);
export const fetchDebtRedemptionComponents = emptyActionGenerator(FETCH_DEBT_REDEMPTION_COMPONENTS);
export const receiveDebtRedemptionComponents = payloadActionGenerator(
  RECEIVE_DEBT_REDEMPTION_COMPONENTS,
);
export const fetchPerpRedemptionComponents = emptyActionGenerator(FETCH_PERP_REDEMPTION_COMPONENTS);
export const receivePerpRedemptionComponents = payloadActionGenerator(
  RECEIVE_PERP_REDEMPTION_COMPONENTS,
);
export const requestPerpRedemptionFees = emptyActionGenerator(REQUEST_PERP_REDEMPTION_FEES);
export const receivePerpRedemptionFees = payloadActionGenerator(RECEIVE_PERP_REDEMPTION_FEES);
export const requestDebtRedemptionFees = emptyActionGenerator(REQUEST_DEBT_REDEMPTION_FEES);
export const receiveDebtRedemptionFees = payloadActionGenerator(RECEIVE_DEBT_REDEMPTION_FEES);

/**
 * Update issuance quantity
 * @param quantity - target issuance value
 */
export const updateRedemptionQuantity = (quantity: string) => (dispatch: any) => {
  dispatch(updateRedemptionQuantityV2(quantity));
};

/**
 * Call SetJS and redeem the desired fund token quantity.
 */
export const redeemCurrentSet = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const setJSInstance = setJSInstanceSelector(state);

  try {
    const transactionArguments = await createRedeemFundTransactionV2Args(state);

    if (!transactionArguments) return;

    const redemptionRequest = submitSetJSTransaction(() =>
      setJSInstance.issuance.redeemAsync(...transactionArguments),
    );

    dispatch(setIsSubmittingRedemptionTransaction(true));
    dispatch(redemptionRequest);
  } catch (e) {
    if (userRejectedMetamaskTransaction(e)) return;

    toast(i18n.t('issue-and-redeem:redeem-action.redeem-failed'));
  } finally {
    dispatch(setIsSubmittingRedemptionTransaction(false));
  }
};

/**
 * Set the max redeemable quantity the user can redeem of the currently shown Fund.
 */
export const setMaxRedeemableQuantity = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);
  const erc20Balances = erc20BalancesSelector(state);
  const maxRedeemableQuantity = erc20Balances[currentSetAddress]?.toString();
  const formattedMaxRedeemableQuantity = tokenFromBaseUnits(maxRedeemableQuantity || '0');

  dispatch(updateRedemptionQuantityV2(formattedMaxRedeemableQuantity.toString()));
};
