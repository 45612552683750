const batchTradingExtensionABI = [
  {
    inputs: [
      { internalType: 'contract IManagerCore', name: '_managerCore', type: 'address' },
      { internalType: 'contract ITradeModule', name: '_tradeModule', type: 'address' },
      { internalType: 'string[]', name: '_integrations', type: 'string[]' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: '_setToken', type: 'address' },
      { indexed: true, internalType: 'address', name: '_delegatedManager', type: 'address' },
    ],
    name: 'BatchTradeExtensionInitialized',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: '_setToken', type: 'address' },
      { indexed: true, internalType: 'uint256', name: '_index', type: 'uint256' },
      { indexed: false, internalType: 'bytes', name: '_lowLevelData', type: 'bytes' },
      {
        components: [
          { internalType: 'string', name: 'exchangeName', type: 'string' },
          { internalType: 'address', name: 'sendToken', type: 'address' },
          { internalType: 'uint256', name: 'sendQuantity', type: 'uint256' },
          { internalType: 'address', name: 'receiveToken', type: 'address' },
          { internalType: 'uint256', name: 'receiveQuantity', type: 'uint256' },
          { internalType: 'bytes', name: 'data', type: 'bytes' },
        ],
        indexed: false,
        internalType: 'struct BatchTradeExtension.TradeInfo',
        name: '_tradeInfo',
        type: 'tuple',
      },
    ],
    name: 'BytesTradeFailed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: '_setToken', type: 'address' },
      { indexed: true, internalType: 'address', name: '_delegatedManager', type: 'address' },
    ],
    name: 'ExtensionRemoved',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'string', name: '_integrationName', type: 'string' }],
    name: 'IntegrationAdded',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'string', name: '_integrationName', type: 'string' }],
    name: 'IntegrationRemoved',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: '_setToken', type: 'address' },
      { indexed: true, internalType: 'uint256', name: '_index', type: 'uint256' },
      { indexed: false, internalType: 'string', name: '_reason', type: 'string' },
      {
        components: [
          { internalType: 'string', name: 'exchangeName', type: 'string' },
          { internalType: 'address', name: 'sendToken', type: 'address' },
          { internalType: 'uint256', name: 'sendQuantity', type: 'uint256' },
          { internalType: 'address', name: 'receiveToken', type: 'address' },
          { internalType: 'uint256', name: 'receiveQuantity', type: 'uint256' },
          { internalType: 'bytes', name: 'data', type: 'bytes' },
        ],
        indexed: false,
        internalType: 'struct BatchTradeExtension.TradeInfo',
        name: '_tradeInfo',
        type: 'tuple',
      },
    ],
    name: 'StringTradeFailed',
    type: 'event',
  },
  {
    inputs: [{ internalType: 'string[]', name: '_integrations', type: 'string[]' }],
    name: 'addIntegrations',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract ISetToken', name: '_setToken', type: 'address' },
      {
        components: [
          { internalType: 'string', name: 'exchangeName', type: 'string' },
          { internalType: 'address', name: 'sendToken', type: 'address' },
          { internalType: 'uint256', name: 'sendQuantity', type: 'uint256' },
          { internalType: 'address', name: 'receiveToken', type: 'address' },
          { internalType: 'uint256', name: 'receiveQuantity', type: 'uint256' },
          { internalType: 'bytes', name: 'data', type: 'bytes' },
        ],
        internalType: 'struct BatchTradeExtension.TradeInfo[]',
        name: '_trades',
        type: 'tuple[]',
      },
    ],
    name: 'batchTrade',
    outputs: [''],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getIntegrations',
    outputs: [{ internalType: 'string[]', name: '', type: 'string[]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IDelegatedManager', name: '_delegatedManager', type: 'address' },
    ],
    name: 'initializeExtension',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IDelegatedManager', name: '_delegatedManager', type: 'address' },
    ],
    name: 'initializeModule',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IDelegatedManager', name: '_delegatedManager', type: 'address' },
    ],
    name: 'initializeModuleAndExtension',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'integrations',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'string', name: '', type: 'string' }],
    name: 'isIntegration',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'managerCore',
    outputs: [{ internalType: 'contract IManagerCore', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'removeExtension',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'string[]', name: '_integrations', type: 'string[]' }],
    name: 'removeIntegrations',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'contract ISetToken', name: '', type: 'address' }],
    name: 'setManagers',
    outputs: [{ internalType: 'contract IDelegatedManager', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'tradeModule',
    outputs: [{ internalType: 'contract ITradeModule', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
];

export default batchTradingExtensionABI;
