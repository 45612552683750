import React, { PureComponent } from 'react';
import { Placeholder } from 'semantic-ui-react';
import { css, StyleSheet } from 'aphrodite';

import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';

const { TABLET_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  IssueRedeemButtons_buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    [TABLET_MEDIA_QUERY]: {
      backgroundColor: 'white',
      borderTop: `1px solid ${COLORS.lightBlue2}`,
      display: 'flex',
      flexDirection: 'row',
      position: 'fixed',
      bottom: 0,
      padding: '10px',
      zIndex: 998,
      width: '100%',
    },
  },
  IssueRedeemButtons_buttonPlaceholderMarginRight: {
    [TABLET_MEDIA_QUERY]: {
      marginRight: '12px',
    },
  },
  IssueRedeemButtons_buttonPlaceholder: {
    borderRadius: '8px',
    height: '57px',
    width: '176px',
    display: 'inline-block',
    marginTop: '0',
    marginRight: '15px',
    [TABLET_MEDIA_QUERY]: {
      height: '50px',
      width: '100%',
    },
  },
  IssueRedeemButtons_buttonPlaceholderInner: {
    height: '57px',
    width: '176px',
    [TABLET_MEDIA_QUERY]: {
      width: '100%',
    },
  },

  IssueRedeemButtons_buySellButton: {
    width: '150px',
    height: '50px',
    background: COLORS.blue,
    backgroundClip: 'padding-box',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    color: COLORS.white,
    fontSize: '16px',
    fontWeight: 600,
    position: 'relative',
    transition: '0.2s',
    ':active': {
      transform: 'scale(0.975)',
      opacity: 0.85,
    },
    ':after': {
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      content: '""',
      zIndex: -1,
    },
    ':hover': {
      boxShadow: `0 8px 14px ${COLORS.blueAlpha25}`,
      transform: 'scale(1.025)',
    },
    [TABLET_MEDIA_QUERY]: {
      height: '50px',
      width: '100%',
    },
  },
  IssueRedeemButtons_disabledButton: {
    width: '150px',
    height: '50px',
    background: COLORS.white,
    backgroundClip: 'padding-box',
    border: `1px solid ${COLORS.darkGray}`,
    borderRadius: '8px',
    cursor: 'not-allowed',
    color: COLORS.darkGray,
    fontSize: '16px',
    fontWeight: 600,
    position: 'relative',
    transition: '0.2s',
    ':active': {
      transform: 'scale(0.975)',
      opacity: 0.85,
    },
    ':after': {
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      content: '""',
      zIndex: -1,
      borderRadius: '8px',
    },
    [TABLET_MEDIA_QUERY]: {
      height: '50px',
      width: '100%',
    },
  },
  IssueRedeemButtons_marginRight: {
    marginRight: '15px',
    [TABLET_MEDIA_QUERY]: {
      marginRight: '10px',
    },
  },
});

interface IIssueRedeemButtonsProps {
  isLoading: boolean;
  onNavToIssuance: (...args: any[]) => any;
  onNavToRedemption: (...args: any[]) => any;
}

/**
 * @title IssueRedeemButtons
 * @author Set Labs
 *
 * The IssueRedeemButtons open the issue and redeem flows
 */
class IssueRedeemButtons extends PureComponent<IIssueRedeemButtonsProps> {
  public render() {
    const { isLoading, onNavToIssuance, onNavToRedemption } = this.props;

    if (isLoading) {
      return (
        <div className={css(styles.IssueRedeemButtons_buttonsContainer)}>
          <Placeholder
            className={css(
              styles.IssueRedeemButtons_buttonPlaceholder,
              styles.IssueRedeemButtons_buttonPlaceholderMarginRight,
            )}
          >
            <Placeholder.Image className={css(styles.IssueRedeemButtons_buttonPlaceholderInner)} />
          </Placeholder>

          <Placeholder className={css(styles.IssueRedeemButtons_buttonPlaceholder)}>
            <Placeholder.Image className={css(styles.IssueRedeemButtons_buttonPlaceholderInner)} />
          </Placeholder>
        </div>
      );
    }

    return (
      <div className={css(styles.IssueRedeemButtons_buttonsContainer)}>
        <button
          type="button"
          className={css(
            styles.IssueRedeemButtons_buySellButton,
            styles.IssueRedeemButtons_marginRight,
          )}
          onClick={onNavToIssuance}
        >
          Issue
        </button>

        <button
          type="button"
          className={css(styles.IssueRedeemButtons_buySellButton)}
          onClick={onNavToRedemption}
        >
          Redeem
        </button>
      </div>
    );
  }
}

export default IssueRedeemButtons;
