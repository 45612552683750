import React, { useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { COLORS } from '../../constants/index';
import { BigNumber } from '../../utils/index';

const styles = StyleSheet.create({
  FormContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  InputWrapper: {
    border: `1px solid ${COLORS.blueGray}`,
    height: '38px',
    paddingLeft: '0.75em',
    paddingRight: '0.75em',
    borderRadius: '5px',
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center',
  },
  Input: {
    border: 0,
    textAlign: 'end',
    width: '100%',
    fontSize: '16px',
  },
  ErrorInput: {
    backgroundColor: COLORS.pink2,
  },
  hover: {
    ':hover': {
      border: `1px solid ${COLORS.lightBlue6}`,
    },
  },
  focus: {
    boxShadow: `0px 0px 0px 2px ${COLORS.blueShadow}`,
    border: `1px solid ${COLORS.lightBlue6}`,
  },
  focusError: {
    boxShadow: `0px 0px 0px 2px ${COLORS.redShadow}`,
    border: `1px solid ${COLORS.red2}`,
  },
  HelperText: {
    color: COLORS.lightGray5,
  },
  error: {
    backgroundColor: COLORS.pink2,
    border: `1px solid ${COLORS.red2}`,
  },
  errorText: {
    width: '18em',
    lineHeight: 1.1,
    color: COLORS.red2,
    fontWeight: 500,
    position: 'absolute',
    marginTop: '3.1em',
  },
  Max: {
    color: COLORS.royalBlue2,
    fontWeight: 600,
    fontSize: '12px',
    textAlign: 'center',
    lineHeight: '3em',
    ':hover': {
      opacity: '50%',
      cursor: 'pointer',
    },
  },
});

interface MaxTextFieldProps {
  disabled?: boolean;
  name: string;
  placeholder?: string;
  label?: string;
  helperText?: string;
  onChange: any;
  onClickMax: (...args: any[]) => any;
  value: any;
  width?: string;
  toggleIsFeeSplitDesired?: (...args: any[]) => any;
  isFeeSplitDesired?: boolean;
  fieldName: string;
  max?: string;
}

const MaxTextField = ({
  disabled,
  name,
  placeholder,
  label,
  helperText,
  onChange,
  onClickMax,
  value,
  width,
  fieldName,
  max = '100',
}: MaxTextFieldProps) => {
  const [inFocus, setInFocus] = useState(false);
  const [error, setError] = useState('');

  const handleOnFocus = () => setInFocus(true);
  const handleOnBlur = () => setInFocus(false);

  const handleChange = (e: any) => {
    onChange(e);

    if ((e.target.value !== '0' && !e.target.value) || max === '0') {
      setError('');
      return;
    }

    if (new BigNumber(e.target.value).gte(max)) {
      setError(`${fieldName} must be below or equal to ${max}`);
      return;
    }

    if (new BigNumber(e.target.value).lte(0)) {
      setError(`${fieldName} must be above 0`);
      return;
    }

    setError('');
  };

  return (
    <div
      style={{
        width: width ? width : '205px',
      }}
      className={css(styles.FormContainer)}
    >
      <label>{label}</label>
      <div
        className={css(
          styles.InputWrapper,
          styles.hover,
          inFocus && (error ? styles.focusError : styles.focus),
          error && styles.error,
        )}
      >
        <span
          className={css(styles.Max)}
          onClick={() => {
            onClickMax();
            setError('');
          }}
        >
          MAX
        </span>
        <input
          className={css(error && styles.ErrorInput, styles.Input)}
          type={'number'}
          name={name}
          min={0}
          step={0.1}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          disabled={disabled}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
        />
      </div>
      <div className={css(error ? styles.errorText : styles.HelperText)}>
        {(helperText || error) && <label>{error ? error : helperText}</label>}
      </div>
    </div>
  );
};

export default MaxTextField;
