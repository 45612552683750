import {
  IAllocationPrices,
  ICoinBalance,
  IHistoricals,
  IOrderDisplay,
  IRebalancingCriteria,
  IRebalancingSet,
  ISet,
  IListToken,
  IToken,
  ITokenInfo,
  ITradingPair,
  ITransaction,
} from '../typings/index';
import {
  WAVAX_ADDRESS_AVALANCHE,
  WETH_ADDRESS_ARBITRUM,
  WETH_ADDRESS_ETHEREUM,
  WETH_ADDRESS_OPTIMISM,
  WMATIC_ADDRESS_POLYGON,
} from './tokenListConstants';

const DEFAULT_COIN_HISTORICALS: IAllocationPrices = {};

const DEFAULT_HISTORICALS: IHistoricals = {
  components: {
    prices: {},
    quantities: {},
    target_allocations: {},
  },
  dates: [],
  prices: [],
  rebalances: [],
};

const DEFAULT_ORDER_DETAILS: IOrderDisplay = {
  gas_cost: '0',
  gas_price_usd: '0',
  price_currency: '0',
  price_per_set_usd: '0',
  quantity: '0',
  received_currency_from_exchange: '',
  required_currency_for_exchange: '',
  total_price_usd: '0',
  components: [],
};

const DEFAULT_REBALANCING_CRITERIA: IRebalancingCriteria = {
  description: '',
  price: {
    requirement: '',
    title: '',
    valid: false,
    value: '',
  },
  rebalance: {
    title: '',
    valid: false,
    value: '',
  },
  time: {
    requirement: '',
    title: '',
    valid: false,
    value: '',
  },
};

const DEFAULT_ISSUANCE_SOURCE = [
  {
    id: 'balancer',
    name: 'Balancer',
    exchange_url: 'https://balancer.exchange/#/swap',
    exchange_image_url: 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/integrations/balancer.svg',
  },
  {
    id: 'uniswap',
    name: 'Uniswap',
    exchange_url: 'https://uniswap.org/',
    exchange_image_url: 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/integrations/uniswap.svg',
  },
];

const DEFAULT_REBALANCING_SET: IRebalancingSet = {
  address: '',
  beta: false,
  benchmark: false,
  colors: ['2D2CE5', '2D2CE5'],
  components: [],
  created_at: '',
  daily_percent_change: '',
  daily_price_change: '',
  description: '',
  short_description: '',
  historicals: DEFAULT_HISTORICALS,
  coin_historicals: DEFAULT_COIN_HISTORICALS,
  id: '',
  is_sponsored: false,
  image: '',
  issuance_sources: DEFAULT_ISSUANCE_SOURCE,
  market_coverage: '',
  name: '',
  next_rebalance: '',
  price_usd: '',
  rebalancing_interval: '',
  rebalance_criteria: DEFAULT_REBALANCING_CRITERIA,
  strategy_medium_description: '',
  strategy_short_description: '',
  status: '',
  strategy: '',
  strategy_description: '',
  strategy_title: '',
  subtitle: '',
  subtitle_description: '',
  symbol: '',
  tolerance: '',
  unit_shares: '',
};

const DEFAULT_TOKEN_INFO: ITokenInfo = {
  address: '',
  amount: '',
  daily_percent_change: '',
  decimals: '',
  id: '',
  image: '',
  image_url: '',
  info: {} as any,
  name: '',
  percent_of_set: '',
  price_usd: '',
  quantity: '',
  symbol: '',
  units: '',
};

const DEFAULT_TOKEN: IToken = {
  address: '',
  amount: '',
  benchmark: false,
  daily_percent_change: '',
  decimals: '',
  id: '',
  image: '',
  image_url: '',
  info: DEFAULT_TOKEN_INFO,
  name: '',
  percent_of_set: '',
  price_usd: '',
  quantity: '',
  symbol: '',
  units: '',
};

const DEFAULT_TOKEN_BALANCE: ICoinBalance = {
  address: '',
  amount: '',
  balance_usd: '',
  decimals: 18,
  id: 'eth',
  image: 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/eth.svg',
  name: 'Ethereum',
  price_usd: '',
  symbol: 'ETH',
};

const DEFAULT_TRADING_PAIR: ITradingPair = {
  address: WETH_ADDRESS_ETHEREUM,
  decimals: 0,
  id: 'weth',
  image_url: 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/eth.svg',
  name: 'Ethereum',
  price_eth: '',
  price_usd: '',
  symbol: 'ETH',
};

const POLYGON_DEFAULT_TRADING_PAIR: ITradingPair = {
  address: WMATIC_ADDRESS_POLYGON,
  decimals: 18,
  id: 'matic-network',
  image_url: 'https://cryptologos.cc/logos/polygon-matic-logo.svg?v=022',
  name: 'Matic',
  price_eth: '',
  price_usd: '',
  symbol: 'MATIC',
};

const ARBITRUM_DEFAULT_TRADING_PAIR: ITradingPair = {
  address: WETH_ADDRESS_ARBITRUM,
  decimals: 0,
  id: 'weth',
  image_url: 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/eth.svg',
  name: 'Ethereum',
  price_eth: '',
  price_usd: '',
  symbol: 'WETH',
};

const OPTIMISM_DEFAULT_TRADING_PAIR: ITradingPair = {
  address: WETH_ADDRESS_OPTIMISM,
  decimals: 0,
  id: 'weth',
  image_url: 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/coin-icons/eth.svg',
  name: 'Ethereum',
  price_eth: '',
  price_usd: '',
  symbol: 'WETH',
};

const AVALANCHE_DEFAULT_TRADING_PAIR: ITradingPair = {
  address: WAVAX_ADDRESS_AVALANCHE,
  decimals: 18,
  id: 'avalanche-2',
  image_url: 'https://cryptologos.cc/logos/avalanche-avax-logo.svg',
  name: 'Avalanche',
  price_eth: '',
  price_usd: '',
  symbol: 'AVAX',
};

const DEFAULT_TRANSACTION: ITransaction = {
  description: '',
  id: '',
  price: '',
  status: '',
  title: '',
  type: '',
};

const DEFAULT_SET: ISet = {
  address: '',
  colors: ['FFF', '000'],
  components: [],
  description: '',
  id: '',
  image: '',
  market_cap: '',
  name: '',
  natural_units: 0,
  price_eth: '',
  price_usd: '',
  percent_change_daily: '',
  supply: '',
  symbol: '',
};

const DEFAULT_DECIMAL_PLACES = 3;

export const DEFAULT_TOKEN_LIST_ENTRY: IListToken = {
  address: '0x00',
  chainId: '1',
  decimals: '18',
  logoURI: 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-token-icon.svg',
  name: 'Unknown Token Name',
  symbol: 'UNKNOWN',
};

export const DEFAULT_SLIPPAGE_MODULE_SLIPPAGE_PERCENTAGE = '1.0';

export default {
  DEFAULT_DECIMAL_PLACES,
  DEFAULT_ORDER_DETAILS,
  DEFAULT_REBALANCING_SET,
  DEFAULT_SET,
  DEFAULT_TOKEN_INFO,
  DEFAULT_TOKEN,
  DEFAULT_TOKEN_BALANCE,
  DEFAULT_TRADING_PAIR,
  POLYGON_DEFAULT_TRADING_PAIR,
  ARBITRUM_DEFAULT_TRADING_PAIR,
  OPTIMISM_DEFAULT_TRADING_PAIR,
  AVALANCHE_DEFAULT_TRADING_PAIR,
  DEFAULT_TRANSACTION,
  DEFAULT_SLIPPAGE_MODULE_SLIPPAGE_PERCENTAGE,
  DEFAULT_TOKEN_LIST_ENTRY,
};
