import React from 'react';
import { css } from 'aphrodite';
import { Header } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';

import styles from './OnboardingStyles';

const SetProducts = (props: WithTranslation) => {
  const { t } = props;

  return (
    <div>
      <div className={css(styles.Onboarding_slide)}>
        <div className={css(styles.Onboarding_slideSection)}>
          <Header as="h1" className={css(styles.Onboarding_sectionTitle)}>
            {t('set-products.product-title')}
          </Header>
          <p className={css(styles.Onboarding_sectionSubtitle)}>
            {t('set-products.product-subtitle')}
          </p>
        </div>
        <div className={css(styles.Onboarding_slideSection)}>
          <img
            className={css(styles.SetProducts_image)}
            src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/portfolio-no-cta.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default withTranslation('onboarding')(SetProducts);
