import { NETWORK_CONSTANTS } from '../constants/index';

export const TRANSFER_PROXY_ADDRESS = '0x882d80D3a191859d64477eb78Cca46599307ec1C';

export default {
  [NETWORK_CONSTANTS.AVALANCHE_CHAIN]: {
    controllerAddress: '0xC59E05a299b2FBD418DA8E67CB51DA05c67bDB79',
    setTokenCreatorAddress: '0xbaae6346DE5915886B1759c7e68272352b524161',
    basicIssuanceModuleAddress: '0x3832b1EEA677Db706324f2f5C5FA18081c44ccCb',
    streamingFeeModuleAddress: '0x0c9184c1190b73dFFCEea44aE196E11CD2eC4a6C',
    tradeModuleAddress: '0xD9ABfa88beacC41FA1119210e39fCd49b58D5ef8',
    protocolViewerAddress: '0x504e2230bAAce0974a61822D8F7Ac255Be3Bf061',
    slippageIssuanceModuleAddress: '0x0000000000000000000000000000000000000000',
    perpV2LeverageModuleAddress: '0x0000000000000000000000000000000000000000',
    perpV2LeverageModuleViewerAddress: '0x0000000000000000000000000000000000000000',
    perpV2BasisTradingModuleAddress: '0x0000000000000000000000000000000000000000',
    perpV2BasisTradingModuleViewerAddress: '0x0000000000000000000000000000000000000000',
    debtIssuanceModuleV2Address: '0x6808f8D7DF3295BdA11998dd675C1fd2CedCbF5f',
    delegatedManagerFactoryAddress: '0xaF31F117439bDEfa52B4e2fa47C0B800a6D10c70',
    issuanceExtensionAddress: '0xea1bD0ae3Ec45e32a6090eDb445dACFc083A8db6',
    tradeExtensionAddress: '0xd33042aa2d9aEd46d741F2ff063de6516DBbFdE5',
    batchTradeExtensionAddress: '0xa415bcFD9d8B70DaFEe7d53cDDB636e1395683C7',
    streamingFeeExtensionAddress: '0x5018AC1223CebdE29bE41b07b858F3846390B052',
  },
  [NETWORK_CONSTANTS.OPTIMISM_CHAIN]: {
    controllerAddress: '0x84D5657347cC2beD0A4D6a82c0A6f3bE1a021cc6',
    setTokenCreatorAddress: '0x0bc84D31f11D90156c30B4f19509Ede969A0B840',
    basicIssuanceModuleAddress: '0xe1B7e8D3B385A19173ECd7dEAeDD1368f8706263',
    streamingFeeModuleAddress: '0x6a7aE5124677314dc32C5ba3004CbFC9c7Febff0',
    tradeModuleAddress: '0x7215f38011C3e4058Ca3cF7d2b99033016EeFBD8',
    protocolViewerAddress: '0x4E05073560B9377E5561B559c9cADBbe7112e38f',
    slippageIssuanceModuleAddress: '0x2B67D4F9407F772374CaE8B010dB36A770C2c3ae',
    perpV2LeverageModuleAddress: '0x659641F71525b02bFe7203F3e5113c48E6736540',
    perpV2LeverageModuleViewerAddress: '0xea1bD0ae3Ec45e32a6090eDb445dACFc083A8db6',
    perpV2BasisTradingModuleAddress: '0x2C229EE3aD3fdC0e581d51BaA6b6f45CC9A6Ca39',
    perpV2BasisTradingModuleViewerAddress: '0x3EBEe70505d799ddc6A92Af32A83925EEc4fA8B6',
    debtIssuanceModuleAddress: '0x0000000000000000000000000000000000000000',
    debtIssuanceModuleV2Address: '0xda6D2Da01b7141Ba3232025DC45F192eAE5569DA',
    delegatedManagerFactoryAddress: '0x6A2B2fCC2bF5c1C1c0c96036bd11337ec5fFA16a',
    issuanceExtensionAddress: '0x32C993A42a654C63d296EeF15c5AcD3FFd272165',
    tradeExtensionAddress: '0x5fdbEb52Adfc6FbA26ef0E4b5e9464C63A15467b',
    batchTradeExtensionAddress: '0x797136fB4dd214FEF0A510B2946ce58CaB390218',
    streamingFeeExtensionAddress: '0x8F14b50d5e56422b2759C4fdD8D148Bb28a6599D',
  },
  [NETWORK_CONSTANTS.POLYGON_CHAIN]: {
    controllerAddress: '0x75FBBDEAfE23a48c0736B2731b956b7a03aDcfB2',
    setTokenCreatorAddress: '0x14f0321be5e581abF9d5BC76260bf015Dc04C53d',
    basicIssuanceModuleAddress: '0x38E5462BBE6A72F79606c1A0007468aA4334A92b',
    debtIssuanceModuleAddress: '0x0000000000000000000000000000000000000000',
    debtIssuanceModuleV2Address: '0xf2dC2f456b98Af9A6bEEa072AF152a7b0EaA40C9',
    streamingFeeModuleAddress: '0x8440f6a2c42118bed0D6E6A89Bf170ffd13e21c0',
    tradeModuleAddress: '0xd04AabadEd11e92Fefcd92eEdbBC81b184CdAc82',
    navIssuanceModuleAddress: '0xb795Ef471e31610739FE9dab06E2D91024f4048E',
    protocolViewerAddress: '0x8D5CF870354ffFaE0586B639da6D4E4F6C659c69',
    slippageIssuanceModuleAddress: '0x0000000000000000000000000000000000000000',
    perpV2LeverageModuleAddress: '0x0000000000000000000000000000000000000000',
    perpV2LeverageModuleViewerAddress: '0x0000000000000000000000000000000000000000',
    perpV2BasisTradingModuleAddress: '0x0000000000000000000000000000000000000000',
    perpV2BasisTradingModuleViewerAddress: '0x0000000000000000000000000000000000000000',
    delegatedManagerFactoryAddress: '0xBDae9e6897E08f7ceEc538c4cb0B7f413EEc3497',
    issuanceExtensionAddress: '0xdBBD0295eF049515A5eD1803995bA7296B866565',
    tradeExtensionAddress: '0x963EA50D6A06278A57F08BC5a056F840F59f7634',
    batchTradeExtensionAddress: '0xd71689B98F526Aa679EfBC9eeeF98F7f99AEC31C',
    streamingFeeExtensionAddress: '0x2E60b48DFc8d995BDbAb52AC353E115b939Dcb9e',
  },
  [NETWORK_CONSTANTS.ETHEREUM_CHAIN]: {
    controllerAddress: '0xa4c8d221d8BB851f83aadd0223a8900A6921A349',
    setTokenCreatorAddress: '0xeF72D3278dC3Eba6Dc2614965308d1435FFd748a',
    basicIssuanceModuleAddress: '0xd8EF3cACe8b4907117a45B0b125c68560532F94D',
    debtIssuanceModuleAddress: '0x39F024d621367C044BacE2bf0Fb15Fb3612eCB92',
    debtIssuanceModuleV2Address: '0x69a592D2129415a4A1d1b1E309C17051B7F28d57',
    streamingFeeModuleAddress: '0x08f866c74205617B6F3903EF481798EcED10cDEC',
    tradeModuleAddress: '0x90F765F63E7DC5aE97d6c576BF693FB6AF41C129',
    navIssuanceModuleAddress: '0xCd34F1b92C6d0d03430ec4A410F758F7776a3504',
    protocolViewerAddress: '0x74391125304f1e4ce11bDb8aaAAABcF3A3Ae2f41',
    slippageIssuanceModuleAddress: '0x0000000000000000000000000000000000000000',
    perpV2LeverageModuleAddress: '0x0000000000000000000000000000000000000000',
    perpV2LeverageModuleViewerAddress: '0x0000000000000000000000000000000000000000',
    perpV2BasisTradingModuleAddress: '0x0000000000000000000000000000000000000000',
    perpV2BasisTradingModuleViewerAddress: '0x0000000000000000000000000000000000000000',
    delegatedManagerFactoryAddress: '0x5132044c71b98315bDD5D8E6900bcf93EB2EbeC0',
    issuanceExtensionAddress: '0x05C5c57E5E75FC8EaD83FE06ebe4aCc471Fb2948',
    tradeExtensionAddress: '0x00DD1D0A8acF768700c009CB5E54BBb5b6920071',
    batchTradeExtensionAddress: '0x2db41170bEd581eAf00415015A8c24900799f99D',
    streamingFeeExtensionAddress: '0x1F52bA34eb80cBD48b9f3Dad43FFB4Cb6D0FCCF5',
  },
};
