import { StyleSheet, css } from 'aphrodite';
import React, { PureComponent } from 'react';
import { Container, Header } from 'semantic-ui-react';

import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';
import { withTranslation, WithTranslation } from 'react-i18next';

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  Security_container: {
    marginTop: '30px',
    marginBottom: '60px',
  },
  Security_header: {
    marginBottom: '5px',
  },
  Security_subHeader: {
    color: COLORS.darkGray,
    marginBottom: '20px',
    fontWeight: 500,
  },
  Security_description: {
    color: COLORS.darkBlue,
    marginBottom: '30px',
  },
  Security_auditTitle: {
    marginBottom: '20px',
  },
  Security_auditRowContainer: {
    borderTop: `1px solid ${COLORS.lightBlue2}`,
    paddingBottom: '30px',
    paddingTop: '30px',
  },
  Security_auditRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  Security_auditFirstColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  Security_auditNumber: {
    color: COLORS.lightBlue4,
    marginRight: '20px',
    fontSize: '24px',
    fontWeight: 400,
  },
  Security_auditHeader: {
    fontSize: '24px',
    fontWeight: 500,
    color: COLORS.darkBlue,
    marginRight: '15px',
  },
  Security_headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [MOBILE_MEDIA_QUERY]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  Security_comingSoonText: {
    marginRight: '15px',
    [MOBILE_MEDIA_QUERY]: {
      marginRight: '0',
      marginTop: '8px',
    },
  },
});

class Security extends PureComponent<WithTranslation> {
  componentDidMount() {
    document.title = 'Security | TokenSets';
  }

  render() {
    const { t } = this.props;

    return (
      <Container className={css(styles.Security_container)}>
        <Header as="h1" className={css(styles.Security_header)}>
          {t('heading.title')}
        </Header>
        <Header as="h2" className={css(styles.Security_subHeader)}>
          {t('heading.sub-title')}
        </Header>
        <p className={css(styles.Security_description)}>
          {t('heading.description-1')}
          <a href="https://openzeppelin.com/" target="_blank" rel="noopener">
            OpenZeppelin
          </a>
          {t('heading.description-2')}
          <a href="https://www.abdk.consulting/" target="_blank" rel="noopener">
            ABDK
          </a>
          {t('heading.description-3')}
          <a href="https://iosiro.com/" target="_blank" rel="noopener">
            Iosiro
          </a>
          {t('heading.description-4')}
          <b>{t('heading.description-5')}</b>
        </p>
        <p className={css(styles.Security_description)}>{t('heading.description-6')}</p>

        <Header as="h3" className={css(styles.Security_auditTitle)}>
          {t('audits.header')}
        </Header>

        <a href="https://blog.openzeppelin.com/set-protocol-audit/" target="_blank" rel="noopener">
          <div className={css(styles.Security_auditRowContainer)}>
            <div className={css(styles.Security_auditRow)}>
              <div className={css(styles.Security_auditFirstColumn)}>
                <Header as="h4" className={css(styles.Security_auditNumber)}>
                  01
                </Header>
                <div className={css(styles.Security_headerContainer)}>
                  <Header as="h4" className={css(styles.Security_auditHeader)}>
                    OpenZeppelin - {t('audits.september')} 2020
                  </Header>
                </div>
              </div>
              <div>
                <img
                  alt="Dark blue arrow right"
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/dark-blue-arrow-right.svg"
                />
              </div>
            </div>
          </div>
        </a>

        <div className={css(styles.Security_auditRowContainer)}>
          <div className={css(styles.Security_auditRow)}>
            <div className={css(styles.Security_auditFirstColumn)}>
              <Header as="h4" className={css(styles.Security_auditNumber)}>
                02
              </Header>
              <div className={css(styles.Security_headerContainer)}>
                <Header as="h4" className={css(styles.Security_auditHeader)}>
                  ABDK - September 2020
                </Header>
                <span className={css(styles.Security_comingSoonText)}>
                  Public Digest Not Available
                </span>
              </div>
            </div>
            <div>
              <img
                alt="Dark blue arrow right"
                src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/dark-blue-arrow-right.svg"
              />
            </div>
          </div>
        </div>

        <a
          href="https://gist.github.com/bernard-wagner/0626e587a87e9532bddba2da7ea80c89"
          target="_blank"
          rel="noopener"
        >
          <div className={css(styles.Security_auditRowContainer)}>
            <div className={css(styles.Security_auditRow)}>
              <div className={css(styles.Security_auditFirstColumn)}>
                <Header as="h4" className={css(styles.Security_auditNumber)}>
                  03
                </Header>
                <div className={css(styles.Security_headerContainer)}>
                  <Header as="h4" className={css(styles.Security_auditHeader)}>
                    Iosiro - Perpetual Module - {t('audits.december')} 2021
                  </Header>
                </div>
              </div>
              <div>
                <img
                  alt="Dark blue arrow right"
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/dark-blue-arrow-right.svg"
                />
              </div>
            </div>
          </div>
        </a>

        <a
          href="https://gist.github.com/bernard-wagner/eb5469ed393d1fde02a9246396a9995b"
          target="_blank"
          rel="noopener"
        >
          <div className={css(styles.Security_auditRowContainer)}>
            <div className={css(styles.Security_auditRow)}>
              <div className={css(styles.Security_auditFirstColumn)}>
                <Header as="h4" className={css(styles.Security_auditNumber)}>
                  04
                </Header>
                <div className={css(styles.Security_headerContainer)}>
                  <Header as="h4" className={css(styles.Security_auditHeader)}>
                    Iosiro - Basis Trading Module - {t('audits.march')} 2022
                  </Header>
                </div>
              </div>
              <div>
                <img
                  alt="Dark blue arrow right"
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/dark-blue-arrow-right.svg"
                />
              </div>
            </div>
          </div>
        </a>
      </Container>
    );
  }
}

export default withTranslation('security')(Security);
