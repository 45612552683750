import Web3 from 'web3';
import WalletLink from 'walletlink';
import { NETWORK_CONSTANTS } from '../constants/index';
import { infuraDomain, infuraKey } from '../utils/providers';

const setupWalletLink = () => {
  const walletLink = new WalletLink({
    appName: 'TokenSets',
    appLogoUrl: 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/set-logo-padded.png',
  });

  const provider = walletLink.makeWeb3Provider(
    `${infuraDomain}${infuraKey}`,
    Number(NETWORK_CONSTANTS.ETHEREUM_ENV_NETWORK),
  );

  const web3Instance = new Web3(provider);

  return {
    walletLink,
    provider,
    web3Instance,
  };
};

export default setupWalletLink;
