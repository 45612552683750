import { emptyActionGenerator, payloadActionGenerator } from '../utils/reduxHelpers';
import { IPosition } from '../typings/index';
import { COLORS } from '../constants/index';
import { currentSetComponentsSelector } from '../selectors/setDetailsSelectors';
import { generateColorFromString } from '../utils/colorUtils';
import { tokenListColorsOverride } from '../constants/tokenListConstants';

export const FETCH_TOKEN_COLORS = 'FETCH_TOKEN_COLORS';
export const RECEIVE_TOKEN_COLORS = 'RECEIVE_TOKEN_COLORS';

export const requestTokenColors = emptyActionGenerator(FETCH_TOKEN_COLORS);
export const receiveTokenColors = payloadActionGenerator(RECEIVE_TOKEN_COLORS);

export const fetchTokenColorsForCurrentSet = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const currentSetComponents = currentSetComponentsSelector(state);

  dispatch(fetchTokenColors(currentSetComponents));
};

export const fetchTokenColors = (tokens: IPosition[]) => async (dispatch: any) => {
  if (!tokens) {
    return;
  }

  const tokenColorRequests = tokens.map((token: IPosition) => {
    return new Promise<[string, string]>(resolve => {
      const defaultTokenColors: [string, string] = tokenListColorsOverride[
        token?.component?.toLowerCase()
      ] || [generateColorFromString(token?.component), COLORS.whiteDarkened.replace('#', '')];

      resolve(defaultTokenColors);
    });
  });

  try {
    dispatch(requestTokenColors());

    const tokenColors = await Promise.all(tokenColorRequests);
    const tokenColorsByAddress = tokens.reduce((list, token, index) => {
      const tokenAddress = token?.component;
      return {
        ...list,
        [tokenAddress]: tokenColors[index],
      };
    }, {});

    dispatch(receiveTokenColors(tokenColorsByAddress));
  } catch (error) {
    dispatch(receiveTokenColors({}));
  }
};
