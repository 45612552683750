import { StyleSheet } from 'aphrodite';

import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';

const { MOBILE_MEDIA_QUERY, TABLET_MEDIA_QUERY, TABLET_LANDSCAPE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  CustomSetExploreTable_container: {
    margin: 'auto',
    width: '100%',
    padding: '0px',
    borderRadius: '4px',
    backgroundColor: COLORS.white,
    position: 'relative',
    marginBottom: '20px',
    marginTop: '20px',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      padding: '0px 20px',
    },
  },
  CustomSetExploreTable_metaUserImage: {
    width: '38px',
    height: '38px',
    marginRight: '12px',
    borderRadius: '50%',
    paddingTop: '1px',
  },
  CustomSetExploreTable_searchBarContainer: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  CustomSetExploreTable_settingsBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  CustomSetExploreTable_filters: {
    marginBottom: '10px',
    width: '485px',
    [MOBILE_MEDIA_QUERY]: {
      width: '300px',
    },
  },
  CustomSetExploreTable_ctaWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  CustomSetExploreTable_createSetCTA: {
    backgroundColor: COLORS.blue,
    height: '30px',
    color: COLORS.white,
    fontSize: '12px',
    marginTop: '1px',
    padding: '0px 8px',
    border: `1px solid ${COLORS.blue}`,
    boxShadow: 'none',
    ':hover': {
      backgroundColor: COLORS.blueDarkened,
      color: COLORS.white,
      boxShadow: 'none',
    },
  },
  CustomSetExploreTable_createSetPolygonCTA: {
    backgroundColor: COLORS.darkPurple,
    height: '30px',
    color: COLORS.white,
    fontSize: '12px',
    marginTop: '1px',
    padding: '0px 8px',
    border: `1px solid ${COLORS.darkPurple}`,
    boxShadow: 'none',
    ':hover': {
      backgroundColor: COLORS.darkPurpleDarkened,
      color: COLORS.white,
      boxShadow: 'none',
    },
  },
  CustomSetExploreTable_headerText: {
    marginBottom: '0px',
  },
  CustomSetExploreTable_setNameAndImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  CustomSetExploreTable_setNameCell: {
    minHeight: '65px',
    verticalAlign: 'middle',
    display: 'flex',
    alignItems: 'center',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      display: 'inline-flex',
      width: '70%',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
    },
    [MOBILE_MEDIA_QUERY]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      flexWrap: 'nowrap',
    },
  },
  CustomSetExploreTable_componentAllocation: {
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      width: '90%',
      marginTop: '10px',
    },
  },
  CustomSetExploreTable_setNameFormatter: {
    display: 'inline-block',
    color: COLORS.darkBlue,
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '-0.09px',
    lineHeight: '22px',
    marginLeft: '12px',
    overflowWrap: 'break-word',
    maxWidth: '180px',
    maxHeight: '74px',
    overflowY: 'hidden',
  },
  CustomSetExploreTable_header: {
    borderBottom: `1px solid ${COLORS.lightBlue2}`,
  },
  CustomSetExploreTable_headerCell: {
    fontSize: '14px',
    fontWeight: 'normal',
    background: COLORS.white,
    color: COLORS.darkGray,
    borderBottom: `1px solid ${COLORS.lightBlue2}`,
  },
  CustomSetExploreTable_headerFilterCell: {
    fontSize: '14px',
    fontWeight: 'normal',
    background: COLORS.white,
    color: COLORS.darkGray,
    borderBottom: `1px solid ${COLORS.lightBlue2}`,
    verticalAlign: 'middle',
  },
  CustomSetExploreTable_bodyCell: {
    height: '60px',
    verticalAlign: 'middle',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      display: 'inline-flex',
      width: '30%',
      alignItems: 'center',
      justifyContent: 'flex-end',
      height: '80px',
    },
  },
  CustomSetExploreTable_secondaryBodyCell: {
    paddingTop: '0px',
  },
  CustomSetExploreTable_mobileHeaderCell: {
    display: 'inline-flex',
    width: '45%',
    alignItems: 'flex-end',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '50px',
    verticalAlign: 'middle',
  },
  CustomSetExploreTable_mobileHeaderNameCell: {
    height: '50px',
    verticalAlign: 'middle',
    display: 'inline-flex',
    width: '50%',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  CustomSetExploreTable_tableTrendCell: {
    paddingLeft: '2px',
    paddingRight: '2px',
  },
  CustomSetExploreTable_tableCellFlex: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  CustomSetExploreTable_tableCellFlexEnd: {
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      justifyContent: 'flex-end',
    },
  },
  CustomSetExploreTable_price: {
    color: COLORS.darkBlue,
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: '10px',
  },
  CustomSetExploreTable_tableCellMobile: {
    textAlign: 'right',
  },
  CustomSetExploreTable_tableRow: {
    borderRadius: '4px',
    borderBottom: `1px solid ${COLORS.lightBlue2}`,
    transition: '0.2s',
    cursor: 'pointer',
    width: '100%',
    ':hover': {
      transform: 'scale(1.01)',
      boxShadow: '0 4px 6px 0 rgba(35, 1, 221, 0.05)',
    },
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      paddingTop: '17px',
      paddingBottom: '17px',
    },
  },
  CustomSetExploreTable_rowCellWithoutTableBorder: {
    borderTop: '0px',
  },
  CustomSetExploreTable_wideCol: {
    display: 'inline-block',
    width: '40%',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      width: '70%',
    },
  },
  CustomSetExploreTable_narrowCol: {
    width: '15%',
  },
  CustomSetExploreTable_divider: {
    width: '98%',
    margin: 'auto',
  },
  CustomSetExploreTable_firstHeader: {
    paddingLeft: '10px',
  },
  CustomSetExploreTable_tableText: {
    verticalAlign: 'middle',
    display: 'table-cell',
    height: '30px',
  },
  CustomSetExploreTable_setTableText: {
    verticalAlign: 'middle',
    display: 'table-cell',
    height: '70px',
    color: COLORS.black,
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      display: 'block',
      height: 'auto',
      marginBottom: '10px',
    },
  },
  CustomSetExploreTable_setName: {
    fontWeight: 600,
    display: 'inline-block',
    height: 'auto',
    paddingTop: '6px',
  },
  CustomSetExploreTable_setNameSubTitle: {
    display: 'inline-block',
    verticalAlign: 'middle',
    paddingTop: '6px',
    paddingLeft: '6px',
  },
  CustomSetExploreTable_buttonHolder: {
    display: 'table-cell',
    verticalAlign: 'middle',
    height: '60px',
  },
  CustomSetExploreTable_setIcon: {
    display: 'inline-block',
    minWidth: '28px',
    width: '28px',
    height: '28px',
    position: 'relative',
    verticalAlign: 'middle',
    borderRadius: '4px',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      minWidth: '30px',
      width: '30px',
      height: '30px',
    },
  },
  CustomSetExploreTable_iconHolder: {
    display: 'inline-block',
    marginRight: '15px',
    marginLeft: '15px',
    borderRadius: '100%',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      marginLeft: '0',
    },
  },
  CustomSetExploreTable_setIntro: {
    display: 'flex',
    alignItems: 'center',
    height: 'auto',
  },
  // Placeholders
  CustomSetExploreTable_iconHolderShimmer: {
    display: 'inline-block',
    minWidth: '28px',
    width: '28px',
    height: '28px',
    marginRight: '15px',
    marginLeft: '15px',
    borderRadius: '100%',
    overflow: 'hidden',
  },
  CustomSetExploreTable_tableRowHeaderShimmer: {
    height: '20px',
    textAlign: 'center',
  },
  CustomSetExploreTable_tableRowShimmer: {
    height: '75px',
    padding: '25px 0',
    textAlign: 'center',
  },
  CustomSetExploreTable_iconShimmerContainer: {
    display: 'inline-block',
  },
  CustomSetExploreTable_nameShimmerContainer: {
    display: 'inline-block',
    width: '150px',
    height: '10px',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      width: '80px',
    },
  },
  CustomSetExploreTable_innerContainer: {
    padding: '10px 10px 0 10px',
  },
  CustomSetExploreTable_errorContainer: {
    border: `1px solid ${COLORS.lightBlue2}`,
    borderRadius: '4px',
    padding: '40px',
    textAlign: 'center',
    width: '100%',
  },
  CustomSetExploreTable_errorDescription: {
    marginBottom: '20px',
  },
  CustomSetExploreTable_flowColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '16px',
  },
  CustomSetExploreTable_filterLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  CustomSetExploreTable_filterArrow: {
    display: 'inline-block',
    marginTop: '-5px',
    marginLeft: '5px',
  },
  CustomSetExploreTable_filterText: {
    display: 'inline-block',
  },
  CustomSetExploreTable_customPriceBadgeStyle: {
    width: '85px',
  },
  CustomSetExploreTable_containerMarginTop: {
    marginTop: '25px',
  },
  CustomSetExploreTable_tableCell: {
    display: 'inline-block',
    width: '15%',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      width: '30%',
    },
  },
  CustomSetExploreTable_zeroStateText: {
    color: COLORS.darkGray,
    padding: '30px',
    textAlign: 'center',
  },
  CustomSetExploreTable_currency: {
    color: COLORS.black,
    fontSize: '10px',
    marginLeft: '5px',
  },
  CustomSetExploreTable_traderIcon: {
    display: 'inline-block',
    minWidth: '28px',
    width: '28px',
    height: '28px',
    position: 'relative',
    verticalAlign: 'middle',
    borderRadius: '100%',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      minWidth: '30px',
      width: '30px',
      height: '30px',
    },
  },
  CustomSetExploreTable_traderName: {
    display: 'inline-block',
    color: COLORS.darkBlue,
    fontSize: '14px',
    letterSpacing: '-0.09px',
    lineHeight: '22px',
    marginLeft: '12px',
    overflowWrap: 'break-word',
    maxWidth: '180px',
    maxHeight: '74px',
  },
  CustomSetExploreTable_feeCell: {
    display: 'flex',
    alignItems: 'center',
  },
  CustomSetExploreTable_maxDrawdown: {
    fontWeight: 'bold',
    display: 'inline-block',
    color: COLORS.darkBlue,
    fontSize: '14px',
    letterSpacing: '-0.09px',
    lineHeight: '22px',
    overflowWrap: 'break-word',
    maxWidth: '180px',
    maxHeight: '74px',
    overflowY: 'hidden',
  },
  CustomSetExploreTable_holderIcon: {
    marginLeft: '10px',
  },
  /* ------------- FOOTER------------- */
  CustomSetExploreTable_footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    borderTop: `1px solid ${COLORS.gray}`,
    margin: '0px -20px -10px',
    [TABLET_MEDIA_QUERY]: {
      marginBottom: '0px',
    },
  },
  CustomSetExploreTable_footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60px',
    width: '100%',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    backgroundColor: COLORS.lightGray2,
    ':hover': {
      backgroundColor: COLORS.lightGray,
    },
  },
  CustomSetExploreTable_footerTextWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  CustomSetExploreTable_footerText: {
    color: COLORS.blue,
    fontWeight: 500,
  },
  CustomSetExploreTable_rightArrow: {
    marginLeft: '10px',
  },
});

export default styles;
