import { OmittedAddressMapping } from '../typings/index';

// ************ NOTE: ************
// Use the checksummed address in here

// These lists currently cannot be empty otherwise the graph API request will actually
// return an empty list
export const omittedEthereumAddressesList: OmittedAddressMapping = {
  //
  // Custom Sets
  //
  '0x659b92666A1D8D0D23d22e55e0799991eaAf196A': true,
  '0x90d8C1eE7fE895a780405d1B62839fa1c7796A70': true,
  '0x23687D9d40F9Ecc86E7666DDdB820e700F954526': true,
  '0x532777F415735dAD24eC97FeEAC62EB1F15cf128': true,
  '0x7F8E3f03D84e0aA7488375C85Ed470b4451f0899': true,
  '0xdc0798c1536EE6452578bB6DaE2bfEc6BBef98c9': true,
  '0xa37895c1b8CE91F15dC774913fBcE9dCE8723705': true,
  '0x6DbA883991Fce7A31fF70F3048C788abF2864d54': true,
  //
  // Tokens
  //
};

export const omittedPolygonAddressesList: OmittedAddressMapping = {
  //
  // Custom Sets
  //
  '0x00074BA30fB06596793a212ea264841E55E666bc': true,
  '0x756AECfB4AB7c7400A8B62A347E96C246F4F4229': true,
  //
  // Tokens
  //
  '0x0000000000000000000000000000000000001010': true, // Can't use MATIC, need WMATIC
};

export const omittedArbitrumAddressesList: OmittedAddressMapping = {
  //
  // Arbitrum
  //
};

export const omittedOptimismAddressesList: OmittedAddressMapping = {
  //
  // Optimism
  //
  '0x2c1Ae762FE57ab01B215374bE9F2F2B5Bcc31C81': true,
  '0x191b29E0c16AdAb408b3B369714295DDB0FEF4ae': true,
};

export const omittedAvalancheAddressesList: OmittedAddressMapping = {
  //
  // Avalanche
  //
  //
  // Tokens
  //
  // AVAX cannot be used directly, use WAVAX.
  // It also isn't a real address with a 0x so it's a bug on Coingecko side
  FvwEAhmxKfeiG8SnEvq42hc6whRyY3EFYAvebMqDNDGCgxN5Z: true,
};
