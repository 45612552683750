import React, { PureComponent } from 'react';
import { Container, Header } from 'semantic-ui-react';
import { css, StyleSheet } from 'aphrodite';

import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  AccountChainWarningBanner_container: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: `1px solid ${COLORS.blue}`,
    borderRadius: '4px',
    padding: '20px',
    marginBottom: '20px',
  },
  AccountChainWarningBanner_headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '15px',
  },
  AccountChainWarningBanner_header: {
    fontSize: '24px',
  },
  AccountChainWarningBanner_icon: {
    marginRight: '10px',
  },
  AccountChainWarningBanner_text: {
    color: COLORS.darkBlue,
    marginBottom: '12px',
    lineHeight: '1.5',
  },
  AccountChainWarningBanner_button: {
    display: 'inline',
    width: '180px',
    padding: '10px 6px',
    fontWeight: 600,
    backgroundColor: COLORS.blue,
    [MOBILE_MEDIA_QUERY]: {
      width: '100%',
    },
  },
});

interface IAccountChainWarningBannerProps {
  chainName: string;
  onAccept: (...args: any[]) => any;
}

/**
 * @title AccountChainWarningBanner
 * @author Set Labs
 *
 * AccountChainWarningBanner displays a warning for users about higher risk Sets before they buy into it.
 */
class AccountChainWarningBanner extends PureComponent<IAccountChainWarningBannerProps> {
  public render() {
    const { onAccept, chainName } = this.props;

    return (
      <Container>
        <div className={css(styles.AccountChainWarningBanner_container)}>
          <div className={css(styles.AccountChainWarningBanner_headerContainer)}>
            <Header as="h2" className={css(styles.AccountChainWarningBanner_header)}>
              You’re viewing your balance on the {chainName} network
            </Header>
          </div>
          <p className={css(styles.AccountChainWarningBanner_text)}>
            <span className={css(styles.AccountChainWarningBanner_text)}>
              If you want to see your account balance on a different network, select from the
              Explore dropdown in the top Navigation bar to select the network you want or change
              networks via your wallet.
            </span>
          </p>
          <button onClick={onAccept} className={css(styles.AccountChainWarningBanner_button)}>
            I understand
          </button>
        </div>
      </Container>
    );
  }
}

export default AccountChainWarningBanner;
