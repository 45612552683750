import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { IListToken, ISavedSetCreatorLocalStorageForm } from '../typings/index';

const LOCAL_STORAGE_PROPERTIES = {
  bundleInfographic: 'bundleInfographic',
  termsOfService: 'termsOfService',
  v2Banner: 'v2Banner',
  defaultOpenIssuance: 'defaultOpenIssuance',
  web3Transactions: 'web3Transactions',
  pendingSetCreationTransactionHash: 'pendingSetCreationTransactionHash',
  deployedSetNeedsInitializationAddress: 'deployedSetNeedsInitializationAddress',
  setPendingCreation: 'setPendingCreation',
  accountWarning: 'accountWarning',
  tokenLists: 'tokenLists',
};

const TEST = 'test';

export function isLocalStorageEnabled() {
  try {
    localStorage.setItem(TEST, TEST);
    localStorage.removeItem(TEST);
    return true;
  } catch (err) {
    return false;
  }
}

function getLocalStorageItem(property: string) {
  if (!isLocalStorageEnabled()) return [];

  try {
    const localStorageItems = localStorage.getItem(property);
    return JSON.parse(localStorageItems) || [];
  } catch (err) {
    localStorage.setItem(property, JSON.stringify([]));
    return [];
  }
}

function addLocalStorageItem(property: string, newItem: any) {
  if (!isLocalStorageEnabled()) return;

  try {
    const localStorageItems = getLocalStorageItem(property) || [];
    localStorageItems.unshift(newItem);
    localStorage.setItem(property, JSON.stringify(localStorageItems));
  } catch (err) {
    // Do nothing if localStorage not enabled.
  }
}

function addLocalStorageItemAsObject(property: string, newItem: any) {
  if (!isLocalStorageEnabled()) return;

  try {
    localStorage.setItem(property, JSON.stringify(newItem));
  } catch (err) {
    // Do nothing if localStorage not enabled.
  }
}

function removeLocalStorageItem(property: string) {
  if (!isLocalStorageEnabled()) return;

  localStorage.removeItem(property);
}

export function getTOSStatus() {
  return getLocalStorageItem(LOCAL_STORAGE_PROPERTIES.termsOfService);
}

export function acceptTOS() {
  addLocalStorageItem(LOCAL_STORAGE_PROPERTIES.termsOfService, {
    acceptedAt: moment(),
  });
}

export function getTokenListCached(tokenListKey: string) {
  const cachedItem = getLocalStorageItem(LOCAL_STORAGE_PROPERTIES.tokenLists).filter(
    (tokenList: { key: string; list: IListToken[] }) => {
      return tokenList['key'] === tokenListKey;
    },
  )?.[0];

  if (cachedItem) {
    const currentDate = new Date();
    const duration = moment.duration(moment(currentDate).diff(moment(cachedItem['addedAt'])));
    const days = duration.days();

    if (days >= 7) {
      removeLocalStorageItem(LOCAL_STORAGE_PROPERTIES.tokenLists);
      return undefined;
    }
    return cachedItem['list'];
  } else {
    return undefined;
  }
}

export function setTokenListCached(tokenListKey: string, tokenList: IListToken[]) {
  addLocalStorageItem(LOCAL_STORAGE_PROPERTIES.tokenLists, {
    key: tokenListKey,
    list: tokenList,
    addedAt: moment(),
  });
}

export function getAccountWarningStatus(chain: string) {
  return getLocalStorageItem(LOCAL_STORAGE_PROPERTIES.accountWarning).includes(chain);
}

export function acceptAccountWarning(chain: string) {
  addLocalStorageItem(LOCAL_STORAGE_PROPERTIES.accountWarning, chain);
}

export function getBundleInfographicStatus() {
  return getLocalStorageItem(LOCAL_STORAGE_PROPERTIES.bundleInfographic);
}

export function confirmBundleInfographicStatus() {
  addLocalStorageItem(LOCAL_STORAGE_PROPERTIES.bundleInfographic, {
    confirmedAt: moment(),
  });
}

export function getV2BannerDismissal() {
  return getLocalStorageItem(LOCAL_STORAGE_PROPERTIES.v2Banner);
}

export function dismissV2Banner() {
  addLocalStorageItem(LOCAL_STORAGE_PROPERTIES.v2Banner, {
    dismissedAt: moment(),
  });
}

/* Set Creation Transaction Tracking */
export function addPendingSetCreationTransactionHash(transactionHash: {
  transactionHash: string;
  timestamp: number;
}) {
  addLocalStorageItem(LOCAL_STORAGE_PROPERTIES.pendingSetCreationTransactionHash, transactionHash);
}

export function getPendingSetCreationTransactionHash():
  | { transactionHash: string; timestamp: number }
  | undefined {
  const localStorageArray = getLocalStorageItem(
    LOCAL_STORAGE_PROPERTIES.pendingSetCreationTransactionHash,
  );
  console.log('local storage array is', localStorageArray);
  return localStorageArray?.[0];
}

export function removePendingSetCreationTransactionHash() {
  removeLocalStorageItem(LOCAL_STORAGE_PROPERTIES.pendingSetCreationTransactionHash);
}

// Store Form Data for Sets Pending Creation
export function addSetPendingCreation(setData: ISavedSetCreatorLocalStorageForm) {
  addLocalStorageItemAsObject(LOCAL_STORAGE_PROPERTIES.setPendingCreation, setData);
}

export function getSetPendingCreation(): undefined | ISavedSetCreatorLocalStorageForm {
  return getLocalStorageItem(LOCAL_STORAGE_PROPERTIES.setPendingCreation);
}

export function removeSetPendingCreation() {
  removeLocalStorageItem(LOCAL_STORAGE_PROPERTIES.setPendingCreation);
}

export function defaultIssuanceIsOpen() {
  const isDefaultOpen = getLocalStorageItem(LOCAL_STORAGE_PROPERTIES.defaultOpenIssuance);

  return isEmpty(isDefaultOpen);
}

export function closeDefaultIssuance() {
  addLocalStorageItem(LOCAL_STORAGE_PROPERTIES.defaultOpenIssuance, {
    dismissedAt: moment(),
  });
}
