import React from 'react';
import { Loader } from 'semantic-ui-react';
import { StyleSheet } from 'aphrodite/no-important';
import { css } from 'aphrodite/no-important';
import { COLORS } from '../../constants/index';

const styles = StyleSheet.create({
  button: {
    border: '2px solid transparent',
    padding: '15px 20px',
    width: '398px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: COLORS.blueGray2,
  },
});

interface ButtonProps {
  style?: object;
}

// Used to display loading spinner inside Button, nonfunctional
export default function PendingButton({ style }: ButtonProps) {
  return (
    <button style={style} disabled={true} className={css(styles.button)}>
      <Loader active inline="centered" className="modal-loader" />
    </button>
  );
}
