import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Header } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { ProgressBar } from '../../components/index';
import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';
import { makeBlockExplorerLink } from '../../utils/web3Utils';

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

export const styles = StyleSheet.create({
  DebtIssuancePending_title: {
    display: 'block',
    width: '400px',
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: 600,
    margin: 'auto',
    marginBottom: '20px',
    [MOBILE_MEDIA_QUERY]: {
      width: '90%',
    },
  },
  DebtIssuancePending_text: {
    display: 'block',
    width: '400px',
    textAlign: 'center',
    margin: 'auto',
    marginBottom: '20px',
    [MOBILE_MEDIA_QUERY]: {
      width: '90%',
    },
  },
  DebtIssuancePending_buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  DebtIssuancePending_viewTransactionButton: {
    padding: '5px 10px',
    color: COLORS.grayDarkened,
    fontSize: '12px',
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '4px',
    marginBottom: '20px',
    ':hover': {
      backgroundColor: COLORS.lightGray,
    },
  },
});

export type DebtIssuancePendingProps = {
  name: string;
  transactionId: string;
  hideProgressBar?: boolean;
};

const DebtIssuancePending = (props: DebtIssuancePendingProps & WithTranslation) => {
  const { name, hideProgressBar, transactionId, t } = props;

  return (
    <div>
      <Header as="h3" className={css(styles.DebtIssuancePending_title)}>
        {t('issue-modals.pending-title')}
      </Header>

      <p className={css(styles.DebtIssuancePending_text)}>
        {t('issue-modals.pending-description', { tokenName: name })}
      </p>

      <div className={css(styles.DebtIssuancePending_buttonWrapper)}>
        <a
          className={css(styles.DebtIssuancePending_viewTransactionButton)}
          href={makeBlockExplorerLink(transactionId)}
          target="_blank"
          rel="noopener noreferrer"
        >
          View Transaction →
        </a>
      </div>

      {!hideProgressBar && <ProgressBar pendingText="" seconds={30} />}
    </div>
  );
};

export default withTranslation('issue-and-redeem')(DebtIssuancePending);
