import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { Container, Grid } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';

import logo from '../../img/logos/set-and-tokensets-logo.svg';
import SocialMediaIcons from '../../components/SocialMediaIcons';
import PoweredBySetProtocol from '../../components/PoweredBySetProtocol';
import FooterLinks from '../../components/FooterLinks';
import Text from '../../components/Text';
import { COLORS } from '../../constants/index';
import { IWindowDimensions } from '../../typings/index';

const styles = StyleSheet.create({
  Footer_container: {
    borderTop: `1px solid ${COLORS.gray}`,
  },
  Footer_grid: {
    paddingTop: '55px',
    paddingBottom: '55px',
  },
  Footer_logo: {
    margin: '0 0',
    paddingRight: '10px',
    height: '40px',
  },
  Footer_logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'left',
  },
  Footer_subTextContainer: {
    margin: '15px 0 15px 0',
    color: COLORS.darkGray,
    maxWidth: '200px',
  },
  Footer_socialMediaHeader: {
    fontWeight: 600,
    fontSize: '1rem;',
    color: COLORS.darkBlue,
    margin: '15px 0 25px 0',
  },
});

interface IFooterProps {
  readonly windowDimension?: IWindowDimensions;
}

const Footer: React.SFC<IFooterProps & WithTranslation> = ({ windowDimension, t }) => {
  const linksColumn = (
    <Grid.Column floated="left">
      <FooterLinks />
    </Grid.Column>
  );

  const socialColumn = (
    <Grid.Column textAlign={windowDimension.isMobile ? 'left' : 'right'}>
      <Grid.Row className={css(styles.Footer_socialMediaHeader)}>
        {t('join-our-community')}
      </Grid.Row>
      <Grid.Row>
        <SocialMediaIcons />
      </Grid.Row>
    </Grid.Column>
  );

  return (
    <Container className={css(styles.Footer_container)} fluid>
      <Grid columns={2} container stackable className={css(styles.Footer_grid)}>
        <Grid.Row>
          <Grid.Column floated="left" width={4} textAlign="left">
            <Grid.Row>
              <div className={css(styles.Footer_logoContainer)}>
                <img src={logo} alt="Set logo" className={css(styles.Footer_logo)} />
              </div>
            </Grid.Row>
            <Grid.Row>
              <div className={css(styles.Footer_subTextContainer)}>
                <Text type="small">{t('asset-management-simplified')}</Text>
              </div>
            </Grid.Row>
            <Grid.Row>
              <PoweredBySetProtocol />
            </Grid.Row>
          </Grid.Column>

          <Grid.Column width={7}>
            {windowDimension.isMobile ? (
              <Grid columns={1}>
                <Grid.Row>{socialColumn}</Grid.Row>
              </Grid>
            ) : (
              <Grid columns={2}>
                <Grid.Row>
                  {linksColumn}
                  {socialColumn}
                </Grid.Row>
              </Grid>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  windowDimension: state.windowDimension,
});

const translated = withTranslation('footer')(Footer);

export default connect(mapStateToProps)(translated);
