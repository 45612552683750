import { toast } from 'react-toastify';

import { utils } from 'ethers';
import { submitSetJSTransaction } from '../actions/transactionWatcherActions';
import { batchFetchPerpIssuanceAllowances } from '../actions/approvalActions';
import {
  fetchPerpIssuanceComponents,
  receivePerpIssuanceComponents,
  receivePerpIssuanceFees,
  requestPerpIssuanceFees,
  setIsSubmittingIssuanceTransaction,
  updateIssuanceQuantity,
} from '../actions/issuanceV2Actions';
import { tokenToBaseUnits } from '../utils/formatUtils';
import {
  setJSInstanceSelector,
  setDetailsCurrentSetAddressSelector,
  issuanceInputQuantityV2Selector,
  readOnlySetJSInstanceSelector,
} from '../selectors/baseSelectors';
import {
  allPerpComponentsSelector,
  createPerpIssueRedeemTransactionArgs,
  maxPerpIssuableTokenQuantitySelector,
} from '../selectors/perpIssuanceRedemptionSelectors';
import { BigNumber, payloadActionGenerator, userRejectedMetamaskTransaction } from '../utils/index';
import { IDebtComponents, IFeeResponse } from '../typings/index';
import { transactionCallerSelector } from '../selectors/transactionSelectors';
import { BigNumber as EthersBigNumber } from 'ethers';
import { TYPE_ISSUE } from '../constants/index';

const SET_MAX_TOKEN_PERP_ISSUE_AMOUNT_BY_LEVERAGE = 'SET_MAX_TOKEN_PERP_ISSUE_AMOUNT_BY_LEVERAGE';

const setMaxTokenPerpIssueAmountByLeverage = payloadActionGenerator(
  SET_MAX_TOKEN_PERP_ISSUE_AMOUNT_BY_LEVERAGE,
);

/* Fetch token allowances for perp issuance */
export const fetchPerpIssuanceAllowancesForCurrentSet = () => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const issuanceComponents = allPerpComponentsSelector(TYPE_ISSUE, state);

  if (!issuanceComponents) return;

  dispatch(batchFetchPerpIssuanceAllowances(issuanceComponents));
};

export const getMaximumSetTokenIssueAmount = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);
  const readOnlySetJSInstance = readOnlySetJSInstanceSelector(state);
  const caller = transactionCallerSelector(state);

  // Higher than the default used for issuance since a max will likely
  // have higher slippage
  const maxSlippagePercentage = '2.5';

  try {
    const maximumSetTokenIssueAmount = await readOnlySetJSInstance.perpV2LeverageViewer.getMaximumSetTokenIssueAmountAsync(
      currentSetAddress,
      EthersBigNumber.from(
        tokenToBaseUnits(new BigNumber(maxSlippagePercentage).div(100).toString()).toString(),
      ),
      caller,
    );

    dispatch(setMaxTokenPerpIssueAmountByLeverage(maximumSetTokenIssueAmount.toString() || '0'));
  } catch (e) {
    toast.warn('Could not calculate max issuance amount based on leverage for this set', {
      toastId: 'fetch-max-issuable-amount',
    });
    console.log(e);
  }
};

export const getPerpIssuanceRequiredComponents = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const setJSInstance = setJSInstanceSelector(state);
  const caller = transactionCallerSelector(state);
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);
  const issuanceQuantity = EthersBigNumber.from(10).pow(18);

  try {
    dispatch(fetchPerpIssuanceComponents());
    const requiredComponents = await setJSInstance.slippageIssuance.getRequiredComponentIssuanceUnits(
      currentSetAddress,
      issuanceQuantity,
      caller,
    );

    const componentAddresses = requiredComponents?.[0];
    const equityValues = requiredComponents?.[1];
    const debtValues = requiredComponents?.[2];
    const perpComponents: IDebtComponents = {
      componentAddresses,
      equityValues,
      debtValues,
    };
    dispatch(receivePerpIssuanceComponents(perpComponents));
  } catch (e) {
    toast.warn('Could not fetch required perp issuance components', {
      toastId: 'fetch-perp-issuance-components',
    });
    console.log('Could not fetch required perp issuance components', e);
    dispatch(receivePerpIssuanceComponents({}));
  }
};

export const perpIssueCurrentSet = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const setJSInstance = setJSInstanceSelector(state);

  try {
    const perpIssueTransactionArguments = await createPerpIssueRedeemTransactionArgs(
      TYPE_ISSUE,
      state,
    );

    if (!perpIssueTransactionArguments) return;

    const perpIssuanceRequest = submitSetJSTransaction(() => {
      return setJSInstance.slippageIssuance.issueWithSlippageAsync(
        ...perpIssueTransactionArguments,
      );
    });

    dispatch(setIsSubmittingIssuanceTransaction(true));

    dispatch(perpIssuanceRequest);
  } catch (e) {
    console.log(e);
    if (userRejectedMetamaskTransaction(e)) {
      if (e.message.includes('execution reverted: 11')) {
        toast.error(
          'Your perpetual issuance request could not be completed. There is not enough collateral to cover a new borrow.',
          { toastId: 'issuance-request' },
        );
      } else {
        toast.error(`RPC Error: ${e.message}`, {
          toastId: 'issuance-request',
        });
      }
      return;
    }

    toast.error('Something went wrong with your issuance request. Please try again later.', {
      toastId: 'issuance-request',
    });
  } finally {
    dispatch(setIsSubmittingIssuanceTransaction(false));
  }
};

/**
 * Set the max issuable quantity the user can mint of the currently shown Set.
 */
export const setMaxPerpIssuableQuantity = () => (dispatch: any, getState: any) => {
  const state = getState();
  const maxIssuableQuantity = maxPerpIssuableTokenQuantitySelector(state);

  dispatch(updateIssuanceQuantity(maxIssuableQuantity || '0'));
};

/**
 * Fetch the fees for the given set issuance quantity
 */
export const fetchFeesForIssueQuantity = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const setJSInstance = setJSInstanceSelector(state);
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);
  const rawIssuanceQuantity = issuanceInputQuantityV2Selector(state);
  const formattedIssuanceQuantity = utils.parseEther(
    rawIssuanceQuantity?.length ? rawIssuanceQuantity : '0',
  );

  dispatch(requestPerpIssuanceFees());

  try {
    const totalFees: IFeeResponse = await setJSInstance.slippageIssuance.calculateTotalFeesAsync(
      currentSetAddress,
      formattedIssuanceQuantity,
      true,
    );

    dispatch(
      receivePerpIssuanceFees({
        totalQuantity: totalFees.totalQuantity,
        managerFee: totalFees.managerFee,
        managerFeePercentage: new BigNumber(totalFees.managerFee.toString() || 0)
          .div(formattedIssuanceQuantity.toString())
          .mul(100)
          .toString(),
        protocolFee: totalFees.protocolFee,
        protocolFeePercentage: new BigNumber(totalFees.protocolFee.toString() || 0)
          .div(formattedIssuanceQuantity.toString())
          .mul(100)
          .toString(),
      }),
    );

    return totalFees;
  } catch (e) {
    console.log(e);
    toast.warn('Something went wrong with fetching fee values. Please try again later.', {
      toastId: 'fee-request',
    });
  }
};
