import {
  SET_FETCHING_PERP_LEVERAGE_DATA,
  RECEIVE_COLLATERAL_TOKEN,
  RECEIVE_POSITION_UNIT_INFO,
  RECEIVE_ACCOUNT_INFO,
  RECEIVE_VIRTUAL_ASSET_DISPLAY_INFOS,
  RECEIVE_TOTAL_COLLATERAL_UNITS,
} from '../actions/perpLeverageActions';

import { IPerpPositionUnitInfo, IPerpAccountInfo } from '../typings/index';
import { VAssetDisplayInfo } from 'set.js/dist/types/src/types';

export interface PerpLeverageReducerState {
  isFetchingPerpLeverageData: boolean;
  collateralToken: string;
  totalCollateralUnits: string;
  positionUnitInfos: IPerpPositionUnitInfo[];
  accountInfo: IPerpAccountInfo;
  vAssetDisplayInfos: VAssetDisplayInfo[];
}

const initialState: PerpLeverageReducerState = {
  isFetchingPerpLeverageData: false,
  collateralToken: undefined,
  totalCollateralUnits: undefined,
  positionUnitInfos: [] as IPerpPositionUnitInfo[],
  accountInfo: {} as IPerpAccountInfo,
  vAssetDisplayInfos: [] as VAssetDisplayInfo[],
};

const perpLeverageReducer = (state = initialState, action: any): PerpLeverageReducerState => {
  switch (action.type) {
    case SET_FETCHING_PERP_LEVERAGE_DATA:
      return {
        ...state,
        isFetchingPerpLeverageData: action.payload,
      };

    case RECEIVE_COLLATERAL_TOKEN:
      return {
        ...state,
        collateralToken: action.payload,
      };
    case RECEIVE_TOTAL_COLLATERAL_UNITS:
      return {
        ...state,
        collateralToken: action.payload[0],
        totalCollateralUnits: action.payload[1].toString(),
      };
    case RECEIVE_POSITION_UNIT_INFO:
      return {
        ...state,
        positionUnitInfos: action.payload.map((info: IPerpPositionUnitInfo) => {
          return {
            baseToken: info.baseToken,
            baseUnit: info.baseUnit,
            quoteUnit: info.quoteUnit,
          };
        }),
      };
    case RECEIVE_ACCOUNT_INFO:
      return {
        ...state,
        accountInfo: {
          collateralBalance: action.payload.collateralBalance,
          owedRealizedPnL: action.owedRealizedPnl,
          pendingFundingPayments: action.payload.pendingFundingPayments,
          netQuoteBalance: action.payload.netQuoteBalance,
        },
      };
    case RECEIVE_VIRTUAL_ASSET_DISPLAY_INFOS:
      return {
        ...state,
        vAssetDisplayInfos: action.payload,
      };

    default:
      return state;
  }
};

export default perpLeverageReducer;
