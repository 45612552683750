import { StyleSheet, css } from 'aphrodite';
import React, { PureComponent } from 'react';
import { Container } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import queryString from 'query-string';
import { toChecksumAddress } from 'tochecksum';

import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';
import { IWindowDimensions, IMatch, GraphSetToken, IListToken } from '../../typings/index';
import Pagination from '../../containers/Pagination';
import { SetCategory } from '../../reducers/exploreReducer';
import CustomSetExploreTable from '../../containers/CustomSetExploreTable';
import CategoryFilter from '../../components/RebalancingSetExploreTable/CategoryFilter';
import { TableState, tableStateTableDataSourceMap } from './enums';

const { TABLET_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  Explore_marginBottomMed: {
    marginBottom: '15px',
    [TABLET_MEDIA_QUERY]: {
      marginBottom: '15px',
    },
  },
  Explore_marginTopLarge: {
    marginTop: '10px',
    [TABLET_MEDIA_QUERY]: {
      marginTop: '30px',
    },
  },
  Explore_pagination: {
    marginTop: '20px',
  },
  Explore_menuItem: {
    color: COLORS.darkGray,
    fontWeight: 500,
    marginLeft: '40px',
    paddingLeft: '5px',
    paddingRight: '5px',
    [TABLET_MEDIA_QUERY]: {
      marginLeft: '20px',
    },
  },
  Explore_menuItemFirst: {
    marginLeft: '0',
  },
  Explore_menuItemActive: {
    borderColor: COLORS.blue,
    color: COLORS.blue,
    fontWeight: 600,
  },
  Explore_tab: {
    borderRadius: '0 4px 4px 0',
  },
  Explore_header: {
    margin: '48px auto',
    textAlign: 'center',
  },
  Explore_headerText: {
    color: COLORS.darkBlue,
    fontSize: '40px',
    lineHeight: '40px',
    fontWeight: 600,
    marginBottom: '16px',
  },
  Explore_subheaderText: {
    color: COLORS.disabledGray,
    fontSize: '18px',
    lineHeight: '18px',
    fontWeight: 400,
  },
  Explore_tableContainer: {
    border: `1px solid ${COLORS.blueGray2}`,
    borderRadius: '12px',
    padding: '40px',
    marginBottom: '15px',
    backgroundColor: COLORS.white,
    [TABLET_MEDIA_QUERY]: {
      marginTop: '10px',
      padding: '10px',
    },
  },
});

interface IExploreProps {
  currentMainnetChainId: number;
  account: string;
  paginatedDeployedSetAddresses: string[];
  paginatedCommunitySets: IListToken[];
  featuredTokenSets: IListToken[];
  history: any;
  location?: any;
  filteredSetCategory: SetCategory;
  isFetchingDeployedSetDetails: boolean;
  isHidingHeader?: boolean;
  isHidingPagination?: boolean;
  isShowingFooter: boolean;
  isShowingAdvancedData: boolean;
  isTruncatedList: boolean;
  windowDimension: IWindowDimensions;
  match: IMatch;
  isFetchingNextPage: boolean;
  currentPageNumber: number;
  setTokenPages: GraphSetToken[][];
  selectedNetworks: string[];
  isFetchingTokenList: boolean;
  tokenSetsTokenListByAddress: { [address: string]: IListToken };
  fetchDeployedSetsWithLogos: (...args: any[]) => any;
  fetchTokenSetsTokenList: (...args: any[]) => any;
  fetchFeaturedTokenSetsTokenList: (...args: any[]) => any;
  setCustomSetsPageNumberWithQuery: (...args: any[]) => any;
  onSetCategoryFilter: (...args: any[]) => any;
  changeNetworkFilter: (...args: any[]) => any;
}

interface IExploreState {
  activeTabIndex: number;
}

enum QueryParams {
  ThematicExposureView = 'thematic',
  LeveragedExposureView = 'leverage',
  YieldBearingView = 'yield',
  UncategorizedView = 'uncategorized',
}

/**
 * Display Top Portfolios
 */
class Explore extends PureComponent<IExploreProps & WithTranslation, IExploreState> {
  constructor(props: any) {
    super(props);

    this.state = {
      activeTabIndex: 0,
    };
  }

  async componentDidMount() {
    const {
      fetchTokenSetsTokenList,
      fetchFeaturedTokenSetsTokenList,
      setCustomSetsPageNumberWithQuery,
    } = this.props;

    setCustomSetsPageNumberWithQuery(1);
    fetchTokenSetsTokenList();
    fetchFeaturedTokenSetsTokenList();

    this.setCategoryByQueryParam();
  }

  static get defaultProps() {
    return {
      isShowingFooter: false,
      isShowingAdvancedData: true,
      isHidingHeader: false,
      isTruncatedList: false,
      windowDimension: {
        isTablet: false,
      } as IWindowDimensions,
    };
  }

  public setCategoryByQueryParam = () => {
    const { location, onSetCategoryFilter } = this.props;

    if (!location || !location.search) return;

    const params = queryString.parse(location.search);

    switch (params.show) {
      case QueryParams.ThematicExposureView:
        onSetCategoryFilter(SetCategory.THEMATIC_EXPOSURE);
        return;
      case QueryParams.LeveragedExposureView:
        onSetCategoryFilter(SetCategory.LEVERAGED_EXPOSURE);
        return;
      case QueryParams.YieldBearingView:
        onSetCategoryFilter(SetCategory.YIELD_BEARING);
        return;
      case QueryParams.UncategorizedView:
        onSetCategoryFilter(SetCategory.UNCATEGORIZED);
        return;
    }
  };

  public renderNewExplore = () => {
    const {
      currentMainnetChainId,
      history,
      isShowingAdvancedData,
      isTruncatedList,
      filteredSetCategory,
      featuredTokenSets,
      windowDimension,
      isFetchingTokenList,
      paginatedCommunitySets,
      isFetchingDeployedSetDetails,
      onSetCategoryFilter,
    } = this.props;

    const setList =
      featuredTokenSets
        ?.filter((s: IListToken) => {
          if (filteredSetCategory === SetCategory.THEMATIC_EXPOSURE) {
            return s.type === 'index' || s.type === 'rebalancing_index';
          } else if (filteredSetCategory === SetCategory.LEVERAGED_EXPOSURE) {
            return s.type === 'flexible_leverage' || s.type === 'perpetual_leverage';
          } else if (filteredSetCategory === SetCategory.YIELD_BEARING) {
            return s.type === 'index_yield' || s.type === 'basis_trading';
          }
        })
        ?.filter((s: IListToken) => {
          return String(s.chainId) === String(currentMainnetChainId);
        }) || [];

    return (
      <>
        <div className={css(styles.Explore_header)}>
          <div className={css(styles.Explore_subheaderText)}>
            Explore Sets and products created on Set Protocol
          </div>
        </div>
        <CategoryFilter
          filteredSetCategory={filteredSetCategory}
          onSetCategoryFilter={category => {
            switch (category) {
              case SetCategory.THEMATIC_EXPOSURE:
                window.history.replaceState(
                  {},
                  '',
                  `#/explore?show=${QueryParams.ThematicExposureView}`,
                );
                break;
              case SetCategory.LEVERAGED_EXPOSURE:
                window.history.replaceState(
                  {},
                  '',
                  `#/explore?show=${QueryParams.LeveragedExposureView}`,
                );
                break;
              case SetCategory.YIELD_BEARING:
                window.history.replaceState(
                  {},
                  '',
                  `#/explore?show=${QueryParams.YieldBearingView}`,
                );
                break;
              case SetCategory.UNCATEGORIZED:
                window.history.replaceState(
                  {},
                  '',
                  `#/explore?show=${QueryParams.UncategorizedView}`,
                );
                break;
            }
            onSetCategoryFilter(category);
          }}
          windowDimension={windowDimension}
        />
        <div className={css(styles.Explore_tableContainer)}>
          {filteredSetCategory === SetCategory.UNCATEGORIZED ? (
            <>
              <CustomSetExploreTable
                hideHeader
                setAddresses={paginatedCommunitySets?.map(communitySet =>
                  toChecksumAddress(communitySet.address),
                )}
                history={history}
                isLoading={isFetchingTokenList || isFetchingDeployedSetDetails}
                isShowingAdvancedData={isShowingAdvancedData}
                isTruncatedList={isTruncatedList}
                windowDimension={windowDimension}
                onSort={() => {}}
              />
              <div className={css(styles.Explore_pagination)}>
                <Pagination
                  tableDataSource={tableStateTableDataSourceMap[TableState.SHOW_COMMUNITY_SETS]}
                  elementType={'Sets'}
                />
              </div>
            </>
          ) : (
            <>
              <CustomSetExploreTable
                hideHeader
                setAddresses={setList?.map(set => toChecksumAddress(set.address))}
                history={history}
                isLoading={isFetchingTokenList || isFetchingDeployedSetDetails}
                isShowingAdvancedData={isShowingAdvancedData}
                isTruncatedList={isTruncatedList}
                windowDimension={windowDimension}
                onSort={() => {}}
              />
            </>
          )}
        </div>
      </>
    );
  };

  public render() {
    return <Container>{this.renderNewExplore()}</Container>;
  }
}

export default withTranslation('explore')(Explore);
