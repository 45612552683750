const perpIssuanceModuleABI = [
  {
    inputs: [
      {
        internalType: 'contract IController',
        name: '_controller',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: '_newFeeRecipient',
        type: 'address',
      },
    ],
    name: 'FeeRecipientUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: '_newIssueFee',
        type: 'uint256',
      },
    ],
    name: 'IssueFeeUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: '_newRedeemFee',
        type: 'uint256',
      },
    ],
    name: 'RedeemFeeUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: '_issuer',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: '_to',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: '_hookContract',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: '_quantity',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: '_managerFee',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: '_protocolFee',
        type: 'uint256',
      },
    ],
    name: 'SetTokenIssued',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: '_redeemer',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: '_to',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: '_quantity',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: '_managerFee',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: '_protocolFee',
        type: 'uint256',
      },
    ],
    name: 'SetTokenRedeemed',
    type: 'event',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_quantity',
        type: 'uint256',
      },
      {
        internalType: 'bool',
        name: '_isIssue',
        type: 'bool',
      },
    ],
    name: 'calculateTotalFees',
    outputs: [
      {
        internalType: 'uint256',
        name: 'totalQuantity',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'managerFee',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'protocolFee',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'controller',
    outputs: [
      {
        internalType: 'contract IController',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
    ],
    name: 'getModuleIssuanceHooks',
    outputs: [
      {
        internalType: 'address[]',
        name: '',
        type: 'address[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_quantity',
        type: 'uint256',
      },
    ],
    name: 'getRequiredComponentIssuanceUnits',
    outputs: [
      {
        internalType: 'address[]',
        name: '',
        type: 'address[]',
      },
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_quantity',
        type: 'uint256',
      },
    ],
    name: 'getRequiredComponentIssuanceUnitsOffChain',
    outputs: [
      {
        internalType: 'address[]',
        name: '',
        type: 'address[]',
      },
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_quantity',
        type: 'uint256',
      },
    ],
    name: 'getRequiredComponentRedemptionUnits',
    outputs: [
      {
        internalType: 'address[]',
        name: '',
        type: 'address[]',
      },
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_quantity',
        type: 'uint256',
      },
    ],
    name: 'getRequiredComponentRedemptionUnitsOffChain',
    outputs: [
      {
        internalType: 'address[]',
        name: '',
        type: 'address[]',
      },
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_maxManagerFee',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_managerIssueFee',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_managerRedeemFee',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: '_feeRecipient',
        type: 'address',
      },
      {
        internalType: 'contract IManagerIssuanceHook',
        name: '_managerIssuanceHook',
        type: 'address',
      },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_hook',
        type: 'address',
      },
    ],
    name: 'isModuleIssuanceHook',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '',
        type: 'address',
      },
    ],
    name: 'issuanceSettings',
    outputs: [
      {
        internalType: 'uint256',
        name: 'maxManagerFee',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'managerIssueFee',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'managerRedeemFee',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'feeRecipient',
        type: 'address',
      },
      {
        internalType: 'contract IManagerIssuanceHook',
        name: 'managerIssuanceHook',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_quantity',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: '_to',
        type: 'address',
      },
    ],
    name: 'issue',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_setQuantity',
        type: 'uint256',
      },
      {
        internalType: 'address[]',
        name: '_checkedComponents',
        type: 'address[]',
      },
      {
        internalType: 'uint256[]',
        name: '_maxTokenAmountsIn',
        type: 'uint256[]',
      },
      {
        internalType: 'address',
        name: '_to',
        type: 'address',
      },
    ],
    name: 'issueWithSlippage',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_quantity',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: '_to',
        type: 'address',
      },
    ],
    name: 'redeem',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_setQuantity',
        type: 'uint256',
      },
      {
        internalType: 'address[]',
        name: '_checkedComponents',
        type: 'address[]',
      },
      {
        internalType: 'uint256[]',
        name: '_minTokenAmountsOut',
        type: 'uint256[]',
      },
      {
        internalType: 'address',
        name: '_to',
        type: 'address',
      },
    ],
    name: 'redeemWithSlippage',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
    ],
    name: 'registerToIssuanceModule',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'removeModule',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
    ],
    name: 'unregisterFromIssuanceModule',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_newFeeRecipient',
        type: 'address',
      },
    ],
    name: 'updateFeeRecipient',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_newIssueFee',
        type: 'uint256',
      },
    ],
    name: 'updateIssueFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_newRedeemFee',
        type: 'uint256',
      },
    ],
    name: 'updateRedeemFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

export default perpIssuanceModuleABI;
