import { TableDataSource } from '../../reducers/tableReducers';

interface ITableStateTableDataSourceMap {
  [key: number]: string;
}

export enum TableState {
  SHOW_PORTFOLIOS,
  SHOW_COMMUNITY_SETS,
  SHOW_CUSTOM_SETS,
}

// Used to determine which pagination data to source actions & state from, based on current table display data.
export const tableStateTableDataSourceMap: ITableStateTableDataSourceMap = {
  [TableState.SHOW_PORTFOLIOS]: TableDataSource.portfolios,
  [TableState.SHOW_COMMUNITY_SETS]: TableDataSource.communitySets,
  [TableState.SHOW_CUSTOM_SETS]: TableDataSource.customSets,
};
