import {
  SET_IS_SUBMITTING_REDEMPTION_TRANSACTION_V2,
  UPDATE_REDEMPTION_INPUT_QUANTITY_V2,
  FETCH_DEBT_REDEMPTION_COMPONENTS,
  RECEIVE_DEBT_REDEMPTION_COMPONENTS,
  FETCH_PERP_REDEMPTION_COMPONENTS,
  RECEIVE_PERP_REDEMPTION_COMPONENTS,
  REQUEST_PERP_REDEMPTION_FEES,
  RECEIVE_PERP_REDEMPTION_FEES,
  RECEIVE_DEBT_REDEMPTION_FEES,
  REQUEST_DEBT_REDEMPTION_FEES,
} from '../actions/redemptionV2Actions';
import { IDebtComponents, IFeeResponse } from '../typings/index';

export interface RedemptionV2ReducerState {
  isSubmittingRedemptionTransaction: boolean;
  isFetchingDebtRedemptionComponents: boolean;
  isFetchingPerpRedemptionComponents: boolean;
  isFetchingPerpRedemptionFees: boolean;
  isFetchingDebtRedemptionFees: boolean;
  redemptionInputQuantity: string;
  requiredDebtRedemptionComponents: IDebtComponents;
  requiredPerpRedemptionComponents: IDebtComponents;
  perpRedemptionFees: IFeeResponse;
  debtRedemptionFees: IFeeResponse;
}

const initialState = {
  isSubmittingRedemptionTransaction: false,
  isFetchingDebtRedemptionComponents: false,
  isFetchingPerpRedemptionComponents: false,
  isFetchingPerpRedemptionFees: false,
  isFetchingDebtRedemptionFees: false,
  redemptionInputQuantity: '1',
  requiredDebtRedemptionComponents: {},
  requiredPerpRedemptionComponents: {},
  perpRedemptionFees: {} as IFeeResponse,
  debtRedemptionFees: {} as IFeeResponse,
};

const redemptionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_IS_SUBMITTING_REDEMPTION_TRANSACTION_V2:
      return {
        ...state,
        isSubmittingRedemptionTransaction: action.payload,
      };

    case UPDATE_REDEMPTION_INPUT_QUANTITY_V2:
      return {
        ...state,
        redemptionInputQuantity: action.payload,
      };

    case FETCH_DEBT_REDEMPTION_COMPONENTS:
      return {
        ...state,
        isFetchingDebtRedemptionComponents: true,
      };

    case RECEIVE_DEBT_REDEMPTION_COMPONENTS:
      return {
        ...state,
        isFetchingDebtRedemptionComponents: false,
        requiredDebtRedemptionComponents: action.payload,
      };

    case FETCH_PERP_REDEMPTION_COMPONENTS:
      return {
        ...state,
        isFetchingPerpRedemptionComponents: true,
      };

    case RECEIVE_PERP_REDEMPTION_COMPONENTS:
      return {
        ...state,
        isFetchingPerpRedemptionComponents: false,
        requiredPerpRedemptionComponents: action.payload,
      };

    case REQUEST_PERP_REDEMPTION_FEES:
      return {
        ...state,
        isFetchingPerpRedemptionFees: true,
      };

    case RECEIVE_PERP_REDEMPTION_FEES:
      return {
        ...state,
        isFetchingPerpRedemptionFees: false,
        perpRedemptionFees: {
          totalQuantity: action.payload.totalQuantity,
          managerFee: action.payload.managerFee,
          managerFeePercentage: action.payload.managerFeePercentage,
          protocolFee: action.payload.protocolFee,
          protocolFeePercentage: action.payload.protocolFeePercentage,
        },
      };

    case REQUEST_DEBT_REDEMPTION_FEES:
      return {
        ...state,
        isFetchingDebtRedemptionFees: true,
      };

    case RECEIVE_DEBT_REDEMPTION_FEES:
      return {
        ...state,
        isFetchingDebtRedemptionFees: false,
        debtRedemptionFees: {
          totalQuantity: action.payload.totalQuantity,
          managerFee: action.payload.managerFee,
          managerFeePercentage: action.payload.managerFeePercentage,
          protocolFee: action.payload.protocolFee,
          protocolFeePercentage: action.payload.protocolFeePercentage,
        },
      };

    default:
      return state;
  }
};

export default redemptionReducer;
