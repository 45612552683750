import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { withTranslation, WithTranslation } from 'react-i18next';
import Web3 from 'web3';

import { ITokenBalances, IMatch, IHistory, ISetDetails, IFeeResponse } from '../../typings/index';
import { formatTokenUnit, BigNumber } from '../../utils/index';
import { IDebtComponentWithToken } from '../../typings/index';
import { generateColorFromString } from '../../utils/colorUtils';
import { getListTokenImage, tokenFromBaseUnits } from '../../utils/formatUtils';
import { COLORS } from '../../constants/index';
import darkArrow from '../../img/icons/right-arrow-dark.svg';
import { utils } from 'ethers';

const styles = StyleSheet.create({
  DebtIssuanceForm_container: {
    maxWidth: '400px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  DebtIssuanceForm_setImage: {
    width: '50px',
    height: '50px',
    marginBottom: '20px',
    borderRadius: '50%',
  },
  DebtIssuanceForm_title: {
    fontSize: '30px',
    fontWeight: 600,
    color: COLORS.darkBlue,
    lineHeight: 1.2,
    textAlign: 'center',
    marginBottom: '20px',
  },
  DebtIssuanceForm_subTitle: {
    fontSize: '14px',
    lineHeight: 1.5,
    textAlign: 'center',
    marginBottom: '20px',
  },
  DebtIssuanceForm_debtIssuanceDescription: {
    fontSize: '14px',
    lineHeight: 1.5,
    textAlign: 'left',
    marginBottom: '20px',
  },

  /* Input Field */
  DebtIssuanceForm_issueInputContainer: {
    width: '100%',
    position: 'relative',
    marginBottom: '20px',
  },
  DebtIssuanceForm_issueQuantityInput: {
    width: '100%',
    fontSize: '16px',
    padding: '15px',
    paddingRight: '120px',
    marginBottom: '20px',
    border: `2px solid ${COLORS.gray}`,
    borderRadius: '4px',
    ':focus': {
      borderColor: COLORS.blue,
    },
  },
  DebtIssuanceForm_issueInputLabelContainer: {
    position: 'absolute',
    top: 15,
    right: 16,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  DebtIssuanceForm_issueInputSymbol: {
    fontSize: '12px',
    color: COLORS.blue,
    fontWeight: 500,
    lineHeight: 1,
  },
  DebtIssuanceForm_issueMaxButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '22px',
    fontSize: '9px',
    marginLeft: '10px',
    padding: '11px 12px 10px 12px',
    ':active': {
      backgroundColor: COLORS.blueDarkened,
    },
  },

  /* DebtIssuance Components Table Header */
  DebtIssuanceForm_tableHeader: {
    display: 'flex',
    paddingBottom: '10px',
    borderBottom: `1px solid ${COLORS.gray}`,
  },
  DebtIssuanceForm_tokenLabel: {
    fontSize: '10px',
    lineHeight: 1.5,
    flex: 1,
  },
  DebtIssuanceForm_requiredAmountLabel: {
    fontSize: '10px',
    lineHeight: 1.5,
    flex: 1,
    textAlign: 'right',
  },
  DebtIssuanceForm_userBalanceLabel: {
    fontSize: '10px',
    lineHeight: 1.5,
    flex: 1,
    textAlign: 'right',
  },

  /* DebtIssuance Components */
  DebtIssuanceForm_componentContainer: {
    width: '100%',
    marginBottom: '30px',
  },
  DebtIssuanceForm_component: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
    borderBottom: `1px solid ${COLORS.lightGray}`,
  },
  DebtIssuanceForm_componentLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  DebtIssuanceForm_componentIcon: {
    height: '20px',
    width: '20px',
    borderRadius: '50%',
  },
  DebtIssuanceForm_componentSymbol: {
    fontSize: '14px',
    color: COLORS.darkBlue,
    fontWeight: 600,
    marginLeft: '10px',
  },

  DebtIssuanceForm_componentQuantityRequired: {
    fontSize: '14px',
    color: COLORS.darkBlue,
    flex: 1,
    textAlign: 'right',
  },
  DebtIssuanceForm_componentUserBalance: {
    fontSize: '14px',
    color: COLORS.green,
    flex: 1,
    textAlign: 'right',
  },

  /* Submit Button + Footer */
  DebtIssuanceForm_submitButton: {
    width: '100%',
    padding: '20px',
    fontWeight: 600,
    marginBottom: '30px',
    backgroundColor: COLORS.blue,
    color: COLORS.white,
    ':hover': {
      backgroundColor: COLORS.blueDarkened,
    },
  },
  DebtIssuanceForm_submitButtonDisabled: {
    pointerEvents: 'none',
    backgroundColor: COLORS.gray,
    ':hover': {
      backgroundColor: COLORS.gray,
    },
  },
  DebtIssuanceForm_errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px',
    backgroundColor: COLORS.redAlpha25,
    borderRadius: '4px',
  },
  DebtIssuanceForm_errorText: {
    textAlign: 'center',
    fontSize: '14px',
    color: COLORS.red,
  },

  DebtIssuanceForm_backButton: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '40px',
    height: '40px',
    transform: 'rotate(180deg)',
    padding: '10px',
    margin: '20px',
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '50%',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: COLORS.lightGray,
    },
  },
});

type DebtIssuanceFormProps = {
  customV2SetPathPrefix: string;
  isDebtIssuanceReady: boolean;
  isExceedingAumCap: boolean;
  userHasSufficientFunds: boolean;
  setDetails: ISetDetails;
  setAddress: string;
  issuanceQuantity: string;
  inputComponents: IDebtComponentWithToken[];
  outputComponents: IDebtComponentWithToken[];
  erc20BalancesByAddress: ITokenBalances;
  currentSetAumCap: BigNumber;
  issuanceQuantityAboveAumCap: string;
  match: IMatch;
  history: IHistory;
  debtIssuanceFees: IFeeResponse;
  isFetchingDebtIssuanceFees: boolean;
  containsDebtPosition: boolean;
  fetchFeesForIssueQuantity: (...args: any[]) => any;
  updateIssuanceQuantity: (...args: any[]) => any;
  setMaxIssuableQuantity: (...args: any[]) => any;
  debtIssueCurrentSet: (...args: any[]) => any;
};

/**
 * @title DebtIssuanceForm
 * @author Set Protocol
 *
 * DebtIssuanceForm is the interface users use to input a desired issuance quantity,
 * The required underlying component quantities, and to initiate the issuance transaction.
 */
class DebtIssuance extends PureComponent<DebtIssuanceFormProps & WithTranslation> {
  async componentDidMount() {
    const { fetchFeesForIssueQuantity } = this.props;

    await fetchFeesForIssueQuantity();
  }

  public onUpdateIssuanceQuantity = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { updateIssuanceQuantity, fetchFeesForIssueQuantity } = this.props;
    const value = e.target?.value;

    await updateIssuanceQuantity(value);
    await fetchFeesForIssueQuantity();
  };

  public renderInputComponents = () => {
    const { inputComponents, erc20BalancesByAddress, issuanceQuantity, t } = this.props;

    if (!inputComponents) return null;

    const requiredComponentsList = inputComponents?.map((component: IDebtComponentWithToken) => {
      const { address } = component;
      const checkSumAddress = Web3.utils.toChecksumAddress(address);
      const userBalance = erc20BalancesByAddress[checkSumAddress]?.toString();
      const formattedEquityValue = tokenFromBaseUnits(
        component?.equityValue?.toString() || '0',
        component?.decimals ?? 18,
      );
      const requiredQuantity = formattedEquityValue.mul(issuanceQuantity || 0);
      const derivedTokenColor = generateColorFromString(address || '');

      return (
        <div className={css(styles.DebtIssuanceForm_component)} key={component.symbol}>
          <div className={css(styles.DebtIssuanceForm_componentLabelContainer)}>
            <img
              className={css(styles.DebtIssuanceForm_componentIcon)}
              src={getListTokenImage(component)}
              style={{ backgroundColor: `#${derivedTokenColor}` }}
            />
            <span className={css(styles.DebtIssuanceForm_componentSymbol)}>
              {component?.symbol}
            </span>
          </div>
          <span className={css(styles.DebtIssuanceForm_componentQuantityRequired)}>
            {requiredQuantity.toFixed(8, BigNumber.ROUND_DOWN)}
          </span>
          <span className={css(styles.DebtIssuanceForm_componentUserBalance)}>
            {formatTokenUnit(userBalance, component?.decimals, 8)}
          </span>
        </div>
      );
    });

    return (
      <div className={css(styles.DebtIssuanceForm_componentContainer)}>
        <div className={css(styles.DebtIssuanceForm_tableHeader)}>
          <span className={css(styles.DebtIssuanceForm_tokenLabel)}>{t('table.token')}</span>
          <span className={css(styles.DebtIssuanceForm_requiredAmountLabel)}>
            {t('table.amount-required')}
          </span>
          <span className={css(styles.DebtIssuanceForm_userBalanceLabel)}>
            {t('table.your-balance')}
          </span>
        </div>
        {requiredComponentsList}
      </div>
    );
  };

  public renderOutputComponents = () => {
    const {
      setDetails,
      setAddress,
      outputComponents,
      issuanceQuantity,
      isFetchingDebtIssuanceFees,
      debtIssuanceFees,
      t,
    } = this.props;

    if (!outputComponents) return null;

    const formattedIssuanceQuantity = utils.parseEther(
      issuanceQuantity?.length ? issuanceQuantity : '0',
    );

    const requiredComponentsList = outputComponents?.map((component: IDebtComponentWithToken) => {
      const { address } = component;
      const formattedDebtValue = tokenFromBaseUnits(
        component?.debtValue?.toString() || '0',
        component?.decimals ?? 18,
      );
      const returnedQuantity = formattedDebtValue.mul(issuanceQuantity || 0);
      const derivedTokenColor = generateColorFromString(address || '');

      return (
        <div className={css(styles.DebtIssuanceForm_component)} key={component.symbol}>
          <div className={css(styles.DebtIssuanceForm_componentLabelContainer)}>
            <img
              className={css(styles.DebtIssuanceForm_componentIcon)}
              src={getListTokenImage(component)}
              style={{ backgroundColor: `#${derivedTokenColor}` }}
            />
            <span className={css(styles.DebtIssuanceForm_componentSymbol)}>
              {component?.symbol}
            </span>
          </div>
          <span className={css(styles.DebtIssuanceForm_componentQuantityRequired)}>
            {returnedQuantity.toFixed(8, BigNumber.ROUND_DOWN)}
          </span>
        </div>
      );
    });

    const derivedSetColor = generateColorFromString(setAddress || '');
    const currentSetToken = (
      <div className={css(styles.DebtIssuanceForm_component)} key={setDetails?.symbol}>
        <div className={css(styles.DebtIssuanceForm_componentLabelContainer)}>
          <img
            className={css(styles.DebtIssuanceForm_componentIcon)}
            style={{ backgroundColor: `#${derivedSetColor}` }}
            src={'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-set-token-icon.svg'}
          />
          <span className={css(styles.DebtIssuanceForm_componentSymbol)}>{setDetails?.symbol}</span>
        </div>
        <span className={css(styles.DebtIssuanceForm_componentQuantityRequired)}>
          {issuanceQuantity}
        </span>
      </div>
    );

    let issuanceFee = '0';
    if (!isFetchingDebtIssuanceFees && debtIssuanceFees?.managerFee) {
      issuanceFee = tokenFromBaseUnits(debtIssuanceFees?.managerFee.toString()).toFixed(
        2,
        BigNumber.ROUND_UP,
      );
    }

    return (
      <div className={css(styles.DebtIssuanceForm_componentContainer)}>
        {formattedIssuanceQuantity.gt(0) && (
          <p className={css(styles.DebtIssuanceForm_debtIssuanceDescription)}>
            {t('debt-issue-form.fees', {
              setName: setDetails?.name,
              feePercentage: `${issuanceFee}%`,
            })}{' '}
            {t('debt-issue-form.returned-tokens')}
          </p>
        )}
        <div className={css(styles.DebtIssuanceForm_tableHeader)}>
          <span className={css(styles.DebtIssuanceForm_tokenLabel)}>{t('table.token')}</span>
          <span className={css(styles.DebtIssuanceForm_requiredAmountLabel)}>
            {t('table.amount-returned')}
          </span>
        </div>
        {currentSetToken}
        {requiredComponentsList}
      </div>
    );
  };

  renderErrorMessage = () => {
    const {
      isExceedingAumCap,
      userHasSufficientFunds,
      setDetails,
      currentSetAumCap,
      issuanceQuantityAboveAumCap,
      t,
    } = this.props;

    if (isExceedingAumCap) {
      const totalSupplyCap = tokenFromBaseUnits(currentSetAumCap?.toString());
      return (
        <div className={css(styles.DebtIssuanceForm_errorContainer)}>
          <span className={css(styles.DebtIssuanceForm_errorText)}>
            {t('issue-form.error-aum-cap', {
              totalSupplyCap,
              issuanceQuantityAboveAumCap,
            })}
          </span>
        </div>
      );
    }

    if (!userHasSufficientFunds) {
      return (
        <div className={css(styles.DebtIssuanceForm_errorContainer)}>
          <span className={css(styles.DebtIssuanceForm_errorText)}>
            {t('issue-form.error-title')}{' '}
            {t('issue-form.error-description', { setName: setDetails?.name })}
          </span>
        </div>
      );
    }

    return null;
  };

  render() {
    const {
      setAddress,
      customV2SetPathPrefix,
      setDetails,
      issuanceQuantity,
      isDebtIssuanceReady,
      history,
      containsDebtPosition,
      debtIssueCurrentSet,
      setMaxIssuableQuantity,
      t,
    } = this.props;

    return (
      <div className={css(styles.DebtIssuanceForm_container)}>
        <img
          src={darkArrow}
          className={css(styles.DebtIssuanceForm_backButton)}
          onClick={() => history.push(`${customV2SetPathPrefix}/${setAddress}`)}
        />
        <img
          className={css(styles.DebtIssuanceForm_setImage)}
          src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/issue.svg"
        />
        <p className={css(styles.DebtIssuanceForm_title)}>
          {containsDebtPosition ? 'Debt Issue' : 'Issue'} {setDetails?.name}
        </p>
        <p className={css(styles.DebtIssuanceForm_subTitle)}>
          {t(containsDebtPosition ? 'debt-issue-form.title' : 'debt-issue-form.no-debt-title', {
            tokenSymbol: setDetails?.symbol,
          })}
        </p>

        <div className={css(styles.DebtIssuanceForm_issueInputContainer)}>
          <input
            className={css(styles.DebtIssuanceForm_issueQuantityInput)}
            type="number"
            value={issuanceQuantity}
            onChange={this.onUpdateIssuanceQuantity}
          />
          <div className={css(styles.DebtIssuanceForm_issueInputLabelContainer)}>
            <span className={css(styles.DebtIssuanceForm_issueInputSymbol)}>
              {setDetails?.symbol}
            </span>
            <button
              className={css(styles.DebtIssuanceForm_issueMaxButton)}
              type="button"
              onClick={setMaxIssuableQuantity}
            >
              MAX
            </button>
          </div>
        </div>

        {this.renderInputComponents()}
        {this.renderOutputComponents()}

        <button
          className={css(
            styles.DebtIssuanceForm_submitButton,
            !isDebtIssuanceReady && styles.DebtIssuanceForm_submitButtonDisabled,
          )}
          disabled={!isDebtIssuanceReady}
          type="button"
          onClick={debtIssueCurrentSet}
        >
          {t('buttons.issue')}
        </button>

        {this.renderErrorMessage()}
      </div>
    );
  }
}
export default withTranslation('issue-and-redeem')(DebtIssuance);
