import { css, StyleSheet } from 'aphrodite';
import React, { PureComponent } from 'react';
import FontAwesome from 'react-fontawesome';

import { Text } from '../../components/index';
import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';

const { TABLET_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  PriceChangeBadge_setPercentChange: {
    borderRadius: '5px',
    display: 'flex',
    padding: '6px 10px 4px',
    marginLeft: '10px',
    justifyContent: 'center',
    textAlign: 'center',
    [TABLET_MEDIA_QUERY]: {
      display: 'inline-block',
      marginLeft: '0',
    },
  },
  PriceChangeBadge_marginRight: {
    marginRight: '10px',
  },
  PriceChangeBadge_priceChangeArrow: {
    color: COLORS.white,
    marginRight: '5px',
    fontSize: '12px',
  },
  PriceChangeBadge_badgeText: {
    fontSize: '13px',
  },
});

interface IPriceChangeBadgeProps {
  isTablet: boolean;
  shouldShowDefaultValue: boolean;
  defaultPercentChange: string;
  percentChange: string;
  customStyle?: any;
  precision?: number;
}

class PriceChangeBadge extends PureComponent<IPriceChangeBadgeProps> {
  public static defaultProps: Partial<IPriceChangeBadgeProps> = {
    shouldShowDefaultValue: false,
    customStyle: undefined,
    precision: 2,
  };

  roundValue = (percentChange: string, precision: number) => {
    if (percentChange) {
      const percentValue = Number(percentChange.replace(/[\+\-\%]/g, ''));

      if (percentValue === 0) {
        return '0';
      }

      return percentValue.toFixed(precision);
    }
    return '0';
  };

  render() {
    const {
      defaultPercentChange,
      percentChange,
      precision,
      isTablet,
      customStyle,
      shouldShowDefaultValue,
    } = this.props;

    const isInvalid = percentChange && percentChange === '-';
    const isPriceUp =
      (percentChange && percentChange[0] !== '-') ||
      (isInvalid && defaultPercentChange && defaultPercentChange[0] !== '-');

    let priceChangeValue = isInvalid ? defaultPercentChange : percentChange;
    let backgroundColor = COLORS.gray;

    // If percent change is valid, or if it's an invalid percent change that should show the default value
    if (!isInvalid || (isInvalid && shouldShowDefaultValue)) {
      priceChangeValue = `${isPriceUp ? '+' : '-'}${this.roundValue(priceChangeValue, precision)}%`;
      backgroundColor = isPriceUp ? COLORS.green : COLORS.red;
    } else {
      priceChangeValue = '—';
    }

    return (
      <div
        className={css(
          styles.PriceChangeBadge_setPercentChange,
          !isTablet && styles.PriceChangeBadge_marginRight,
          customStyle,
        )}
        style={{ backgroundColor }}
      >
        <Text color="white" type="small" className={css(styles.PriceChangeBadge_badgeText)}>
          {!isTablet && !isInvalid && (
            <FontAwesome
              name={isPriceUp ? 'arrow-up' : 'arrow-down'}
              className={css(styles.PriceChangeBadge_priceChangeArrow)}
            />
          )}
          {priceChangeValue}
        </Text>
      </div>
    );
  }
}

export default PriceChangeBadge;
