import { connect } from 'react-redux';
import RedemptionFormWrapper from './RedemptionFormWrapper';
import { customV2SetPathPrefixSelector, transactionIdSelector } from '../../selectors/index';
import { resetTransactionWatcher } from '../../actions/transactionWatcherActions';
import { currentSetDetailsSelector } from '../../selectors/setDetailsSelectors';

const mapStateToProps = (state: any) => {
  const currentTransactionId = transactionIdSelector(state);
  const { name, symbol } = currentSetDetailsSelector(state) || {};
  const customV2SetPathPrefix = customV2SetPathPrefixSelector(state);

  return {
    customV2SetPathPrefix,
    name,
    symbol,
    currentTransactionId,
  };
};

const mapDispatchToProps = {
  resetTransactionWatcher,
};

export default connect(mapStateToProps, mapDispatchToProps)(RedemptionFormWrapper);
