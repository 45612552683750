const streamingFeeSplitExtensionABI = [
  {
    inputs: [
      { internalType: 'contract IManagerCore', name: '_managerCore', type: 'address' },
      {
        internalType: 'contract IStreamingFeeModule',
        name: '_streamingFeeModule',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: '_setToken', type: 'address' },
      { indexed: true, internalType: 'address', name: '_delegatedManager', type: 'address' },
    ],
    name: 'ExtensionRemoved',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: '_setToken', type: 'address' },
      { indexed: true, internalType: 'address', name: '_ownerFeeRecipient', type: 'address' },
      { indexed: true, internalType: 'address', name: '_methodologist', type: 'address' },
      { indexed: false, internalType: 'uint256', name: '_ownerTake', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: '_methodologistTake', type: 'uint256' },
    ],
    name: 'FeesDistributed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: '_setToken', type: 'address' },
      { indexed: true, internalType: 'address', name: '_delegatedManager', type: 'address' },
    ],
    name: 'StreamingFeeSplitExtensionInitialized',
    type: 'event',
  },
  {
    inputs: [{ internalType: 'contract ISetToken', name: '_setToken', type: 'address' }],
    name: 'accrueFeesAndDistribute',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IDelegatedManager', name: '_delegatedManager', type: 'address' },
    ],
    name: 'initializeExtension',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IDelegatedManager', name: '_delegatedManager', type: 'address' },
      {
        components: [
          { internalType: 'address', name: 'feeRecipient', type: 'address' },
          { internalType: 'uint256', name: 'maxStreamingFeePercentage', type: 'uint256' },
          { internalType: 'uint256', name: 'streamingFeePercentage', type: 'uint256' },
          { internalType: 'uint256', name: 'lastStreamingFeeTimestamp', type: 'uint256' },
        ],
        internalType: 'struct IStreamingFeeModule.FeeState',
        name: '_settings',
        type: 'tuple',
      },
    ],
    name: 'initializeModule',
    // @ts-ignore
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IDelegatedManager', name: '_delegatedManager', type: 'address' },
      {
        components: [
          { internalType: 'address', name: 'feeRecipient', type: 'address' },
          { internalType: 'uint256', name: 'maxStreamingFeePercentage', type: 'uint256' },
          { internalType: 'uint256', name: 'streamingFeePercentage', type: 'uint256' },
          { internalType: 'uint256', name: 'lastStreamingFeeTimestamp', type: 'uint256' },
        ],
        internalType: 'struct IStreamingFeeModule.FeeState',
        name: '_settings',
        type: 'tuple',
      },
    ],
    name: 'initializeModuleAndExtension',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'managerCore',
    outputs: [{ internalType: 'contract IManagerCore', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'removeExtension',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'contract ISetToken', name: '', type: 'address' }],
    name: 'setManagers',
    outputs: [{ internalType: 'contract IDelegatedManager', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'streamingFeeModule',
    outputs: [{ internalType: 'contract IStreamingFeeModule', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract ISetToken', name: '_setToken', type: 'address' },
      { internalType: 'address', name: '_newFeeRecipient', type: 'address' },
    ],
    name: 'updateFeeRecipient',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract ISetToken', name: '_setToken', type: 'address' },
      { internalType: 'uint256', name: '_newFee', type: 'uint256' },
    ],
    name: 'updateStreamingFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

export default streamingFeeSplitExtensionABI;
