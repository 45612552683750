import { css, StyleSheet } from 'aphrodite';
import React, { PureComponent } from 'react';
import { Container } from 'semantic-ui-react';

import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';
import { CustomSetDetailsResponse, IMatch } from '../../typings/index';
import CustomSetDescription from './CustomSetDescription';
import CustomSetManagerProfile from './CustomSetManagerProfile';
import { BigNumber } from '../../utils/index';

const { TABLET_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  CustomSetMetadata_container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '40px',
    marginTop: '40px',
    marginLeft: '0px',
    width: '70%',
    [TABLET_MEDIA_QUERY]: {
      marginBototm: '20px',
      marginTop: '40px',
      width: '100%',
    },
  },
  CustomSetMetadata_activityFeed: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '40px',
  },
  CustomSetMetadata_activityFeedTitleText: {
    fontWeight: 500,
    color: COLORS.darkBlue,
    fontSize: '24px',
    marginBottom: '10px',
    paddingBottom: '20px',
    borderBottom: `1px solid ${COLORS.gray}`,
  },
});

interface ICustomSetMetadataProps {
  isLoading: boolean;
  match: IMatch;
  setAddress: string;
  customSetDetails: CustomSetDetailsResponse;
  setSymbol: string;
  setBalance: BigNumber;
  account: string;
  streamingFee: string;
  issuanceFee: string;
  redemptionFee: string;
  totalSupply: string;
  unaccruedFees: string;
  managerAddress: string;
  currentChain: string;
}

/**
 * @title CustomSetMetadata
 * @author Set Labs
 *
 * CustomSetMetadata displays set information below the price chart.
 * It displays the icon, name, description, desktop buy, and sell buttons and trader profile and more
 */
class CustomSetMetadata extends PureComponent<ICustomSetMetadataProps> {
  public render() {
    const {
      isLoading,
      match,
      setAddress,
      setSymbol,
      setBalance,
      account,
      managerAddress,
      issuanceFee,
      redemptionFee,
      streamingFee,
      totalSupply,
      unaccruedFees,
      customSetDetails,
      currentChain,
    } = this.props;

    if (isLoading || !setAddress) return null;

    return (
      <Container>
        <div className={css(styles.CustomSetMetadata_container)}>
          <CustomSetDescription
            currentChain={currentChain}
            streamingFee={streamingFee}
            issuanceFee={issuanceFee}
            redemptionFee={redemptionFee}
            unaccruedFees={unaccruedFees}
            setAddress={setAddress}
            setSymbol={setSymbol}
            setBalance={setBalance}
            totalSupply={totalSupply}
            account={account}
            managerAddress={managerAddress}
            match={match}
            customSetDetails={customSetDetails}
          />
          {customSetDetails?.setDetails && (
            <CustomSetManagerProfile customSetDetails={customSetDetails} />
          )}
        </div>
      </Container>
    );
  }
}

export default CustomSetMetadata;
