import {
  REQUEST_APPROVAL,
  COMPLETE_APPROVAL,
  FAIL_APPROVAL,
  REQUEST_UNISWAP_APPROVAL,
  COMPLETE_UNISWAP_APPROVAL,
  FAIL_UNISWAP_APPROVAL,
  REQUEST_SUSHISWAP_APPROVAL,
  COMPLETE_SUSHISWAP_APPROVAL,
  FAIL_SUSHISWAP_APPROVAL,
  REQUEST_ZERO_EX_APPROVAL,
  COMPLETE_ZERO_EX_APPROVAL,
  FAIL_ZERO_EX_APPROVAL,
  REQUEST_DEBT_ISSUANCE_APPROVAL,
  COMPLETE_DEBT_ISSUANCE_APPROVAL,
  FAIL_DEBT_ISSUANCE_APPROVAL,
  REQUEST_PERP_ISSUANCE_APPROVAL,
  COMPLETE_PERP_ISSUANCE_APPROVAL,
  FAIL_PERP_ISSUANCE_APPROVAL,
  REQUEST_EXCHANGE_ISSUANCE_PAYMENT_APPROVAL,
  COMPLETE_EXCHANGE_ISSUANCE_PAYMENT_APPROVAL,
  FAIL_EXCHANGE_ISSUANCE_PAYMENT_APPROVAL,
  REQUEST_EXCHANGE_ISSUANCE_UNDERLYING_APPROVAL,
  COMPLETE_EXCHANGE_ISSUANCE_UNDERLYING_APPROVAL,
  FAIL_EXCHANGE_ISSUANCE_UNDERLYING_APPROVAL,
} from '../actions/approvalActions';
import { TokenApprovalStatus } from '../typings/index';

const initialState: { [tokenId: string]: TokenApprovalStatus } = {};

/** Tracks transfer proxy approvals for _SetJS controller_ contract */
const approvalReducer = (state: any = initialState, action: any) => {
  const existingTokenApproval = state[action.payload];

  switch (action.type) {
    case REQUEST_APPROVAL:
      return {
        ...state,
        [action.payload]: {
          ...existingTokenApproval,
          isPending: true,
          isApproved: false,
        },
      };

    case COMPLETE_APPROVAL:
      return {
        ...state,
        [action.payload]: {
          ...existingTokenApproval,
          isPending: false,
          isApproved: true,
        },
      };

    case FAIL_APPROVAL:
      return {
        ...state,
        [action.payload]: {
          ...existingTokenApproval,
          isPending: false,
          isApproved: false,
        },
      };

    case REQUEST_UNISWAP_APPROVAL:
      return {
        ...state,
        [action.payload]: {
          ...existingTokenApproval,
          isUniswapPending: true,
          isUniswapApproved: false,
        },
      };

    case COMPLETE_UNISWAP_APPROVAL:
      return {
        ...state,
        [action.payload]: {
          ...existingTokenApproval,
          isUniswapPending: false,
          isUniswapApproved: true,
        },
      };

    case FAIL_UNISWAP_APPROVAL:
      return {
        ...state,
        [action.payload]: {
          ...existingTokenApproval,
          isUniswapPending: false,
          isUniswapApproved: false,
        },
      };

    case REQUEST_ZERO_EX_APPROVAL:
      return {
        ...state,
        [action.payload]: {
          ...existingTokenApproval,
          isZeroExPending: true,
          isZeroExApproved: false,
        },
      };

    case COMPLETE_ZERO_EX_APPROVAL:
      return {
        ...state,
        [action.payload]: {
          ...existingTokenApproval,
          isZeroExPending: false,
          isZeroExApproved: true,
        },
      };

    case FAIL_ZERO_EX_APPROVAL:
      return {
        ...state,
        [action.payload]: {
          ...existingTokenApproval,
          isZeroExPending: false,
          isZeroExApproved: false,
        },
      };

    case REQUEST_SUSHISWAP_APPROVAL:
      return {
        ...state,
        [action.payload]: {
          ...existingTokenApproval,
          isSushiswapPending: true,
          isSushiswapApproved: false,
        },
      };

    case COMPLETE_SUSHISWAP_APPROVAL:
      return {
        ...state,
        [action.payload]: {
          ...existingTokenApproval,
          isSushiswapPending: false,
          isSushiswapApproved: true,
        },
      };

    case FAIL_SUSHISWAP_APPROVAL:
      return {
        ...state,
        [action.payload]: {
          ...existingTokenApproval,
          isSushiswapPending: false,
          isSushiswapApproved: false,
        },
      };

    case REQUEST_DEBT_ISSUANCE_APPROVAL:
      return {
        ...state,
        [action.payload]: {
          ...existingTokenApproval,
          isDebtIssuancePending: true,
          isDebtIssuanceApproved: false,
        },
      };

    case COMPLETE_DEBT_ISSUANCE_APPROVAL:
      return {
        ...state,
        [action.payload]: {
          ...existingTokenApproval,
          isDebtIssuancePending: false,
          isDebtIssuanceApproved: true,
        },
      };

    case FAIL_DEBT_ISSUANCE_APPROVAL:
      return {
        ...state,
        [action.payload]: {
          ...existingTokenApproval,
          isDebtIssuancePending: false,
          isDebtIssuanceApproved: false,
        },
      };

    case REQUEST_PERP_ISSUANCE_APPROVAL:
      return {
        ...state,
        [action.payload]: {
          ...existingTokenApproval,
          isPerpIssuancePending: true,
          isPerpIssuanceApproved: false,
        },
      };

    case COMPLETE_PERP_ISSUANCE_APPROVAL:
      return {
        ...state,
        [action.payload]: {
          ...existingTokenApproval,
          isPerpIssuancePending: false,
          isPerpIssuanceApproved: true,
        },
      };

    case FAIL_PERP_ISSUANCE_APPROVAL:
      return {
        ...state,
        [action.payload]: {
          ...existingTokenApproval,
          isPerpIssuancePending: false,
          isPerpIssuanceApproved: false,
        },
      };

    case REQUEST_EXCHANGE_ISSUANCE_PAYMENT_APPROVAL:
      return {
        ...state,
        [action.payload]: {
          ...existingTokenApproval,
          isExchangeIssuancePaymentTokenPending: true,
          isExchangeIssuancePaymentTokenApproved: false,
        },
      };

    case COMPLETE_EXCHANGE_ISSUANCE_PAYMENT_APPROVAL:
      return {
        ...state,
        [action.payload]: {
          ...existingTokenApproval,
          isExchangeIssuancePaymentTokenPending: false,
          isExchangeIssuancePaymentTokenApproved: true,
        },
      };

    case FAIL_EXCHANGE_ISSUANCE_PAYMENT_APPROVAL:
      return {
        ...state,
        [action.payload]: {
          ...existingTokenApproval,
          isExchangeIssuancePaymentTokenPending: false,
          isExchangeIssuancePaymentTokenApproved: false,
        },
      };

    case REQUEST_EXCHANGE_ISSUANCE_UNDERLYING_APPROVAL:
      return {
        ...state,
        [action.payload]: {
          ...existingTokenApproval,
          isExchangeIssuanceUnderlyingTokenPending: true,
          isExchangeIssuanceUnderlyingTokenApproved: false,
        },
      };

    case COMPLETE_EXCHANGE_ISSUANCE_UNDERLYING_APPROVAL:
      return {
        ...state,
        [action.payload]: {
          ...existingTokenApproval,
          isExchangeIssuanceUnderlyingTokenPending: false,
          isExchangeIssuanceUnderlyingTokenApproved: true,
        },
      };

    case FAIL_EXCHANGE_ISSUANCE_UNDERLYING_APPROVAL:
      return {
        ...state,
        [action.payload]: {
          ...existingTokenApproval,
          isExchangeIssuanceUnderlyingTokenPending: false,
          isExchangeIssuanceUnderlyingTokenApproved: false,
        },
      };

    default:
      return state;
  }
};

export default approvalReducer;
