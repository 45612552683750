import { gql } from '@apollo/client';
import { ETHEREUM_ADDRESSES, MAX_SUBGRAPH_SKIP_AMOUNT } from '../constants/index';
import { tableSelector } from '../containers/Pagination/selectors';
import { TableDataSource, tableDisplayQuantityByDataSource } from '../reducers/tableReducers';
import { apolloClientSelector } from '../selectors/apolloSelectors';
import { omittedAddressesListSelector } from '../selectors/tokenListsSelectors';
import { GraphSetTokenCount, GraphSetToken } from '../typings/index';
import { payloadActionGenerator, emptyActionGenerator } from '../utils/reduxHelpers';
import _ from 'lodash';
import { CORE_SET_TOKEN_FIELDS } from '../queries';

/*  Default Pagination */

export const INCREMENT_PAGE_NUMBER = 'INCREMENT_PAGE_NUMBER';
export const DECREMENT_PAGE_NUMBER = 'DECREMENT_PAGE_NUMBER';
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const RESET_PAGINATION = 'RESET_PAGINATION';
export const SET_SORT_COLUMN = 'SET_SORT_COLUMN';

export const incrementPageNumber = emptyActionGenerator(INCREMENT_PAGE_NUMBER);
export const decrementPageNumber = emptyActionGenerator(DECREMENT_PAGE_NUMBER);
export const setPageNumber = payloadActionGenerator(SET_PAGE_NUMBER);
export const resetPagination = payloadActionGenerator(RESET_PAGINATION);
export const setSortColumn = payloadActionGenerator(SET_SORT_COLUMN);

/*  Portfolios Pagination */

export const PORTFOLIOS_INCREMENT_PAGE_NUMBER = 'PORTFOLIOS_INCREMENT_PAGE_NUMBER';
export const PORTFOLIOS_DECREMENT_PAGE_NUMBER = 'PORTFOLIOS_DECREMENT_PAGE_NUMBER';
export const PORTFOLIOS_SET_PAGE_NUMBER = 'PORTFOLIOS_SET_PAGE_NUMBER';
export const PORTFOLIOS_RESET_PAGINATION = 'PORTFOLIOS_RESET_PAGINATION';
export const PORTFOLIOS_SET_SORT_COLUMN = 'PORTFOLIOS_SET_SORT_COLUMN';

export const portfoliosIncrementPageNumber = emptyActionGenerator(PORTFOLIOS_INCREMENT_PAGE_NUMBER);
export const portfoliosDecrementPageNumber = emptyActionGenerator(PORTFOLIOS_DECREMENT_PAGE_NUMBER);
export const portfoliosSetPageNumber = payloadActionGenerator(PORTFOLIOS_SET_PAGE_NUMBER);
export const portfoliosResetPagination = payloadActionGenerator(PORTFOLIOS_RESET_PAGINATION);
export const portfoliosSortColumn = payloadActionGenerator(PORTFOLIOS_SET_SORT_COLUMN);

/*  Custom Sets Pagination */

export const CUSTOM_SETS_INCREMENT_PAGE_NUMBER = 'CUSTOM_SETS_INCREMENT_PAGE_NUMBER';
export const CUSTOM_SETS_DECREMENT_PAGE_NUMBER = 'CUSTOM_SETS_DECREMENT_PAGE_NUMBER';
export const CUSTOM_SETS_SET_PAGE_NUMBER = 'CUSTOM_SETS_SET_PAGE_NUMBER';
export const CUSTOM_SETS_RESET_PAGINATION = 'CUSTOM_SETS_RESET_PAGINATION';
export const CUSTOM_SETS_SET_SORT_COLUMN = 'CUSTOM_SETS_SET_SORT_COLUMN';

export const REQUEST_NEXT_CUSTOM_SETS_PAGE = 'REQUEST_NEXT_CUSTOM_SETS_PAGE';
export const RECEIVE_NEXT_CUSTOM_SETS_PAGE = 'RECEIVE_NEXT_CUSTOM_SETS_PAGE';

export const customSetsIncrementPageNumber = emptyActionGenerator(
  CUSTOM_SETS_INCREMENT_PAGE_NUMBER,
);
export const customSetsDecrementPageNumber = emptyActionGenerator(
  CUSTOM_SETS_DECREMENT_PAGE_NUMBER,
);
export const customSetsSetPageNumber = payloadActionGenerator(CUSTOM_SETS_SET_PAGE_NUMBER);
export const customSetsResetPagination = payloadActionGenerator(CUSTOM_SETS_RESET_PAGINATION);
export const customSetsSortColumn = payloadActionGenerator(CUSTOM_SETS_SET_SORT_COLUMN);

export const requestNextCustomSetsPage = emptyActionGenerator(REQUEST_NEXT_CUSTOM_SETS_PAGE);
export const receiveNextCustomSetsPage = payloadActionGenerator(RECEIVE_NEXT_CUSTOM_SETS_PAGE);

/*  Social Set Pagination */

export const SOCIAL_SETS_INCREMENT_PAGE_NUMBER = 'SOCIAL_SETS_INCREMENT_PAGE_NUMBER';
export const SOCIAL_SETS_DECREMENT_PAGE_NUMBER = 'SOCIAL_SETS_DECREMENT_PAGE_NUMBER';
export const SOCIAL_SETS_SET_PAGE_NUMBER = 'SOCIAL_SETS_SET_PAGE_NUMBER';
export const SOCIAL_SETS_RESET_PAGINATION = 'SOCIAL_SETS_RESET_PAGINATION';
export const SOCIAL_SETS_SET_SORT_COLUMN = 'SOCIAL_SETS_SET_SORT_COLUMN';

export const socialSetsIncrementPageNumber = emptyActionGenerator(
  SOCIAL_SETS_INCREMENT_PAGE_NUMBER,
);
export const socialSetsDecrementPageNumber = emptyActionGenerator(
  SOCIAL_SETS_DECREMENT_PAGE_NUMBER,
);
export const socialSetsSetPageNumber = payloadActionGenerator(SOCIAL_SETS_SET_PAGE_NUMBER);
export const socialSetsResetPagination = payloadActionGenerator(SOCIAL_SETS_RESET_PAGINATION);
export const socialSetsSetSortColumn = payloadActionGenerator(SOCIAL_SETS_SET_SORT_COLUMN);

/* Explore Page Robo Sets Pagination */

export const ROBO_SETS_INCREMENT_PAGE_NUMBER = 'ROBO_SETS_INCREMENT_PAGE_NUMBER';
export const ROBO_SETS_DECREMENT_PAGE_NUMBER = 'ROBO_SETS_DECREMENT_PAGE_NUMBER';
export const ROBO_SETS_SET_PAGE_NUMBER = 'ROBO_SETS_SET_PAGE_NUMBER';
export const ROBO_SETS_RESET_PAGINATION = 'ROBO_SETS_RESET_PAGINATION';
export const ROBO_SETS_SET_SORT_COLUMN = 'ROBO_SETS_SET_SORT_COLUMN';

export const roboSetsIncrementPageNumber = emptyActionGenerator(ROBO_SETS_INCREMENT_PAGE_NUMBER);
export const roboSetsDecrementPageNumber = emptyActionGenerator(ROBO_SETS_DECREMENT_PAGE_NUMBER);
export const roboSetsSetPageNumber = payloadActionGenerator(ROBO_SETS_SET_PAGE_NUMBER);
export const roboSetsResetPagination = payloadActionGenerator(ROBO_SETS_RESET_PAGINATION);
export const roboSetsSetSortColumn = payloadActionGenerator(ROBO_SETS_SET_SORT_COLUMN);

/* Explore Page Trader List Pagination */

export const TRADER_LIST_INCREMENT_PAGE_NUMBER = 'TRADER_LIST_INCREMENT_PAGE_NUMBER';
export const TRADER_LIST_DECREMENT_PAGE_NUMBER = 'TRADER_LIST_DECREMENT_PAGE_NUMBER';
export const TRADER_LIST_SET_PAGE_NUMBER = 'TRADER_LIST_SET_PAGE_NUMBER';
export const TRADER_LIST_RESET_PAGINATION = 'TRADER_LIST_RESET_PAGINATION';
export const TRADER_LIST_SET_SORT_COLUMN = 'TRADER_LIST_SET_SORT_COLUMN';

export const traderListIncrementPageNumber = emptyActionGenerator(
  TRADER_LIST_INCREMENT_PAGE_NUMBER,
);
export const traderListDecrementPageNumber = emptyActionGenerator(
  TRADER_LIST_DECREMENT_PAGE_NUMBER,
);
export const traderListSetPageNumber = payloadActionGenerator(TRADER_LIST_SET_PAGE_NUMBER);
export const traderListResetPagination = payloadActionGenerator(TRADER_LIST_RESET_PAGINATION);
export const traderListSetSortColumn = payloadActionGenerator(TRADER_LIST_SET_SORT_COLUMN);

const PAGINATED_SETS_QUERY = gql`
  ${CORE_SET_TOKEN_FIELDS}
  query FetchSetsPaginated(
    $limit: Int!
    $skipAmount: Int!
    $omittedAddresses: [String!]
    $lastInception: Int!
  ) {
    setTokens(
      first: $limit
      skip: $skipAmount
      where: { id_not_in: $omittedAddresses, inception_gt: $lastInception }
      orderBy: inception
    ) {
      ...CoreSetTokenFields
    }
    setTokenCount(id: "1") {
      count
    }
  }
`;

export const setCustomSetsPageNumberWithQuery = (pageNumber: number) => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const apolloClient = apolloClientSelector(state);
  const omittedAddressesList = omittedAddressesListSelector(state);

  const omittedAddressesArray = Object.keys(omittedAddressesList);

  const limit = tableDisplayQuantityByDataSource[TableDataSource.customSets];
  let skipAmount = (pageNumber - 1) * limit;
  let lastInception = 0;

  dispatch(requestNextCustomSetsPage());

  // If the skipAmount is greater than the max, then we will loop through the graph API in batches of size
  // MAX_SUBGRAPH_SKIP_AMOUNT and keep track of the lastInception block value to continue to decrement the
  // skip amount using the lastInception as the new starting point.
  // If this starts to cause too many queries in the future we can try to see if we can do some sort of
  // reverse order and count from the back and just limit the pages they are allowed to click through.
  let batch = 1;
  while (skipAmount > MAX_SUBGRAPH_SKIP_AMOUNT) {
    // These omittedAddress lists currently cannot be empty otherwise the graph API
    // request will actually return an empty list
    const graphResponse: {
      data: { setTokens: GraphSetToken[]; setTokenCount: GraphSetTokenCount };
    } = await apolloClient.query({
      query: PAGINATED_SETS_QUERY,
      variables: {
        limit,
        skipAmount: MAX_SUBGRAPH_SKIP_AMOUNT - limit,
        omittedAddresses: _.isEmpty(omittedAddressesList)
          ? [ETHEREUM_ADDRESSES.NULL_ADDRESS]
          : Object.keys(omittedAddressesList).map(address => address.toLowerCase()),
        lastInception,
      },
    });

    const setTokens = graphResponse?.data?.setTokens || [];

    // No more set tokens (reached the end), so don't need to increment page
    if (!setTokens?.length) {
      return;
    }

    // Since set token addresses are unique, they are used as the "id" field in our
    // subgraph. Potentially remove this logic later.
    const setTokensWithAddressMapping: GraphSetToken[] = setTokens?.map((token: any) => ({
      ...token,
      address: token.id,
    }));

    dispatch(
      receiveNextCustomSetsPage({
        setTokens: setTokensWithAddressMapping || [],
        page: batch * (MAX_SUBGRAPH_SKIP_AMOUNT / limit),
        totalCount: graphResponse?.data?.setTokenCount?.count || 0,
        loading: true,
      }),
    );

    if (graphResponse?.data?.setTokens) {
      lastInception = Number(
        graphResponse?.data?.setTokens[graphResponse?.data?.setTokens.length - 1].inception,
      );
      skipAmount -= 5000;
      batch++;
    }
  }

  // These omittedAddress lists currently cannot be empty otherwise the graph API
  // request will actually return an empty list
  const graphResponse: {
    data: { setTokens: GraphSetToken[]; setTokenCount: GraphSetTokenCount };
  } = await apolloClient.query({
    query: PAGINATED_SETS_QUERY,
    variables: {
      limit,
      skipAmount,
      omittedAddresses: _.isEmpty(omittedAddressesList)
        ? [ETHEREUM_ADDRESSES.NULL_ADDRESS]
        : Object.keys(omittedAddressesList).map(address => address.toLowerCase()),
      lastInception,
    },
  });

  const setTokens = graphResponse?.data?.setTokens || [];

  // No more set tokens (reached the end), so don't need to increment page
  if (!setTokens?.length) {
    return;
  }

  // Since set token addresses are unique, they are used as the "id" field in our
  // subgraph. Potentially remove this logic later.
  const setTokensWithAddressMapping: GraphSetToken[] = setTokens?.map((token: any) => ({
    ...token,
    address: token.id,
  }));

  dispatch(
    receiveNextCustomSetsPage({
      setTokens: setTokensWithAddressMapping || [],
      page: pageNumber,
      totalCount: graphResponse?.data?.setTokenCount?.count
        ? graphResponse?.data?.setTokenCount?.count - omittedAddressesArray.length
        : 0,
    }),
  );
};

export const incrementCustomSetsPageNumberWithQuery = () => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const { currentPageNumber } = tableSelector(state, TableDataSource.customSets);

  dispatch(setCustomSetsPageNumberWithQuery(currentPageNumber + 1));
};

export const decrementCustomSetsPageNumberWithQuery = () => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const { currentPageNumber } = tableSelector(state, TableDataSource.customSets);

  // No page before page number 1
  if (currentPageNumber === 1) {
    return;
  }

  dispatch(setCustomSetsPageNumberWithQuery(currentPageNumber - 1));
};
