import { css, StyleSheet } from 'aphrodite';
import React, { PureComponent } from 'react';

import { COLORS, NETWORK_CONSTANTS, WINDOW_DIMENSIONS } from '../../constants/index';

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  LoginOptions_container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  LoginOptions_button: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 500,
    padding: '12px',
    marginBottom: '15px',
    width: '48%',
    [MOBILE_MEDIA_QUERY]: {
      width: '100%',
      height: '65px',
    },
  },
  LoginOptions_buttonSubText: {
    color: COLORS.white,
    fontWeight: 400,
    fontSize: '14px',
  },
  LoginOptions_loginOptionIcon: {
    marginRight: '10px',
    height: '20px',
    width: '20px',
  },
  LoginOptions_signIn: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  LoginOptions_signInText: {
    color: COLORS.darkBlue,
    marginTop: '50px',
    fontSize: '20px',
    marginBottom: '35px',
  },
  LoginOptions_polygonDescription: {
    marginTop: '35px',
    color: COLORS.darkGrayDarkened,
    marginBottom: '15px',
    fontSize: '17px',
  },
});

interface LoginOptionsProps {
  currentChain: string;
  isETHL1: boolean;
  isMobile: boolean;
  metaMaskEnabled: boolean;
  metaMaskMobileEnabled: boolean;
  operaWalletEnabled: boolean;
  loginWithPhone: (...args: any[]) => any;
  loginWithWalletLink: (...args: any[]) => any;
  loginWithMetamask: (...args: any[]) => any;
  loginWithOpera: (...args: any[]) => any;
  loginWithWalletConnect: (...args: any[]) => any;
}

class LoginOptions extends PureComponent<LoginOptionsProps> {
  render() {
    const {
      currentChain,
      isETHL1,
      isMobile,
      metaMaskEnabled,
      metaMaskMobileEnabled,
      operaWalletEnabled,
      loginWithPhone,
      loginWithWalletLink,
      loginWithMetamask,
      loginWithOpera,
    } = this.props;

    const phoneColumn = (
      <button
        className={`${css(styles.LoginOptions_button)} button-secondary`}
        onClick={loginWithPhone}
      >
        <img
          alt="login icon"
          className={css(styles.LoginOptions_loginOptionIcon)}
          src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/wallets/sms.svg"
        />
        Phone
      </button>
    );

    const coinbaseWalletColumn = (
      <button
        className={`${css(styles.LoginOptions_button)} button-secondary`}
        onClick={loginWithWalletLink}
      >
        <img
          alt="login icon"
          className={css(styles.LoginOptions_loginOptionIcon)}
          src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/wallets/coinbase-wallet.svg"
        />
        Coinbase Wallet
      </button>
    );

    const metamaskColumn = (metaMaskEnabled || metaMaskMobileEnabled) && (
      <button
        className={`${css(styles.LoginOptions_button)} button-secondary`}
        onClick={loginWithMetamask}
        style={{
          ...(!isETHL1 && {
            justifyContent: 'center',
            margin: 'auto',
          }),
        }}
      >
        <img
          alt="login icon"
          className={css(styles.LoginOptions_loginOptionIcon)}
          src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/wallets/metamask.svg"
        />
        Metamask
      </button>
    );

    const operaColumn = (
      <button
        className={`${css(styles.LoginOptions_button)} button-secondary`}
        onClick={loginWithOpera}
      >
        <img
          alt="login icon"
          className={css(styles.LoginOptions_loginOptionIcon)}
          src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/wallets/opera.png"
        />
        Opera
      </button>
    );

    let nonETHL1LoginDescription;
    if (currentChain !== NETWORK_CONSTANTS.ETHEREUM_CHAIN) {
      nonETHL1LoginDescription =
        'In order to create and buy sets, you’ll need to connect with your MetaMask wallet.';
    }

    if (isMobile) {
      return (
        <div className={css(styles.LoginOptions_container)}>
          {!isETHL1 ? (
            <div className={css(styles.LoginOptions_signIn)}>
              <div className={css(styles.LoginOptions_signInText)}>Sign in with</div>
              {metamaskColumn}
              {!!nonETHL1LoginDescription && (
                <div className={css(styles.LoginOptions_polygonDescription)}>
                  {nonETHL1LoginDescription}
                </div>
              )}
            </div>
          ) : (
            <>
              {phoneColumn}
              {coinbaseWalletColumn}
              {}
            </>
          )}
        </div>
      );
    }

    return (
      <div className={css(styles.LoginOptions_container)}>
        {!isETHL1 ? (
          <div className={css(styles.LoginOptions_signIn)}>
            <div className={css(styles.LoginOptions_signInText)}>Sign in with</div>
            {metamaskColumn}
            <div className={css(styles.LoginOptions_polygonDescription)}>
              {nonETHL1LoginDescription}
            </div>
          </div>
        ) : (
          <>
            {phoneColumn}
            {coinbaseWalletColumn}
            {operaWalletEnabled ? operaColumn : metamaskColumn}
          </>
        )}
      </div>
    );
  }
}

export default LoginOptions;
