import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { withTranslation, WithTranslation } from 'react-i18next';
import Web3 from 'web3';

import { ITokenBalances, IMatch, IHistory, ISetDetails, IFeeResponse } from '../../typings/index';
import { formatTokenUnit, BigNumber } from '../../utils/index';
import { IDebtComponentWithToken } from '../../typings/index';
import { generateColorFromString } from '../../utils/colorUtils';
import { getListTokenImage, tokenFromBaseUnits } from '../../utils/formatUtils';
import { COLORS } from '../../constants/index';
import darkArrow from '../../img/icons/right-arrow-dark.svg';
import { utils } from 'ethers';

const styles = StyleSheet.create({
  DebtRedemptionForm_container: {
    maxWidth: '400px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  DebtRedemptionForm_setImage: {
    width: '50px',
    height: '50px',
    marginBottom: '20px',
    borderRadius: '50%',
  },
  DebtRedemptionForm_title: {
    fontSize: '30px',
    fontWeight: 600,
    color: COLORS.darkBlue,
    lineHeight: 1.2,
    textAlign: 'center',
    marginBottom: '20px',
  },
  DebtRedemptionForm_subTitle: {
    fontSize: '14px',
    lineHeight: 1.5,
    textAlign: 'center',
    marginBottom: '20px',
  },
  DebtRedemptionForm_debtIssuanceDescription: {
    fontSize: '14px',
    lineHeight: 1.5,
    textAlign: 'left',
    marginBottom: '20px',
  },

  /* Input Field */
  DebtRedemptionForm_issueInputContainer: {
    width: '100%',
    position: 'relative',
    marginBottom: '20px',
  },
  DebtRedemptionForm_issueQuantityInput: {
    width: '100%',
    fontSize: '16px',
    padding: '15px',
    paddingRight: '120px',
    marginBottom: '20px',
    border: `2px solid ${COLORS.gray}`,
    borderRadius: '4px',
    ':focus': {
      borderColor: COLORS.blue,
    },
  },
  DebtRedemptionForm_issueInputLabelContainer: {
    position: 'absolute',
    top: 15,
    right: 16,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  DebtRedemptionForm_issueInputSymbol: {
    fontSize: '12px',
    color: COLORS.blue,
    fontWeight: 500,
    lineHeight: 1,
  },
  DebtRedemptionForm_issueMaxButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '22px',
    fontSize: '9px',
    marginLeft: '10px',
    padding: '11px 12px 10px 12px',
    ':active': {
      backgroundColor: COLORS.blueDarkened,
    },
  },

  /* DebtIssuance Components Table Header */
  DebtRedemptionForm_tableHeader: {
    display: 'flex',
    paddingBottom: '10px',
    borderBottom: `1px solid ${COLORS.gray}`,
  },
  DebtRedemptionForm_tokenLabel: {
    fontSize: '10px',
    lineHeight: 1.5,
    flex: 1,
  },
  DebtRedemptionForm_requiredAmountLabel: {
    fontSize: '10px',
    lineHeight: 1.5,
    flex: 1,
    textAlign: 'right',
  },
  DebtRedemptionForm_userBalanceLabel: {
    fontSize: '10px',
    lineHeight: 1.5,
    flex: 1,
    textAlign: 'right',
  },

  /* DebtIssuance Components */
  DebtRedemptionForm_componentContainer: {
    width: '100%',
    marginBottom: '30px',
  },
  DebtRedemptionForm_component: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
    borderBottom: `1px solid ${COLORS.lightGray}`,
  },
  DebtRedemptionForm_componentLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  DebtRedemptionForm_componentIcon: {
    height: '20px',
    width: '20px',
    borderRadius: '50%',
  },
  DebtRedemptionForm_componentSymbol: {
    fontSize: '14px',
    color: COLORS.darkBlue,
    fontWeight: 600,
    marginLeft: '10px',
  },

  DebtRedemptionForm_componentQuantityRequired: {
    fontSize: '14px',
    color: COLORS.darkBlue,
    flex: 1,
    textAlign: 'right',
  },
  DebtRedemptionForm_componentUserBalance: {
    fontSize: '14px',
    color: COLORS.green,
    flex: 1,
    textAlign: 'right',
  },

  /* Submit Button + Footer */
  DebtRedemptionForm_submitButton: {
    width: '100%',
    padding: '20px',
    fontWeight: 600,
    marginBottom: '30px',
    backgroundColor: COLORS.blue,
    color: COLORS.white,
    ':hover': {
      backgroundColor: COLORS.blueDarkened,
    },
  },
  DebtRedemptionForm_submitButtonDisabled: {
    pointerEvents: 'none',
    backgroundColor: COLORS.gray,
    ':hover': {
      backgroundColor: COLORS.gray,
    },
  },
  DebtRedemptionForm_errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px',
    backgroundColor: COLORS.redAlpha25,
    borderRadius: '4px',
  },
  DebtRedemptionForm_errorText: {
    textAlign: 'center',
    fontSize: '14px',
    color: COLORS.red,
  },

  DebtRedemptionForm_backButton: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '40px',
    height: '40px',
    transform: 'rotate(180deg)',
    padding: '10px',
    margin: '20px',
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '50%',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: COLORS.lightGray,
    },
  },
});

type DebtRedemptionFormProps = {
  setAddress: string;
  customV2SetPathPrefix: string;
  isDebtRedemptionReady: boolean;
  userHasSufficientFunds: boolean;
  setDetails: ISetDetails;
  inputComponents: IDebtComponentWithToken[];
  outputComponents: IDebtComponentWithToken[];
  redemptionQuantity: string;
  erc20BalancesByAddress: ITokenBalances;
  match: IMatch;
  history: IHistory;
  debtRedemptionFees: IFeeResponse;
  isFetchingDebtRedemptionFees: boolean;
  fetchFeesForRedeemQuantity: (...args: any[]) => any;
  setMaxRedeemableQuantity: (...args: any[]) => any;
  updateRedemptionQuantity: (...args: any[]) => any;
  debtRedeemCurrentSet: (...args: any[]) => any;
};

class DebtRedemption extends PureComponent<DebtRedemptionFormProps & WithTranslation> {
  componentDidMount() {
    const { fetchFeesForRedeemQuantity } = this.props;

    fetchFeesForRedeemQuantity();
  }

  public onUpdateRedemptionQuantity = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { updateRedemptionQuantity, fetchFeesForRedeemQuantity } = this.props;
    const value = e.target?.value;

    await updateRedemptionQuantity(value);
    await fetchFeesForRedeemQuantity();
  };

  public renderInputComponents = () => {
    const { inputComponents, erc20BalancesByAddress, redemptionQuantity, t } = this.props;

    if (!inputComponents) return null;

    const requiredComponentsList = inputComponents?.map((component: IDebtComponentWithToken) => {
      const { address } = component;
      const checkSumAddress = Web3.utils.toChecksumAddress(address);
      const userBalance = erc20BalancesByAddress[checkSumAddress]?.toString();
      const formattedDebtValue = tokenFromBaseUnits(
        component?.debtValue?.toString() || '0',
        component?.decimals ?? 18,
      );
      const requiredQuantity = formattedDebtValue.mul(redemptionQuantity || 0);
      const derivedTokenColor = generateColorFromString(address || '');

      return (
        <div className={css(styles.DebtRedemptionForm_component)} key={component.symbol}>
          <div className={css(styles.DebtRedemptionForm_componentLabelContainer)}>
            <img
              className={css(styles.DebtRedemptionForm_componentIcon)}
              src={getListTokenImage(component)}
              style={{ backgroundColor: `#${derivedTokenColor}` }}
            />
            <span className={css(styles.DebtRedemptionForm_componentSymbol)}>
              {component?.symbol}
            </span>
          </div>
          <span className={css(styles.DebtRedemptionForm_componentQuantityRequired)}>
            {requiredQuantity.toFixed(8, BigNumber.ROUND_DOWN)}
          </span>
          <span className={css(styles.DebtRedemptionForm_componentUserBalance)}>
            {formatTokenUnit(userBalance, component?.decimals, 8)}
          </span>
        </div>
      );
    });

    return (
      <div className={css(styles.DebtRedemptionForm_componentContainer)}>
        <div className={css(styles.DebtRedemptionForm_tableHeader)}>
          <span className={css(styles.DebtRedemptionForm_tokenLabel)}>{t('table.token')}</span>
          <span className={css(styles.DebtRedemptionForm_requiredAmountLabel)}>
            {t('table.amount-required')}
          </span>
          <span className={css(styles.DebtRedemptionForm_userBalanceLabel)}>
            {t('table.your-balance')}
          </span>
        </div>
        {requiredComponentsList}
      </div>
    );
  };

  public renderOutputComponents = () => {
    const {
      setDetails,
      outputComponents,
      redemptionQuantity,
      isFetchingDebtRedemptionFees,
      debtRedemptionFees,
      t,
    } = this.props;

    if (!outputComponents) return null;

    const formattedRedemptionQuantity = utils.parseEther(
      redemptionQuantity?.length ? redemptionQuantity : '0',
    );

    const requiredComponentsList = outputComponents?.map((component: IDebtComponentWithToken) => {
      const { address } = component;
      const formattedEquityValue = tokenFromBaseUnits(
        component?.equityValue?.toString() || '0',
        component?.decimals ?? 18,
      );
      const returnedQuantity = formattedEquityValue.mul(redemptionQuantity || 0);
      const derivedTokenColor = generateColorFromString(address || '');

      return (
        <div className={css(styles.DebtRedemptionForm_component)} key={component.symbol}>
          <div className={css(styles.DebtRedemptionForm_componentLabelContainer)}>
            <img
              className={css(styles.DebtRedemptionForm_componentIcon)}
              src={getListTokenImage(component)}
              style={{ backgroundColor: `#${derivedTokenColor}` }}
            />
            <span className={css(styles.DebtRedemptionForm_componentSymbol)}>
              {component?.symbol}
            </span>
          </div>
          <span className={css(styles.DebtRedemptionForm_componentQuantityRequired)}>
            {returnedQuantity.toFixed(8, BigNumber.ROUND_DOWN)}
          </span>
        </div>
      );
    });

    let redemptionFee = '0';
    if (!isFetchingDebtRedemptionFees && debtRedemptionFees?.managerFee) {
      redemptionFee = tokenFromBaseUnits(debtRedemptionFees?.managerFee.toString()).toFixed(
        2,
        BigNumber.ROUND_UP,
      );
    }

    return (
      <div className={css(styles.DebtRedemptionForm_componentContainer)}>
        {formattedRedemptionQuantity.gt(0) && (
          <p className={css(styles.DebtRedemptionForm_debtIssuanceDescription)}>
            {t('debt-redeem-form.fees', {
              setName: setDetails?.name,
              feePercentage: `${redemptionFee}%`,
            })}{' '}
            {t('debt-redeem-form.returned-tokens')}
          </p>
        )}
        <div className={css(styles.DebtRedemptionForm_tableHeader)}>
          <span className={css(styles.DebtRedemptionForm_tokenLabel)}>{t('table.token')}</span>
          <span className={css(styles.DebtRedemptionForm_requiredAmountLabel)}>
            {t('table.amount-returned')}
          </span>
        </div>
        {requiredComponentsList}
      </div>
    );
  };

  renderErrorMessage = () => {
    const { userHasSufficientFunds, setDetails, t } = this.props;

    if (!userHasSufficientFunds) {
      return (
        <div className={css(styles.DebtRedemptionForm_errorContainer)}>
          <span className={css(styles.DebtRedemptionForm_errorText)}>
            {t('redeem-form.error-title')}{' '}
            {t('redeem-form.error-description', { tokenName: setDetails?.name })}
          </span>
        </div>
      );
    }

    return null;
  };

  render() {
    const {
      setAddress,
      customV2SetPathPrefix,
      setDetails,
      redemptionQuantity,
      isDebtRedemptionReady,
      history,
      debtRedeemCurrentSet,
      setMaxRedeemableQuantity,
      t,
    } = this.props;

    return (
      <div className={css(styles.DebtRedemptionForm_container)}>
        <img
          src={darkArrow}
          className={css(styles.DebtRedemptionForm_backButton)}
          onClick={() => history.push(`${customV2SetPathPrefix}/${setAddress}`)}
        />
        <img
          className={css(styles.DebtRedemptionForm_setImage)}
          src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/issue.svg"
        />
        <p className={css(styles.DebtRedemptionForm_title)}>Debt Redeem {setDetails?.name}</p>
        <p className={css(styles.DebtRedemptionForm_subTitle)}>
          {t('debt-redeem-form.title', { tokenSymbol: setDetails?.symbol })}
        </p>

        <div className={css(styles.DebtRedemptionForm_issueInputContainer)}>
          <input
            className={css(styles.DebtRedemptionForm_issueQuantityInput)}
            type="number"
            value={redemptionQuantity}
            onChange={this.onUpdateRedemptionQuantity}
          />
          <div className={css(styles.DebtRedemptionForm_issueInputLabelContainer)}>
            <span className={css(styles.DebtRedemptionForm_issueInputSymbol)}>
              {setDetails?.symbol}
            </span>
            <button
              className={css(styles.DebtRedemptionForm_issueMaxButton)}
              type="button"
              onClick={setMaxRedeemableQuantity}
            >
              MAX
            </button>
          </div>
        </div>

        {this.renderInputComponents()}
        {this.renderOutputComponents()}

        <button
          className={css(
            styles.DebtRedemptionForm_submitButton,
            !isDebtRedemptionReady && styles.DebtRedemptionForm_submitButtonDisabled,
          )}
          disabled={!isDebtRedemptionReady}
          type="button"
          onClick={debtRedeemCurrentSet}
        >
          {t('buttons.redeem')}
        </button>

        {this.renderErrorMessage()}
      </div>
    );
  }
}
export default withTranslation('issue-and-redeem')(DebtRedemption);
