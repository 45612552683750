import { css, StyleSheet } from 'aphrodite';
import React, { Fragment, PureComponent } from 'react';
import { Container, Popup } from 'semantic-ui-react';
import { WithTranslation } from 'react-i18next';
import _ from 'underscore';
import { toChecksumAddress } from 'tochecksum';

import IssueRedeemButtons from './IssueRedeemButtons';
import CustomSetHeader from './CustomSetHeader';
import CustomSetMetadata from './CustomSetMetadata';
import CustomSetWarningBanner from './CustomSetWarningBanner';

import {
  GO_TO_CUSTOM_SET_AND_PORTFOLIO_ISSUANCE_PAGE,
  GO_TO_CUSTOM_SET_AND_PORTFOLIO_REDEMPTION_PAGE,
} from '../../actions/web3Actions';
import {
  IMatch,
  IWindowDimensions,
  IRebalancingSet,
  ISetDetails,
  IListToken,
  IToken,
  ISetConfirmedWarningsList,
  ITokenBalances,
  CustomSetDetailsResponse,
  IPerpPositionUnitInfo,
  IPerpAccountInfo,
} from '../../typings/index';
import { WINDOW_DIMENSIONS, COLORS, DEFAULT_SET_UNITS } from '../../constants/index';
import { BigNumber } from '../../utils/index';
import { Text } from '../../components/index';
import SetAllocationSection from '../../components/PriceChart/SetAllocationSection';
import CustomSetMissing from './CustomSetMissing';
import { IListTokenWithPosition } from '../../typings/index';
import { urlIntendedChain } from '../../utils/web3Utils';
import PerpLeverageDetails from '../../components/PerpLeverageDetails';
import { VAssetDisplayInfo } from 'set.js/dist/types/src/types';
import { Link } from 'react-router-dom';

const { MOBILE_MEDIA_QUERY, TABLET_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  CustomSetDetails_rebalanceButton: {
    backgroundColor: 'transparent',
    border: `1px solid ${COLORS.white}`,
    borderRadius: '4px',
    color: COLORS.white,
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 500,
    marginLeft: '10px',
    padding: '6px 12px',
    transition: '0.2s',
    ':hover': {
      backgroundColor: COLORS.white,
      color: COLORS.green,
      transform: 'scale(1.05)',
    },
  },
  CustomSetDetails_container: {
    marginBottom: '70px',
    [MOBILE_MEDIA_QUERY]: {
      marginTop: '20px',
      marginBottom: '50px',
    },
  },
  CustomSetDetails_divider: {
    marginTop: '90px',
  },
  CustomSetDetails_loadingContainer: {
    marginBottom: '30px',
  },
  CustomSetDetails_loadingText: {
    color: COLORS.darkGray,
  },
  CustomSetDetails_rebalanceMessageContainer: {
    backgroundColor: COLORS.green,
    verticalAlign: 'middle',
    display: 'block',
    width: '100%',
    textAlign: 'center',
    position: 'relative',
    padding: '15px 40px',
    marginTop: '-8px',
    borderRadius: '0',
    [MOBILE_MEDIA_QUERY]: {
      marginTop: '0',
      borderBottom: `1px solid ${COLORS.gray}`,
    },
  },
  CustomSetDetails_rebalanceLink: {
    color: COLORS.white,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    marginTop: '10px',
    transition: '0.2s',
    ':hover': {
      color: COLORS.whiteDarkened,
    },
  },
  CustomSetDetails_disabledMessageContainer: {
    verticalAlign: 'middle',
    display: 'block',
    width: '100%',
    textAlign: 'center',
    position: 'relative',
    padding: '15px 40px',
    marginTop: '-8px',
    borderRadius: '0',
    [MOBILE_MEDIA_QUERY]: {
      marginTop: '0',
      borderBottom: `1px solid ${COLORS.gray}`,
    },
  },
  CustomSetDetails_buyDisabledLink: {
    color: COLORS.blue,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    marginTop: '10px',
    transition: '0.2s',
    ':hover': {
      color: COLORS.darkBlue,
    },
  },
  CustomSetDetails_disabledMessageContainerText: {
    color: COLORS.white,
  },
  CustomSetDetails_detailsDimmer: {
    opacity: '50%',
    pointerEvents: 'none',
  },
  CustomSetDetails_backLinkContainer: {
    marginTop: '20px',
    marginBottom: '10px',
    [TABLET_MEDIA_QUERY]: {
      marginBottom: '30px',
      marginTop: '30px',
    },
  },
  CustomSetDetails_backLink: {
    color: COLORS.disabledGray,
    fontSize: '14px',
    fontWeight: 500,
  },
});

interface ICustomSetDetailsProps {
  isBuySellModalOpen: boolean;
  isFetchingSetDetails: boolean;
  isInvalidSetDetails: boolean;
  isFetchingCustomSetDetails: boolean;
  isInvalidCustomSetDetails: boolean;
  isLoggedIn: boolean;
  isTransactionProcessing: boolean;
  isGeofencedSet: boolean;
  isBasicIssuanceEnabled: boolean;
  perpV2LeverageModuleEnabled: boolean;
  perpV2BasisTradingModuleEnabled: boolean;
  hasNoExternalPositions: boolean;
  account: string;
  providerType: string;
  match: IMatch;
  location: { pathname: string };
  history: any;
  windowDimension?: IWindowDimensions;
  setDetails?: ISetDetails;
  setOwnerAddress?: string;
  customSetDetails?: CustomSetDetailsResponse;
  setBalances?: ITokenBalances;
  tokenSetsTokenListByAddress?: { [address: string]: IListToken };
  currentSetComponents?: IListTokenWithPosition[];
  formattedCurrentSetComponentsWithColors?: IToken[];
  warningConfirmations: ISetConfirmedWarningsList;
  setDetailsCurrentSetAddress: string;
  currentChain: string;
  networkBadge: string;
  customSetPathPrefix: string;
  perpCollateralToken: string;
  perpPositionUnitInfos: IPerpPositionUnitInfo[];
  perpAccountInfo: IPerpAccountInfo;
  perpSetTotalCollateralUnits: string;
  vAssetDisplayInfo: VAssetDisplayInfo[];
  coingeckoTokenListByAddress: { [address: string]: IListToken };
  coingeckoTokenListBySymbol: { [symbol: string]: IListToken };
  isUserValidOperator: boolean;
  onFetchEnabledModules: (...args: any[]) => any;
  onFetchIpAddress: (...args: any[]) => any;
  onFetchGasPrice: (...args: any[]) => any;
  onHandleLogin: (...args: any[]) => any;
  onOpenEditDetailsModal: (...args: any[]) => any;
  onOpenBuySet: (...args: any[]) => any;
  onOpenSellSet: (...args: any[]) => any;
  onAgreeToExperimentalWarning: (...args: any[]) => any;
  onFetchTokenColorsForCurrentSet: (...args: any[]) => any;
  onFetchUnderlyingTokenAllowances: (...args: any[]) => any;
  onSetCurrentlyViewingSetAddress: (...args: any[]) => any;
  onFetchDeployedSetsWithLogosAndPrices: (...args: any[]) => any;
  fetchUserFundBalance: (...args: any[]) => any;
  fetchSetBalance: (...args: any[]) => any;
  fetchSetDetails: (...args: any[]) => any;
  fetchCustomSetDetails: (...args: any[]) => any;
  fetch1InchTokenList: (...args: any[]) => any;
  fetchCoingeckoTokenList: (...args: any[]) => any;
  fetchTokenSetsTokenList: (...args: any[]) => any;
  fetchDebtIssuanceV2Fees: (...args: any[]) => any;
  accrueStreamingFee: (...args: any[]) => any;
  openChainSwitchModal: (...args: any[]) => any;
  closeChainSwitchModal: (...args: any[]) => any;
  fetchAllPerpLeverageData: (...args: any[]) => any;
}

type ICustomSetDetailsState = {
  fullyLoaded: boolean;
};

/**
 * @title CustomSetDetails
 * @author Set Labs
 *
 * Displays major information about a fund's components, metadata, and rebalance history.
 *
 */
class CustomSetDetailsComponent extends PureComponent<
  ICustomSetDetailsProps & WithTranslation,
  ICustomSetDetailsState
> {
  mounted = false;
  state = {
    fullyLoaded: false,
  };

  async componentDidMount() {
    const {
      match,
      history,
      currentChain,
      fetchAllPerpLeverageData,
      fetchSetBalance,
      fetch1InchTokenList,
      fetchCoingeckoTokenList,
      fetchTokenSetsTokenList,
      onFetchEnabledModules,
      onFetchIpAddress,
      onFetchGasPrice,
      fetchSetDetails,
      fetchDebtIssuanceV2Fees,
      onSetCurrentlyViewingSetAddress,
      onFetchTokenColorsForCurrentSet,
    } = this.props;

    this.mounted = true;
    document.title = 'Portfolio Details | TokenSets';

    if (match.params.set === 'undefined') {
      history.push('/404');
      return;
    }

    const checksummedAddress = toChecksumAddress(match.params.set);

    if (urlIntendedChain() !== currentChain) {
      // Do nothing because of URL mismatch
    } else {
      await fetch1InchTokenList();
      await fetchCoingeckoTokenList();
      await fetchTokenSetsTokenList();
      await onFetchIpAddress();

      await this.loadSet();
      await this.loadCustomSet();

      await fetchSetBalance(checksummedAddress);
      await onSetCurrentlyViewingSetAddress(checksummedAddress);
      await onFetchTokenColorsForCurrentSet();
      await onFetchGasPrice();
      await fetchSetDetails(checksummedAddress);
      fetchDebtIssuanceV2Fees(checksummedAddress);

      const moduleStatuses = await onFetchEnabledModules(checksummedAddress, onFetchEnabledModules);
      if (moduleStatuses.isPerpV2LeverageEnabled || moduleStatuses.isPerpV2BasisTradingEnabled) {
        await fetchAllPerpLeverageData(
          checksummedAddress,
          moduleStatuses.isPerpV2BasisTradingEnabled,
        );
      }
    }

    this.setState({
      fullyLoaded: true,
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async componentDidUpdate(prevProps: ICustomSetDetailsProps) {
    const {
      isBuySellModalOpen,
      setDetails,
      match,
      fetchSetBalance,
      onFetchTokenColorsForCurrentSet,
      onSetCurrentlyViewingSetAddress,
    } = this.props;

    const checksummedAddress = toChecksumAddress(match.params.set);
    await onSetCurrentlyViewingSetAddress(checksummedAddress);

    if (match.params.set !== prevProps.match.params.set) {
      this.setState({
        fullyLoaded: false,
      });
      await this.loadSet();
      await this.loadCustomSet();
      await fetchSetBalance(checksummedAddress);
      this.setState({
        fullyLoaded: true,
      });
    } else if (!isBuySellModalOpen && prevProps.isBuySellModalOpen) {
      // Re-load set details and re-fetch set balance without blocking on a fullyLoaded page load
      await this.loadSet();
      await fetchSetBalance(checksummedAddress);
    }

    if (!_.isEmpty(setDetails) && !_.isEqual(setDetails, prevProps.setDetails)) {
      onFetchTokenColorsForCurrentSet();
      document.title = `${setDetails?.name} | TokenSets`;
    }
  }

  async loadSet(): Promise<ISetDetails> {
    const { match, fetchSetDetails, onFetchDeployedSetsWithLogosAndPrices } = this.props;
    const setAddress = toChecksumAddress(match.params.set);

    await onFetchDeployedSetsWithLogosAndPrices([setAddress]);

    return await fetchSetDetails(setAddress);
  }

  async loadCustomSet(): Promise<CustomSetDetailsResponse> {
    const { match, fetchCustomSetDetails } = this.props;
    const setAddress = toChecksumAddress(match.params.set);

    return await fetchCustomSetDetails(setAddress);
  }

  public openEditDetailsModal = () => {
    const { onOpenEditDetailsModal } = this.props;

    onOpenEditDetailsModal();
  };

  public navigateToIssuance = () => {
    const { isLoggedIn, history, match, customSetPathPrefix, onHandleLogin } = this.props;

    if (!isLoggedIn) {
      onHandleLogin(GO_TO_CUSTOM_SET_AND_PORTFOLIO_ISSUANCE_PAGE);
    } else {
      history.push(`${customSetPathPrefix}/${toChecksumAddress(match.params.set)}/issue`);
    }
  };

  public navigateToRedemption = () => {
    const { isLoggedIn, history, match, customSetPathPrefix, onHandleLogin } = this.props;

    if (!isLoggedIn) {
      onHandleLogin(GO_TO_CUSTOM_SET_AND_PORTFOLIO_REDEMPTION_PAGE);
    } else {
      history.push(`${customSetPathPrefix}/${toChecksumAddress(match.params.set)}/redeem`);
    }
  };

  render() {
    const {
      perpV2LeverageModuleEnabled,
      perpV2BasisTradingModuleEnabled,
      isFetchingSetDetails,
      isFetchingCustomSetDetails,
      isInvalidSetDetails,
      isBasicIssuanceEnabled,
      windowDimension,
      history,
      match,
      account,
      setDetails,
      setOwnerAddress,
      customSetDetails,
      currentChain,
      networkBadge,
      setBalances,
      tokenSetsTokenListByAddress,
      currentSetComponents,
      formattedCurrentSetComponentsWithColors,
      warningConfirmations,
      perpCollateralToken,
      perpPositionUnitInfos,
      perpAccountInfo,
      perpSetTotalCollateralUnits,
      vAssetDisplayInfo,
      coingeckoTokenListByAddress,
      coingeckoTokenListBySymbol,
      isUserValidOperator,
      onAgreeToExperimentalWarning,
      accrueStreamingFee,
      openChainSwitchModal,
      closeChainSwitchModal,
      t,
    } = this.props;
    const { fullyLoaded } = this.state;

    if (urlIntendedChain() !== currentChain) {
      openChainSwitchModal();
      return null;
    } else {
      closeChainSwitchModal();
    }

    const { isTablet } = windowDimension;

    const currentSet = {
      address: toChecksumAddress(match.params.set),
      name: customSetDetails?.setDetails?.setName || setDetails?.name,
      symbol: customSetDetails?.setDetails?.setSymbol || setDetails?.symbol,
      image: customSetDetails?.setDetails?.setIcon,
      components: formattedCurrentSetComponentsWithColors || [],
    };

    const isSetOperator = setOwnerAddress && toChecksumAddress(setOwnerAddress) === account;
    const isWarningBannerDisplayed = !warningConfirmations?.[toChecksumAddress(match.params.set)];
    const dimmerStyles = isWarningBannerDisplayed && styles.CustomSetDetails_detailsDimmer;

    if (isInvalidSetDetails) {
      return <CustomSetMissing setAddress={toChecksumAddress(match.params.set)} />;
    }

    const isLoading = isFetchingCustomSetDetails || !fullyLoaded;
    return (
      <div>
        {isWarningBannerDisplayed && (
          <CustomSetWarningBanner
            onAgreeToExperimentalWarning={onAgreeToExperimentalWarning}
            setAddress={toChecksumAddress(match.params.set)}
          />
        )}
        <div className={css(dimmerStyles)}>
          {isTablet && (
            <Popup
              hideOnScroll
              position={'top center'}
              on="click"
              trigger={
                <div>
                  <IssueRedeemButtons
                    isLoading={isLoading}
                    onNavToIssuance={this.navigateToIssuance}
                    onNavToRedemption={this.navigateToRedemption}
                  />
                </div>
              }
            >
              <div>
                <Text color="darkBlue" fontWeight={500}>
                  {t('common.coming-soon')}
                </Text>
              </div>
            </Popup>
          )}
          <Container className={css(styles.CustomSetDetails_container)}>
            <Fragment>
              <div className={css(styles.CustomSetDetails_backLinkContainer)}>
                <Link
                  className={css(styles.CustomSetDetails_backLink)}
                  to={`/explore?show=uncategorized`}
                >
                  {'<'}&nbsp;&nbsp;&nbsp;{`Back to Explorer`}
                </Link>
              </div>
              <CustomSetHeader
                isLoading={isLoading}
                isSetOperator={isSetOperator}
                isUserValidOperator={isUserValidOperator}
                isWarningBannerDisplayed={isWarningBannerDisplayed}
                isBasicIssuanceEnabled={isBasicIssuanceEnabled}
                setDetails={setDetails}
                setOwnerAddress={setOwnerAddress}
                customSetDetails={customSetDetails}
                networkBadge={networkBadge}
                setAddress={toChecksumAddress(match.params.set)}
                setSymbol={currentSet?.symbol}
                setBalance={(setBalances || {})[toChecksumAddress(match.params.set)]}
                account={account}
                windowDimension={windowDimension}
                history={history}
                currentChain={currentChain}
                onOpenEditDetailsModal={this.openEditDetailsModal}
                onNavToIssuance={this.navigateToIssuance}
                onNavToRedemption={this.navigateToRedemption}
                accrueStreamingFee={accrueStreamingFee}
                tokenSetsTokenListByAddress={tokenSetsTokenListByAddress}
              />
              <br />
              {perpV2LeverageModuleEnabled || perpV2BasisTradingModuleEnabled ? (
                <PerpLeverageDetails
                  currentSetComponents={currentSetComponents}
                  coingeckoTokenListByAddress={coingeckoTokenListByAddress}
                  coingeckoTokenListBySymbol={coingeckoTokenListBySymbol}
                  currentSet={currentSet as IRebalancingSet}
                  windowDimension={windowDimension}
                  isLoading={isLoading || !currentSet.components.length}
                  tokenSetsTokenListByAddress={tokenSetsTokenListByAddress}
                  perpCollateralToken={perpCollateralToken}
                  perpPositionUnitInfos={perpPositionUnitInfos}
                  perpAccountInfo={perpAccountInfo}
                  perpSetTotalCollateralUnits={perpSetTotalCollateralUnits}
                  vAssetDisplayInfo={vAssetDisplayInfo}
                  formattedCurrentSetComponentsWithColors={formattedCurrentSetComponentsWithColors}
                />
              ) : (
                <SetAllocationSection
                  isCustomSet
                  currentSetComponents={currentSetComponents}
                  formattedCurrentSetComponentsWithColors={formattedCurrentSetComponentsWithColors}
                  coinPercentChanges={{}}
                  coinTargetAllocations={{}}
                  percentChange={'0'}
                  currentSet={currentSet as IRebalancingSet}
                  windowDimension={windowDimension}
                  isLoading={isLoading || !currentSet.components.length}
                  tokenSetsTokenListByAddress={tokenSetsTokenListByAddress}
                />
              )}
              {!isFetchingSetDetails &&
                !isFetchingCustomSetDetails &&
                this.mounted &&
                (!_.isEmpty(setDetails) || !_.isEmpty(customSetDetails)) && (
                  <CustomSetMetadata
                    currentChain={currentChain}
                    isLoading={isLoading}
                    customSetDetails={customSetDetails}
                    setAddress={toChecksumAddress(match.params.set)}
                    setSymbol={currentSet?.symbol}
                    setBalance={(setBalances || {})[toChecksumAddress(match.params.set)]}
                    totalSupply={setDetails?.totalSupply?.div(DEFAULT_SET_UNITS)?.toString()}
                    account={account}
                    managerAddress={toChecksumAddress(setOwnerAddress || '')}
                    match={match}
                    issuanceFee={setDetails?.issuanceFeePercentage || '0'}
                    redemptionFee={setDetails?.redemptionFeePercentage || '0'}
                    streamingFee={Number(
                      new BigNumber(setDetails?.streamingFeePercentage || 0)
                        .mul(100)
                        .div(DEFAULT_SET_UNITS)
                        .toNumber()
                        .toFixed(18),
                    ).toString()}
                    unaccruedFees={new BigNumber(setDetails?.unaccruedFees || 0)
                      .div(DEFAULT_SET_UNITS)
                      .mul(setDetails?.totalSupply || 0)
                      .div(DEFAULT_SET_UNITS)
                      .toNumber()
                      .toFixed(2)}
                  />
                )}
            </Fragment>
          </Container>
        </div>
      </div>
    );
  }
}

export default CustomSetDetailsComponent;
