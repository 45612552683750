import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { toast } from 'react-toastify';

import RedemptionFormWrapper from '../../containers/RedemptionFormWrapperV2';
import { IHistory, IMatch, ISetDetails } from '../../typings/index';
import { WINDOW_DIMENSIONS, COLORS } from '../../constants/index';
import RedemptionShimmer from './RedemptionShimmer';
import RedemptionDisabled from './RedemptionDisabled';
import DebtRedemptionApproval from '../../containers/DebtRedemptionApproval';
import DebtRedemptionFormWrapper from '../../containers/DebtRedemptionFormWrapper';
import PerpRedemptionApproval from '../../containers/PerpRedemptionApproval';
import PerpRedemptionFormWrapper from '../../containers/PerpRedemptionFormWrapper';

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  Redemption_container: {
    position: 'relative',
    width: '60vw',
    maxWidth: '500px',
    margin: '40px auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '40px',
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '4px',
    [MOBILE_MEDIA_QUERY]: {
      width: '90vw',
      padding: '20px',
    },
  },
});

type RedemptionProps = {
  setAddress: string;
  customV2SetPathPrefix: string;
  isLoading: boolean;
  isFetchingIssuanceModuleEnabled: boolean;
  isIssuanceModuleEnabled: boolean;
  isDebtIssuanceModuleEnabled: boolean;
  isPerpIssuanceModuleEnabled: boolean;
  hasAllDebtRedemptionApprovals: boolean;
  hasAllPerpRedemptionApprovals: boolean;
  account: string;
  setDetails: ISetDetails;
  history: IHistory;
  match: IMatch;
  isWrongNetwork: boolean;
  onFetchEnabledModules: (...args: any[]) => any;
  onFetchDebtRedemptionComponents: (...args: any[]) => any;
  onFetchDebtRedemptionAllowancesForCurrentSet: (...args: any[]) => any;
  onFetchPerpRedemptionComponents: (...args: any[]) => any;
  onFetchPerpRedemptionAllowancesForCurrentSet: (...args: any[]) => any;
  onFetchAllowancesForCurrentSet: (...args: any[]) => any;
  onFetchCurrentSetTokenBalances: (...args: any[]) => any;
  onFetchGasPrice: (...args: any[]) => any;
  onFetchTokenList: (...args: any[]) => any;
  onSetCurrentlyViewingSetAddress: (...args: any[]) => any;
  onFetchSetDetailsByAddress: (...args: any[]) => any;
};

type RedemptionState = {
  fullyLoaded: boolean;
};

/**
 * @title Redemption
 * @author Set Protocol
 *
 * Walks a user through approving and redeeming a V2 Set.
 * Top level container which fetches data and renders Approval Form or Redemption Form.
 */
class Redemption extends PureComponent<RedemptionProps, RedemptionState> {
  state = {
    fullyLoaded: false,
  };

  async componentDidMount() {
    document.title = 'Redeem Set | TokenSets';
    await this.fetchInitialRedemptionRequirements();
  }

  async fetchInitialRedemptionRequirements() {
    const {
      onFetchEnabledModules,
      onFetchSetDetailsByAddress,
      onSetCurrentlyViewingSetAddress,
      onFetchTokenList,
      onFetchCurrentSetTokenBalances,
      onFetchAllowancesForCurrentSet,
      onFetchGasPrice,
      onFetchDebtRedemptionComponents,
      onFetchDebtRedemptionAllowancesForCurrentSet,
      onFetchPerpRedemptionComponents,
      onFetchPerpRedemptionAllowancesForCurrentSet,
      match,
    } = this.props;
    this.setState({ fullyLoaded: false });
    const setAddress = match.params.set;
    await onFetchTokenList();
    await onSetCurrentlyViewingSetAddress(setAddress);
    const setDetails = await onFetchSetDetailsByAddress(setAddress);

    if (!setDetails) {
      return;
    }

    await onFetchCurrentSetTokenBalances();
    const enabledModules = await onFetchEnabledModules(setAddress);

    if (enabledModules.isDebtIssuanceEnabled || enabledModules.isDebtIssuanceV2Enabled) {
      await onFetchDebtRedemptionComponents();
      onFetchDebtRedemptionAllowancesForCurrentSet();
    }

    if (enabledModules.isPerpIssuanceEnabled) {
      await onFetchPerpRedemptionComponents();
      onFetchPerpRedemptionAllowancesForCurrentSet();
    }

    await onFetchAllowancesForCurrentSet();
    await onFetchGasPrice();
    this.setState({ fullyLoaded: true });
  }

  render() {
    const {
      setAddress,
      customV2SetPathPrefix,
      isLoading,
      isFetchingIssuanceModuleEnabled,
      isDebtIssuanceModuleEnabled,
      isPerpIssuanceModuleEnabled,
      isIssuanceModuleEnabled,
      hasAllDebtRedemptionApprovals,
      hasAllPerpRedemptionApprovals,
      account,
      setDetails,
      match,
      history,
      isWrongNetwork,
    } = this.props;
    const { fullyLoaded } = this.state;

    if (isWrongNetwork) {
      toast.warn('Sorry, you cannot redeem while on an unsupported network.');
      history.push(`${customV2SetPathPrefix}/${match.params.set}`);
    }

    if (!fullyLoaded || isLoading || isFetchingIssuanceModuleEnabled) {
      return <RedemptionShimmer />;
    }

    if (!isIssuanceModuleEnabled && !isDebtIssuanceModuleEnabled && !isPerpIssuanceModuleEnabled) {
      return (
        <RedemptionDisabled
          customV2SetPathPrefix={customV2SetPathPrefix}
          setAddress={setAddress}
          setDetails={setDetails}
          account={account}
          history={history}
          match={match}
        />
      );
    }

    if (isDebtIssuanceModuleEnabled && !hasAllDebtRedemptionApprovals) {
      return (
        <div className={css(styles.Redemption_container)}>
          <DebtRedemptionApproval history={history} />
        </div>
      );
    }

    if (isDebtIssuanceModuleEnabled && hasAllDebtRedemptionApprovals) {
      return (
        <div className={css(styles.Redemption_container)}>
          <DebtRedemptionFormWrapper history={history} match={match} />
        </div>
      );
    }

    if (isPerpIssuanceModuleEnabled && !hasAllPerpRedemptionApprovals) {
      return (
        <div className={css(styles.Redemption_container)}>
          <PerpRedemptionApproval history={history} />
        </div>
      );
    }

    if (isPerpIssuanceModuleEnabled && hasAllPerpRedemptionApprovals) {
      return (
        <div className={css(styles.Redemption_container)}>
          <PerpRedemptionFormWrapper match={match} history={history} />
        </div>
      );
    }

    return (
      <div className={css(styles.Redemption_container)}>
        <RedemptionFormWrapper match={match} history={history} />
      </div>
    );
  }
}

export default Redemption;
