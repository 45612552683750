const delegatedManagerFactoryABI = [
  {
    inputs: [
      { internalType: 'contract IManagerCore', name: '_managerCore', type: 'address' },
      { internalType: 'contract ISetTokenCreator', name: '_setTokenFactory', type: 'address' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'contract ISetToken', name: '_setToken', type: 'address' },
      {
        indexed: true,
        internalType: 'contract DelegatedManager',
        name: '_manager',
        type: 'address',
      },
      { indexed: false, internalType: 'address', name: '_deployer', type: 'address' },
    ],
    name: 'DelegatedManagerCreated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'contract ISetToken', name: '_setToken', type: 'address' },
      {
        indexed: true,
        internalType: 'contract IDelegatedManager',
        name: '_manager',
        type: 'address',
      },
    ],
    name: 'DelegatedManagerInitialized',
    type: 'event',
  },
  {
    inputs: [
      { internalType: 'contract ISetToken', name: '_setToken', type: 'address' },
      { internalType: 'address', name: '_owner', type: 'address' },
      { internalType: 'address', name: '_methodologist', type: 'address' },
      { internalType: 'address[]', name: '_operators', type: 'address[]' },
      { internalType: 'address[]', name: '_assets', type: 'address[]' },
      { internalType: 'address[]', name: '_extensions', type: 'address[]' },
    ],
    name: 'createManager',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address[]', name: '_components', type: 'address[]' },
      { internalType: 'int256[]', name: '_units', type: 'int256[]' },
      { internalType: 'string', name: '_name', type: 'string' },
      { internalType: 'string', name: '_symbol', type: 'string' },
      { internalType: 'address', name: '_owner', type: 'address' },
      { internalType: 'address', name: '_methodologist', type: 'address' },
      { internalType: 'address[]', name: '_modules', type: 'address[]' },
      { internalType: 'address[]', name: '_operators', type: 'address[]' },
      { internalType: 'address[]', name: '_assets', type: 'address[]' },
      { internalType: 'address[]', name: '_extensions', type: 'address[]' },
    ],
    name: 'createSetAndManager',
    outputs: [
      { internalType: 'contract ISetToken', name: '', type: 'address' },
      { internalType: 'address', name: '', type: 'address' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract ISetToken', name: '_setToken', type: 'address' },
      { internalType: 'uint256', name: '_ownerFeeSplit', type: 'uint256' },
      { internalType: 'address', name: '_ownerFeeRecipient', type: 'address' },
      { internalType: 'address[]', name: '_initializeTargets', type: 'address[]' },
      { internalType: 'bytes[]', name: '_initializeBytecode', type: 'bytes[]' },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'contract ISetToken', name: '', type: 'address' }],
    name: 'initializeState',
    outputs: [
      { internalType: 'address', name: 'deployer', type: 'address' },
      { internalType: 'address', name: 'owner', type: 'address' },
      { internalType: 'contract IDelegatedManager', name: 'manager', type: 'address' },
      { internalType: 'bool', name: 'isPending', type: 'bool' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'managerCore',
    outputs: [{ internalType: 'contract IManagerCore', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'setTokenFactory',
    outputs: [{ internalType: 'contract ISetTokenCreator', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
];

export default delegatedManagerFactoryABI;
