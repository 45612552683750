import axios from 'axios';
import { emptyActionGenerator, payloadActionGenerator } from '../utils/reduxHelpers';
import { IListToken, GraphSetToken } from '../typings/index';
import { tokenListNameOverride } from '../constants/tokenListConstants';
import _ from 'lodash';
import { apolloClientSelector } from '../selectors/apolloSelectors';
import { accountSelector } from '../selectors/baseSelectors';
import { toast } from 'react-toastify';
import { OWNED_AND_OPERATED_SETS_FOR_ACCOUNT_QUERY } from '../queries/index';
import {
  coingeckoTokenListKeySelector,
  coingeckoTokenListUrlSelector,
  geminiTokenListKeySelector,
  geminiTokenListUrlSelector,
  tokenSetsTokenListUrlSelector,
} from '../selectors/tokenListsSelectors';
import {
  getTokenListCached,
  isLocalStorageEnabled,
  setTokenListCached,
} from '../utils/localStorageUtils';

export const FETCH_TOKEN_LIST = 'FETCH_TOKEN_LIST';
export const RECEIVE_TOKEN_LIST = 'RECEIVE_TOKEN_LIST';

export const REQUEST_MANAGER_TOKENS = 'REQUEST_MANAGER_TOKENS';
export const RECEIVE_MANAGER_TOKENS = 'RECEIVE_MANAGER_TOKENS';
export const ERROR_MANAGER_TOKENS = 'ERROR_MANAGER_TOKENS';

export const requestTokenList = emptyActionGenerator(FETCH_TOKEN_LIST);
export const receiveTokenList = payloadActionGenerator(RECEIVE_TOKEN_LIST);

export const requestManagerTokens = emptyActionGenerator(REQUEST_MANAGER_TOKENS);
export const receiveManagerTokens = payloadActionGenerator(RECEIVE_MANAGER_TOKENS);
export const errorManagerTokens = payloadActionGenerator(ERROR_MANAGER_TOKENS);

export const fetch1InchTokenList = () => async (dispatch: any) => {
  const url = `https://cloudflare-ipfs.com/ipfs/QmV5HMEzd5cEbGUDhUF1XKZ6yRsuPmRF2QySDGa5bjEoeK/`;

  try {
    dispatch(requestTokenList());

    const response = await axios.get(url, {
      transformRequest: (_: any, headers) => {
        delete headers['X-SET-USER'];
      },
      useCache: true,
    });

    const sortedTokens = response?.data?.tokens.sort((a: any, b: any) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });

    const tokenList = {
      '1inch': sortedTokens || [],
    };

    dispatch(receiveTokenList(tokenList));

    return sortedTokens;
  } catch (error) {
    dispatch(receiveTokenList({}));
    toast.warn(
      'Sorry, something went wrong when fetching the token list from 1inch. Please try again later.',
      { toastId: 'fetch-1inch-token-list' },
    );
  }
};

export const fetchGeminiTokenList = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const geminiTokenListUrl = geminiTokenListUrlSelector(state);
  const geminiTokenListKey = geminiTokenListKeySelector(state);

  try {
    dispatch(requestTokenList());

    const response = await axios.get(geminiTokenListUrl, {
      transformRequest: (_: any, headers) => {
        delete headers['X-SET-USER'];
      },
      useCache: true,
    });

    const responseTokens = response?.data?.tokens || [];

    const sortedTokens = responseTokens.sort((a: any, b: any) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });

    const tokenList = {
      gemini: sortedTokens || [],
      [geminiTokenListKey]: sortedTokens || [],
    };

    dispatch(receiveTokenList(tokenList));

    return sortedTokens;
  } catch (error) {
    dispatch(receiveTokenList({}));
    toast.warn(
      'Sorry, something went wrong when fetching the token list from Gemini. Please try again later.',
      { toastId: 'fetch-gemini-token-list' },
    );
  }
};

export const fetchCoingeckoTokenList = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const coingeckoTokenListUrl = coingeckoTokenListUrlSelector(state);
  const coingeckoTokenListKey = coingeckoTokenListKeySelector(state);

  try {
    dispatch(requestTokenList());

    const valueInCache = getTokenListCached(coingeckoTokenListKey);
    if (!isLocalStorageEnabled() || !valueInCache) {
      const response = await axios.get(coingeckoTokenListUrl, {
        transformRequest: (_: any, headers) => {
          delete headers['X-SET-USER'];
        },
        useCache: true,
      });

      const sortedTokensBySymbol = response?.data?.tokens.sort((a: any, b: any) => {
        if (a.symbol > b.symbol) return 1;
        if (a.symbol < b.symbol) return -1;
        return 0;
      });

      const sortedTokensByName = sortedTokensBySymbol?.sort((a: any, b: any) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });

      // Partially replace logo URI route with small images instead of thumbnails
      const withHighResLogos = sortedTokensByName?.map((token: IListToken) => {
        const tokenName = tokenListNameOverride[token?.address.toLowerCase()] || token.name;
        try {
          const currentURI = token.logoURI;
          const newURI = currentURI.replace('thumb', 'small');

          return {
            ...token,
            name: tokenName,
            logoURI: newURI,
          };
        } catch (e) {
          return {
            ...token,
            name: tokenName,
            logoURI:
              'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-token-icon.svg',
          };
        }
      });

      const tokenList = {
        coingecko: withHighResLogos || [],
        [coingeckoTokenListKey]: withHighResLogos || [],
      };

      dispatch(receiveTokenList(tokenList));

      setTokenListCached(coingeckoTokenListKey, withHighResLogos);

      return withHighResLogos || [];
    }
    const tokenList = {
      coingecko: valueInCache,
      [coingeckoTokenListKey]: valueInCache,
    };

    dispatch(receiveTokenList(tokenList));

    return valueInCache;
  } catch (error) {
    toast.warn(
      'Sorry, something went wrong when fetching the token list from Coingecko. Please try again later.',
      { toastId: 'fetch-coingecko-token-list' },
    );
    console.log('could not fetch token list: ', error);

    dispatch(receiveTokenList({}));

    return [];
  }
};

export const fetchTokenSetsTokenList = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const tokenSetsTokenListUrl = tokenSetsTokenListUrlSelector(state);

  try {
    dispatch(requestTokenList());

    const response = await axios.get(tokenSetsTokenListUrl, {
      transformRequest: (_: any, headers) => {
        delete headers['X-SET-USER'];
      },
      useCache: true,
    });

    const tokenSetsList = response?.data?.tokens || ([] as IListToken[]);

    const tokenList = {
      tokenSets: tokenSetsList,
    };

    dispatch(receiveTokenList(tokenList));

    return tokenSetsList;
  } catch (error) {
    toast.warn('Sorry, something went wrong when fetching the TokenSets token list', {
      toastId: 'fetch-tokensets-token-list',
    });
    dispatch(receiveTokenList({}));

    return [];
  }
};

export const fetchFeaturedTokenSetsTokenList = () => async (dispatch: any) => {
  const tokenSetsTokenListUrl = `https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/set.featured.tokenlist.json`;

  try {
    dispatch(requestTokenList());

    const response = await axios.get(tokenSetsTokenListUrl, {
      transformRequest: (_: any, headers) => {
        delete headers['X-SET-USER'];
      },
      useCache: true,
    });

    const tokenSetsList = response?.data?.tokens || ([] as IListToken[]);

    const tokenList = {
      featuredTokenSets: tokenSetsList,
    };

    dispatch(receiveTokenList(tokenList));

    return tokenSetsList;
  } catch (error) {
    toast.warn('Sorry, something went wrong when fetching the featured TokenSets token list', {
      toastId: 'fetch-tokensets-token-list',
    });
    dispatch(receiveTokenList({}));

    return [];
  }
};

export const fetchManagerTokens = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const account = accountSelector(state);
  const apolloClient = apolloClientSelector(state);

  dispatch(requestManagerTokens());

  let graphResponse = undefined;

  if (!account.length) {
    return undefined;
  }

  try {
    graphResponse = await apolloClient.query({
      query: OWNED_AND_OPERATED_SETS_FOR_ACCOUNT_QUERY,
      variables: {
        account: account.toLowerCase(),
      },
    });

    const eoaOwnedSets = graphResponse?.data?.manager?.setTokens || [];
    const delegatedManagerOwnedSets = graphResponse?.data?.owner?.setTokens || [];
    const delegatedManagerOperatedSets = graphResponse?.data?.operator?.setTokens || [];

    const ownedAndOperatedSets = eoaOwnedSets
      .concat(delegatedManagerOwnedSets)
      .concat(delegatedManagerOperatedSets);
    const deduplicatedSets = _.uniqBy(ownedAndOperatedSets, 'id');

    // Since set token addresses are unique, they are used as the "id" field in our
    // subgraph. Potentially remove this logic later.
    const setTokensWithAddressMapping: GraphSetToken[] = deduplicatedSets?.map((token: any) => ({
      ...token,
      address: token.id,
    }));

    dispatch(receiveManagerTokens(setTokensWithAddressMapping));

    return setTokensWithAddressMapping;
  } catch (error) {
    console.log(error);
    toast.warn(
      'Sorry, something went wrong when fetching the list of tokens you own. Please try again later.',
      { toastId: 'fetch-manager-tokens' },
    );
    dispatch(errorManagerTokens(error));
    return undefined;
  }
};
