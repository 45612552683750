import { toast } from 'react-toastify';
import promisify from 'tiny-promisify';
import Web3 from 'web3';
import setupWalletLink from '../utils/walletLink';

import ProviderEngine from 'web3-provider-engine';
import RpcSubprovider from 'web3-provider-engine/subproviders/rpc';

import { EthereumProvider } from '@walletconnect/ethereum-provider'
import { mainnet } from 'wagmi/chains';

import { store } from '../store';
import { updateAccountBalance } from '../actions/balanceActions';
import { initializeSetJS } from '../actions/setJSActions';
import { initializeUniswapRouter, initializeSushiswapRouter } from '../actions/uniswapActions';
import {
  receiveAccount,
  selectProviderType,
  setCurrentNetworkId,
  updateLoginStatus,
  logOut,
  setLedgerPath,
  setCurrentChain,
} from '../actions/web3Actions';
import {
  WEB3_PROVIDERS,
  NETWORK_CONSTANTS,
  LEDGER_PATHS,
  BLOCKEXPLORER_URL,
} from '../constants/index';
import { ILoginData } from '../typings/index';
import { fm, fmProvider, magic, magicProvider } from '../utils/index';
import i18n from '../i18n';
import { ethers } from 'ethers';
import {
  currentChainOrDefaultSelector,
  httpProviderHostSelector,
} from '../selectors/web3Selectors';
import { networkIdSelector } from '../selectors/baseSelectors';
import { BLOCKEXPLORER_NAME } from '../constants/subdomain';
import { infuraDomain, infuraKey } from './providers';
import { setupWalletConnectAsync } from './walletConnect';

const delegatedManagerFactoryABI = require('../constants/abis/delegatedManagerFactoryABI').default;

const {
  UNSUPPORTED_CHAIN,
  POLYGON_CHAIN,
  ETHEREUM_CHAIN,
  ARBITRUM_CHAIN,
  OPTIMISM_CHAIN,
  AVALANCHE_CHAIN,
  ETHEREUM_ENV_NETWORK,
  MAIN_NET_ID,
  KOVAN_ID,
  ROPSTEN_ID,
  POLYGON_MAINNET_ID,
  POLYGON_MUMBAI_ID,
  ARBITRUM_MAINNET_ID,
  ARBITRUM_RINKEBY_ID,
  OPTIMISM_MAINNET_ID,
  OPTIMISM_KOVAN_ID,
  AVALANCHE_MAINNET_ID,
  AVALANCHE_FUJI_ID,
} = NETWORK_CONSTANTS;

export const ledgerEngine = (function () {
  // Instance stores a reference to the Singleton
  const instances: any = {};

  function init(_networkId: string, _ledgerPath: string) {
    // Singleton

    return {
      // Public methods and variables
      configure: function () {
        const engine = new ProviderEngine();

        // TODO: Set up ledger using some other method than @0x/subproviders
        engine.on('error', () => {
          // Do nothing on connection error
        });
        engine.addProvider(
          new RpcSubprovider({
            rpcUrl: `${infuraDomain}${infuraKey}`,
          }),
        );
        engine.start();

        return engine;
      },
    };
  }
  return {
    // Get the Singleton instance if one exists
    // or create one if it doesn't
    getInstance: function (ledgerPath: string, networkId: string) {
      const key = `${networkId}-${ledgerPath}`;
      if (!(key in instances)) {
        instances[key] = init(networkId, ledgerPath).configure();
      } else {
        if (!instances[key].isRunning()) {
          instances[key].start();
        }
      }

      return instances[key];
    },

    stopInstance: function (ledgerPath: string, networkId: string) {
      const key = `${networkId}-${ledgerPath}`;
      if (key in instances && instances[key].isRunning()) {
        instances[key].stop();
      }
    },
  };
})();

export const supportedNetworkIds = () => {
  const networkIds = [];
  networkIds.push(MAIN_NET_ID);
  networkIds.push(ROPSTEN_ID);
  networkIds.push(KOVAN_ID);
  networkIds.push(POLYGON_MAINNET_ID);
  networkIds.push(POLYGON_MUMBAI_ID);
  networkIds.push(OPTIMISM_MAINNET_ID);
  networkIds.push(OPTIMISM_KOVAN_ID);
  networkIds.push(AVALANCHE_MAINNET_ID);
  networkIds.push(AVALANCHE_FUJI_ID);
  networkIds.push(ARBITRUM_MAINNET_ID);
  networkIds.push(ARBITRUM_RINKEBY_ID);

  return networkIds;
};

/**
 * subscribeToProviderUpdates
 * Subscribes to any account or network changes made with provider.
 */
const subscribeToProviderUpdates = (ethereum: any) => {
  if (ethereum && ethereum._events && !ethereum._events.accountsChanged && ethereum.on) {
    const web3Instance = new Web3(ethereum);
    ethereum.on('accountsChanged', (accounts: string[]) => {
      const {
        web3: { account },
      } = store.getState();

      if (accounts && accounts[0] && account) {
        const newAccount = web3Instance.utils.toChecksumAddress(accounts[0]);
        if (account !== newAccount) {
          toast(i18n.t('components:web3.changed-account'));
        }
        store.dispatch(receiveAccount(newAccount));
        store.dispatch(updateAccountBalance());
      } else {
        ethereum.removeAllListeners();
        store.dispatch(logOut());
      }
    });
    ethereum.on('chainChanged', (networkId: string) => {
      if (supportedNetworkIds().includes(networkId)) {
        if (networkId === POLYGON_MAINNET_ID || networkId === POLYGON_MUMBAI_ID) {
          store.dispatch(setCurrentChain(POLYGON_CHAIN));
        } else if (networkId === ARBITRUM_MAINNET_ID || networkId === ARBITRUM_RINKEBY_ID) {
          store.dispatch(setCurrentChain(ARBITRUM_CHAIN));
        } else if (networkId === OPTIMISM_MAINNET_ID || networkId === OPTIMISM_KOVAN_ID) {
          store.dispatch(setCurrentChain(OPTIMISM_CHAIN));
        } else if (networkId === AVALANCHE_MAINNET_ID || networkId === AVALANCHE_FUJI_ID) {
          store.dispatch(setCurrentChain(AVALANCHE_CHAIN));
        } else {
          store.dispatch(setCurrentChain(ETHEREUM_CHAIN));
        }
      } else {
        store.dispatch(setCurrentChain(UNSUPPORTED_CHAIN));
      }
      window.location.reload();
    });
    ethereum.on('networkChanged', (networkId: string) => {
      if (supportedNetworkIds().includes(networkId)) {
        if (networkId === POLYGON_MAINNET_ID || networkId === POLYGON_MUMBAI_ID) {
          store.dispatch(setCurrentChain(POLYGON_CHAIN));
        } else if (networkId === ARBITRUM_MAINNET_ID || networkId === ARBITRUM_RINKEBY_ID) {
          store.dispatch(setCurrentChain(ARBITRUM_CHAIN));
        } else if (networkId === OPTIMISM_MAINNET_ID || networkId === OPTIMISM_KOVAN_ID) {
          store.dispatch(setCurrentChain(OPTIMISM_CHAIN));
        } else if (networkId === AVALANCHE_MAINNET_ID || networkId === AVALANCHE_FUJI_ID) {
          store.dispatch(setCurrentChain(AVALANCHE_CHAIN));
        } else {
          store.dispatch(setCurrentChain(ETHEREUM_CHAIN));
        }
      } else {
        store.dispatch(setCurrentChain(UNSUPPORTED_CHAIN));
      }
      window.location.reload();
    });
  }
};

/**
 * initDefaultProvider
 * Initializes web3 with the default login provider (Infura).
 * This provider is used whenever the user is logged out.
 */
export const initDefaultProvider = () => {
  const state = store.getState();
  const httpProviderHost = httpProviderHostSelector(state);

  try {
    const provider = new Web3.providers.HttpProvider(httpProviderHost);
    const providerLegacy = new Web3.providers.HttpProvider(httpProviderHost);
    const web3Instance = new Web3(providerLegacy);

    store.dispatch(initializeSetJS(provider as any));
    store.dispatch(initializeUniswapRouter(web3Instance));
    store.dispatch(initializeSushiswapRouter(web3Instance));
  } catch (e) {
    console.log('failed to initialize default provider', e);
  }
};

const initWeb3Wallet = (
  accounts: string[],
  web3Instance: any,
  networkVersion = MAIN_NET_ID,
  providerType: string,
) => {
  const account = web3Instance.utils.toChecksumAddress(accounts[0]);
  if (supportedNetworkIds().includes(networkVersion)) {
    if (networkVersion === POLYGON_MAINNET_ID || networkVersion === POLYGON_MUMBAI_ID) {
      store.dispatch(setCurrentChain(POLYGON_CHAIN));
    } else if (networkVersion === ARBITRUM_MAINNET_ID || networkVersion === ARBITRUM_RINKEBY_ID) {
      store.dispatch(setCurrentChain(ARBITRUM_CHAIN));
    } else if (networkVersion === OPTIMISM_MAINNET_ID || networkVersion === OPTIMISM_KOVAN_ID) {
      store.dispatch(setCurrentChain(OPTIMISM_CHAIN));
    } else if (networkVersion === AVALANCHE_MAINNET_ID || networkVersion === AVALANCHE_FUJI_ID) {
      store.dispatch(setCurrentChain(AVALANCHE_CHAIN));
    } else {
      store.dispatch(setCurrentChain(ETHEREUM_CHAIN));
    }
  } else {
    store.dispatch(setCurrentChain(UNSUPPORTED_CHAIN));
  }

  store.dispatch(receiveAccount(account));
  store.dispatch(selectProviderType(providerType));
  store.dispatch(setCurrentNetworkId(networkVersion));
  store.dispatch(updateLoginStatus(true));
  store.dispatch(updateAccountBalance());
  store.dispatch(initializeSetJS(web3Instance.currentProvider as any));
  store.dispatch(initializeUniswapRouter(web3Instance));
  store.dispatch(initializeSushiswapRouter(web3Instance));
  toast(`🎉 ${i18n.t('components:web3.login-success')}`);
};

/**
 * initWithWeb3CurrentProvider
 * Initializes web3 and gets user's account using provider type.
 */
const initWithWeb3CurrentProvider = async (providerType: string): Promise<ILoginData> => {
  const { web3 } = window;
  const web3Instance = new Web3(web3.currentProvider);
  const loginData: ILoginData = {
    account: '',
    loggedIn: false,
    networkId: web3.currentProvider.networkVersion,
    providerType,
  };

  try {
    const accounts: string[] = await promisify(web3Instance.eth.getAccounts)();

    if (accounts && accounts.length > 0) {
      initWeb3Wallet(accounts, web3Instance, web3.currentProvider.networkVersion, providerType);
      loginData.account = accounts[0];
      loginData.loggedIn = true;
      return loginData;
    }
    return loginData;
  } catch (error) {
    // User denied account access
    return loginData;
  }
};

/**
 * initWithLedger
 * Initializes web3 using Ledger
 */
const initWithLedger = async (path = LEDGER_PATHS.DEFAULT_PATH): Promise<ILoginData> => {
  const loginData: ILoginData = {
    account: '',
    loggedIn: false,
    networkId: MAIN_NET_ID,
    providerType: WEB3_PROVIDERS.LEDGER,
  };

  try {
    const engine = ledgerEngine.getInstance(path, loginData.networkId);
    const web3Instance = new Web3(engine);

    const accounts = await web3Instance.eth.getAccounts();

    if (accounts && accounts.length > 0) {
      initWeb3Wallet(accounts, web3Instance, loginData.networkId, WEB3_PROVIDERS.LEDGER);
      store.dispatch(setLedgerPath(path));
      loginData.account = accounts[0];
      loginData.loggedIn = true;
    }
    return loginData;
  } catch (err) {
    // Some error occurred potentially on Ledger device side
    return loginData;
  }
};

/**
 * getEthereumAccounts
 * Grabs ethereum accounts depending on whether it uses ethereum.send or normal ethereum.enable
 */
const getEthereumAccounts = async (providerType: string): Promise<string[]> => {
  const { ethereum } = window;
  const web3Instance = new Web3(ethereum as any);
  let accounts: string[];

  if (providerType === WEB3_PROVIDERS.STATUS_WALLET) {
    // accounts = await ethereum.send('eth_requestAccounts');
  } else {
    /**
     * Metamask login
     * Metamask pulls the document.title and uses that as the app's name in Metamask's initialization script.
     */
    const oldTitle = document.title;
    document.title = 'TokenSets';
    accounts = await ethereum.enable();
    document.title = oldTitle;
  }

  if (!accounts || accounts.length === 0) {
    accounts = await web3Instance.eth.getAccounts();
  }
  return accounts;
};

/**
 * initWithEthereum
 * Initializes web3 using the injected ethereum object. Used for Metamask, ImToken, and TrustWallet.
 */
const initWithEthereum = async (providerType: string): Promise<ILoginData> => {
  const { ethereum } = window;
  const web3Instance = new Web3(ethereum as any);
  const loginData: ILoginData = {
    account: '',
    loggedIn: false,
    networkId: '1', //ethereum.networkVersion,
    providerType,
  };

  try {
    const accounts = await getEthereumAccounts(providerType);

    if (accounts && accounts.length > 0) {
      initWeb3Wallet(accounts, web3Instance, (ethereum as any).networkVersion, providerType);
      if (providerType !== WEB3_PROVIDERS.IMTOKEN) {
        // Subscribe to account and network changes.
        subscribeToProviderUpdates(ethereum);
      }
      loginData.account = accounts[0];
      loginData.loggedIn = true;
      return loginData;
    }
    return loginData;
  } catch (error) {
    // User denied account access
    return loginData;
  }
};

/**
 * initFortmatic
 * Initializes web3 and logs user in using Fortmatic.
 */
const initFortmatic = async (providerType: string, email?: string): Promise<ILoginData> => {
  const loginData: ILoginData = {
    account: '',
    loggedIn: false,
    networkId: MAIN_NET_ID,
    providerType,
  };

  try {
    let accounts, web3Instance;
    if (providerType === WEB3_PROVIDERS.FORTMATIC_EMAIL) {
      web3Instance = new Web3(magicProvider as any);
      const isLoggedIn = await magic.user.isLoggedIn();
      if (isLoggedIn) {
        const account = (await magic.user.getMetadata()).publicAddress;
        accounts = [account];
      } else {
        await magic.auth.loginWithMagicLink({ email });
        const account = (await magic.user.getMetadata()).publicAddress;
        accounts = [account];
      }
    } else {
      await fm.configure({ primaryLoginOption: 'phone' });
      await fm.user.login();
      web3Instance = new Web3(fmProvider);
      accounts = await promisify(web3Instance.eth.getAccounts)();
    }

    if (accounts && accounts.length > 0) {
      initWeb3Wallet(
        accounts,
        web3Instance,
        undefined, // Fortmatic doesn't give a network ID
        providerType,
      );
      loginData.account = accounts[0];
      loginData.loggedIn = true;
      return loginData;
    }
    console.log('Error retrieving accounts');
    return loginData;
  } catch (error) {
    // User denied account access
    return loginData;
  }
};

/**
 * initWalletLink
 * Initializes web3 and logs user in using Wallet Link.
 * Used for logging in with Coinbase Wallet.
 */
const initWalletLink = async (): Promise<ILoginData> => {
  const networkId = ETHEREUM_ENV_NETWORK;
  const loginData: ILoginData = {
    account: '',
    loggedIn: false,
    networkId,
    providerType: WEB3_PROVIDERS.INFURA,
  };

  try {
    const { provider, web3Instance } = setupWalletLink();

    const accounts = await provider.send('eth_requestAccounts');

    if (accounts && accounts.length > 0) {
      initWeb3Wallet(accounts, web3Instance, networkId, WEB3_PROVIDERS.INFURA);
      loginData.account = accounts[0];
      loginData.loggedIn = true;
      return loginData;
    }
    console.log('Error retrieving accounts');
    return loginData;
  } catch (error) {
    // User denied account access
    return loginData;
  }
};

/**
 * initWalletConnect
 * Initializes web3 and logs user in using WalletConnect.
 * Used for logging in with WalletConnect wallets.
 */
const initWalletConnect = async (): Promise<ILoginData> => {
  const networkId = ETHEREUM_ENV_NETWORK;
  const loginData: ILoginData = {
    account: '',
    loggedIn: false,
    networkId,
    providerType: WEB3_PROVIDERS.INFURA,
  };

  try {
    const chains = [mainnet];
    const projectId = '2262d12a95e916dbeb4c378b6c67157b'; // TokenSets WalletConnect Project ID

    const provider = await EthereumProvider.init({
      projectId, // REQUIRED your projectId
      chains: chains.map(c => c.id), // REQUIRED chain ids
      showQrModal: true, // REQUIRED set to "true" to use @web3modal/standalone,
      methods: ['eth_sendTransaction', 'eth_signTransaction'], // OPTIONAL ethereum methods
      events: ['accountsChanged', 'chainChanged'], // OPTIONAL ethereum events
      rpcMap: {
        1: `https://mainnet.infura.io/v3/${infuraKey}`,
      },
    });
    await provider.enable();

    const web3Instance = new Web3(provider as any);

    const accounts: string[] = provider.accounts;

    if (accounts && accounts.length > 0) {
      initWeb3Wallet(accounts, web3Instance, networkId, WEB3_PROVIDERS.WALLET_CONNECT);
      loginData.account = accounts[0];
      loginData.loggedIn = true;

      provider.on('disconnect', () => {
        store.dispatch(logOut());
      });

      return loginData;
    }

    return loginData;
  } catch (error) {
    console.log(error);
    // User denied account access
    return loginData;
  }
};

/**
 * getWeb3
 * Initializes web3 based on provider
 */
export const getWeb3 = async (providerType?: string, email?: string) => {
  const { ethereum, web3 } = window;
  const {
    web3: { ledgerPath },
  } = store.getState();
  // Modern dapp browsers with opt out of Fortmatic
  switch (providerType) {
    case WEB3_PROVIDERS.METAMASK:
      if (ethereum) {
        return await initWithEthereum(providerType);
      }
      break;
    case WEB3_PROVIDERS.LEDGER:
      return await initWithLedger(ledgerPath);
    case WEB3_PROVIDERS.IMTOKEN:
      if (ethereum) {
        return await initWithEthereum(providerType);
      } else if (web3) {
        return await initWithWeb3CurrentProvider(providerType);
      }
      break;
    case WEB3_PROVIDERS.OPERA:
      if (ethereum) {
        return await initWithEthereum(providerType);
      }
      break;
    case WEB3_PROVIDERS.TRUST_WALLET:
      if (web3) {
        return await initWithWeb3CurrentProvider(providerType);
      }
      break;
    case WEB3_PROVIDERS.COINBASE_WALLET:
      if (web3) {
        return await initWithWeb3CurrentProvider(providerType);
      }
      break;
    case WEB3_PROVIDERS.INFURA:
      return await initWalletLink();
    case WEB3_PROVIDERS.WALLET_CONNECT:
      return await initWalletConnect();
    case WEB3_PROVIDERS.FORTMATIC_PHONE:
      return await initFortmatic(WEB3_PROVIDERS.FORTMATIC_PHONE);
      break;
    case WEB3_PROVIDERS.FORTMATIC_EMAIL:
      return await initFortmatic(WEB3_PROVIDERS.FORTMATIC_EMAIL, email);
      break;
    case WEB3_PROVIDERS.STATUS_WALLET:
      if (ethereum) {
        return await initWithEthereum(providerType);
      }
      break;
    case WEB3_PROVIDERS.ALPHA_WALLET:
      if (ethereum) {
        return await initWithEthereum(providerType);
      }
      break;
    case WEB3_PROVIDERS.MOBILE_WEB3_WALLET:
      if (web3) {
        return await initWithWeb3CurrentProvider(providerType);
      } else if (ethereum) {
        return await initWithEthereum(providerType);
      }
      break;
    default:
      return await initFortmatic(WEB3_PROVIDERS.FORTMATIC_EMAIL);
      break;
  }
};

/**
 * Detects if user is using Opera and if ethereum is being injected into the browser.
 */
export const detectOperaWallet = (): boolean => {
  const isUsingOpera =
    (!!window.opr && !!window.opr.addons) ||
    !!window.opera ||
    navigator.userAgent.indexOf(' OPR/') >= 0 || // Opera version 15 and beyond
    navigator.userAgent.indexOf(' Opera/') >= 0 || // Opera versions below version 15
    navigator.userAgent.indexOf(' Opera Mobi/') >= 0 || // Opera mobile
    navigator.userAgent.indexOf(' OPT/') >= 0; // Opera Touch for mobile
  const hasOperaWallet = isUsingOpera && !!window.ethereum;
  return hasOperaWallet;
};

export const detectWalletType = () => {
  const {
    windowDimension: { isMobile },
    web3: { currentChain },
  } = store.getState();

  const isL1Ethereum = currentChain === ETHEREUM_CHAIN;

  const metaMaskEnabled = window.ethereum && !!(window.ethereum as any).isMetaMask;
  const imTokenEnabled =
    isL1Ethereum && (!!window.imToken || (window.ethereum && !!(window.ethereum as any).isImToken));
  const coinbaseWalletEnabled =
    isL1Ethereum &&
    window.web3 &&
    window.web3.currentProvider &&
    (!!window.web3.currentProvider.isToshi || !!window.web3.currentProvider.isCipher);
  const trustWalletEnabled =
    isL1Ethereum &&
    window.web3 &&
    window.web3.currentProvider &&
    !!window.web3.currentProvider.isTrust;
  const statusWalletEnabled =
    isL1Ethereum && window.ethereum && !!(window.ethereum as any).isStatus;
  const alphaWalletEnabled =
    isL1Ethereum &&
    window.web3 &&
    window.web3.currentProvider &&
    !!window.web3.currentProvider.isAlphaWallet;
  // Opera 8.0+ + window.ethereum check
  const operaWalletEnabled = isL1Ethereum && detectOperaWallet();
  const isMobileWallet = isL1Ethereum && isMobile && (window.web3 || window.ethereum);

  return {
    metaMaskEnabled,
    imTokenEnabled,
    coinbaseWalletEnabled,
    trustWalletEnabled,
    statusWalletEnabled,
    alphaWalletEnabled,
    operaWalletEnabled,
    isMobileWallet,
  };
};

/**
 * Logs user into Web3
 */
export const login = async (): Promise<ILoginData> => {
  const enabledWallets = detectWalletType();

  if (enabledWallets.imTokenEnabled) {
    return await getWeb3(WEB3_PROVIDERS.IMTOKEN);
  } else if (enabledWallets.coinbaseWalletEnabled) {
    return await getWeb3(WEB3_PROVIDERS.COINBASE_WALLET);
  } else if (enabledWallets.trustWalletEnabled) {
    return await getWeb3(WEB3_PROVIDERS.TRUST_WALLET);
  } else if (enabledWallets.metaMaskEnabled) {
    return await getWeb3(WEB3_PROVIDERS.METAMASK);
  } else if (enabledWallets.operaWalletEnabled) {
    return await getWeb3(WEB3_PROVIDERS.OPERA);
  } else if (enabledWallets.statusWalletEnabled) {
    return await getWeb3(WEB3_PROVIDERS.STATUS_WALLET);
  } else if (enabledWallets.alphaWalletEnabled) {
    return await getWeb3(WEB3_PROVIDERS.ALPHA_WALLET);
  } else if (enabledWallets.isMobileWallet) {
    return await getWeb3(WEB3_PROVIDERS.MOBILE_WEB3_WALLET);
  }
  return await getWeb3(WEB3_PROVIDERS.FORTMATIC_EMAIL);
};

/**
 * getNetworkId
 * Gets the network id that the user is on.
 */
export const getNetworkId = async (web3Instance: Web3) => {
  if (!web3Instance || !web3Instance.eth) return -1;

  return promisify(web3Instance.eth.net.getId)();
};

/**
 * getAccount
 * Gets the user's account string
 */
export const getAccount = async (web3Instance: Web3) => {
  if (!web3Instance) {
    return '';
  }
  const accounts = await promisify(web3Instance.eth.getAccounts)();
  if (!accounts || accounts.length === 0) {
    return '';
  }

  return accounts[0];
};

/**
 * Generates a signature for the passed in message.
 * Throws if signing is unsuccessful.
 * @param web3Instance - Web 3 Instance. Usually provided via redux store.
 * @param message - The message to be signed.
 * @param account - The account address used to generate the signature.
 */
export const getMessageSignature = async (web3Instance: any, message: string, account: string) => {
  if (!account || account.length === 0) {
    toast(i18n.t('components:web3.create-signature-failed'));
    throw new Error('No Account. Unable to generate message signature.');
  }
  const web3Message = web3Instance.utils.fromUtf8(message);

  // Have to cast web3Instance to 'any' because the @types/web3 improperly sets no params to `sign` method
  return await web3Instance.eth.personal.sign(web3Message, account);
};

export const urlIntendedChain = () => {
  const isPolygonUrl =
    window.location.hash.includes('/v2/set/polygon') ||
    window.location.hash.includes('/portfolio/polygon') ||
    window.location.hash.includes('/set/polygon') ||
    window.location.hash.includes('polygon');
  const isArbitrumUrl =
    window.location.hash.includes('/v2/set/arbitrum') ||
    window.location.hash.includes('/portfolio/arbitrum') ||
    window.location.hash.includes('/set/arbitrum') ||
    window.location.hash.includes('arbitrum');
  const isOptimismUrl =
    window.location.hash.includes('/v2/set/optimism') ||
    window.location.hash.includes('/portfolio/optimism') ||
    window.location.hash.includes('/set/optimism') ||
    window.location.hash.includes('optimism');
  const isAvalancheUrl =
    window.location.hash.includes('/v2/set/avalanche') ||
    window.location.hash.includes('/portfolio/avalanche') ||
    window.location.hash.includes('/set/avalanche') ||
    window.location.hash.includes('avalanche');

  if (isPolygonUrl) {
    return NETWORK_CONSTANTS.POLYGON_CHAIN;
  } else if (isArbitrumUrl) {
    return NETWORK_CONSTANTS.ARBITRUM_CHAIN;
  } else if (isOptimismUrl) {
    return NETWORK_CONSTANTS.OPTIMISM_CHAIN;
  } else if (isAvalancheUrl) {
    return NETWORK_CONSTANTS.AVALANCHE_CHAIN;
  } else {
    return NETWORK_CONSTANTS.ETHEREUM_CHAIN;
  }
};

export const defaultNetworkForChain = (chainName: string) => {
  switch (chainName) {
    case NETWORK_CONSTANTS.POLYGON_CHAIN:
      return POLYGON_MAINNET_ID;
    case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
      return ARBITRUM_MAINNET_ID;
    case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
      return OPTIMISM_MAINNET_ID;
    case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
      return AVALANCHE_MAINNET_ID;
    default:
      return MAIN_NET_ID;
  }
};

/**
 * getWeb3Instance
 * Returns an instance of web3 based on what the providerType is
 * If `useDefault` is passed in as true, then we use a default infura
 * provider to initialize a web3 instance. We don't do this all the time
 * because there are a lot more entry points in code where the web3 instance
 * is checked to see if the user is logged in (ideally we don't do that but
 * it would have been a much bigger change if I had to check and change behavior
 * everywhere in the code)
 */
export const getWeb3Instance = async (useDefault = false) => {
  const state = store.getState();
  const {
    web3: { providerType },
  } = state;
  const { ethereum, web3 } = (window as unknown) as { ethereum: any; web3: any };

  let web3Instance = undefined;
  if (
    (providerType === WEB3_PROVIDERS.METAMASK || providerType === WEB3_PROVIDERS.IMTOKEN) &&
    ethereum
  ) {
    web3Instance = new Web3(ethereum as any);
    if (supportedNetworkIds().includes(ethereum.networkVersion)) {
      if (
        ethereum.networkVersion === POLYGON_MAINNET_ID ||
        ethereum.networkVersion === POLYGON_MUMBAI_ID
      ) {
        store.dispatch(setCurrentChain(POLYGON_CHAIN));
      } else if (
        ethereum.networkVersion === ARBITRUM_MAINNET_ID ||
        ethereum.networkVersion === ARBITRUM_RINKEBY_ID
      ) {
        store.dispatch(setCurrentChain(ARBITRUM_CHAIN));
      } else if (
        ethereum.networkVersion === OPTIMISM_MAINNET_ID ||
        ethereum.networkVersion === OPTIMISM_KOVAN_ID
      ) {
        store.dispatch(setCurrentChain(OPTIMISM_CHAIN));
      } else if (
        ethereum.networkVersion === AVALANCHE_MAINNET_ID ||
        ethereum.networkVersion === AVALANCHE_FUJI_ID
      ) {
        store.dispatch(setCurrentChain(AVALANCHE_CHAIN));
      } else {
        store.dispatch(setCurrentChain(ETHEREUM_CHAIN));
      }
    } else {
      store.dispatch(setCurrentChain(UNSUPPORTED_CHAIN));
    }
    store.dispatch(setCurrentNetworkId(ethereum.networkVersion));
    subscribeToProviderUpdates(ethereum);
  } else if (
    (providerType === WEB3_PROVIDERS.COINBASE_WALLET ||
      providerType === WEB3_PROVIDERS.TRUST_WALLET) &&
    web3
  ) {
    web3Instance = new Web3(web3.currentProvider);
  } else if (providerType === WEB3_PROVIDERS.OPERA) {
    web3Instance = new Web3(ethereum);
    store.dispatch(setCurrentNetworkId('1'));
  } else if (providerType === WEB3_PROVIDERS.FORTMATIC_PHONE) {
    web3Instance = new Web3(fmProvider);
    // Users using Fortmatic will be on MainNet due to env variable
    store.dispatch(setCurrentNetworkId('1'));
  } else if (providerType === WEB3_PROVIDERS.FORTMATIC_EMAIL) {
    web3Instance = new Web3(magicProvider as any);
    store.dispatch(setCurrentNetworkId('1'));
  } else if (providerType === WEB3_PROVIDERS.INFURA) {
    web3Instance = setupWalletLink().web3Instance;
  } else if (providerType === WEB3_PROVIDERS.WALLET_CONNECT) {
    web3Instance = (await setupWalletConnectAsync()).web3Instance;
  }

  if (web3Instance) {
    store.dispatch(initializeSetJS(web3Instance.currentProvider as any));
    store.dispatch(initializeUniswapRouter(web3Instance));
    store.dispatch(initializeSushiswapRouter(web3Instance));
  }

  // After the initialization of setJS because we don't want setJS
  // to use a default provider, it needs an account
  if (!web3Instance && useDefault) {
    const httpProviderHost = httpProviderHostSelector(state);
    const provider = new Web3.providers.HttpProvider(httpProviderHost);
    web3Instance = new Web3(provider);
  }

  return web3Instance;
};

/**
 * getLedgerWeb3
 * Returns an instance of a Ledger-enabled Web3 instance
 */
export const getLedgerWeb3 = (ledgerPath: string) => {
  const networkId = MAIN_NET_ID;

  const engine = ledgerEngine.getInstance(ledgerPath, networkId);
  return new Web3(engine);
};

/**
 * Returns true if the error indicates the user rejected a metamask transaction request
 * @param error - Error object, ideally from set.js/metamask
 */
export const userRejectedMetamaskTransaction = (error: any): boolean => {
  if (
    error &&
    typeof error === 'object' &&
    (error.message.includes('User denied transaction') ||
      error.code === -32603 ||
      error.message.includes('Internal JSON-RPC error'))
  ) {
    return true;
  }
  return false;
};

/**
 * Returns true if error indicates user is on the wrong network
 * @param error - Error object from set.js/metamask
 */
export const userIsOnWrongNetwork = (error: any) => {
  if (
    error &&
    typeof error === 'object' &&
    error.message.includes('Unable to find address for contract') &&
    error.message.includes('on network with id')
  ) {
    return true;
  }
  return false;
};

/**
 * Returns true if the error indicates the transaction is still awaiting confirmation past timeout.
 * Returns false otherwise.
 * @param error - Error object
 */
export const transactionStillAwaitingConfirmation = (error: any): boolean => {
  if (error && typeof error === 'object' && error.message.includes('Timeout has been exceeded')) {
    return true;
  }
  return false;
};

/**
 * Returns true if the transaction mines, returns false if the transaction times out.
 * Throws for any other error
 * @param txId - The transaction id awaiting confirmation
 * @param setJSInstance - An instance of set.js
 */
export const confirmTransactionMined = async (
  txId: string,
  setJSInstance: any,
  waitTime = 20000,
): Promise<boolean> => {
  try {
    const receipt = await setJSInstance.blockchain.awaitTransactionMinedAsync(
      txId,
      undefined,
      waitTime,
    );

    if (!receipt.status) throw new Error('Transaction was mined but failed or reverted.');

    return true;
  } catch (e) {
    if (transactionStillAwaitingConfirmation(e)) {
      return false;
    }

    // If an unexpected error occurs, pass it on
    throw e;
  }
};

/**
 * Returns block number for a mined transaction.
 * @param txId - The transaction id awaiting confirmation
 * @param setJSInstance - An instance of set.js
 */
export const getTransactionBlockNumber = async (
  txId: string,
  setJSInstance: any,
  waitTime = 20000,
): Promise<number | null> => {
  try {
    const receipt = await setJSInstance.blockchain.awaitTransactionMinedAsync(
      txId,
      undefined,
      waitTime,
    );

    return receipt?.blockNumber || null;
  } catch (e) {
    return null;
  }
};

export const makeBlockExplorerLink = (transactionHash: string) => {
  const state = store.getState();
  const currentChain = currentChainOrDefaultSelector(state);
  const defaultNetworkIdForChain = defaultNetworkForChain(currentChain);

  return `${BLOCKEXPLORER_URL[defaultNetworkIdForChain]}/tx/${transactionHash}`;
};

export const makeBlockExplorerContractLink = (contractAddress: string) => {
  const state = store.getState();
  const currentChain = currentChainOrDefaultSelector(state);
  const defaultNetworkIdForChain = defaultNetworkForChain(currentChain);

  return `${BLOCKEXPLORER_URL[defaultNetworkIdForChain]}/address/${contractAddress}`;
};

export const blockExplorerName = () => {
  const state = store.getState();
  const networkId = networkIdSelector(state);

  return BLOCKEXPLORER_NAME[networkId] || BLOCKEXPLORER_NAME[MAIN_NET_ID];
};

export const makeBlockExplorerContractLinkForNetwork = (
  contractAddress: string,
  networkId: string,
) => {
  const domain = BLOCKEXPLORER_URL[networkId] || BLOCKEXPLORER_URL[MAIN_NET_ID];

  return `${domain}/address/${contractAddress}`;
};

// NOTE: if multiple sets are created in the same block, this will return the same value
export const getCreatedSetTokenAddress = async (
  fromBlockNumber: number,
  toBlockNumber: number,
): Promise<string> => {
  const web3Instance = await getWeb3Instance();
  const ethersWeb3Instance = new ethers.providers.Web3Provider(
    web3Instance.currentProvider as any,
    'any',
  );

  const abi = [
    'event SetTokenCreated(address indexed _setToken, address _manager, string _name, string _symbol)',
  ];
  const iface = new ethers.utils.Interface(abi);
  const topic = ethers.utils.id('SetTokenCreated(address,address,string,string)');

  const logs = await ethersWeb3Instance.getLogs({
    fromBlock: fromBlockNumber || 'latest',
    toBlock: toBlockNumber || 'latest',
    topics: [topic],
  });

  const parsed = iface.parseLog(logs[logs.length - 1]);
  return parsed.args._setToken;
};

// NOTE: if multiple sets are created in the same block, this will return the same value
// TODO: add filtering by set deployer address (logged in user)
export const getDeployedManagerContract = async (
  fromBlockNumber?: number,
  toBlockNumber?: number,
): Promise<{ setTokenAddress: string; managerContractAddress: string }> => {
  const web3Instance = await getWeb3Instance();
  const ethersWeb3Instance = new ethers.providers.Web3Provider(
    web3Instance.currentProvider as any,
    'any',
  );

  const abi = delegatedManagerFactoryABI;
  const iface = new ethers.utils.Interface(abi);
  const topic = ethers.utils.id('DelegatedManagerCreated(address,address,address)');

  const logs = await ethersWeb3Instance.getLogs({
    fromBlock: fromBlockNumber || 'latest',
    toBlock: toBlockNumber || 'latest',
    topics: [topic],
  });

  const parsed = iface.parseLog(logs[logs.length - 1]);
  return {
    setTokenAddress: parsed.args._setToken,
    managerContractAddress: parsed.args._manager,
  };
};

export const checkIsManagerPendingInitialization = async (
  setTokenAddress: string,
  factoryAddress: string,
) => {
  const web3Instance = await getWeb3Instance();
  const ethersWeb3Instance = new ethers.providers.Web3Provider(
    web3Instance.currentProvider as any,
    'any',
  );

  const factoryAbi = delegatedManagerFactoryABI;
  const factoryContract = new ethers.Contract(factoryAddress, factoryAbi, ethersWeb3Instance);
  const initializationState = await factoryContract.initializeState(setTokenAddress);

  return initializationState && initializationState.isPending;
};
