import { StyleSheet } from 'aphrodite';
import { WINDOW_DIMENSIONS } from '../../constants/index';
import { COLORS } from '../../constants/index';

const { TABLET_LANDSCAPE_MEDIA_QUERY, MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

export default StyleSheet.create({
  FeaturedSetCard_card: {
    maxHeight: '320px',
    minHeight: '320px',
    height: '320px',
    position: 'relative',
    width: '350px',
    maxWidth: '350px',
    cursor: 'pointer',
    background: COLORS.white,
    [MOBILE_MEDIA_QUERY]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  FeaturedSetCard_icon: {
    height: '17px',
    width: '22px',
    marginLeft: '24px',
    marginRight: '14px',
  },
  FeaturedSetCard_categoryHeader: {
    color: COLORS.blue,
    width: 'calc(100% + 60px)',
    background: COLORS.blueGradient,
    display: 'flex',
    fontWeight: 'bold',
    height: '52px',
    marginTop: '-20px',
    marginLeft: '-30px',
    marginBottom: '24px',
    alignItems: 'center',
  },
  FeaturedSetCard_methodologistCard: {
    minHeight: '345px',
    maxHeight: '345px',
    height: '345px',
    position: 'relative',
    maxWidth: '350px',
    cursor: 'pointer',
    background: COLORS.white,
    [MOBILE_MEDIA_QUERY]: {
      minHeight: '330px',
      maxHeight: '330px',
      width: '100%',
      maxWidth: '100%',
    },
  },
  FeaturedSetCard_container: {
    padding: '20px 30px 8px 30px',
    position: 'relative',
    height: '100%',
    border: `1px solid ${COLORS.lightGray3}`,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
  },
  FeaturedSetCard_cell: {
    display: 'flex',
    width: '100%',
    border: 'none',
    marginBottom: '10px',
    marginTop: '10px',
  },
  FeaturedSetCard_operatorCell: {
    display: 'flex',
    bottom: '0px',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    width: '100%',
    marginLeft: '-30px',
    padding: '0px 30px',
    borderTop: `1px solid ${COLORS.lightGray3}`,
  },
  FeaturedSetCard_setIcon: {
    width: '45px',
    height: '45px',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      width: '40px',
      height: '40px',
    },
  },
  FeaturedSetCard_operatorIcon: {
    width: '30px',
    height: '30px',
    borderRadius: '100%',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      width: '30px',
      height: '30px',
    },
  },
  FeaturedSetCard_setName: {
    lineHeight: '1.5rem',
    maxHeight: '3rem',
    overflow: 'hidden',
  },
  FeaturedSetCard_description: {
    color: COLORS.darkGray,
    fontSize: '14px',
    overflow: 'hidden',
    lineHeight: '1.4rem',
    maxHeight: '6rem',
    fontWeight: 'normal',
    padding: '7px 0',
  },
  FeaturedSetCard_iconContainer: {
    marginRight: '15px',
    marginBottom: '10px',
  },
  FeaturedSetCard_nameAndBadgeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  FeaturedSetCard_setBadgeList: {
    marginTop: '5px',
  },
  FeaturedSetCard_setComponentAllocation: {
    width: '100%',
    marginBottom: '30px',
  },
  FeaturedSetCard_operatorDetails: {
    minHeight: '50px',
    display: 'flex',
    alignItems: 'center',
  },
  FeaturedSetCard_operatorName: {
    marginLeft: '20px',
    fontWeight: 500,
    color: COLORS.darkBlue,
  },

  // Placeholder Shimmers
  FeaturedSetCard_setIconShimmer: {
    width: '45px',
    height: '45px',
    borderRadius: '100%',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      width: '40px',
      height: '40px',
    },
  },
});
