export const tokenListNameOverride: { [tokenAddress: string]: string } = {
  //
  // Ethereum
  //

  //
  // Generic ERC20s
  //
  '0xe41d2489571d322189246dafa5ebde1f4699f498': '0x',
  '0x111111111117dc0aa78b770fa6a738034120c302': '1inch',
  '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9': 'Aave',
  '0x3f382dbd960e3a9bbceae22651e88158d2791550': 'Aavegotchi',
  '0x00a8b738e453ffd858a7edf03bccfe20412f0eb0': 'Alliance Block',
  '0x67b66c99d3eb37fa76aa3ed1ff33e8e39f0b9c7a': 'Alpha Finance ibETH',
  '0x1337def16f9b486faed0293eb623dc8395dfe46a': 'Armor',
  '0xdacd69347de42babfaecd09dc88958378780fb62': 'Atari',
  '0x221657776846890989a759ba2973e427dff5c9bb': 'Augur V2',
  '0xf5d669627376ebd411e34b98f19c868c8aba5ada': 'Axie Infinity',
  '0xba11d00c5f74255f56a5e366f4f77f5a186d7f55': 'Band',
  '0x0391d2021f89dc339f60fff84546ea23e337750f': 'BarnBridge',
  '0x514910771af9ca656af840dff83e8264ecf986ca': 'Chainlink',
  '0x0000000000004946c0e9f43f4dee607b0ef1fa1c': 'Chi Gas Token',
  '0x88930072f583936f506ce1f1d5fe69290c2d6a2a': 'Civitas',
  '0x4688a8b1f292fdab17e9a90c8bc379dc1dbd8713': 'Cover',
  '0xd533a949740bb3306d119cc777fa900ba034cd52': 'Curve',
  '0x6b175474e89094c44da98b954eedeac495271d0f': 'Dai',
  '0x9469d013805bffb7d3debe5e7839237e535ec483': 'Darwinia Network',
  '0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b': 'DeFi Pulse Index',
  '0x84ca8bc7997272c7cfb4d0cd3d55cd942b3c9419': 'DIA Token',
  '0x0a913bead80f321e7ac35285ee10d9d922659cb7': 'DOS Network',
  '0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c': 'Enjin',
  '0xeeeeeeeee2af8d0e1940679860398308e0ef24d6': 'Ethverse',
  '0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0': 'Frax Share',
  '0x853d955acef822db058eb8505911ed77f175b99e': 'Frax Finance',
  '0x0000000000b3f879cb30fe243b4dfee438691c04': 'Gas Token 2',
  '0x4c327471c44b2dacd6e90525f9d629bd2e4f662c': 'Ghost',
  '0xc944e90c64b2c07662a292be6244bdf05cda44a7': 'Graph',
  '0xa0246c9032bc3a600820415ae600c6388619a14d': 'Harvest',
  '0xe28b3b32b6c345a34ff64674606124dd5aceca30': 'Injective',
  '0x8a9c67fee641579deba04928c4bc45f66e26343a': 'Jarvis',
  '0xe6710e0cda178f3d921f456902707b0d4c4a332b': 'Julien',
  '0x1ceb5cb57c4d4e2b2433641b95dd330a33185a44': 'Keep3r',
  '0xdd974d5c2e2928dea5f71b9825b8b646686bd200': 'Kyber',
  '0xbbbbca6a901c926f240b89eacb641d8aec7aeafd': 'Loopring',
  '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0': 'Matic',
  '0x3593d125a4f7849a1b059e64f4517a86dd60c95d': 'Mantra DAO',
  '0x967da4048cd07ab37855c090aaf366e4ce1b9f48': 'Ocean',
  '0x8207c1ffc5b6804f6024322ccf34f29c3541ae26': 'Origin',
  '0x0258f474786ddfd37abce6df6bbb1dd5dfc4434a': 'Orion',
  '0x6c5ba91642f10282b576d91922ae6448c9d52f4e': 'Phala',
  '0x429881672b9ae42b8eba0e26cd9c73711b891ca5': 'Pickle',
  '0x3c03b4ec9477809072ff9cc9292c9b25d4a8e6c6': 'PolkaCover',
  '0x834ce7ad163ab3be0c5fd4e0a81e67ac8f51e00c': 'Polkainsure',
  '0x83e6f1e41cdd28eaceb20cb649155049fac3d5aa': 'Polkastarter',
  '0xd291e7a03283640fdc51b121ac401383a46cc623': 'Rari',
  '0x8762db106b2c2a0bccb3a80d1ed41273552616e8': 'Reserve Right',
  '0xfa5047c9c78b8877af97bdcb85db743fd7313d4a': 'Rook',
  '0x3845badade8e6dff049820680d1f14bd3903a5d0': 'Sandbox',
  '0xdf801468a808a32656d2ed2d2d80b72a129739f4': 'Somnium Space Cubes',
  '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2': 'Sushi',
  '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f': 'Synthetix',
  '0x0ba45a8b5d5575935b8158a88c631e9f9c95a2e5': 'Tellor',
  '0xdac17f958d2ee523a2206206994597c13d831ec7': 'Tether USD',
  '0x0000000000095413afc295d19edeb1ad7b71c952': 'Tokenlon',
  '0x4c19596f5aaff459fa38b0f7ed92f11ae6543784': 'Trust Token',
  '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48': 'USD Coin',
  '0xdc9ac3c20d1ed0b540df9b1fedc10039df13f99c': 'Utrust',
  '0x04fa0d235c4abf4bcf4787af4cf447de572ef828': 'UMA',
  '0x9355372396e3f6daf13359b7b607a3374cc638e0': 'Whale',
  '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599': 'Wrapped BTC',
  '0xa0afaa285ce85974c3c881256cb7f225e3a1178a': 'Wrapped CRES',
  '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2': 'Wrapped ETH',
  '0x0ae055097c6d159879521c384f1d2123d1f195e6': 'xDai Stake Token',
  '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e': 'Yearn',
  '0xb4bebd34f6daafd808f73de0d10235a92fbb6c3d': 'Yearn Ecosystem',
  '0xf4cd3d3fda8d7fd6c5a500203e38640a70bf9577': 'YFDAI.Finance',

  //
  // Compound Tokens
  //
  '0x70e36f6bf80a52b3b46b3af8e106cc0ed743e8e4': 'Compound COMP',
  '0x5d3a536e4d6dbd6114cc1ead35777bab948e3643': 'Compound DAI',
  '0x4ddc2d193948926d02f9b1fe9e1daa0718270ed5': 'Compound ETH',
  '0x35a18000230da775cac24873d00ff85bccded550': 'Compound UNI',
  '0x39aa39c021dfbae8fac545936693ac917d5e7563': 'Compound USDC',
  '0xf650c3d88d12db855b8bf7d11be6c55a4e07dcc9': 'Compound USDT',
  '0xc11b1268c1a384e55c48c2391d8d480264a3a7f4': 'Compound WBTC',
  '0xb3319f5d18bc0d84dd1b4825dcde5d5f7266d407': 'Compound ZRX',

  //
  // Aave V2 Tokens
  //
  '0x028171bca77440897b824ca71d1c56cac55b68a3': 'Aave V2 DAI',
  '0x101cc05f4a51c0319f570d5e146a8c625198e636': 'Aave V2 TUSD',
  '0xb9d7cb55f463405cdfbe4e90a6d2df01c2b92bf1': 'Aave V2 UNI',
  '0x3ed3b47dd13ec9a98b44e6204a523e766b225811': 'Aave V2 USDT',
  '0x030ba81f1c18d280636f32af80b9aad02cf0854e': 'Aave V2 WETH',
  '0x5165d24277cd063f5ac44efd447b27025e888f37': 'Aave V2 YFI',

  //
  // Aave V1 Tokens
  //
  '0xfc1e690f61efd961294b3e1ce3313fbd8aa4f85d': 'Aave V1 DAI',
  '0x9ba00d6856a4edf4665bca2c2309936572473b7e': 'Aave V1 USDC',
  '0xa64bd6c70cb9051f6a9ba1f163fdc07e0dfb5f84': 'Aave V1 LINK',
  '0x328c4c80bc7aca0834db37e6600a6c49e12da4de': 'Aave V1 SNX',
  '0x625ae63000f46200499120b906716420bd059240': 'Aave V1 SUSD',
  '0x6ee0f7bb50a54ab5253da0667b0dc2ee526c30a8': 'Aave V1 BUSD',

  //
  // Yearn Vaults
  //
  '0x5dbcf33d8c2e976c6b560249878e6f1491bca25c': 'Yearn yCRV Vault',

  // Curve Pools
  '0xdf5e0e81dff6faf3a7e52ba697820c5e32d806a8': 'Curve Y Pool',

  //
  // Polygon Tokens
  //

  //
  // Generic ERC20s
  //
  '0xd6df932a45c0f255f85145f286ea0b292b21c90b': 'Aave',
  '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39': 'Chainlink',
  '0x8505b9d2254a7ae468c0e9dd10ccea3a837aef5c': 'Compound',
  '0x172370d5cd63279efa6d502dab29171933a610af': 'Curve',
  '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063': 'Dai',
  '0x85955046df4668e1dd369d2de9f3aeb98dd2a369': 'DeFi Pulse Index',
  '0x104592a158490a9228070e0a8e5343b499e125d0': 'Frax',
  '0x3a3df212b7aa91aa0402b9035b098891d276572b': 'Fish',
  '0x5fe2b58c013d7601147dcdd68c143a77499f5531': 'Graph',
  '0xd86b5923f3ad7b585ed81b448170ae026c65ae9a': 'IRON Stablecoin',
  '0x50b728d8d964fd00c2d0aad81718b71311fef68a': 'Synthetix',
  '0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a': 'Sushi',
  '0xc2132d05d31c914a87c6611c10748aeb04b58e8f': 'Tether USD',
  '0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32': 'Telcoin',
  '0xaaa5b9e6c589642f98a1cda99b9d024b8407285a': 'Titan',
  '0x2791bca1f2de4661ed88a30c99a7a9449aa84174': 'USD Coin',
  '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6': 'Wrapped BTC',
  '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619': 'Wrapped ETH',
  '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270': 'Wrapped MATIC',
  '0xb33eaad8d922b1083446dc23f610c2567fb5180f': 'Uniswap',
  '0xda537104d6a5edd53c6fbba9a898708e465260b6': 'Yearn',

  //
  // Optimism Tokens
  //

  //
  // Generic ERC20s
  //
  '0x522439fB1Da6DB24f18baAB1782486B55FE3A7b6': 'AVAX 1X',
  '0x19F0622903A977A24bB47521732E6291002a4edE': 'LUNA 1X',
  '0x95FFfb13856D2BE739a862f9b645573e5C838bdD': 'SOL 1X',
  '0xbA6a2Fa321BB06D668c5192Be77428106c5C01E5': 'SOLUNAVAX Index',
};

export const tokenListColorsOverride: { [tokenAddress: string]: [string, string] } = {
  //
  // Ethereum Tokens
  //
  '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9': ['2ebac6', 'b6509e'], // AAVE
  '0x18aaa7115705e8be94bffebde57af9bfc265b998': ['d405d6', 'c39fdd'], // Audius
  '0x221657776846890989a759ba2973e427dff5c9bb': ['5c5c73', '0e0e21'], // Augur
  '0xbb0e17ef65f82ab018d8edd776e8dd940327b28b': ['076ce3', '7ac5f4'], // Axie Infinity
  '0xf5d669627376ebd411e34b98f19c868c8aba5ada': ['076ce3', '7ac5f4'], // Axie Infinity (Legacy)
  '0xba100000625a3754423978a60c9317c58a424e3d': ['000000', '1c1b1b'], // Balancer
  '0x514910771af9ca656af840dff83e8264ecf986ca': ['0a248c', '56a3b3'], // Chainlink
  '0xc00e94cb662c3520282e6f5717214004a7f26888': ['00d395', '00b07c'], // Compound
  '0xccf4429db6322d5c611ee964527d42e5d685dd6a': ['fad961', 'f76b1c'], // Compound BTC
  '0x5d3a536e4d6dbd6114cc1ead35777bab948e3643': ['09cc9c', '8475e4'], // Compound Dai
  '0x4ddc2d193948926d02f9b1fe9e1daa0718270ed5': ['09cc9c', '8475e4'], // Compound ETH
  '0x39aa39c021dfbae8fac545936693ac917d5e7563': ['09cc9c', '8475e4'], // Compound USDC
  '0x2ba592f78db6436527729929aaf6c908497cb200': ['7ce7e4', 'effbfa'], // Cream
  '0x6b175474e89094c44da98b954eedeac495271d0f': ['fad961', 'f76b1c'], // Dai
  '0xee06a81a695750e71a662b51066f2c74cf4478a0': ['3088ec', 'e3eefa'], // Decentral Games
  '0x0f5d2fb29fb7d3cfee444a200298f468908cc942': ['e72b6b', 'fc9b63'], // Decentraland
  '0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b': ['8453e4', 'bc9cf4'], // DeFi Pulse Index
  '0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c': ['04fcf0', '7bf5fc'], // Enjin
  '0x80fb784b7ed66730e8b1dbd9820afd29931aab03': ['0dc0cd', '009fcf'], // EthLend
  '0x0b498ff89709d3838a063f1dfa463091f9801c2b': ['480a81', 'e4dbec'], // BTC 2x FLI
  '0xaa6e8127831c9de45ae56bb1b0d4d4da6e5665bd': ['480a81', 'e4dbec'], // ETH 2x FLI
  '0xa0246c9032bc3a600820415ae600c6388619a14d': ['fad961', 'f76b1c'], // Harvest Finance
  '0x0954906da0bf32d5479e25f46056d22f08464cab': ['090909', 'dedede'], // Index
  '0xdd974d5c2e2928dea5f71b9825b8b646686bd200': ['31cb9e', '1cbf8f'], // Kyber Network (Legacy)
  '0xdefa4e8a7bcba345f687a2f1456f5edd9ce97202': ['31cb9e', '1cbf8f'], // Kyber Network v2
  '0xbbbbca6a901c926f240b89eacb641d8aec7aeafd': ['155aff', '5084ff'], // Loopring
  '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2': ['1abc9c', '0cab8c'], // Maker
  '0xd5525d397898e5502075ea5e830d8914f6f0affe': ['fab024', 'fadc6f'], // Meme
  '0xa3bed4e1c75d00fa6f4e5e6922db7261b5e9acd2': ['000000', '1c1b1b'], // Meta
  '0x0cae9e4d663793c2a2a0b211c1cf4bbca2b9caa7': ['ffd491', 'ed950c'], // Mirrored Amazon
  '0xd36932143f6ebdedd872d5fb0651f4b72fd15a84': ['f4f4f4', 'dcdcdc'], // Mirrored Apple
  '0x0e99cc0535bb6251f6679fa6e65d6d3b430e840b': ['bde6ff', '1da1f1'], // Mirrored Facebook
  '0x59a921db27dd6d4d974745b7ffc5c33932653442': ['95e8ab', '34ac54'], // Mirrored Google
  '0xc8d674114bac90148d11d3c1d33c61835a0f9dcd': ['ffabae', 'e30c14'], // Mirrored Netflix
  '0xb6ca7399b4f9ca56fc27cbff44f4d2e4eef1fc81': ['3d4141', '26d5d2'], // Muse
  '0x72e364f2abdc788b7e918bc238b21f109cd634d7': ['6fe8e5', 'f06cde'], // MVI
  '0x87d73e916d7057945c9bcd8cdd94e42a6f47f776': ['050405', 'df436c'], // NFTX
  '0xfca59cd816ab1ead66534d82bc21e7515ce441cf': ['175ffc', 'fa74f4'], // Rarible
  '0xa1d6df714f91debf4e0802a542e13067f31b8262': ['d62122', 'fae4e3'], // Redfox Labs
  '0x408e41876cccdc0f92210600ef50372656052a38': ['001c3a', '002042'], // Ren
  '0x557b933a7c2c45672b610f8954a3deb39a51a8ca': ['696969', 'fc1715'], // REVV
  '0x3845badade8e6dff049820680d1f14bd3903a5d0': ['05acee', 'eafafa'], // Sand
  '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2': ['38b2f4', '7bccf7'], // Sushi
  '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f': ['110c24', '0b0520'], // Synthetix Network Token
  '0x1fd154b4d0e3753b714b511a53fe1fb72dc7ae1c': ['722fe0', '140449'], // SW Dao
  '0xd084b83c305dafd76ae3e1b4e1f1fe2ecccb3988': ['df436c', 'df436c'], // Terra Virtua Kolect
  '0xdac17f958d2ee523a2206206994597c13d831ec7': ['43a78d', '8ccab7'], // Tether
  '0x04fa0d235c4abf4bcf4787af4cf447de572ef828': ['fc4c4c', 'fc7c7c'], // UMA
  '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984': ['ff007a', 'ffb7da'], // Uniswap
  '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48': ['2474cc', '94bce4'], // USD
  '0x4ba6ddd7b89ed838fed25d208d4f644106e34279': ['fad961', 'f76b1c'], // Vether
  '0x7a2bc711e19ba6aff6ce8246c546e8c4b4944dfd': ['60b932', 'e0f1d8'], // WAX Economic Token
  '0x9355372396e3f6daf13359b7b607a3374cc638e0': ['9b97b3', 'f2f2f2'], // Whale
  '0x123151402076fc819b7564510989e475c9cd93ca': ['fff200', 'ffd500'], // Wrapped Digital Gold
  '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2': ['8089f5', '4950e8'], // Wrapped ETH
  '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599': ['fad961', 'f76b1c'], // Wrapped Bitcoin
  '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e': ['0068e2', '4b8edd'], // Yearn
  '0x5dbcf33d8c2e976c6b560249878e6f1491bca25c': ['0068e2', '4b8edd'], // Yearn YCRV Vault

  //
  // Polygon Tokens
  //
  '0xd6df932a45c0f255f85145f286ea0b292b21c90b': ['2ebac6', 'b6509e'], // AAVE
  '0x28424507fefb6f7f8e9d3860f56504e4e5f5f390': ['569ab7', '9375bd'], // Aave WETH
  '0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3': ['000000', '1c1b1b'], // Balancer
  '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39': ['0a248c', '56a3b3'], // Chainlink
  '0x8505b9d2254a7ae468c0e9dd10ccea3a837aef5c': ['00d395', '00b07c'], // Compound
  '0x172370d5cd63279efa6d502dab29171933a610af': ['fdca00', 'f0f504'], // Curve
  '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063': ['fad961', 'f76b1c'], // Dai
  '0x85955046df4668e1dd369d2de9f3aeb98dd2a369': ['8453e4', 'bc9cf4'], // DeFi Pulse Index
  '0x5fe2b58c013d7601147dcdd68c143a77499f5531': ['5533c0', '4160cc'], // Graph
  '0x831753dd7087cac61ab5644b308642cc1c33dc13': ['262f71', '418aca'], // Quickswap
  '0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a': ['38b2f4', '7bccf7'], // Sushi
  '0x50b728d8d964fd00c2d0aad81718b71311fef68a': ['110c24', '0b0520'], // Synthetix Network Token
  '0xc2132d05d31c914a87c6611c10748aeb04b58e8f': ['43a78d', '8ccab7'], // Tether
  '0xb33eaad8d922b1083446dc23f610c2567fb5180f': ['ff007a', 'ffb7da'], // Uniswap
  '0x2791bca1f2de4661ed88a30c99a7a9449aa84174': ['2474cc', '94bce4'], // USD
  '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6': ['fad961', 'f76b1c'], // Wrapped BTC
  '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619': ['8089f5', '4950e8'], // Wrapped ETH
  '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270': ['2ebafa', '2d92fa'], // Wrapper MATIC
  '0xda537104d6a5edd53c6fbba9a898708e465260b6': ['0068e2', '4b8edd'], // Yearn

  //
  // Optimism Tokens
  //
  '0x522439fb1da6db24f18baab1782486b55fe3a7b6': ['e84141', 'f2f2f2'], // AVAX 1X
  '0x19f0622903a977a24bb47521732e6291002a4ede': ['ffd852', 'f2f2f2'], // LUNA 1X
  '0x95fffb13856d2be739a862f9b645573e5c838bdd': ['43b4ca', 'f2f2f2'], // SOL 1X
  '0xba6a2fa321bb06d668c5192be77428106c5c01e5': ['ff8086', 'f2f2f2'], // SOLUNAVAX

  '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1': ['fad961', 'f76b1c'], // DAI
  '0x350a791bfc2c21f9ed5d10980dad2e2638ffa7f6': ['0a248c', '56a3b3'], // Chainlink
  '0x8700daec35af8ff88c16bdf0418774cb3d7599b4': ['110c24', '0b0520'], // Synthetix Network Token
  '0x7f5c764cbc14f9669b88837ca1490cca17c31607': ['2474cc', '94bce4'], // USDC
  '0x94b008aa00579c1307b0ef2c499ad98a8ce58e58': ['04ab83', 'f2f2f2'], // USDT
  '0x6fd9d7ad17242c41f7131d257212c54a0e816691': ['ff007a', 'ffb7da'], // Uniswap
  '0x68f180fcce6836688e9084f035309e29bf0a2095': ['fad961', 'f76b1c'], // Wrapped BTC
  '0x4200000000000000000000000000000000000006': ['8089f5', '4950e8'], // Wrapped ETH

  '0x151bb01c79f4516c233948d69dae39869bccb737': ['00ffa3', '03e1ff'], // vSOL
  '0x86f1e0420c26a858fc203a3645dd1a36868f18e5': ['efa263', 'f4e7d7'], // vBTC
  '0xb24f50dd9918934ab2228be7a097411ca28f6c14': ['172852', 'ffd83d'], // vLUNA
  '0x5faa136fc58b6136ffdaeaac320076c4865c070f': ['e84142', 'ffffff'], // vAVAX
  '0x8c835dfaa34e2ae61775e80ee29e2c724c6ae2bb': ['181919', 'f2f2f2'], // vETH
  '0xc84da6c8ec7a57cd10b939e79eaf9d2d17834e04': ['2474cc', '94bce4'], // vUSD
  '0x333b1ea429a88d0dd48ce7c06c16609cd76f43a8': ['05acee', 'eafafa'], // vSAND
  '0x2f198182ec54469195a4a06262a9431a42462373': ['0a248c', '56a3b3'], // vLINK
  '0x5f714b5347f0b5de9f9598e39840e176ce889b9c': ['46509f', '000000'], // vATOM
  '0x77d0cc9568605bfff32f918c8ffaa53f72901416': ['00aee9', '69fabd'], // vONE
};

export const tokenLogoOverrides: { [address: string]: string } = {
  '0x151bb01c79f4516c233948d69dae39869bccb737':
    'https://assets.coingecko.com/coins/images/4128/thumb_2x/solana.png', // vSOL
  '0x86f1e0420c26a858fc203a3645dd1a36868f18e5':
    'https://assets.coingecko.com/coins/images/1/thumb_2x/bitcoin.png', // vBTC
  '0xb24f50dd9918934ab2228be7a097411ca28f6c14':
    'https://assets.coingecko.com/coins/images/8284/thumb_2x/luna1557227471663.png', // vLUNA
  '0x5faa136fc58b6136ffdaeaac320076c4865c070f':
    'https://assets.coingecko.com/coins/images/12559/thumb_2x/coin-round-red.png', // vAVAX
  '0x8c835dfaa34e2ae61775e80ee29e2c724c6ae2bb':
    'https://assets.coingecko.com/coins/images/279/thumb_2x/ethereum.png', // vETH
  '0xc84da6c8ec7a57cd10b939e79eaf9d2d17834e04':
    'https://assets.coingecko.com/coins/images/6319/thumb_2x/USD_Coin_icon.png', // vUSD
  '0x333b1ea429a88d0dd48ce7c06c16609cd76f43a8':
    'https://assets.coingecko.com/coins/images/12129/thumb_2x/sandbox_logo.jpg', // vSAND
  '0x2f198182ec54469195a4a06262a9431a42462373':
    'https://assets.coingecko.com/coins/images/877/thumb_2x/chainlink-new-logo.png', // vLINK
  '0x5f714b5347f0b5de9f9598e39840e176ce889b9c':
    'https://assets.coingecko.com/coins/images/1481/thumb_2x/cosmos_hub.png', // vATOM
  '0x77d0cc9568605bfff32f918c8ffaa53f72901416':
    'https://assets.coingecko.com/coins/images/4344/thumb_2x/Y88JAze.png', // vONE
};

export const WETH_ADDRESS_ETHEREUM = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2';
export const WETH_ADDRESS_POLYGON = '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619';
export const WETH_ADDRESS_ARBITRUM = '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1';
export const WETH_ADDRESS_OPTIMISM = '0x4200000000000000000000000000000000000006';
export const WMATIC_ADDRESS_POLYGON = '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270';
export const WAVAX_ADDRESS_AVALANCHE = '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7';
