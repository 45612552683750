import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Container, Grid } from 'semantic-ui-react';

import { WINDOW_DIMENSIONS } from '../../constants/index';
import { IWindowDimensions } from '../../typings/index';

import socialCapitalLogo from '../../img/home/investors/logo-social.svg';
import DFJLogo from '../../img/home/investors/logo-dfj.svg';
import haystackLogo from '../../img/home/investors/logo-haystack.svg';
import kindredLogo from '../../img/home/investors/logo-kindred.svg';
import vyCapitalLogo from '../../img/home/investors/logo-vy.svg';
import craftVenturesLogo from '../../img/home/investors/logo-craft.svg';

const { TABLET_LANDSCAPE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  InvestorGrid_sectionContainer: {
    paddingBottom: '100px',
    position: 'relative',
    zIndex: 1,
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      paddingBottom: '50px',
    },
  },
  InvestorGrid_innerSectionContainer: {
    marginLeft: '0',
    marginRight: '0',
    position: 'relative',
    zIndex: 2,
    [WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY]: {
      padding: '30px 25px',
    },
  },
  InvestorGrid_h1: {
    marginBottom: '60px',
    marginTop: '0',
  },
  InvestorGrid_h3: {
    fontSize: '32px',
    fontWeight: 500,
  },
  InvestorGrid_h4: {
    fontSize: '36px',
    marginBottom: '40px',
  },
  InvestorGrid_hiringCTA: {
    marginTop: '60px',
  },
  InvestorGrid_investorGrid: {
    marginBottom: '45px',
    marginTop: '15px',
  },
  InvestorGrid_investorImage: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '20px',
    marginTop: '30px',
  },
  InvestorGrid_largeMarginTop: {
    marginTop: '60px',
    [WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY]: {
      marginTop: '20px',
    },
  },
  InvestorGrid_profileDescription: {
    fontSize: '14px',
    lineHeight: '20px',
    marginTop: '20px',
  },
  InvestorGrid_profileImage: {
    borderRadius: '50%',
    marginBottom: '20px',
    marginTop: '60px',
    maxWidth: '80%',
  },
  InvestorGrid_profileName: {
    fontSize: '24px',
    marginBottom: '10px',
    marginTop: '10px',
  },
  InvestorGrid_profilePosition: {
    fontSize: '18px',
    marginBottom: '10px',
    marginTop: '10px',
  },
  InvestorGrid_subTitleLarge: {
    marginTop: '-5px',
    maxWidth: '600px',
  },
});

interface IInvestorGridProps {
  windowDimensions: IWindowDimensions;
}

class InvestorGrid extends PureComponent<IInvestorGridProps, {}> {
  render() {
    const {
      windowDimensions: { isTablet, isTabletLandscape },
    } = this.props;

    return (
      <section className={css(styles.InvestorGrid_sectionContainer)}>
        <Container className={css(styles.InvestorGrid_innerSectionContainer)}>
          <h3 className={css(styles.InvestorGrid_h3)}>Backed By</h3>
          <Grid className={css(styles.InvestorGrid_investorGrid)}>
            <Grid.Row columns={isTabletLandscape ? (isTablet ? 3 : 4) : 3}>
              <Grid.Column className={css(styles.InvestorGrid_investorImage)}>
                <img src={craftVenturesLogo} alt="Craft" />
              </Grid.Column>
              <Grid.Column className={css(styles.InvestorGrid_investorImage)}>
                <img src={vyCapitalLogo} alt="VY Capital" />
              </Grid.Column>
              <Grid.Column className={css(styles.InvestorGrid_investorImage)}>
                <img src={DFJLogo} alt="DFJ" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={isTabletLandscape ? (isTablet ? 3 : 4) : 3}>
              <Grid.Column className={css(styles.InvestorGrid_investorImage)}>
                <img src={kindredLogo} alt="Kindred Ventures" />
              </Grid.Column>
              <Grid.Column className={css(styles.InvestorGrid_investorImage)}>
                <img src={haystackLogo} alt="Haystack" />
              </Grid.Column>
              <Grid.Column className={css(styles.InvestorGrid_investorImage)}>
                <img src={socialCapitalLogo} alt="Social Capital" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </section>
    );
  }
}

export default InvestorGrid;
