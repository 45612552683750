import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react';

import HomeHeader from './HomeHeader';
import HomeProducts from './HomeProducts';
import HomeTrustedBy from './HomeTrustedBy';
import HomePowerfulIntegrations from './HomePowerfulIntegrations';
import HomeStepByStep from './HomeStepByStep';
import { IWindowDimensions, IMatch } from '../../typings/index';
import { WINDOW_DIMENSIONS } from '../../constants/index';

const { TABLET_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  Home_headerContainer: {
    display: 'flex',
    alignItems: 'center',
    [TABLET_MEDIA_QUERY]: {
      flexDirection: 'column',
    },
  },
});

interface IHomeProps {
  account: string;
  history: any;
  windowDimension: IWindowDimensions;
  match: IMatch;
}

class Home extends PureComponent<IHomeProps> {
  public componentDidMount() {
    document.title = 'TokenSets – Asset Management Simplified';
  }

  render() {
    const { windowDimension } = this.props;
    const { isTablet } = windowDimension;

    return (
      <div>
        <Container className={css(styles.Home_headerContainer)}>
          <HomeHeader />
        </Container>
        <HomeTrustedBy isTablet={isTablet} />
        <HomePowerfulIntegrations />
        <HomeProducts />
        <HomeStepByStep isTablet={isTablet} />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  account: state.web3.account,
  windowDimension: state.windowDimension,
});

export default connect(mapStateToProps)(Home as any);
