import { toast } from 'react-toastify';
import { toChecksumAddress } from 'tochecksum';
import { simpleResolveTransaction } from '../actions/transactionsActions';
import {
  BigNumber,
  faultTolerantPromise,
  payloadActionGenerator,
  userRejectedMetamaskTransaction,
} from '../utils/index';
import { BigNumber as EthersBigNumber } from 'ethers';
import {
  approveSetJSTransferProxy,
  getSetJSTransferProxyAllowance,
  approveToDebtIssuanceModule,
  approveToPerpIssuanceModule,
  getDebtIssuanceModuleAllowance,
  getPerpIssuanceModuleAllowance,
} from '../actions/setJSActions';
import {
  approveToUniswapOrSushiswapRouter,
  getUniswapOrSushiswapRouterAllowance,
} from '../actions/uniswapActions';
import { SETJS_CONFIG, TYPE_ISSUE, TYPE_REDEEM } from '../constants/index';
import {
  IERC20Token,
  IListTokenWithPosition,
  IListToken,
  IDebtComponentWithToken,
} from '../typings/index';
import { allUnapprovedListTokensSelector } from '../selectors/issuanceV2Selectors';
import {
  allUnapprovedDebtIssuanceTokensSelector,
  debtIssuanceModuleAddressSelector,
} from '../selectors/debtIssuanceSelectors';
import { perpIssuanceModuleAddressSelector } from '../selectors/protocolAddressSelector';
import { allUnapprovedPerpTokensSelector } from '../selectors/perpIssuanceRedemptionSelectors';
import { allUnapprovedDebtRedemptionTokensSelector } from '../selectors/debtRedemptionSelectors';
import {
  setJSInstanceSelector,
  accountSelector,
  currentChainOrDefaultSelector,
} from '../selectors/index';
import i18n from '../i18n';
import { isEmpty } from 'lodash';

const TIMEOUT_FOR_TX_MINED = 120000; // 120 seconds
const UNLIMITED_ISSUANCE_QTY = '1000000000000000000000000'; // 1,000,000.0 * 10 ^18

export const REQUEST_APPROVAL = 'REQUEST_APPROVAL';
export const COMPLETE_APPROVAL = 'COMPLETE_APPROVAL';
export const FAIL_APPROVAL = 'FAIL_APPROVAL';

export const REQUEST_UNISWAP_APPROVAL = 'REQUEST_UNISWAP_APPROVAL';
export const COMPLETE_UNISWAP_APPROVAL = 'COMPLETE_UNISWAP_APPROVAL';
export const FAIL_UNISWAP_APPROVAL = 'FAIL_UNISWAP_APPROVAL';

export const REQUEST_ZERO_EX_APPROVAL = 'REQUEST_ZERO_EX_APPROVAL';
export const COMPLETE_ZERO_EX_APPROVAL = 'COMPLETE_ZERO_EX_APPROVAL';
export const FAIL_ZERO_EX_APPROVAL = 'FAIL_ZERO_EX_APPROVAL';

export const REQUEST_SUSHISWAP_APPROVAL = 'REQUEST_SUSHISWAP_APPROVAL';
export const COMPLETE_SUSHISWAP_APPROVAL = 'COMPLETE_SUSHISWAP_APPROVAL';
export const FAIL_SUSHISWAP_APPROVAL = 'FAIL_SUSHISWAP_APPROVAL';

export const REQUEST_DEBT_ISSUANCE_APPROVAL = 'REQUEST_DEBT_ISSUANCE_APPROVAL';
export const COMPLETE_DEBT_ISSUANCE_APPROVAL = 'COMPLETE_DEBT_ISSUANCE_APPROVAL';
export const FAIL_DEBT_ISSUANCE_APPROVAL = 'FAIL_DEBT_ISSUANCE_APPROVAL';

export const REQUEST_PERP_ISSUANCE_APPROVAL = 'REQUEST_PERP_ISSUANCE_APPROVAL';
export const COMPLETE_PERP_ISSUANCE_APPROVAL = 'COMPLETE_PERP_ISSUANCE_APPROVAL';
export const FAIL_PERP_ISSUANCE_APPROVAL = 'FAIL_PERP_ISSUANCE_APPROVAL';

export const REQUEST_EXCHANGE_ISSUANCE_PAYMENT_APPROVAL =
  'REQUEST_EXCHANGE_ISSUANCE_PAYMENT_APPROVAL';
export const COMPLETE_EXCHANGE_ISSUANCE_PAYMENT_APPROVAL =
  'COMPLETE_EXCHANGE_ISSUANCE_PAYMENT_APPROVAL';
export const FAIL_EXCHANGE_ISSUANCE_PAYMENT_APPROVAL = 'FAIL_EXCHANGE_ISSUANCE_PAYMENT_APPROVAL';

export const REQUEST_EXCHANGE_ISSUANCE_UNDERLYING_APPROVAL =
  'REQUEST_EXCHANGE_ISSUANCE_UNDERLYING_APPROVAL';
export const COMPLETE_EXCHANGE_ISSUANCE_UNDERLYING_APPROVAL =
  'COMPLETE_EXCHANGE_ISSUANCE_UNDERLYING_APPROVAL';
export const FAIL_EXCHANGE_ISSUANCE_UNDERLYING_APPROVAL =
  'FAIL_EXCHANGE_ISSUANCE_UNDERLYING_APPROVAL';

export const BATCH_RECEIVE_ALLOWANCES = 'BATCH_RECEIVE_ALLOWANCES';

export const batchReceiveAllowances = payloadActionGenerator(BATCH_RECEIVE_ALLOWANCES);

export const requestApproval = payloadActionGenerator(REQUEST_APPROVAL);
export const completeApproval = payloadActionGenerator(COMPLETE_APPROVAL);
export const failApproval = payloadActionGenerator(FAIL_APPROVAL);

export const requestUniswapApproval = payloadActionGenerator(REQUEST_UNISWAP_APPROVAL);
export const completeUniswapApproval = payloadActionGenerator(COMPLETE_UNISWAP_APPROVAL);
export const failUniswapApproval = payloadActionGenerator(FAIL_UNISWAP_APPROVAL);

export const requestZeroExApproval = payloadActionGenerator(REQUEST_ZERO_EX_APPROVAL);
export const completeZeroExApproval = payloadActionGenerator(COMPLETE_ZERO_EX_APPROVAL);
export const failZeroExApproval = payloadActionGenerator(FAIL_ZERO_EX_APPROVAL);

export const requestSushiswapApproval = payloadActionGenerator(REQUEST_SUSHISWAP_APPROVAL);
export const completeSushiswapApproval = payloadActionGenerator(COMPLETE_SUSHISWAP_APPROVAL);
export const failSushiswapApproval = payloadActionGenerator(FAIL_SUSHISWAP_APPROVAL);

export const requestDebtIssuanceApproval = payloadActionGenerator(REQUEST_DEBT_ISSUANCE_APPROVAL);
export const completeDebtIssuanceApproval = payloadActionGenerator(COMPLETE_DEBT_ISSUANCE_APPROVAL);
export const failDebtIssuanceApproval = payloadActionGenerator(FAIL_DEBT_ISSUANCE_APPROVAL);

export const requestPerpIssuanceApproval = payloadActionGenerator(REQUEST_PERP_ISSUANCE_APPROVAL);
export const completePerpIssuanceApproval = payloadActionGenerator(COMPLETE_PERP_ISSUANCE_APPROVAL);
export const failPerpIssuanceApproval = payloadActionGenerator(FAIL_PERP_ISSUANCE_APPROVAL);

export const requestExchangeIssuancePaymentApproval = payloadActionGenerator(
  REQUEST_EXCHANGE_ISSUANCE_PAYMENT_APPROVAL,
);
export const completeExchangeIssuancePaymentApproval = payloadActionGenerator(
  COMPLETE_EXCHANGE_ISSUANCE_PAYMENT_APPROVAL,
);
export const failExchangeIssuancePaymentApproval = payloadActionGenerator(
  FAIL_EXCHANGE_ISSUANCE_PAYMENT_APPROVAL,
);

export const requestExchangeIssuanceUnderlyingApproval = payloadActionGenerator(
  REQUEST_EXCHANGE_ISSUANCE_UNDERLYING_APPROVAL,
);
export const completeExchangeIssuanceUnderlyingApproval = payloadActionGenerator(
  COMPLETE_EXCHANGE_ISSUANCE_UNDERLYING_APPROVAL,
);
export const failExchangeIssuanceUnderlyingApproval = payloadActionGenerator(
  FAIL_EXCHANGE_ISSUANCE_UNDERLYING_APPROVAL,
);

/* Basic Issuance Approvals */
export const approveERC20Allowance = (token: IERC20Token) => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const setJSInstance = setJSInstanceSelector(state);

  const txPromise = dispatch(approveSetJSTransferProxy(token));
  const checksumAddress = toChecksumAddress(token.address);

  return simpleResolveTransaction({
    txPromise,
  })
    .then(async (txId: string) => {
      dispatch(requestApproval(checksumAddress));

      await setJSInstance.blockchain.awaitTransactionMinedAsync(
        txId,
        undefined,
        TIMEOUT_FOR_TX_MINED,
      );

      dispatch(completeApproval(checksumAddress));
      const completionMessage = i18n.t('components:toasts.standard.approve-complete', {
        tokenName: token.symbol,
      });
      toast(completionMessage);
    })
    .catch((error: any) => {
      dispatch(failApproval(checksumAddress));

      if (userRejectedMetamaskTransaction(error)) return;

      const failedMessage = i18n.t('components:toasts.errors.approve-failed', {
        tokenName: token.symbol,
      });
      toast(failedMessage);
    });
};

/**
 * Approves SetJS controller for unlimited transfer proxy allowance.
 * Used for V2 on-chain sets.
 * @param token - Token to be approved to transfer proxy.
 */
export const approveListTokenAllowance = (token: IListTokenWithPosition) => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const setJSInstance = setJSInstanceSelector(state);
  const checksumAddress = toChecksumAddress(token.address);

  const txPromise = dispatch(approveSetJSTransferProxy(token));

  return simpleResolveTransaction({
    txPromise,
  })
    .then(async (txId: string) => {
      dispatch(requestApproval(checksumAddress));

      await setJSInstance.blockchain.awaitTransactionMinedAsync(
        txId,
        undefined,
        TIMEOUT_FOR_TX_MINED,
      );

      dispatch(completeApproval(checksumAddress));
      const completionMessage = i18n.t('components:toasts.standard.approve-complete', {
        tokenName: token.symbol,
      });
      toast(completionMessage);
    })
    .catch((error: any) => {
      dispatch(failApproval(checksumAddress));

      if (userRejectedMetamaskTransaction(error)) return;

      const failedMessage = i18n.t('components:toasts.errors.approve-failed', {
        tokenName: token.symbol,
      });
      toast(failedMessage);
    });
};

export const approveAllListTokensForCurrentSet = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const allUnapprovedTokens = allUnapprovedListTokensSelector(state);

  allUnapprovedTokens.forEach((token: IListTokenWithPosition) => {
    dispatch(approveListTokenAllowance(token));
  });
};

/**
 * Fetches ERC-20 transfer proxy allowance for the user's address.
 */
export const fetchERC20Allowance = (token: IERC20Token) => async (dispatch: any) => {
  const checksumAddress = toChecksumAddress(token.address);

  dispatch(requestApproval(checksumAddress));

  try {
    const allowance: BigNumber = await dispatch(getSetJSTransferProxyAllowance(token));

    if (!allowance || allowance.lt(UNLIMITED_ISSUANCE_QTY)) {
      dispatch(failApproval(checksumAddress));
      return;
    }

    dispatch(completeApproval(checksumAddress));
  } catch (error) {
    dispatch(failApproval(checksumAddress));
  }
};

export const batchFetchERC20Allowances = (tokensToFetch: IERC20Token[]) => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const setJSInstance = setJSInstanceSelector(state);
  const userAddress = accountSelector(state);
  const currentChain = currentChainOrDefaultSelector(state);

  if (isEmpty(userAddress)) return;

  tokensToFetch.forEach((token: IERC20Token) => {
    dispatch(requestApproval(toChecksumAddress(token.address)));
  });

  try {
    const allowancesPromise: Promise<
      EthersBigNumber[]
    > = setJSInstance.setToken.batchFetchAllowancesAsync(
      tokensToFetch.map(token => toChecksumAddress(token.address)),
      userAddress,
      SETJS_CONFIG[currentChain].basicIssuanceModuleAddress,
      userAddress,
    );

    const allowances: EthersBigNumber[] = await faultTolerantPromise<EthersBigNumber[]>(
      allowancesPromise,
    );

    allowances.forEach((allowance: EthersBigNumber, i: number) => {
      try {
        if (!allowance || allowance.lt(UNLIMITED_ISSUANCE_QTY)) {
          dispatch(failApproval(toChecksumAddress(tokensToFetch[i].address)));
        } else {
          dispatch(completeApproval(toChecksumAddress(tokensToFetch[i].address)));
        }
      } catch (error) {
        dispatch(failApproval(toChecksumAddress(tokensToFetch[i].address)));
      }
    });
  } catch (error) {
    console.log('could not batch fetch allowances: ', error);
    toast.warn('There was an issue fetching your allowances. Please try again later.', {
      toastId: 'batch-fetch-allowances',
    });
    tokensToFetch.forEach((token: IERC20Token) => {
      dispatch(failApproval(toChecksumAddress(token.address)));
    });
  }
};

/**
 * Fetches SetJS ERC-20 transfer proxy allowance for the user's address.
 * Used with V2 on-chain sets.
 */
export const fetchListTokenAllowance = (token: IListTokenWithPosition) => async (dispatch: any) => {
  const checksumAddress = toChecksumAddress(token.address);
  dispatch(requestApproval(checksumAddress));

  try {
    const allowance: BigNumber = await dispatch(getSetJSTransferProxyAllowance(token));

    if (!allowance || allowance.lt(UNLIMITED_ISSUANCE_QTY)) {
      dispatch(failApproval(checksumAddress));
      return;
    }

    dispatch(completeApproval(checksumAddress));
  } catch (error) {
    dispatch(failApproval(checksumAddress));
  }
};

export const batchFetchListTokenAllowances = (tokensToFetch: IListTokenWithPosition[]) => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const setJSInstance = setJSInstanceSelector(state);
  const userAddress = accountSelector(state);
  const currentChain = currentChainOrDefaultSelector(state);

  if (isEmpty(userAddress)) return;

  tokensToFetch.forEach((token: IListToken) => {
    dispatch(requestApproval(toChecksumAddress(token.address)));
  });

  try {
    const allowancesPromise: Promise<
      EthersBigNumber[]
    > = setJSInstance.setToken.batchFetchAllowancesAsync(
      tokensToFetch.map(token => toChecksumAddress(token.address)),
      userAddress,
      SETJS_CONFIG[currentChain].basicIssuanceModuleAddress,
      userAddress,
    );

    const allowances: EthersBigNumber[] = await faultTolerantPromise<EthersBigNumber[]>(
      allowancesPromise,
    );

    allowances.forEach((allowance: EthersBigNumber, i: number) => {
      try {
        if (!allowance || allowance.lt(UNLIMITED_ISSUANCE_QTY)) {
          dispatch(failApproval(toChecksumAddress(tokensToFetch[i].address)));
        } else {
          dispatch(completeApproval(toChecksumAddress(tokensToFetch[i].address)));
        }
      } catch (error) {
        dispatch(failApproval(toChecksumAddress(tokensToFetch[i].address)));
      }
    });
  } catch (error) {
    console.log('could not batch fetch list token allowances: ', error);
    toast.warn('There was an issue fetching your allowances. Please try again later', {
      toastId: 'batch-fetch-list-token-allowances',
    });
    tokensToFetch.forEach((token: IListToken) => {
      dispatch(failApproval(toChecksumAddress(token.address)));
    });
  }
};

/* Debt Issuance / Redemption Approvals */
export const approveTokenForDebtIssuance = (token: IListToken) => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const setJSInstance = setJSInstanceSelector(state);

  try {
    dispatch(requestDebtIssuanceApproval(toChecksumAddress(token.address)));
    const { hash: txHash } = await dispatch(approveToDebtIssuanceModule(token));

    await setJSInstance.blockchain.awaitTransactionMinedAsync(
      txHash,
      undefined,
      TIMEOUT_FOR_TX_MINED,
    );

    dispatch(completeDebtIssuanceApproval(toChecksumAddress(token.address)));
    const completionMessage = i18n.t('components:toasts.standard.approve-complete', {
      tokenName: token.symbol,
    });
    toast(completionMessage);
  } catch (error) {
    dispatch(failDebtIssuanceApproval(toChecksumAddress(token.address)));

    if (userRejectedMetamaskTransaction(error)) return;

    const failedMessage = i18n.t('components:toasts.errors.approve-failed', {
      tokenName: token.symbol,
    });
    toast(failedMessage);
  }
};

export const approveAllRequiredTokensForDebtIssuance = () => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const allUnapprovedTokens = allUnapprovedDebtIssuanceTokensSelector(state);

  allUnapprovedTokens.forEach((token: IDebtComponentWithToken) => {
    dispatch(approveTokenForDebtIssuance(token));
  });
};

export const approveAllRequiredTokensForDebtRedemption = () => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const allUnapprovedTokens = allUnapprovedDebtRedemptionTokensSelector(state);

  allUnapprovedTokens.forEach((token: IDebtComponentWithToken) => {
    dispatch(approveTokenForDebtIssuance(token));
  });
};

export const fetchDebtIssuanceAllowance = (token: IListToken) => async (dispatch: any) => {
  const checksumAddress = toChecksumAddress(token.address);
  dispatch(requestDebtIssuanceApproval(checksumAddress));

  try {
    const allowance: BigNumber = await dispatch(getDebtIssuanceModuleAllowance(token));

    if (!allowance || allowance.lt(UNLIMITED_ISSUANCE_QTY)) {
      dispatch(failDebtIssuanceApproval(checksumAddress));
      return;
    }

    dispatch(completeDebtIssuanceApproval(checksumAddress));
  } catch (error) {
    dispatch(failDebtIssuanceApproval(checksumAddress));
  }
};

export const batchFetchDebtIssuanceAllowances = (tokensToFetch: IListToken[]) => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const setJSInstance = setJSInstanceSelector(state);
  const userAddress = accountSelector(state);
  const debtIssuanceModuleAddress = debtIssuanceModuleAddressSelector(state);

  if (isEmpty(userAddress) || isEmpty(tokensToFetch)) return;

  tokensToFetch.forEach((token: IListToken) => {
    dispatch(requestDebtIssuanceApproval(toChecksumAddress(token.address)));
  });

  try {
    const allowancesPromise: Promise<
      EthersBigNumber[]
    > = setJSInstance.setToken.batchFetchAllowancesAsync(
      tokensToFetch.map(token => toChecksumAddress(token.address)),
      userAddress,
      debtIssuanceModuleAddress,
      userAddress,
    );

    const allowances: EthersBigNumber[] = await faultTolerantPromise<EthersBigNumber[]>(
      allowancesPromise,
    );

    allowances.forEach((allowance: EthersBigNumber, i: number) => {
      const checksumTokenAddress = toChecksumAddress(tokensToFetch[i].address);
      if (!allowance || allowance.lt(UNLIMITED_ISSUANCE_QTY)) {
        dispatch(failDebtIssuanceApproval(checksumTokenAddress));
      } else {
        dispatch(completeDebtIssuanceApproval(checksumTokenAddress));
      }
    });
  } catch (error) {
    console.log('could not batch fetch debt issuance token allowances: ', error);
    toast.warn('There was an issue fetching your allowances. Please try again later.', {
      toastId: 'batch-fetch-debt-issuance-token-allowances',
    });
    tokensToFetch.forEach((token: IListToken) => {
      dispatch(failDebtIssuanceApproval(toChecksumAddress(token.address)));
    });
  }
};

/* Perp Issuance / Redemption Approvals */
export const approveTokenForPerpIssuance = (token: IListToken) => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const setJSInstance = setJSInstanceSelector(state);

  try {
    dispatch(requestPerpIssuanceApproval(toChecksumAddress(token.address)));
    const { hash: txHash } = await dispatch(approveToPerpIssuanceModule(token));

    await setJSInstance.blockchain.awaitTransactionMinedAsync(
      txHash,
      undefined,
      TIMEOUT_FOR_TX_MINED,
    );

    dispatch(completePerpIssuanceApproval(toChecksumAddress(token.address)));
    const completionMessage = i18n.t('components:toasts.standard.approve-complete', {
      tokenName: token.symbol,
    });
    toast(completionMessage);
  } catch (error) {
    dispatch(failPerpIssuanceApproval(toChecksumAddress(token.address)));

    if (userRejectedMetamaskTransaction(error)) return;

    const failedMessage = i18n.t('components:toasts.errors.approve-failed', {
      tokenName: token.symbol,
    });
    toast(failedMessage);
  }
};

export const approveAllRequiredTokensForPerpIssuance = () => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const allUnapprovedTokens = allUnapprovedPerpTokensSelector(TYPE_ISSUE, state);

  allUnapprovedTokens.forEach((token: IDebtComponentWithToken) => {
    dispatch(approveTokenForPerpIssuance(token));
  });
};

export const approveAllRequiredTokensForPerpRedemption = () => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const allUnapprovedTokens = allUnapprovedPerpTokensSelector(TYPE_REDEEM, state);

  allUnapprovedTokens.forEach((token: IDebtComponentWithToken) => {
    dispatch(approveTokenForPerpIssuance(token));
  });
};

export const fetchPerpIssuanceAllowance = (token: IListToken) => async (dispatch: any) => {
  const checksumAddress = toChecksumAddress(token.address);
  dispatch(requestPerpIssuanceApproval(checksumAddress));

  try {
    const allowance: BigNumber = await dispatch(getPerpIssuanceModuleAllowance(token));

    if (!allowance || allowance.lt(UNLIMITED_ISSUANCE_QTY)) {
      dispatch(failPerpIssuanceApproval(checksumAddress));
      return;
    }

    dispatch(completePerpIssuanceApproval(checksumAddress));
  } catch (error) {
    dispatch(failPerpIssuanceApproval(checksumAddress));
  }
};

export const batchFetchPerpIssuanceAllowances = (tokensToFetch: IListToken[]) => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const setJSInstance = setJSInstanceSelector(state);
  const userAddress = accountSelector(state);
  const perpIssuanceModuleAddress = perpIssuanceModuleAddressSelector(state);

  if (isEmpty(userAddress) || isEmpty(tokensToFetch)) return;

  tokensToFetch.forEach((token: IListToken) => {
    dispatch(requestPerpIssuanceApproval(toChecksumAddress(token.address)));
  });

  try {
    const allowancesPromise: Promise<
      EthersBigNumber[]
    > = setJSInstance.setToken.batchFetchAllowancesAsync(
      tokensToFetch.map(token => toChecksumAddress(token.address)),
      userAddress,
      perpIssuanceModuleAddress,
      userAddress,
    );

    const allowances: EthersBigNumber[] = await faultTolerantPromise<EthersBigNumber[]>(
      allowancesPromise,
    );

    allowances.forEach((allowance: EthersBigNumber, i: number) => {
      const checksumTokenAddress = toChecksumAddress(tokensToFetch[i].address);
      if (!allowance || allowance.lt(UNLIMITED_ISSUANCE_QTY)) {
        dispatch(failPerpIssuanceApproval(checksumTokenAddress));
      } else {
        dispatch(completePerpIssuanceApproval(checksumTokenAddress));
      }
    });
  } catch (error) {
    console.log('could not batch fetch perp issuance token allowances: ', error);
    toast.warn('There was an issue fetching your allowances. Please try again later.', {
      toastId: 'batch-fetch-perp-issuance-token-allowances',
    });
    tokensToFetch.forEach((token: IListToken) => {
      dispatch(failPerpIssuanceApproval(toChecksumAddress(token.address)));
    });
  }
};

/* Uniswap Approvals */
export const approveERC20ToUniswapRouter = (token: IERC20Token) => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const setJSInstance = setJSInstanceSelector(state);

  dispatch(requestSushiswapApproval(token.id));

  const txPromise = dispatch(approveToUniswapOrSushiswapRouter(token));

  return simpleResolveTransaction({
    txPromise,
  })
    .then(async (txId: string) => {
      await setJSInstance.blockchain.awaitTransactionMinedAsync(
        txId,
        undefined,
        TIMEOUT_FOR_TX_MINED,
      );

      dispatch(completeSushiswapApproval(token.id));
      const completionMessage = i18n.t('components:toasts.standard.approve-complete', {
        tokenName: token.symbol,
      });
      toast(completionMessage);
    })
    .catch((error: any) => {
      dispatch(failSushiswapApproval(token.id));

      if (userRejectedMetamaskTransaction(error)) return;

      const failedMessage = i18n.t('components:toasts.errors.approve-failed', {
        tokenName: token.symbol,
      });
      toast(failedMessage);
    });
};

export const fetchUniswapERC20Allowance = (token: IERC20Token) => async (
  dispatch: any,
  _getState: any,
) => {
  dispatch(requestSushiswapApproval(token.id));

  try {
    const allowance: BigNumber = await dispatch(getUniswapOrSushiswapRouterAllowance(token));

    if (!allowance || allowance.lt(UNLIMITED_ISSUANCE_QTY)) {
      dispatch(failSushiswapApproval(token.id));
      return;
    }

    dispatch(completeSushiswapApproval(token.id));
  } catch (error) {
    dispatch(failSushiswapApproval(token.id));
  }
};
