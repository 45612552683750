/* global process */

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';
import _ from 'lodash';

// CSS packages
import 'animate.css/animate.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Account from './containers/Account';
import CaliforniaPrivacy from './containers/CaliforniaPrivacy';
import FundDetails from './containers/FundDetails';
import ErrorBoundary from './containers/ErrorBoundary';
import Explore from './containers/Explore';
import Faq from './containers/Faq';
import Footer from './containers/Footer';
import Home from './containers/Home';
import IssuanceV2 from './containers/IssuanceV2';
import Integrations from './containers/Integrations';
import MissingRoute from './containers/MissingRoute';
import Modals from './containers/Modals';
import NavBar from './containers/NavBar';
import StickyNavBar from './containers/StickyNavBar';
import Onboarding from './containers/Onboarding';
import Security from './containers/Security';
import RedemptionV2 from './containers/RedemptionV2';
import SignUpOptions from './containers/SignUpOptions';
import PrivacyPolicy from './containers/PrivacyPolicy';
import TermsOfService from './containers/TermsOfService';
import Press from './containers/Press';
import CustomSetLanding from './containers/CustomSetLanding';
import { Banner } from './components/index';
import {
  updateWindowDimension as updateWindowDimensionAction,
  updateScrollPosition as updateScrollPositionAction,
} from './actions/windowDimensionActions';
import { checkLoginStatus as checkLoginStatusAction } from './actions/web3Actions';
import { IWindowDimensions } from './typings/index';
import { getWeb3Instance } from './utils/index';

// Styles
import './App.css';
import { initDefaultProvider } from './utils/web3Utils';
import { appLoadingSelector, setJSInstanceSelector } from './selectors/baseSelectors';
import { fetchGeminiTokenList, fetchTokenSetsTokenList } from './actions/tokenListsActions';
import { updateAccountBalance } from './actions/balanceActions';
import { isWrongNetworkSelector } from './selectors/web3Selectors';

export interface IAppProps {
  appLoading: boolean;
  setJS: any;
  account: string;
  history: any;
  location: {
    pathname: string;
    key: string;
    search: string;
    hash: string;
  };
  isOnWrongNetwork: boolean;
  providerType: string;
  store: any;
  windowDimension: IWindowDimensions;
  updateAccountBalance: (...args: any[]) => any;
  checkLoginStatus: (...args: any[]) => any;
  updateWindowDimension: (...args: any[]) => any;
  updateScrollPosition: (...args: any[]) => any;
  fetchTokenSetsTokenList: (...args: any[]) => any;
  fetchGeminiTokenList: (...args: any[]) => any;
}

interface IRoutes {
  path: string;
  name: string;
  Component: any;
}

const loggedOutRoutes: IRoutes[] = [
  { path: '/', name: 'Home', Component: Home },
  { path: '/ccpa', name: 'CaliforniaPrivacy', Component: CaliforniaPrivacy },
  { path: '/explore', name: 'Explore', Component: Explore },
  { path: '/faq', name: 'FAQ', Component: Faq },
  { path: '/privacy', name: 'PrivacyPolicy', Component: PrivacyPolicy },
  { path: '/v2/set/:set', name: 'CustomSetLanding', Component: CustomSetLanding },
  { path: '/v2/set/polygon/:set', name: 'CustomSetLandingPolygon', Component: CustomSetLanding },
  { path: '/v2/set/ethereum/:set', name: 'CustomSetLandingEthereum', Component: CustomSetLanding },
  { path: '/v2/set/arbitrum/:set', name: 'CustomSetLandingArbitrum', Component: CustomSetLanding },
  { path: '/v2/set/optimism/:set', name: 'CustomSetLandingOptimism', Component: CustomSetLanding },
  {
    path: '/v2/set/avalanche/:set',
    name: 'CustomSetLandingAvalanche',
    Component: CustomSetLanding,
  },
  {
    path: '/portfolio/avalanche/:set',
    name: 'PortfolioSetLandingAvalanche',
    Component: FundDetails,
  },
  { path: '/portfolio/optimism/:set', name: 'PortfolioSetLandingOptimism', Component: FundDetails },
  { path: '/portfolio/arbitrum/:set', name: 'PortfolioSetLandingArbitrum', Component: FundDetails },
  { path: '/portfolio/polygon/:set', name: 'PortfolioSetLandingPolygon', Component: FundDetails },
  { path: '/portfolio/:set', name: 'PortfolioSetLandingEthereum', Component: FundDetails },
  { path: '/security', name: 'Security', Component: Security },
  { path: '/terms', name: 'TermsOfService', Component: TermsOfService },
  { path: '/press', name: 'Press', Component: Press },
  { path: '/integrations', name: 'Integrations', Component: Integrations },
  { path: '/404', name: 'MissingRoute', Component: MissingRoute },
];

const loggedInRoutes: IRoutes[] = [
  { path: '/', name: 'Home', Component: Home },
  { path: '/account', name: 'Account', Component: Account },
  { path: '/ccpa', name: 'CaliforniaPrivacy', Component: CaliforniaPrivacy },
  { path: '/explore', name: 'Explore', Component: Explore },
  { path: '/faq', name: 'FAQ', Component: Faq },
  { path: '/privacy', name: 'PrivacyPolicy', Component: PrivacyPolicy },
  { path: '/security', name: 'Security', Component: Security },
  { path: '/v2/set/:set', name: 'CustomSetLanding', Component: CustomSetLanding },
  { path: '/v2/set/ethereum/:set', name: 'CustomSetLandingEthereum', Component: CustomSetLanding },
  { path: '/v2/set/polygon/:set', name: 'CustomSetLandingPolygon', Component: CustomSetLanding },
  { path: '/v2/set/arbitrum/:set', name: 'CustomSetLandingArbitrum', Component: CustomSetLanding },
  { path: '/v2/set/optimism/:set', name: 'CustomSetLandingOptimism', Component: CustomSetLanding },
  {
    path: '/v2/set/avalanche/:set',
    name: 'CustomSetLandingAvalanche',
    Component: CustomSetLanding,
  },
  {
    path: '/portfolio/avalanche/:set',
    name: 'PortfolioSetLandingAvalanche',
    Component: FundDetails,
  },
  { path: '/portfolio/optimism/:set', name: 'PortfolioSetLandingOptimism', Component: FundDetails },
  { path: '/portfolio/arbitrum/:set', name: 'PortfolioSetLandingArbitrum', Component: FundDetails },
  { path: '/portfolio/polygon/:set', name: 'PortfolioSetLandingPolygon', Component: FundDetails },
  { path: '/portfolio/:set', name: 'PortfolioSetLandingEthereum', Component: FundDetails },
  { path: '/set/:set/redeem', name: 'Redeem Set', Component: RedemptionV2 },
  { path: '/set/ethereum/:set/redeem', name: 'RedeemSetEthereum', Component: RedemptionV2 },
  { path: '/set/polygon/:set/redeem', name: 'RedeemSetPolygon', Component: RedemptionV2 },
  { path: '/set/arbitrum/:set/redeem', name: 'RedeemSetArbitrum', Component: RedemptionV2 },
  { path: '/set/optimism/:set/redeem', name: 'RedeemSetOptimism', Component: RedemptionV2 },
  { path: '/set/avalanche/:set/redeem', name: 'RedeemSetAvalanche', Component: RedemptionV2 },
  { path: '/set/:set/issue', name: 'Issue Set', Component: IssuanceV2 },
  { path: '/set/ethereum/:set/issue', name: 'IssueSetEthereum', Component: IssuanceV2 },
  { path: '/set/polygon/:set/issue', name: 'IssueSetPolygon', Component: IssuanceV2 },
  { path: '/set/arbitrum/:set/issue', name: 'IssueSetArbitrum', Component: IssuanceV2 },
  { path: '/set/optimism/:set/issue', name: 'IssueSetOptimism', Component: IssuanceV2 },
  { path: '/set/avalanche/:set/issue', name: 'IssueSetAvalanche', Component: IssuanceV2 },
  { path: '/signup', name: 'SignUp', Component: SignUpOptions },
  { path: '/start', name: 'Onboarding', Component: Onboarding },
  { path: '/terms', name: 'TermsOfService', Component: TermsOfService },
  { path: '/press', name: 'Press', Component: Press },
  { path: '/integrations', name: 'Integrations', Component: Integrations },
  { path: '/404', name: 'MissingRoute', Component: MissingRoute },
];

const WRONG_NETWORK_TEXT =
  'You are on an unsupported network and may experience unexpected behavior. Please switch to a supported network.';

class App extends Component<IAppProps> {
  state = {
    initializing: true,
  };

  constructor(props: IAppProps) {
    // This code needs to run first so that's why we put it in pre-render in the constructor.
    // Children componentDidMounts will run before App.tsx's componentDidMount
    super(props);
    const { checkLoginStatus } = props;

    this.setupAxios();
    checkLoginStatus();
  }

  async componentDidMount() {
    const {
      updateWindowDimension,
      updateScrollPosition,
      updateAccountBalance,
      checkLoginStatus,
      providerType,
      fetchTokenSetsTokenList,
      fetchGeminiTokenList,
    } = this.props;

    await checkLoginStatus();

    if (providerType) {
      getWeb3Instance();
    } else {
      initDefaultProvider();
    }

    await fetchTokenSetsTokenList();
    await fetchGeminiTokenList();
    await updateAccountBalance();

    // Listen for window resizing, and update `windowDimension` in Redux
    window.addEventListener('resize', updateWindowDimension);
    window.addEventListener('scroll', updateScrollPosition);
    if (window.outerWidth > 0 && window.outerHeight > 0) {
      // Sometimes window hasn't fully loaded yet so we shouldn't set to 0 in those
      // cases
      updateWindowDimension();
    }

    this.setState({ initializing: false });
  }

  componentWillUnmount() {
    const { updateWindowDimension, updateScrollPosition } = this.props;
    window.removeEventListener('resize', updateWindowDimension);
    window.removeEventListener('scroll', updateScrollPosition);
  }

  componentDidUpdate() {
    document.body.style.paddingBottom = '32px';
  }

  setupAxios = () => {
    // Set axios defaults
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.adapter = cacheAdapterEnhancer(axios.defaults.adapter, {
      enabledByDefault: false,
      cacheFlag: 'useCache',
    });

    // Add a request interceptor. Formats all outgoing requests with same headers/params if available.
    axios.interceptors.request.use(
      config => {
        const { account } = this.props;
        // Add in account as X-SET-USER header if logged in
        // Add in beta flag if environment is beta enabled
        return {
          ...config,
          headers: {
            ...config.headers,
            ...(account && { 'X-SET-USER': account }),
          },
          params: {
            ...config.params,
          },
        };
      },
      error => {
        return Promise.reject(error);
      },
    );

    /** Adds a response interceptor for global error handling.
     * If an error response has been handled in here, Promise.reject(null) to pass a null error object
     * to the caller's .catch block.
     * The caller's catch block should check for null error objects before doing any more error handling.
     */
    axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError) => {
        // If the error response is undefined, there could be a CORS issue,
        // firewall, or browser extension blocking the request
        if (error && typeof error.response === 'undefined') {
          return Promise.reject(null);
        }

        return Promise.reject(error);
      },
    );
  };

  /**
   * Used to determine if the app should have padding. Returns a boolean.
   */
  determineAppPadding = (path: string, prevProps: IAppProps): boolean => {
    const { location } = this.props;
    return (
      !location.hash.includes(path) &&
      prevProps.location.hash.includes(path) &&
      document.body.style.paddingBottom === '0px'
    );
  };

  render() {
    const {
      appLoading,
      setJS,
      account,
      isOnWrongNetwork,
      history,
      location,
      windowDimension: { isTablet, isMobile },
    } = this.props;

    const routes: IRoutes[] = account ? loggedInRoutes : loggedOutRoutes;

    if (_.isEmpty(setJS) || this.state.initializing || appLoading) {
      return null;
    }

    return (
      <div className="App">
        <ErrorBoundary activeItem={location.pathname} history={history}>
          {/* <WagmiConfig client={wagmiClient}> */}
          {isTablet && <NavBar activeItem={location.pathname} history={history} />}
          <Banner visible={isOnWrongNetwork} content={WRONG_NETWORK_TEXT} />
          {!isTablet && <NavBar activeItem={location.pathname} history={history} />}
          {!isMobile && <StickyNavBar activeItem={location.pathname} history={history} />}
          <TransitionGroup>
            <Switch>
              {routes.map(({ path, Component }) => {
                return (
                  <Route key={path} exact path={path}>
                    {(input: any) => {
                      const newProps = {
                        ...this.props,
                        match: input.match,
                      };
                      return (
                        <CSSTransition
                          in={input.match != null}
                          key={location.key}
                          classNames="fade"
                          timeout={500}
                          unmountOnExit
                        >
                          <Component {...newProps} />
                        </CSSTransition>
                      );
                    }}
                  </Route>
                );
              })}
              <Route component={MissingRoute} />
            </Switch>
          </TransitionGroup>
          <Footer />
          <Modals path={location.pathname} />
          {/* </WagmiConfig> */}
        </ErrorBoundary>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const setJS = setJSInstanceSelector(state);
  const appLoading = appLoadingSelector(state);
  const isOnWrongNetwork = isWrongNetworkSelector(state);

  return {
    appLoading,
    setJS,
    isOnWrongNetwork,
    account: state.web3.account,
    providerType: state.web3.providerType,
    windowDimension: state.windowDimension,
  };
};

const mapDispatchToProps = {
  updateAccountBalance,
  checkLoginStatus: checkLoginStatusAction,
  updateWindowDimension: updateWindowDimensionAction,
  updateScrollPosition: updateScrollPositionAction,
  fetchTokenSetsTokenList,
  fetchGeminiTokenList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)((App as any) as React.FunctionComponent<IAppProps>);
