import { connect } from 'react-redux';

import Issuance from './Issuance';
import { fetchERC20AllowancesForCurrentlyViewingSet } from '../../actions/issuanceV2Actions';
import { fetchEnabledModules } from '../../actions/setDetailsActions';
import { getGasPrice } from '../../actions/gasPriceActions';
import {
  hasAllApprovalsSelector,
  isBasicIssuanceModuleEnabledForCurrentSetSelector,
} from '../../selectors/issuanceV2Selectors';
import { setCurrentlyViewingSetAddress, fetchSetDetails } from '../../actions/setDetailsActions';
import { fetchCoingeckoTokenList } from '../../actions/tokenListsActions';
import { fetchBalancesForCurrentlyViewingSet } from '../../actions/balanceActions';
import { currentSetDetailsSelector } from '../../selectors/setDetailsSelectors';
import {
  fetchDebtIssuanceAllowancesForCurrentSet,
  getDebtIssuanceRequiredComponents,
} from '../../actions/debtIssuanceActions';
import {
  isDebtIssuanceModuleEnabledForCurrentSetSelector,
  hasAllDebtIssuanceApprovalsSelector,
} from '../../selectors/debtIssuanceSelectors';
import {
  isFetchingSetDetailsSelector,
  isFetchingModuleEnabledSelector,
  isFetchingDebtIssuanceComponentsSelector,
  accountSelector,
  setDetailsCurrentSetAddressSelector,
  isFetchingPerpIssuanceComponentsSelector,
} from '../../selectors/baseSelectors';
import {
  customV2SetPathPrefixSelector,
  isWrongNetworkSelector,
} from '../../selectors/web3Selectors';
import {
  hasAllPerpApprovalsSelector,
  isPerpIssuanceModuleEnabledForCurrentSetSelector,
} from '../../selectors/perpIssuanceRedemptionSelectors';
import {
  fetchPerpIssuanceAllowancesForCurrentSet,
  getPerpIssuanceRequiredComponents,
} from '../../actions/perpIssuanceActions';
import { TYPE_ISSUE } from '../../constants/index';

const mapStateToProps = (state: any) => {
  const customV2SetPathPrefix = customV2SetPathPrefixSelector(state);
  const hasAllApprovals = hasAllApprovalsSelector(state);
  const hasAllDebtIssuanceApprovals = hasAllDebtIssuanceApprovalsSelector(state);
  const hasAllPerpIssuanceApprovals = hasAllPerpApprovalsSelector(TYPE_ISSUE, state);
  const isFetchingSetDetails = isFetchingSetDetailsSelector(state);
  const isIssuanceModuleEnabled = isBasicIssuanceModuleEnabledForCurrentSetSelector(state);
  const isDebtIssuanceModuleEnabled = isDebtIssuanceModuleEnabledForCurrentSetSelector(state);
  const isFetchingIssuanceModuleEnabled = isFetchingModuleEnabledSelector(state);
  const isPerpIssuanceModuleEnabled = isPerpIssuanceModuleEnabledForCurrentSetSelector(state);
  const isFetchingDebtIssuanceComponents = isFetchingDebtIssuanceComponentsSelector(state);
  const isFetchingPerpIssuanceComponents = isFetchingPerpIssuanceComponentsSelector(state);
  const account = accountSelector(state);
  const setDetails = currentSetDetailsSelector(state);
  const setAddress = setDetailsCurrentSetAddressSelector(state);
  const isWrongNetwork = isWrongNetworkSelector(state);

  return {
    isWrongNetwork,
    setAddress,
    customV2SetPathPrefix,
    account,
    setDetails,
    hasAllApprovals,
    hasAllDebtIssuanceApprovals,
    hasAllPerpIssuanceApprovals,
    isFetchingSetDetails,
    isFetchingIssuanceModuleEnabled,
    isFetchingDebtIssuanceComponents,
    isFetchingPerpIssuanceComponents,
    isIssuanceModuleEnabled,
    isDebtIssuanceModuleEnabled,
    isPerpIssuanceModuleEnabled,
  };
};

const mapDispatchToProps = {
  onFetchEnabledModules: fetchEnabledModules,
  onFetchDebtIssuanceComponents: getDebtIssuanceRequiredComponents,
  onFetchPerpIssuanceComponents: getPerpIssuanceRequiredComponents,
  onFetchAllowancesForCurrentSet: fetchERC20AllowancesForCurrentlyViewingSet,
  onFetchDebtIssuanceAllowancesForCurrentSet: fetchDebtIssuanceAllowancesForCurrentSet,
  onFetchPerpIssuanceAllowancesForCurrentSet: fetchPerpIssuanceAllowancesForCurrentSet,
  onFetchCurrentSetTokenBalances: fetchBalancesForCurrentlyViewingSet,
  onFetchGasPrice: getGasPrice,
  onFetchCoingeckoTokenList: fetchCoingeckoTokenList,
  onSetCurrentlyViewingSetAddress: setCurrentlyViewingSetAddress,
  onFetchSetDetailsByAddress: fetchSetDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Issuance);
