import { utils, BigNumber as EthersBigNumber } from 'ethers';

import {
  accountSelector,
  erc20BalancesSelector,
  setDetailsCurrentSetAddressSelector,
  redemptionInputQuantityV2Selector,
} from '../selectors/baseSelectors';
import { BigNumber, getWeb3Instance } from '../utils/index';
import { tokenFromBaseUnits } from '../utils/formatUtils';
import basicIssuanceModuleABI from '../constants/abis/basicIssuanceModuleABI';
import { basicIssuanceModuleAddressSelector, gasPriceTransactionOptionsSelector } from '.';

/**
 * Returns a bool if the user has enough Sets to redeem.
 * @param state - The Redux state.
 */
export const userHasSufficientFundsForRedemptionQuantity = (state: any): boolean => {
  const address = setDetailsCurrentSetAddressSelector(state);
  const redeemQuantity = redemptionInputQuantityV2Selector(state);
  const userErc20Balances = erc20BalancesSelector(state);

  const userBalance = userErc20Balances?.[address] || new BigNumber(0);
  const formattedUserBalance = tokenFromBaseUnits(userBalance?.toString() || '0');

  return formattedUserBalance.gte(redeemQuantity || 0);
};

export const createRedeemFundTransactionV2Args = async (state: any) => {
  const setAddress = setDetailsCurrentSetAddressSelector(state);
  const userAddress = accountSelector(state);
  const rawRedemptionQuantity = redemptionInputQuantityV2Selector(state);
  const formattedRedemptionQuantity = utils.parseEther(
    rawRedemptionQuantity?.length ? rawRedemptionQuantity : '0',
  );
  const gasPriceTransactionOptions = gasPriceTransactionOptionsSelector(state);
  const web3Instance = await getWeb3Instance();
  const basicIssuanceModuleAddress = basicIssuanceModuleAddressSelector(state);
  const basicIssuanceModuleContract = new web3Instance.eth.Contract(
    basicIssuanceModuleABI as any,
    basicIssuanceModuleAddress,
  );
  const gasLimit = await basicIssuanceModuleContract.methods
    .redeem(setAddress, formattedRedemptionQuantity, userAddress)
    .estimateGas({ from: userAddress });

  if (!setAddress || !userAddress || formattedRedemptionQuantity.lte(0)) return;

  const transactionOpts = {
    ...gasPriceTransactionOptions,
    gasLimit: EthersBigNumber.from(gasLimit),
  };

  return [setAddress, formattedRedemptionQuantity, userAddress, userAddress, transactionOpts];
};
