import { css, StyleSheet } from 'aphrodite';
import React, { PureComponent } from 'react';

import { ITraderFund, IRebalancingSet } from '../../typings/index';
import { COLORS } from '../../constants/index';
import { BigNumber } from '../../utils/index';

const styles = StyleSheet.create({
  ComponentAllocation_container: {
    display: 'flex',
    flexDirection: 'column',
  },
  ComponentAllocation_componentContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '7px',
  },
  ComponentAllocation_component: {
    display: 'flex',
    marginRight: '20px',
  },
  ComponentAllocation_componentIcon: {
    verticalAlign: 'middle',
    height: '18px',
    marginRight: '5px',
  },
  ComponentAllocation_componentSymbol: {
    fontSize: '11px',
    fontWeight: 500,
    color: COLORS.darkBlue,
    marginRight: '5px',
  },
  ComponentAllocation_componentPercentage: {
    fontSize: '11px',
    color: COLORS.darkBlue,
  },
  ComponentAllocation_allocationBarBackground: {
    display: 'flex',
    width: '100%',
    height: '6px',
    backgroundColor: COLORS.lightGray,
    borderRadius: '5px',
    overflow: 'hidden',
  },
  ComponentAllocation_allocationBar: {
    height: '6px',
  },
  ComponentAllocation_secondaryContainer: {
    display: 'flex',
  },
  ComponentAllocation_secondaryComponent: {
    display: 'flex',
    marginRight: '-10px',
  },
  ComponentAllocation_secondaryIcon: {
    verticalAlign: 'middle',
    height: '18px',
  },
  ComponentAllocation_secondaryComponentsLabel: {
    color: COLORS.darkBlue,
    fontSize: '10px',
    marginLeft: '15px',
  },

  // Shortened Card Variant
  ComponentAllocation_shortenedComponent: {
    marginRight: '7px',
  },
});

interface IComponentAllocationProps {
  set: IRebalancingSet | ITraderFund;
  customStyles?: any;
  isCardVariant?: boolean;
  truncateTo?: number;
}

class ComponentAllocation extends PureComponent<IComponentAllocationProps> {
  // The quantity of bunched up token icons to display in "more" section
  SECONDARY_TOKENS_DISPLAY_QUANTITY = 3;

  static get defaultProps() {
    return {
      set: {},
      truncateTo: 2,
      isCardVariant: false,
    };
  }

  public getSortedComponents = () => {
    const {
      set: { components },
    } = this.props;

    return components.sort((componentA: any, componentB: any) =>
      new BigNumber(componentB.percent_of_set).minus(componentA.percent_of_set).toNumber(),
    );
  };

  public renderPrimaryTokens = () => {
    const { set, truncateTo, isCardVariant } = this.props;

    const sortedComponents = this.getSortedComponents();

    // Shortened components are used to fit multi-asset sets in FeaturedSetCard
    const shouldShortenComponents = sortedComponents.length > 2 && isCardVariant;
    const primaryTokens = sortedComponents.slice(0, truncateTo);

    return primaryTokens.map((component: any) => {
      const { image, image_url, symbol, percent_of_set } = component;

      let formattedSymbol = symbol;
      if (symbol === 'WETH') {
        formattedSymbol = 'ETH';
      }

      if (symbol === 'WBTC') {
        formattedSymbol = 'BTC';
      }

      return (
        <div
          key={`${set.name}-${symbol}-primary`}
          className={css(
            styles.ComponentAllocation_component,
            shouldShortenComponents && styles.ComponentAllocation_shortenedComponent,
          )}
        >
          <img className={css(styles.ComponentAllocation_componentIcon)} src={image || image_url} />
          <span className={css(styles.ComponentAllocation_componentSymbol)}>{formattedSymbol}</span>
          <span className={css(styles.ComponentAllocation_componentPercentage)}>
            {percent_of_set}%
          </span>
        </div>
      );
    });
  };

  public renderSecondaryTokens = () => {
    const { truncateTo, set } = this.props;

    const sortedComponents = this.getSortedComponents();

    const secondaryTokens = sortedComponents.slice(
      truncateTo,
      truncateTo + this.SECONDARY_TOKENS_DISPLAY_QUANTITY,
    );

    if (!secondaryTokens.length) return null;

    const secondaryTokenIcons = secondaryTokens.map((component: any, index: number) => {
      const { image, image_url, symbol } = component;

      return (
        <div
          key={`${set.name}-${symbol}-secondary`}
          className={css(styles.ComponentAllocation_secondaryComponent)}
          style={{ zIndex: 100 - index }}
        >
          <img className={css(styles.ComponentAllocation_secondaryIcon)} src={image || image_url} />
        </div>
      );
    });

    const allRemainingTokensQty = sortedComponents.length - truncateTo;
    const allRemainingTokensLabel = (
      <span className={css(styles.ComponentAllocation_secondaryComponentsLabel)}>
        <b>+{allRemainingTokensQty}</b> more
      </span>
    );

    return (
      <div className={css(styles.ComponentAllocation_secondaryContainer)}>
        {secondaryTokenIcons} {allRemainingTokensLabel}
      </div>
    );
  };

  public renderComponentLabels = () => {
    const primaryDisplayTokens = this.renderPrimaryTokens();
    const secondaryDisplayTokens = this.renderSecondaryTokens();

    return (
      <div className={css(styles.ComponentAllocation_componentContainer)}>
        {primaryDisplayTokens}
        {secondaryDisplayTokens}
      </div>
    );
  };

  public createAllocationBarSection = (component: any, i: number) => {
    const { percent_of_set, colors, id } = component;
    const allocationColor = colors && colors[0] ? `#${colors[0]}` : COLORS.lightGray;

    const customStyle = StyleSheet.create({
      customBarStyle: {
        width: `${percent_of_set}%`,
        backgroundColor: allocationColor,
      },
    });

    return (
      <div
        className={css(styles.ComponentAllocation_allocationBar, customStyle.customBarStyle)}
        key={`${id}-${i}`}
      />
    );
  };

  public renderComponentAllocationBar = () => {
    const sortedComponents = this.getSortedComponents();

    return (
      <div className={css(styles.ComponentAllocation_allocationBarBackground)}>
        {sortedComponents.map((component: any, i: number) =>
          this.createAllocationBarSection(component, i),
        )}
      </div>
    );
  };

  public render() {
    const {
      customStyles,
      set: { components },
    } = this.props;

    if (!components || !components.length) return null;

    return (
      <div className={css(styles.ComponentAllocation_container, customStyles)}>
        {this.renderComponentLabels()}
        {this.renderComponentAllocationBar()}
      </div>
    );
  }
}

export default ComponentAllocation;
