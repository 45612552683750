import { StyleSheet, css } from 'aphrodite';
import React, { PureComponent } from 'react';

import { COLORS } from '../../constants/index';

const DotDivider_dot = {
  borderRadius: '50%',
  height: '10px',
  width: '10px',
};

const styles = StyleSheet.create({
  DotDivider_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '130px',
  },
  DotDivider_redDot: {
    ...DotDivider_dot,
    backgroundColor: COLORS.red,
  },
  DotDivider_blueDot: {
    ...DotDivider_dot,
    backgroundColor: COLORS.blue,
  },
  DotDivider_yellowDot: {
    ...DotDivider_dot,
    backgroundColor: COLORS.yellow,
  },
  DotDivider_greenDot: {
    ...DotDivider_dot,
    backgroundColor: COLORS.green,
  },
});

class DotDivider extends PureComponent {
  render() {
    return (
      <div>
        <div className={css(styles.DotDivider_container)}>
          <div className={css(styles.DotDivider_redDot)} />
          <div className={css(styles.DotDivider_blueDot)} />
          <div className={css(styles.DotDivider_yellowDot)} />
          <div className={css(styles.DotDivider_greenDot)} />
        </div>
      </div>
    );
  }
}

export default DotDivider;
