import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Placeholder } from 'semantic-ui-react';
import { WINDOW_DIMENSIONS } from '../../constants/index';

const { TABLET_MEDIA_QUERY } = WINDOW_DIMENSIONS;

export const styles = StyleSheet.create({
  RedemptionShimmer_container: {
    width: '40%',
    height: '400px',
    maxWidth: '500px',
    margin: '40px auto',
    [TABLET_MEDIA_QUERY]: {
      height: '80vh',
      width: '90%',
    },
  },
  RedemptionShimmer_placeholderContainer: {
    height: '100%',
    width: '100%',
    borderRadius: '4px',
  },
  RedemptionShimmer_placeholderImage: {
    margin: '100px',
    width: '100%',
  },
});

export default class RedemptionShimmer extends PureComponent {
  public render() {
    return (
      <div className={css(styles.RedemptionShimmer_container)}>
        <Placeholder className={css(styles.RedemptionShimmer_placeholderContainer)} fluid>
          <Placeholder.Image className={css(styles.RedemptionShimmer_placeholderImage)} />
        </Placeholder>
      </div>
    );
  }
}
