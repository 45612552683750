import React, { FunctionComponent } from 'react';
import { css, StyleSheet } from 'aphrodite';

import arbitrumNetworkBadge from '../img/icons/arbitrum-network-badge.svg';
import polygonNetworkBadge from '../img/icons/polygon-network-badge.svg';
import ethereumNetworkBadge from '../img/icons/ethereum-network-badge.svg';
import optimismNetworkBadge from '../img/icons/optimism-network-badge.svg';
import avalancheNetworkBadge from '../img/icons/avalanche-network-badge.svg';

const styles = StyleSheet.create({
  NetworkBadge_icon: {
    height: '16px',
    width: 'auto',
  },
});

import { NETWORK_CONSTANTS } from '../constants/index';

const { POLYGON_CHAIN, OPTIMISM_CHAIN, ARBITRUM_CHAIN, AVALANCHE_CHAIN } = NETWORK_CONSTANTS;

type INetworkBadgeProps = {
  network: string;
};

const NetworkBadge: FunctionComponent<INetworkBadgeProps> = ({ network }: INetworkBadgeProps) => {
  let networkBadge;
  let networkAlt;
  if (network == POLYGON_CHAIN) {
    networkBadge = polygonNetworkBadge;
    networkAlt = 'Polygon icon';
  } else if (network == ARBITRUM_CHAIN) {
    networkBadge = arbitrumNetworkBadge;
    networkAlt = 'Arbitrum icon';
  } else if (network == OPTIMISM_CHAIN) {
    networkBadge = optimismNetworkBadge;
    networkAlt = 'Optimism icon';
  } else if (network == AVALANCHE_CHAIN) {
    networkBadge = avalancheNetworkBadge;
    networkAlt = 'Avalanche icon';
  } else {
    networkBadge = ethereumNetworkBadge;
    networkAlt = 'Ethereum icon';
  }

  return <img src={networkBadge} alt={networkAlt} className={css(styles.NetworkBadge_icon)} />;
};

export default NetworkBadge;
