import { Dispatch } from 'redux';
import axios from 'axios';

import { BANNED_COUNTRIES } from '../constants/index';
import { emptyActionGenerator, payloadActionGenerator } from '../utils/reduxHelpers';
import { ipAddressSelector } from '../selectors/baseSelectors';

/* Action Types */
export const REQUEST_IP_ADDRESS = 'REQUEST_IP_ADDRESS';
export const RECEIVE_IP_ADDRESS = 'RECEIVE_IP_ADDRESS';
export const SET_IS_RESTRICTED_IP_ADDRESS = 'SET_IS_RESTRICTED_IP_ADDRESS';

/* Action Creators */
const requestIpAddress = emptyActionGenerator(REQUEST_IP_ADDRESS);
const receiveIpAddress = payloadActionGenerator(RECEIVE_IP_ADDRESS);
const setIsRestrictedIpAddress = payloadActionGenerator(SET_IS_RESTRICTED_IP_ADDRESS);

export function fetchIpAddress() {
  return (dispatch: Dispatch<Function>, getState: any) => {
    const state = getState();
    const ipAddress = ipAddressSelector(state);

    if (ipAddress) return;

    dispatch(requestIpAddress());

    const url = `https://ipapi.co/json/`;

    return axios
      .get(url, {
        transformRequest: (_: any, headers) => {
          delete headers['X-SET-USER'];
        },
      })
      .then(response => {
        const ipAddress = response?.data?.ip;
        dispatch(receiveIpAddress(ipAddress));

        const isRestrictedIpAddress = BANNED_COUNTRIES.some(
          country => country === response?.data?.country,
        );
        dispatch(setIsRestrictedIpAddress(isRestrictedIpAddress));
      })
      .catch((error: any) => {
        dispatch(receiveIpAddress(null));
        dispatch(setIsRestrictedIpAddress(true));

        if (!error) return;

        console.error(error);
      });
  };
}
