import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { COLORS } from '../../constants/index';

const styles = StyleSheet.create({
  Line: {
    borderTop: `1px solid ${COLORS.blueGray2}`,
  },
});

interface LineProps {
  width?: string;
}

export default function Line({ width }: LineProps) {
  return (
    <div>
      <hr className={css(styles.Line)} style={{ width: width ? width : '100%' }} />
    </div>
  );
}
