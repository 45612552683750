import { css, StyleSheet } from 'aphrodite';
import React, { Fragment, PureComponent } from 'react';
import { Header, Icon, Label, Modal } from 'semantic-ui-react';
import { QRCode } from 'react-qr-svg';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CSSTransition } from 'react-transition-group';

import { COLORS, STYLES, WINDOW_DIMENSIONS } from '../../constants/index';
import { Button, Confetti } from '../../components/index';
import { BigNumber, truncateTokenAmount } from '../../utils/index';

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  OnboardingModal_container: {
    marginBottom: '100px',
    borderTop: `5px solid ${COLORS.blue}`,
    borderRadius: '5px',
    backgroundColor: COLORS.white,
    padding: '32px',
    transition: '0.2s',
  },
  OnboardingModal_copyContainer: {
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '4px',
    display: 'block',
    marginBottom: '12px',
    padding: '20px',
    position: 'relative',
  },
  OnboardingModal_header1: {
    color: COLORS.darkBlue,
    width: '100%',
    fontSize: '36px',
    fontWeight: 500,
  },
  OnboardingModal_header2: {
    color: COLORS.darkBlue,
    marginBottom: '32px',
    marginTop: '32px',
    width: '100%',
    fontSize: '20px',
    fontWeight: 500,
  },
  OnboardingModal_header3: {
    color: COLORS.darkGray,
    marginBottom: '32px',
    marginTop: '32px',
    width: '100%',
    fontSize: '20px',
    fontWeight: 400,
  },
  OnboardingModal_balance: {
    color: COLORS.darkBlue,
    display: 'inline',
    fontSize: '48px',
    fontWeight: 500,
  },
  OnboardingModal_balanceContainer: {
    marginBottom: '16px',
  },
  OnboardingModal_currentBalanceText: {
    fontWeight: 500,
  },
  OnboardingModal_description: {
    marginBottom: '32px',
  },
  OnboardingModal_ethSymbol: {
    fontWeight: 500,
    marginLeft: '3px',
  },
  OnboardingModal_label: {
    bottom: 40,
    left: '42%',
    display: 'none',
    position: 'absolute',
    transition: '0.2s',
    zIndex: 100000,
    [MOBILE_MEDIA_QUERY]: {
      left: '40%',
    },
  },
  OnboardingModal_labelShow: {
    display: 'block',
    animationName: [STYLES.fadeInDown],
    animationDuration: '0.2s',
    animationIterationCount: 'once',
    zIndex: 100000,
  },
  OnboardingModal_qrCode: {
    width: 256,
    marginBottom: '35px',
  },
  OnboardingModal_qrCodeContainer: {
    textAlign: 'center',
  },
  OnboardingModal_qrCodeText: {
    marginTop: '-10px',
    marginBottom: '10px',
  },
  OnboardingModal_showQRCode: {
    cursor: 'pointer',
    display: 'inline-block',
    marginBottom: '16px',
    textDecoration: 'underline',
    transition: '0.2s',
    ':hover': {
      color: COLORS.darkBlue,
    },
  },
  OnboardingModal_showQRCodeContainer: {
    marginBottom: '20px',
    textAlign: 'center',
  },
  OnboardingModal_copyText: {
    color: COLORS.blue,
    fontSize: '14px',
    cursor: 'pointer',
    transition: '0.2s',
    wordBreak: 'break-all',
    ':hover': {
      color: COLORS.darkBlue,
    },
  },
  OnboardingModal_skip: {
    cursor: 'pointer',
    fontSize: '12px',
    textDecoration: 'underline',
    textAlign: 'center',
    marginTop: '25px',
    marginBottom: '-25px',
    transition: '0.2s',
    ':hover': {
      color: COLORS.darkBlue,
    },
    ':active': {
      transform: 'scale(0.98)',
    },
  },
  OnboardingModal_confetti: {
    marginLeft: '165px',
  },
});

interface IOnboardingModalProps {
  isModalOpen: boolean;
  isTabletLandscape: boolean;
  account: string;
  ethBalance: string;
  onClose: (...args: any[]) => any;
  fetchBalances: (...args: any[]) => any;
}

interface IOnboardingModalState {
  isShowingQRCode: boolean;
  hasCopied: boolean;
  pollBalanceInterval: any;
}

/**
 * @title OnboardingModal
 * @author Set Labs
 *
 * The OnboardingModal is the modal that prompts the user to add ETH to their account in order to start using TokenSets.
 */
class OnboardingModal extends PureComponent<IOnboardingModalProps, IOnboardingModalState> {
  constructor(props: any) {
    super(props);

    this.state = {
      isShowingQRCode: false,
      hasCopied: false,
      pollBalanceInterval: undefined,
    };
  }

  componentDidUpdate() {
    const { isModalOpen } = this.props;
    const { pollBalanceInterval } = this.state;
    if (isModalOpen && !pollBalanceInterval) {
      this.pollBalance();
    }
  }

  componentWillUnmount() {
    const { pollBalanceInterval } = this.state;
    clearInterval(pollBalanceInterval);
  }

  public pollBalance = () => {
    const { account, fetchBalances } = this.props;
    if (account) {
      const pollBalanceInterval = setInterval(() => fetchBalances(), 5000);
      this.setState({ pollBalanceInterval });
    } else {
      setTimeout(this.pollBalance, 1000);
    }
  };

  /**
   * Sets hasCopied state to display 'Copied!' when user copies their address
   */
  public onCopy = () => {
    const { hasCopied } = this.state;
    if (!hasCopied) {
      this.setState({ hasCopied: true });
      setTimeout(() => this.setState({ hasCopied: false }), 2000);
    }
  };

  public renderEmptyWalletMessage = (isEthBalanceZero: boolean) => {
    return (
      <CSSTransition in={isEthBalanceZero} timeout={300} classNames="fade">
        <Fragment>
          <Header as="h3" className={css(styles.OnboardingModal_header1)}>
            Welcome! 👋
          </Header>
          <Header as="h3" className={css(styles.OnboardingModal_header2)}>
            Fresh account? Let’s add some ETH!
          </Header>
          <p className={css(styles.OnboardingModal_description)}>
            All Sets are bought and sold using ETH. Send or withdraw ETH to your account below from
            any exchange or wallet you hold ETH in.
          </p>
        </Fragment>
      </CSSTransition>
    );
  };

  public renderFilledWalletMessage = (isEthBalanceZero: boolean) => {
    return (
      <CSSTransition in={!isEthBalanceZero} timeout={300} classNames="fade">
        <Fragment>
          <Header as="h3" className={css(styles.OnboardingModal_header1)}>
            Perfect! 🎉
          </Header>
          <Header as="h3" className={css(styles.OnboardingModal_header3)}>
            Loaded up on ETH and ready to go!
          </Header>
        </Fragment>
      </CSSTransition>
    );
  };

  public render() {
    const { account, ethBalance, isModalOpen, isTabletLandscape, onClose } = this.props;
    const { isShowingQRCode, hasCopied } = this.state;
    const labelDisplayClass = hasCopied ? styles.OnboardingModal_labelShow : undefined;
    const isEthBalanceZero = new BigNumber(ethBalance || '0').isZero();

    return (
      <Modal
        open={isModalOpen}
        onClose={onClose}
        size="tiny"
        closeIcon={isTabletLandscape}
        className={`${css(styles.OnboardingModal_container)} scrolling`}
      >
        <Modal.Content>
          <div className={css(styles.OnboardingModal_confetti)}>
            <Confetti isActive={!isEthBalanceZero} />
          </div>
          <CSSTransition in={isModalOpen} timeout={1000} classNames="fade" unmountOnExit>
            <Fragment>
              {isEthBalanceZero
                ? this.renderEmptyWalletMessage(isEthBalanceZero)
                : this.renderFilledWalletMessage(isEthBalanceZero)}
              <div className={css(styles.OnboardingModal_balanceContainer)}>
                <p className={css(styles.OnboardingModal_currentBalanceText)}>
                  Your Current Balance
                </p>
                <Header as="h4" className={css(styles.OnboardingModal_balance)}>
                  {truncateTokenAmount(ethBalance)}
                </Header>
                <span className={css(styles.OnboardingModal_ethSymbol)}>ETH</span>
              </div>
              <p>Send ETH to your account below</p>
              <div className={css(styles.OnboardingModal_copyContainer)}>
                <Label
                  basic
                  className={css(styles.OnboardingModal_label, labelDisplayClass)}
                  pointing="below"
                  content="Copied!"
                />
                <CopyToClipboard text={account} onCopy={this.onCopy}>
                  <p className={css(styles.OnboardingModal_copyText)}>
                    {account}
                    <Icon name="copy outline" />
                  </p>
                </CopyToClipboard>
              </div>
              <div className={css(styles.OnboardingModal_showQRCodeContainer)}>
                {!isShowingQRCode ? (
                  <p
                    className={css(styles.OnboardingModal_showQRCode)}
                    onClick={() => this.setState({ isShowingQRCode: true })}
                  >
                    Show QR Code
                  </p>
                ) : (
                  <p
                    className={css(styles.OnboardingModal_showQRCode)}
                    onClick={() => this.setState({ isShowingQRCode: false })}
                  >
                    Hide QR Code
                  </p>
                )}
              </div>
              <CSSTransition in={isShowingQRCode} timeout={300} classNames="fade" unmountOnExit>
                <div>
                  <p className={css(styles.OnboardingModal_qrCodeText)}>
                    If you’re using a mobile wallet, scan this QR code to instantly copy the
                    address.
                  </p>
                  <div className={css(styles.OnboardingModal_qrCodeContainer)}>
                    <QRCode
                      className={css(styles.OnboardingModal_qrCode)}
                      bgColor="#FFFFFF"
                      fgColor="#000000"
                      level="Q"
                      value={account}
                    />
                  </div>
                </div>
              </CSSTransition>
              <Button
                text={'Finish'}
                onClick={onClose}
                isDisabled={isEthBalanceZero}
                style={{
                  container: {
                    width: '100%',
                    display: 'block',
                    padding: '25px 20px',
                    backgroundColor: COLORS.blue,
                  },
                }}
              />
              {isEthBalanceZero && (
                <p className={css(styles.OnboardingModal_skip)} onClick={onClose}>
                  Skip
                </p>
              )}
            </Fragment>
          </CSSTransition>
        </Modal.Content>
      </Modal>
    );
  }
}

export default (OnboardingModal as any) as React.SFC<IOnboardingModalProps>;
