import React, { useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { COLORS } from '../../constants/index';

const styles = StyleSheet.create({
  FormContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  InputWrapper: {
    border: `1px solid ${COLORS.blueGray}`,
    height: '38px',
    paddingLeft: '0.5em',
    borderRadius: '5px',
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center',
  },
  Input: {
    border: 0,
    textAlign: 'end',
    width: '80%',
    fontSize: '16px',
    paddingRight: '0.5em',
  },
  ErrorInput: {
    backgroundColor: COLORS.pink2,
  },
  Symbol: {
    width: '20%',
    textAlign: 'center',
  },
  hover: {
    ':hover': {
      border: `1px solid ${COLORS.lightBlue6}`,
    },
  },
  focus: {
    boxShadow: `0px 0px 0px 2px ${COLORS.blueShadow}`,
    border: `1px solid ${COLORS.lightBlue6}`,
  },
  focusError: {
    boxShadow: `0px 0px 0px 2px ${COLORS.redShadow}`,
    border: `1px solid ${COLORS.red2}`,
  },
  HelperText: {
    color: COLORS.lightGray5,
  },
  error: {
    backgroundColor: COLORS.pink2,
    border: `1px solid ${COLORS.red2}`,
  },
  errorText: {
    color: COLORS.red2,
    fontWeight: 500,
  },
});

interface PercentTextFieldProps {
  disabled?: boolean;
  name: string;
  placeholder?: string;
  label?: string;
  helperText?: string;
  onChange: any;
  value: any;
  width?: string;
  max?: number;
}

export default function PercentTextField({
  disabled,
  name,
  placeholder = 'Placeholder',
  label = 'Field label',
  helperText,
  onChange,
  value,
  width,
  max = 100,
}: PercentTextFieldProps) {
  const [inFocus, setInFocus] = useState(false);
  const [error, setError] = useState('');

  const handleOnFocus = () => setInFocus(true);
  const handleOnBlur = () => {
    setInFocus(false);
  };

  const handleChange = (e: any) => {
    if (Number(e.target.value) > max) {
      setError(`Must be below or equal to ${max}`);
    } else if (Number(e.target.value) < 0) {
      setError('Must be above 0');
    } else if (!e.target.value) {
      setError('Field is empty');
    } else {
      setError('');
    }
    onChange(e);
  };

  return (
    <div
      style={{
        width: width ? width : '205px',
      }}
      className={css(styles.FormContainer)}
    >
      <label>{label}</label>
      <div
        className={css(
          styles.InputWrapper,
          styles.hover,
          inFocus && (error ? styles.focusError : styles.focus),
          error && styles.error,
        )}
      >
        <input
          className={css(error && styles.ErrorInput, styles.Input)}
          type={'number'}
          name={name}
          min={0}
          max={max}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          disabled={disabled}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
        />
        <span className={css(styles.Symbol)}>%</span>
      </div>
      {(helperText || error) && (
        <label className={css(error ? styles.errorText : styles.HelperText)}>
          {error ? error : helperText}
        </label>
      )}
    </div>
  );
}
