import { css } from 'aphrodite';
import React, { PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Container, Header } from 'semantic-ui-react';

import styles from './homeStyles';

interface IHomeTrustedBySectionProps {
  isTablet?: boolean;
}

/**
 * @title HomeTrustedBy
 * @author Set Labs
 *
 * The HomeTrustedBy section shows other teams and companies that use Set.
 */
class HomeTrustedBy extends PureComponent<IHomeTrustedBySectionProps & WithTranslation> {
  render() {
    const { isTablet, t } = this.props;

    return (
      <Container fluid className={css(styles.HomeTrustedBy_container)}>
        <Container>
          <Header className={css(styles.HomeTrustedBy_header)}>
            {t('home-trusted-by-section.title')}
          </Header>
          {isTablet ? (
            <div className={css(styles.HomeTrustedBy_logosContainer)}>
              <div
                className={css(
                  styles.HomeTrustedBy_logosRow,
                  styles.HomeTrustedBy_logosMarginBottom,
                )}
              >
                <img
                  alt="DeFi Pulse Logo"
                  className={css(styles.HomeTrustedBy_logo)}
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/defi-pulse-logo.svg"
                />
                <img
                  alt="Coinshares Logo"
                  className={css(styles.HomeTrustedBy_logo)}
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/coinshares-logo.png"
                />
              </div>
              <div
                className={css(
                  styles.HomeTrustedBy_logosRow,
                  styles.HomeTrustedBy_logosMarginBottom,
                )}
              >
                <img
                  alt="EmberFund Logo"
                  className={css(styles.HomeTrustedBy_logo)}
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/ember-fund-logo.png"
                />
                <img
                  alt="SW Capital Logo"
                  className={css(styles.HomeTrustedBy_logo)}
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/sw-capital-logo.png"
                />
              </div>
              <div
                className={css(
                  styles.HomeTrustedBy_logosRow,
                  styles.HomeTrustedBy_logosMarginBottom,
                )}
              >
                <img
                  alt="Byte Tree Logo"
                  className={css(styles.HomeTrustedBy_logo)}
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/bytetree-logo.png"
                />
                <img
                  alt="Yam Logo"
                  className={css(styles.HomeTrustedBy_logo)}
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/yam-logo.png"
                />
              </div>
              <div
                className={css(
                  styles.HomeTrustedBy_logosRow,
                  styles.HomeTrustedBy_logosMarginBottom,
                )}
              >
                <img
                  alt="Bankless Logo"
                  className={css(styles.HomeTrustedBy_logo)}
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/bankless-logo.png"
                />
                <img
                  alt="Moonquant Capital Logo"
                  className={css(styles.HomeTrustedBy_logo)}
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/moonquant-capital-logo.png"
                />
              </div>
              <div className={css(styles.HomeTrustedBy_logosRow)}>
                <img
                  alt="Index Coop Logo"
                  className={css(styles.HomeTrustedBy_logo)}
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/index-logo.svg"
                />
              </div>
            </div>
          ) : (
            <div className={css(styles.HomeTrustedBy_logosContainer)}>
              <div
                className={css(
                  styles.HomeTrustedBy_logosRow,
                  styles.HomeTrustedBy_logosMarginBottom,
                )}
              >
                <img
                  alt="DeFi Pulse Logo"
                  className={css(styles.HomeTrustedBy_logo)}
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/defi-pulse-logo.svg"
                />
                <img
                  alt="Coinshares Logo"
                  className={css(styles.HomeTrustedBy_logo)}
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/coinshares-logo.png"
                />
                <img
                  alt="EmberFund Logo"
                  className={css(styles.HomeTrustedBy_logo)}
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/ember-fund-logo.png"
                />
                <img
                  alt="SW Capital Logo"
                  className={css(styles.HomeTrustedBy_logo)}
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/sw-capital-logo.png"
                />
                <img
                  alt="Bankless Logo"
                  className={css(styles.HomeTrustedBy_logo)}
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/bankless-logo.png"
                />
              </div>
              <div
                className={css(
                  styles.HomeTrustedBy_logosRow,
                  styles.HomeTrustedBy_logosLastRow,
                  styles.HomeTrustedBy_logosMarginBottom,
                )}
              >
                <img
                  alt="Index Coop Logo"
                  className={css(styles.HomeTrustedBy_logo)}
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/index-logo.svg"
                />
                <img
                  alt="Yam Logo"
                  className={css(styles.HomeTrustedBy_logo)}
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/yam-logo.png"
                />
                <img
                  alt="Byte Tree Logo"
                  className={css(styles.HomeTrustedBy_logo)}
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/bytetree-logo.png"
                />
                <img
                  alt="Moonquant Capital Logo"
                  className={css(styles.HomeTrustedBy_logo)}
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/moonquant-capital-logo.png"
                />
              </div>
            </div>
          )}
        </Container>
      </Container>
    );
  }
}

export default withTranslation('home')(HomeTrustedBy);
