import _ from 'lodash';
import { toChecksumAddress } from 'tochecksum';
import { emptyActionGenerator, payloadActionGenerator } from '../utils/reduxHelpers';
import { batchFetchSetDetails } from './setDetailsActions';
import {
  ISetDetailsHydrated,
  IListToken,
  IPositionHydrated,
  GraphSetToken,
} from '../typings/index';
import { fetchCoingeckoTokenList, fetchTokenSetsTokenList } from './tokenListsActions';
import { chunkArray } from '../utils/formatUtils';
import { CORE_SET_TOKEN_FIELDS } from '../queries/index';
import { gql } from '@apollo/client';
import { apolloClientSelector } from '../selectors/apolloSelectors';

export const REQUEST_DEPLOYED_SET_ADDRESSES = 'REQUEST_DEPLOYED_SET_ADDRESSES';
export const RECEIVE_DEPLOYED_SET_ADDRESSES = 'RECEIVE_DEPLOYED_SET_ADDRESSES';
export const RECEIVE_DEPLOYED_SET_ADDRESSES_ERROR = 'RECEIVE_DEPLOYED_SET_ADDRESSES_ERROR';

export const REQUEST_DEPLOYED_SETS_WITH_DETAILS = 'REQUEST_DEPLOYED_SETS_WITH_DETAILS';
export const RECEIVE_DEPLOYED_SETS_WITH_DETAILS = 'RECEIVE_DEPLOYED_SETS_WITH_DETAILS;';
export const RECEIVE_DEPLOYED_SETS_WITH_DETAILS_ERROR = 'RECEIVE_DEPLOYED_SETS_WITH_DETAILS_ERROR';

export const requestDeployedSetAddresses = emptyActionGenerator(REQUEST_DEPLOYED_SET_ADDRESSES);
export const receiveDeployedSetAddresses = payloadActionGenerator(RECEIVE_DEPLOYED_SET_ADDRESSES);
export const receiveDeployedSetAddressesError = emptyActionGenerator(
  RECEIVE_DEPLOYED_SET_ADDRESSES_ERROR,
);

export const requestDeployedSetsWithDetails = emptyActionGenerator(
  REQUEST_DEPLOYED_SETS_WITH_DETAILS,
);
export const receiveDeployedSetsWithDetails = payloadActionGenerator(
  RECEIVE_DEPLOYED_SETS_WITH_DETAILS,
);
export const receiveDeployedSetsWithDetailsError = payloadActionGenerator(
  RECEIVE_DEPLOYED_SETS_WITH_DETAILS_ERROR,
);

const SEARCH_OWNED_SETS_QUERY = gql`
  ${CORE_SET_TOKEN_FIELDS}
  query SearchOwnedSets($ownedAddresses: [String!], $lastInception: Int!) {
    setTokens(
      first: 1000
      where: { address_in: $ownedAddresses, inception_gt: $lastInception }
      orderBy: inception
    ) {
      ...CoreSetTokenFields
    }
  }
`;

/*
  Compares the list of ownedAddresses with the list of set addresses using Graph query to see which ones are owned
*/
export const fetchOwnedSetAddresses = (ownedAddresses: string[]) => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const apolloClient = apolloClientSelector(state);

  try {
    dispatch(requestDeployedSetAddresses());

    let lastInception = 0;
    let currentSetTokensPage: GraphSetToken[] = [];
    let ownedSetAddresses: string[] = [];
    const lowercasedOwnedAddresses = ownedAddresses.map(address => address.toLowerCase());
    while (currentSetTokensPage.length || lastInception === 0) {
      const graphResponse: {
        data: { setTokens: GraphSetToken[] };
      } = await apolloClient.query({
        query: SEARCH_OWNED_SETS_QUERY,
        variables: {
          ownedAddresses: lowercasedOwnedAddresses,
          lastInception: Number(lastInception),
        },
      });

      currentSetTokensPage = graphResponse?.data?.setTokens;
      ownedSetAddresses = ownedSetAddresses.concat(
        currentSetTokensPage.map((token: GraphSetToken) => token.address),
      );
      lastInception = currentSetTokensPage[currentSetTokensPage?.length - 1]
        ? currentSetTokensPage[currentSetTokensPage?.length - 1].inception
        : lastInception;

      if (!currentSetTokensPage.length) {
        break;
      }
    }

    const checksummedAddresses = ownedSetAddresses.map(address => toChecksumAddress(address));
    dispatch(receiveDeployedSetAddresses(checksummedAddresses));

    return checksummedAddresses;
  } catch (e) {
    dispatch(receiveDeployedSetAddressesError());
    console.log('could not fetch deployed set addresses', e);
  }
};

export const fetchDeployedSetsWithLogos = (addresses: string[]) => async (dispatch: any) => {
  if (!addresses?.length) {
    dispatch(receiveDeployedSetsWithDetails({}));
    return;
  }

  const setDetailsMapping: { [address: string]: ISetDetailsHydrated } = {};
  const coinPriceMapping: { [address: string]: string } = {};
  const tokenListMapping: { [address: string]: IListToken } = {};
  const tokenSetsListMapping: { [address: string]: IListToken } = {};

  try {
    dispatch(requestDeployedSetsWithDetails());

    const tokenList = await dispatch(fetchCoingeckoTokenList());
    tokenList.forEach((token: IListToken) => {
      tokenListMapping[token.address.toLowerCase()] = token;
    });

    const tokenSetsList = await dispatch(fetchTokenSetsTokenList());

    tokenSetsList?.forEach((token: IListToken) => {
      tokenSetsListMapping[token.address.toLowerCase()] = token;
    });

    const splitAddresses = chunkArray(addresses, 10);

    const setDetailsResults = await Promise.all(
      splitAddresses.map(addressChunk => dispatch(batchFetchSetDetails(addressChunk))),
    );
    const setDetails = [].concat(...setDetailsResults);

    for (let i = 0; i < setDetails.length; i++) {
      const setDetail: ISetDetailsHydrated = setDetails[i];

      const newPositions: IPositionHydrated[] = [];
      setDetail.positions.forEach((position: IPositionHydrated) => {
        const componentAddress = position.component.toLowerCase();
        const newPosition = {
          ...position,
        };
        newPosition.logoURI =
          tokenSetsListMapping[componentAddress]?.logoURI ||
          tokenListMapping[componentAddress]?.logoURI ||
          'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-set-token-icon.svg';
        newPosition.priceUsd = coinPriceMapping[componentAddress] || '0';
        newPosition.decimals = tokenListMapping[componentAddress]?.decimals;

        newPositions.push(newPosition);
      });

      const newSetDetail = {
        ...setDetail,
        address: addresses[i],
      };

      newSetDetail.positions = newPositions;
      newSetDetail.logoURI =
        tokenSetsListMapping[addresses[i].toLowerCase()]?.logoURI ||
        'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-set-token-icon.svg';

      setDetailsMapping[toChecksumAddress(newSetDetail.address)] = newSetDetail;
    }

    dispatch(receiveDeployedSetsWithDetails(setDetailsMapping));

    return setDetailsMapping;
  } catch (err) {
    console.log('could not fetch deployed set details', err);
    dispatch(receiveDeployedSetsWithDetailsError(err));
  }
};
