import { getWeb3Instance } from '../utils/index';

interface ISimpleResolveTransactionArg {
  txPromise: any;
}

/**
 * Sends an ethereum transfer transaction, but returns a simple transaction id instead of mined ethereum block data.
 * Returning transaction id this way matches the erc20 transfer interface.
 * @param transactionOptions - Standard ether transfer transaction options.
 */
export const transferEtherTransaction = async (transactionOptions: any): Promise<string> => {
  const web3Instance = await getWeb3Instance();
  return new Promise((resolve, reject) => {
    web3Instance.eth
      .sendTransaction(transactionOptions)
      .on('transactionHash', (txId: string) => {
        if (!txId) reject();

        resolve(txId);
      })
      .on('error', (error: any) => {
        reject(error);
      });
  });
};

/**
 * Resolves transaction promise and returns control via promise.
 */
export const simpleResolveTransaction = async ({ txPromise }: ISimpleResolveTransactionArg) => {
  return txPromise.then(async (transaction: any) => {
    let txId = transaction;

    if (transaction && transaction.tx) {
      txId = transaction.tx;
    }

    if (transaction && transaction.hash) {
      txId = transaction.hash;
    }

    return txId;
  });
};
