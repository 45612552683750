import { css } from 'aphrodite';
import React, { PureComponent } from 'react';
import { Container, Grid, Placeholder, Header, Table } from 'semantic-ui-react';
import _ from 'lodash';

import styles from './CustomSetExploreTableStyles';

interface ICustomSetExploreTableShimmerProps {
  exploreHeader?: string;
  isTabletLandscape?: boolean;
  isTruncatedList?: boolean;
  isShowingAdvancedData?: boolean;
}

class CustomSetExploreTableShimmer extends PureComponent<ICustomSetExploreTableShimmerProps> {
  public render() {
    const { exploreHeader, isTabletLandscape, isTruncatedList, isShowingAdvancedData } = this.props;
    const withoutTableBorderStyle = !isShowingAdvancedData
      ? styles.CustomSetExploreTable_rowCellWithoutTableBorder
      : undefined;

    return (
      <Container>
        {isTabletLandscape ? (
          <>
            {!isTruncatedList && (
              <Header as="h3" className={css(styles.CustomSetExploreTable_headerText)}>
                {exploreHeader}
              </Header>
            )}
          </>
        ) : (
          <div className={css(styles.CustomSetExploreTable_settingsBar)}>
            {!isTruncatedList && (
              <Header as="h3" className={css(styles.CustomSetExploreTable_headerText)}>
                {exploreHeader}
              </Header>
            )}
          </div>
        )}
        {!isTabletLandscape ? (
          <div className={css(styles.CustomSetExploreTable_container)}>
            <Table className={css(styles.CustomSetExploreTable_innerContainer)}>
              <Table.Header className={css(styles.CustomSetExploreTable_header)}>
                <Table.Row>
                  <Table.HeaderCell
                    style={{ width: '212px' }}
                    className={css(styles.CustomSetExploreTable_headerCell)}
                  >
                    <Placeholder>
                      <Placeholder.Header>
                        <Placeholder.Line />
                      </Placeholder.Header>
                    </Placeholder>
                  </Table.HeaderCell>
                  <Table.HeaderCell className={css(styles.CustomSetExploreTable_headerCell)}>
                    <Placeholder style={{ margin: 'auto' }}>
                      <Placeholder.Header>
                        <Placeholder.Line />
                      </Placeholder.Header>
                    </Placeholder>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Placeholder>
                      <Placeholder.Header>
                        <Placeholder.Line />
                      </Placeholder.Header>
                    </Placeholder>
                  </Table.HeaderCell>
                  <Table.HeaderCell className={css(styles.CustomSetExploreTable_headerCell)}>
                    <Placeholder>
                      <Placeholder.Header>
                        <Placeholder.Line />
                      </Placeholder.Header>
                    </Placeholder>
                  </Table.HeaderCell>
                  <Table.HeaderCell className={css(styles.CustomSetExploreTable_headerCell)}>
                    <Placeholder>
                      <Placeholder.Header>
                        <Placeholder.Line />
                      </Placeholder.Header>
                    </Placeholder>
                  </Table.HeaderCell>
                  <Table.HeaderCell className={css(styles.CustomSetExploreTable_headerCell)}>
                    <Placeholder>
                      <Placeholder.Header>
                        <Placeholder.Line />
                      </Placeholder.Header>
                    </Placeholder>
                  </Table.HeaderCell>
                  <Table.HeaderCell className={css(styles.CustomSetExploreTable_headerCell)}>
                    <Placeholder>
                      <Placeholder.Header>
                        <Placeholder.Line />
                      </Placeholder.Header>
                    </Placeholder>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {_.range(isTruncatedList ? 5 : 10).map((id: number) => {
                return (
                  <Table.Body
                    key={`${id}-desktop`}
                    className={css(styles.CustomSetExploreTable_tableRow)}
                  >
                    <Table.Row>
                      <Table.Cell
                        className={css(
                          styles.CustomSetExploreTable_setNameCell,
                          withoutTableBorderStyle,
                        )}
                      >
                        <div className={css(styles.CustomSetExploreTable_iconShimmerContainer)}>
                          <Placeholder
                            className={css(
                              styles.CustomSetExploreTable_setIcon,
                              styles.CustomSetExploreTable_iconHolderShimmer,
                            )}
                            style={{ width: '28px', height: '28px' }}
                          >
                            <Placeholder.Image />
                          </Placeholder>
                        </div>
                        <div className={css(styles.CustomSetExploreTable_nameShimmerContainer)}>
                          <Placeholder>
                            <Placeholder.Paragraph>
                              <Placeholder.Line />
                            </Placeholder.Paragraph>
                          </Placeholder>
                        </div>
                      </Table.Cell>
                      <Table.Cell
                        className={css(
                          styles.CustomSetExploreTable_bodyCell,
                          withoutTableBorderStyle,
                        )}
                      >
                        <div className={css(styles.CustomSetExploreTable_iconShimmerContainer)}>
                          <Placeholder
                            className={css(
                              styles.CustomSetExploreTable_setIcon,
                              styles.CustomSetExploreTable_iconHolderShimmer,
                            )}
                            style={{ width: '28px', height: '28px' }}
                          >
                            <Placeholder.Image />
                          </Placeholder>
                        </div>
                      </Table.Cell>
                      <Table.Cell
                        className={css(
                          styles.CustomSetExploreTable_bodyCell,
                          withoutTableBorderStyle,
                        )}
                      >
                        <Placeholder style={{ width: '40px' }}>
                          <Placeholder.Paragraph>
                            <Placeholder.Line />
                          </Placeholder.Paragraph>
                        </Placeholder>
                      </Table.Cell>
                      <Table.Cell
                        className={css(
                          styles.CustomSetExploreTable_bodyCell,
                          withoutTableBorderStyle,
                        )}
                      >
                        <Placeholder style={{ width: '40px' }}>
                          <Placeholder.Paragraph>
                            <Placeholder.Line />
                          </Placeholder.Paragraph>
                        </Placeholder>
                      </Table.Cell>
                      <Table.Cell
                        className={css(
                          styles.CustomSetExploreTable_bodyCell,
                          styles.CustomSetExploreTable_tableTrendCell,
                          withoutTableBorderStyle,
                        )}
                      >
                        <Placeholder style={{ borderRadius: '4px', height: '20px', width: '65px' }}>
                          <Placeholder.Paragraph>
                            <Placeholder.Image />
                          </Placeholder.Paragraph>
                        </Placeholder>
                      </Table.Cell>
                      <Table.Cell
                        className={css(
                          styles.CustomSetExploreTable_bodyCell,
                          styles.CustomSetExploreTable_tableTrendCell,
                          withoutTableBorderStyle,
                        )}
                      >
                        <Placeholder style={{ borderRadius: '4px', height: '20px', width: '65px' }}>
                          <Placeholder.Paragraph>
                            <Placeholder.Image />
                          </Placeholder.Paragraph>
                        </Placeholder>
                      </Table.Cell>
                      <Table.Cell
                        className={css(
                          styles.CustomSetExploreTable_bodyCell,
                          styles.CustomSetExploreTable_tableTrendCell,
                          withoutTableBorderStyle,
                        )}
                      >
                        <Placeholder style={{ borderRadius: '4px', height: '20px', width: '65px' }}>
                          <Placeholder.Paragraph>
                            <Placeholder.Image />
                          </Placeholder.Paragraph>
                        </Placeholder>
                      </Table.Cell>
                    </Table.Row>

                    {/* Allocation & Set Badge Row */}
                    {isShowingAdvancedData && (
                      <Table.Row>
                        <Table.Cell
                          className={css(
                            styles.CustomSetExploreTable_secondaryBodyCell,
                            withoutTableBorderStyle,
                          )}
                        >
                          <Placeholder
                            style={{
                              borderRadius: '20px',
                              width: '60px',
                              height: '18px',
                              marginLeft: '10px',
                            }}
                          >
                            <Placeholder.Paragraph>
                              <Placeholder.Line />
                            </Placeholder.Paragraph>
                          </Placeholder>
                        </Table.Cell>
                        <Table.Cell
                          colSpan="10"
                          className={css(
                            styles.CustomSetExploreTable_secondaryBodyCell,
                            withoutTableBorderStyle,
                          )}
                        >
                          <Placeholder
                            style={{ minWidth: '100%', height: '10px', borderRadius: '20px' }}
                          >
                            <Placeholder.Paragraph>
                              <Placeholder.Line />
                            </Placeholder.Paragraph>
                          </Placeholder>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                );
              })}
            </Table>
          </div>
        ) : (
          <div>
            {_.range(isTruncatedList ? 5 : 10).map((id: number) => {
              return (
                <Grid.Row
                  key={`${id}-mobile`}
                  className={css(styles.CustomSetExploreTable_tableRow)}
                >
                  <div className={css(styles.CustomSetExploreTable_setNameCell)}>
                    <div className={css(styles.CustomSetExploreTable_setNameAndImage)}>
                      <div className={css(styles.CustomSetExploreTable_iconShimmerContainer)}>
                        <Placeholder
                          className={css(
                            styles.CustomSetExploreTable_setIcon,
                            styles.CustomSetExploreTable_iconHolderShimmer,
                          )}
                          style={{ width: '28px', height: '28px' }}
                        >
                          <Placeholder.Image />
                        </Placeholder>
                      </div>
                      <div className={css(styles.CustomSetExploreTable_nameShimmerContainer)}>
                        <Placeholder>
                          <Placeholder.Paragraph>
                            <Placeholder.Line />
                          </Placeholder.Paragraph>
                        </Placeholder>
                      </div>
                    </div>
                    {isShowingAdvancedData && (
                      <Placeholder
                        style={{
                          minWidth: '90%',
                          height: '10px',
                          borderRadius: '10px',
                          marginTop: '20px',
                          marginLeft: '14px',
                        }}
                      >
                        <Placeholder.Paragraph>
                          <Placeholder.Line />
                        </Placeholder.Paragraph>
                      </Placeholder>
                    )}
                  </div>
                  <div className={css(styles.CustomSetExploreTable_bodyCell)}>
                    <div className={css(styles.CustomSetExploreTable_flowColumn)}>
                      <div style={{ marginBottom: '10px' }}>
                        <Placeholder style={{ width: '50px' }}>
                          <Placeholder.Paragraph>
                            <Placeholder.Line />
                          </Placeholder.Paragraph>
                        </Placeholder>
                      </div>
                    </div>
                  </div>
                </Grid.Row>
              );
            })}
          </div>
        )}
      </Container>
    );
  }
}

export default CustomSetExploreTableShimmer;
