import React from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { css } from 'aphrodite';
import { withTranslation, WithTranslation } from 'react-i18next';

import { GraphSetToken, ISetDetailsHydrated, IWindowDimensions } from '../../typings/index';
import { Text } from '../../components/index';
import { styles } from './Account';
import { BigNumber } from '../../utils/index';
import { toChecksumAddress } from 'tochecksum';
import { toFixed2Locale } from '../../utils/formatUtils';

interface IManagedSetsHeader {
  networkBadge: string;
  windowDimension: IWindowDimensions;
  deployedSetDetailsHydrated: { [address: string]: ISetDetailsHydrated };
  managerTokens: GraphSetToken[];
}

const ManagedSetsHeader = (props: IManagedSetsHeader & WithTranslation) => {
  const {
    windowDimension: { isTablet },
    deployedSetDetailsHydrated,
    managerTokens,
    networkBadge,
  } = props;

  const tokenAddresses = managerTokens.map((token: GraphSetToken) => token.address);

  const totalMarketCapUsd = tokenAddresses.reduce((totalValue: BigNumber, address: string) => {
    const checksumAddress = toChecksumAddress(address);
    const setDetails = deployedSetDetailsHydrated[checksumAddress];
    const priceUsd = new BigNumber(setDetails?.marketCap || 0);

    return totalValue.plus(priceUsd);
  }, new BigNumber(0));

  return (
    <Grid>
      <Grid.Row columns={isTablet ? 1 : 2}>
        <Grid.Column width={16} textAlign="center">
          <div className={css(styles.Account_headerContainer)}>
            <Text className={css(styles.Account_totalBalanceText)}>Total Assets Managed</Text>
            <Header as="h1" className={css(styles.Account_totalBalance)}>
              ${toFixed2Locale(totalMarketCapUsd)}
            </Header>
            <img className={css(styles.Account_networkBadge)} src={networkBadge} style={{}} />
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default withTranslation('account')(ManagedSetsHeader);
