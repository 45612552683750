import { connect } from 'react-redux';
import DebtIssuanceForm from './DebtIssuanceForm';
import { updateIssuanceQuantity } from '../../actions/issuanceV2Actions';
import {
  setMaxDebtIssuableQuantity,
  debtIssueCurrentSet,
  fetchFeesForIssueQuantity,
} from '../../actions/debtIssuanceActions';
import { resetTransactionWatcher } from '../../actions/transactionWatcherActions';
import { currentSetDetailsSelector } from '../../selectors/setDetailsSelectors';
import {
  doesCurrentInputQuantityExceedAumCapSelector,
  isDebtIssuanceReadySelector,
  debtIssuanceInputComponentsSelector,
  debtIssuanceOutputComponentsSelector,
  debtIssuanceAumCapSelector,
  issuanceInputQuantityAboveAumCap,
  userHasSufficientFundsForDebtIssuanceQuantity,
  debtIssuanceContainsDebtPositionSelector,
} from '../../selectors/debtIssuanceSelectors';
import {
  issuanceInputQuantityV2Selector,
  setDetailsCurrentSetAddressSelector,
  erc20BalancesSelector,
  transactionIdSelector,
  windowDimensionSelector,
  customV2SetPathPrefixSelector,
  isFetchingDebtIssuanceFeesSelector,
  debtIssuanceFeesSelector,
} from '../../selectors/index';

const mapStateToProps = (state: any) => {
  const isDebtIssuanceReady = isDebtIssuanceReadySelector(state);
  const issuanceQuantity = issuanceInputQuantityV2Selector(state);
  const inputComponents = debtIssuanceInputComponentsSelector(state);
  const outputComponents = debtIssuanceOutputComponentsSelector(state);
  const erc20BalancesByAddress = erc20BalancesSelector(state);
  const currentTransactionId = transactionIdSelector(state);
  const windowDimensions = windowDimensionSelector(state);
  const setDetails = currentSetDetailsSelector(state);
  const setAddress = setDetailsCurrentSetAddressSelector(state);
  const isExceedingAumCap = doesCurrentInputQuantityExceedAumCapSelector(state);
  const currentSetAumCap = debtIssuanceAumCapSelector(state);
  const issuanceQuantityAboveAumCap = issuanceInputQuantityAboveAumCap(state);
  const userHasSufficientFunds = userHasSufficientFundsForDebtIssuanceQuantity(state);
  const customV2SetPathPrefix = customV2SetPathPrefixSelector(state);
  const debtIssuanceFees = debtIssuanceFeesSelector(state);
  const isFetchingDebtIssuanceFees = isFetchingDebtIssuanceFeesSelector(state);
  const containsDebtPosition = debtIssuanceContainsDebtPositionSelector(state);

  return {
    debtIssuanceFees,
    isFetchingDebtIssuanceFees,
    customV2SetPathPrefix,
    setDetails,
    setAddress,
    isDebtIssuanceReady,
    isExceedingAumCap,
    userHasSufficientFunds,
    issuanceQuantity,
    inputComponents,
    outputComponents,
    erc20BalancesByAddress,
    currentTransactionId,
    currentSetAumCap,
    issuanceQuantityAboveAumCap,
    windowDimensions,
    containsDebtPosition,
  };
};

const mapDispatchToProps = {
  updateIssuanceQuantity,
  setMaxIssuableQuantity: setMaxDebtIssuableQuantity,
  debtIssueCurrentSet,
  resetTransactionWatcher,
  fetchFeesForIssueQuantity,
};

export default connect(mapStateToProps, mapDispatchToProps)(DebtIssuanceForm);
