import { css, StyleSheet } from 'aphrodite';
import React, { PureComponent } from 'react';

import { COLORS } from '../../constants/index';

export interface IContentBoxProps {
  readonly hasInnerContainer?: boolean;
  readonly hasMargin?: boolean;
  readonly hasPadding?: boolean;
  readonly isBordered?: boolean;
  readonly isCentered?: boolean;
  readonly isFullWidth?: boolean;
  readonly isInnerContainerWide?: boolean;
  readonly className?: string;
  readonly textAlign?: any;
}

class ContentBox extends PureComponent<IContentBoxProps> {
  public static get defaultProps() {
    return {
      hasInnerContainer: true,
      hasMargin: true,
      hasPadding: true,
      isBordered: true,
      isCentered: true,
      isFullWidth: false,
      isInnerContainerWide: false,
      className: '',
      textAlign: 'center',
    };
  }

  public onInnerContainerStyle = () => {
    const { isInnerContainerWide } = this.props;
    const innerContainerStyle = {
      margin: '10px auto',
      maxWidth: isInnerContainerWide ? '750px' : '450px',
    };
    const innerContainerClass = css(StyleSheet.create({ innerContainerStyle }).innerContainerStyle);
    return innerContainerClass;
  };

  public onContainerStyle() {
    const {
      className,
      hasMargin,
      hasPadding,
      isBordered,
      isCentered,
      isFullWidth,
      textAlign,
    } = this.props;
    const container = {
      marginLeft: isCentered ? 'auto' : '0',
      marginRight: isCentered ? 'auto' : '0',
      maxWidth: isFullWidth ? 'auto' : '700px',
      padding: hasPadding ? '22px' : '0',
      marginBottom: hasMargin ? '12px' : undefined,
      marginTop: hasMargin ? '20px' : undefined,
      background: COLORS.white,
      border: `${isBordered ? '1px' : '0'} solid ${COLORS.gray}`,
      borderRadius: '5px',
      textAlign,
    };
    const containerClass = css(StyleSheet.create({ container }).container);
    if (className) return containerClass.concat(' ').concat(className);
    return containerClass;
  }

  public render() {
    const { children, hasInnerContainer } = this.props;
    const containerClass = this.onContainerStyle();
    return (
      <div className={containerClass}>
        {hasInnerContainer ? (
          <div className={this.onInnerContainerStyle()}>{children}</div>
        ) : (
          children
        )}
      </div>
    );
  }
}

export default ContentBox;
