import { css, StyleSheet } from 'aphrodite';
import React, { PureComponent, Fragment } from 'react';
import { Container, Divider, Grid } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Text } from '../../components/index';
import {
  IListToken,
  IListTokenWithPosition,
  IRebalancingSet,
  IToken,
  IWindowDimensions,
} from '../../typings/index';
import { COLORS, NETWORK_CONSTANTS, WINDOW_DIMENSIONS } from '../../constants/index';
import { BigNumber, truncateTokenAmount } from '../../utils/index';
import SetAllocationSectionPlaceholder from './SetAllocationSectionPlaceholder';
import { makeBlockExplorerContractLink, urlIntendedChain } from '../../utils/web3Utils';

const { MOBILE_MEDIA_QUERY, TABLET_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  SetAllocationSection_centerAlignBlock: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  SetAllocationSection_divider: {
    width: '100%',
    margin: '15px auto 0',
  },
  SetAllocationSection_grid: {
    marginLeft: '0',
    marginRight: '0',
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '4px',
    backgroundColor: COLORS.white,
  },
  SetAllocationSection_polygonGrid: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  SetAllocationSection_gridPaddingBottom: {
    paddingBottom: '15px',
  },
  SetAllocationSection_gridHeader: {
    fontSize: '12px',
    lineHeight: '14px',
    display: 'block',
    verticalAlign: 'middle',
    paddingTop: '30px',
  },
  SetAllocationSection_totalPriceFloat: {
    float: 'right',
  },
  SetAllocationSection_gridRow: {
    padding: '10px 30px',
  },
  SetAllocationSection_gridRowSubHeader: {
    paddingTop: '5px',
    paddingBottom: '5px',
    marginTop: '15px',
    marginBottom: '15px',
    paddingLeft: '30px',
    backgroundColor: COLORS.lightGray,
    fontWeight: 500,
    color: COLORS.darkGray,
  },
  SetAllocationSection_gridColumn: {
    paddingLeft: '0',
    paddingRight: '0',
    display: 'table',
  },
  SetAllocationSection_header: {
    marginBottom: '20px',
    textAlign: 'left',
  },
  SetAllocationSection_tokenNameHolder: {
    display: 'flex',
    alignItems: 'center',
  },
  SetAllocationSection_tokenName: {
    color: COLORS.darkBlue,
    fontWeight: 500,
  },
  SetAllocationSection_iconHolder: {
    display: 'inline-block',
    marginRight: '15px',
    borderRadius: '100%',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingRight: '5px',
  },
  SetAllocationSection_setIcon: {
    display: 'inline-block',
    maxWidth: '20px',
    height: '20px',
    verticalAlign: 'middle',
    borderRadius: '100px',
  },
  SetAllocationSection_setSymbol: {
    fontSize: '12px',
    marginLeft: '3px',
  },
  SetAllocationSection_setDescription: {
    marginTop: '30px',
    maxWidth: '500px',
  },
  SetAllocationSection_setTitle: {
    fontSize: '46px',
    maxWidth: '500px',
    [MOBILE_MEDIA_QUERY]: {
      fontSize: '48px',
      marginTop: '15px',
    },
  },
  SetAllocationSection_container: {
    [TABLET_MEDIA_QUERY]: {
      marginLeft: '0',
      marginRight: '0',
    },
  },
  SetAllocationSection_marginRight: {
    marginRight: '10px',
  },
  SetAllocationSection_setPercentChange: {
    borderRadius: '5px',
    display: 'flex',
    minWidth: '30px',
    marginLeft: '5px',
    marginRight: '5px',
  },
  SetAllocationSection_priceChangeArrow: {
    marginRight: '5px',
  },
  SetAllocationSection_seeMoreRow: {
    backgroundColor: COLORS.lightGray,
    cursor: 'pointer',
    borderRadius: '0 0 4px 4px',
    transition: '0.2s',
    ':hover': {
      backgroundColor: COLORS.lightGrayDarkened,
    },
  },
  SetAllocationSection_seeMoreText: {
    color: COLORS.blue,
    fontWeight: 500,
  },
});

interface ISetAllocationSectionProps {
  isCustomSet?: boolean;
  currentSet: IRebalancingSet;
  coinTargetAllocations?: { [tokenId: string]: any };
  percentChange?: string;
  coinPercentChanges?: { [tokenId: string]: string };
  formattedCurrentSetComponentsWithColors: IToken[];
  currentSetComponents: IListTokenWithPosition[];
  windowDimension?: IWindowDimensions;
  isLoading?: boolean;
  tokenSetsTokenListByAddress?: { [address: string]: IListToken };
  currentTokenQuantities?: { [tokenId: string]: string };
}

interface ISetAllocationSectionState {
  isExpanded: boolean;
}

const TRUNCATED_SETS_AMOUNT = 5;

/**
 * @title SetAllocationSection
 * @author Set Labs
 *
 * The SetAllocationSection displays allocation bars to inform users on
 * what the value and elements of each Set are.
 */
class SetAllocationSection extends PureComponent<
  ISetAllocationSectionProps & WithTranslation,
  ISetAllocationSectionState
> {
  static get defaultProps() {
    return {
      isLoading: false,
      currentSet: {} as IRebalancingSet,
      coinPricesUSD: {},
      coinTargetAllocations: {},
      coinPercentChanges: {},
      isCustomSet: false,
    };
  }

  state = {
    isExpanded: false,
  };

  public renderGridHeader = () => {
    const {
      isCustomSet,
      currentSet,
      tokenSetsTokenListByAddress,
      windowDimension: { isTabletLandscape },
      t,
    } = this.props;

    if (isTabletLandscape) {
      return (
        <Fragment>
          {/* Table Header */}
          <Grid.Row columns={2} className={css(styles.SetAllocationSection_gridRow)}>
            <Grid.Column
              className={css(styles.SetAllocationSection_gridColumn)}
              textAlign="left"
              width={10}
            >
              <Text className={css(styles.SetAllocationSection_gridHeader)} color="darkGray">
                {t('price-chart.token')}
              </Text>
            </Grid.Column>
            <Divider className={css(styles.SetAllocationSection_divider)} />
          </Grid.Row>

          {/* Set Summary */}
          <Grid.Row columns={2} className={css(styles.SetAllocationSection_gridRow)}>
            <Grid.Column
              className={css(styles.SetAllocationSection_gridColumn)}
              textAlign="left"
              width={16}
            >
              <div className={css(styles.SetAllocationSection_iconHolder)}>
                <img
                  className={css(styles.SetAllocationSection_setIcon)}
                  src={
                    currentSet.image ||
                    tokenSetsTokenListByAddress[currentSet.address.toLowerCase()]?.logoURI ||
                    'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-set-token-icon.svg'
                  }
                  alt={currentSet.name}
                />
              </div>
              <Text color="darkBlue" fontWeight={500}>
                {currentSet.symbol}
              </Text>
            </Grid.Column>
          </Grid.Row>

          {/* Divider */}
          <Grid.Row columns={2} className={css(styles.SetAllocationSection_gridRowSubHeader)}>
            <Grid.Column
              className={css(styles.SetAllocationSection_gridColumn)}
              textAlign="left"
              width={16}
            >
              Underlying Tokens
            </Grid.Column>
          </Grid.Row>
        </Fragment>
      );
    }

    return (
      <Fragment>
        {/* Table Header */}
        <Grid.Row columns={2} className={css(styles.SetAllocationSection_gridRow)}>
          <Grid.Column
            className={css(styles.SetAllocationSection_gridColumn)}
            textAlign="left"
            width={8}
          >
            <Text className={css(styles.SetAllocationSection_gridHeader)} color="darkGray">
              {t('price-chart.token')}
            </Text>
          </Grid.Column>
          <Grid.Column textAlign={isCustomSet ? 'right' : 'left'} width={8}>
            <Text className={css(styles.SetAllocationSection_gridHeader)} color="darkGray">
              {t('price-chart.quantity-per-set')}
            </Text>
          </Grid.Column>
          <Divider className={css(styles.SetAllocationSection_divider)} />
        </Grid.Row>

        {/*  Set Summary */}
        <Grid.Row columns={2} className={css(styles.SetAllocationSection_gridRow)}>
          <Grid.Column
            className={css(styles.SetAllocationSection_gridColumn)}
            textAlign="left"
            width={12}
          >
            <div className={css(styles.SetAllocationSection_iconHolder)}>
              <img
                className={css(styles.SetAllocationSection_setIcon)}
                src={
                  currentSet.image ||
                  tokenSetsTokenListByAddress[currentSet.address.toLowerCase()]?.logoURI ||
                  'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-set-token-icon.svg'
                }
                alt={currentSet.name}
              />
            </div>
            <Text color="darkBlue" fontWeight={500}>
              {currentSet.name}
            </Text>
          </Grid.Column>
        </Grid.Row>

        {/* Divider */}
        <Grid.Row columns={2} className={css(styles.SetAllocationSection_gridRowSubHeader)}>
          <Grid.Column
            className={css(styles.SetAllocationSection_gridColumn)}
            textAlign="left"
            width={16}
          >
            {t('price-chart.underlying')}
          </Grid.Column>
        </Grid.Row>
      </Fragment>
    );
  };

  public renderSeeMore = (hasMoreThanTruncatedAmount: boolean) => {
    const { t } = this.props;

    if (!hasMoreThanTruncatedAmount) {
      return;
    }

    const { isExpanded } = this.state;

    return (
      <Grid.Row
        className={css(styles.SetAllocationSection_seeMoreRow)}
        onClick={() => this.setState({ isExpanded: !isExpanded })}
      >
        <Grid.Column textAlign="center">
          <p className={css(styles.SetAllocationSection_seeMoreText)}>
            {isExpanded ? t('price-chart-footer.see-less') : t('price-chart-footer.see-more')}
          </p>
        </Grid.Column>
      </Grid.Row>
    );
  };

  public renderGridBody = (hasMoreThanTruncatedAmount: boolean) => {
    const {
      formattedCurrentSetComponentsWithColors,
      currentSetComponents,
      currentTokenQuantities,
      isCustomSet,
      currentSet,
      windowDimension: { isTabletLandscape },
    } = this.props;
    const { isExpanded } = this.state;

    let displayedComponents = (currentSetComponents || []).map((c, i) => {
      return {
        ...c,
        index: i,
      };
    });

    if (hasMoreThanTruncatedAmount && !isExpanded) {
      displayedComponents = displayedComponents.slice(0, TRUNCATED_SETS_AMOUNT);
    }

    return displayedComponents.map(token => {
      if (!token) return null;
      const internalToken = currentSet?.components?.find(
        c => c.address.toLowerCase() === token.address.toLowerCase(),
      );
      const tokenWithColor = formattedCurrentSetComponentsWithColors.find(
        c => c.address.toLowerCase() === token.address.toLowerCase(),
      );
      if (!internalToken) return null;

      const currentTokenQuantity =
        currentTokenQuantities?.[token.address.toLowerCase()] ||
        token.perTokenPosition.toString() ||
        '0';

      const isDebtPosition = new BigNumber(currentTokenQuantity).lt(0);
      const tokenName = token.name + (isDebtPosition ? ' (Debt)' : '');
      const symbolName = token.symbol;

      if (isTabletLandscape) {
        return (
          <React.Fragment key={`${token.name}-${token.index}`}>
            <Grid.Row columns={2} className={css(styles.SetAllocationSection_gridRow)}>
              <Grid.Column
                className={css(styles.SetAllocationSection_gridColumn)}
                textAlign="left"
                width={16}
              >
                <div className={css(styles.SetAllocationSection_iconHolder)}>
                  <img
                    className={css(styles.SetAllocationSection_setIcon)}
                    src={
                      tokenWithColor.image ||
                      'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-token-icon.svg'
                    }
                    alt={token.name}
                  />
                </div>
                <Text color="darkBlue" fontWeight={500}>
                  {symbolName} {isDebtPosition ? ' (Debt)' : ''}
                </Text>
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>
        );
      }

      return (
        <React.Fragment key={`${token.name}-${token.index}`}>
          <Grid.Row columns={2} className={css(styles.SetAllocationSection_gridRow)}>
            <Grid.Column
              className={css(styles.SetAllocationSection_gridColumn)}
              textAlign="left"
              width={8}
            >
              <div className={css(styles.SetAllocationSection_tokenNameHolder)}>
                <div className={css(styles.SetAllocationSection_iconHolder)}>
                  <img
                    className={css(styles.SetAllocationSection_setIcon)}
                    src={
                      tokenWithColor.image ||
                      'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-token-icon.svg'
                    }
                    alt={token.name}
                  />
                </div>
                <a
                  className={css(styles.SetAllocationSection_tokenName)}
                  href={makeBlockExplorerContractLink(token?.address)}
                  target="_blank"
                >
                  {tokenName}
                </a>
              </div>
            </Grid.Column>
            <Grid.Column textAlign={isCustomSet ? 'right' : 'left'} width={8}>
              <Text color="darkBlue">{truncateTokenAmount(currentTokenQuantity)}</Text>
              <Text color="darkGray" className={css(styles.SetAllocationSection_setSymbol)}>
                {symbolName}
              </Text>
            </Grid.Column>
          </Grid.Row>
        </React.Fragment>
      );
    });
  };

  public render() {
    const { isLoading, currentSet, windowDimension } = this.props;
    const { isTablet } = windowDimension;
    const { components } = currentSet;

    if (!isLoading && !components?.length) return null;

    const intendedChain = urlIntendedChain();
    const ContainerElement = isTablet ? 'div' : Container;
    const hasMoreThanTruncatedAmount = components.length > TRUNCATED_SETS_AMOUNT;
    const paddingBottom = hasMoreThanTruncatedAmount
      ? undefined
      : styles.SetAllocationSection_gridPaddingBottom;

    return isLoading ? (
      <SetAllocationSectionPlaceholder windowDimension={windowDimension} />
    ) : (
      <ContainerElement
        className={css(styles.SetAllocationSection_container)}
        style={{
          ...(intendedChain === NETWORK_CONSTANTS.POLYGON_CHAIN && {
            borderTop: '5px solid',
            borderImageSlice: '1 0 0',
            borderImageSource: COLORS.polygonGradient,
            paddingTop: '10px',
          }),
        }}
      >
        <div className={css(styles.SetAllocationSection_centerAlignBlock)}>
          <Grid
            className={css(
              styles.SetAllocationSection_grid,
              intendedChain === NETWORK_CONSTANTS.POLYGON_CHAIN &&
                styles.SetAllocationSection_polygonGrid,
              paddingBottom,
            )}
          >
            {this.renderGridHeader()}
            {this.renderGridBody(hasMoreThanTruncatedAmount)}
            {this.renderSeeMore(hasMoreThanTruncatedAmount)}
          </Grid>
        </div>
      </ContainerElement>
    );
  }
}

export default withTranslation('set-details')(SetAllocationSection);
