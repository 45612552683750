import { StyleSheet, css } from 'aphrodite';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { slide as MobileMenu } from 'react-burger-menu';
import { Link } from 'react-router-dom';
import { Menu, Container, Popup, Grid } from 'semantic-ui-react';

import logoWithText from '../../img/logos/set-and-tokensets-logo.svg';
import chevronDown from '../../img/icons/down-chevron-dark-blue.svg';
import accountIcon from '../../img/icons/account-icon-gray.svg';
import accountIconDark from '../../img/icons/account-icon-dark-blue.svg';

import {
  COLORS,
  DATA_SELECTS,
  NETWORK_CONSTANTS,
  SUPPORTED_NETWORKS,
  WEB3_PROVIDERS,
  WINDOW_DIMENSIONS,
} from '../../constants/index';
import {
  GO_TO_ACCOUNT_PAGE,
  handleSwitchChain as handleSwitchChainAction,
  logOut as logOutAction,
  handleLogin as handleLoginAction,
  openChainSwitchModal,
} from '../../actions/web3Actions';
import { IMenuItem, IWindowDimensions } from '../../typings/index';
import { withTranslation, WithTranslation } from 'react-i18next';
import { currentChainOrDefaultSelector } from '../../selectors/web3Selectors';
import { providerTypeSelector } from '../../selectors/baseSelectors';
import CustomSetExploreTableNetworkSwitcher from '../../containers/CustomSetExploreTable/CustomSetExploreTableNetworkSwitcher';

const { TABLET_LANDSCAPE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  NavBar_mobilePlaceholder: {
    height: '52px',
  },
  NavBar_backgroundContainer: {
    position: 'absolute',
    backgroundColor: COLORS.blueContrastBackground,
    width: '100%',
    height: '900px',
    zIndex: -1000000,
  },
  NavBar_stickyContainer: {
    backgroundColor: COLORS.white,
    borderBottom: `1px solid ${COLORS.lightBlue2}`,
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  NavBar_outerContainer: {
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      backgroundColor: COLORS.white,
      width: '100%',
      height: '52px',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 999,
      paddingBottom: '0',
    },
  },
  NavBar_container: {
    minHeight: '75px',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      minHeight: '0',
      paddingTop: '0',
      paddingBottom: '0',
    },
  },
  NavBar_containerHomeFeed: {
    width: '90%',
  },
  NavBar_logoMenuItem: {
    padding: '0',
    paddingLeft: '0',
    display: 'flex',
    color: COLORS.darkBlue,
    fontSize: '14px',
    height: '50px',
    WebkitTransition: 'width .35s, height .35s, background-color .35s, -webkit-transform .35s',
    transition: 'width .35s, height .35s, background-color .35s, transform .35s',
  },
  NavBar_logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  NavBar_logo: {
    margin: '0 auto',
    paddingRight: '15px',
    height: '46px',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      position: 'relative',
      top: '2px',
    },
  },
  NavBar_mobileLogo: {
    margin: '0 auto',
    height: '32px',
    position: 'absolute',
    top: '10px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  NavBar_mobileAccountSignIn: {
    position: 'absolute',
    right: '10px',
    top: '16px',
  },
  NavBar_accountIcon: {
    width: '24px',
    height: '24px',
  },
  NavBar_logoWithText: {
    margin: '0 auto',
    paddingRight: '30px',
    height: '46px',
    width: 'auto',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      paddingRight: '0',
    },
  },
  NavBar_noHover: {
    ':hover': {
      background: 'transparent',
    },
  },
  NavBar_menu: {
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
    WebkitBoxShadow: 'none',
    minHeight: '80px',
    MozBoxShadow: 'none',
    boxShadow: 'none',
    border: 'none',
    height: '75px',
    marginRight: '0',
    marginLeft: '0',
    marginBottom: '0',
    paddingTop: '6px',
    paddingBottom: '6px',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      height: '52px',
      minHeight: '0',
      paddingTop: '0px',
      paddingBottom: '0px',
      position: 'relative',
      zIndex: 10000,
    },
  },
  NavBar_rightMenu: {
    flexGrow: 2,
    justifyContent: 'flex-end',
    position: 'relative',
    alignItems: 'center',
  },
  NavBar_hiringButton: {
    background: `linear-gradient(90deg, ${COLORS.blue}, ${COLORS.lightBlue3})`,
    color: COLORS.white,
    fontWeight: 500,
    borderRadius: '20px',
    padding: '10px 15px',
    position: 'relative',
    right: '0px',
    fontSize: '12px',
  },
  NavBar_createSetButton: {
    background: `linear-gradient(90deg, ${COLORS.blue}, ${COLORS.lightBlue3})`,
    color: COLORS.white,
    fontWeight: 500,
    borderRadius: '4px',
    padding: '10px 15px',
    position: 'relative',
    right: '0px',
    fontSize: '12px',
  },
  NavBar_menuItem: {
    padding: '5px 15px',
  },
  NavBar_newMenuItem: {
    padding: '5px 8px',
  },
  NavBar_resourcesMenuItem: {
    padding: '5px 30px',
  },
  NavBar_menuItemText: {
    fontWeight: 400,
    color: COLORS.darkBlue,
    transition: '0.2s',
    cursor: 'pointer',
    ':hover': {
      color: COLORS.blue,
    },
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      fontWeight: 500,
      fontSize: '24px',
      marginTop: '24px',
      marginBottom: '24px',
    },
  },
  NavBar_resourcePopup: {
    maxWidth: '180px',
  },
  NavBar_explorePopup: {
    maxWidth: '225px',
  },
  NavBar_menuItemBorder: {
    borderTop: `1px solid ${COLORS.lightGray}`,
  },
  NavBar_innerMenuItem: {
    display: 'flex',
    alignItems: 'center',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      display: 'flex',
      alignItems: 'center',
      padding: '2px 30px',
      transition: '0.2s',
    },
  },
  NavBar_activeMenuItem: {
    color: COLORS.darkBlue,
    fontWeight: 500,
  },
  NavBar_navIcon: {
    colors: COLORS.darkGray,
    marginRight: '5px',
    height: '16px',
    width: 'auto',
    marginBottom: '2px',
  },
  NavBar_notificationIcon: {
    position: 'relative',
    top: '-8px',
    right: '-4px',
    backgroundColor: COLORS.red,
    borderRadius: '50%',
    height: '8px',
    width: '8px',
  },
  NavBar_mobileMenuLogo: {
    paddingBottom: '25px',
    width: '160px',
  },
  NavBar_mobileMenuItemText: {
    marginLeft: '-30px',
    lineHeight: '30px',
    ':hover': {
      color: COLORS.darkBlue,
    },
  },
  NavBar_mobileMenuSmallItemText: {
    color: COLORS.darkBlue,
    marginTop: '6px',
    marginBottom: '6px',
    marginLeft: '-30px',
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: 300,
  },
  NavBar_mobileMenuLastSmallItem: {
    marginBottom: '24px',
  },
  NavBar_mobileMenuDropdown: {
    justifyContent: 'space-between',
  },
  NavBar_mobileMenuDropdownContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'hidden',
    transition: 'max-height 0.2s',
    maxHeight: '500px',
  },
  NavBar_mobileMenuDropdownContentHidden: {
    maxHeight: '0px',
  },
  NavBar_dropdownRow: {
    display: 'table',
    height: '20px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: COLORS.lightGray,
    },
  },
  NavBar_iconColumn: {
    width: '6.25%',
    paddingRight: '15px',
    verticalAlign: 'middle',
    display: 'table-cell',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      marginRight: '15px',
      marginTop: '15px',
      marginBottom: '10px',
    },
  },
  NavBar_separator: {
    width: '100%',
    borderTop: `1px solid ${COLORS.gray}`,
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      borderTop: 'none',
    },
  },
  // Dropdown
  NavBar_chevronDown: {
    width: '10px',
    marginLeft: '5px',
    pointerEvents: 'none',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      marginLeft: '0',
      marginRight: '5px',
    },
  },
  NavBar_mobileChevron: {
    width: '15px',
    marginLeft: '5px',
    pointerEvents: 'none',
    transition: '0.2s',
  },
  NavBar_mobileChevronUp: {
    transform: 'rotate(180deg)',
  },
  NavBar_dropDownText: {
    color: COLORS.darkBlue,
    display: 'flex',
    alignItems: 'center',
  },
  // Sign In
  NavBar_menuItemSignIn: {
    border: `1px solid ${COLORS.darkBlue}`,
    borderRadius: '4px',
    height: '40px',
    padding: '0 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '0.2s',
    ':hover': {
      backgroundColor: COLORS.darkBlue,
      border: `1px solid ${COLORS.darkBlue}`,
      boxShadow: '0 6px 12px 0 rgba(35, 1, 221, 0.25)',
    },
    ':hover .sign-in': {
      color: COLORS.white,
    },
  },
  NavBar_setPopup: {
    zIndex: 15000,
    overflow: 'hidden',
  },
  // Sign Up
  NavBar_signUpButton: {
    background: `linear-gradient(90deg, ${COLORS.blue}, ${COLORS.lightBlue3})`,
    borderRadius: '50px',
    justifyContent: 'center',
    marginTop: '60px',
  },
  NavBar_signUpButtonText: {
    color: COLORS.white,
  },
  // Accordion
  NavBar_accordionTitle: {
    display: 'flex',
    alignItems: 'center',
  },
});

const mobileMenuStyles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '24px',
    height: '18px',
    left: '20px',
    top: '20px',
  },
  bmBurgerBars: {
    background: COLORS.darkBlue,
    borderRadius: '2px',
    height: '2px',
  },
  bmBurgerBarsHover: {
    background: 'transparent',
  },
  bmCrossButton: {
    display: 'none',
    top: '15px',
    right: '15px',
  },
  bmCross: {
    background: COLORS.darkBlue,
    width: '8px',
    height: '25px',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    width: '75%',
    top: '0',
    left: '0',
  },
  bmMenu: {
    background: COLORS.white,
    padding: '1em 1em 0',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: COLORS.lightGray,
  },
  bmItemList: {
    color: COLORS.white,
    padding: '0.8em',
  },
  bmItem: {
    display: 'inline-block',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    top: '0',
    left: '0',
  },
};

export interface INavBarProps {
  providerType: string;
  isLoggedIn: boolean;
  isSocialTrader: boolean;
  currentChain: string;
  account: string;
  username: string;
  activeItem: string;
  history: any;
  showBackground?: boolean;
  windowDimension: IWindowDimensions;
  handleLogin: (...args: any[]) => any;
  handleSwitchChain: (...args: any[]) => any;
  logOut: (...args: any[]) => any;
  openChainSwitchModal: (...args: any[]) => any;
}

interface INavBarState {
  hamburgerOpen: boolean;
  accountDropdownOpen: boolean;
  companyDropdownOpen: boolean;
  resourcesDropdownOpen: boolean;
  exploreSetsDropdownOpen: boolean;
  activeIndex: number;
}

class NavBar extends PureComponent<INavBarProps & WithTranslation, INavBarState> {
  static get defaultProps() {
    return {
      windowDimension: {
        isTabletLandscape: false,
        isMobile: false,
      } as IWindowDimensions,
      activeItem: '/',
      showBackground: true,
    };
  }

  constructor(props: INavBarProps & WithTranslation) {
    super(props);
    this.state = {
      hamburgerOpen: false,
      accountDropdownOpen: false,
      companyDropdownOpen: false,
      resourcesDropdownOpen: false,
      exploreSetsDropdownOpen: false,
      activeIndex: 0,
    };
  }

  handleMenuItemClick = () => {
    this.setState({
      hamburgerOpen: false,
      accountDropdownOpen: false,
      companyDropdownOpen: false,
      resourcesDropdownOpen: false,
      exploreSetsDropdownOpen: false,
    });
  };

  handleHamburgerClick = (state: any) => {
    const { isOpen } = state;
    this.setState({ hamburgerOpen: isOpen });
  };

  handleCompanyClick = () => {
    this.setState({ companyDropdownOpen: !this.state.companyDropdownOpen });
  };

  handleResourcesClick = () => {
    this.setState({ resourcesDropdownOpen: !this.state.resourcesDropdownOpen });
  };

  handleExploreSetsClick = () => {
    this.setState({ exploreSetsDropdownOpen: !this.state.exploreSetsDropdownOpen });
  };

  renderMenuLink({ name, path, svg, svgInactive }: IMenuItem) {
    if (!name) return;
    const { activeItem } = this.props;
    const isActive = activeItem === path;
    const activeStyle = isActive ? styles.NavBar_activeMenuItem : null;

    return (
      <Menu.Item
        as={Link}
        name={name}
        to={path}
        onClick={() => this.handleMenuItemClick()}
        className={css(styles.NavBar_noHover, styles.NavBar_menuItem)}
        key={name}
        data-select={DATA_SELECTS.NAVBAR_MAPPING[name]}
      >
        <div className={css(styles.NavBar_innerMenuItem)}>
          {svg ? (
            <img
              alt={name}
              className={css(styles.NavBar_navIcon)}
              src={isActive ? svg : svgInactive}
            />
          ) : null}
          <span className={css(activeStyle, styles.NavBar_menuItemText)}>{name}</span>
        </div>
      </Menu.Item>
    );
  }
  renderResourcesDropdown = () => {
    const { activeItem, t } = this.props;
    const { resourcesDropdownOpen } = this.state;
    const isActive = activeItem === '#/faq';
    const activeStyle = isActive ? styles.NavBar_activeMenuItem : undefined;

    const trigger = (
      <Menu.Item className={css(styles.NavBar_noHover, styles.NavBar_resourcesMenuItem)}>
        <div className={css(styles.NavBar_innerMenuItem, styles.NavBar_menuItemText)}>
          {t('resources.resources')}
          <img src={chevronDown} alt="Chevron down" className={css(styles.NavBar_chevronDown)} />
        </div>
      </Menu.Item>
    );

    return (
      <Popup
        trigger={trigger}
        on="hover"
        basic
        className={`${css(styles.NavBar_setPopup)} transition-fadein`}
        hideOnScroll
        hoverable
        open={resourcesDropdownOpen}
        onOpen={() => {
          this.setState({
            resourcesDropdownOpen: true,
            accountDropdownOpen: false,
            exploreSetsDropdownOpen: false,
            companyDropdownOpen: false,
            hamburgerOpen: false,
          });
        }}
        onClose={() => this.setState({ resourcesDropdownOpen: false })}
        content={
          <div
            onMouseEnter={() => this.setState({ resourcesDropdownOpen: true })}
            onMouseLeave={() => this.setState({ resourcesDropdownOpen: false })}
          >
            <Grid className={`${css(styles.NavBar_resourcePopup)}`}>
              <Grid.Row className={css(styles.NavBar_dropdownRow, activeStyle)}>
                <Grid.Column
                  className={css(styles.NavBar_iconColumn, activeStyle)}
                  onClick={() => {
                    this.setState({ resourcesDropdownOpen: false });
                  }}
                  href="https://docs.tokensets.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <span className={css(styles.NavBar_dropDownText)}>
                    {t('resources.developers')}
                  </span>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row className={css(styles.NavBar_dropdownRow, activeStyle)}>
                <Grid.Column
                  as={Link}
                  className={css(styles.NavBar_iconColumn, activeStyle)}
                  onClick={() => {
                    this.handleMenuItemClick();
                    this.setState({ resourcesDropdownOpen: false });
                  }}
                  to="/security"
                >
                  <span className={css(styles.NavBar_dropDownText)}>{t('resources.security')}</span>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row className={css(styles.NavBar_dropdownRow, activeStyle)}>
                <Grid.Column
                  className={css(styles.NavBar_iconColumn, activeStyle)}
                  onClick={() => {
                    this.setState({ resourcesDropdownOpen: false });
                  }}
                  href="https://medium.com/set-protocol"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <span className={css(styles.NavBar_dropDownText)}>{t('resources.blog')}</span>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row className={css(styles.NavBar_dropdownRow, activeStyle)}>
                <Grid.Column
                  className={css(styles.NavBar_iconColumn, activeStyle)}
                  onClick={() => {
                    this.setState({ resourcesDropdownOpen: false });
                  }}
                  href="https://discord.gg/x3PGVfP"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <span className={css(styles.NavBar_dropDownText)}>Discord</span>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row className={css(styles.NavBar_dropdownRow, activeStyle)}>
                <Grid.Column
                  as={Link}
                  className={css(styles.NavBar_iconColumn, activeStyle)}
                  onClick={() => {
                    this.handleMenuItemClick();
                    this.setState({ resourcesDropdownOpen: false });
                  }}
                  to="/press"
                >
                  <span className={css(styles.NavBar_dropDownText)}>{t('resources.press')}</span>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row className={css(styles.NavBar_dropdownRow, activeStyle)}>
                <Grid.Column
                  as={Link}
                  className={css(styles.NavBar_iconColumn, activeStyle)}
                  onClick={() => {
                    this.handleMenuItemClick();
                    this.setState({ resourcesDropdownOpen: false });
                  }}
                  to="/integrations"
                >
                  <span className={css(styles.NavBar_dropDownText)}>
                    {t('resources.integrations')}
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        }
      />
    );
  };

  renderExploreSetsDropdown = () => {
    const { currentChain, handleSwitchChain } = this.props;
    const supportedNetworks = SUPPORTED_NETWORKS;

    return (
      <Menu.Item className={css(styles.NavBar_noHover, styles.NavBar_newMenuItem)}>
        <CustomSetExploreTableNetworkSwitcher
          supportedNetworks={supportedNetworks}
          currentChain={currentChain}
          handleSwitchChain={handleSwitchChain}
          width="130px"
          redirectTo={window.location.href}
        />
      </Menu.Item>
    );
  };

  navigateToAccount = () => {
    const { activeItem, history } = this.props;

    if (activeItem === '#/account') return;

    this.handleMenuItemClick();
    history.push('/account');
  };

  renderAccountDropdown = () => {
    const { activeItem, logOut, history, t } = this.props;
    const { accountDropdownOpen } = this.state;
    const isActive = activeItem === '#/account';
    const activeStyle = isActive ? styles.NavBar_activeMenuItem : undefined;

    const trigger = (
      <Menu.Item
        as={Link}
        className={css(styles.NavBar_noHover, styles.NavBar_newMenuItem)}
        to="/account"
        onClick={() => {
          this.setState({ accountDropdownOpen: false });
        }}
      >
        <div className={css(styles.NavBar_innerMenuItem, styles.NavBar_menuItemText)}>
          <img src={accountIcon} alt="Account icon" className={css(styles.NavBar_navIcon)} />
          {t('account.account')}
          <img src={chevronDown} alt="Chevron down" className={css(styles.NavBar_chevronDown)} />
        </div>
      </Menu.Item>
    );

    return (
      <Popup
        trigger={trigger}
        on="hover"
        basic
        className={`${css(styles.NavBar_setPopup)} transition-fadein`}
        hideOnScroll
        hoverable
        open={accountDropdownOpen}
        onOpen={() => {
          this.setState({
            accountDropdownOpen: true,
            exploreSetsDropdownOpen: false,
            companyDropdownOpen: false,
            resourcesDropdownOpen: false,
            hamburgerOpen: false,
          });
        }}
        onClose={() => this.setState({ accountDropdownOpen: false })}
        content={
          <div
            onMouseEnter={() => this.setState({ accountDropdownOpen: true })}
            onMouseLeave={() => this.setState({ accountDropdownOpen: false })}
          >
            <Grid>
              {/* To Account */}
              <Grid.Row className={css(styles.NavBar_dropdownRow, activeStyle)}>
                <Grid.Column
                  className={css(styles.NavBar_iconColumn, activeStyle)}
                  onClick={this.navigateToAccount}
                >
                  <span className={css(styles.NavBar_dropDownText)}>{t('account.my-account')}</span>
                </Grid.Column>
              </Grid.Row>

              {/* Sign Out */}
              <Grid.Row className={css(styles.NavBar_dropdownRow)}>
                <Grid.Column
                  className={css(styles.NavBar_iconColumn, activeStyle)}
                  onClick={() => {
                    this.handleMenuItemClick();
                    logOut();
                    if (activeItem === '/account') {
                      history.push('/');
                    }
                  }}
                >
                  <span className={css(styles.NavBar_dropDownText)}>{t('account.sign-out')}</span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        }
      />
    );
  };

  renderSignIn = () => {
    const { handleLogin, t } = this.props;

    return (
      <React.Fragment>
        <Menu.Item className={css(styles.NavBar_noHover, styles.NavBar_newMenuItem)}>
          <div
            className={css(styles.NavBar_menuItemSignIn)}
            onClick={() => {
              this.handleMenuItemClick();
              handleLogin();
            }}
          >
            <span
              className={`${css(styles.NavBar_menuItemText, styles.NavBar_innerMenuItem)} sign-in`}
            >
              {t('sign-in')}
            </span>
          </div>
        </Menu.Item>
      </React.Fragment>
    );
  };

  renderMobileMenu = () => {
    const {
      providerType,
      currentChain,
      activeItem,
      account,
      history,
      logOut,
      handleLogin,
      handleSwitchChain,
      openChainSwitchModal,
      t,
    } = this.props;
    const { hamburgerOpen, resourcesDropdownOpen, companyDropdownOpen } = this.state;
    const supportedNetworks = SUPPORTED_NETWORKS;

    return (
      <React.Fragment>
        <MobileMenu
          isOpen={hamburgerOpen}
          styles={mobileMenuStyles}
          onStateChange={this.handleHamburgerClick}
        >
          <Link onClick={() => this.handleMenuItemClick()} to="/">
            <img
              className={css(styles.NavBar_mobileMenuLogo)}
              src={logoWithText}
              alt="TokenSets logo"
            />
          </Link>
          <Link
            className={css(styles.NavBar_innerMenuItem)}
            onClick={() => this.handleMenuItemClick()}
            to="/"
          >
            <span className={css(styles.NavBar_menuItemText, styles.NavBar_mobileMenuItemText)}>
              {t('home')}
            </span>
          </Link>
          <Link
            className={css(styles.NavBar_innerMenuItem, styles.NavBar_menuItemBorder)}
            onClick={async () => {
              this.handleMenuItemClick();
              if (currentChain !== NETWORK_CONSTANTS.ETHEREUM_CHAIN) {
                await handleSwitchChain(NETWORK_CONSTANTS.ETHEREUM_CHAIN, '/explore');
              }
            }}
            to="/explore"
          >
            <span className={css(styles.NavBar_menuItemText, styles.NavBar_mobileMenuItemText)}>
              Explore Ethereum
            </span>
          </Link>
          {supportedNetworks.includes(NETWORK_CONSTANTS.POLYGON_CHAIN) && (
            <Link
              className={css(styles.NavBar_innerMenuItem, styles.NavBar_menuItemBorder)}
              onClick={async () => {
                this.handleMenuItemClick();
                if (currentChain !== NETWORK_CONSTANTS.POLYGON_CHAIN) {
                  if (account && providerType !== WEB3_PROVIDERS.METAMASK) {
                    openChainSwitchModal();
                  } else {
                    await handleSwitchChain(NETWORK_CONSTANTS.POLYGON_CHAIN, '/explore');
                  }
                }
              }}
              to="/explore"
            >
              <span className={css(styles.NavBar_menuItemText, styles.NavBar_mobileMenuItemText)}>
                Explore Polygon
              </span>
            </Link>
          )}
          {supportedNetworks.includes(NETWORK_CONSTANTS.ARBITRUM_CHAIN) && (
            <Link
              className={css(styles.NavBar_innerMenuItem, styles.NavBar_menuItemBorder)}
              onClick={async () => {
                this.handleMenuItemClick();
                if (currentChain !== NETWORK_CONSTANTS.ARBITRUM_CHAIN) {
                  if (account && providerType !== WEB3_PROVIDERS.METAMASK) {
                    openChainSwitchModal();
                  } else {
                    await handleSwitchChain(NETWORK_CONSTANTS.ARBITRUM_CHAIN, '/explore');
                  }
                }
              }}
              to="/explore"
            >
              <span className={css(styles.NavBar_menuItemText, styles.NavBar_mobileMenuItemText)}>
                Explore Arbitrum
              </span>
            </Link>
          )}
          {supportedNetworks.includes(NETWORK_CONSTANTS.OPTIMISM_CHAIN) && (
            <Link
              className={css(styles.NavBar_innerMenuItem, styles.NavBar_menuItemBorder)}
              onClick={async () => {
                this.handleMenuItemClick();
                if (currentChain !== NETWORK_CONSTANTS.OPTIMISM_CHAIN) {
                  if (account && providerType !== WEB3_PROVIDERS.METAMASK) {
                    openChainSwitchModal();
                  } else {
                    await handleSwitchChain(NETWORK_CONSTANTS.OPTIMISM_CHAIN, '/explore');
                  }
                }
              }}
              to="/explore"
            >
              <span className={css(styles.NavBar_menuItemText, styles.NavBar_mobileMenuItemText)}>
                Explore Optimism
              </span>
            </Link>
          )}
          {supportedNetworks.includes(NETWORK_CONSTANTS.AVALANCHE_CHAIN) && (
            <Link
              className={css(styles.NavBar_innerMenuItem, styles.NavBar_menuItemBorder)}
              onClick={async () => {
                this.handleMenuItemClick();
                if (currentChain !== NETWORK_CONSTANTS.AVALANCHE_CHAIN) {
                  if (account && providerType !== WEB3_PROVIDERS.METAMASK) {
                    openChainSwitchModal();
                  } else {
                    await handleSwitchChain(NETWORK_CONSTANTS.AVALANCHE_CHAIN, '/explore');
                  }
                }
              }}
              to="/explore"
            >
              <span className={css(styles.NavBar_menuItemText, styles.NavBar_mobileMenuItemText)}>
                Explore Avalanche
              </span>
            </Link>
          )}

          {/* Mobile Resources Dropdown */}
          <div
            className={css(
              styles.NavBar_innerMenuItem,
              styles.NavBar_menuItemBorder,
              styles.NavBar_mobileMenuDropdown,
            )}
            onClick={this.handleResourcesClick}
          >
            <span className={css(styles.NavBar_menuItemText, styles.NavBar_mobileMenuItemText)}>
              {t('resources.resources')}
            </span>
            <img
              src={chevronDown}
              alt="Mobile Chevron Symbol"
              className={css(
                styles.NavBar_mobileChevron,
                resourcesDropdownOpen && styles.NavBar_mobileChevronUp,
              )}
            />
          </div>
          <div
            className={css(
              styles.NavBar_mobileMenuDropdownContent,
              !resourcesDropdownOpen && styles.NavBar_mobileMenuDropdownContentHidden,
            )}
          >
            <a
              href="https://docs.tokensets.com"
              target="_blank"
              rel="noreferrer noopener"
              className={css(styles.NavBar_innerMenuItem)}
              key="menu_dev_docs"
            >
              <span className={css(styles.NavBar_mobileMenuSmallItemText)}>
                {t('resources.developers')}
              </span>
            </a>
            <Link
              className={css(styles.NavBar_innerMenuItem)}
              onClick={() => this.handleMenuItemClick()}
              to="/security"
            >
              <span className={css(styles.NavBar_mobileMenuSmallItemText)}>
                {t('resources.security')}
              </span>
            </Link>
            <a
              href="https://medium.com/set-protocol"
              target="_blank"
              rel="noreferrer noopener"
              className={css(styles.NavBar_innerMenuItem)}
              key="menu_blog"
            >
              <span className={css(styles.NavBar_mobileMenuSmallItemText)}>
                {t('resources.blog')}
              </span>
            </a>
            <Link
              className={css(styles.NavBar_innerMenuItem)}
              onClick={() => this.handleMenuItemClick()}
              to="/press"
            >
              <span className={css(styles.NavBar_mobileMenuSmallItemText)}>
                {t('resources.press')}
              </span>
            </Link>
            <Link
              className={css(styles.NavBar_innerMenuItem)}
              onClick={() => this.handleMenuItemClick()}
              to="/integrations"
            >
              <span className={css(styles.NavBar_mobileMenuSmallItemText)}>
                {t('resources.integrations')}
              </span>
            </Link>
            <a
              href="https://discord.gg/x3PGVfP"
              target="_blank"
              rel="noreferrer noopener"
              className={css(styles.NavBar_innerMenuItem)}
            >
              <span
                className={css(
                  styles.NavBar_mobileMenuSmallItemText,
                  styles.NavBar_mobileMenuLastSmallItem,
                )}
              >
                Discord
              </span>
            </a>
          </div>

          {/* Mobile Company Dropdown */}
          <div
            className={css(
              styles.NavBar_innerMenuItem,
              styles.NavBar_menuItemBorder,
              styles.NavBar_mobileMenuDropdown,
            )}
            onClick={this.handleCompanyClick}
          >
            <span className={css(styles.NavBar_menuItemText, styles.NavBar_mobileMenuItemText)}>
              {t('company.company')}
            </span>
            <img
              src={chevronDown}
              alt="Mobile Chevron Symbol"
              className={css(
                styles.NavBar_mobileChevron,
                companyDropdownOpen && styles.NavBar_mobileChevronUp,
              )}
            />
          </div>
          <div
            className={css(styles.NavBar_innerMenuItem, styles.NavBar_menuItemBorder)}
            onClick={() => {
              if (account) {
                this.handleMenuItemClick();
                logOut();
                if (activeItem === '/account') {
                  history.push('/');
                }
              } else {
                this.handleMenuItemClick();
                handleLogin();
              }
            }}
          >
            <span className={css(styles.NavBar_menuItemText)} style={{ marginLeft: '-30px' }}>
              {account ? t('account.sign-out') : t('sign-in')}
            </span>
          </div>
        </MobileMenu>

        <Link to="/">
          <img src={logoWithText} className={css(styles.NavBar_mobileLogo)} alt="Set logo" />
        </Link>
        <div
          className={css(styles.NavBar_mobileAccountSignIn)}
          onClick={() => {
            if (account) {
              this.handleMenuItemClick();
              this.navigateToAccount();
            } else {
              this.handleMenuItemClick();
              handleLogin(GO_TO_ACCOUNT_PAGE);
            }
          }}
        >
          <img
            className={css(styles.NavBar_accountIcon)}
            src={accountIconDark}
            alt="Account icon"
          />
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { activeItem, windowDimension, isLoggedIn, showBackground } = this.props;
    const { isTabletLandscape } = windowDimension;

    return (
      <React.Fragment>
        {activeItem === '/explore' && showBackground && (
          <div className={css(styles.NavBar_backgroundContainer)} />
        )}
        {isTabletLandscape ? <div className={css(styles.NavBar_mobilePlaceholder)} /> : null}
        <div className={css(styles.NavBar_outerContainer)}>
          <Container
            className={css(styles.NavBar_container)}
            data-select={DATA_SELECTS.NAVBAR_BODY}
            id="NavBarHomeFeed_container"
          >
            <Menu borderless className={css(styles.NavBar_menu)}>
              {!isTabletLandscape && (
                <Menu.Item
                  header
                  as={Link}
                  name="Logo"
                  to="/"
                  onClick={() => this.handleMenuItemClick()}
                  className={css(
                    styles.NavBar_menuItem,
                    styles.NavBar_logoMenuItem,
                    styles.NavBar_noHover,
                  )}
                  key="Logo"
                  data-select={DATA_SELECTS.NAVBAR_HOME}
                >
                  <div className={css(styles.NavBar_logoContainer)}>
                    <img
                      src={logoWithText}
                      alt="TokenSets"
                      className={css(styles.NavBar_logoWithText)}
                    />
                  </div>
                </Menu.Item>
              )}
              {isTabletLandscape ? (
                this.renderMobileMenu()
              ) : (
                <Menu.Menu className={css(styles.NavBar_rightMenu)}>
                  {this.renderResourcesDropdown()}
                  {this.renderExploreSetsDropdown()}
                  {isLoggedIn ? this.renderAccountDropdown() : this.renderSignIn()}
                </Menu.Menu>
              )}
            </Menu>
          </Container>
          {activeItem.includes('/set/') && !activeItem.includes('/v2/set/') ? (
            <div className={css(styles.NavBar_separator)} />
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  const currentChain = currentChainOrDefaultSelector(state);
  const providerType = providerTypeSelector(state);

  return {
    providerType,
    windowDimension: state.windowDimension,
    account: state.web3.account,
    username: state.web3.username,
    isLoggedIn: state.web3.isLoggedIn,
    isSocialTrader: state.web3.isSocialTrader,
    currentChain,
  };
};

const mapDispatchToProps = {
  handleLogin: handleLoginAction,
  logOut: logOutAction,
  handleSwitchChain: handleSwitchChainAction,
  openChainSwitchModal,
};

const translated = withTranslation('navbar')(NavBar);

export default connect(mapStateToProps, mapDispatchToProps)(translated);
