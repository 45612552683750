import React, { createRef, PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite';

import { COLORS, DATA_SELECTS, STYLES } from '../../constants/index';

const { monoFont, sansSerifFont, fontWeights } = STYLES;

const styles = StyleSheet.create<any>({
  textarea: {
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '5px',
    fontWeight: fontWeights.regular,
    height: '200px',
    width: '100%',
    margin: '0',
    padding: '28px',
    resize: 'none',
    transition: '0.2s',
    '::placeholder': {
      color: COLORS.darkGray,
    },
  },
  inputContainer: {
    position: 'relative',
    minWidth: '150px',
    margin: '30px auto',
  },
  // Widths
  threeQuarters: { width: '75%' },
  half: { width: '50%' },
  quarter: { width: '25%' },
});

export interface ITextAreaProps {
  readonly error?: boolean;
  readonly isFocus?: boolean;
  readonly isSubmitting?: boolean;
  readonly borderWidth?: string;
  readonly fontStyle?: string;
  readonly placeholder?: string;
  readonly value?: string;
  readonly width?: string;
  readonly children?: React.ReactNode[];
  readonly onChange?: (...args: any[]) => any;
}

class TextArea extends PureComponent<ITextAreaProps> {
  private inputRef = createRef<HTMLInputElement>();

  public static get defaultProps() {
    return {
      error: false,
      isFocus: false,
      isSubmitting: false,
      borderWidth: '1px',
      fontStyle: '',
      placeholder: '',
      value: '',
      width: '',
      onChange: () => {},
    };
  }

  public static handleStyles(borderWidth: string, error: any, fontStyle: string) {
    return StyleSheet.create({
      textArea: {
        ...styles.input,
        border: `${borderWidth} solid ${error ? COLORS.red : COLORS.gray}`,
        fontFamily: fontStyle === 'mono' ? monoFont : sansSerifFont,
        ':focus': {
          border: `${borderWidth} solid ${error ? COLORS.red : COLORS.blue}`,
        },
      },
    });
  }

  public componentDidMount() {
    const { isFocus } = this.props;
    if (isFocus && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  public render() {
    const {
      borderWidth,
      error,
      fontStyle,
      onChange,
      isSubmitting,
      placeholder,
      value,
      width,
    } = this.props;
    const textAreaStyle = TextArea.handleStyles(borderWidth, error, fontStyle);
    const widthStyle = styles[width];
    return (
      <div
        className={css(styles.inputContainer, widthStyle)}
        data-select={DATA_SELECTS.INPUT_CONTAINER}
      >
        <textarea
          className={css(styles.textarea, textAreaStyle.textArea)}
          disabled={isSubmitting}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
        />
      </div>
    );
  }
}

export default TextArea;
