import Web3 from 'web3';
import { createSelector } from 'reselect';
import { utils, BigNumber as EthersBigNumber } from 'ethers';
import { toChecksumAddress } from 'tochecksum';

import { ApprovalStatus } from '../containers/IssuanceApprovalV2/enums';
import {
  allApprovalsSelector,
  erc20BalancesSelector,
  issuanceInputQuantityV2Selector,
  setDetailsCurrentSetAddressSelector,
  perpIssuanceModuleEnabledSelector,
  requiredPerpIssuanceComponentsSelector,
  accountSelector,
  maxTokenPerpIssueAmountByLeverageSelector,
  requiredPerpRedemptionComponentsSelector,
  networkIdSelector,
  redemptionInputQuantityV2Selector,
  isSubmittingRedemptionTransactionV2Selector,
} from '../selectors/baseSelectors';
import {
  currentSetDetailsSelector,
  currentSetPositionsSelector,
  currentSetTotalSupplySelector,
} from '../selectors/setDetailsSelectors';
import { DEFAULT_TOKEN_LIST_ENTRY } from '../constants/defaultParameters';
import {
  FormType,
  IApprovalStatuses,
  IDebtComponents,
  IDebtComponentWithToken,
} from '../typings/index';
import { BigNumber } from '../utils/index';
import { truncateEthAddress } from '../utils/formatUtils';
import { getWeb3Instance } from '../utils/web3Utils';
import { tokenFromBaseUnits } from '../utils/formatUtils';
import aumCaps from '../constants/aumCaps';
import perpIssuanceModuleABI from '../constants/abis/perpIssuanceModuleABI';
import { isSubmittingIssuanceTransactionV2Selector } from './baseSelectors';
import { GAS_BUFFER_MULTIPLIER } from '../constants/transactionOpts';
import { coingeckoTokenListByAddressSelector } from './tokenListsSelectors';
import { DEFAULT_PARAMETERS, POSITION_STATE, TYPE_ISSUE, TYPE_REDEEM } from '../constants/index';
import { gasPriceTransactionOptionsSelector, maxSlippagePercentageAllowedSelector } from '.';
import { perpIssuanceModuleAddressSelector } from './protocolAddressSelector';

// ********** General Selectors **********

/* Represents the current Set as a perp component. Used for perp redemption approvals + form */
export const currentSetDetailsAsPerpComponentWithTokenSelector = createSelector(
  currentSetDetailsSelector,
  setDetailsCurrentSetAddressSelector,
  networkIdSelector,
  (currentSetDetails, currentSetAddress, networkId): IDebtComponentWithToken => {
    return {
      ...DEFAULT_TOKEN_LIST_ENTRY,
      address: currentSetAddress,
      chainId: networkId,
      decimals: '18',
      name: currentSetDetails?.name,
      symbol: currentSetDetails?.symbol,
      equityValue: EthersBigNumber.from(0),
      debtValue: EthersBigNumber.from(10).pow(18),
    };
  },
);

export const componentsEquityDebtWithTokenSelector = (components: IDebtComponents, state: any) => {
  const tokenListByAddress = coingeckoTokenListByAddressSelector(state);
  const { componentAddresses, equityValues, debtValues } = components || {};

  const componentsWithToken = componentAddresses?.map((address: string, index: number) => {
    const annotatedDefaultToken = {
      ...DEFAULT_TOKEN_LIST_ENTRY,
      address,
      symbol: truncateEthAddress(address),
      name: truncateEthAddress(address),
    };

    const tokenListEntry = tokenListByAddress[address.toLowerCase()] || annotatedDefaultToken;

    const equityValue = equityValues[index];
    const debtValue = debtValues[index];

    return {
      ...tokenListEntry,
      equityValue,
      debtValue,
    };
  });

  return componentsWithToken;
};

export const isPerpIssuanceModuleEnabledForCurrentSetSelector = (state: any): boolean => {
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);
  const perpIssuanceEnabledMap = perpIssuanceModuleEnabledSelector(state);

  return perpIssuanceEnabledMap?.[currentSetAddress] || false;
};

// ********** AUM Cap Selectors **********

export const perpIssuanceAumCapSelector = (state: any): BigNumber | null => {
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);

  return aumCaps[currentSetAddress] || null;
};

export const maxIssuableTokenQuantityByAumCapSelector = (state: any): BigNumber | null => {
  const issuanceCap = perpIssuanceAumCapSelector(state);
  const currentTotalSupply = currentSetTotalSupplySelector(state);

  if (!issuanceCap) return null;

  return tokenFromBaseUnits(issuanceCap.sub(currentTotalSupply).toString());
};

export const doesCurrentInputQuantityExceedAumCapSelector = (state: any): boolean => {
  const rawIssuanceQuantity = issuanceInputQuantityV2Selector(state);
  const maxIssuableQuantity = maxIssuableTokenQuantityByAumCapSelector(state);

  if (!maxIssuableQuantity) return false;

  return maxIssuableQuantity.lt(rawIssuanceQuantity || '0');
};

export const issuanceInputQuantityAboveAumCap = (state: any) => {
  const maxIssuableByAumCap = maxIssuableTokenQuantityByAumCapSelector(state);
  const rawInputQuantity = issuanceInputQuantityV2Selector(state);

  return new BigNumber(rawInputQuantity || '0').sub(maxIssuableByAumCap || '0').toString();
};

// ********** Component Selectors **********

// The set token is required for redemption, but not required for approvals.
export const allPerpComponentsSelector = (
  type: FormType,
  state: any,
): IDebtComponentWithToken[] => {
  const currentSet = currentSetDetailsAsPerpComponentWithTokenSelector(state);
  const componentsWithTokens = approvalRequiredPerpComponents(type, state);

  if (type === TYPE_ISSUE) {
    return componentsWithTokens;
  } else {
    return componentsWithTokens ? [currentSet].concat(componentsWithTokens) : [currentSet];
  }
};

export const approvalRequiredPerpComponents = (
  type: FormType,
  state: any,
): IDebtComponentWithToken[] => {
  const perpComponents =
    type === TYPE_REDEEM
      ? requiredPerpRedemptionComponentsSelector(state)
      : requiredPerpIssuanceComponentsSelector(state);
  return componentsEquityDebtWithTokenSelector(perpComponents, state);
};

export const perpIssuanceInputComponentsSelector = (state: any): IDebtComponentWithToken[] => {
  const allComponents = allPerpComponentsSelector(TYPE_ISSUE, state);
  return allComponents?.filter((component: IDebtComponentWithToken) =>
    component.equityValue?.gt(0),
  );
};

export const perpIssuanceOutputComponentsSelector = (state: any): IDebtComponentWithToken[] => {
  const allComponents = allPerpComponentsSelector(TYPE_ISSUE, state);
  return allComponents?.filter((component: IDebtComponentWithToken) => component.debtValue?.gt(0));
};

export const perpRedemptionInputComponentsSelector = (state: any): IDebtComponentWithToken[] => {
  const allComponents = allPerpComponentsSelector(TYPE_REDEEM, state);
  return allComponents?.filter((component: IDebtComponentWithToken) => component.debtValue?.gt(0));
};

export const perpRedemptionOutputComponentsSelector = (state: any): IDebtComponentWithToken[] => {
  const allComponents = allPerpComponentsSelector(TYPE_REDEEM, state);
  return allComponents?.filter((component: IDebtComponentWithToken) =>
    component.equityValue?.gt(0),
  );
};

export const allApprovalStatusesByIdForPerpIssuanceRedemption = (
  type: FormType,
  state: any,
): IApprovalStatuses => {
  const requiredComponents = approvalRequiredPerpComponents(type, state);
  const allApprovals = allApprovalsSelector(state);

  if (!requiredComponents || requiredComponents.length == 0) return {};

  const approvalStatuses: IApprovalStatuses = {};

  requiredComponents.forEach((token: IDebtComponentWithToken) => {
    const currentTokenApproval = allApprovals[toChecksumAddress(token.address)];

    if (currentTokenApproval?.isPerpIssuanceApproved) {
      approvalStatuses[toChecksumAddress(token.address)] = ApprovalStatus.APPROVED;
      return;
    }

    if (currentTokenApproval?.isPerpIssuancePending) {
      approvalStatuses[toChecksumAddress(token.address)] = ApprovalStatus.PENDING;
      return;
    }

    approvalStatuses[toChecksumAddress(token.address)] = ApprovalStatus.UNAPPROVED;
  });

  return approvalStatuses;
};

export const allUnapprovedPerpTokensSelector = (
  type: FormType,
  state: any,
): IDebtComponentWithToken[] => {
  const requiredComponents = approvalRequiredPerpComponents(type, state);
  const allApprovalStatusesByTokenId = allApprovalStatusesByIdForPerpIssuanceRedemption(
    type,
    state,
  );

  return requiredComponents?.filter((token: IDebtComponentWithToken) => {
    return (
      allApprovalStatusesByTokenId[toChecksumAddress(token.address)] === ApprovalStatus.UNAPPROVED
    );
  });
};

export const hasAllPerpApprovalsSelector = (type: FormType, state: any): boolean => {
  const requiredComponents = approvalRequiredPerpComponents(type, state);
  const allApprovalStatusesByTokenId = allApprovalStatusesByIdForPerpIssuanceRedemption(
    type,
    state,
  );

  return requiredComponents?.every((token: IDebtComponentWithToken) => {
    return (
      allApprovalStatusesByTokenId[toChecksumAddress(token.address)] === ApprovalStatus.APPROVED
    );
  });
};

export const isAnyApprovalPendingForPerpSelector = (type: FormType, state: any): boolean => {
  const requiredComponents = approvalRequiredPerpComponents(type, state);
  const allApprovalStatusesByTokenId = allApprovalStatusesByIdForPerpIssuanceRedemption(
    type,
    state,
  );

  return requiredComponents?.some((token: IDebtComponentWithToken) => {
    return (
      allApprovalStatusesByTokenId[toChecksumAddress(token.address)] === ApprovalStatus.PENDING
    );
  });
};

export const userHasSufficientFundsForPerpIssuanceQuantity = (state: any): boolean => {
  const requiredComponents = allPerpComponentsSelector(TYPE_ISSUE, state);
  const userERC20Balances = erc20BalancesSelector(state);
  const rawIssuanceQuantity = issuanceInputQuantityV2Selector(state);
  const issuanceInputComponentQuantityPerSetWithSlippage = issuanceInputComponentQuantityPerSetWithSlippageSelector(
    state,
  );

  return requiredComponents?.every((component: IDebtComponentWithToken, i: number) => {
    const requiredQuantity = issuanceInputComponentQuantityPerSetWithSlippage[i].mul(
      rawIssuanceQuantity || 0,
    );
    const userBalance = userERC20Balances[Web3.utils.toChecksumAddress(component.address)];

    return requiredQuantity.lte(userBalance || 0);
  });
};

export const userHasSufficientFundsForPerpRedemptionQuantity = (state: any): boolean => {
  const requiredComponents = allPerpComponentsSelector(TYPE_REDEEM, state);
  const userERC20Balances = erc20BalancesSelector(state);
  const rawRedemptionQuantity = redemptionInputQuantityV2Selector(state);

  return requiredComponents.every((component: IDebtComponentWithToken) => {
    const requiredQuantity = new BigNumber(component.debtValue.toString()).mul(
      rawRedemptionQuantity || 0,
    );
    const userBalance = userERC20Balances[Web3.utils.toChecksumAddress(component.address)];

    return requiredQuantity.lte(userBalance || 0);
  });
};

export const isPerpIssuanceReadySelector = (state: any): boolean => {
  const userHasSufficientFunds = userHasSufficientFundsForPerpIssuanceQuantity(state);
  const rawIssuanceQuantity = issuanceInputQuantityV2Selector(state);
  const exceedsAumCap = doesCurrentInputQuantityExceedAumCapSelector(state);
  const isSubmittingIssuanceTransaction = isSubmittingIssuanceTransactionV2Selector(state);

  return (
    !isSubmittingIssuanceTransaction &&
    userHasSufficientFunds &&
    !exceedsAumCap &&
    Number(rawIssuanceQuantity || '0') > 0
  );
};

export const isPerpRedemptionReadySelector = (state: any): boolean => {
  const userHasSufficientFunds = userHasSufficientFundsForPerpRedemptionQuantity(state);
  const rawRedemptionQuantity = redemptionInputQuantityV2Selector(state);
  const isSubmittingTransaction = isSubmittingRedemptionTransactionV2Selector(state);

  return userHasSufficientFunds && !isSubmittingTransaction && Number(rawRedemptionQuantity) > 0;
};

export const requiredComponentsWithMaxPerpIssuableQuantitySelector = (state: any) => {
  const userBalances = erc20BalancesSelector(state);
  const requiredComponents = allPerpComponentsSelector(TYPE_ISSUE, state);
  const issuanceInputComponentQuantityPerSetWithSlippage = issuanceInputComponentQuantityPerSetWithSlippageSelector(
    state,
  );

  return requiredComponents?.map((component: IDebtComponentWithToken, i: number) => {
    const checksumAddress = Web3.utils.toChecksumAddress(component.address);
    const userBalance = userBalances[checksumAddress] || new BigNumber(0);

    const maxIssuableQuantity = userBalance.div(
      issuanceInputComponentQuantityPerSetWithSlippage[i],
    );

    return {
      ...component,
      maxIssuableQuantity,
    };
  });
};

export const requiredComponentsWithMaxPerpRedeemableQuantitySelector = (state: any) => {
  const userBalances = erc20BalancesSelector(state);
  const requiredComponents = allPerpComponentsSelector(TYPE_REDEEM, state);

  return requiredComponents?.map((component: IDebtComponentWithToken) => {
    const requiredComponentQuantityPerSet = component.debtValue.toString();
    const checksumAddress = Web3.utils.toChecksumAddress(component.address);
    const userBalance = userBalances[checksumAddress] || new BigNumber(0);

    const maxRedeemableQuantity = userBalance.div(requiredComponentQuantityPerSet);

    return {
      ...component,
      maxRedeemableQuantity,
    };
  });
};

export const maxPerpIssuableTokenQuantitySelector = (state: any): string => {
  const requiredComponents = requiredComponentsWithMaxPerpIssuableQuantitySelector(state);
  const maxIssuableQuantityByAumCap = maxIssuableTokenQuantityByAumCapSelector(state);
  const maxTokenPerpIssueAmountByLeverage = maxTokenPerpIssueAmountByLeverageSelector(state);

  if (!requiredComponents?.length) return;

  const tokenWithLowestIssuableQuantity = requiredComponents?.reduce(
    (lowestToken: any, currentToken: any) => {
      if (new BigNumber(currentToken.maxIssuableQuantity).lt(lowestToken.maxIssuableQuantity))
        return currentToken;

      return lowestToken;
    },
    requiredComponents[0],
  );

  const maxIssuableQuantity =
    tokenWithLowestIssuableQuantity?.maxIssuableQuantity?.toFixed(
      tokenWithLowestIssuableQuantity.decimals,
      BigNumber.ROUND_DOWN,
    ) || '0';

  return BigNumber.min(
    maxIssuableQuantityByAumCap || Infinity,
    maxIssuableQuantity,
    maxTokenPerpIssueAmountByLeverage
      ? tokenFromBaseUnits(maxTokenPerpIssueAmountByLeverage)
      : Infinity,
  ).toString();
};

export const maxPerpRedeemableTokenQuantitySelector = (state: any): string => {
  const requiredComponents = requiredComponentsWithMaxPerpRedeemableQuantitySelector(state);

  if (!requiredComponents?.length) return;

  const tokenWithLowestRedeemableQuantity = requiredComponents.reduce(
    (lowestToken: any, currentToken: any) => {
      if (currentToken.maxRedeemableQuantity.lt(lowestToken.maxRedeemableQuantity))
        return currentToken;

      return lowestToken;
    },
    requiredComponents[0],
  );

  const maxRedeemableQuantity =
    tokenWithLowestRedeemableQuantity?.maxRedeemableQuantity?.toFixed(
      tokenWithLowestRedeemableQuantity.decimals,
      BigNumber.ROUND_DOWN,
    ) || '0';

  return maxRedeemableQuantity;
};

export const issuanceInputComponentQuantityPerSetWithSlippageSelector = (state: any) => {
  const requiredComponents = perpIssuanceInputComponentsSelector(state);
  const maxSlippagePercentageAllowed = maxSlippagePercentageAllowedSelector(state);
  const positions = currentSetPositionsSelector(state);

  return requiredComponents?.map((component: IDebtComponentWithToken) => {
    const inputComponentQuantityPerSet = component.equityValue.toString();

    // Check that the positions of this address do not have any external positions
    const hasOnlyDefaultPosition = !positions
      .filter(p => p.component.toLowerCase() === component.address.toLowerCase())
      .some(p => p.positionState === POSITION_STATE['EXTERNAL']);

    const multiplyFactor = hasOnlyDefaultPosition
      ? 1
      : new BigNumber(1).plus(
          new BigNumber(
            maxSlippagePercentageAllowed ||
              DEFAULT_PARAMETERS.DEFAULT_SLIPPAGE_MODULE_SLIPPAGE_PERCENTAGE,
          ).div(100),
        );

    return new BigNumber(inputComponentQuantityPerSet).mul(multiplyFactor);
  });
};

export const redemptionOutputComponentQuantityPerSetWithSlippageSelector = (state: any) => {
  const requiredComponents = perpRedemptionOutputComponentsSelector(state);
  const maxSlippagePercentageAllowed = maxSlippagePercentageAllowedSelector(state);
  const positions = currentSetPositionsSelector(state);

  return requiredComponents?.map((component: IDebtComponentWithToken) => {
    const outputComponentQuantityPerSet = component.equityValue.toString();

    // Check that the positions of this address do not have any external positions
    const hasOnlyDefaultPosition = !positions
      .filter(p => p.component.toLowerCase() === component.address.toLowerCase())
      .some(p => p.positionState === POSITION_STATE['EXTERNAL']);

    const multiplyFactor = hasOnlyDefaultPosition
      ? 1
      : new BigNumber(1).minus(
          new BigNumber(
            maxSlippagePercentageAllowed ||
              DEFAULT_PARAMETERS.DEFAULT_SLIPPAGE_MODULE_SLIPPAGE_PERCENTAGE,
          ).div(100),
        );

    return new BigNumber(outputComponentQuantityPerSet).mul(multiplyFactor);
  });
};

// ********** Main Transaction Selectors **********

// Issuance
export const maxTokenAmountsInSelector = (state: any) => {
  const rawIssuanceQuantity = issuanceInputQuantityV2Selector(state);
  const requiredComponents = perpIssuanceInputComponentsSelector(state);
  const issuanceInputComponentQuantityPerSetWithSlippage = issuanceInputComponentQuantityPerSetWithSlippageSelector(
    state,
  );

  return requiredComponents?.map((_: IDebtComponentWithToken, i: number) => {
    const inputComponentQuantityPerSet = issuanceInputComponentQuantityPerSetWithSlippage[i];

    return EthersBigNumber.from(
      new BigNumber(inputComponentQuantityPerSet)
        .mul(rawIssuanceQuantity || 0)
        .round(undefined, BigNumber.ROUND_UP)
        .toString(),
    );
  });
};

// Redemption
export const minTokenAmountsOutSelector = (state: any) => {
  const rawRedemptionQuantity = redemptionInputQuantityV2Selector(state);
  const requiredComponents = perpRedemptionOutputComponentsSelector(state);
  const redemptionOutputComponentQuantityPerSetWithSlippage = redemptionOutputComponentQuantityPerSetWithSlippageSelector(
    state,
  );

  return requiredComponents?.map((_: IDebtComponentWithToken, i: number) => {
    const outputComponentQuantityPerSet = redemptionOutputComponentQuantityPerSetWithSlippage[i];

    return EthersBigNumber.from(
      new BigNumber(outputComponentQuantityPerSet)
        .mul(rawRedemptionQuantity || 0)
        .round(undefined, BigNumber.ROUND_DOWN)
        .toString(),
    );
  });
};

export const createPerpIssueRedeemTransactionArgs = async (type: FormType, state: any) => {
  const setAddress = setDetailsCurrentSetAddressSelector(state);
  const userAddress = accountSelector(state);
  const rawQuantity =
    type === TYPE_REDEEM
      ? redemptionInputQuantityV2Selector(state)
      : issuanceInputQuantityV2Selector(state);
  const formattedQuantity = utils.parseEther(rawQuantity?.length ? rawQuantity : '0');
  const gasPriceTransactionOptions = gasPriceTransactionOptionsSelector(state);
  const perpIssuanceModuleAddress = perpIssuanceModuleAddressSelector(state);

  // For Issuance
  const inputComponents = perpIssuanceInputComponentsSelector(state);
  const inputComponentAddresses = inputComponents?.map(c => c.address);
  const maxTokenAmountsIn = maxTokenAmountsInSelector(state);

  // For Redemption
  const outputComponents = perpRedemptionOutputComponentsSelector(state);
  const outputComponentAddresses = outputComponents?.map(c => c.address);
  const minTokenAmountsOut = minTokenAmountsOutSelector(state);

  const web3Instance = await getWeb3Instance();
  const perpIssuanceModuleContract = new web3Instance.eth.Contract(
    perpIssuanceModuleABI as any,
    perpIssuanceModuleAddress,
  );

  const gasLimit =
    type === TYPE_REDEEM
      ? await perpIssuanceModuleContract.methods
          .redeemWithSlippage(
            setAddress,
            formattedQuantity,
            outputComponentAddresses,
            minTokenAmountsOut,
            userAddress,
          )
          .estimateGas({ from: userAddress })
      : await perpIssuanceModuleContract.methods
          .issueWithSlippage(
            setAddress,
            formattedQuantity,
            inputComponentAddresses,
            maxTokenAmountsIn,
            userAddress,
          )
          .estimateGas({ from: userAddress });

  const gasLimitWithBuffer = new BigNumber(gasLimit).mul(GAS_BUFFER_MULTIPLIER || '1').toFixed(0);

  const transactionOpts = {
    ...gasPriceTransactionOptions,
    gasLimit: EthersBigNumber.from(gasLimitWithBuffer),
  };

  if (!setAddress || !userAddress || formattedQuantity.lte(0)) return;

  return [
    setAddress,
    formattedQuantity,
    type === TYPE_REDEEM ? outputComponentAddresses : inputComponentAddresses,
    type === TYPE_REDEEM ? minTokenAmountsOut : maxTokenAmountsIn,
    userAddress,
    undefined,
    transactionOpts,
  ];
};
