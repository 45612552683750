import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite';
import Input from '../../components/Input';
import Text from '../../components/Text';
import { Icon } from 'semantic-ui-react';
import InfoIcon from '../../components/InfoIcon';
import { COLORS, DEFAULT_PARAMETERS } from '../../constants/index';

const styles = StyleSheet.create({
  SlippageInputPopup_settingsIcon: {
    width: '50px',
    top: '15px',
    right: '-2px',
    position: 'absolute',
    cursor: 'pointer',
  },
  SlippageInputPopup_warningSign: {
    color: COLORS.red,
  },
  SlippageInputPopup_transactionSettingsContainer: {
    borderRadius: '5px',
    border: `1px solid ${COLORS.lightGray4}`,
    position: 'absolute',
    top: '45px',
    right: '25px',
    width: '320px',
    backgroundColor: COLORS.lightGray5,
    borderTop: `5px solid ${COLORS.blue}`,
    padding: '12px 12px 18px',
    zIndex: 1000,
  },
  SlippageInputPopup_header: {
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '17px',
    color: COLORS.darkBlue,
  },
  SlippageInputPopup_slippageLabel: {
    fontSize: '12px',
    marginRight: '5px',
    color: COLORS.darkBlue,
  },
  SlippageInputPopup_fieldLabel: {
    marginRight: '8px',
    fontSize: '10px',
  },
  SlippageInputPopup_percentageSelectButton: {
    backgroundColor: COLORS.white,
    border: `1px solid ${COLORS.lightGray4}`,
    height: '30px',
    padding: '6px 5px 5px',
    borderRadius: '20px',
    color: COLORS.darkGray,
    fontSize: '14px',
    width: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '11px',
  },
  SlippageInputPopup_percentageSelectButtonActive: {
    color: COLORS.white,
    backgroundColor: COLORS.blue,
  },
});

type SlippageInputPopupProps = {
  maxSlippagePercentage: string;
  setMaxSlippagePercentage: (...args: any[]) => any;
};

type SlippageInputPopupState = {
  expanded: boolean;
  firstButtonActive: boolean;
  secondButtonActive: boolean;
  thirdButtonActive: boolean;
};

export default class SlippageInputPopup extends PureComponent<
  SlippageInputPopupProps,
  SlippageInputPopupState
> {
  state = {
    expanded: false,
    firstButtonActive: false,
    secondButtonActive: false,
    thirdButtonActive: true,
  };

  render() {
    const { maxSlippagePercentage, setMaxSlippagePercentage } = this.props;
    const { expanded, firstButtonActive, secondButtonActive, thirdButtonActive } = this.state;

    const highSlippage = maxSlippagePercentage && Number(maxSlippagePercentage) > 1;
    const showSlippageValue = !firstButtonActive && !secondButtonActive && !thirdButtonActive;

    return (
      <>
        {expanded && (
          <div
            style={{ height: highSlippage ? '185px' : '140px' }}
            className={css(styles.SlippageInputPopup_transactionSettingsContainer)}
          >
            <Text className={css(styles.SlippageInputPopup_header)}>TRANSACTION SETTINGS</Text>
            <div style={{ display: 'flex' }}>
              <div
                style={{ display: 'flex', alignItems: 'center', paddingLeft: '15px', width: '50%' }}
              >
                <Text className={css(styles.SlippageInputPopup_slippageLabel)}>Max Slippage</Text>
                <InfoIcon
                  hasHighZIndex={true}
                  pointerPosition="bottom center"
                  data={{
                    header: 'Max Slippage',
                    description:
                      'This will be the max allowed slippage percentage when executing a trade ' +
                      'to issue or redeem the Set, for each component.',
                  }}
                />
              </div>
              <div style={{ width: '50%' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                  <button
                    className={css(
                      styles.SlippageInputPopup_percentageSelectButton,
                      firstButtonActive && styles.SlippageInputPopup_percentageSelectButtonActive,
                    )}
                    onClick={() => {
                      this.setState({
                        firstButtonActive: true,
                        secondButtonActive: false,
                        thirdButtonActive: false,
                      });
                      setMaxSlippagePercentage('0.1');
                    }}
                  >
                    0.1%
                  </button>
                  <button
                    className={css(
                      styles.SlippageInputPopup_percentageSelectButton,
                      secondButtonActive && styles.SlippageInputPopup_percentageSelectButtonActive,
                    )}
                    style={{
                      marginRight: '7px',
                      marginLeft: '7px',
                    }}
                    onClick={() => {
                      this.setState({
                        firstButtonActive: false,
                        secondButtonActive: true,
                        thirdButtonActive: false,
                      });
                      setMaxSlippagePercentage('0.5');
                    }}
                  >
                    0.5%
                  </button>
                  <button
                    className={css(
                      styles.SlippageInputPopup_percentageSelectButton,
                      thirdButtonActive && styles.SlippageInputPopup_percentageSelectButtonActive,
                    )}
                    onClick={() => {
                      this.setState({
                        firstButtonActive: false,
                        secondButtonActive: false,
                        thirdButtonActive: true,
                      });
                      setMaxSlippagePercentage(
                        DEFAULT_PARAMETERS.DEFAULT_SLIPPAGE_MODULE_SLIPPAGE_PERCENTAGE,
                      );
                    }}
                  >
                    1.0%
                  </button>
                </div>
                <Input
                  type="number"
                  value={showSlippageValue ? maxSlippagePercentage : undefined}
                  marginStyle="unset"
                  placeholder="Custom %"
                  placeholderFontColor={COLORS.darkGray}
                  innerStyles={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                  customStyles={{
                    padding: '8px 5px 5px',
                    borderRadius: '20px',
                    height: '30px',
                    fontSize: '14px',
                    width: '100%',
                    paddingRight: '10px',
                    textAlign: 'right',
                  }}
                  onChange={e => {
                    if (e.target.value?.length) {
                      setMaxSlippagePercentage(e.target.value);
                      this.setState({
                        firstButtonActive: false,
                        secondButtonActive: false,
                        thirdButtonActive: false,
                      });
                    } else {
                      setMaxSlippagePercentage(
                        DEFAULT_PARAMETERS.DEFAULT_SLIPPAGE_MODULE_SLIPPAGE_PERCENTAGE,
                      );
                      this.setState({ thirdButtonActive: true });
                    }
                  }}
                />
              </div>
            </div>
            {highSlippage && (
              <div style={{ marginTop: '5px', paddingLeft: '15px' }}>
                <Icon
                  className={css(styles.SlippageInputPopup_warningSign)}
                  size="small"
                  name="warning sign"
                />
                <Text styles={{ fontSize: '12px' }} color={COLORS.red}>
                  Your transactions might be frontrun due to higher slippage
                </Text>
              </div>
            )}
          </div>
        )}
        <Icon
          size="large"
          name="setting"
          className={css(styles.SlippageInputPopup_settingsIcon)}
          alt="Settings icon"
          onClick={() => this.setState({ expanded: !this.state.expanded })}
        />
        {expanded && (
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'fixed',
              top: 0,
              left: 0,
            }}
            onClick={() => this.setState({ expanded: false })}
          />
        )}
      </>
    );
  }
}
