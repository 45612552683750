import { css } from 'aphrodite';
import React, { PureComponent } from 'react';
import { Container, Header } from 'semantic-ui-react';

import styles from './homeStyles';

/**
 * @title HomePowerfulIntegrations
 * @author Set Labs
 *
 * The HomePowerfulIntegrations section shows the integrations and interfaces asset managers
 * can use on TokenSets.
 */
class HomePowerfulIntegrations extends PureComponent {
  render() {
    return (
      <Container className={css(styles.Home_paddingBottomLarge)}>
        <div className={css(styles.HomeExplainerSection_container)}>
          <div className={css(styles.HomeExplainerSection_leftColumn)}>
            <div className={css(styles.HomeExplainerSection_titleContainer)}>
              <Header as="h3" className={css(styles.HomeExplainerSection_subTitle)}>
                Trading &amp; Yield Generation
              </Header>
              <Header as="h2" className={css(styles.HomeExplainerSection_title)}>
                Powerful DeFi integrations
              </Header>
            </div>
            <p className={css(styles.HomeExplainerSection_description)}>
              Manage your Sets with advanced trading tools that support DeFi services like AAVE and
              Compound.
            </p>
          </div>
          <div className={css(styles.HomeExplainerSection_imageContainer)}>
            <img
              src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/powerful-integrations.svg"
              className={css(styles.HomeExplainerSection_simpleInterfaceImage)}
              alt="Powerful Integrations"
            />
          </div>
        </div>
      </Container>
    );
  }
}

export default HomePowerfulIntegrations;
