import React, { Fragment } from 'react';
import { css } from 'aphrodite';
import { Grid, Placeholder } from 'semantic-ui-react';
import { IWindowDimensions } from '../../typings/index';

import { styles } from './Account';

interface ISetTokensPlaceholderProps {
  windowDimension: IWindowDimensions;
}

const SetTokensPlaceholder = (props: ISetTokensPlaceholderProps) => {
  const {
    windowDimension: { isMobile },
  } = props;

  return (
    <Fragment>
      <Grid columns={isMobile ? 2 : 3}>
        <Grid.Row className={css(styles.Account_setListRowBorder)}>
          <Grid.Column width={7}>
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </Grid.Column>
          {isMobile ? null : (
            <Grid.Column floated="right" textAlign="right" width={3}>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line />
                </Placeholder.Header>
              </Placeholder>
            </Grid.Column>
          )}
          <Grid.Column floated="right" textAlign="right" width={isMobile ? 5 : 3}>
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </Grid.Column>
          {isMobile ? null : (
            <Grid.Column floated="right" textAlign="right" width={isMobile ? 5 : 3}>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line />
                </Placeholder.Header>
              </Placeholder>
            </Grid.Column>
          )}
        </Grid.Row>
        {[0, 1].map((value: number) => (
          <Grid.Row
            className={css(styles.Account_setListRow, styles.Account_animatedRow)}
            key={`SetsPlaceholder_${value}`}
          >
            <Grid.Column
              className={css(
                styles.Account_cellContentContainer,
                styles.Account_cellContentContainerLeft,
              )}
              width={7}
            >
              <div
                className={css(
                  styles.Account_setIconContainer,
                  styles.Account_setIconPlaceholderContainer,
                )}
              >
                <Placeholder>
                  <Placeholder.Image style={{ width: '28px', height: '28px' }} />
                </Placeholder>
              </div>
              <Placeholder>
                <Placeholder.Image style={{ width: '100px', height: '20px' }} />
              </Placeholder>
            </Grid.Column>
            {isMobile ? null : (
              <Grid.Column
                className={css(
                  styles.Account_cellContentContainer,
                  styles.Account_cellContentContainerRight,
                )}
                floated="right"
                textAlign="right"
                width={3}
              >
                <Placeholder>
                  <Placeholder.Image style={{ width: '50px', height: '20px' }} />
                </Placeholder>
              </Grid.Column>
            )}
            <Grid.Column
              className={css(
                styles.Account_cellContentContainer,
                styles.Account_cellContentContainerRight,
              )}
              floated="right"
              textAlign="right"
              width={isMobile ? 5 : 3}
            >
              <Placeholder>
                <Placeholder.Image style={{ width: '50px', height: '20px' }} />
              </Placeholder>
            </Grid.Column>
            {isMobile ? null : (
              <Grid.Column
                className={css(
                  styles.Account_cellContentContainer,
                  styles.Account_cellContentContainerRight,
                )}
                floated="right"
                textAlign="right"
                width={isMobile ? 5 : 3}
              >
                <Placeholder>
                  <Placeholder.Image style={{ width: '50px', height: '20px' }} />
                </Placeholder>
              </Grid.Column>
            )}
          </Grid.Row>
        ))}
      </Grid>
    </Fragment>
  );
};

export default SetTokensPlaceholder;
