import { connect } from 'react-redux';

import Redemption from './Redemption';
import { fetchERC20AllowancesForCurrentlyViewingSet } from '../../actions/issuanceV2Actions';
import {
  fetchEnabledModules,
  setCurrentlyViewingSetAddress,
  fetchSetDetails,
} from '../../actions/setDetailsActions';
import { fetchCoingeckoTokenList } from '../../actions/tokenListsActions';
import { fetchBalancesForCurrentlyViewingSet } from '../../actions/balanceActions';
import {
  getDebtRedemptionRequiredComponents,
  fetchDebtRedemptionAllowancesForCurrentSet,
} from '../../actions/debtRedemptionActions';
import { getGasPrice } from '../../actions/gasPriceActions';
import { isDebtIssuanceModuleEnabledForCurrentSetSelector } from '../../selectors/debtIssuanceSelectors';
import { isBasicIssuanceModuleEnabledForCurrentSetSelector } from '../../selectors/issuanceV2Selectors';
import { currentSetDetailsSelector } from '../../selectors/setDetailsSelectors';
import { hasAllDebtRedemptionApprovalsSelector } from '../../selectors/debtRedemptionSelectors';
import {
  accountSelector,
  isFetchingModuleEnabledSelector,
  isFetchingSetDetailsSelector,
  setDetailsCurrentSetAddressSelector,
} from '../../selectors/baseSelectors';
import {
  customV2SetPathPrefixSelector,
  isWrongNetworkSelector,
} from '../../selectors/web3Selectors';
import {
  fetchPerpRedemptionAllowancesForCurrentSet,
  getPerpRedemptionRequiredComponents,
} from '../../actions/perpRedemptionActions';
import {
  isPerpIssuanceModuleEnabledForCurrentSetSelector,
  hasAllPerpApprovalsSelector,
} from '../../selectors/perpIssuanceRedemptionSelectors';
import { TYPE_REDEEM } from '../../constants/index';

const mapStateToProps = (state: any) => {
  const customV2SetPathPrefix = customV2SetPathPrefixSelector(state);
  const isLoading = isFetchingSetDetailsSelector(state);
  const isIssuanceModuleEnabled = isBasicIssuanceModuleEnabledForCurrentSetSelector(state);
  const isFetchingIssuanceModuleEnabled = isFetchingModuleEnabledSelector(state);
  const isDebtIssuanceModuleEnabled = isDebtIssuanceModuleEnabledForCurrentSetSelector(state);
  const isPerpIssuanceModuleEnabled = isPerpIssuanceModuleEnabledForCurrentSetSelector(state);
  const hasAllDebtRedemptionApprovals = hasAllDebtRedemptionApprovalsSelector(state);
  const hasAllPerpRedemptionApprovals = hasAllPerpApprovalsSelector(TYPE_REDEEM, state);
  const account = accountSelector(state);
  const setDetails = currentSetDetailsSelector(state);
  const setAddress = setDetailsCurrentSetAddressSelector(state);
  const isWrongNetwork = isWrongNetworkSelector(state);

  return {
    isWrongNetwork,
    setAddress,
    customV2SetPathPrefix,
    isLoading,
    isFetchingIssuanceModuleEnabled,
    isDebtIssuanceModuleEnabled,
    isPerpIssuanceModuleEnabled,
    isIssuanceModuleEnabled,
    hasAllDebtRedemptionApprovals,
    hasAllPerpRedemptionApprovals,
    account,
    setDetails,
  };
};

const mapDispatchToProps = {
  onFetchEnabledModules: fetchEnabledModules,
  onFetchDebtRedemptionComponents: getDebtRedemptionRequiredComponents,
  onFetchPerpRedemptionComponents: getPerpRedemptionRequiredComponents,
  onFetchAllowancesForCurrentSet: fetchERC20AllowancesForCurrentlyViewingSet,
  onFetchDebtRedemptionAllowancesForCurrentSet: fetchDebtRedemptionAllowancesForCurrentSet,
  onFetchPerpRedemptionAllowancesForCurrentSet: fetchPerpRedemptionAllowancesForCurrentSet,
  onFetchCurrentSetTokenBalances: fetchBalancesForCurrentlyViewingSet,
  onFetchGasPrice: getGasPrice,
  onFetchTokenList: fetchCoingeckoTokenList,
  onSetCurrentlyViewingSetAddress: setCurrentlyViewingSetAddress,
  onFetchSetDetailsByAddress: fetchSetDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Redemption);
