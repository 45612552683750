import ethereumAddresses from '../constants/ethereumAddresses';

import CoinIcon from '../img/icons/set-manager-coin-icon.svg';
import PercentIcon from '../img/icons/set-manager-percent-icon.svg';
import TradeIcon from '../img/icons/set-manager-trade-icon.svg';
import setJSConfig from '../constants/setJSConfig';
import { NETWORK_CONSTANTS } from '../constants/index';

export const extensions = {
  ethereum: {
    TRADE_EXTENSION: {
      address: setJSConfig[NETWORK_CONSTANTS.ETHEREUM_CHAIN].tradeExtensionAddress,
      key: 'TRADE_EXTENSION',
      name: 'Trade',
      imageUrl: TradeIcon,
      description: 'Trade tokens in your Set',
      isInitialized: false,
      isAdded: false,
      isOffered: true,
    },
    BATCH_TRADE_EXTENSION: {
      address: setJSConfig[NETWORK_CONSTANTS.ETHEREUM_CHAIN].batchTradeExtensionAddress,
      key: 'BATCH_TRADE_EXTENSION',
      name: 'Batch Trade',
      imageUrl: TradeIcon,
      description: 'Trade multiple tokens in your Set at once',
      isInitialized: false,
      isAdded: false,
      isOffered: true,
    },
    STREAMING_FEE_EXTENSION: {
      address: setJSConfig[NETWORK_CONSTANTS.ETHEREUM_CHAIN].streamingFeeExtensionAddress,
      key: 'STREAMING_FEE_EXTENSION',
      name: 'Streaming Fees',
      imageUrl: PercentIcon,
      description: 'Set Streaming Fee',
      isInitialized: false,
      isAdded: false,
      isOffered: true,
    },
    ISSUANCE_EXTENSION: {
      address: setJSConfig[NETWORK_CONSTANTS.ETHEREUM_CHAIN].issuanceExtensionAddress,
      key: 'ISSUANCE_EXTENSION',
      name: 'Issue & Redeem Fees',
      imageUrl: PercentIcon,
      description: 'Set Issuance & Redemption Fees',
      isInitialized: false,
      isAdded: false,
      isOffered: true,
    },
    TRANSFER_OWNERSHIP: {
      address: null as any,
      key: 'TRANSFER_OWNERSHIP',
      name: 'Transfer Ownership',
      imageUrl: CoinIcon,
      description: 'Specify an address to transfer ownership of this Set to',
      isInitialized: true,
      isAdded: true,
      isOffered: true,
    },
    OPERATORS_ALLOWLIST: {
      address: null as any,
      key: 'OPERATORS_ALLOWLIST',
      name: 'Set Operators',
      imageUrl: CoinIcon,
      description: 'Add or remove operators that manage this Set',
      isInitialized: true,
      isAdded: true,
      isOffered: true,
    },
    ASSET_ALLOWLIST: {
      address: null as any,
      key: 'ASSET_ALLOWLIST',
      name: 'Asset Allowlist',
      imageUrl: CoinIcon,
      description: 'Add or remove tokens that this Set may trade',
      isInitialized: true,
      isAdded: true,
      isOffered: true,
    },
  },
  avalanche: {
    TRADE_EXTENSION: {
      address: setJSConfig[NETWORK_CONSTANTS.AVALANCHE_CHAIN].tradeExtensionAddress,
      key: 'TRADE_EXTENSION',
      name: 'Trade',
      imageUrl: TradeIcon,
      description: 'Trade tokens in your Set',
      isInitialized: false,
      isAdded: false,
      isOffered: true,
    },
    BATCH_TRADE_EXTENSION: {
      address: setJSConfig[NETWORK_CONSTANTS.AVALANCHE_CHAIN].batchTradeExtensionAddress,
      key: 'BATCH_TRADE_EXTENSION',
      name: 'Batch Trade',
      imageUrl: TradeIcon,
      description: 'Trade multiple tokens in your Set at once',
      isInitialized: false,
      isAdded: false,
      isOffered: true,
    },
    STREAMING_FEE_EXTENSION: {
      address: setJSConfig[NETWORK_CONSTANTS.AVALANCHE_CHAIN].streamingFeeExtensionAddress,
      key: 'STREAMING_FEE_EXTENSION',
      name: 'Streaming Fees',
      imageUrl: PercentIcon,
      description: 'Set Streaming Fee',
      isInitialized: false,
      isAdded: false,
      isOffered: true,
    },
    ISSUANCE_EXTENSION: {
      address: setJSConfig[NETWORK_CONSTANTS.AVALANCHE_CHAIN].issuanceExtensionAddress,
      key: 'ISSUANCE_EXTENSION',
      name: 'Issue & Redeem Fees',
      imageUrl: PercentIcon,
      description: 'Set Issuance & Redemption Fees',
      isInitialized: false,
      isAdded: false,
      isOffered: true,
    },
    TRANSFER_OWNERSHIP: {
      address: null as any,
      key: 'TRANSFER_OWNERSHIP',
      name: 'Transfer Ownership',
      imageUrl: CoinIcon,
      description: 'Specify an address to transfer ownership of this Set to',
      isInitialized: true,
      isAdded: true,
      isOffered: true,
    },
    OPERATORS_ALLOWLIST: {
      address: null as any,
      key: 'OPERATORS_ALLOWLIST',
      name: 'Set Operators',
      imageUrl: CoinIcon,
      description: 'Add or remove operators that manage this Set',
      isInitialized: true,
      isAdded: true,
      isOffered: true,
    },
    ASSET_ALLOWLIST: {
      address: null as any,
      key: 'ASSET_ALLOWLIST',
      name: 'Asset Allowlist',
      imageUrl: CoinIcon,
      description: 'Add or remove tokens that this Set may trade',
      isInitialized: true,
      isAdded: true,
      isOffered: true,
    },
  },
  optimism: {
    TRADE_EXTENSION: {
      address: setJSConfig[NETWORK_CONSTANTS.OPTIMISM_CHAIN].tradeExtensionAddress,
      key: 'TRADE_EXTENSION',
      name: 'Trade',
      imageUrl: TradeIcon,
      description: 'Trade tokens in your Set',
      isInitialized: false,
      isAdded: false,
      isOffered: true,
    },
    BATCH_TRADE_EXTENSION: {
      address: setJSConfig[NETWORK_CONSTANTS.OPTIMISM_CHAIN].batchTradeExtensionAddress,
      key: 'BATCH_TRADE_EXTENSION',
      name: 'Batch Trade',
      imageUrl: TradeIcon,
      description: 'Trade multiple tokens in your Set at once',
      isInitialized: false,
      isAdded: false,
      isOffered: true,
    },
    STREAMING_FEE_EXTENSION: {
      address: setJSConfig[NETWORK_CONSTANTS.OPTIMISM_CHAIN].streamingFeeExtensionAddress,
      key: 'STREAMING_FEE_EXTENSION',
      name: 'Streaming Fees',
      imageUrl: PercentIcon,
      description: 'Set Streaming Fee',
      isInitialized: false,
      isAdded: false,
      isOffered: true,
    },
    ISSUANCE_EXTENSION: {
      address: setJSConfig[NETWORK_CONSTANTS.OPTIMISM_CHAIN].issuanceExtensionAddress,
      key: 'ISSUANCE_EXTENSION',
      name: 'Issue & Redeem Fees',
      imageUrl: PercentIcon,
      description: 'Set Issuance & Redemption Fees',
      isInitialized: false,
      isAdded: false,
      isOffered: true,
    },
    TRANSFER_OWNERSHIP: {
      address: null as any,
      key: 'TRANSFER_OWNERSHIP',
      name: 'Transfer Ownership',
      imageUrl: CoinIcon,
      description: 'Specify an address to transfer ownership of this Set to',
      isInitialized: true,
      isAdded: true,
      isOffered: true,
    },
    OPERATORS_ALLOWLIST: {
      address: null as any,
      key: 'OPERATORS_ALLOWLIST',
      name: 'Set Operators',
      imageUrl: CoinIcon,
      description: 'Add or remove operators that manage this Set',
      isInitialized: true,
      isAdded: true,
      isOffered: true,
    },
    ASSET_ALLOWLIST: {
      address: null as any,
      key: 'ASSET_ALLOWLIST',
      name: 'Asset Allowlist',
      imageUrl: CoinIcon,
      description: 'Add or remove tokens that this Set may trade',
      isInitialized: true,
      isAdded: true,
      isOffered: true,
    },
  },
  polygon: {
    ISSUANCE_EXTENSION: {
      address: setJSConfig[NETWORK_CONSTANTS.POLYGON_CHAIN].issuanceExtensionAddress,
      key: 'ISSUANCE_EXTENSION',
      name: 'Issue & Redeem Fees',
      imageUrl: PercentIcon,
      description: 'Set Issuance & Redemption Fees',
      isInitialized: false,
      isAdded: false,
      isOffered: true,
    },
    STREAMING_FEE_EXTENSION: {
      address: setJSConfig[NETWORK_CONSTANTS.POLYGON_CHAIN].streamingFeeExtensionAddress,
      key: 'STREAMING_FEE_EXTENSION',
      name: 'Streaming Fees',
      imageUrl: PercentIcon,
      description: 'Set Streaming Fee',
      isInitialized: false,
      isAdded: false,
      isOffered: true,
    },
    TRANSFER_OWNERSHIP: {
      address: null as any,
      key: 'TRANSFER_OWNERSHIP',
      name: 'Transfer Ownership',
      imageUrl: CoinIcon,
      description: 'Specify an address to transfer ownership of this Set to',
      isInitialized: true,
      isAdded: true,
      isOffered: true,
    },
    OPERATORS_ALLOWLIST: {
      address: null as any,
      key: 'OPERATORS_ALLOWLIST',
      name: 'Set Operators',
      imageUrl: CoinIcon,
      description: 'Add or remove operators that manage this Set',
      isInitialized: true,
      isAdded: true,
      isOffered: true,
    },
    TRADE_EXTENSION: {
      address: setJSConfig[NETWORK_CONSTANTS.POLYGON_CHAIN].tradeExtensionAddress,
      key: 'TRADE_EXTENSION',
      name: 'Trade',
      imageUrl: TradeIcon,
      description: 'Trade tokens in your Set',
      isInitialized: false,
      isAdded: false,
      isOffered: true,
    },
    BATCH_TRADE_EXTENSION: {
      address: setJSConfig[NETWORK_CONSTANTS.POLYGON_CHAIN].batchTradeExtensionAddress,
      key: 'BATCH_TRADE_EXTENSION',
      name: 'Batch Trade',
      imageUrl: TradeIcon,
      description: 'Trade multiple tokens in your Set at once',
      isInitialized: false,
      isAdded: false,
      isOffered: true,
    },
    CLAIM_EXTENSION: {
      address: ethereumAddresses.NULL_ADDRESS,
      key: 'CLAIM_EXTENSION',
      name: 'Claim',
      imageUrl: 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/claim.svg',
      description: 'Collect airdropped tokens and staking rewards',
      isInitialized: false,
      isAdded: false,
      isOffered: false,
    },
    LEVERAGE_EXTENSION: {
      address: ethereumAddresses.NULL_ADDRESS,
      key: 'LEVERAGE_EXTENSION',
      name: 'Leverage',
      imageUrl: 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/long_short.svg',
      description: 'Go long or short by borrowing collateral from Aave, Compound, and Maker',
      isInitialized: false,
      isAdded: false,
      isOffered: false,
    },
    GOVERNANCE_EXTENSION: {
      address: ethereumAddresses.NULL_ADDRESS,
      key: 'GOVERNANCE_EXTENSION',
      name: 'Governance',
      imageUrl: 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/governance.svg',
      description: 'Participate in governance using your Set’s tokens',
      isInitialized: false,
      isAdded: false,
      isOffered: false,
    },
    WRAP_EXTENSION: {
      address: ethereumAddresses.NULL_ADDRESS,
      key: 'WRAP_EXTENSION',
      name: 'Wrap',
      imageUrl: 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/wrap.svg',
      description: 'Earn yield on your assets by wrapping into cTokens, aTokens, yUSD',
      isInitialized: false,
      isAdded: false,
      isOffered: false,
    },
    ASSET_ALLOWLIST: {
      address: null as any,
      key: 'ASSET_ALLOWLIST',
      name: 'Asset Allowlist',
      imageUrl: CoinIcon,
      description: 'Add or remove tokens that this Set may trade',
      isInitialized: true,
      isAdded: true,
      isOffered: true,
    },
  },
};
