import { connect } from 'react-redux';

import IssuanceApproval from '../../containers/IssuanceApprovalV2/IssuanceApproval';
import {
  setDetailsCurrentSetAddressSelector,
  windowDimensionSelector,
} from '../../selectors/baseSelectors';
import {
  approveTokenForDebtIssuance,
  approveAllRequiredTokensForDebtIssuance,
} from '../../actions/approvalActions';
import {
  debtIssuanceInputComponentsSelector,
  isAnyApprovalPendingForDebtIssuanceSelector,
  allApprovalStatusesByIdForDebtIssuance,
} from '../../selectors/debtIssuanceSelectors';
import { customV2SetPathPrefixSelector } from '../../selectors/web3Selectors';

const mapStateToProps = (state: any) => {
  const isAnyApprovalPending = isAnyApprovalPendingForDebtIssuanceSelector(state);
  const allApprovalStatusesByTokenId = allApprovalStatusesByIdForDebtIssuance(state);
  const requiredComponents = debtIssuanceInputComponentsSelector(state);
  const customV2SetPathPrefix = customV2SetPathPrefixSelector(state);
  const setAddress = setDetailsCurrentSetAddressSelector(state);

  const windowDimensions = windowDimensionSelector(state);

  return {
    customV2SetPathPrefix,
    setAddress,
    isAnyApprovalPending,
    allApprovalStatusesByTokenId,
    requiredComponents,
    windowDimensions,
  };
};

const mapDispatchToProps = {
  approveAllListTokensForCurrentSet: approveAllRequiredTokensForDebtIssuance,
  approveListTokenAllowance: approveTokenForDebtIssuance,
};

export default connect(mapStateToProps, mapDispatchToProps)(IssuanceApproval);
