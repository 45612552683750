import UniswapRouterABI from '@uniswap/v2-periphery/build/IUniswapV2Router02.json';
import { BigNumber as EthersBigNumber } from 'ethers';
import { getWeb3Instance, payloadActionGenerator } from '../utils/index';
import { setJSInstanceSelector, accountSelector } from '../selectors/baseSelectors';
import { ETHEREUM_CONSTANTS, UNISWAP } from '../constants/index';
import { TransactionOptions, IERC20Token } from '../typings/index';
import { gasPriceTransactionOptionsSelector } from '../selectors/transactionSelectors';
import erc20ABI from '../constants/abis/erc20ABI';

const { UNISWAP_ROUTER_ADDRESS, SUSHISWAP_ROUTER_ADDRESS } = UNISWAP;

export enum UniswapTradeType {
  SWAP_EXACT_TOKENS_FOR_TOKENS,
  SWAP_EXACT_TOKENS_FOR_ETH,
  SWAP_EXACT_ETH_FOR_TOKENS,
  SWAP_ETH_FOR_EXACT_TOKENS,
}

export const INITIALIZE_UNISWAP_ROUTER = 'INITIALIZE_UNISWAP_ROUTER';
export const initializeUniswapRouterAction = payloadActionGenerator(INITIALIZE_UNISWAP_ROUTER);

export const INITIALIZE_SUSHISWAP_ROUTER = 'INITIALIZE_SUSHISWAP_ROUTER';
export const initializeSushiswapRouterAction = payloadActionGenerator(INITIALIZE_SUSHISWAP_ROUTER);

export const initializeUniswapRouter = (web3Instance: any) => (dispatch: any) => {
  const uniswapInstance = new web3Instance.eth.Contract(
    UniswapRouterABI.abi,
    UNISWAP_ROUTER_ADDRESS,
  );

  dispatch(initializeUniswapRouterAction(uniswapInstance));
};

export const initializeSushiswapRouter = (web3Instance: any) => (dispatch: any) => {
  const sushiswapInstance = new web3Instance.eth.Contract(
    UniswapRouterABI.abi,
    SUSHISWAP_ROUTER_ADDRESS,
  );

  dispatch(initializeSushiswapRouterAction(sushiswapInstance));
};

export const getUniswapTradeTransaction = (
  uniswapOrSushiswapInstance: any,
  tradeType: UniswapTradeType,
  tradeConfigs: any[],
  txOpts: TransactionOptions,
): (() => Promise<string>) => {
  switch (tradeType) {
    case UniswapTradeType.SWAP_EXACT_ETH_FOR_TOKENS:
      return () =>
        new Promise((resolve, reject) => {
          uniswapOrSushiswapInstance.methods
            .swapExactETHForTokens(...tradeConfigs)
            .send(txOpts)
            .on('transactionHash', (txId: string) => {
              if (!txId) reject();

              resolve(txId);
            })
            .on('error', (error: any) => {
              reject(error);
            });
        });
    case UniswapTradeType.SWAP_EXACT_TOKENS_FOR_ETH:
      return () =>
        new Promise((resolve, reject) => {
          uniswapOrSushiswapInstance.methods
            .swapExactTokensForETH(...tradeConfigs)
            .send(txOpts)
            .on('transactionHash', (txId: string) => {
              if (!txId) reject();

              resolve(txId);
            })
            .on('error', (error: any) => {
              reject(error);
            });
        });

    case UniswapTradeType.SWAP_EXACT_TOKENS_FOR_TOKENS:
      return () =>
        new Promise((resolve, reject) => {
          uniswapOrSushiswapInstance.methods
            .swapExactTokensForTokens(...tradeConfigs)
            .send(txOpts)
            .on('transactionHash', (txId: string) => {
              if (!txId) reject();

              resolve(txId);
            })
            .on('error', (error: any) => {
              reject(error);
            });
        });

    case UniswapTradeType.SWAP_ETH_FOR_EXACT_TOKENS:
      return () =>
        new Promise((resolve, reject) => {
          uniswapOrSushiswapInstance.methods
            .swapETHForExactTokens(...tradeConfigs)
            .send(txOpts)
            .on('transactionHash', (txId: string) => {
              if (!txId) reject();

              resolve(txId);
            })
            .on('error', (error: any) => {
              reject(error);
            });
        });
  }
};

/**
 * Approve the target token to the Uniswap Router for trading.
 * @param token - Token to be approved
 */
export const approveToUniswapOrSushiswapRouter = (token: IERC20Token) => async (
  _: any,
  getState: any,
) => {
  const state = getState();
  const setJSInstance = setJSInstanceSelector(state);
  const userAccount = accountSelector(state);
  const gasPriceTransactionOptions = gasPriceTransactionOptionsSelector(state);
  const web3Instance = await getWeb3Instance();
  const erc20Contract = new web3Instance.eth.Contract(erc20ABI as any, token.address);

  const transferProxyAllowance = ETHEREUM_CONSTANTS.UNLIMITED_TRANSFER_PROXY_ALLOWANCE;

  const spenderAddress = SUSHISWAP_ROUTER_ADDRESS;

  const gasLimit = await erc20Contract.methods
    .approve(spenderAddress, EthersBigNumber.from(transferProxyAllowance))
    .estimateGas({ from: userAccount });

  return await setJSInstance.erc20.approveProxyAsync(
    token.address,
    spenderAddress,
    EthersBigNumber.from(transferProxyAllowance),
    userAccount,
    {
      ...gasPriceTransactionOptions,
      gasLimit: EthersBigNumber.from(gasLimit),
    },
  );
};

/**
 * Fetch the user's transfer proxy token allowance for Uniswap Router.
 * @param token - Token allowance to be fetched
 */
export const getUniswapOrSushiswapRouterAllowance = (token: IERC20Token) => async (
  _: any,
  getState: any,
) => {
  const state = getState();
  const userAddress = accountSelector(state);
  const setJSInstance = setJSInstanceSelector(state);
  const spenderAddress = SUSHISWAP_ROUTER_ADDRESS;

  if (!token?.address || !userAddress || !spenderAddress) return null;

  return await setJSInstance.erc20.getAllowanceAsync(
    token.address,
    userAddress,
    spenderAddress,
    userAddress,
  );
};
