import React from 'react';
import { css } from 'aphrodite';
import { Header } from 'semantic-ui-react';

import styles from './OnboardingStyles';
import { withTranslation, WithTranslation } from 'react-i18next';

const WhatIsASet = (props: WithTranslation) => {
  const { t } = props;

  return (
    <div className={css(styles.Onboarding_slide)}>
      <div className={css(styles.Onboarding_slideSection)}>
        <Header as="h1" className={css(styles.Onboarding_sectionTitle)}>
          {t('what-is-a-set.title')}
        </Header>
        <span className={css(styles.Onboarding_sectionSubtitle)}>
          {t('what-is-a-set.subtitle')}
        </span>
      </div>
      <div className={css(styles.Onboarding_slideSection)}>
        <img
          alt="What Is A Set?"
          className={css(styles.Onboarding_image)}
          src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/onboarding/bird-and-balloons.svg"
        />
      </div>
    </div>
  );
};

export default withTranslation('onboarding')(WhatIsASet);
