// This file is autogenerated. Run "yarn run-translations" in order to regenerate it

import accountEN from './en/account.json';
import auctionsEN from './en/auctions.json';
import buySellFundFormEN from './en/buy-sell-fund-form.json';
import buySellModalEN from './en/buy-sell-modal.json';
import commonEN from './en/common.json';
import companyEN from './en/company.json';
import componentsEN from './en/components.json';
import exploreEN from './en/explore.json';
import faqEN from './en/faq.json';
import feeControlsEN from './en/fee-controls.json';
import filtersEN from './en/filters.json';
import footerEN from './en/footer.json';
import formsEN from './en/forms.json';
import fundDetailsEN from './en/fund-details.json';
import homeFeedEN from './en/home-feed.json';
import homeEN from './en/home.json';
import integrationsEN from './en/integrations.json';
import issueAndRedeemEN from './en/issue-and-redeem.json';
import loginModalEN from './en/login-modal.json';
import navbarEN from './en/navbar.json';
import onboardingEN from './en/onboarding.json';
import rebalancingNotificationsEN from './en/rebalancing-notifications.json';
import securityEN from './en/security.json';
import sendSetModalEN from './en/send-set-modal.json';
import setDetailsV2EN from './en/set-details-v2.json';
import setDetailsEN from './en/set-details.json';
import socialTraderLandingEN from './en/social-trader-landing.json';
import traderProfileEN from './en/trader-profile.json';

// This file loads and defines the namespaces for usage in components.
// The key is the namespace for a given language.
// E.g. A component will call withTranslation('navbar') for access to /navbar.json strings.

export default {
  en: {
    account: accountEN,
    auctions: auctionsEN,
    'buy-sell-fund-form': buySellFundFormEN,
    'buy-sell-modal': buySellModalEN,
    common: commonEN,
    company: companyEN,
    components: componentsEN,
    explore: exploreEN,
    faq: faqEN,
    'fee-controls': feeControlsEN,
    filters: filtersEN,
    footer: footerEN,
    forms: formsEN,
    'fund-details': fundDetailsEN,
    'home-feed': homeFeedEN,
    home: homeEN,
    integrations: integrationsEN,
    'issue-and-redeem': issueAndRedeemEN,
    'login-modal': loginModalEN,
    navbar: navbarEN,
    onboarding: onboardingEN,
    'rebalancing-notifications': rebalancingNotificationsEN,
    security: securityEN,
    'send-set-modal': sendSetModalEN,
    'set-details-v2': setDetailsV2EN,
    'set-details': setDetailsEN,
    'social-trader-landing': socialTraderLandingEN,
    'trader-profile': traderProfileEN,
  },
};
