import _ from 'lodash';
import React, { Fragment } from 'react';
import { css } from 'aphrodite';
import { Link } from 'react-router-dom';
import { Grid, Checkbox } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { toChecksumAddress } from 'tochecksum';

import SetTokenComponents from './SetTokenComponents';
import SetTokensPlaceholder from './SetTokensPlaceholder';
import { PriceChangeBadge, Text } from '../../components/index';
import {
  IUser,
  IWindowDimensions,
  ISetDetailsHydrated,
  ITokenBalances,
  IListToken,
  IListTokenWithBalance,
} from '../../typings/index';
import { createAnimationDelay } from './utils';
import { styles } from './Account';
import { BigNumber, truncateTokenAmount } from '../../utils/index';
import Web3 from 'web3';
import { tokenToBaseUnits } from '../../utils/formatUtils';
import { tokenListNameOverride } from '../../constants/tokenListConstants';
import { makeBlockExplorerContractLink } from '../../utils/web3Utils';

interface ISetTokensProps {
  portfolioPathPrefix: string;
  customV2SetPathPrefix: string;
  isFetchingApiBalances: boolean;
  deployedSetDetailsHydrated: { [address: string]: ISetDetailsHydrated };
  expandView: boolean;
  erc20Balances: ITokenBalances;
  tokenSetsTokenListByAddress: { [address: string]: IListToken };
  setAddresses: string[];
  geminiTokenListByAddress: { [address: string]: IListToken };
  user: IUser;
  windowDimension: IWindowDimensions;
  accountBalance: string;
  networkCurrencyPrice: string;
  networkListTokenWithBalance: IListTokenWithBalance;
  onToggleExpandedView: (...args: any[]) => any;
}

class SetTokens extends React.Component<ISetTokensProps & WithTranslation> {
  public renderTableControls = () => {
    const { onToggleExpandedView, t } = this.props;

    return (
      <Grid.Row className={css(styles.Account_setListRowCondensed)}>
        <Grid.Column className={css(styles.Account_toggleContainer)} width={16}>
          <div className={css(styles.Account_toggleLabel)}>{t('account.show-expanded-view')}</div>{' '}
          <Checkbox className="dark" toggle onClick={onToggleExpandedView} />
        </Grid.Column>
      </Grid.Row>
    );
  };

  public renderUserOwnsNoSets = () => {
    const {
      t,
      windowDimension: { isTablet },
    } = this.props;

    return (
      <div className={css(styles.Account_welcomeContainer)}>
        {!isTablet && (
          <>
            <p className={css(styles.Account_welcomeTitle)}>{t('welcome.title')}</p>
            <p className={css(styles.Account_welcomeSubtitle)}>{t('welcome.subtitle')}</p>
          </>
        )}
        <div className={css(styles.Account_welcomeOptionsContainer)}>
          <div className={css(styles.Account_welcomeOption)}>
            <div className={css(styles.Account_welcomeOptionHeader)}>
              <img
                src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/flag.svg"
                className={css(styles.Account_welcomeOptionIcon)}
              />
              <span className={css(styles.Account_welcomeOptionTitleText)}>
                {t('welcome.getting-started')}
              </span>
              <span className={css(styles.Account_welcomeOptionBodyText)}>
                {t('welcome.getting-started-subtitle')}
              </span>
            </div>
            <a
              className={css(styles.Account_welcomeOptionButton)}
              href="https://help.tokensets.com/en/articles/3961247-welcome-to-tokensets"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('welcome.getting-started-button')}
            </a>
          </div>

          <div className={css(styles.Account_welcomeOption)}>
            <div className={css(styles.Account_welcomeOptionHeader)}>
              <img
                src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/magnifying-glass.svg"
                className={css(styles.Account_welcomeOptionIcon)}
              />
              <span className={css(styles.Account_welcomeOptionTitleText)}>
                {t('welcome.already-have-an-account')}
              </span>
              <span className={css(styles.Account_welcomeOptionBodyText)}>
                {t('welcome.already-have-an-account-subtitle')}
              </span>
            </div>
            <a
              className={css(styles.Account_welcomeOptionButton)}
              href="https://help.tokensets.com/en/articles/3787480-my-account-shows-0-set-balances-why-is-this-happening"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('welcome.already-have-an-account-button')}
            </a>
          </div>
        </div>
      </div>
    );
  };

  public renderSetToken = (set: any, index: number, isLastSet: boolean, isFund: boolean) => {
    const {
      portfolioPathPrefix,
      expandView,
      windowDimension,
      windowDimension: { isMobile, isTablet },
    } = this.props;

    const borderStyles = isLastSet ? undefined : styles.Account_setListRowBorder;
    const animationDelay = createAnimationDelay(index)[`Account_rowAnimationDelay${index}`];
    const alignment = isMobile ? 'right' : 'left';
    const path = isFund ? `${portfolioPathPrefix}/${set.id}` : `/set/${set.id}`;

    return (
      <Grid.Row
        className={css(
          styles.Account_setListRow,
          borderStyles,
          styles.Account_animatedRow,
          animationDelay,
        )}
        key={set.id}
      >
        <Grid.Column
          as={Link}
          className={
            isMobile
              ? css(styles.Account_cellContentContainer, styles.Account_cellContentContainerLeft)
              : css(styles.Account_cellContentContainer)
          }
          width={isMobile ? 9 : 7}
          to={path}
        >
          <div className={css(styles.Account_setIconContainer)}>
            <img className={css(styles.Account_setIcon)} src={set.image} alt={`${set.name} icon`} />
          </div>
          <div className={css(styles.Account_setNameContainer)}>
            <Text
              color="darkBlue"
              className={css(styles.Account_setTableText, styles.Account_setName)}
            >
              {set.name}
            </Text>
          </div>
        </Grid.Column>

        {!isMobile && (
          <Grid.Column className={css(styles.Account_priceChangeBadgeContainer)} width={3}>
            <PriceChangeBadge percentChange={set.daily_percent_change} isTablet={isTablet} />
          </Grid.Column>
        )}

        <Grid.Column
          className={
            isMobile
              ? css(styles.Account_cellContentContainerRight)
              : css(styles.Account_cellContentContainer)
          }
          floated={alignment}
          textAlign={alignment}
          width={isMobile ? 7 : 3}
        >
          <Text
            color="darkBlue"
            fontWeight={isMobile ? 500 : undefined}
            tag="p"
            className={css(styles.Account_setTableText)}
          >
            {set.balance_usd}
          </Text>
          {isMobile && (
            <Fragment>
              <Text className={css(styles.Account_setTableText)} color="darkGray" tag="p">
                {truncateTokenAmount(set.amount)}
              </Text>
              <PriceChangeBadge percentChange={set.daily_percent_change} isTablet={isTablet} />
            </Fragment>
          )}
        </Grid.Column>

        {!isMobile && (
          <Grid.Column
            className={css(styles.Account_cellContentContainer)}
            floated={alignment}
            textAlign={alignment}
            width={3}
          >
            <Text color="darkBlue" tag="p" className={css(styles.Account_setTableText)}>
              {truncateTokenAmount(set.amount)}
            </Text>
          </Grid.Column>
        )}

        <SetTokenComponents isShowing={expandView} set={set} windowDimension={windowDimension} />
      </Grid.Row>
    );
  };

  public renderToken = (
    tokenAddress: string,
    tableRowIndex: number,
    isLastRow: boolean,
    isCoin: boolean,
    isCurrency: boolean = false,
  ) => {
    const {
      windowDimension: { isMobile },
      deployedSetDetailsHydrated,
      tokenSetsTokenListByAddress,
      geminiTokenListByAddress,
      erc20Balances,
      customV2SetPathPrefix,
      accountBalance,
      networkListTokenWithBalance,
    } = this.props;
    const checksumAddress = Web3.utils.toChecksumAddress(tokenAddress);
    const address = tokenAddress.toLowerCase();
    const setDetails = deployedSetDetailsHydrated[checksumAddress];
    let token = tokenSetsTokenListByAddress[address] || geminiTokenListByAddress[address];
    let balance = erc20Balances[address];

    // Special case for currency token
    if (isCurrency) {
      balance = tokenToBaseUnits(accountBalance);
      token = networkListTokenWithBalance;
    }

    let quantity = new BigNumber(balance || 0).div(10 ** Number(token?.decimals ?? 18)).toString();

    if (truncateTokenAmount(quantity) === 0) {
      return null;
    }

    const alignment = isMobile ? 'right' : 'left';
    const borderStyles = isLastRow ? undefined : styles.Account_setListRowBorder;
    const animationDelay = createAnimationDelay(tableRowIndex)[
      `Account_rowAnimationDelay${tableRowIndex}`
    ];

    const path = isCoin
      ? makeBlockExplorerContractLink(tokenAddress)
      : `${customV2SetPathPrefix}/${tokenAddress}`;

    return (
      <Grid.Row
        className={css(
          styles.Account_setListRow,
          borderStyles,
          styles.Account_animatedRow,
          animationDelay,
        )}
        key={tableRowIndex}
      >
        <Grid.Column
          as={isCoin ? 'a' : Link}
          className={
            isMobile
              ? css(styles.Account_cellContentContainer, styles.Account_cellContentContainerLeft)
              : css(styles.Account_cellContentContainer)
          }
          width={isMobile ? 9 : 7}
          to={!isCoin ? path : undefined}
          href={isCoin && path}
        >
          <div className={css(styles.Account_setIconContainer)}>
            <img
              className={css(styles.Account_setIcon)}
              onError={ev =>
                ((ev.target as any).src =
                  'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-token-icon.svg')
              }
              src={
                (setDetails ? setDetails.logoURI : token.logoURI) ||
                'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-token-icon.svg'
              }
              alt="token logo"
            />
          </div>
          <Text
            color="darkBlue"
            className={css(styles.Account_setTableText, styles.Account_setName)}
          >
            {tokenListNameOverride[tokenAddress.toLowerCase()] || setDetails?.name || token?.name}
          </Text>
        </Grid.Column>

        {!isMobile && (
          <Grid.Column
            className={css(styles.Account_priceChangeBadgeContainer)}
            width={3}
          ></Grid.Column>
        )}

        <Grid.Column
          className={
            isMobile
              ? css(styles.Account_cellContentContainerRight)
              : css(styles.Account_cellContentContainer)
          }
          floated={alignment}
          textAlign={alignment}
          width={isMobile ? 7 : 3}
        >
          {isMobile && (
            <Fragment>
              <Text className={css(styles.Account_setTableText)} color="darkGray" tag="p">
                {truncateTokenAmount(quantity)}
              </Text>
            </Fragment>
          )}
        </Grid.Column>

        {!isMobile && (
          <Grid.Column
            className={css(styles.Account_cellContentContainer)}
            floated={alignment}
            textAlign={alignment}
            width={3}
          >
            <Text color="darkBlue" tag="p" className={css(styles.Account_setTableText)}>
              {truncateTokenAmount(quantity)}
            </Text>
          </Grid.Column>
        )}
      </Grid.Row>
    );
  };

  public renderSets = () => {
    const {
      windowDimension: { isMobile },
      deployedSetDetailsHydrated,
      setAddresses,
      erc20Balances,
      t,
    } = this.props;

    const alignment = isMobile ? 'right' : 'left';

    // Valid if it has a balance and has details hydrated from set.js call
    const validTokenAddresses = setAddresses.filter(address => {
      return (
        erc20Balances[address.toLowerCase()]?.toNumber() > 0 &&
        deployedSetDetailsHydrated[toChecksumAddress(address)]
      );
    });

    if (!validTokenAddresses.length) {
      return this.renderUserOwnsNoSets();
    }

    return (
      <>
        <Grid.Row className={css(styles.Account_setListRowBorder)}>
          <Grid.Column
            className={isMobile ? css(styles.Account_cellContentContainerLeft) : ''}
            width={7}
          >
            <b>Sets</b>
          </Grid.Column>
          {!isMobile && (
            <Grid.Column floated={alignment} textAlign={alignment} width={6}></Grid.Column>
          )}
          <Grid.Column
            className={css(styles.Account_cellContentContainerRight)}
            floated={alignment}
            textAlign={alignment}
            width={isMobile ? 6 : 3}
          >
            <p>{t('general.balance')}</p>
          </Grid.Column>
        </Grid.Row>

        {validTokenAddresses.map((tokenAddress, index) => {
          const isLastSet = index === validTokenAddresses.length - 1;
          const tableRowIndex = index + 1;
          return this.renderToken(tokenAddress, tableRowIndex, isLastSet, false);
        })}
      </>
    );
  };

  public renderCoins = () => {
    const {
      windowDimension: { isMobile },
      geminiTokenListByAddress,
      erc20Balances,
      t,
    } = this.props;

    const alignment = isMobile ? 'right' : 'left';
    const tokenAddresses = Object.keys(geminiTokenListByAddress);
    const validTokenAddresses = tokenAddresses.filter(address => {
      return erc20Balances[address]?.toNumber() > 0;
    });

    return (
      <>
        <Grid.Row className={css(styles.Account_setListRowBorder)}>
          <Grid.Column
            className={isMobile ? css(styles.Account_cellContentContainerLeft) : ''}
            width={7}
          >
            <b>{t('general.coins')}</b>
          </Grid.Column>
          {!isMobile && (
            <Grid.Column floated={alignment} textAlign={alignment} width={6}></Grid.Column>
          )}
          <Grid.Column
            className={css(styles.Account_cellContentContainerRight)}
            floated={alignment}
            textAlign={alignment}
            width={isMobile ? 6 : 3}
          >
            <p>{t('general.balance')}</p>
          </Grid.Column>
        </Grid.Row>
        {/* Special case for currency token balance */}
        {this.renderToken('0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', 0, false, true, true)}
        {validTokenAddresses.map((tokenAddress, index) => {
          const isLastSet = index === validTokenAddresses.length - 1;
          const tableRowIndex = index + 1;

          return this.renderToken(tokenAddress, tableRowIndex, isLastSet, true);
        })}
      </>
    );
  };

  render() {
    const {
      isFetchingApiBalances,
      windowDimension,
      windowDimension: { isMobile },
    } = this.props;

    if (isFetchingApiBalances) {
      return (
        <div>
          <SetTokensPlaceholder windowDimension={windowDimension} />
        </div>
      );
    }

    return (
      <div>
        <Grid columns={isMobile ? 3 : 4} className={css(styles.Account_setBalancesGrid)}>
          {this.renderSets()}
          <Grid.Row />
          {this.renderCoins()}
        </Grid>
      </div>
    );
  }
}

export default withTranslation('account')(SetTokens);
