import { css, StyleSheet } from 'aphrodite';
import React, { PureComponent } from 'react';
import { Modal, Loader } from 'semantic-ui-react';
import queryString from 'query-string';
import { CATEGORY_TO_SET_TYPE_MAPPING, COLORS, WINDOW_DIMENSIONS } from '../../constants/index';
import { Button } from '../../components/index';
import { urlIntendedChain } from '../../utils/web3Utils';
import _ from 'underscore';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  ChainSwitchModal_container: {
    marginBottom: '100px',
    borderRadius: '5px',
    backgroundColor: COLORS.white,
    padding: '0px',
    transition: '0.2s',
    textAlign: 'left',
    [MOBILE_MEDIA_QUERY]: {
      padding: '12px',
      top: '20px',
    },
  },
  ChainSwitchModal_header: {
    color: COLORS.darkBlue,
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '28px',
    marginBottom: '10px',
    height: '28px',
  },
  ChainSwitchModal_emailFeatures: {
    display: 'flex',
    marginBottom: '12px',
    [MOBILE_MEDIA_QUERY]: {
      marginBottom: '10px',
    },
  },
  ChainSwitchModal_checkmark: {
    backgroundColor: COLORS.green,
    height: '22px',
    width: '22px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '22px',
    marginRight: '12px',
  },
  ChainSwitchModal_featureSection: {
    width: 'auto',
  },
  ChainSwitchModal_label: {
    color: COLORS.darkBlue,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  ChainSwitchModalDescription_labelText: {
    height: '42px',
    width: '328px',
    color: COLORS.darkGray,
    fontSize: '16px',
    lineHeight: '16px',
    marginBottom: '22px',
  },
  ChainSwitchModal_labelDescription: {
    height: '28px',
    width: '242px',
    color: '#1D144F',
    fontSize: '16px',
    lineHeight: '28px',
  },
  ChainSwitchModal_enterEmailSection: {
    marginTop: '20px',
  },
  ChainSwitchModal_neverShowAgainText: {
    color: COLORS.darkGray,
    margin: 'auto',
    fontSize: '16px',
    lineHeight: '18px',
    cursor: 'pointer',
    textAlign: 'center',
    marginTop: '34px',
  },
  ChainSwitchModal_disabledButtonText: {
    color: COLORS.darkGray,
    fontWeight: 500,
    fontSize: '16px',
  },
  ChainSwitchModal_buttonText: {
    color: COLORS.white,
    fontWeight: 500,
    fontSize: '16px',
  },
  ChainSwitchModal_skipButtonText: {
    color: COLORS.blue,
    fontWeight: 500,
    fontSize: '16px',
    width: 'fit-content',
    margin: 'auto',
    cursor: 'pointer',
  },
  ChainSwitchModal_questionMark: {
    backgroundColor: COLORS.gray,
    margin: 'auto',
    width: '80px',
    height: '80px',
    borderRadius: '80px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '31.15px',
    marginTop: '26.62px',
  },
  ChainSwitchModal_okHandIcon: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '48px',
  },
  ChainSwitchModal_confirmationHeader: {
    textAlign: 'center',
    color: COLORS.darkBlue,
    fontSize: '32px',
    fontWeight: 500,
    lineHeight: '28px',
    marginBottom: '15px',
  },
  ChainSwitchModal_description: {
    textAlign: 'center',
    marginBottom: '35px',
    fontSize: '16px',
    lineHeight: '28px',
    color: COLORS.darkBlue,
  },
  ChainSwitchModal_topMargin: {
    marginTop: '12px',
    textAlign: 'center',
  },
  ChainSwitchModal_errorText: {
    color: COLORS.red,
    marginTop: '-11.3px',
    fontSize: '16px',
    lineHeight: '18px',
  },
  ChainSwitchModal_disableButtonText: {
    textAlign: 'center',
    color: COLORS.darkGray,
    marginTop: '27.12px',
    cursor: 'pointer',
  },
  ChainSwitchModalDescription_label: {
    color: COLORS.darkGray,
    marginBottom: '32px',
  },
  ChainSwitchModal_actionButton: {
    height: '54px',
    fontSize: '16px',
    width: '75%',
    margin: 'auto',
  },
  ChainSwitchModal_finishButtonText: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '18px',
    color: COLORS.white,
  },
  ChainSwitchModal_backLink: {
    color: COLORS.blue,
    backgroundColor: COLORS.blueAlphaBackground,
    borderRadius: '16px',
    fontSize: '14px',
    fontWeight: 500,
    padding: '8px 16px 4px',
    width: 'fit-content',
  },
  ChainSwitchModal_newConfirmationHeader: {
    color: COLORS.black,
    fontSize: '40px',
    lineHeight: '40px',
    fontWeight: 600,
    marginTop: '16px',
    marginBottom: '16px',
  },
  ChainSwitchModal_newDescription: {
    fontSize: '18px',
    fontWeight: 400,
    colo: COLORS.black,
    marginBottom: '40px',
  },
  ChainSwitchModal_newContainer: {
    marginBottom: '100px',
    backgroundColor: COLORS.white,
    padding: '24px',
    transition: '0.2s',
    textAlign: 'left',
    maxWidth: '525px',
    borderRadius: '12px',
    [MOBILE_MEDIA_QUERY]: {
      padding: '12px',
      top: '20px',
    },
  },
  ChainSwitchModal_backLinkContainer: {
    marginBottom: '16px',
  },
});

interface IChainSwitchModalProps {
  account: string;
  isModalOpen: boolean;
  isTabletLandscape: boolean;
  isMobile: boolean;
  providerType: string;
  location: any;
  onSubmit: (...args: any[]) => any;
  onClose: (...args: any[]) => any;
}

interface IChainSwitchModalState {
  isSubmitting: boolean;
}

/**
 * @title ChainSwitchModal
 * @author Set Labs
 *
 * The ChainSwitchModal is the modal that prompts the user to enter their email address to get notifications
 */
class ChainSwitchModal extends PureComponent<IChainSwitchModalProps, IChainSwitchModalState> {
  constructor(props: IChainSwitchModalProps) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  componentDidUpdate(prevProps: IChainSwitchModalProps) {
    const { location, isModalOpen } = this.props;
    if (!_.isEqual(prevProps.location, location)) {
      if (
        isModalOpen &&
        !location.hash.includes('/v2/set/') &&
        !location.hash.includes('/portfolio/')
      ) {
        this.handleClose();
      }
    }
  }

  public handleSubmit = async () => {
    this.setState({ isSubmitting: true });

    await this.props.onSubmit();
    this.setState({ isSubmitting: false });
  };

  public handleClose = () => {
    const { onClose } = this.props;

    onClose();
  };

  public renderNewContent() {
    const { location } = this.props;

    const { isSubmitting } = this.state;

    const intendedChain = urlIntendedChain();
    const capitalizedIntendedChain = capitalize(intendedChain);

    const params = location && location.search ? queryString.parse(location.search) : {};

    return (
      <Modal.Content>
        <div>
          <div className={css(styles.ChainSwitchModal_backLinkContainer)}>
            <Link
              className={css(styles.ChainSwitchModal_backLink)}
              onClick={this.handleClose}
              to={params.origin ? `/explore?show=${params.origin}` : '/explore'}
            >
              {'<'}&nbsp;&nbsp;&nbsp;
              {`Back to ${
                params.origin &&
                typeof params.origin === 'string' &&
                Object.keys(CATEGORY_TO_SET_TYPE_MAPPING).includes(params.origin)
                  ? `${CATEGORY_TO_SET_TYPE_MAPPING[params.origin]} Sets`
                  : 'Explorer'
              }`}
            </Link>
          </div>
          <div className={css(styles.ChainSwitchModal_newConfirmationHeader)}>
            This Set is on the {capitalizedIntendedChain} network
          </div>
          <div className={css(styles.ChainSwitchModal_newDescription)}>
            Switch your network to interact with this page
          </div>
          <div className={css(styles.ChainSwitchModal_topMargin)}>
            <Button
              onClick={this.handleSubmit}
              isDisabled={isSubmitting}
              className={css(styles.ChainSwitchModal_actionButton)}
              style={{
                container: {
                  width: '100%',
                  display: 'block',
                  backgroundColor: COLORS.blue,
                },
              }}
            >
              <div style={{ position: 'relative' }}>
                {isSubmitting ? <Loader active /> : null}
                <div
                  className={
                    isSubmitting
                      ? css(styles.ChainSwitchModal_disabledButtonText)
                      : css(styles.ChainSwitchModal_buttonText)
                  }
                >
                  {isSubmitting ? 'Switching Networks...' : 'Switch Networks'}
                </div>
              </div>
            </Button>
          </div>
        </div>
      </Modal.Content>
    );
  }

  public render() {
    const { isModalOpen } = this.props;

    return (
      <Modal
        open={isModalOpen}
        onClose={this.handleClose}
        size="small"
        closeOnDimmerClick={false}
        closeOnEscape={false}
        closeOnDocumentClick={false}
        closeIcon={false}
        className={`${css(styles.ChainSwitchModal_newContainer)} scrolling`}
      >
        {this.renderNewContent()}
      </Modal>
    );
  }
}

export default ChainSwitchModal;
