import { css, StyleSheet } from 'aphrodite';
import React, { PureComponent } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Popup } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { COLORS, NETWORK_CONSTANTS, WINDOW_DIMENSIONS } from '../../constants/index';
import { InfoIcon } from '../../components/index';
import { CustomSetDetailsResponse, IMatch } from '../../typings/index';
import { BigNumber, truncateEthAddress } from '../../utils/index';
import {
  defaultNetworkForChain,
  makeBlockExplorerContractLinkForNetwork,
  urlIntendedChain,
} from '../../utils/web3Utils';
import { toFixed2Locale } from 'utils/formatUtils';
const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  CustomSetDescription_container: {
    width: '100%',
    marginTop: '25px',
  },
  CustomSetDescription_header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    borderBottom: `1px solid ${COLORS.gray}`,
    padding: '12px 0',
  },
  CustomSetDescription_title: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: 1.2,
    color: COLORS.darkBlue,
  },
  CustomSetDescription_descriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '40px',
    whiteSpace: 'pre-wrap',
  },
  CustomSetDescription_descriptionText: {
    color: COLORS.darkBlue,
    fontSize: '16px',
    lineHeight: 1.6,
    wordBreak: 'break-word',
  },
  CustomSetDescription_metaInformationContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    [MOBILE_MEDIA_QUERY]: {
      justifyContent: 'space-between',
    },
  },
  CustomSetDescription_metaInformation: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '30px',
    marginBottom: '20px',
    width: '20%',
    [MOBILE_MEDIA_QUERY]: {
      justifyContent: 'space-between',
      marginRight: '0px',
      width: '45%',
    },
  },
  CustomSetDescription_metaInformationFixed: {
    minWidth: '20%',
  },
  CustomSetDescription_metaInformationColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '20px',
    [MOBILE_MEDIA_QUERY]: {
      flexDirection: 'row',
      width: '100%',
      margin: '0px',
    },
  },
  CustomSetDescription_metaInformationRow: {
    display: 'flex',
  },
  CustomSetDescription_metaInformationLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  CustomSetDescription_metaInformationLabelText: {
    fontSize: '14px',
    color: COLORS.darkGray,
    marginTop: '5px',
  },
  CustomSetDescription_metaInformationToolTipIcon: {
    marginLeft: '7px',
  },
  CustomSetDescription_separator: {
    borderColor: COLORS.lightGray,
    margin: '25px auto',
    borderWidth: '0.5px',
  },
  CustomSetDescription_metaInformationContent: {
    fontSize: '18px',
    fontWeight: 500,
    color: COLORS.darkBlue,
  },
  CustomSetDescription_marketCap: {
    [MOBILE_MEDIA_QUERY]: { order: 1 },
  },
  CustomSetDescription_apy: {
    [MOBILE_MEDIA_QUERY]: { order: 2 },
  },
  CustomSetDescription_apyText: {
    color: COLORS.green,
  },
  CustomSetDescription_streamingFee: {
    [MOBILE_MEDIA_QUERY]: { order: 3 },
  },
  CustomSetDescription_unactualizedStreamingFee: {
    [MOBILE_MEDIA_QUERY]: { order: 4 },
  },
  CustomSetDescription_entryFee: {
    [MOBILE_MEDIA_QUERY]: { order: 5 },
  },
  CustomSetDescription_exitFee: {
    [MOBILE_MEDIA_QUERY]: { order: 6 },
  },
  CustomSetDescription_apyFee: {
    [MOBILE_MEDIA_QUERY]: { order: 7 },
  },
  CustomSetDescription_totalTrades: {
    [MOBILE_MEDIA_QUERY]: { order: 8 },
  },
  CustomSetDescription_tradesPerMonth: {
    [MOBILE_MEDIA_QUERY]: { order: 9 },
  },
  CustomSetDescription_creatorsBalance: {
    [MOBILE_MEDIA_QUERY]: { order: 10 },
  },
  CustomSetDescription_setHolder: {
    [MOBILE_MEDIA_QUERY]: { order: 11 },
  },
  CustomSetDescription_maxDrawdown: {
    [MOBILE_MEDIA_QUERY]: { order: 12 },
  },
  CustomSetDescription_setAddress: {
    [MOBILE_MEDIA_QUERY]: { order: 13 },
  },
  CustomSetDescription_etherscanAddress: {
    [MOBILE_MEDIA_QUERY]: { order: 14 },
  },
  CustomSetDescription_inceptionDate: {
    [MOBILE_MEDIA_QUERY]: { order: 15 },
  },
  CustomSetDescription_etherscanLink: {
    color: COLORS.blue,
  },
  CustomSetDescription_copyAddress: {
    fontSize: '18px',
    fontWeight: 500,
    color: COLORS.blue,
    cursor: 'pointer',
  },
  CustomSetDescription_popupHeader: {
    fontWeight: 500,
    color: COLORS.darkBlue,
  },
  CustomSetDescription_popup: {
    boxShadow: `0 6px 12px 0 ${COLORS.lightBlueAlpha25}`,
  },
  CustomSetDescription_currentAMMText: {
    display: 'flex',
    alignItems: 'center',
    color: COLORS.blue,
  },
  CustomSetDescription_uniswapIcon: {
    marginRight: '8px',
  },
});

interface ICustomSetDescriptionProps {
  currentChain: string;
  issuanceFee: string;
  redemptionFee: string;
  streamingFee: string;
  totalSupply: string;
  unaccruedFees: string;
  setAddress: string;
  managerAddress: string;
  setSymbol: string;
  setBalance: BigNumber;
  account: string;
  match: IMatch;
  customSetDetails: CustomSetDetailsResponse;
}

/**
 * @title CustomSetDescription
 * @author Set Labs
 *
 * Social Set Description displays information about the Social Set, it's assets under vault and number of users following the set.
 */
class CustomSetDescription extends PureComponent<ICustomSetDescriptionProps & WithTranslation> {
  public renderCopyAddress = () => {
    const { setAddress, t } = this.props;
    const trigger = (
      <CopyToClipboard text={setAddress}>
        <span className={css(styles.CustomSetDescription_copyAddress)}>
          {t('common.copy-address')}
        </span>
      </CopyToClipboard>
    );

    return (
      <Popup
        className={css(styles.CustomSetDescription_popup)}
        trigger={<div>{trigger}</div>}
        on="click"
      >
        <p className={css(styles.CustomSetDescription_popupHeader)}>
          {t('common.copy-address-tooltip-title')}
        </p>
        <p>{t('common.copy-address-tooltip-description')}</p>
      </Popup>
    );
  };

  public render() {
    const {
      issuanceFee,
      redemptionFee,
      streamingFee,
      unaccruedFees,
      totalSupply,
      setAddress,
      managerAddress,
      setSymbol,
      setBalance,
      account,
      customSetDetails,
      currentChain,
      t,
    } = this.props;

    const formattedUnactualizedStreamingFees = unaccruedFees ? `${unaccruedFees}` : '--';
    const intendedChain = urlIntendedChain();
    const defaultNetworkIdForChain = defaultNetworkForChain(intendedChain);

    return (
      <div className={css(styles.CustomSetDescription_container)}>
        <div className={css(styles.CustomSetDescription_header)}>
          <span className={css(styles.CustomSetDescription_title)}>
            {t('social-trader-set.about')}
          </span>
        </div>

        {customSetDetails?.setDetails && (
          <div className={css(styles.CustomSetDescription_descriptionContainer)}>
            <div className={css(styles.CustomSetDescription_descriptionText)}>
              {customSetDetails?.setDetails?.setDescription}
            </div>
          </div>
        )}

        <div
          className={css(
            styles.CustomSetDescription_metaInformationContainer,
            styles.CustomSetDescription_marketCap,
          )}
        >
          <div className={css(styles.CustomSetDescription_metaInformation)}>
            <span className={css(styles.CustomSetDescription_metaInformationContent)}>
              {totalSupply ? `${toFixed2Locale(new BigNumber(totalSupply))}` : '--'}
            </span>

            <div className={css(styles.CustomSetDescription_metaInformationLabel)}>
              <span className={css(styles.CustomSetDescription_metaInformationLabelText)}>
                Total Supply
              </span>
            </div>
          </div>

          <div
            className={css(
              styles.CustomSetDescription_metaInformation,
              styles.CustomSetDescription_streamingFee,
            )}
          >
            <span className={css(styles.CustomSetDescription_metaInformationContent)}>
              {`${streamingFee}%` || '--'}
            </span>

            <div className={css(styles.CustomSetDescription_metaInformationLabel)}>
              <span className={css(styles.CustomSetDescription_metaInformationLabelText)}>
                {t('social-trader-set.streaming-fee')}
              </span>
              <InfoIcon
                className={css(styles.CustomSetDescription_metaInformationToolTipIcon)}
                data={{
                  header: t('social-trader-set.streaming-fee'),
                  description: t('social-trader-set.streaming-fee-description'),
                }}
              />
            </div>
          </div>

          <div
            className={css(
              styles.CustomSetDescription_metaInformation,
              styles.CustomSetDescription_streamingFee,
            )}
          >
            <span className={css(styles.CustomSetDescription_metaInformationContent)}>
              {`${issuanceFee}%` || '--'}
            </span>

            <div className={css(styles.CustomSetDescription_metaInformationLabel)}>
              <span className={css(styles.CustomSetDescription_metaInformationLabelText)}>
                Issuance Fee
              </span>
              <InfoIcon
                className={css(styles.CustomSetDescription_metaInformationToolTipIcon)}
                data={{
                  header: 'Issuance Fee',
                  description:
                    'This is the fee the Set Owner & Methodologist accrue whenever a user issues this Set by supplying the underlying collateral to Set Protocol. For example, if a Set has a 1% Issuance Fee, and a user issues 100 USD worth of the Set, 1 USD worth of the Set will go to the Set Owner & Methodologist and 99 USD worth of the Set will go to the user.',
                }}
              />
            </div>
          </div>

          <div
            className={css(
              styles.CustomSetDescription_metaInformation,
              styles.CustomSetDescription_streamingFee,
            )}
          >
            <span className={css(styles.CustomSetDescription_metaInformationContent)}>
              {`${redemptionFee}%` || '--'}
            </span>

            <div className={css(styles.CustomSetDescription_metaInformationLabel)}>
              <span className={css(styles.CustomSetDescription_metaInformationLabelText)}>
                Redemption Fee
              </span>
              <InfoIcon
                className={css(styles.CustomSetDescription_metaInformationToolTipIcon)}
                data={{
                  header: 'Redemption Fee',
                  description:
                    'This is the fee the Set Owner & Methodologist accrue whenever a user redeems this Set by burning the set and retrieving the underlying collateral. For example, if a Set has a 1% Redemption Fee, and a user redeems 100 USD worth of the Set, 1 USD worth of the Set will go to the Set Owner & Methodologist and 99 USD worth of the underlying collateral will go to the user.',
                }}
              />
            </div>
          </div>

          <div
            className={css(
              styles.CustomSetDescription_metaInformation,
              styles.CustomSetDescription_unactualizedStreamingFee,
            )}
          >
            <span className={css(styles.CustomSetDescription_metaInformationContent)}>
              {formattedUnactualizedStreamingFees || '--'}
            </span>

            <div className={css(styles.CustomSetDescription_metaInformationLabel)}>
              <span className={css(styles.CustomSetDescription_metaInformationLabelText)}>
                {t('social-trader-set.unactualized-streaming-fee')}
              </span>
              <InfoIcon
                className={css(styles.CustomSetDescription_metaInformationToolTipIcon)}
                data={{
                  header: t('social-trader-set.unactualized-streaming-fee'),
                  description: t('social-trader-set.unactualized-streaming-fee-description'),
                }}
              />
            </div>
          </div>

          <div
            className={css(
              styles.CustomSetDescription_metaInformation,
              styles.CustomSetDescription_metaInformationFixed,
              styles.CustomSetDescription_setAddress,
            )}
          >
            <span className={css(styles.CustomSetDescription_metaInformationContent)}>
              {this.renderCopyAddress()}
            </span>

            <div className={css(styles.CustomSetDescription_metaInformationLabel)}>
              <span className={css(styles.CustomSetDescription_metaInformationLabelText)}>
                {t('common.set-address')}
              </span>
              <InfoIcon
                className={css(styles.CustomSetDescription_metaInformationToolTipIcon)}
                data={{
                  header: t('common.set-address'),
                  description: t('common.set-address-description'),
                }}
              />
            </div>
          </div>

          <div
            className={css(
              styles.CustomSetDescription_metaInformation,
              styles.CustomSetDescription_metaInformationFixed,
              styles.CustomSetDescription_etherscanAddress,
            )}
          >
            <a
              href={makeBlockExplorerContractLinkForNetwork(setAddress, defaultNetworkIdForChain)}
              className={css(
                styles.CustomSetDescription_metaInformationContent,
                styles.CustomSetDescription_etherscanLink,
              )}
              target="_blank"
            >
              {t('common.view-on-etherscan')}
            </a>

            <div className={css(styles.CustomSetDescription_metaInformationLabel)}>
              <span className={css(styles.CustomSetDescription_metaInformationLabelText)}>
                {t('common.etherscan-link')}
              </span>
            </div>
          </div>

          <div
            className={css(
              styles.CustomSetDescription_metaInformation,
              styles.CustomSetDescription_metaInformationFixed,
              styles.CustomSetDescription_etherscanAddress,
            )}
          >
            <a
              href={makeBlockExplorerContractLinkForNetwork(
                managerAddress,
                defaultNetworkIdForChain,
              )}
              className={css(
                styles.CustomSetDescription_metaInformationContent,
                styles.CustomSetDescription_etherscanLink,
              )}
              target="_blank"
            >
              {truncateEthAddress(managerAddress)}
            </a>

            <div className={css(styles.CustomSetDescription_metaInformationLabel)}>
              <span className={css(styles.CustomSetDescription_metaInformationLabelText)}>
                {t('common.manager-address')}
              </span>
              <InfoIcon
                className={css(styles.CustomSetDescription_metaInformationToolTipIcon)}
                data={{
                  header: t('common.manager-address'),
                  description: t('common.manager-address-description'),
                }}
              />
            </div>
          </div>

          {currentChain === NETWORK_CONSTANTS.ETHEREUM_CHAIN && (
            <div
              className={css(
                styles.CustomSetDescription_metaInformation,
                styles.CustomSetDescription_metaInformationFixed,
                styles.CustomSetDescription_etherscanAddress,
              )}
            >
              <a
                href={`https://app.zerion.io/invest/asset/${setSymbol?.toUpperCase()}-${setAddress?.toLowerCase()}`}
                className={css(
                  styles.CustomSetDescription_metaInformationContent,
                  styles.CustomSetDescription_etherscanLink,
                )}
                target="_blank"
              >
                {t('common.zerion-link')}
              </a>

              <div className={css(styles.CustomSetDescription_metaInformationLabel)}>
                <span className={css(styles.CustomSetDescription_metaInformationLabelText)}>
                  {t('common.performance')}
                </span>
              </div>
            </div>
          )}

          {account && account.length > 0 && (
            <div
              className={css(
                styles.CustomSetDescription_metaInformation,
                styles.CustomSetDescription_metaInformationFixed,
                styles.CustomSetDescription_etherscanAddress,
              )}
            >
              <span className={css(styles.CustomSetDescription_metaInformationContent)}>
                {setBalance?.toFixed(8)}
              </span>
              <div className={css(styles.CustomSetDescription_metaInformationLabel)}>
                <span className={css(styles.CustomSetDescription_metaInformationLabelText)}>
                  {t('common.current-balance')}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation('set-details-v2')(CustomSetDescription);
