import React, { ReactNode, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import ToolTip from '../../components/Tooltip/Tooltip';
import { COLORS } from '../../constants/index';

const styles = StyleSheet.create({
  Container: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    width: '20px',
    height: '20px',
    marginRight: '0.5em',
  },
  title: {
    fontWeight: 600,
    fontSize: '20px',
    marginRight: '0.5em',
    color: COLORS.black,
  },
  TitleFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  Column: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  InnerInputWithButton: {
    width: '60%',
    border: 0,
    height: '48px',
    paddingTop: '3px',
    lineHeight: '48px',
    paddingLeft: '1em',
    fontWeight: 600,
    fontSize: '16px',
    color: COLORS.disabledGray,
  },
  Input: {
    border: `1px solid ${COLORS.lightGray9}`,
    height: '50px',
    width: '36em',
    borderRadius: '5px',
    marginBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    marginTop: '2em',
    overflow: 'hidden',
  },
  wideWidth: {
    width: '700px',
  },
  width75: {
    width: '75%',
  },
  width25: {
    width: '25%',
  },
  hover: {
    ':hover': {
      border: `1px solid ${COLORS.lightBlue6}`,
    },
  },
  focus: {
    boxShadow: `0px 0px 0px 2px ${COLORS.blueShadow}`,
    border: `1px solid ${COLORS.lightBlue6}`,
  },
  focusError: {
    boxShadow: `0px 0px 0px 2px ${COLORS.redShadow}`,
    border: `1px solid ${COLORS.red2}`,
  },
  error: {
    backgroundColor: COLORS.pink2,
    border: `1px solid ${COLORS.red2}`,
  },
  button: {
    width: '14em',
    height: '49px',
    borderRadius: 0,
    borderLeft: `1px solid ${COLORS.lightGray9}`,
  },
  disabled: {
    backgroundColor: COLORS.disabledGray,
  },
  buttonText: {
    fontWeight: 600,
    color: COLORS.white,
    lineHeight: '100%',
  },
  ToolTipWrapper: {
    marginTop: '5px',
  },
  PercentWrapper: {
    width: '10%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.lightGray,
    borderLeft: `1px solid ${COLORS.lightGray9}`,
  },
  PercentChar: {
    fontSize: '22px',
    fontWeight: 600,
    color: COLORS.darkGray,
  },
  errorText: {
    width: '24em',
    lineHeight: 1.1,
    color: COLORS.red2,
    fontWeight: 500,
    fontSize: '16px',
    position: 'absolute',
    bottom: '-25px',
  },
});

interface TitleFormProps {
  title?: any;
  subTitle?: any;
  errorMessage?: any;
  isButtonDisabled?: any;
  buttonText?: string;
  toolTipText?: any;
  value?: string | number;
  placeholder?: string;
  inputType?: string;
  showPercent?: boolean;
  wideWidth?: boolean;
  body?: ReactNode;
  onClick?: (...args: any[]) => any;
  onChange?: (...args: any[]) => any;
}

export default function TitleForm({
  wideWidth,
  title,
  subTitle,
  body,
  buttonText,
  toolTipText,
  value,
  errorMessage,
  isButtonDisabled,
  placeholder,
  inputType = 'number',
  showPercent = true,
  onClick,
  onChange,
}: TitleFormProps) {
  const [inFocus, setInFocus] = useState(false);
  const handleOnFocus = () => setInFocus(true);
  const handleOnBlur = () => setInFocus(false);

  return (
    <div className={css(styles.Container)}>
      <div className={css(styles.Column, wideWidth && styles.wideWidth)}>
        <div className={css(styles.TitleFlex)}>
          <p className={css(styles.title)}>{title}</p>
          <div className={css(styles.ToolTipWrapper)}>
            <ToolTip width={'24em'}>{toolTipText}</ToolTip>
          </div>
        </div>
        <p>{subTitle}</p>
        {body}
        <div
          className={css(
            styles.Input,
            styles.hover,
            inFocus && (errorMessage ? styles.focusError : styles.focus),
            errorMessage && styles.error,
            wideWidth && styles.wideWidth,
          )}
        >
          <input
            className={css(styles.InnerInputWithButton, wideWidth && styles.width75)}
            type={inputType}
            onChange={onChange}
            step={0.01}
            value={value}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            placeholder={placeholder}
          />
          {showPercent && (
            <div className={css(styles.PercentWrapper)}>
              <span className={css(styles.PercentChar)}>%</span>
            </div>
          )}
          <button
            disabled={isButtonDisabled}
            className={css(
              isButtonDisabled && styles.disabled,
              styles.button,
              wideWidth && styles.width25,
            )}
            onClick={onClick}
          >
            <p className={css(styles.buttonText)}>{buttonText}</p>
          </button>
        </div>
        <div className={css(styles.errorText)}>
          {errorMessage && value && <label>{errorMessage}</label>}
        </div>
      </div>
    </div>
  );
}
