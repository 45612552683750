import React, { PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import TransactionWatcher, {
  TransactionCompleted,
  TransactionFailed,
  TransactionLong,
} from '../../containers/TransactionWatcher';
import PerpIssuanceForm, { PerpIssuancePending } from '../../containers/PerpIssuanceForm';
import { IMatch, IHistory } from '../../typings/index';

type PerpIssuanceFormWrapperProps = {
  customV2SetPathPrefix: string;
  name: string;
  currentTransactionId: string;
  history: IHistory;
  match: IMatch;
  resetTransactionWatcher: (...args: any[]) => any;
};

/**
 * @title PerpIssuanceFormWrapper
 * @author Set Protocol
 *
 * Wraps PerpIssuanceForm with TransactionWatcher functionality + supplementary pending, success, fail screens.
 */
class PerpIssuance extends PureComponent<PerpIssuanceFormWrapperProps & WithTranslation> {
  public navigateToSetPage = () => {
    const { customV2SetPathPrefix, history, match, resetTransactionWatcher } = this.props;
    history.push(`${customV2SetPathPrefix}/${match.params.set}`);
    resetTransactionWatcher();
  };

  render() {
    const { name, match, history, currentTransactionId, resetTransactionWatcher, t } = this.props;

    const beginIssuanceComponent = <PerpIssuanceForm history={history} match={match} />;
    const issuanceApproving = beginIssuanceComponent;
    const issuancePending = (
      <PerpIssuancePending transactionId={currentTransactionId} name={name} />
    );
    const completedIssuance = (
      <TransactionCompleted
        title={t('issue-modals.completed-title')}
        text={t('issue-modals.completed-description', { tokenName: name })}
        transactionHash={currentTransactionId}
        onFinish={this.navigateToSetPage}
      />
    );
    const longIssuance = (
      <TransactionLong
        title={t('issue-modals.long-title', { tokenName: name })}
        text={t('issue-modals.long-description')}
        transactionHash={currentTransactionId}
        onRetry={resetTransactionWatcher}
        onClose={this.navigateToSetPage}
      />
    );
    const failedIssuance = (
      <TransactionFailed
        title={t('issue-modals.failed-title')}
        text={t('issue-modals.failed-description', { tokenName: name })}
        transactionHash={currentTransactionId}
        onRetry={resetTransactionWatcher}
      />
    );

    return (
      <TransactionWatcher
        startTransactionComponent={beginIssuanceComponent}
        approvingTransactionComponent={issuanceApproving}
        pendingTransactionComponent={issuancePending}
        completedTransactionComponent={completedIssuance}
        longTransactionComponent={longIssuance}
        failedTransactionComponent={failedIssuance}
      />
    );
  }
}
export default withTranslation('issue-and-redeem')(PerpIssuance);
