import { StyleSheet } from 'aphrodite';

import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  SignUpOptions_body: {
    paddingBottom: '150px',
    textAlign: 'left',
  },
  SignUpOptions_buttonContainer: {
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [MOBILE_MEDIA_QUERY]: {
      width: '100%',
    },
  },
  SignUpOptions_buttonContainer2: {
    width: '300px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [MOBILE_MEDIA_QUERY]: {
      width: '100%',
    },
  },
  SignUpOptions_buttonContainerAbsolute: {
    position: 'absolute',
    bottom: '0',
    right: '0',
  },
  SignUpOptions_buttonContainerTerms: {
    backgroundColor: COLORS.white,
    marginTop: '24px',
    marginLeft: '0',
    marginRight: '0',
    paddingBottom: '20px',
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    zIndex: 100,
  },
  SignUpOptions_buttonGrid: {
    backgroundColor: COLORS.white,
    maxWidth: '500px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  SignUpOptions_buttonMarginTop: {
    marginTop: '12px',
  },
  SignUpOptions_centerAlignVertical: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  SignUpOptions_checkboxContainer: {
    marginBottom: '24px',
  },
  SignUpOptions_nameColumnLeft: {
    paddingRight: '7px',
  },
  SignUpOptions_nameColumnRight: {
    paddingLeft: '7px',
  },
  SignUpOptions_container: {
    marginRight: '0',
    height: '99vh',
  },
  SignUpOptions_container2: {
    marginTop: '50px',
    [MOBILE_MEDIA_QUERY]: {
      marginTop: '18px',
    },
  },
  SignUpOptions_confetti: {
    position: 'absolute',
    right: '50%',
    zIndex: 10000,
  },
  SignUpOptions_description: {
    display: 'block',
    lineHeight: '26px',
    fontSize: '16px',
    marginBottom: '20px',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '300px',
    [MOBILE_MEDIA_QUERY]: {
      maxWidth: 'auto',
    },
  },
  SignUpOptions_descriptionContainer: {
    marginBottom: '40px',
  },
  SignUpOptions_desktopContainer: {
    width: '450px',
    borderRadius: '10px',
    boxShadow: `0 6px 24px 0 ${COLORS.lightBlueAlpha15}`,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    position: 'absolute',
    top: '120px',
    [MOBILE_MEDIA_QUERY]: {
      width: '100%',
      borderRadius: '0',
      border: 'none',
      boxShadow: 'none',
      marginLeft: '0',
      marginRight: '0',
    },
  },
  SignUpOptions_desktopOuterContainer: {
    height: '85vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [MOBILE_MEDIA_QUERY]: {
      height: 'auto',
      display: 'block',
    },
  },
  SignUpOptions_disagreeButton: {
    backgroundColor: COLORS.white,
    border: `2px solid ${COLORS.blue}`,
    color: COLORS.blue,
  },
  SignUpOptions_errorText: {
    color: COLORS.red,
    display: 'inline-block',
    marginBottom: '16px',
    marginTop: '15px',
  },
  SignUpOptions_errorTextMarginTop: {
    marginBottom: '0',
  },
  SignUpOptions_header: {
    lineHeight: '2rem',
    fontSize: '24px',
    fontWeight: 500,
    marginBottom: '20px',
    [MOBILE_MEDIA_QUERY]: {
      lineHeight: '2.3rem',
      fontSize: '24px',
      fontWeight: 500,
      marginBottom: '20px',
    },
  },
  SignUpOptions_header2: {
    lineHeight: '2rem',
    fontSize: '42px',
    fontWeight: 500,
    marginBottom: '20px',
    textAlign: 'center',
    [MOBILE_MEDIA_QUERY]: {
      lineHeight: '2.3rem',
      fontSize: '32px',
      fontWeight: 500,
      marginBottom: '10px',
    },
  },
  SignUpOptions_header3: {
    marginBottom: '10px',
  },
  SignUpOptions_imageContainer: {
    marginBottom: '40px',
    textAlign: 'center',
  },
  SignUpOptions_innerContainer: {
    marginBottom: '40px',
    marginTop: '40px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '86%',
    [MOBILE_MEDIA_QUERY]: {
      marginTop: '0',
      minHeight: '80vh',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  },
  SignUpOptions_input: {
    borderRadius: '4px',
    border: `1px solid ${COLORS.gray}`,
    fontSize: '16px',
    marginBottom: '14px',
    padding: '20px',
    transition: '0.1s',
    width: '100%',
    ':focus': {
      border: `1px solid ${COLORS.blue}`,
    },
  },
  SignUpOptions_inputError: {
    border: `1px solid ${COLORS.red}`,
    marginBottom: '0',
  },
  SignUpOptions_inputMarginBottom: {
    marginBottom: '24px',
  },
  SignUpOptions_inputNoMarginBottom: {
    marginBottom: '0',
  },
  SignUpOptions_passwordContainer: {
    position: 'relative',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  SignUpOptions_rebalancingSetTableContainer: {
    marginBottom: '30px',
    marginTop: '30px',
  },
  SignUpOptions_termsLeftButtonColumn: {
    paddingRight: '0.5rem',
  },
  SignUpOptions_termsRightButtonColumn: {
    paddingLeft: '0.5rem',
  },
  SignUpOptions_textContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    [MOBILE_MEDIA_QUERY]: {
      textAlign: 'left',
    },
  },
});

export default styles;
