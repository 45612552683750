import { GraphSetToken, IAction } from '../../typings/index';
import {
  CUSTOM_SETS_INCREMENT_PAGE_NUMBER,
  CUSTOM_SETS_DECREMENT_PAGE_NUMBER,
  CUSTOM_SETS_SET_PAGE_NUMBER,
  CUSTOM_SETS_SET_SORT_COLUMN,
  RECEIVE_NEXT_CUSTOM_SETS_PAGE,
  REQUEST_NEXT_CUSTOM_SETS_PAGE,
} from '../../actions/tableActions';
import { SortType } from './enums';

export interface ITableState {
  currentPageNumber: number;
  setTokenPages: GraphSetToken[][];
  totalCount: number;
  sortColumn: SortType;
  isAscending: boolean;
  isFetchingNextPage: boolean;
}

const initialState: ITableState = {
  currentPageNumber: 1,
  setTokenPages: [],
  totalCount: 0,
  sortColumn: SortType.marketCap,
  isAscending: false,
  isFetchingNextPage: false,
};

const paginationReducer = (state = initialState, action: IAction): ITableState => {
  switch (action.type) {
    case CUSTOM_SETS_INCREMENT_PAGE_NUMBER:
      return {
        ...state,
        currentPageNumber: state.currentPageNumber + 1,
      };
    case CUSTOM_SETS_DECREMENT_PAGE_NUMBER:
      return {
        ...state,
        currentPageNumber: state.currentPageNumber - 1,
      };
    case CUSTOM_SETS_SET_PAGE_NUMBER:
      return {
        ...state,
        currentPageNumber: action.payload,
      };
    case REQUEST_NEXT_CUSTOM_SETS_PAGE:
      return {
        ...state,
        isFetchingNextPage: true,
      };
    case RECEIVE_NEXT_CUSTOM_SETS_PAGE:
      const newSetTokenPages = [...state.setTokenPages];
      newSetTokenPages[action.payload.page] = action.payload.setTokens;
      return {
        ...state,
        setTokenPages: newSetTokenPages,
        currentPageNumber: action.payload.page,
        totalCount: action.payload.totalCount,
        isFetchingNextPage: action.payload.loading !== undefined ? action.payload.loading : false,
      };
    case CUSTOM_SETS_SET_SORT_COLUMN:
      if (state.sortColumn === action.payload) {
        return {
          ...state,
          isAscending: !state.isAscending,
        };
      }
      return {
        ...state,
        sortColumn: action.payload,
        isAscending: false,
      };
    default:
      return state;
  }
};

export default paginationReducer;
