import React, { PureComponent } from 'react';
import { IMatch } from '../../typings/index';

interface IFundDetailsProps {
  match: IMatch;
  history: any;
}

/**
 * @title FundDetails
 * @author Set Labs
 *
 * Displays major information about a fund's components, metadata, and rebalance history.
 *
 */
class FundDetailsComponent extends PureComponent<IFundDetailsProps> {
  async componentDidMount() {
    this.loadFund();
  }

  async loadFund() {
    const { match, history } = this.props;

    const pathParam = match.params.set;

    const fundAddresses: { [key: string]: string } = {
      bye: '/v2/set/optimism/0x927Eb0dBC5c3FD172Fdfa72D563f71612eCB6122',
      mnye: '/v2/set/optimism/0x0Be27c140f9Bdad3474bEaFf0A413EC7e19e9B93',
      jpg: '/v2/set/0x02e7Ac540409D32C90BfB51114003a9E1fF0249c',
      iceth: '/v2/set/0x7C07F7aBe10CE8e33DC6C5aD68FE033085256A84',
      ethmaxy: '/v2/set/0x0fe20e0fa9c78278702b05c333cc000034bb69e2',
      'ibtc-fli-p': '/v2/set/polygon/0x130cE4E4F76c2265f94a961D70618562de0bb8d2',
      'btc-fli-p': '/v2/set/polygon/0xd6cA869a4EC9eD2C7E618062Cdc45306d8dBBc14',
      'imatic-fli-p': '/v2/set/polygon/0x340f412860dA7b7823df372a2b59Ff78b7ae6abc',
      'matic-fli-p': '/v2/set/polygon/0xf287D97B6345bad3D88856b26Fb7c0ab3F2C7976',
      'ieth-fli-p': '/v2/set/polygon/0x4f025829C4B13dF652f38Abd2AB901185fF1e609',
      gmi: '/v2/set/0x47110d43175f7f2C2425E7d15792acC5817EB44f',
      'eth-fli-p': '/v2/set/polygon/0x3Ad707dA309f3845cd602059901E39C4dcd66473',
      data: '/v2/set/0x33d63Ba1E57E54779F7dDAeaA7109349344cf5F1',
      scifi: '/v2/set/0xfDC4a3FC36df16a78edCAf1B837d3ACAaeDB2CB4',
      bed: '/v2/set/0x2aF1dF3AB0ab157e1E2Ad8F88A7D04fbea0c7dc6',
      sushihouse: '/v2/set/0x7b18913D945242A9c313573E6c99064cd940c6aF',
      btcfli: '/v2/set/0x0B498ff89709d3838a063f1dFA463091F9801c2b',
      mvi: '/v2/set/0x72e364F2ABdC788b7E918bc238B21f109Cd634D7',
      ethfli: '/v2/set/0xAa6E8127831c9DE45ae56bB1b0d4D4Da6e5665BD',
      faang: '/v2/set/0x7277a44D1325D81Ac58893002a1B40a41bea43fe',
      yamhouse: '/v2/set/0xD83dfE003E7c42077186D690DD3D24a0c965ca4e',
      cgi: '/v2/set/0xAdA0A1202462085999652Dc5310a7A9e2BF3eD42',
      dpi: '/v2/set/0x1494CA1F11D487c2bBe4543E90080AeBa4BA3C2b',
    };

    if (!fundAddresses[pathParam]) {
      history.push('/404');
      return;
    } else {
      history.push(fundAddresses[pathParam]);
    }
  }

  render() {
    return <div>Redirecting...</div>;
  }
}

export default FundDetailsComponent;
