import {
  SET_IS_SUBMITTING_ISSUANCE_TRANSACTION_V2,
  UPDATE_ISSUANCE_INPUT_QUANTITY_V2,
  FETCH_DEBT_ISSUANCE_COMPONENTS,
  RECEIVE_DEBT_ISSUANCE_COMPONENTS,
  FETCH_PERP_ISSUANCE_COMPONENTS,
  RECEIVE_PERP_ISSUANCE_COMPONENTS,
  SET_MAX_ALLOWED_SLIPPAGE_PERCENTAGE,
  REQUEST_PERP_ISSUANCE_FEES,
  RECEIVE_PERP_ISSUANCE_FEES,
  SET_MAX_TOKEN_PERP_ISSUE_AMOUNT_BY_LEVERAGE,
  REQUEST_DEBT_ISSUANCE_FEES,
  RECEIVE_DEBT_ISSUANCE_FEES,
} from '../actions/issuanceV2Actions';

import { DEFAULT_PARAMETERS } from '../constants/index';
import { IDebtComponents, IFeeResponse } from '../typings/index';

export interface IssuanceV2ReducerState {
  isSubmittingIssuanceTransaction: boolean;
  isFetchingDebtIssuanceComponents: boolean;
  isFetchingPerpIssuanceComponents: boolean;
  isFetchingPerpIssuanceFees: boolean;
  isFetchingDebtIssuanceFees: boolean;
  requiredDebtIssuanceComponents: IDebtComponents;
  requiredPerpIssuanceComponents: IDebtComponents;
  issuanceInputQuantity: string;
  maxSlippagePercentageAllowed: string;
  perpIssuanceFees: IFeeResponse;
  debtIssuanceFees: IFeeResponse;
  maxTokenPerpIssueAmountByLeverage: string;
}

const initialState: IssuanceV2ReducerState = {
  isSubmittingIssuanceTransaction: false,
  isFetchingDebtIssuanceComponents: false,
  isFetchingPerpIssuanceComponents: false,
  isFetchingPerpIssuanceFees: false,
  isFetchingDebtIssuanceFees: false,
  requiredDebtIssuanceComponents: {} as IDebtComponents,
  requiredPerpIssuanceComponents: {} as IDebtComponents,
  issuanceInputQuantity: '1',
  maxSlippagePercentageAllowed: DEFAULT_PARAMETERS.DEFAULT_SLIPPAGE_MODULE_SLIPPAGE_PERCENTAGE,
  perpIssuanceFees: {} as IFeeResponse,
  debtIssuanceFees: {} as IFeeResponse,
  maxTokenPerpIssueAmountByLeverage: '0',
};

const issuanceV2Reducer = (state = initialState, action: any): IssuanceV2ReducerState => {
  switch (action.type) {
    case SET_IS_SUBMITTING_ISSUANCE_TRANSACTION_V2:
      return {
        ...state,
        isSubmittingIssuanceTransaction: action.payload,
      };

    case UPDATE_ISSUANCE_INPUT_QUANTITY_V2:
      return {
        ...state,
        issuanceInputQuantity: action.payload,
      };
    case FETCH_DEBT_ISSUANCE_COMPONENTS:
      return {
        ...state,
        isFetchingDebtIssuanceComponents: true,
      };
    case RECEIVE_DEBT_ISSUANCE_COMPONENTS:
      return {
        ...state,
        isFetchingDebtIssuanceComponents: false,
        requiredDebtIssuanceComponents: action.payload,
      };
    case FETCH_PERP_ISSUANCE_COMPONENTS:
      return {
        ...state,
        isFetchingPerpIssuanceComponents: true,
      };
    case RECEIVE_PERP_ISSUANCE_COMPONENTS:
      return {
        ...state,
        isFetchingPerpIssuanceComponents: false,
        requiredPerpIssuanceComponents: action.payload,
      };
    case SET_MAX_ALLOWED_SLIPPAGE_PERCENTAGE:
      return {
        ...state,
        maxSlippagePercentageAllowed: action.payload,
      };

    case REQUEST_PERP_ISSUANCE_FEES:
      return {
        ...state,
        isFetchingPerpIssuanceFees: true,
      };

    case RECEIVE_PERP_ISSUANCE_FEES:
      return {
        ...state,
        isFetchingPerpIssuanceFees: false,
        perpIssuanceFees: {
          totalQuantity: action.payload.totalQuantity,
          managerFee: action.payload.managerFee,
          managerFeePercentage: action.payload.managerFeePercentage,
          protocolFee: action.payload.protocolFee,
          protocolFeePercentage: action.payload.protocolFeePercentage,
        },
      };
    case REQUEST_DEBT_ISSUANCE_FEES:
      return {
        ...state,
        isFetchingDebtIssuanceFees: true,
      };

    case RECEIVE_DEBT_ISSUANCE_FEES:
      return {
        ...state,
        isFetchingDebtIssuanceFees: false,
        debtIssuanceFees: {
          totalQuantity: action.payload.totalQuantity,
          managerFee: action.payload.managerFee,
          managerFeePercentage: action.payload.managerFeePercentage,
          protocolFee: action.payload.protocolFee,
          protocolFeePercentage: action.payload.protocolFeePercentage,
        },
      };

    case SET_MAX_TOKEN_PERP_ISSUE_AMOUNT_BY_LEVERAGE:
      return {
        ...state,
        maxTokenPerpIssueAmountByLeverage: action.payload,
      };

    default:
      return state;
  }
};

export default issuanceV2Reducer;
