import { StyleSheet, css } from 'aphrodite';
import React from 'react';
import { Header, List } from 'semantic-ui-react';

import { ContentBox, Text } from '../../components/index';

const styles = StyleSheet.create({
  heroHeader: {
    maxWidth: '950px',
    lineHeight: '42px',
  },
  marginBottomSmall: {
    marginBottom: '2rem',
  },
  header: {
    paddingTop: '16px',
    paddingBottom: '10px',
  },
  indented: {
    paddingLeft: '20px',
  },
  italics: {
    fontStyle: 'italic',
  },
  contentPadding: {
    paddingTop: '10px',
    paddingBop: '10px',
  },
  bullet: {
    fontSize: '18px',
  },
});

const CaliforniaPrivacy = () => (
  <ContentBox isFullWidth isInnerContainerWide isBordered={false} textAlign="left">
    <Header as="h1" textAlign="center" className={css(styles.heroHeader, styles.marginBottomSmall)}>
      Privacy Notice For California Residents
    </Header>
    <Text tag="p" className={css(styles.contentPadding)}>
      {'This document was last updated on January 21, 2020.'}
    </Text>
    <Text tag="p" className={css(styles.contentPadding)}>
      This Privacy Notice (“Notice”) for California Residents supplements the information contained
      in Set’s Privacy Policy located
      <a href="/privacy" target="_blank">
        {' '}
        here{' '}
      </a>
      (“Privacy Policy”) and applies solely to all visitors, users, and others who reside in the
      State of California ("consumers" or "you"). This Notice has been adopted in accordance with
      the requirements of the California Consumer Privacy Act of 2018 (“CCPA”), and any terms
      defined in the CCPA have the same meaning when used in this notice, unless otherwise defined
      herein. Any other terms that are not already defined in the CCPA and are capitalized but not
      defined herein shall have the meaning provided in our Privacy Policy.
    </Text>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      If there are any conflicts between this Notice and any other provision of the Privacy Policy,
      the policy or portion that is more protective of you shall control to the extent of such
      conflict.
    </Text>
    <Header as="h4" className={css(styles.header)}>
      Information Set Collects
    </Header>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      Our Site collects information that identifies, relates to, describes, references, is capable
      of being associated with, or could reasonably be linked, directly or indirectly, with a
      particular consumer or device ("Personal Information"). In particular, our Site has collected
      the following categories of Personal Information from its consumers within the last twelve
      (12) months:
    </Text>
    <List bulleted className={css(styles.marginBottomSmall)}>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Identifiers such as a real name, IP address, e-mail address, or account name
          (“Identifiers”);
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Personal information categories listed in the California Customer Records statute (Cal.
          Civ. Code § 1798.80(e)) such as names, financial information, and employment history
          (“California Customer Records Information”);
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Protected classification characteristics under California or federal law such as
          citizenship, national origin or gender (“Protected Class Information”);
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Commercial information such as records of products or services purchased, obtained or
          considered (“Commercial Information”);
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Internet or other similar network activity such as browsing history and your interaction
          with the Site (“Network Activity Information”);
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Geolocation data such as physical location or movements (“Geolocation Information”);{' '}
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Professional or employment-related information such as current or past job history
          (“Professional Information”); and
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>Inferences drawn from other Personal Information (“Inference Information”).</Text>
      </List.Item>
    </List>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      Personal Information does not include:
    </Text>
    <List bulleted className={css(styles.marginBottomSmall)}>
      <List.Item className={css(styles.bullet)}>
        <Text>Publicly available information from government records;</Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>Deidentified or aggregated consumer information; and</Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>Information excluded from the CCPA's scope.</Text>
      </List.Item>
    </List>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      Set obtains the categories of Personal Information described herein from the sources disclosed
      in our Privacy Policy described under the sections entitled “Collection of your Personal
      Information”, “Non-Personal Identification Information”, and “Information Collected from Other
      Sources and Do Not Track Policy.”
    </Text>
    <Header as="h4" className={css(styles.header)}>
      Use of Personal Information
    </Header>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      We may use or disclose the Personal Information we collect for one or more of the business
      purposes listed in our Privacy Policy under the section entitled, “Use of Information”. In
      addition, we generally will use your Personal Information in a manner as described to you when
      collecting your Personal Information or as otherwise set forth in the CCPA.
    </Text>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      We will not collect additional categories of Personal Information or use the Personal
      Information we collected for materially different, unrelated, or incompatible purposes without
      providing you notice.
    </Text>
    <Header as="h4" className={css(styles.header)}>
      Sharing Personal Information
    </Header>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      We may share or disclose your Personal Information to third parties as described in our
      Privacy Policy under the sections entitled “Use of Information” and “Sharing of Your Personal
      Information”. When we disclose Personal Information for a business purpose, we enter a
      contract that describes the purpose and requires the recipient to both keep that Personal
      Information confidential and not use it for any purpose except performing the contract.
    </Text>
    <Text tag="p" className={css(styles.contentPadding, styles.italics)}>
      Disclosures of Personal Information for a Business Purpose
    </Text>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      In the preceding twelve (12) months, Company has disclosed the following categories of
      Personal Information for a business purpose:
    </Text>
    <List bulleted className={css(styles.marginBottomSmall)}>
      <List.Item className={css(styles.bullet)}>
        <Text>Identifiers;</Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>California Customer Records Information;</Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>Commercial Information;</Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>Network Activity Information;</Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>Geolocation Information;</Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>Professional Information; and </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>Inference Information.</Text>
      </List.Item>
    </List>
    <Text tag="p" className={css(styles.contentPadding, styles.italics)}>
      Sales of Personal Information
    </Text>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      For the purposes of the CCPA, “selling” information broadly means scenarios where we have
      shared personal information with partners in exchange for valuable consideration or for a
      third-party’s commercial purposes. In the preceding twelve (12) months, we have not sold any
      Personal Information subject to the CCPA.
    </Text>
    <Header as="h4" className={css(styles.header)}>
      Your Rights and Choices
    </Header>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      The CCPA provides consumers with specific rights regarding their Personal Information. This
      section describes your CCPA rights and explains how to exercise those rights.
    </Text>
    <Text tag="p" className={css(styles.contentPadding, styles.italics)}>
      Access and Data Portability Rights
    </Text>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      You have the right to request that Set disclose certain information to you about our
      collection and use of your Personal Information over the past 12 months. Once we receive and
      confirm your verifiable consumer request, we will disclose to you:
    </Text>
    <List bulleted className={css(styles.marginBottomSmall)}>
      <List.Item className={css(styles.bullet)}>
        <Text>The categories of Personal Information we collected about you;</Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>The categories of sources for the Personal Information we collected about you;</Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Our business or commercial purpose for collecting or selling that Personal Information;
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>The categories of third parties with whom we share that Personal Information;</Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>The specific pieces of Personal Information we collected about you; and</Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          If we sold or disclosed your Personal Information for a business purpose, two separate
          lists disclosing: sales, identifying the Personal Information categories that each
          category of recipient purchased; and disclosures for a business purpose, identifying the
          Personal Information categories that each category of recipient obtained.
        </Text>
      </List.Item>
    </List>
    <Text tag="p" className={css(styles.contentPadding, styles.italics)}>
      Erasure Rights
    </Text>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      You have the right to request that Set delete any of your Personal Information that we
      collected from you and retained, subject to certain exceptions. Once we receive and confirm
      your verifiable consumer request, we will delete (and direct our service providers to delete)
      your Personal Information from our records, unless an exception applies.
    </Text>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      We may deny your deletion request if retaining the information is necessary for us or our
      service provider(s) to:
    </Text>
    <List bulleted className={css(styles.marginBottomSmall)}>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Complete the transaction for which we collected the Personal Information, provide a good
          or service that you requested, take actions reasonably anticipated within the context of
          our ongoing business relationship with you, or otherwise perform our contract with you;
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal
          activity, or prosecute those responsible for such activities;
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Debug products to identify and repair errors that impair existing intended functionality;
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Exercise free speech, ensure the right of another consumer to exercise their free speech
          rights, or exercise another right provided for by law;
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>Comply with the California Electronic Communications Privacy Act;</Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Engage in public or peer-reviewed scientific, historical, or statistical research in the
          public interest that adheres to all other applicable ethics and privacy laws, when the
          information's deletion may likely render impossible or seriously impair the research's
          achievement, if you previously provided informed consent;{' '}
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Enable solely internal uses that are reasonably aligned with consumer expectations based
          on your relationship with us;
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>Comply with a legal obligation; or</Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Make other internal and lawful uses of that information that are compatible with the
          context in which you provided it.
        </Text>
      </List.Item>
    </List>
    <Text tag="p" className={css(styles.contentPadding, styles.italics)}>
      Exercising Your Rights
    </Text>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      To exercise the access, data portability, and deletion rights described herein, please submit
      a verifiable consumer request to us by either:
    </Text>
    <List bulleted className={css(styles.marginBottomSmall)}>
      <List.Item className={css(styles.bullet)}>
        <Text>Calling us at + 1 (866) 782-3807</Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>Emailing us at hello@setprotocol.com </Text>
      </List.Item>
    </List>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      Only you, or a person registered with the California Secretary of State that you authorize to
      act on your behalf, may make a verifiable consumer request related to your Personal
      Information. You may also make a verifiable consumer request on behalf of your minor child.
    </Text>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      You may only make a verifiable consumer request for access or data portability twice within a
      12-month period. The verifiable consumer request must:
    </Text>
    <List bulleted className={css(styles.marginBottomSmall)}>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Provide sufficient information that allows us to reasonably verify you are the person
          about whom we collected Personal Information or an authorized representative.
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Describe your request with sufficient detail that allows us to properly understand,
          evaluate, and respond to it.
        </Text>
      </List.Item>
    </List>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      We cannot respond to your request or provide you with Personal Information if we cannot verify
      your identity or authority to make the request and confirm the Personal Information relates to
      you.
    </Text>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      Making a verifiable consumer request does not require you to create an Account with us.
      However, in our discretion, we may consider requests made through a password protected Account
      sufficiently verified when the request relates to Personal Information associated with that
      specific account.
    </Text>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      We will only use Personal Information provided in a verifiable consumer request to verify the
      requestor's identity or authority to make the request.
    </Text>
    <Text tag="p" className={css(styles.contentPadding, styles.italics)}>
      Response Timing and Format
    </Text>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      We endeavor to respond to a verifiable consumer request within forty-five (45) days of its
      receipt. If we require more time (up to 90 days), we will inform you of the reason and
      extension period in writing.
    </Text>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      If you have an Account with us, we will deliver our written response to that Account to the
      extent possible. If you do not have an Account with us or delivery to the Account is otherwise
      infeasible, we will deliver our written response by mail or electronically, at your option.
    </Text>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      Any disclosures we provide will only cover the 12-month period preceding the verifiable
      consumer request's receipt. The response we provide will also explain the reasons we cannot
      comply with a request, if applicable. For data portability requests, we will select a format
      to provide your Personal Information that is readily usable and should allow you to transmit
      the information from one entity to another entity without hindrance.
    </Text>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      We do not charge a fee to process or respond to your verifiable consumer request unless it is
      excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a
      fee, we will tell you why we made that decision and provide you with a cost estimate before
      completing your request.
    </Text>
    <Header as="h4" className={css(styles.header)}>
      Non-Discrimination
    </Header>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      Set will not discriminate against you for exercising any of your CCPA rights. Unless permitted
      by the CCPA, we will not:
    </Text>
    <List bulleted className={css(styles.marginBottomSmall)}>
      <List.Item className={css(styles.bullet)}>
        <Text>Deny you goods or services;</Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Charge you different prices or rates for goods or services, including through granting
          discounts or other benefits, or imposing penalties;
        </Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>Provide you a different level or quality of goods or services; or</Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>
          Suggest that you may receive a different price or rate for goods or services or a
          different level or quality of goods or services.
        </Text>
      </List.Item>
    </List>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      However, we may offer you certain financial incentives permitted by the CCPA that can result
      in different prices, rates, or quality levels. Any CCPA-permitted financial incentive we offer
      will reasonably relate to your Personal Information’s value and contain written terms that
      describe the program’s material aspects. Participation in a financial incentive program
      requires your prior opt-in consent, which you may revoke at any time. We do not currently
      provide any financial incentives.
    </Text>
    <Header as="h4" className={css(styles.header)}>
      Other California Rights
    </Header>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to
      contact us to prevent disclosure of Personal Information to third parties for such third
      parties’ direct marketing purposes; in order to submit such a request, please contact us at
      hello@setprotocol.com.
    </Text>
    <Header as="h4" className={css(styles.header)}>
      Changes to Our Privacy Notice
    </Header>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      We reserve the right to amend this Notice at our discretion and at any time. When we make
      changes to this Notice, we will post the updated notice through the Site and update the
      Notice's effective date. Your continued use of our Site following the posting of changes
      constitutes your acceptance of such changes.
    </Text>
    <Header as="h4" className={css(styles.header)}>
      Contact Information
    </Header>
    <Text tag="p" className={css(styles.contentPadding, styles.marginBottomSmall)}>
      If you have any questions or comments about this Notice or wish to exercise your rights under
      California law, please do not hesitate to contact us at:
    </Text>
    <List bulleted className={css(styles.marginBottomSmall)}>
      <List.Item className={css(styles.bullet)}>
        <Text>Phone: + 1 (866) 782-3807</Text>
      </List.Item>
      <List.Item className={css(styles.bullet)}>
        <Text>Email: hello@setprotocol.com</Text>
      </List.Item>
    </List>
  </ContentBox>
);
export default CaliforniaPrivacy;
