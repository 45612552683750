import { css, StyleSheet } from 'aphrodite';
import React, { PureComponent } from 'react';

import {
  ISetDetails,
  IPosition,
  IListToken,
  IPositionWithIndex,
  PerpLeverageData,
} from '../../typings/index';
import { COLORS } from '../../constants/index';
import { BigNumber } from '../../utils/index';
import _ from 'lodash';
import { tokenLogoOverrides } from '../../constants/tokenListConstants';

const styles = StyleSheet.create({
  CustomSetComponentAllocation_container: {
    display: 'flex',
    flexDirection: 'column',
  },
  CustomSetComponentAllocation_componentContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '7px',
  },
  CustomSetComponentAllocation_component: {
    display: 'flex',
    marginRight: '20px',
  },
  CustomSetComponentAllocation_componentIcon: {
    verticalAlign: 'middle',
    height: '18px',
    marginRight: '5px',
  },
  CustomSetComponentAllocation_componentSymbol: {
    fontSize: '11px',
    fontWeight: 500,
    color: COLORS.darkBlue,
    marginRight: '5px',
  },
  CustomSetComponentAllocation_componentPercentage: {
    fontSize: '11px',
    color: COLORS.darkBlue,
  },
  CustomSetComponentAllocation_allocationBarBackground: {
    display: 'flex',
    width: '100%',
    height: '6px',
    backgroundColor: COLORS.lightGray,
    borderRadius: '5px',
    overflow: 'hidden',
  },
  CustomSetComponentAllocation_allocationBar: {
    height: '6px',
  },
  CustomSetComponentAllocation_secondaryContainer: {
    display: 'flex',
  },
  CustomSetComponentAllocation_secondaryComponent: {
    display: 'flex',
    marginRight: '-10px',
  },
  CustomSetComponentAllocation_secondaryIcon: {
    verticalAlign: 'middle',
    height: '18px',
  },
  CustomSetComponentAllocation_secondaryComponentsLabel: {
    color: COLORS.darkBlue,
    fontSize: '10px',
    marginLeft: '15px',
  },

  // Shortened Card Variant
  CustomSetComponentAllocation_shortenedComponent: {
    marginRight: '7px',
  },
});

interface ICustomSetComponentAllocationProps {
  details: ISetDetails;
  customStyles?: any;
  isCardVariant?: boolean;
  truncateTo?: number;
  tokenList?: { [address: string]: IListToken };
  moduleStatuses?: { [key: string]: boolean };
  perpLeverageData?: PerpLeverageData;
  coingeckoTokenListBySymbol?: { [symbol: string]: IListToken };
}

interface ICustomSetComponentAllocationState {
  sortedPositions: IPositionWithIndex[];
}

class CustomSetComponentAllocation extends PureComponent<
  ICustomSetComponentAllocationProps,
  ICustomSetComponentAllocationState
> {
  // The quantity of bunched up token icons to display in "more" section
  SECONDARY_TOKENS_DISPLAY_QUANTITY = 3;

  state: ICustomSetComponentAllocationState = {
    sortedPositions: [],
  };

  static get defaultProps() {
    return {
      details: {},
      truncateTo: 2,
      isCardVariant: false,
      priceUsd: new BigNumber(0),
      coingeckoPrices: {},
      coingeckoTokenListBySymbol: {},
      tokenList: {},
    };
  }

  componentDidMount = () => {
    const sortedPositions = this.getSortedPositions();

    this.setState({
      sortedPositions,
    });
  };

  componentDidUpdate = (prevProps: ICustomSetComponentAllocationProps) => {
    const { perpLeverageData, details } = this.props;

    if (
      !_.isEqual(
        prevProps.perpLeverageData?.vAssetDisplayInfos,
        perpLeverageData?.vAssetDisplayInfos,
      ) ||
      !_.isEqual(prevProps.details, details)
    ) {
      const sortedPositions = this.getSortedPositions();

      this.setState({
        sortedPositions,
      });
    }
  };

  public getSortedPositions = (): IPositionWithIndex[] => {
    const {
      details: { positions },
      perpLeverageData,
    } = this.props;

    let returnPositions = positions;

    let convertedVirtualAssetPositions: IPosition[] = [];
    if (perpLeverageData?.vAssetDisplayInfos?.length) {
      convertedVirtualAssetPositions = perpLeverageData?.vAssetDisplayInfos.map(vAsset => {
        return {
          component: vAsset.vAssetAddress,
          unit: new BigNumber(vAsset.positionUnit.toString()),
        };
      });
      returnPositions = returnPositions.concat(convertedVirtualAssetPositions);
    }

    return returnPositions.map((p, i) => {
      return { ...p, i };
    });
  };

  public renderPrimaryTokens = () => {
    const {
      truncateTo,
      isCardVariant,
      tokenList,
      details,
      perpLeverageData,
      coingeckoTokenListBySymbol,
    } = this.props;
    const { sortedPositions } = this.state;

    // Shortened components are used to fit multi-asset sets in FeaturedSetCard
    const shouldShortenComponents = sortedPositions.length > 2 && isCardVariant;
    const primaryTokens = sortedPositions.slice(0, truncateTo);

    return primaryTokens.map((position: IPositionWithIndex, i: number) => {
      const { component } = position;
      const vAsset = (perpLeverageData?.vAssetDisplayInfos || []).find(
        vAsset => vAsset.vAssetAddress.toLowerCase() === component.toLowerCase(),
      );

      const token = tokenList[component.toLowerCase()];

      const baseSymbolRoot = vAsset
        ? vAsset?.symbol === 'vUSD'
          ? 'usdc'
          : vAsset.symbol?.slice(1)?.toLowerCase()
        : undefined;
      // Will probably need to replace coingeckoTokenList lookup with a hardcoded list of symbols since Coingecko
      // doesn't have all tokens in certain chain tokenlists
      const imageUrl = vAsset
        ? tokenLogoOverrides[component.toLowerCase()] ||
          (
            coingeckoTokenListBySymbol[baseSymbolRoot] ||
            coingeckoTokenListBySymbol['w' + baseSymbolRoot]
          )?.logoURI
        : token?.logoURI || 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-token-icon.svg';

      const symbol = vAsset?.symbol || token?.symbol || '';

      let formattedSymbol = symbol;
      if (symbol === 'WETH') {
        formattedSymbol = 'ETH';
      }

      if (symbol === 'WBTC') {
        formattedSymbol = 'BTC';
      }

      return (
        <div
          key={`primary-${component}-${details.address}-${i}`}
          className={css(
            styles.CustomSetComponentAllocation_component,
            shouldShortenComponents && styles.CustomSetComponentAllocation_shortenedComponent,
          )}
        >
          <img className={css(styles.CustomSetComponentAllocation_componentIcon)} src={imageUrl} />
          <span className={css(styles.CustomSetComponentAllocation_componentSymbol)}>
            {formattedSymbol}
          </span>
        </div>
      );
    });
  };

  public renderSecondaryTokens = () => {
    const {
      truncateTo,
      tokenList,
      details,
      perpLeverageData,
      coingeckoTokenListBySymbol,
    } = this.props;
    const { sortedPositions } = this.state;

    const secondaryTokens = sortedPositions.slice(
      truncateTo,
      truncateTo + this.SECONDARY_TOKENS_DISPLAY_QUANTITY,
    );

    if (!secondaryTokens.length) return null;

    const secondaryTokenIcons = secondaryTokens.map((position: IPosition, index: number) => {
      const { component } = position;

      const vAsset = (perpLeverageData?.vAssetDisplayInfos || []).find(
        vAsset => vAsset.vAssetAddress.toLowerCase() === component.toLowerCase(),
      );

      const token = tokenList[component.toLowerCase()];
      const baseSymbolRoot = vAsset
        ? vAsset?.symbol === 'vUSD'
          ? 'usdc'
          : vAsset.symbol?.slice(1)?.toLowerCase()
        : undefined;
      // Will probably need to replace coingeckoTokenList lookup with a hardcoded list of symbols since Coingecko
      // doesn't have all tokens in certain chain tokenlists
      const imageUrl = vAsset
        ? tokenLogoOverrides[component.toLowerCase()] ||
          (
            coingeckoTokenListBySymbol[baseSymbolRoot] ||
            coingeckoTokenListBySymbol['w' + baseSymbolRoot]
          )?.logoURI
        : token?.logoURI || 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-token-icon.svg';
      return (
        <div
          key={`secondary-${component}-${details.address}-${index}`}
          className={css(styles.CustomSetComponentAllocation_secondaryComponent)}
          style={{ zIndex: 20 - index }}
        >
          <img className={css(styles.CustomSetComponentAllocation_secondaryIcon)} src={imageUrl} />
        </div>
      );
    });

    const allRemainingTokensQty = sortedPositions.length - truncateTo;
    const allRemainingTokensLabel = (
      <span className={css(styles.CustomSetComponentAllocation_secondaryComponentsLabel)}>
        <b>+{allRemainingTokensQty}</b> more
      </span>
    );

    return (
      <div className={css(styles.CustomSetComponentAllocation_secondaryContainer)}>
        {secondaryTokenIcons} {allRemainingTokensLabel}
      </div>
    );
  };

  public renderComponentLabels = () => {
    const primaryDisplayTokens = this.renderPrimaryTokens();
    const secondaryDisplayTokens = this.renderSecondaryTokens();

    return (
      <div className={css(styles.CustomSetComponentAllocation_componentContainer)}>
        {primaryDisplayTokens}
        {secondaryDisplayTokens}
      </div>
    );
  };

  public render() {
    const {
      customStyles,
      details: { positions },
    } = this.props;

    if (!positions || !positions.length) return null;

    return (
      <div className={css(styles.CustomSetComponentAllocation_container, customStyles)}>
        {this.renderComponentLabels()}
      </div>
    );
  }
}

export default CustomSetComponentAllocation;
