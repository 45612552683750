import BigNumber from 'bignumber.js';

import {
  accountBalanceSelector,
  balancesSelector,
  erc20BalancesSelector,
  setDetailsCurrentSetAddressSelector,
} from '../selectors/baseSelectors';
import { tokenFromBaseUnits, tokenToBaseUnits } from '../utils/formatUtils';
import {
  IBalances,
  IBalancesMapping,
  IERC20Balance,
  IListToken,
  IListTokenWithBalance,
  ITokenBalances,
} from '../typings/index';
import { createSelector } from 'reselect';
import {
  geminiTokenListByAddressSelector,
  tokenSetsTokenListByAddressSelector,
} from './tokenListsSelectors';
import { currentChainOrDefaultSelector } from './web3Selectors';
import { NETWORK_CONSTANTS } from '../constants/index';

export const getUserBalancesByTokenIdSelector = (state: any) => {
  const balances: IBalances = balancesSelector(state);

  const balancesMapping: IBalancesMapping = {};
  const createKeyValuePair = (value: IERC20Balance) => (balancesMapping[value.id] = value);

  balances?.fund_balances?.forEach(createKeyValuePair);
  balances?.rebalancing_set_balances?.forEach(createKeyValuePair);
  balances?.coin_balances?.forEach(createKeyValuePair);

  return balancesMapping;
};

export const getUserBalanceOfCurrentSetOnChain = (state: any): BigNumber => {
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);
  const erc20Balances = erc20BalancesSelector(state);

  return erc20Balances[currentSetAddress] || new BigNumber(0);
};

export const getFormattedUserBalanceOfCurrentSetOnChain = (state: any): string => {
  const userBalance = getUserBalanceOfCurrentSetOnChain(state);

  return tokenFromBaseUnits(userBalance.toString()).toString();
};

export const networkListTokenWithBalanceSelector = (state: any): IListTokenWithBalance => {
  const accountBalance = accountBalanceSelector(state);
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case NETWORK_CONSTANTS.POLYGON_CHAIN:
      return {
        name: 'Matic Token',
        symbol: 'MATIC',
        decimals: '18',
        address: '0x0000000000000000000000000000000000001010',
        logoURI: 'https://www.gemini.com/images/currencies/icons/default/matic.svg',
        chainId: '137',
        balance: tokenToBaseUnits(accountBalance).toString(),
      };
    case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
      return {
        name: 'Ether',
        symbol: 'ETH',
        decimals: '18',
        address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        logoURI: 'https://www.gemini.com/images/currencies/icons/default/eth.svg',
        chainId: '42161',
        balance: tokenToBaseUnits(accountBalance).toString(),
      };
    case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
      return {
        name: 'Ether',
        symbol: 'ETH',
        decimals: '18',
        address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        logoURI: 'https://www.gemini.com/images/currencies/icons/default/eth.svg',
        chainId: '10',
        balance: tokenToBaseUnits(accountBalance).toString(),
      };
    case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
      return {
        name: 'Avalanche Token',
        symbol: 'AVAX',
        decimals: '18',
        address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        logoURI: 'https://cryptologos.cc/logos/avalanche-avax-logo.svg',
        chainId: '43114',
        balance: tokenToBaseUnits(accountBalance).toString(),
      };
    default:
      return {
        name: 'Ether',
        symbol: 'ETH',
        decimals: '18',
        address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        logoURI: 'https://www.gemini.com/images/currencies/icons/default/eth.svg',
        chainId: '1',
        balance: tokenToBaseUnits(accountBalance).toString(),
      };
  }
};

export const allAccountTokenBalancesSelector = createSelector(
  tokenSetsTokenListByAddressSelector,
  geminiTokenListByAddressSelector,
  erc20BalancesSelector,
  networkListTokenWithBalanceSelector,
  (
    tokenSetsTokenListByAddress: { [address: string]: IListToken },
    geminiTokenListByAddress: { [address: string]: IListToken },
    erc20Balances: ITokenBalances,
    networkListTokenWithBalance,
  ): { [address: string]: IListTokenWithBalance } => {
    const allTokenAddresses = Object.keys(tokenSetsTokenListByAddress).concat(
      Object.keys(geminiTokenListByAddress),
    );

    const tokenBalanceMapping: { [address: string]: IListTokenWithBalance } = {};
    tokenBalanceMapping[networkListTokenWithBalance.address] = networkListTokenWithBalance;

    allTokenAddresses.forEach(address => {
      if (erc20Balances[address.toLowerCase()]?.toNumber() > 0) {
        tokenBalanceMapping[address] = {
          ...(tokenSetsTokenListByAddress[address] || geminiTokenListByAddress[address]),
          balance: erc20Balances[address.toLowerCase()]?.toString() || '0',
        };
      }
    });

    return tokenBalanceMapping;
  },
);
