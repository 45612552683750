import React, { PureComponent } from 'react';
import { css } from 'aphrodite';
import { Header } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { IWindowDimensions, IHistory } from '../../typings/index';
import styles from './OnboardingStyles';

interface IFinishOnboardingProps {
  readonly history: IHistory;
  readonly windowDimension: IWindowDimensions;
}

class FinishOnboarding extends PureComponent<IFinishOnboardingProps & WithTranslation> {
  public getSetTableHeader = () => {
    const {
      t,
      windowDimension: { isMobile },
    } = this.props;

    let headerData = [
      {
        label: t('sets.name'),
        customStyle: styles.FinishOnboarding_setTableHeaderCell,
      },
      {
        label: t('sets.market-cap'),
        customStyle: [
          styles.FinishOnboarding_setTableMarketCap,
          styles.FinishOnboarding_setTableHeaderCell,
        ],
      },
    ];

    if (isMobile) headerData = headerData.slice(0, 1);

    return headerData;
  };

  render() {
    const { t } = this.props;

    return (
      <div className={css(styles.Onboarding_slide)}>
        <div className={css(styles.Onboarding_slideSection)}>
          <Header as="h1" className={css(styles.Onboarding_sectionTitle)}>
            {t('finish-onboarding.title')}
          </Header>
          <p className={css(styles.Onboarding_sectionSubtitle, styles.FinishOnboarding_subtitle)}>
            {t('finish-onboarding.buying-a-set')}
          </p>
          <p className={css(styles.Onboarding_sectionSubtitle, styles.FinishOnboarding_subtitle)}>
            {t('finish-onboarding.selling-a-set')}
          </p>
        </div>
        <div className={css(styles.Onboarding_slideSection)}>
          <img
            alt="Set categories"
            className={css(styles.FinishOnboarding_image)}
            src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/set-categories.svg"
          />
        </div>
      </div>
    );
  }
}

export default withTranslation('onboarding')(FinishOnboarding);
