import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { withTranslation, WithTranslation } from 'react-i18next';
import Web3 from 'web3';

import { utils, BigNumber as EthersBigNumber } from 'ethers';
import {
  ITokenBalances,
  IMatch,
  IHistory,
  ISetDetails,
  IDebtComponentWithToken,
  IFeeResponse,
} from '../../typings/index';
import { formatTokenUnit, BigNumber } from '../../utils/index';
import { generateColorFromString } from '../../utils/colorUtils';
import { getListTokenImage, tokenFromBaseUnits } from '../../utils/formatUtils';
import { COLORS, POSITION_STATE } from '../../constants/index';
import darkArrow from '../../img/icons/right-arrow-dark.svg';
import SlippageInputPopup from '../../components/SlippageInputPopup';
import { InfoIcon } from '../../components/index';

const styles = StyleSheet.create({
  PerpIssuanceForm_container: {
    maxWidth: '400px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  PerpIssuanceForm_setImage: {
    width: '50px',
    height: '50px',
    marginBottom: '20px',
    borderRadius: '50%',
  },
  PerpIssuanceForm_title: {
    fontSize: '30px',
    fontWeight: 600,
    color: COLORS.darkBlue,
    lineHeight: 1.2,
    textAlign: 'center',
    marginBottom: '20px',
  },
  PerpIssuanceForm_subTitle: {
    fontSize: '14px',
    lineHeight: 1.5,
    textAlign: 'center',
    marginBottom: '20px',
  },
  PerpIssuanceForm_perpIssuanceDescription: {
    fontSize: '14px',
    lineHeight: 1.5,
    textAlign: 'left',
    marginBottom: '20px',
  },

  /* Input Field */
  PerpIssuanceForm_issueInputContainer: {
    width: '100%',
    position: 'relative',
    marginBottom: '20px',
  },
  PerpIssuanceForm_issueQuantityInput: {
    width: '100%',
    fontSize: '16px',
    padding: '15px',
    paddingRight: '120px',
    marginBottom: '20px',
    border: `2px solid ${COLORS.gray}`,
    borderRadius: '4px',
    ':focus': {
      borderColor: COLORS.blue,
    },
  },
  PerpIssuanceForm_issueInputLabelContainer: {
    position: 'absolute',
    top: 15,
    right: 16,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  PerpIssuanceForm_issueInputSymbol: {
    fontSize: '12px',
    color: COLORS.blue,
    fontWeight: 500,
    lineHeight: 1,
  },
  PerpIssuanceForm_issueMaxButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '22px',
    fontSize: '9px',
    marginLeft: '10px',
    padding: '11px 12px 10px 12px',
    ':active': {
      backgroundColor: COLORS.blueDarkened,
    },
  },

  /* PerpIssuance Components Table Header */
  PerpIssuanceForm_tableHeader: {
    display: 'flex',
    paddingBottom: '10px',
    borderBottom: `1px solid ${COLORS.gray}`,
  },
  PerpIssuanceForm_tokenLabel: {
    fontSize: '10px',
    lineHeight: 1.5,
    flex: 1,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  PerpIssuanceForm_outputTokenLabel: {
    fontSize: '10px',
    lineHeight: 1.5,
    flex: 1,
  },
  PerpIssuanceForm_requiredAmountLabel: {
    fontSize: '10px',
    lineHeight: 1.5,
    flex: 1,
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
  PerpIssuanceForm_userBalanceLabel: {
    fontSize: '10px',
    lineHeight: 1.5,
    flex: 1,
    textAlign: 'right',
    alignSelf: 'flex-end',
  },

  /* PerpIssuance Components */
  PerpIssuanceForm_componentContainer: {
    width: '100%',
    marginBottom: '30px',
    padding: '0px 17px',
  },
  PerpIssuanceForm_externalPositionContainer: {
    width: '100%',
    marginBottom: '30px',
    padding: '60px 17px 22px',
    border: `1px solid ${COLORS.lightGray4}`,
    borderRadius: '5px',
    position: 'relative',
  },
  PerpIssuanceForm_component: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
    borderBottom: `1px solid ${COLORS.lightGray}`,
  },
  PerpIssuanceForm_componentLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  PerpIssuanceForm_componentIcon: {
    height: '20px',
    width: '20px',
    borderRadius: '50%',
  },
  PerpIssuanceForm_componentSymbol: {
    fontSize: '14px',
    color: COLORS.darkBlue,
    fontWeight: 600,
    marginLeft: '10px',
  },

  PerpIssuanceForm_componentQuantityRequired: {
    fontSize: '14px',
    color: COLORS.darkBlue,
    flex: 1,
    textAlign: 'right',
  },
  PerpIssuanceForm_componentUserBalance: {
    fontSize: '14px',
    color: COLORS.green,
    flex: 1,
    textAlign: 'right',
  },

  /* Submit Button + Footer */
  PerpIssuanceForm_submitButton: {
    width: '100%',
    padding: '20px',
    fontWeight: 600,
    marginBottom: '30px',
    backgroundColor: COLORS.blue,
    color: COLORS.white,
    ':hover': {
      backgroundColor: COLORS.blueDarkened,
    },
  },
  PerpIssuanceForm_submitButtonDisabled: {
    pointerEvents: 'none',
    backgroundColor: COLORS.gray,
    ':hover': {
      backgroundColor: COLORS.gray,
    },
  },
  PerpIssuanceForm_errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px',
    backgroundColor: COLORS.redAlpha25,
    borderRadius: '4px',
  },
  PerpIssuanceForm_errorText: {
    textAlign: 'center',
    fontSize: '14px',
    color: COLORS.red,
  },

  PerpIssuanceForm_backButton: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '40px',
    height: '40px',
    transform: 'rotate(180deg)',
    padding: '10px',
    margin: '20px',
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '50%',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: COLORS.lightGray,
    },
  },
});

type PerpIssuanceFormProps = {
  customV2SetPathPrefix: string;
  isPerpIssuanceReady: boolean;
  isExceedingAumCap: boolean;
  userHasSufficientFunds: boolean;
  setDetails: ISetDetails;
  setAddress: string;
  issuanceQuantity: string;
  inputComponents: IDebtComponentWithToken[];
  outputComponents: IDebtComponentWithToken[];
  erc20BalancesByAddress: ITokenBalances;
  currentSetAumCap: BigNumber;
  issuanceQuantityAboveAumCap: string;
  match: IMatch;
  history: IHistory;
  maxSlippagePercentageAllowed: string;
  perpIssuanceFees: IFeeResponse;
  isFetchingPerpIssuanceFees: boolean;
  maxTokenAmountsIn: EthersBigNumber[];
  getMaximumSetTokenIssueAmount: (...args: any[]) => any;
  fetchFeesForIssueQuantity: (...args: any[]) => any;
  updateIssuanceQuantity: (...args: any[]) => any;
  setMaxIssuableQuantity: (...args: any[]) => any;
  perpIssueCurrentSet: (...args: any[]) => any;
  setMaxAllowedSlippagePercentage: (...args: any[]) => any;
};

/**
 * @title PerpIssuanceForm
 * @author Set Protocol
 *
 * PerpIssuanceForm is the interface users use to input a desired issuance quantity,
 * The required underlying component quantities, and to initiate the issuance transaction.
 */
class PerpIssuance extends PureComponent<PerpIssuanceFormProps & WithTranslation> {
  async componentDidMount() {
    const { fetchFeesForIssueQuantity, getMaximumSetTokenIssueAmount } = this.props;

    await getMaximumSetTokenIssueAmount();
    await fetchFeesForIssueQuantity();
  }

  public onUpdateIssuanceQuantity = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { updateIssuanceQuantity, fetchFeesForIssueQuantity } = this.props;
    const value = e.target?.value;

    await updateIssuanceQuantity(value);
    fetchFeesForIssueQuantity();
  };

  public renderInputComponents = () => {
    const {
      inputComponents,
      setDetails,
      maxSlippagePercentageAllowed,
      setMaxAllowedSlippagePercentage,
      t,
    } = this.props;

    if (!inputComponents) return null;

    const componentsWithOnlyDefaultPosition = inputComponents.filter(
      (component: IDebtComponentWithToken) => {
        // Check that the positions of this address do not have any external positions
        const hasOnlyDefaultPosition = !setDetails.positions
          .filter(p => p.component.toLowerCase() === component.address.toLowerCase())
          .some(p => p.positionState === POSITION_STATE['EXTERNAL']);

        return hasOnlyDefaultPosition;
      },
    );
    const componentsWithExternalPositions = inputComponents.filter(
      (component: IDebtComponentWithToken) => {
        return !componentsWithOnlyDefaultPosition.find(c => c.address === component.address);
      },
    );

    return (
      <>
        {componentsWithExternalPositions.length > 0 && (
          <div className={css(styles.PerpIssuanceForm_externalPositionContainer)}>
            <SlippageInputPopup
              maxSlippagePercentage={maxSlippagePercentageAllowed}
              setMaxSlippagePercentage={setMaxAllowedSlippagePercentage}
            />
            <div className={css(styles.PerpIssuanceForm_tableHeader)}>
              <span className={css(styles.PerpIssuanceForm_tokenLabel)}>
                EXTERNAL POSITIONS
                <InfoIcon
                  hasHighZIndex={true}
                  pointerPosition="top right"
                  data={{
                    header: 'External Positions',
                    description:
                      'These positions can have slippage due to the trades required to establish virtual positions.',
                  }}
                />
              </span>
              <span className={css(styles.PerpIssuanceForm_requiredAmountLabel)}>
                {t('table.amount-required')}
              </span>
              <span className={css(styles.PerpIssuanceForm_userBalanceLabel)}>
                {t('table.your-balance')}
              </span>
            </div>
            {this.componentList(componentsWithExternalPositions)}
          </div>
        )}
        {componentsWithOnlyDefaultPosition.length > 0 && (
          <div className={css(styles.PerpIssuanceForm_componentContainer)}>
            <div className={css(styles.PerpIssuanceForm_tableHeader)}>
              <span className={css(styles.PerpIssuanceForm_tokenLabel)}>
                DEFAULT POSITIONS
                <InfoIcon
                  hasHighZIndex={true}
                  pointerPosition="top right"
                  data={{
                    header: 'Default Positions',
                    description:
                      'These positions do not experience slippage and can be directly supplied.',
                  }}
                />
              </span>
              <span className={css(styles.PerpIssuanceForm_requiredAmountLabel)}>
                {t('table.amount-required')}
              </span>
              <span className={css(styles.PerpIssuanceForm_userBalanceLabel)}>
                {t('table.your-balance')}
              </span>
            </div>
            {this.componentList(componentsWithOnlyDefaultPosition)}
          </div>
        )}
      </>
    );
  };

  public componentList = (components: IDebtComponentWithToken[]) => {
    const { inputComponents, erc20BalancesByAddress, maxTokenAmountsIn } = this.props;

    return components?.map((component: IDebtComponentWithToken) => {
      const { address } = component;

      const componentIndex = inputComponents.findIndex(c => c.address === address);

      const checkSumAddress = Web3.utils.toChecksumAddress(address);
      const userBalance = erc20BalancesByAddress[checkSumAddress]?.toString();
      const derivedTokenColor = generateColorFromString(address || '');

      return (
        <div className={css(styles.PerpIssuanceForm_component)} key={component.symbol}>
          <div className={css(styles.PerpIssuanceForm_componentLabelContainer)}>
            <img
              className={css(styles.PerpIssuanceForm_componentIcon)}
              src={getListTokenImage(component)}
              style={{ backgroundColor: `#${derivedTokenColor}` }}
            />
            <span className={css(styles.PerpIssuanceForm_componentSymbol)}>
              {component?.symbol}
            </span>
          </div>
          <span className={css(styles.PerpIssuanceForm_componentQuantityRequired)}>
            {tokenFromBaseUnits(
              maxTokenAmountsIn[componentIndex].toString(),
              component.decimals,
            ).toFixed(8, BigNumber.ROUND_DOWN)}
          </span>
          <span className={css(styles.PerpIssuanceForm_componentUserBalance)}>
            {formatTokenUnit(userBalance, component?.decimals, 8)}
          </span>
        </div>
      );
    });
  };

  public renderOutputComponents = () => {
    const {
      setDetails,
      setAddress,
      outputComponents,
      issuanceQuantity,
      isFetchingPerpIssuanceFees,
      perpIssuanceFees,
      t,
    } = this.props;

    if (!outputComponents) return null;

    const formattedIssuanceQuantity = utils.parseEther(
      issuanceQuantity?.length ? issuanceQuantity : '0',
    );

    const requiredComponentsList = outputComponents?.map((component: IDebtComponentWithToken) => {
      const { address } = component;
      const formattedDebtValue = tokenFromBaseUnits(
        component?.debtValue?.toString() || '0',
        component?.decimals || 18,
      );
      const returnedQuantity = formattedDebtValue.mul(issuanceQuantity || 0);
      const derivedTokenColor = generateColorFromString(address || '');

      return (
        <div className={css(styles.PerpIssuanceForm_component)} key={component.symbol}>
          <div className={css(styles.PerpIssuanceForm_componentLabelContainer)}>
            <img
              className={css(styles.PerpIssuanceForm_componentIcon)}
              src={getListTokenImage(component)}
              style={{ backgroundColor: `#${derivedTokenColor}` }}
            />
            <span className={css(styles.PerpIssuanceForm_componentSymbol)}>
              {component?.symbol}
            </span>
          </div>
          <span className={css(styles.PerpIssuanceForm_componentQuantityRequired)}>
            {returnedQuantity.toFixed(8, BigNumber.ROUND_DOWN)}
          </span>
        </div>
      );
    });

    const derivedSetColor = generateColorFromString(setAddress || '');
    const currentSetToken = (
      <div className={css(styles.PerpIssuanceForm_component)} key={setDetails?.symbol}>
        <div className={css(styles.PerpIssuanceForm_componentLabelContainer)}>
          <img
            className={css(styles.PerpIssuanceForm_componentIcon)}
            style={{ backgroundColor: `#${derivedSetColor}` }}
            src={'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-set-token-icon.svg'}
          />
          <span className={css(styles.PerpIssuanceForm_componentSymbol)}>{setDetails?.symbol}</span>
        </div>
        <span className={css(styles.PerpIssuanceForm_componentQuantityRequired)}>
          {issuanceQuantity || '0'}
        </span>
      </div>
    );

    return (
      <div className={css(styles.PerpIssuanceForm_componentContainer)}>
        {formattedIssuanceQuantity.gt(0) && (
          <p className={css(styles.PerpIssuanceForm_perpIssuanceDescription)}>
            {t('perp-issue-form.fees', {
              setName: setDetails?.name,
              feePercentage: `${
                !isFetchingPerpIssuanceFees || perpIssuanceFees?.managerFee
                  ? Number(
                      new BigNumber(perpIssuanceFees?.managerFeePercentage || 0).toFixed(
                        8,
                        BigNumber.ROUND_DOWN,
                      ),
                    )
                  : 0
              }%`,
            })}{' '}
            {t('perp-issue-form.returned-tokens')}
          </p>
        )}
        <div className={css(styles.PerpIssuanceForm_tableHeader)}>
          <span className={css(styles.PerpIssuanceForm_outputTokenLabel)}>{t('table.token')}</span>
          <span className={css(styles.PerpIssuanceForm_requiredAmountLabel)}>
            {t('table.amount-returned')}
          </span>
        </div>
        {currentSetToken}
        {requiredComponentsList}
      </div>
    );
  };

  renderErrorMessage = () => {
    const {
      isExceedingAumCap,
      userHasSufficientFunds,
      setDetails,
      currentSetAumCap,
      issuanceQuantityAboveAumCap,
      t,
    } = this.props;

    if (isExceedingAumCap) {
      const totalSupplyCap = tokenFromBaseUnits(currentSetAumCap?.toString());
      return (
        <div className={css(styles.PerpIssuanceForm_errorContainer)}>
          <span className={css(styles.PerpIssuanceForm_errorText)}>
            {t('issue-form.error-aum-cap', {
              totalSupplyCap,
              issuanceQuantityAboveAumCap,
            })}
          </span>
        </div>
      );
    }

    if (!userHasSufficientFunds) {
      return (
        <div className={css(styles.PerpIssuanceForm_errorContainer)}>
          <span className={css(styles.PerpIssuanceForm_errorText)}>
            {t('issue-form.error-title')}{' '}
            {t('issue-form.error-description', { setName: setDetails?.name })}
          </span>
        </div>
      );
    }

    return null;
  };

  render() {
    const {
      setAddress,
      customV2SetPathPrefix,
      setDetails,
      issuanceQuantity,
      isPerpIssuanceReady,
      history,
      perpIssueCurrentSet,
      setMaxIssuableQuantity,
      t,
    } = this.props;

    return (
      <div className={css(styles.PerpIssuanceForm_container)}>
        <img
          src={darkArrow}
          className={css(styles.PerpIssuanceForm_backButton)}
          onClick={() => history.push(`${customV2SetPathPrefix}/${setAddress}`)}
        />
        <img
          className={css(styles.PerpIssuanceForm_setImage)}
          src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/issue.svg"
        />
        <p className={css(styles.PerpIssuanceForm_title)}>Issue {setDetails?.name} with Slippage</p>
        <p className={css(styles.PerpIssuanceForm_subTitle)}>
          {t('perp-issue-form.title', { tokenSymbol: setDetails?.symbol })}
        </p>

        <div className={css(styles.PerpIssuanceForm_issueInputContainer)}>
          <input
            className={css(styles.PerpIssuanceForm_issueQuantityInput)}
            type="number"
            step="0.01"
            value={issuanceQuantity}
            onChange={this.onUpdateIssuanceQuantity}
          />
          <div className={css(styles.PerpIssuanceForm_issueInputLabelContainer)}>
            <span className={css(styles.PerpIssuanceForm_issueInputSymbol)}>
              {setDetails?.symbol}
            </span>
            <button
              className={css(styles.PerpIssuanceForm_issueMaxButton)}
              type="button"
              onClick={setMaxIssuableQuantity}
            >
              MAX
            </button>
          </div>
        </div>

        {this.renderInputComponents()}
        {this.renderOutputComponents()}

        <button
          className={css(
            styles.PerpIssuanceForm_submitButton,
            !isPerpIssuanceReady && styles.PerpIssuanceForm_submitButtonDisabled,
          )}
          disabled={!isPerpIssuanceReady}
          type="button"
          onClick={perpIssueCurrentSet}
        >
          {t('buttons.issue')}
        </button>

        {this.renderErrorMessage()}
      </div>
    );
  }
}
export default withTranslation('issue-and-redeem')(PerpIssuance);
