import { StyleSheet, css } from 'aphrodite';
import React, { FunctionComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';

import { CloseIcon } from '../../components/index';
import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';
import { IIssuanceSource, IRebalancingSet } from '../../typings/index';

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  ExternalLinkBuyModal_container: {
    marginBottom: '100px',
    borderRadius: '5px',
    backgroundColor: COLORS.white,
    width: '350px',
    [MOBILE_MEDIA_QUERY]: {
      height: '100%',
      margin: '0',
      width: '100%',
    },
  },
  ExternalLinkBuyModal_setIcon: {
    marginBottom: '15px',
    marginTop: '15px',
    height: '70px',
    width: '70px',
  },
  ExternalLinkBuyModal_content: {
    textAlign: 'center',
  },
  ExternalLinkBuyModal_button: {
    backgroundColor: COLORS.darkBlue,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    transition: '0.2s',
    ':hover': {
      transform: 'scale(1.02)',
    },
  },
  ExternalLinkBuyModal_description: {
    color: COLORS.darkBlue,
    marginBottom: '15px',
  },
  ExternalLinkBuyModal_buttonContainer: {
    marginTop: '15px',
  },
  ExternalLinkBuyModal_linkIcon: {
    marginRight: '10px',
    height: '25px',
    width: '25px',
  },
  ExternalLinkBuyModal_linkText: {
    color: COLORS.white,
    fontWeight: 500,
  },
});

type IExternalLinkBuyModalProps = {
  currentSet: IRebalancingSet;
  isModalOpen: boolean;
  isUserBuying: boolean;
  onCloseModal: (...args: any[]) => any;
  t: (...args: any[]) => any;
} & WithTranslation;

/**
 * @title ExternalLinkBuyModal
 * @author Set Labs
 *
 * The ExternalLinkBuyModal shows the user external links on where to buy Sets from if
 * TokenSets doesn't support buying from our site directly.
 */
const ExternalLinkBuyModal: FunctionComponent<IExternalLinkBuyModalProps> = ({
  currentSet,
  isModalOpen,
  isUserBuying,
  onCloseModal,
  t,
}) => {
  const action = isUserBuying ? t('common:buttons.buy') : t('common:buttons.sell');
  const description = t('external-link-modal.description', { action, setName: currentSet.name });

  return (
    <Modal
      open={isModalOpen}
      onClose={onCloseModal}
      closeIcon={<CloseIcon onClick={onCloseModal} />}
      className={css(styles.ExternalLinkBuyModal_container)}
    >
      <Modal.Content className={css(styles.ExternalLinkBuyModal_content)}>
        <img
          src={currentSet.image}
          alt="Set icon"
          className={css(styles.ExternalLinkBuyModal_setIcon)}
        />
        <p className={css(styles.ExternalLinkBuyModal_description)}>{description}</p>
        {currentSet.issuance_sources.map((issuanceSource: IIssuanceSource) => (
          <div className={css(styles.ExternalLinkBuyModal_buttonContainer)} key={issuanceSource.id}>
            <a href={issuanceSource.exchange_url} target="_blank">
              <button className={css(styles.ExternalLinkBuyModal_button)}>
                <img
                  className={css(styles.ExternalLinkBuyModal_linkIcon)}
                  src={issuanceSource.exchange_image_url}
                  alt={`${issuanceSource.name} icon`}
                />
                <span className={css(styles.ExternalLinkBuyModal_linkText)}>
                  {`${t('external-link-modal.view-on')} ${issuanceSource.name}`}
                </span>
              </button>
            </a>
          </div>
        ))}
      </Modal.Content>
    </Modal>
  );
};

export default withTranslation('buy-sell-modal')(ExternalLinkBuyModal);
