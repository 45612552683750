import React, { PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { StyleSheet, css } from 'aphrodite';
import { Icon } from 'semantic-ui-react';

import { COLORS } from '../../constants/index';
import { inclusiveRange } from './utils';

const styles = StyleSheet.create({
  Pagination_container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
  },
  Pagination_buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '34px',
    height: '34px',
    padding: '6px',
    margin: '0px 10px',
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '30px',
    boxShadow: `0 2px 4px 0 ${COLORS.gray}`,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: COLORS.lightGray,
    },
  },
  Pagination_buttonArrowIcon: {
    margin: '0px',
    lineHeight: 1,
  },
  Pagination_currentPageButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '34px',
    height: '34px',
    padding: '6px',
    margin: '0px 10px',
    border: `1px solid ${COLORS.darkBlue}`,
    borderRadius: '30px',
    backgroundColor: COLORS.darkBlue,
  },
  Pagination_currentPageText: {
    color: COLORS.white,
    fontSize: '16px',
    lineHeight: 1,
  },
  Pagination_inactivePageButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '34px',
    height: '34px',
    padding: '6px',
    backgroundColor: COLORS.white,
    cursor: 'pointer',
  },
  Pagination_inactivePageText: {
    color: COLORS.darkBlue,
    fontSize: '16px',
    ':hover': {
      color: COLORS.darkBlueDarkened,
    },
  },
  Pagination_placeholder: {
    width: '34px',
    height: '34px',
    padding: '6px',
    margin: '0px 10px',
  },
  Pagination_currentRangeContainer: {
    textAlign: 'center',
  },
  Pagination_currentRangeText: {
    display: 'inline-block',
    fontSize: '14px',
    color: COLORS.darkGray,
    marginBottom: '10px',
  },
});

interface IPaginationProps {
  currentPageNumber: number;
  maxPageCount: number;
  maxElementsPerPageCount: number;
  totalElements: number;
  elementType?: string;
  customStyles?: any;
  onIncrementPageNumber: (...args: any[]) => any;
  onDecrementPageNumber: (...args: any[]) => any;
  onSetPageNumber: (...args: any[]) => any;
}

class Pagination extends PureComponent<IPaginationProps & WithTranslation> {
  static get defaultProps() {
    return {
      elementType: 'items',
    };
  }

  public scrollToTop = () => window.scroll({ top: 225, behavior: 'smooth' });

  public setPageNumber = (pageNumber: number) => {
    const { onSetPageNumber } = this.props;

    onSetPageNumber(pageNumber);
    this.scrollToTop();
  };

  public incrementPageNumber = () => {
    const { onIncrementPageNumber } = this.props;

    onIncrementPageNumber();
    this.scrollToTop();
  };

  public decrementPageNumber = () => {
    const { onDecrementPageNumber } = this.props;

    onDecrementPageNumber();
    this.scrollToTop();
  };

  public renderPreviousPageButton = () => {
    const { currentPageNumber } = this.props;

    if (currentPageNumber <= 1) {
      return <div className={css(styles.Pagination_placeholder)}></div>;
    }

    return (
      <div className={css(styles.Pagination_buttonContainer)} onClick={this.decrementPageNumber}>
        <Icon name="chevron left" className={css(styles.Pagination_buttonArrowIcon)} />
      </div>
    );
  };

  public renderNextPageButton = () => {
    const { currentPageNumber, maxPageCount } = this.props;

    if (currentPageNumber >= maxPageCount) {
      return <div className={css(styles.Pagination_placeholder)}></div>;
    }

    return (
      <div className={css(styles.Pagination_buttonContainer)} onClick={this.incrementPageNumber}>
        <Icon name="chevron right" className={css(styles.Pagination_buttonArrowIcon)} />
      </div>
    );
  };

  public renderCurrentPageNumber = () => {
    const { currentPageNumber } = this.props;

    return (
      <div className={css(styles.Pagination_currentPageButton)}>
        <span className={css(styles.Pagination_currentPageText)}>{currentPageNumber}</span>
      </div>
    );
  };

  /**
   * Renders a target number as a clickable pagination element.
   * If pageNumber is null, renders a placeholder ellipsis
   * @param pageNumber - Number or null value to be rendered.
   */
  public renderPageNumber = (pageNumber: number | null) => {
    if (pageNumber === null) {
      return (
        <div key="ellipse" className={css(styles.Pagination_inactivePageButton)}>
          <span className={css(styles.Pagination_inactivePageText)}>...</span>
        </div>
      );
    }

    return (
      <div
        key={pageNumber}
        className={css(styles.Pagination_inactivePageButton)}
        onClick={() => this.setPageNumber(pageNumber)}
      >
        <span className={css(styles.Pagination_inactivePageText)}>{pageNumber}</span>
      </div>
    );
  };

  /**
   * Gets page numbers to be render to the right of the current page number.
   * Returned null values will be rendered as ellipsis (...).
   */
  public getNextPageNumbers = (): (number | null)[] => {
    const { currentPageNumber, maxPageCount } = this.props;

    if (currentPageNumber >= maxPageCount) return [];

    if (maxPageCount <= 6) return inclusiveRange(currentPageNumber + 1, maxPageCount);

    if (currentPageNumber === 1) return [2, 3, null, maxPageCount];

    if (currentPageNumber + 3 >= maxPageCount)
      return inclusiveRange(currentPageNumber + 1, maxPageCount);

    return [currentPageNumber + 1, null, maxPageCount];
  };

  /**
   * Gets page numbers to render to the left of the current page number.
   * Returned null values will be rendered as ellipsis (...).
   */
  public getPreviousPageNumbers = (): (number | null)[] => {
    const { currentPageNumber, maxPageCount } = this.props;

    if (currentPageNumber <= 0) return [];

    if (maxPageCount <= 6) return inclusiveRange(1, currentPageNumber - 1);

    if (currentPageNumber === maxPageCount) return [1, null, maxPageCount - 2, maxPageCount - 1];

    if (currentPageNumber - 3 <= 1) return inclusiveRange(1, currentPageNumber - 1);

    return [1, null, currentPageNumber - 1];
  };

  public renderCurrentDisplayRange = () => {
    const {
      currentPageNumber,
      maxElementsPerPageCount,
      totalElements,
      elementType,
      t,
    } = this.props;

    const currentEndRange = currentPageNumber * maxElementsPerPageCount;
    const currentStartRange = currentEndRange - maxElementsPerPageCount + 1;

    const endRangeOrMaxCount = totalElements < currentEndRange ? totalElements : currentEndRange;

    return (
      <div className={css(styles.Pagination_currentRangeContainer)}>
        <span className={css(styles.Pagination_currentRangeText)}>
          {t('pagination.footer-description', {
            startRange: totalElements === 0 ? 0 : currentStartRange,
            endRange: endRangeOrMaxCount,
            totalElementCount: totalElements,
            elementType,
          })}
        </span>
      </div>
    );
  };

  public render() {
    const { customStyles } = this.props;

    const previousPageNumbers = this.getPreviousPageNumbers();
    const nextPageNumbers = this.getNextPageNumbers();

    return (
      <>
        <div className={css(styles.Pagination_container, customStyles)}>
          {this.renderPreviousPageButton()}
          {previousPageNumbers.map(this.renderPageNumber)}
          {this.renderCurrentPageNumber()}
          {nextPageNumbers.map(this.renderPageNumber)}
          {this.renderNextPageButton()}
        </div>
        {this.renderCurrentDisplayRange()}
      </>
    );
  }
}

export default withTranslation('components')(Pagination);
