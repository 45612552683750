import { css, StyleSheet } from 'aphrodite';
import React, { PureComponent } from 'react';
import { Modal, Button } from 'semantic-ui-react';

import { COLORS, WINDOW_DIMENSIONS } from '../../constants/index';
import i18n, { supportedLanguages, supportedLanguagesLabelsMap } from '../../i18n';
import CloseIcon from '../../components/CloseIcon';
import { withTranslation, WithTranslation } from 'react-i18next';

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  SelectLanguageModal_container: {
    width: '80%',
    maxWidth: '800px',
    transition: '0.2s',
    textAlign: 'left',
    backgroundColor: COLORS.white,
    borderRadius: '4px',
    padding: '0px',
    [MOBILE_MEDIA_QUERY]: {
      width: '90%',
      padding: '12px',
      top: '20px',
    },
  },
  SelectLanguageModal_header: {
    display: 'flex',
    marginBottom: '20px',
  },
  SelectLanguageModal_title: {
    color: COLORS.darkBlue,
    fontWeight: 500,
    fontSize: '24px',
  },
  SelectLanguageModal_languageOptionsContainer: {
    display: 'flex',
    justifyContente: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
    [MOBILE_MEDIA_QUERY]: {
      justifyContent: 'space-around',
    },
  },
  SelectLanguageModal_activeLanguageOption: {
    border: `1px solid ${COLORS.darkBlue}`,
  },
  SelectLanguageModal_languageOption: {
    margin: '20px',
    width: '140px',
    fontWeight: 400,
    backgroundColor: COLORS.white,
    color: COLORS.darkBlue,
    ':hover': {
      backgroundColor: COLORS.lightGray,
    },
    [MOBILE_MEDIA_QUERY]: {
      width: '120px',
      margin: '0px',
      marginBottom: '20px',
    },
  },
});

interface ISelectLanguageModalProps {
  isModalOpen: boolean;
  onCloseModal: (...args: any[]) => any;
}

/**
 * @title SelectLanguageModal
 * @author Set Labs
 *
 * The SelectLanguageModal allows users to choose a desired language option for displaying text.
 */
class SelectLanguageModal extends PureComponent<ISelectLanguageModalProps & WithTranslation> {
  public onSelectLanguage = async (language: string) => {
    const { onCloseModal } = this.props;

    i18n.changeLanguage(language);

    onCloseModal();
  };

  public renderSelectLanguageForm = () => {
    const { t } = this.props;

    return (
      <div>
        <div className={css(styles.SelectLanguageModal_header)}>
          <span className={css(styles.SelectLanguageModal_title)}>
            {t('select-language-modal.choose-a-language')}
          </span>
        </div>
        <div className={css(styles.SelectLanguageModal_languageOptionsContainer)}>
          {supportedLanguages.map((language: string) => (
            <Button
              key={language}
              className={css(
                styles.SelectLanguageModal_languageOption,
                i18n.language === language && styles.SelectLanguageModal_activeLanguageOption,
              )}
              onClick={() => this.onSelectLanguage(language)}
            >
              {supportedLanguagesLabelsMap[language]}
            </Button>
          ))}
        </div>
      </div>
    );
  };

  public render() {
    const { isModalOpen, onCloseModal } = this.props;

    return (
      <Modal
        closeIcon={<CloseIcon onClick={onCloseModal} />}
        open={isModalOpen}
        onClose={onCloseModal}
        size="mini"
        className={`${css(styles.SelectLanguageModal_container)} scrolling`}
      >
        <Modal.Content>{this.renderSelectLanguageForm()}</Modal.Content>
      </Modal>
    );
  }
}

export default withTranslation('components')(SelectLanguageModal);
