import {
  OPEN_SELECT_LANGUAGE_MODAL,
  CLOSE_SELECT_LANGUAGE_MODAL,
} from '../actions/selectLanguageActions';

interface InitialState {
  isSelectLanguageModalOpen: boolean;
}

const initialState: InitialState = {
  isSelectLanguageModalOpen: false,
};

const selectLanguageReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case OPEN_SELECT_LANGUAGE_MODAL:
      return {
        isSelectLanguageModalOpen: true,
      };
    case CLOSE_SELECT_LANGUAGE_MODAL:
      return {
        isSelectLanguageModalOpen: false,
      };
    default:
      return state;
  }
};

export default selectLanguageReducer;
