import keyMirror from 'keymirror';

const DATA_SELECTS = keyMirror({
  SET_DETAIL_HEADER_COL_BODY: null,
  SET_DETAIL_HEADER_COL_ICON: null,
  SET_DETAIL_HEADER_COL_SYMBOL: null,
  SET_DETAIL_HEADER_COL_HEADER: null,
  SET_DETAIL_HEADER_COL_DESCRIPTION: null,
  SET_DETAIL_HEADER_COL_BUNDLE_BTN: null,
  SET_DETAIL_HEADER_COL_UNBUNDLE_BTN: null,
  SET_DETAIL_HEADER_COL_ADVANCED_DROPDOWN_BTN: null,
  SET_DETAIL_HEADER_COL_MARKETCAP: null,
  SET_DETAIL_HEADER_COL_MARKETCAP_VALUE: null,
  SET_DETAIL_HEADER_COL_SUPPLY: null,
  SET_DETAIL_HEADER_COL_SUPPLY_VALUE: null,
});

export default DATA_SELECTS;
