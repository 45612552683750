import { tokenToBaseUnits } from '../utils/formatUtils';
import BigNumber from '../utils/bigNumber';

const ethFliAumCap = '400000';

const aumCaps: { [tokenAddress: string]: BigNumber } = {
  // Staging Sets FLI Sets
  '0xdB00988CE40Da3817Ad81A69896f29dAbaa8B10D': tokenToBaseUnits(ethFliAumCap),
  '0x2eD82584D6a59cF3Cc3E8C51af320A331bb4E85B': tokenToBaseUnits(ethFliAumCap),
  '0x5E834C590ac23B2A7C80a75B2d5e55a18DE7E087': tokenToBaseUnits(ethFliAumCap),
  '0x0437c39a59c42E367C00BC3C95999fEb799bce1a': tokenToBaseUnits(ethFliAumCap),

  // Staging Mainnet FLI Sets
  '0xDC11c313a28A68c6Fc4df718DD93f057849e8208': tokenToBaseUnits(ethFliAumCap),
  '0xA735193e7585c5746635A4e10F015BD22ED1c150': tokenToBaseUnits(ethFliAumCap),
};

export default aumCaps;
