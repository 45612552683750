import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import CustomSetDetails from './CustomSetDetails';
import {
  handleLogin as handleLoginAction,
  openChainSwitchModal,
  closeChainSwitchModal,
} from '../../actions/web3Actions';
import {
  accountSelector,
  isLoggedInSelector,
  isTransactionPendingSelector,
  providerTypeSelector,
  windowDimensionSelector,
  isFetchingSetDetailsSelector,
  confirmedWarningsListSelector,
  isInvalidSetDetailsSelector,
  setBalancesSelector,
  setDetailsCurrentSetAddressSelector,
  isFetchingCustomSetDetailsSelector,
  isInvalidCustomSetDetailsSelector,
  currentChainOrDefaultSelector,
  perpV2LeverageModuleEnabledSelector,
  perpCollateralTokenSelector,
  perpAccountInfoSelector,
  perpPositionUnitInfosSelector,
  perpSetTotalCollateralUnitsSelector,
  vAssetDisplayInfosSelector,
  customSetPathPrefixSelector,
  perpV2BasisTradingModuleEnabledSelector,
  networkBadgeSelector,
} from '../../selectors/index';
import {
  fetchSetDetails,
  openEditSetDetailsModal,
  setCurrentlyViewingSetAddress,
  fetchCustomSetDetails,
  agreeToExperimentalWarning,
} from '../../actions/setDetailsActions';
import {
  fetch1InchTokenList,
  fetchCoingeckoTokenList,
  fetchTokenSetsTokenList,
} from '../../actions/tokenListsActions';

import {
  currentCustomSetDetailsSelector,
  currentSetComponentsSelector,
  isGeofencedSetSelector,
} from '../../selectors/setDetailsSelectors';
import { fetchSetBalance } from '../../actions/balanceActions';
import { fetchTokenColorsForCurrentSet } from '../../actions/tokenColorsActions';
import { fetchIpAddress } from '../../actions/ipAddressActions';
import { getGasPrice } from '../../actions/gasPriceActions';
import { isBasicIssuanceModuleEnabledForCurrentSetSelector } from '../../selectors/issuanceV2Selectors';
import { fetchEnabledModules, fetchDebtIssuanceV2Fees } from '../../actions/setDetailsActions';
import {
  currentSetDetailsSelector,
  formattedSetComponentsWithColorsSelector,
} from '../../selectors/setDetailsSelectors';
import {
  coingeckoTokenListByAddressSelector,
  coingeckoTokenListBySymbolSelector,
  tokenSetsTokenListByAddressSelector,
} from '../../selectors/tokenListsSelectors';
import { accrueStreamingFee } from '../../actions/feesV2Actions';
import { fetchDeployedSetsWithLogos } from '../../actions/deployedSetsActions';
import { fetchAllPerpLeverageData } from '../../actions/perpLeverageActions';
import {
  isUserDelegatedManagerSetOperator,
  getCurrentSetOwnerSelector,
} from '../../selectors/setManagerSelectors';

const mapStateToProps = (state: any) => {
  const isLoggedIn = isLoggedInSelector(state);
  const isTransactionProcessing = isTransactionPendingSelector(state);
  const isGeofencedSet = isGeofencedSetSelector(state);
  const isBasicIssuanceEnabled = isBasicIssuanceModuleEnabledForCurrentSetSelector(state);
  const isFetchingCustomSetDetails = isFetchingCustomSetDetailsSelector(state);
  const isInvalidCustomSetDetails = isInvalidCustomSetDetailsSelector(state);
  const account = accountSelector(state);
  const providerType = providerTypeSelector(state);
  const windowDimension = windowDimensionSelector(state);
  const isFetchingSetDetails = isFetchingSetDetailsSelector(state);
  const isInvalidSetDetails = isInvalidSetDetailsSelector(state);
  const warningConfirmations = confirmedWarningsListSelector(state);
  const setBalances = setBalancesSelector(state);
  const setDetails = currentSetDetailsSelector(state);
  const currentSetComponents = currentSetComponentsSelector(state);
  const formattedCurrentSetComponentsWithColors = formattedSetComponentsWithColorsSelector(state);
  const setDetailsCurrentSetAddress = setDetailsCurrentSetAddressSelector(state);
  const customSetDetails = currentCustomSetDetailsSelector(state);
  const currentChain = currentChainOrDefaultSelector(state);
  const networkBadge = networkBadgeSelector(state);
  const customSetPathPrefix = customSetPathPrefixSelector(state);
  const tokenSetsTokenListByAddress = tokenSetsTokenListByAddressSelector(state);
  const perpV2LeverageModuleEnabled = perpV2LeverageModuleEnabledSelector(state);
  const perpV2BasisTradingModuleEnabled = perpV2BasisTradingModuleEnabledSelector(state);
  const perpCollateralToken = perpCollateralTokenSelector(state);
  const perpPositionUnitInfos = perpPositionUnitInfosSelector(state);
  const perpAccountInfo = perpAccountInfoSelector(state);
  const perpSetTotalCollateralUnits = perpSetTotalCollateralUnitsSelector(state);
  const coingeckoTokenListByAddress = coingeckoTokenListByAddressSelector(state);
  const coingeckoTokenListBySymbol = coingeckoTokenListBySymbolSelector(state);

  const vAssetDisplayInfo = vAssetDisplayInfosSelector(state);
  const isUserValidOperator = isUserDelegatedManagerSetOperator(state);
  const setOwnerAddress = getCurrentSetOwnerSelector(state);

  return {
    currentSetComponents,
    coingeckoTokenListByAddress,
    coingeckoTokenListBySymbol,
    perpSetTotalCollateralUnits,
    perpCollateralToken,
    vAssetDisplayInfo,
    perpPositionUnitInfos,
    perpAccountInfo,
    perpV2LeverageModuleEnabled: perpV2LeverageModuleEnabled[setDetailsCurrentSetAddress],
    perpV2BasisTradingModuleEnabled: perpV2BasisTradingModuleEnabled[setDetailsCurrentSetAddress],
    tokenSetsTokenListByAddress,
    isLoggedIn,
    isTransactionProcessing,
    isFetchingSetDetails,
    isInvalidSetDetails,
    isFetchingCustomSetDetails,
    isInvalidCustomSetDetails,
    isBasicIssuanceEnabled,
    customSetDetails,
    isGeofencedSet,
    account,
    providerType,
    windowDimension,
    setDetails,
    setOwnerAddress,
    setBalances,
    formattedCurrentSetComponentsWithColors,
    warningConfirmations,
    setDetailsCurrentSetAddress,
    currentChain,
    networkBadge,
    customSetPathPrefix,
    isUserValidOperator,
  };
};

const mapDispatchToProps = {
  fetchAllPerpLeverageData,
  onAgreeToExperimentalWarning: agreeToExperimentalWarning,
  onFetchTokenColorsForCurrentSet: fetchTokenColorsForCurrentSet,
  fetchSetDetails: fetchSetDetails,
  fetchCustomSetDetails: fetchCustomSetDetails,
  fetchSetBalance: fetchSetBalance,
  fetch1InchTokenList: fetch1InchTokenList,
  fetchCoingeckoTokenList,
  fetchTokenSetsTokenList,
  fetchDebtIssuanceV2Fees,
  onFetchEnabledModules: fetchEnabledModules,
  onFetchGasPrice: getGasPrice,
  onFetchIpAddress: fetchIpAddress,
  onSetCurrentlyViewingSetAddress: setCurrentlyViewingSetAddress,
  onHandleLogin: handleLoginAction,
  onOpenEditDetailsModal: openEditSetDetailsModal,
  accrueStreamingFee: accrueStreamingFee,
  openChainSwitchModal,
  closeChainSwitchModal,
  onFetchDeployedSetsWithLogosAndPrices: fetchDeployedSetsWithLogos,
};

const translated = withTranslation('set-details-v2')(CustomSetDetails);

export default connect(mapStateToProps, mapDispatchToProps)(translated);
