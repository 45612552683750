import { css } from 'aphrodite';
import React, { PureComponent } from 'react';
import { Container, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import styles from './homeStyles';
import { LinkButton } from '../../components/index';

/**
 * @title HomeProducts
 * @author Set Labs
 *
 * The HomeProducts section goes over the products built on Set that other asset managers
 * view as product validation for Set V2.
 */
class HomeProducts extends PureComponent {
  render() {
    return (
      <Container className={css(styles.Home_paddingBottomLarge)}>
        <div className={css(styles.HomeExplainerSection_container)}>
          <div className={css(styles.HomeExplainerSection_leftColumn)}>
            <Header as="h3" className={css(styles.HomeExplainerSection_subTitle)}>
              Products
            </Header>
            <div className={css(styles.HomeExplainerSection_titleContainer)}>
              <Header as="h2" className={css(styles.HomeExplainerSection_title)}>
                Powering great products like the&nbsp;
              </Header>
              <Header
                as="h2"
                className={css(
                  styles.HomeExplainerSection_title,
                  styles.HomeExplainerSection_titleHighlighted,
                )}
              >
                Defi Pulse Index
              </Header>
            </div>
            <p className={css(styles.HomeExplainerSection_description)}>
              Professionally-managed Sets like the DeFi Pulse Index make crypto diversification
              simple.
            </p>
            <LinkButton
              disableArrow
              customStyle={styles.Home_extraWideButton}
              to="/v2/set/0x1494CA1F11D487c2bBe4543E90080AeBa4BA3C2b"
              log={{ category: 'Home Page', label: 'View DPI CTA in Product Section' }}
            >
              View the DeFi Pulse Index
            </LinkButton>
          </div>
          <div className={css(styles.HomeExplainerSection_imageContainer)}>
            <div>
              <Link to="/v2/set/0x1494CA1F11D487c2bBe4543E90080AeBa4BA3C2b">
                <img
                  src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/dpi_card_2.svg"
                  className={css(styles.HomeExplainerSection_defiPulseImage)}
                  alt="The DeFi Pulse Index Set"
                />
              </Link>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default HomeProducts;
