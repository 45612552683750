import React from 'react';
import { css } from 'aphrodite';
import { Header } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';

import styles from './OnboardingStyles';

const WalletSetup = (props: WithTranslation) => {
  const { t } = props;

  return (
    <div className={css(styles.Onboarding_slide)}>
      <div className={css(styles.Onboarding_slideSection)}>
        <Header as="h1" className={css(styles.Onboarding_sectionTitle)}>
          {t('wallet-setup.title')}
        </Header>
        <ol className={css(styles.WalletSetup_list)}>
          <li className={css(styles.WalletSetup_listItem)}>
            <span className={css(styles.Onboarding_text, styles.WalletSetup_boldText)}>
              {t('wallet-setup.step-one-main')}
            </span>{' '}
            <span className={css(styles.Onboarding_text)}>
              {t('wallet-setup.step-one-secondary')}
            </span>
          </li>
          <li className={css(styles.WalletSetup_listItem)}>
            <span className={css(styles.Onboarding_text, styles.WalletSetup_boldText)}>
              {t('wallet-setup.step-two-main')}
            </span>{' '}
            <span className={css(styles.Onboarding_text)}>
              {t('wallet-setup.step-two-secondary')}
            </span>
          </li>
          <li className={css(styles.WalletSetup_listItem)}>
            <span className={css(styles.Onboarding_text, styles.WalletSetup_boldText)}>
              {t('wallet-setup.step-three-main')}
            </span>{' '}
            <span className={css(styles.Onboarding_text)}>
              {t('wallet-setup.step-three-secondary')}
            </span>
          </li>
        </ol>
      </div>
      <div className={css(styles.Onboarding_slideSection)}>
        <img
          alt="Track your Set"
          className={css(styles.WalletSetup_image)}
          src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/onboarding/using-phone.svg"
        />
      </div>
    </div>
  );
};

export default withTranslation('onboarding')(WalletSetup);
