import chunk from 'lodash/chunk';

import { TableDataSource, tableDisplayQuantityByDataSource } from '../../reducers/tableReducers';
import { SetCategory } from '../../reducers/exploreReducer';
import { IExploreRebalancingSet } from '../../typings/index';
import { omittedAddressesListSelector } from '../../selectors/tokenListsSelectors';
import { tokenSetsTokenListSelector } from '../../selectors/baseSelectors';

/** Pagination Helpers */

const getPaginatedDataSlice = (data: any[], dataPerPage: number, pageNumber: number) => {
  const chunkedData = chunk(data, dataPerPage);

  return chunkedData[pageNumber - 1];
};

/** Selectors */

export const setCategoryFilterSelector = (state: any): SetCategory => {
  return state.explore.filters.category;
};

export const selectedNetworksSelector = (state: any): string[] => {
  return state.explore.filters.selectedNetworks;
};

export const applyCategoryFilter = (setList: any[], category: SetCategory) => {
  return setList.filter((set: IExploreRebalancingSet) => category === set.category);
};

export const applyNetworkFilter = (setList: any[], networks: string[]) => {
  return setList.filter((set: IExploreRebalancingSet) => networks.includes(set.network));
};

export const applyAllFilters = (state: any, setList: any[]) => {
  const permittedCategory = setCategoryFilterSelector(state);
  const selectedNetworks = selectedNetworksSelector(state);

  let filteredSets = applyCategoryFilter(setList, permittedCategory);

  if (selectedNetworks) {
    filteredSets = applyNetworkFilter(filteredSets, selectedNetworks);
  }

  return filteredSets;
};

export const paginatedDeployedSetsSelector = (state: any) => {
  const {
    deployedSets: { deployedSetAddresses },
    tables: {
      customSets: { currentPageNumber }, //, isAscending, sortColumn },
    },
  } = state;
  const omittedAddressesList = omittedAddressesListSelector(state);

  const displayCount = tableDisplayQuantityByDataSource[TableDataSource.customSets];

  const newAndCleanedAddresses = deployedSetAddresses.filter(
    (address: string) => !omittedAddressesList[address],
  );

  return getPaginatedDataSlice(newAndCleanedAddresses, displayCount, currentPageNumber);
};

export const paginatedCommunitySetsSelector = (state: any) => {
  const {
    tables: {
      default: { currentPageNumber },
    },
  } = state;
  const tokenSetsTokenList = tokenSetsTokenListSelector(state);

  const displayCount = tableDisplayQuantityByDataSource[TableDataSource.communitySets];

  return getPaginatedDataSlice(tokenSetsTokenList, displayCount, currentPageNumber);
};
