import Web3 from 'web3';
import { createSelector } from 'reselect';
import { utils, BigNumber as EthersBigNumber } from 'ethers';
import { toChecksumAddress } from 'tochecksum';

import { ApprovalStatus } from '../containers/IssuanceApprovalV2/enums';
import {
  allApprovalsSelector,
  erc20BalancesSelector,
  issuanceInputQuantityV2Selector,
  setDetailsCurrentSetAddressSelector,
  debtIssuanceModuleEnabledSelector,
  debtIssuanceModuleV2EnabledSelector,
  requiredDebtIssuanceComponentsSelector,
  accountSelector,
} from '../selectors/baseSelectors';
import { currentSetTotalSupplySelector } from '../selectors/setDetailsSelectors';
import { DEFAULT_TOKEN_LIST_ENTRY } from '../constants/defaultParameters';
import { IDebtComponentWithToken } from '../typings/index';
import { BigNumber } from '../utils/index';
import { truncateEthAddress } from '../utils/formatUtils';
import { getWeb3Instance } from '../utils/web3Utils';
import { tokenFromBaseUnits } from '../utils/formatUtils';
import aumCaps from '../constants/aumCaps';
import debtIssuanceModuleABI from '../constants/abis/debtIssuanceModuleABI';
import { isSubmittingIssuanceTransactionV2Selector } from './baseSelectors';
import { GAS_BUFFER_MULTIPLIER } from '../constants/transactionOpts';
import { coingeckoTokenListByAddressSelector } from './tokenListsSelectors';
import { gasPriceTransactionOptionsSelector } from '.';
import {
  debtIssuanceModuleV1AddressSelector,
  debtIssuanceModuleV2AddressSelector,
} from './protocolAddressSelector';

interface IApprovalStatuses {
  [tokenId: string]: ApprovalStatus;
}

export const debtIssuanceModuleAddressSelector = (state: any): string => {
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);
  const debtIssuanceEnabledMap = debtIssuanceModuleEnabledSelector(state);
  const debtIssuanceModuleV1Address = debtIssuanceModuleV1AddressSelector(state);
  const debtIssuanceModuleV2Address = debtIssuanceModuleV2AddressSelector(state);

  if (debtIssuanceEnabledMap?.[currentSetAddress]) {
    return debtIssuanceModuleV1Address;
  } else {
    return debtIssuanceModuleV2Address;
  }
};

export const isDebtIssuanceModuleEnabledForCurrentSetSelector = (state: any): boolean => {
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);
  const debtIssuanceEnabledMap = debtIssuanceModuleEnabledSelector(state);
  const debtIssuanceV2EnabledMap = debtIssuanceModuleV2EnabledSelector(state);

  return (
    debtIssuanceEnabledMap?.[currentSetAddress] ||
    debtIssuanceV2EnabledMap?.[currentSetAddress] ||
    false
  );
};

export const allDebtIssuanceComponentsSelector = createSelector(
  requiredDebtIssuanceComponentsSelector,
  coingeckoTokenListByAddressSelector,
  (debtIssuanceComponents, tokenListByAddress): IDebtComponentWithToken[] => {
    const { componentAddresses, equityValues, debtValues } = debtIssuanceComponents || {};

    return componentAddresses?.map((address: string, index: number) => {
      const annotatedDefaultToken = {
        ...DEFAULT_TOKEN_LIST_ENTRY,
        address,
        symbol: truncateEthAddress(address),
        name: truncateEthAddress(address),
      };

      const tokenListEntry = tokenListByAddress[address.toLowerCase()] || annotatedDefaultToken;

      const equityValue = equityValues[index];
      const debtValue = debtValues[index];

      return {
        ...tokenListEntry,
        equityValue,
        debtValue,
      };
    });
  },
);

export const debtIssuanceContainsDebtPositionSelector = createSelector(
  requiredDebtIssuanceComponentsSelector,
  (debtIssuanceComponents): any => {
    const { debtValues } = debtIssuanceComponents || {};

    let containsDebt = false;
    debtValues.forEach((val: any) => {
      let debtValue = new BigNumber(val).toNumber();

      if (debtValue > 0) containsDebt = true;
    });

    return containsDebt;
  },
);

export const debtIssuanceInputComponentsSelector = (state: any): IDebtComponentWithToken[] => {
  const allComponents = allDebtIssuanceComponentsSelector(state);
  return allComponents?.filter((component: IDebtComponentWithToken) =>
    component.equityValue?.gt(0),
  );
};

export const debtIssuanceOutputComponentsSelector = (state: any): IDebtComponentWithToken[] => {
  const allComponents = allDebtIssuanceComponentsSelector(state);
  return allComponents?.filter((component: IDebtComponentWithToken) => component.debtValue?.gt(0));
};

export const allApprovalStatusesByIdForDebtIssuance = createSelector(
  debtIssuanceInputComponentsSelector,
  allApprovalsSelector,
  (requiredComponents, allApprovals) => {
    if (!requiredComponents || requiredComponents.length == 0) return {};

    const approvalStatuses: IApprovalStatuses = {};

    requiredComponents.forEach((token: IDebtComponentWithToken) => {
      const currentTokenApproval = allApprovals[toChecksumAddress(token.address)];

      if (currentTokenApproval?.isDebtIssuanceApproved) {
        approvalStatuses[toChecksumAddress(token.address)] = ApprovalStatus.APPROVED;
        return;
      }

      if (currentTokenApproval?.isDebtIssuancePending) {
        approvalStatuses[toChecksumAddress(token.address)] = ApprovalStatus.PENDING;
        return;
      }

      approvalStatuses[toChecksumAddress(token.address)] = ApprovalStatus.UNAPPROVED;
    });

    return approvalStatuses;
  },
);

export const allUnapprovedDebtIssuanceTokensSelector = (state: any): IDebtComponentWithToken[] => {
  const requiredComponents = debtIssuanceInputComponentsSelector(state);
  const allApprovalStatusesByTokenId = allApprovalStatusesByIdForDebtIssuance(state);

  return requiredComponents?.filter((token: IDebtComponentWithToken) => {
    return (
      allApprovalStatusesByTokenId[toChecksumAddress(token.address)] === ApprovalStatus.UNAPPROVED
    );
  });
};

export const hasAllDebtIssuanceApprovalsSelector = (state: any): boolean => {
  const requiredComponents = debtIssuanceInputComponentsSelector(state);
  const allApprovalStatusesByTokenId = allApprovalStatusesByIdForDebtIssuance(state);

  return requiredComponents?.every((token: IDebtComponentWithToken) => {
    return (
      allApprovalStatusesByTokenId[toChecksumAddress(token.address)] === ApprovalStatus.APPROVED
    );
  });
};

export const isAnyApprovalPendingForDebtIssuanceSelector = (state: any): boolean => {
  const requiredComponents = debtIssuanceInputComponentsSelector(state);
  const allApprovalStatusesByTokenId = allApprovalStatusesByIdForDebtIssuance(state);

  return requiredComponents?.some((token: IDebtComponentWithToken) => {
    return (
      allApprovalStatusesByTokenId[toChecksumAddress(token.address)] === ApprovalStatus.PENDING
    );
  });
};

export const userHasSufficientFundsForDebtIssuanceQuantity = (state: any): boolean => {
  const requiredComponents = debtIssuanceInputComponentsSelector(state);
  const userERC20Balances = erc20BalancesSelector(state);
  const rawIssuanceQuantity = issuanceInputQuantityV2Selector(state);

  return requiredComponents.every((component: IDebtComponentWithToken) => {
    const requiredQuantity = new BigNumber(component.equityValue.toString()).mul(
      rawIssuanceQuantity || 0,
    );
    const userBalance = userERC20Balances[Web3.utils.toChecksumAddress(component.address)];

    return userBalance.gt(0) && requiredQuantity.lte(userBalance || 0);
  });
};

export const debtIssuanceAumCapSelector = (state: any): BigNumber | null => {
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);

  return aumCaps[currentSetAddress] || null;
};

export const maxIssuableTokenQuantityByAumCapSelector = (state: any): BigNumber | null => {
  const issuanceCap = debtIssuanceAumCapSelector(state);
  const currentTotalSupply = currentSetTotalSupplySelector(state);

  if (!issuanceCap) return null;

  return tokenFromBaseUnits(issuanceCap.sub(currentTotalSupply).toString());
};

export const doesCurrentInputQuantityExceedAumCapSelector = (state: any): boolean => {
  const rawIssuanceQuantity = issuanceInputQuantityV2Selector(state);
  const maxIssuableQuantity = maxIssuableTokenQuantityByAumCapSelector(state);

  if (!maxIssuableQuantity) return false;

  return maxIssuableQuantity.lt(rawIssuanceQuantity || '0');
};

export const isDebtIssuanceReadySelector = (state: any): boolean => {
  const userHasSufficientFunds = userHasSufficientFundsForDebtIssuanceQuantity(state);
  const rawIssuanceQuantity = issuanceInputQuantityV2Selector(state);
  const exceedsAumCap = doesCurrentInputQuantityExceedAumCapSelector(state);
  const isSubmittingIssuanceTransaction = isSubmittingIssuanceTransactionV2Selector(state);

  return (
    !isSubmittingIssuanceTransaction &&
    userHasSufficientFunds &&
    !exceedsAumCap &&
    Number(rawIssuanceQuantity || '0') > 0
  );
};

export const requiredComponentsWithMaxDebtIssuableQuantitySelector = (state: any) => {
  const userBalances = erc20BalancesSelector(state);
  const requiredComponents = debtIssuanceInputComponentsSelector(state);

  return requiredComponents.map((component: IDebtComponentWithToken) => {
    const requiredComponentQuantityPerSet = component.equityValue.toString();
    const checksumAddress = Web3.utils.toChecksumAddress(component.address);
    const userBalance = userBalances[checksumAddress] || new BigNumber(0);

    const maxIssuableQuantity = userBalance.div(requiredComponentQuantityPerSet);

    return {
      ...component,
      maxIssuableQuantity,
    };
  });
};

export const maxDebtIssuableTokenQuantitySelector = (state: any): string => {
  const requiredComponents = requiredComponentsWithMaxDebtIssuableQuantitySelector(state);
  const maxIssuableQuantityByAumCap = maxIssuableTokenQuantityByAumCapSelector(state);
  const setContainsDebtPosition = debtIssuanceContainsDebtPositionSelector(state);

  // We apply an issuance buffer if the Set contains a debt position. This is because
  // debt positions are constantly accruing interest on a block-by-block basis.
  const issuanceBuffer = setContainsDebtPosition
    ? process.env.DEBT_ISSUANCE_MODULE_V2_ISSUANCE_BUFFER || 0
    : 0;

  if (!requiredComponents?.length) return;

  const tokenWithLowestIssuableQuantity = requiredComponents.reduce(
    (lowestToken: any, currentToken: any) => {
      if (currentToken.maxIssuableQuantity.lt(lowestToken.maxIssuableQuantity)) return currentToken;

      return lowestToken;
    },
    requiredComponents[0],
  );

  const maxIssuableQuantityWithAccruedInterestBuffer =
    tokenWithLowestIssuableQuantity?.maxIssuableQuantity
      ?.mul(new BigNumber(1).minus(issuanceBuffer))
      .toFixed(18, BigNumber.ROUND_DOWN) || '0';

  if (
    !maxIssuableQuantityByAumCap ||
    maxIssuableQuantityByAumCap.gte(maxIssuableQuantityWithAccruedInterestBuffer)
  ) {
    return maxIssuableQuantityWithAccruedInterestBuffer;
  }

  return maxIssuableQuantityByAumCap.toString();
};

export const issuanceInputQuantityAboveAumCap = (state: any) => {
  const maxIssuableByAumCap = maxIssuableTokenQuantityByAumCapSelector(state);
  const rawInputQuantity = issuanceInputQuantityV2Selector(state);

  return new BigNumber(rawInputQuantity || '0').sub(maxIssuableByAumCap || '0').toString();
};

export const createDebtIssueTransactionArgs = async (state: any) => {
  const setAddress = setDetailsCurrentSetAddressSelector(state);
  const userAddress = accountSelector(state);
  const rawIssuanceQuantity = issuanceInputQuantityV2Selector(state);
  const formattedIssuanceQuantity = utils.parseEther(
    rawIssuanceQuantity?.length ? rawIssuanceQuantity : '0',
  );
  const gasPriceTransactionOptions = gasPriceTransactionOptionsSelector(state);
  const debtIssuanceModuleAddress = debtIssuanceModuleAddressSelector(state);

  const web3Instance = await getWeb3Instance();

  const debtIssuanceModuleContract = new web3Instance.eth.Contract(
    debtIssuanceModuleABI as any,
    debtIssuanceModuleAddress,
  );

  const gasLimit = await debtIssuanceModuleContract.methods
    .issue(setAddress, formattedIssuanceQuantity, userAddress)
    .estimateGas({ from: userAddress });

  const gasLimitWithBuffer = new BigNumber(gasLimit).mul(GAS_BUFFER_MULTIPLIER || '1').toFixed(0);

  const transactionOpts = {
    ...gasPriceTransactionOptions,
    gasLimit: EthersBigNumber.from(gasLimitWithBuffer),
  };

  if (!setAddress || !userAddress || formattedIssuanceQuantity.lte(0)) return;

  return [setAddress, formattedIssuanceQuantity, userAddress, undefined, transactionOpts];
};
