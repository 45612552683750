import React, { PureComponent, createRef, Fragment } from 'react';
import SearchInput from 'react-search-input';
import { css } from 'aphrodite';
import { styles } from './styles';

import { createFilter } from 'react-search-input';
import { GraphSetToken, IHistory, IListToken } from '../../typings/index';
import { generateColorFromString } from '../../utils/colorUtils';
import _ from 'lodash';
import { toChecksumAddress } from 'tochecksum';

interface ISetSearchBarProps {
  isPolygon: boolean;
  customV2SetPathPrefix: string;
  maxWidth?: string;
  small?: boolean;
  account: string;
  history: IHistory;
  tokenSetsListMapping: { [address: string]: IListToken };
  isFetchingManagerTokens: boolean;
  managerTokens: GraphSetToken[];
  fetchManagerTokens: (...args: any[]) => any;
}

interface ISetSearchBarState {
  searchTerm: string;
  responseData: { data: { manager: any } };
  isActive: boolean;
  isLoading: boolean;
}

const KEYS_TO_FILTERS = ['address', 'name', 'symbol'];

class SetSearchBar extends PureComponent<ISetSearchBarProps, ISetSearchBarState> {
  constructor(props: ISetSearchBarProps) {
    super(props);
    this.state = {
      searchTerm: '',
      responseData: { data: { manager: null } },
      isActive: false,
      isLoading: false,
    };
  }

  wrapperRef = createRef<HTMLDivElement>();
  closeButtonRef = createRef<HTMLDivElement>();

  async componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);

    await this.fetchInitialSets();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  async fetchInitialSets() {
    const { fetchManagerTokens } = this.props;
    await fetchManagerTokens();
  }

  async componentDidUpdate(prevProps: ISetSearchBarProps) {
    if (
      (_.isEmpty(prevProps.account) && this.props.account) ||
      (_.isEmpty(this.props.account) && prevProps.account)
    ) {
      // Mark as loading
      this.setState({ searchTerm: '', isLoading: true });
    }
  }

  handleClickOutside = (e: any) => {
    const { isActive } = this.state;
    if (
      isActive &&
      this.wrapperRef &&
      !this.wrapperRef.current?.contains(e?.target) &&
      !this.closeButtonRef.current?.contains(e?.target)
    ) {
      this.setState({ isActive: false });
    }
  };

  searchUpdated = async (term: string) => {
    this.setState({ searchTerm: term, isLoading: false });
  };

  clickSearchbar = () => {
    this.setState({ isActive: true });
  };

  clearSearchBar = async () => {
    await this.fetchInitialSets();
    this.setState({ searchTerm: '', isActive: false });
  };

  render() {
    const { searchTerm, isActive, isLoading } = this.state;
    const {
      isPolygon,
      customV2SetPathPrefix,
      managerTokens,
      isFetchingManagerTokens,
      maxWidth,
      history,
      small,
      account,
      tokenSetsListMapping,
    } = this.props;

    const managerTokenAddresses = managerTokens?.map((token: any) => token.address);
    const allTokens = (((managerTokens as unknown) as IListToken[]) || []).concat(
      Object.values(tokenSetsListMapping)?.filter(
        (token: IListToken) => !managerTokenAddresses?.includes(token.address),
      ) || [],
    );

    const filteredSets: any = allTokens?.length
      ? allTokens.filter(createFilter(searchTerm, KEYS_TO_FILTERS))
      : [];
    let managerSeparatorRendered = false;
    let otherSeparatorRendered = false;
    let renderManagerSeparator = false;
    let renderOtherSeparator = false;

    const searchResults = filteredSets
      .sort((setDetails1: any, setDetails2: any) => {
        if (
          setDetails1.manager?.id === account.toLowerCase() &&
          setDetails2.manager?.id !== account.toLowerCase()
        ) {
          return -1;
        } else if (
          setDetails1.manager?.id !== account.toLowerCase() &&
          setDetails2.manager?.id == account.toLowerCase()
        ) {
          return 1;
        } else {
          return 0;
        }
      })
      .map((setDetail: any, i: number) => {
        const { address, name, symbol, manager } = setDetail;

        if (!managerSeparatorRendered && manager?.id === account.toLowerCase()) {
          renderManagerSeparator = true;
          managerSeparatorRendered = true;
        } else {
          renderManagerSeparator = false;
        }

        if (
          managerSeparatorRendered &&
          !otherSeparatorRendered &&
          manager?.id !== account.toLowerCase()
        ) {
          renderOtherSeparator = true;
          otherSeparatorRendered = true;
        } else {
          renderOtherSeparator = false;
        }

        const derivedSetColor = generateColorFromString(setDetail?.address || '');

        return (
          <Fragment key={`set-search-${setDetail.address}`}>
            {renderManagerSeparator && (
              <div className={css(styles.SetSearchBar_topSearchSeparator)}>Sets managed by you</div>
            )}
            {renderOtherSeparator && (
              <div className={css(styles.SetSearchBar_searchSeparator)}>Sets</div>
            )}
            <div className={css(styles.SetSearchBar_dropdownTable)} key={`search-${address}`}>
              <div
                className={css(
                  styles.SetSearchBar_dropdownElement,
                  small && styles.SetSearchBar_dropdownElement_small,
                )}
                style={{
                  width: '100%',
                  margin: 'auto',
                  ...(i === filteredSets.length - 1 && { borderBottomLeftRadius: '5px' }),
                  ...(filteredSets.length > 5 &&
                    i === filteredSets.length - 1 && { borderBottomRightRadius: '5px' }),
                }}
                onClick={() => {
                  history.push(`${customV2SetPathPrefix}/${toChecksumAddress(address)}`);
                  this.setState({ searchTerm: toChecksumAddress(address), isActive: false });
                }}
                role="checkbox"
                aria-checked="false"
                tabIndex={0}
              >
                <span>
                  <div
                    className={css(styles.SetSearchBar_setIcon)}
                    style={{ backgroundColor: `#${derivedSetColor}` }}
                  />
                </span>
                <span className={css(styles.SetSearchBar_address)}>
                  <div>
                    <div>
                      <span className={css(styles.SetSearchBar_nameElement)}>{name}</span>
                      <span className={css(styles.SetSearchBar_symbolElement)}> ({symbol})</span>
                    </div>
                    <div className={css(styles.SetSearchBar_addressElement)}>
                      {toChecksumAddress(address)}
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </Fragment>
        );
      });

    let defaultText = 'Loading...';
    if (!isLoading || isFetchingManagerTokens) {
      if (searchTerm.length) {
        defaultText = 'No Sets found';
      } else {
        defaultText = 'Enter an address above to see results';
      }
    }

    const noSearchResults = (
      <div className={css(styles.SetSearchBar_dropdownTable)}>
        <div
          className={css(
            styles.SetSearchBar_dropdownElement,
            styles.SetSearchBar_noResultsElement,
            small && styles.SetSearchBar_dropdownElement_small,
          )}
          style={{
            width: '100%',
            margin: 'auto',
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
          }}
        >
          <span className={css(styles.SetSearchBar_address)}>{defaultText}</span>
        </div>
      </div>
    );

    return (
      <div
        className={css(
          isPolygon ? styles.SetSearchBar_polygonTokenSearch : styles.SetSearchBar_tokenSearch,
        )}
        style={{
          maxWidth,
          ...(!isActive && { borderRadius: '25px' }),
          ...(isActive && { borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }),
        }}
      >
        <SearchInput
          fuzzy
          sortResults
          inputClassName={css(
            styles.SetSearchBar_searchInput,
            small && styles.SetSearchBar_searchInput_small,
          )}
          value={searchTerm}
          placeholder="Search by address, name, or symbol"
          onChange={this.searchUpdated}
          onClick={this.clickSearchbar}
        />
        {isActive && (
          <div
            ref={this.wrapperRef}
            className={css(
              styles.SetSearchBar_dropdownContainer,
              searchResults.length > 5 && styles.SetSearchBar_dropdownOverflow,
            )}
          >
            {searchResults.length > 0 ? searchResults : noSearchResults}
          </div>
        )}
        {isActive && (
          <span
            ref={this.closeButtonRef}
            className={css(
              styles.SetSearchBar_clearSearch,
              small && styles.SetSearchBar_clearSearch_small,
            )}
            onClick={this.clearSearchBar}
          >
            <div>ⓧ</div>
          </span>
        )}
      </div>
    );
  }
}

export default SetSearchBar;
