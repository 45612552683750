import React from 'react';

import { COLORS } from '../../constants/index';

interface IUnbundleIconProps {
  readonly className?: string;
  readonly color?: string;
}

const UnbundleIcon: React.SFC<IUnbundleIconProps> = ({ className, color }) => (
  <svg
    className={className}
    width="17px"
    height="19px"
    viewBox="0 0 17 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <desc>Set Labs. Unbundle Icon.</desc>
    <g id="Detail-/-Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-8" transform="translate(-42.792969, -113.000000)">
        <g id="Group-Copy" transform="translate(21.539062, 94.097656)">
          <g
            id="split-37"
            transform="translate(29.425781, 28.863281) rotate(-180.000000) translate(-29.425781, -28.863281) translate(21.425781, 19.863281)"
          >
            <path
              d="M3.414,2 L6,2 L6,0 L1,0 C0.448,0 0,0.448 0,1 L0,6 L2,6 L2,3.414 L7,8.414 L7,13 L9,13 L9,7.586 L3.414,2 Z"
              id="Shape"
              fill={color}
              fillRule="nonzero"
            />
            <circle
              id="Oval-2-Copy-2"
              stroke={color}
              strokeWidth="2"
              cx="8.08447266"
              cy="15.4750977"
              r="2.16748047"
            />
            <path
              d="M15,0 L10,0 L10,2 L12.586,2 L9.414,5.172 L10.828,6.586 L14,3.414 L14,6 L16,6 L16,1 C16,0.448 15.552,0 15,0 Z"
              id="Shape"
              fill={color}
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

UnbundleIcon.defaultProps = {
  className: '',
  color: COLORS.white,
};

export default UnbundleIcon;
