import { BigNumber } from '../utils/index';
import { tokenFromBaseUnits } from '../utils/formatUtils';
import { perpAccountInfoSelector } from './baseSelectors';
import { currentSetDetailsSelector } from './setDetailsSelectors';

export const perpSetTotalCollateralUnitsSelector = (state: any) => {
  const perpAccountInfo = perpAccountInfoSelector(state);
  const setDetails = currentSetDetailsSelector(state);

  // TODO: Consolidate this code
  return new BigNumber(tokenFromBaseUnits(perpAccountInfo?.collateralBalance?.toString() || '0'))
    .add(tokenFromBaseUnits(perpAccountInfo?.pendingFundingPayments?.toString() || '0'))
    .add(tokenFromBaseUnits(perpAccountInfo?.owedRealizedPnL?.toString() || '0'))
    .div(tokenFromBaseUnits(setDetails?.totalSupply?.toString() || '1'))
    .toString();
};
