import { NETWORK_CONSTANTS } from '../constants/index';
import {
  WETH_ADDRESS_ARBITRUM,
  WETH_ADDRESS_ETHEREUM,
  WETH_ADDRESS_OPTIMISM,
  WMATIC_ADDRESS_POLYGON,
  WAVAX_ADDRESS_AVALANCHE,
} from '../constants/tokenListConstants';
import {
  infuraArbitrumDomain,
  infuraDomain,
  infuraKey,
  infuraOptimismDomain,
  infuraPolygonDomain,
  rpcAvalancheDomain,
} from '../utils/providers';
import { supportedNetworkIds, urlIntendedChain } from '../utils/web3Utils';

import { currentChainSelector, networkIdSelector } from '.';

import polygonBadge from '../img/badges/polygon-badge.svg';
import ethereumBadge from '../img/badges/ethereum-badge.svg';
import arbitrumBadge from '../img/badges/arbitrum-badge.svg';
import optimismBadge from '../img/badges/optimism-badge.svg';
import avalancheBadge from '../img/badges/avalanche-badge.svg';

const {
  ETHEREUM_CHAIN,
  POLYGON_CHAIN,
  ARBITRUM_CHAIN,
  OPTIMISM_CHAIN,
  AVALANCHE_CHAIN,
  MAIN_NET_ID,
  POLYGON_MAINNET_ID,
  ARBITRUM_MAINNET_ID,
  OPTIMISM_MAINNET_ID,
  AVALANCHE_MAINNET_ID,
} = NETWORK_CONSTANTS;

export const isPolygonSelector = (state: any): boolean => {
  const currentChain = currentChainOrDefaultSelector(state);

  return currentChain === POLYGON_CHAIN;
};

export const isETHL1Selector = (state: any): boolean => {
  const currentChain = currentChainOrDefaultSelector(state);

  return currentChain === ETHEREUM_CHAIN;
};

export const isOptimismSelector = (state: any): boolean => {
  const currentChain = currentChainOrDefaultSelector(state);

  return currentChain === OPTIMISM_CHAIN;
};

export const isAvalancheSelector = (state: any): boolean => {
  const currentChain = currentChainOrDefaultSelector(state);

  return currentChain === AVALANCHE_CHAIN;
};

export const isETHCurrencyChainSelector = (state: any): boolean => {
  return isETHL1Selector(state) || isOptimismSelector(state);
};

export const isInfuraSupportedSelector = (state: any): boolean => {
  const currentChain = currentChainOrDefaultSelector(state);

  if (currentChain === AVALANCHE_CHAIN) {
    return false;
  }

  return true;
};

export const providerDomainSelector = (state: any): string => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case POLYGON_CHAIN:
      return infuraPolygonDomain;
    case ARBITRUM_CHAIN:
      return infuraArbitrumDomain;
    case OPTIMISM_CHAIN:
      return infuraOptimismDomain;
    case AVALANCHE_CHAIN:
      return rpcAvalancheDomain;
    default:
      return infuraDomain;
  }
};

export const currentMainnetChainIdSelector = (state: any): string => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case POLYGON_CHAIN:
      return POLYGON_MAINNET_ID;
    case ARBITRUM_CHAIN:
      return ARBITRUM_MAINNET_ID;
    case OPTIMISM_CHAIN:
      return OPTIMISM_MAINNET_ID;
    case AVALANCHE_CHAIN:
      return AVALANCHE_MAINNET_ID;
    default:
      return MAIN_NET_ID;
  }
};

export const httpProviderHostSelector = (state: any): string => {
  const providerDomain = providerDomainSelector(state);
  const isInfuraSupported = isInfuraSupportedSelector(state);

  if (isInfuraSupported) {
    return `${providerDomain}${infuraKey}`;
  } else {
    return `${providerDomain}`;
  }
};

export const isWrongNetworkSelector = (state: any): boolean => {
  const networkId = networkIdSelector(state);

  return !supportedNetworkIds().includes(networkId) && networkId !== undefined;
};

// Returns either the currentChain stored in redux or the supposed default chain based on the current url
// if they are not logged in
export const currentChainOrDefaultSelector = (state: any): string => {
  const currentChain = currentChainSelector(state);

  return currentChain || urlIntendedChain();
};

// Returns the intended chain based on the url or the current logged in provider. Different
// from currentChainOrDefaultSelector in that it follows an OR approach to something like
// isPolygon || isPolygonUrl whereas currentChainOrDefaultSelector looks for provider network first
// and then falls back to url-based default.
export const intendedChainSelector = (state: any): string => {
  const currentChain = currentChainSelector(state);
  const intendedUrl = urlIntendedChain();

  if (currentChain === POLYGON_CHAIN || intendedUrl === POLYGON_CHAIN) {
    return POLYGON_CHAIN;
  } else if (currentChain === ARBITRUM_CHAIN || intendedUrl === ARBITRUM_CHAIN) {
    return ARBITRUM_CHAIN;
  } else if (currentChain === OPTIMISM_CHAIN || intendedUrl === OPTIMISM_CHAIN) {
    return OPTIMISM_CHAIN;
  } else if (currentChain === AVALANCHE_CHAIN || intendedUrl === AVALANCHE_CHAIN) {
    return AVALANCHE_CHAIN;
  } else {
    return ETHEREUM_CHAIN;
  }
};

export const customV2SetPathPrefixSelector = (state: any): string => {
  const currentChain = currentChainOrDefaultSelector(state);

  let url;
  switch (currentChain) {
    case POLYGON_CHAIN:
      url = '/v2/set/polygon';
      break;
    case ARBITRUM_CHAIN:
      url = '/v2/set/arbitrum';
      break;
    case OPTIMISM_CHAIN:
      url = '/v2/set/optimism';
      break;
    case AVALANCHE_CHAIN:
      url = '/v2/set/avalanche';
      break;
    default:
      url = '/v2/set';
  }

  return url;
};

export const customSetPathPrefixSelector = (state: any): string => {
  const currentChain = currentChainOrDefaultSelector(state);

  let url;
  switch (currentChain) {
    case POLYGON_CHAIN:
      url = '/set/polygon';
      break;
    case ARBITRUM_CHAIN:
      url = '/set/arbitrum';
      break;
    case OPTIMISM_CHAIN:
      url = '/set/optimism';
      break;
    case AVALANCHE_CHAIN:
      url = '/set/avalanche';
      break;
    default:
      url = '/set';
  }

  return url;
};

export const portfolioPathPrefixSelector = (state: any): string => {
  const currentChain = currentChainOrDefaultSelector(state);

  let url;
  switch (currentChain) {
    case POLYGON_CHAIN:
      url = '/portfolio/polygon';
      break;
    case ARBITRUM_CHAIN:
      url = '/portfolio/arbitrum';
      break;
    case OPTIMISM_CHAIN:
      url = '/portfolio/optimism';
      break;
    case AVALANCHE_CHAIN:
      url = '/portfolio/avalanche';
      break;
    default:
      url = '/portfolio';
  }

  return url;
};

// Returns the header used in the Explore table section
export const exploreHeaderSelector = (state: any): string => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case POLYGON_CHAIN:
      return 'Find a Polygon Set';
    case ARBITRUM_CHAIN:
      return 'Find an Arbitrum Set';
    case OPTIMISM_CHAIN:
      return 'Find an Optimism Set';
    case AVALANCHE_CHAIN:
      return 'Find an Avalanche Set';
    default:
      return 'Find an Ethereum Set';
  }
};

// Returns the current capitalized chain name
export const chainNameSelector = (state: any): string => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case POLYGON_CHAIN:
      return 'Polygon';
    case ARBITRUM_CHAIN:
      return 'Arbitrum';
    case OPTIMISM_CHAIN:
      return 'Optimism';
    case AVALANCHE_CHAIN:
      return 'Avalanche';
    default:
      return 'Ethereum';
  }
};

export const chainCurrencySelector = (state: any): string => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case POLYGON_CHAIN:
      return 'MATIC';
    case ARBITRUM_CHAIN:
      return 'ETH';
    case OPTIMISM_CHAIN:
      return 'ETH';
    case AVALANCHE_CHAIN:
      return 'AVAX';
    default:
      return 'ETH';
  }
};

export const wrappedCurrencySelector = (state: any): string => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case POLYGON_CHAIN:
      return 'WMATIC';
    case ARBITRUM_CHAIN:
      return 'WETH';
    case OPTIMISM_CHAIN:
      return 'WETH';
    case AVALANCHE_CHAIN:
      return 'WAVAX';
    default:
      return 'WETH';
  }
};

export const networkBadgeSelector = (state: any): string => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case POLYGON_CHAIN:
      return polygonBadge;
    case ARBITRUM_CHAIN:
      return arbitrumBadge;
    case OPTIMISM_CHAIN:
      return optimismBadge;
    case AVALANCHE_CHAIN:
      return avalancheBadge;
    default:
      return ethereumBadge;
  }
};

export const wrappedCurrencyAddressSelector = (state: any): string => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case POLYGON_CHAIN:
      return WMATIC_ADDRESS_POLYGON;
    case ARBITRUM_CHAIN:
      return WETH_ADDRESS_ARBITRUM;
    case OPTIMISM_CHAIN:
      return WETH_ADDRESS_OPTIMISM;
    case AVALANCHE_CHAIN:
      return WAVAX_ADDRESS_AVALANCHE;
    default:
      return WETH_ADDRESS_ETHEREUM;
  }
};

export const coingeckoPlatformSelector = (state: any): string => {
  const currentChain = currentChainOrDefaultSelector(state);

  switch (currentChain) {
    case POLYGON_CHAIN:
      return 'polygon-pos';
    case ARBITRUM_CHAIN:
      return 'arbitrum-one';
    case OPTIMISM_CHAIN:
      return 'optimistic-ethereum';
    case AVALANCHE_CHAIN:
      return 'avalanche';
    default:
      return 'ethereum';
  }
};
