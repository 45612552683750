import React, { PureComponent } from 'react';
import { Grid, Table } from 'semantic-ui-react';
import { css } from 'aphrodite';
import { toChecksumAddress } from 'tochecksum';

import styles from './CustomSetExploreTableStyles';
import {
  IWindowDimensions,
  IHistory,
  IListToken,
  ISetDetailsHydrated,
  PerpLeverageData,
} from '../../typings/index';
import { CustomSetComponentAllocation } from '../../components/index';
import { handleCtrlClick } from '../../utils/browserUtils';
import _ from 'lodash';
import { VAssetDisplayInfo } from 'set.js/dist/types/src/types';
import { truncateEthAddress } from 'utils';
import { toFixed2Locale, tokenFromBaseUnits } from 'utils/formatUtils';

interface ICustomSetExploreTableRowProps {
  customV2SetPathPrefix: string;
  address: string;
  history?: IHistory;
  isShowingAdvancedData?: boolean;
  windowDimension: IWindowDimensions;
  setDetails?: { [address: string]: ISetDetailsHydrated };
  tokenSetsTokenListByAddress: { [tokenAddress: string]: IListToken };
  tokenList: { [tokenAddress: string]: IListToken };
  vAssetDisplayInfo?: VAssetDisplayInfo[];
  coingeckoTokenListBySymbol?: { [symbol: string]: IListToken };
  onFetchEnabledModules: (...args: any[]) => any;
  fetchAllPerpLeverageData: (...args: any[]) => PerpLeverageData;
}

interface ICustomSetExploreTableRowState {
  moduleStatuses?: { [key: string]: boolean };
  perpLeverageData?: PerpLeverageData;
}

export default class CustomSetExploreTableRow extends PureComponent<
  ICustomSetExploreTableRowProps,
  ICustomSetExploreTableRowState
> {
  state: ICustomSetExploreTableRowState = {
    moduleStatuses: {},
    perpLeverageData: undefined,
  };

  componentDidMount = async () => {
    const { address, fetchAllPerpLeverageData, onFetchEnabledModules } = this.props;

    const checksummedAddress = toChecksumAddress(address);

    const moduleStatuses = await onFetchEnabledModules(checksummedAddress);

    if (moduleStatuses.isPerpV2LeverageEnabled || moduleStatuses.isPerpV2BasisTradingEnabled) {
      const perpLeverageData = await fetchAllPerpLeverageData(
        checksummedAddress,
        moduleStatuses.isPerpV2BasisTradingEnabled,
      );
      this.setState({
        perpLeverageData,
      });
    }

    this.setState({
      moduleStatuses,
    });
  };

  public viewTargetSet = (e: any, address: string) => {
    const { history, customV2SetPathPrefix } = this.props;
    const target = `${customV2SetPathPrefix}/${address}`;

    handleCtrlClick(e, target, history);
  };

  render() {
    const {
      address,
      isShowingAdvancedData,
      setDetails,
      tokenList,
      tokenSetsTokenListByAddress,
      coingeckoTokenListBySymbol,
      windowDimension: { isMobile, isTabletLandscape },
    } = this.props;
    const { moduleStatuses, perpLeverageData } = this.state;

    const setCreatorIcon =
      'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-set-token-icon.svg';

    const withoutTableBorderStyle = !isShowingAdvancedData
      ? styles.CustomSetExploreTable_rowCellWithoutTableBorder
      : undefined;

    const details = setDetails[address];

    if (!details) {
      return null;
    }

    if (isTabletLandscape) {
      return (
        <Grid.Row
          className={css(styles.CustomSetExploreTable_tableRow)}
          onClick={(e: any) => this.viewTargetSet(e, address)}
        >
          <div className={css(styles.CustomSetExploreTable_setNameCell)}>
            <div className={css(styles.CustomSetExploreTable_setNameAndImage)}>
              <img
                src={tokenSetsTokenListByAddress[address.toLowerCase()]?.logoURI || setCreatorIcon}
                className={css(styles.CustomSetExploreTable_metaUserImage)}
              />
              <div className={css(styles.CustomSetExploreTable_setNameFormatter)}>
                {tokenSetsTokenListByAddress[address.toLowerCase()]?.name || details.name}
              </div>
            </div>
            {isShowingAdvancedData && !_.isEmpty(tokenList) && (
              <CustomSetComponentAllocation
                truncateTo={isMobile ? 1 : 3}
                details={details}
                tokenList={tokenList}
                customStyles={styles.CustomSetExploreTable_componentAllocation}
                moduleStatuses={moduleStatuses}
                perpLeverageData={perpLeverageData}
                coingeckoTokenListBySymbol={coingeckoTokenListBySymbol}
              />
            )}
          </div>
        </Grid.Row>
      );
    }
    return (
      <Table.Body
        className={css(styles.CustomSetExploreTable_tableRow)}
        onClick={(e: any) => this.viewTargetSet(e, address)}
      >
        <Table.Row>
          <Table.Cell
            className={css(styles.CustomSetExploreTable_setNameCell, withoutTableBorderStyle)}
          >
            <img
              src={tokenSetsTokenListByAddress[address.toLowerCase()]?.logoURI || setCreatorIcon}
              className={css(styles.CustomSetExploreTable_metaUserImage)}
            />
            <div className={css(styles.CustomSetExploreTable_setNameFormatter)}>
              {tokenSetsTokenListByAddress[address.toLowerCase()]?.name || details.name}
            </div>
          </Table.Cell>
          <Table.Cell
            className={css(styles.CustomSetExploreTable_bodyCell, withoutTableBorderStyle)}
          >
            {details.symbol}
          </Table.Cell>
          <Table.Cell
            className={css(styles.CustomSetExploreTable_bodyCell, withoutTableBorderStyle)}
          >
            {details?.totalSupply
              ? toFixed2Locale(tokenFromBaseUnits(details?.totalSupply?.toString()))
              : '-'}
          </Table.Cell>
          <Table.Cell
            className={css(styles.CustomSetExploreTable_bodyCell, withoutTableBorderStyle)}
          >
            {tokenSetsTokenListByAddress[address.toLowerCase()]?.managerName ||
              (details?.manager ? truncateEthAddress(details?.manager) : '')}
          </Table.Cell>
        </Table.Row>
        {isShowingAdvancedData && !_.isEmpty(tokenList) && (
          <Table.Row>
            <Table.Cell
              className={css(styles.CustomSetExploreTable_secondaryBodyCell)}
            ></Table.Cell>
            <Table.Cell
              colSpan="13"
              className={css(styles.CustomSetExploreTable_secondaryBodyCell)}
            >
              <CustomSetComponentAllocation
                truncateTo={4}
                details={details}
                tokenList={tokenList}
                moduleStatuses={moduleStatuses}
                perpLeverageData={perpLeverageData}
                coingeckoTokenListBySymbol={coingeckoTokenListBySymbol}
              />
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    );
  }
}
