import Web3 from 'web3';
import { createSelector } from 'reselect';
import { utils, BigNumber as EthersBigNumber } from 'ethers';
import { toChecksumAddress } from 'tochecksum';
import {
  allApprovalsSelector,
  erc20BalancesSelector,
  setDetailsCurrentSetAddressSelector,
  requiredDebtRedemptionComponentsSelector,
  redemptionInputQuantityV2Selector,
  accountSelector,
  isSubmittingRedemptionTransactionV2Selector,
} from '../selectors/baseSelectors';
import { currentSetDetailsSelector } from '../selectors/setDetailsSelectors';
import { BigNumber } from '../utils/index';
import { truncateEthAddress } from '../utils/formatUtils';
import { IListToken, IDebtComponentWithToken } from '../typings/index';
import { DEFAULT_TOKEN_LIST_ENTRY } from '../constants/defaultParameters';
import { ApprovalStatus } from '../containers/IssuanceApprovalV2/enums';
import { getWeb3Instance } from '../utils/web3Utils';
import debtIssuanceModuleABI from '../constants/abis/debtIssuanceModuleABI';
import networkConstants from '../constants/networkConstants';
import { GAS_BUFFER_MULTIPLIER } from '../constants/transactionOpts';
import { coingeckoTokenListSelector } from './tokenListsSelectors';
import { debtIssuanceModuleAddressSelector } from './debtIssuanceSelectors';
import { gasPriceTransactionOptionsSelector } from '.';

interface IApprovalStatuses {
  [tokenId: string]: ApprovalStatus;
}

/* Represents the current Set as a debt component. Used for debt redemption approvals + form */
export const currentSetDetailsAsDebtComponentWithTokenSelector = createSelector(
  currentSetDetailsSelector,
  setDetailsCurrentSetAddressSelector,
  (currentSetDetails, currentSetAddress): IDebtComponentWithToken => {
    return {
      ...DEFAULT_TOKEN_LIST_ENTRY,
      address: currentSetAddress,
      chainId: networkConstants.ETHEREUM_ENV_NETWORK,
      decimals: '18',
      name: currentSetDetails?.name,
      symbol: currentSetDetails?.symbol,
      equityValue: EthersBigNumber.from(0),
      debtValue: EthersBigNumber.from(10).pow(18),
    };
  },
);

export const allDebtRedemptionComponentsSelector = createSelector(
  requiredDebtRedemptionComponentsSelector,
  coingeckoTokenListSelector,
  currentSetDetailsAsDebtComponentWithTokenSelector,
  (debtRedemptionComponents, coingeckoTokenList, currentSet): IDebtComponentWithToken[] => {
    const { componentAddresses, equityValues, debtValues } = debtRedemptionComponents || {};

    const debtComponents = componentAddresses?.map((address: string, index: number) => {
      const annotatedDefaultToken = {
        ...DEFAULT_TOKEN_LIST_ENTRY,
        address,
        symbol: truncateEthAddress(address),
        name: truncateEthAddress(address),
      };

      const tokenListEntry =
        coingeckoTokenList?.find(
          (token: IListToken) => token?.address?.toLowerCase() === address?.toLowerCase(),
        ) || annotatedDefaultToken;

      const equityValue = equityValues[index];
      const debtValue = debtValues[index];

      return {
        ...tokenListEntry,
        equityValue,
        debtValue,
      };
    });

    return debtComponents ? [currentSet].concat(debtComponents) : [currentSet];
  },
);

export const debtRedemptionContainsDebtPositionSelector = createSelector(
  requiredDebtRedemptionComponentsSelector,
  (debtIssuanceComponents): any => {
    const { debtValues } = debtIssuanceComponents || {};

    let containsDebt = false;
    debtValues.forEach((val: any) => {
      let debtValue = new BigNumber(val).toNumber();

      if (debtValue > 0) containsDebt = true;
    });

    return containsDebt;
  },
);

export const debtRedemptionInputComponentsSelector = (state: any): IDebtComponentWithToken[] => {
  const allComponents = allDebtRedemptionComponentsSelector(state);

  return allComponents?.filter((component: IDebtComponentWithToken) => component.debtValue?.gt(0));
};

export const debtRedemptionOutputComponentsSelector = (state: any): IDebtComponentWithToken[] => {
  const allComponents = allDebtRedemptionComponentsSelector(state);
  return allComponents?.filter((component: IDebtComponentWithToken) =>
    component.equityValue?.gt(0),
  );
};

// The set token is required for redemption, but not required for approvals.
export const approvalRequiredDebtRedemptionComponents = (state: any): IDebtComponentWithToken[] => {
  const inputComponents = debtRedemptionInputComponentsSelector(state);
  const currentSet = currentSetDetailsAsDebtComponentWithTokenSelector(state);

  return inputComponents?.filter(
    (component: IDebtComponentWithToken) =>
      component?.address?.toLowerCase() !== currentSet?.address?.toLowerCase(),
  );
};

export const allApprovalStatusesByIdForDebtRedemption = createSelector(
  approvalRequiredDebtRedemptionComponents,
  allApprovalsSelector,
  (requiredComponents, allApprovals) => {
    if (!requiredComponents || requiredComponents.length == 0) return {};

    const approvalStatuses: IApprovalStatuses = {};

    requiredComponents.forEach((token: IDebtComponentWithToken) => {
      const currentTokenApproval = allApprovals[toChecksumAddress(token.address)];

      if (currentTokenApproval?.isDebtIssuanceApproved) {
        approvalStatuses[toChecksumAddress(token.address)] = ApprovalStatus.APPROVED;
        return;
      }

      if (currentTokenApproval?.isDebtIssuancePending) {
        approvalStatuses[toChecksumAddress(token.address)] = ApprovalStatus.PENDING;
        return;
      }

      approvalStatuses[toChecksumAddress(token.address)] = ApprovalStatus.UNAPPROVED;
    });

    return approvalStatuses;
  },
);

export const allUnapprovedDebtRedemptionTokensSelector = (
  state: any,
): IDebtComponentWithToken[] => {
  const requiredComponents = approvalRequiredDebtRedemptionComponents(state);
  const allApprovalStatusesByTokenId = allApprovalStatusesByIdForDebtRedemption(state);

  return requiredComponents?.filter((token: IDebtComponentWithToken) => {
    return (
      allApprovalStatusesByTokenId[toChecksumAddress(token.address)] === ApprovalStatus.UNAPPROVED
    );
  });
};

export const hasAllDebtRedemptionApprovalsSelector = (state: any): boolean => {
  const requiredComponents = approvalRequiredDebtRedemptionComponents(state);
  const allApprovalStatusesByTokenId = allApprovalStatusesByIdForDebtRedemption(state);

  return requiredComponents?.every((token: IDebtComponentWithToken) => {
    return (
      allApprovalStatusesByTokenId[toChecksumAddress(token.address)] === ApprovalStatus.APPROVED
    );
  });
};

export const isAnyApprovalPendingForDebtRedemptionSelector = (state: any): boolean => {
  const requiredComponents = approvalRequiredDebtRedemptionComponents(state);
  const allApprovalStatusesByTokenId = allApprovalStatusesByIdForDebtRedemption(state);

  return requiredComponents?.some((token: IDebtComponentWithToken) => {
    return (
      allApprovalStatusesByTokenId[toChecksumAddress(token.address)] === ApprovalStatus.PENDING
    );
  });
};

export const userHasSufficientFundsForDebtRedemptionQuantity = (state: any): boolean => {
  const requiredComponents = debtRedemptionInputComponentsSelector(state);
  const userERC20Balances = erc20BalancesSelector(state);
  const rawRedemptionQuantity = redemptionInputQuantityV2Selector(state);

  return requiredComponents.every((component: IDebtComponentWithToken) => {
    const requiredQuantity = new BigNumber(component.debtValue.toString()).mul(
      rawRedemptionQuantity || 0,
    );
    const userBalance = userERC20Balances[Web3.utils.toChecksumAddress(component.address)];

    return userBalance.gt(0) && requiredQuantity.lte(userBalance || 0);
  });
};

export const isDebtRedemptionReadySelector = (state: any): boolean => {
  const userHasSufficientFunds = userHasSufficientFundsForDebtRedemptionQuantity(state);
  const rawRedemptionQuantity = redemptionInputQuantityV2Selector(state);
  const isSubmittingTransaction = isSubmittingRedemptionTransactionV2Selector(state);

  return userHasSufficientFunds && !isSubmittingTransaction && Number(rawRedemptionQuantity) > 0;
};

export const requiredComponentsWithMaxDebtIssuableQuantitySelector = (state: any) => {
  const userBalances = erc20BalancesSelector(state);
  const requiredComponents = debtRedemptionInputComponentsSelector(state);

  return requiredComponents.map((component: IDebtComponentWithToken) => {
    const requiredComponentQuantityPerSet = component.debtValue.toString();
    const checksumAddress = Web3.utils.toChecksumAddress(component.address);
    const userBalance = userBalances[checksumAddress] || new BigNumber(0);

    const maxRedeemableQuantity = userBalance.div(requiredComponentQuantityPerSet);

    return {
      ...component,
      maxRedeemableQuantity,
    };
  });
};

export const maxDebtRedeemableTokenQuantitySelector = (state: any): string => {
  const requiredComponents = requiredComponentsWithMaxDebtIssuableQuantitySelector(state);
  const setContainsDebtPosition = debtRedemptionContainsDebtPositionSelector(state);

  // We apply an redemption buffer if the Set contains a debt position. This is because
  // debt positions are constantly accruing interest on a block-by-block basis.
  const DEBT_ISSUANCE_MODULE_V2_REDEMPTION_BUFFER = 0.0001;
  const redemptionBuffer = setContainsDebtPosition
    ? DEBT_ISSUANCE_MODULE_V2_REDEMPTION_BUFFER || 0
    : 0;

  if (!requiredComponents?.length) return;

  const tokenWithLowestRedeemableQuantity = requiredComponents.reduce(
    (lowestToken: any, currentToken: any) => {
      if (currentToken.maxRedeemableQuantity.lt(lowestToken.maxRedeemableQuantity))
        return currentToken;

      return lowestToken;
    },
    requiredComponents[0],
  );

  const maxRedeemableQuantityWithAccruedInterestBuffer =
    tokenWithLowestRedeemableQuantity?.maxRedeemableQuantity
      ?.mul(new BigNumber(1).minus(redemptionBuffer))
      .toFixed(18, BigNumber.ROUND_DOWN) || '0';

  return maxRedeemableQuantityWithAccruedInterestBuffer;
};

export const createDebtRedeemTransactionArgs = async (state: any) => {
  const setAddress = setDetailsCurrentSetAddressSelector(state);
  const userAddress = accountSelector(state);
  const rawRedemptionQuantity = redemptionInputQuantityV2Selector(state);
  const formattedRedemptionQuantity = utils.parseEther(
    rawRedemptionQuantity?.length ? rawRedemptionQuantity : '0',
  );
  const gasPriceTransactionOptions = gasPriceTransactionOptionsSelector(state);
  const debtIssuanceModuleAddress = debtIssuanceModuleAddressSelector(state);

  const web3Instance = await getWeb3Instance();
  const debtIssuanceModuleContract = new web3Instance.eth.Contract(
    debtIssuanceModuleABI as any,
    debtIssuanceModuleAddress,
  );

  const gasLimit = await debtIssuanceModuleContract.methods
    .redeem(setAddress, formattedRedemptionQuantity, userAddress)
    .estimateGas({ from: userAddress });

  const gasLimitWithBuffer = new BigNumber(gasLimit).mul(GAS_BUFFER_MULTIPLIER || '1').toFixed(0);

  const transactionOpts = {
    ...gasPriceTransactionOptions,
    gasLimit: EthersBigNumber.from(gasLimitWithBuffer),
  };

  if (!setAddress || !userAddress || formattedRedemptionQuantity.lte(0)) return;

  return [setAddress, formattedRedemptionQuantity, userAddress, undefined, transactionOpts];
};
