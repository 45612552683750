import React from 'react';
import { css } from 'aphrodite';
import { Grid } from 'semantic-ui-react';
import { CSSTransition } from 'react-transition-group';

import { Text } from '../../components/index';
import { styles } from './Account';
import { IRebalancingSet, IWindowDimensions } from '../../typings/index';
import { truncateTokenAmount } from '../../utils/formatUtils';

interface ISetTokenComponentsProps {
  isShowing: boolean;
  set: IRebalancingSet;
  windowDimension: IWindowDimensions;
}

const SetTokenComponents = (props: ISetTokenComponentsProps) => {
  const {
    set,
    isShowing,
    windowDimension: { isMobile },
  } = props;

  return (
    <CSSTransition in={isShowing} classNames="fade" timeout={300} unmountOnExit>
      <Grid className={css(styles.Account_expandedDetailsGrid)}>
        {set?.components?.map(component => {
          return (
            <Grid.Row
              className={css(styles.Account_expandedDetails)}
              key={`component-details-${component.symbol}`}
            >
              <Grid.Column className={css(styles.Account_cellContentContainer)} width={10}>
                <div className={css(styles.Account_setIconContainer)}>
                  <img
                    className={css(styles.Account_setIcon)}
                    src={component.image}
                    alt="Component logo"
                  />
                </div>
                <Text
                  color="darkBlue"
                  className={css(styles.Account_setTableText, styles.Account_setName)}
                >
                  {isMobile ? component.symbol : component.name}
                </Text>
              </Grid.Column>
              <Grid.Column
                className={css(styles.Account_innerCellContentContainer)}
                floated="right"
                textAlign={isMobile ? 'right' : 'left'}
                width={isMobile ? 6 : 3}
              >
                <Text className={css(styles.Account_setTableText)} color="darkBlue" tag="p">
                  {component.balance}
                </Text>
                {isMobile && (
                  <Text className={css(styles.Account_setTableText)} color="darkGray" tag="p">
                    {truncateTokenAmount(component.amount)}
                  </Text>
                )}
              </Grid.Column>
              {!isMobile && (
                <Grid.Column
                  className={css(styles.Account_innerCellContentContainerForAmount)}
                  floated="right"
                  textAlign="left"
                  width={3}
                >
                  <Text className={css(styles.Account_setTableText)} color="darkBlue" tag="p">
                    {truncateTokenAmount(component.amount)}
                  </Text>
                </Grid.Column>
              )}
            </Grid.Row>
          );
        })}
      </Grid>
    </CSSTransition>
  );
};

export default SetTokenComponents;
