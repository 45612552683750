import axios from 'axios';
import every from 'lodash/every';
import { toChecksumAddress } from 'tochecksum';
import { emptyActionGenerator, payloadActionGenerator } from '../utils/reduxHelpers';
import { BigNumber, faultTolerantPromise } from '../utils/index';
import { BigNumber as EthersBigNumber } from 'ethers';
import { userRejectedMetamaskTransaction, getWeb3Instance } from '../utils/web3Utils';
import { ITradingPair, ITokenBalances, IListTokenWithPosition } from '../typings/index';
import { currentMainnetChainIdSelector, currentRebalancingSetSelector } from '../selectors/index';
import { currentSetComponentsSelector } from '../selectors/setDetailsSelectors';
import {
  managerTokensSelector,
  setDetailsCurrentSetAddressSelector,
} from '../selectors/baseSelectors';
import { isEmpty } from 'lodash';
import {
  geminiTokenListByAddressSelector,
  tokenSetsTokenListByAddressSelector,
} from '../selectors/tokenListsSelectors';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { TRANSFER_PROXY_ADDRESS } from '../constants/setJSConfig';

export const REQUEST_BALANCES = 'REQUEST_BALANCES';
export const RECEIVE_BALANCES = 'RECEIVE_BALANCES';
export const RECEIVE_BALANCES_FAILED = 'RECEIVE_BALANCES_FAILED';

export const UPDATE_ACCOUNT_BALANCE = 'UPDATE_ACCOUNT_BALANCE';

export const SET_IS_FETCHING_BALANCE_STATUS = 'SET_IS_FETCHING_BALANCE_STATUS';
export const UPDATE_ERC_20_BALANCE = 'UPDATE_ERC_20_BALANCE';
export const UPDATE_SET_TOKEN_BALANCE = 'UPDATE_SET_TOKEN_BALANCE';

export const REQUEST_ERC20_ALLOWANCE = 'REQUEST_ERC20_ALLOWANCE';
export const RECEIVE_ERC20_ALLOWANCE = 'RECEIVE_ERC20_ALLOWANCE';
export const SET_IS_APPROVING_ALLOWANCE_STATUS = 'SET_IS_APPROVING_ALLOWANCE_STATUS';

export const RECEIVE_FUND_ONCHAIN_BALANCE = 'RECEIVE_FUND_ONCHAIN_BALANCE';
export const RECEIVE_COIN_ONCHAIN_BALANCE = 'RECEIVE_COIN_ONCHAIN_BALANCE';

export const requestBalances = emptyActionGenerator(REQUEST_BALANCES);
export const receiveBalances = payloadActionGenerator(RECEIVE_BALANCES);
export const receiveBalancesFailed = emptyActionGenerator(RECEIVE_BALANCES_FAILED);

export const updateAccountBalanceAction = payloadActionGenerator(UPDATE_ACCOUNT_BALANCE);

export const setIsFetchingBalanceStatus = payloadActionGenerator(SET_IS_FETCHING_BALANCE_STATUS);
export const updateERC20Balance = payloadActionGenerator(UPDATE_ERC_20_BALANCE);
export const updateSetTokenBalance = payloadActionGenerator(UPDATE_SET_TOKEN_BALANCE);

export const requestERC20Allowance = emptyActionGenerator(REQUEST_ERC20_ALLOWANCE);
export const receiveERC20Allowance = payloadActionGenerator(RECEIVE_ERC20_ALLOWANCE);
export const setIsApprovingAllowanceStatus = payloadActionGenerator(
  SET_IS_APPROVING_ALLOWANCE_STATUS,
);

export const receiveFundOnchainBalance = payloadActionGenerator(RECEIVE_FUND_ONCHAIN_BALANCE);
export const receiveCoinOnchainBalance = payloadActionGenerator(RECEIVE_COIN_ONCHAIN_BALANCE);

export function updateAccountBalance() {
  return async (dispatch: Function, getState: Function) => {
    const {
      web3: { account },
    } = getState();
    let balance = '0';
    const web3Instance = await getWeb3Instance();

    try {
      if (web3Instance && account) {
        let weiBalance = await web3Instance.eth.getBalance(account);
        if (!weiBalance) {
          weiBalance = '0';
        }
        balance = web3Instance.utils.fromWei(weiBalance);
      }
      dispatch(updateAccountBalanceAction(balance));
    } catch (e) {
      console.log('could not fetch account balance: ', e);
    }
  };
}

/**
 * Fetches Set and ETH balances.
 */
export const fetchBalances = () => async (dispatch: any): Promise<boolean> => {
  try {
    dispatch(requestBalances());

    const balances = await axios.get('v1/accounts/balances');

    if (!balances.data.total_balance_usd) {
      balances.data.total_balance_usd = '$0';
    }
    dispatch(receiveBalances(balances.data));
  } catch (error) {
    dispatch(receiveBalancesFailed());

    if (userRejectedMetamaskTransaction(error)) return false;
  }
};

// TODO: consolidate this fetching with batch fetchERC20Balances.
// Either store truncated version, or raw balance.
export const fetchTokenBalance = (tokenAddress: string, tokenDecimals: number) => async (
  dispatch: any,
  getState: any,
): Promise<BigNumber | undefined> => {
  const state = getState();
  const {
    web3: { account },
    setJS: { setJSInstance: setJS },
  } = state;

  const web3Instance = await getWeb3Instance();
  if (!web3Instance || !account || !tokenAddress || !tokenDecimals || !setJS || !setJS.erc20) {
    return;
  }

  dispatch(setIsFetchingBalanceStatus(true));
  try {
    const tokenBalancePromise: Promise<EthersBigNumber> = setJS.erc20.getBalanceAsync(
      tokenAddress,
      account,
      account,
    );

    const tokenBalance = await faultTolerantPromise<EthersBigNumber>(tokenBalancePromise);

    const bigNumberBalance = new BigNumber(tokenBalance.toString());
    const bigNumberWeiMultiplier = new BigNumber(10).pow(tokenDecimals);
    const formattedBalance = bigNumberBalance.div(bigNumberWeiMultiplier);
    dispatch(setIsFetchingBalanceStatus(false));

    return formattedBalance;
  } catch (e) {
    dispatch(setIsFetchingBalanceStatus(false));
  }
};

/**
 * Fetches ERC-20 balance from user's wallet
 * TODO: add a toast or throw error when balance cannot be fetched.
 * TODO: consolidate this fetching with batch fetchERC20Balances.
 * Either store truncated version, or raw balance.
 */
export const fetchERC20Balance = (tokenAddress: string, tokenDecimals: number) => async (
  dispatch: any,
) => {
  const balance = await dispatch(fetchTokenBalance(tokenAddress, tokenDecimals));

  if (!balance) return;

  const setTokenBalance = { [tokenAddress]: balance };
  dispatch(updateERC20Balance(setTokenBalance));
};

/**
 * Fetches a user's Set balance.
 */
export const fetchSetBalance = (tokenAddress: string, tokenDecimals = 18) => async (
  dispatch: any,
) => {
  const balance = await dispatch(fetchTokenBalance(tokenAddress, tokenDecimals));

  if (!balance) return;

  const setTokenBalance = { [tokenAddress]: balance };
  dispatch(updateSetTokenBalance(setTokenBalance));
};

/**
 * Fetches user's balance of "Current Set".
 */
export const fetchCurrentSetBalance = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const currentSet = currentRebalancingSetSelector(state);

  const balance = await dispatch(fetchTokenBalance(currentSet.address, 18));

  if (!balance) return;

  const setTokenBalance = { [currentSet.address]: balance };
  dispatch(updateSetTokenBalance(setTokenBalance));
};

/**
 * Batch fetch raw balances from a list of addresses. Used for Sets + Tokens like DAI/USDC.
 * @param addresses - List of addresses to fetch balances from.
 * @returns - An object populated by balances indexed by passed in addresses.
 * TODO: remove new BigNumber shim on returned balance when set.js is fixed
 * TODO: consolidate with fetchERC20Balance & fetchTokenBalance
 */
export const fetchBatchERC20Balances = (addresses: string[]) => async (
  _: any,
  getState: any,
): Promise<ITokenBalances | undefined> => {
  const state = getState();
  const {
    web3: { account },
    setJS: { setJSInstance: setJS },
  } = state;

  if (isEmpty(account) || Object.keys(setJS).length === 0) return {};

  if (isEmpty(addresses) || !every(addresses, address => !!address)) {
    return {};
  }

  try {
    const formattedBalances: ITokenBalances = {};

    const balancesPromise: Promise<EthersBigNumber[]> = setJS.setToken.batchFetchBalancesOfAsync(
      addresses,
      account,
      account,
    );

    const balances: EthersBigNumber[] = await faultTolerantPromise<EthersBigNumber[]>(
      balancesPromise,
    );

    balances.forEach((balance: EthersBigNumber, index: number) => {
      const address = addresses[index];

      formattedBalances[address] = new BigNumber(balance.toString() || 0);
    });

    return formattedBalances;
  } catch (e) {
    toast.warn('Sorry, something went wrong when fetching your balances.', {
      toastId: 'fetch-batch-balance',
    });
    console.log('error batch fetching for addresses: ', addresses, e);

    return {};
  }
};

/**
 * Fetches onchain balances for all user balances.
 */
export const fetchAllOnchainBalances = (extraAddresses: string[] = []) => async (
  dispatch: any,
  getState: any,
): Promise<boolean> => {
  const state = getState();
  const currentMainnetChainId = currentMainnetChainIdSelector(state);
  const tokenSetsTokenListByAddress = tokenSetsTokenListByAddressSelector(state);
  const geminiTokenListByAddress = geminiTokenListByAddressSelector(state);
  const managerTokens = managerTokensSelector(state);

  const coinAddresses = Object.keys(geminiTokenListByAddress);

  // Fetch all balances of:
  // 1. Sets in the tokenlist for community sets
  // 2. Featured sets from the current chain
  // 3. Sets that the user is a manager of
  // 4. and any other addresses that we want to fetch for
  const setAddresses = _.uniq(
    Object.keys(tokenSetsTokenListByAddress)
      .filter(
        address =>
          String(tokenSetsTokenListByAddress[address].chainId) === String(currentMainnetChainId),
      )
      .concat((managerTokens || [])?.map(t => t.address))
      .concat(extraAddresses),
  )
    .filter(a => !!a)
    .map(a => a.toLowerCase());

  const setBalances = await dispatch(fetchBatchERC20Balances(setAddresses));
  const coinBalances = await dispatch(fetchBatchERC20Balances(coinAddresses));

  const setsWithOnchainBalance = setAddresses.map((setAddress: string) => {
    const onchainBalance = setBalances[setAddress] || EthersBigNumber.from(0);
    return {
      ...tokenSetsTokenListByAddress[setAddress],
      onchainBalance,
    };
  });
  const coinsWithOnchainBalance = coinAddresses.map((coinAddress: any) => {
    const onchainBalance = coinBalances[coinAddress] || EthersBigNumber.from(0);
    return {
      ...geminiTokenListByAddress[coinAddress],
      onchainBalance,
    };
  });

  dispatch(updateERC20Balance(setBalances));
  dispatch(updateERC20Balance(coinBalances));
  dispatch(receiveFundOnchainBalance(setsWithOnchainBalance));
  dispatch(receiveCoinOnchainBalance(coinsWithOnchainBalance));
  dispatch(setIsFetchingBalanceStatus(false));

  return true;
};

/**
 * Fetches balances for all tokens of the currently viewing set.
 * Used for V2 on-chain set details analysis.
 */
export const fetchBalancesForCurrentlyViewingSet = () => async (
  dispatch: any,
  getState: any,
): Promise<boolean> => {
  const state = getState();
  const currentSetAddress = setDetailsCurrentSetAddressSelector(state);
  const setComponents = currentSetComponentsSelector(state);

  const componentAddresses = setComponents?.map((token: IListTokenWithPosition) => token.component);

  if (isEmpty(componentAddresses)) {
    return false;
  }

  const targetAddresses = componentAddresses.concat(currentSetAddress);

  const tokenBalances = await dispatch(fetchBatchERC20Balances(targetAddresses));

  if (isEmpty(tokenBalances)) {
    return false;
  }

  dispatch(updateERC20Balance(tokenBalances));
  return true;
};

/**
 * Fetches ERC-20 transfer proxy allowance for the user's address.
 * TODO: potentially add a toast or throw error when balance cannot be fetched.
 */
export const fetchERC20Allowance = (currency: ITradingPair) => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const {
    web3: { account },
    setJS: { setJSInstance: setJS },
  } = state;

  if (account && currency && currency.address) {
    dispatch(requestERC20Allowance());

    return setJS.erc20
      .getAllowanceAsync(currency.address, account, TRANSFER_PROXY_ADDRESS, account)
      .then((allowance: BigNumber) => {
        dispatch(receiveERC20Allowance({ [toChecksumAddress(currency.address)]: allowance }));
        return allowance;
      })
      .catch((error: any) => {
        dispatch(receiveERC20Allowance(undefined));
        console.log(error);
      });
  }
};
