import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Grid, Loader, Dimmer } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { COLORS, STYLES, WINDOW_DIMENSIONS } from '../../constants/index';
import { InfoIcon, Text } from '../../components/index';
import { BigNumber } from '../../utils/index';

const { monoFont } = STYLES;
const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  CurrentBalanceContainer_currentBalanceContainer: {
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '4px',
    marginTop: '5px',
    marginBottom: '10px',
    padding: '16px',
  },
  CurrentBalanceContainer_currentBalanceText: {
    fontWeight: 500,
    fontSize: '14px',
    color: COLORS.darkBlue,
    fontFamily: 'Graphik',
    marginRight: '10px',
  },
  CurrentBalanceContainer_balanceHeader: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  CurrentBalanceContainer_balanceColumn: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  CurrentBalanceContainer_web3Status: {
    cursor: 'pointer',
  },
  CurrentBalanceContainer_currentBalanceAmount: {
    color: COLORS.darkBlue,
    lineHeight: '1.5',
    fontSize: '20px',
    fontWeight: 500,
    textAlign: 'right',
    fontFamily: monoFont,
  },
  CurrentBalanceContainer_currentBalanceSymbol: {
    color: COLORS.darkBlue,
    display: 'inline-block',
    marginLeft: '5px',
    fontSize: '9px',
    fontWeight: 500,
  },
  CurrentBalanceContainer_currentUSDAmount: {
    color: COLORS.darkGray,
    lineHeight: '1.5',
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'right',
    fontFamily: monoFont,
  },
  CurrentBalanceContainer_currentRate: {
    color: COLORS.darkGray,
    display: 'inline-block',
    marginLeft: '5px',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: monoFont,
    marginTop: '2px',
    [MOBILE_MEDIA_QUERY]: {
      marginLeft: 0,
    },
  },
});

interface ICurrentBalanceContainerProps {
  balance: string;
  balanceTokenSymbol: string;
  pricePerToken: string;
  fetchingBalance: boolean;
}

class CurrentBalanceContainer extends PureComponent<
  ICurrentBalanceContainerProps & WithTranslation
> {
  public render() {
    const { balance, pricePerToken, balanceTokenSymbol, fetchingBalance, t } = this.props;

    const description = `${t(
      'preview.your-balance-description',
    )} \n ${balanceTokenSymbol} ≈ $${pricePerToken}.`;

    const toolTipData = {
      header: t('preview.your-balance'),
      description,
    };

    return (
      <div className={css(styles.CurrentBalanceContainer_currentBalanceContainer)}>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column width={7} className={css(styles.CurrentBalanceContainer_balanceColumn)}>
              <Text
                className={css(styles.CurrentBalanceContainer_currentBalanceText)}
                type="regular"
                tag="p"
              >
                {t('general.balance')}
              </Text>
              <InfoIcon data={toolTipData} hasHighZIndex loggingInfo="Buy Balance" />
            </Grid.Column>
            <Grid.Column width={9} textAlign="right">
              <span className={css(styles.CurrentBalanceContainer_currentBalanceAmount)}>
                {balance}
              </span>
              <span className={css(styles.CurrentBalanceContainer_currentBalanceSymbol)}>
                {balanceTokenSymbol}
              </span>
              {fetchingBalance ? (
                <Dimmer active>
                  <Loader active />
                </Dimmer>
              ) : null}
              <p className={css(styles.CurrentBalanceContainer_currentUSDAmount)}>
                {`$${new BigNumber(balance).mul(pricePerToken || 0).toFixed(2)}`}
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default withTranslation('buy-sell-modal')(CurrentBalanceContainer);
