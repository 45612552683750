import React, { PureComponent } from 'react';
import { Editor, EditorState, RichUtils, DraftEditorCommand, convertToRaw } from 'draft-js';
import { css } from 'aphrodite';
import debounce from 'lodash/debounce';

import { styles } from './RichTextEditorStyles';
import RenderMediaType from './components/RenderMediaType';
import EditorControls from './components/EditorControls';
import { addLinkToEditorState, addImageToEditorState, loadInitialEditorState } from './utils';
import { INLINE_STYLE_OPTIONS, CUSTOM_MEDIA_OPTIONS } from './enums';

export interface IRichTextEditorProps {
  readOnly?: boolean;
  customStyles?: any;
  disableImages?: boolean;
  disableLinks?: boolean;
  initialRawEditorState?: string;
  onRetrieveTextData?: (...args: any[]) => any;
}

export interface IRichTextEditorState {
  activeDataInputType: CUSTOM_MEDIA_OPTIONS;
  imageURL: string;
  linkText: string;
  linkURL: string;
  editorState: EditorState;
}

class RichTextEditor extends PureComponent<IRichTextEditorProps, IRichTextEditorState> {
  constructor(props: IRichTextEditorProps) {
    super(props);

    this.state = {
      activeDataInputType: null,
      imageURL: '',
      linkText: '',
      linkURL: '',
      editorState: loadInitialEditorState(props.initialRawEditorState),
    };
  }

  static get defaultProps() {
    return {
      disableImages: false,
      disableLinks: false,
      onRetrieveTextData: () => {},
    };
  }

  componentDidUpdate(prevProps: IRichTextEditorProps) {
    const { initialRawEditorState } = this.props;
    if (!prevProps.initialRawEditorState && !!initialRawEditorState) {
      const editorState = loadInitialEditorState(initialRawEditorState);

      this.onUpdateEditorState(editorState);
    }
  }

  public onUpdateEditorState = (editorState: EditorState) => {
    this.setState({ editorState });
    this.onSaveEditorState();
  };

  public onSaveEditorState = debounce(() => {
    const { onRetrieveTextData } = this.props;
    const { editorState } = this.state;
    const rawEditorState = convertToRaw(editorState.getCurrentContent());

    onRetrieveTextData(rawEditorState);
  }, 1000);

  /* Helper methods to Set focus to Text Editor ref */
  public editor = { focus: (): any => null };

  public setEditor = (editor: any) => {
    this.editor = editor;
  };

  public setEditorFocus = (e: any) => {
    const { readOnly } = this.props;
    if (readOnly) {
      return;
    }

    e.preventDefault();

    if (this.editor) {
      this.editor.focus();
    }
  };

  /**
   * Enables native keyboard commands to alter editor state.
   * E.g. Ctrl+I/Cmd+I for italics or Ctrl+B/Cmd+B for bold.
   */
  public onHandleKeyCommand = (command: DraftEditorCommand, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      this.onUpdateEditorState(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  /**
   * Renders a custom block component based on block type while editor parses editor state.
   */
  public onCustomMediaRender = (block: any) => {
    if (block.getType() === 'atomic') {
      return {
        component: RenderMediaType,
        editable: false,
      };
    }

    return null;
  };

  /**
   * Select an inline style in EditorControls. Supports Bold, Italics, Underline.
   */
  public onSelectInlineStyleControl = (e: any, selectedDecorator: INLINE_STYLE_OPTIONS) => {
    e.preventDefault();
    const { editorState } = this.state;

    this.onUpdateEditorState(RichUtils.toggleInlineStyle(editorState, selectedDecorator));
  };

  /**
   * Select a data entry type in EditorControls. Supports entering images, url links.
   */
  public onSelectDataInputType = (e: any, blockType: CUSTOM_MEDIA_OPTIONS) => {
    e.preventDefault();

    const { activeDataInputType } = this.state;

    if (blockType === activeDataInputType) {
      this.setState({ activeDataInputType: null });
      return;
    }

    if (blockType === CUSTOM_MEDIA_OPTIONS.LINK) {
      const selectedText = window.getSelection().toString();
      this.setState({ activeDataInputType: blockType, linkText: selectedText });
      return;
    }

    this.setState({ activeDataInputType: blockType });
  };

  public onUpdateImageURL = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ imageURL: target.value });
  };

  public onUpdateLinkText = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ linkText: target.value });
  };

  public onUpdateLinkURL = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ linkURL: target.value });
  };

  // Saves the selected image URL to EditorState
  public onSubmitImage = (e: any) => {
    e.preventDefault();
    const { editorState, imageURL } = this.state;

    const editorStateWithImage = addImageToEditorState(editorState, imageURL);

    this.setState({
      editorState: editorStateWithImage,
      activeDataInputType: null,
      imageURL: '',
    });
  };

  // Saves selected link text + URL to EditorState
  public onSubmitLink = (e: any) => {
    e.preventDefault();
    const { editorState, linkText, linkURL } = this.state;

    const editorStateWithLink = addLinkToEditorState(editorState, linkText, linkURL);

    this.setState({
      editorState: editorStateWithLink,
      activeDataInputType: null,
      linkText: '',
      linkURL: '',
    });
  };

  public getEditorControlOptions = () => {
    const { disableLinks, disableImages } = this.props;

    /** Editor Control Options */
    const inlineStyleControlOptions = [
      INLINE_STYLE_OPTIONS.BOLD,
      INLINE_STYLE_OPTIONS.ITALIC,
      INLINE_STYLE_OPTIONS.UNDERLINE,
    ];

    const dataInputControlOptions = [];
    !disableLinks && dataInputControlOptions.push(CUSTOM_MEDIA_OPTIONS.LINK);
    !disableImages && dataInputControlOptions.push(CUSTOM_MEDIA_OPTIONS.IMAGE);

    return { inlineStyleControlOptions, dataInputControlOptions };
  };

  render() {
    const { readOnly, customStyles } = this.props;
    const { editorState, activeDataInputType, linkText } = this.state;

    const { inlineStyleControlOptions, dataInputControlOptions } = this.getEditorControlOptions();

    if (readOnly) {
      return (
        <div className={css(styles.RichTextEditor_readOnlyContainer, customStyles)}>
          <Editor
            readOnly
            ref={this.setEditor}
            blockRendererFn={this.onCustomMediaRender}
            editorState={editorState}
            handleKeyCommand={this.onHandleKeyCommand}
            onChange={this.onUpdateEditorState}
          />
        </div>
      );
    }

    return (
      <div className={css(styles.RichTextEditor_container)}>
        <EditorControls
          inlineStyleOptions={inlineStyleControlOptions}
          dataInputOptions={dataInputControlOptions}
          activeDataInputType={activeDataInputType}
          activeInlineStyles={editorState.getCurrentInlineStyle()}
          linkText={linkText}
          onSelectInlineStyleControl={this.onSelectInlineStyleControl}
          onSelectDataInputType={this.onSelectDataInputType}
          onUpdateImageInput={this.onUpdateImageURL}
          onSubmitImage={this.onSubmitImage}
          onUpdateLinkTextInput={this.onUpdateLinkText}
          onUpdateLinkURLInput={this.onUpdateLinkURL}
          onSubmitLink={this.onSubmitLink}
        />
        <div className={css(styles.RichTextEditor_editorContainer)} onClick={this.setEditorFocus}>
          <Editor
            ref={this.setEditor}
            blockRendererFn={this.onCustomMediaRender}
            editorState={editorState}
            handleKeyCommand={this.onHandleKeyCommand}
            onChange={this.onUpdateEditorState}
          />
        </div>
      </div>
    );
  }
}

export default RichTextEditor;
