import React, { PureComponent } from 'react';
import { css } from 'aphrodite';
import { Header, Modal, Popup } from 'semantic-ui-react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { QRCode } from 'react-qr-svg';
import { withTranslation, WithTranslation } from 'react-i18next';

import { CloseIcon } from '../../components/index';
import { truncateTokenAmount } from '../../utils/formatUtils';
import { IWindowDimensions } from '../../typings/index';
import styles from './OnboardingStyles';

interface IFundYourWalletProps {
  readonly walletAddress?: string;
  readonly ethBalance?: string;
  readonly windowDimension?: IWindowDimensions;
}
interface IFundYourWalletState {
  readonly isQRCodeOpen: boolean;
}

const trimEthAddress = (address: string) => {
  return address.slice(0, 26).concat('...');
};

class FundYourWallet extends PureComponent<
  IFundYourWalletProps & WithTranslation,
  IFundYourWalletState
> {
  state = {
    isQRCodeOpen: false,
  };

  public toggleQrCode = () => {
    const { isQRCodeOpen } = this.state;

    this.setState({ isQRCodeOpen: !isQRCodeOpen });
  };

  public renderCopyAddress = () => {
    const { t, walletAddress } = this.props;

    const trigger = (
      <CopyToClipboard text={walletAddress}>
        <span className={css(styles.FundYourWallet_copyAddressButton)}>Copy</span>
      </CopyToClipboard>
    );

    return (
      <Popup trigger={<div>{trigger}</div>} on="click">
        <p>{t('set-details:common.copy-address-tooltip-title')}</p>
      </Popup>
    );
  };

  render() {
    const {
      walletAddress,
      ethBalance,
      windowDimension: { isMobile },
      t,
    } = this.props;
    const { isQRCodeOpen } = this.state;

    if (isMobile) {
      return (
        <div className={css(styles.Onboarding_slide)}>
          <div className={css(styles.Onboarding_slideSection)}>
            <img
              src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/dollar-to-crypto.svg"
              className={css(styles.FundYourWallet_image)}
            />
            <Header as="h1" className={css(styles.Onboarding_sectionTitle)}>
              {t('fund-your-wallet.title')}
            </Header>
            <p className={css(styles.Onboarding_sectionSubtitle, styles.FundYourWallet_subtitle)}>
              {t('fund-your-wallet.subtitle')}
            </p>
            <span className={css(styles.Onboarding_sectionSubtitle)}>
              {t('fund-your-wallet.your-wallet-balance')}
            </span>{' '}
            <div>
              <span className={css(styles.Onboarding_text, styles.FundYourWallet_walletBalance)}>
                {truncateTokenAmount(ethBalance)}
              </span>{' '}
              <span className={css(styles.Onboarding_text, styles.FundYourWallet_walletCurrency)}>
                ETH
              </span>
            </div>
            <p className={css(styles.FundYourWallet_qrLabel)}>
              {t('fund-your-wallet.eth-address-label')}
            </p>
            <div className={css(styles.FundYourWallet_ethAddressContainer)}>
              <span className={css(styles.FundYourWallet_ethereumAddressText)}>
                {trimEthAddress(walletAddress)}
              </span>
              {this.renderCopyAddress()}
            </div>
            <Modal
              closeIcon={<CloseIcon onClick={this.toggleQrCode} />}
              onClose={this.toggleQrCode}
              onOpen={this.toggleQrCode}
              open={isQRCodeOpen}
              trigger={
                <button className={css(styles.FundYourWallet_toggleQRCodeButton)} type="button">
                  {isQRCodeOpen
                    ? t('fund-your-wallet.hide-qr-code')
                    : t('fund-your-wallet.show-qr-code')}
                </button>
              }
            >
              <div className={css(styles.FundYourWallet_qrCodeContainer)}>
                <QRCode
                  className={css(styles.FundYourWallet_qrImage)}
                  bgColor="#FFFFFF"
                  fgColor="#000000"
                  level="Q"
                  value={walletAddress}
                />
              </div>
            </Modal>
          </div>
        </div>
      );
    }

    return (
      <div className={css(styles.Onboarding_slide)}>
        <div className={css(styles.Onboarding_slideSection)}>
          <img
            src="https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/dollar-to-crypto.svg"
            className={css(styles.FundYourWallet_image)}
          />
          <Header as="h1" className={css(styles.Onboarding_sectionTitle)}>
            {t('fund-your-wallet.title')}
          </Header>
          <p className={css(styles.Onboarding_sectionSubtitle, styles.FundYourWallet_subtitle)}>
            {t('fund-your-wallet.subtitle')}
          </p>
          <p className={css(styles.FundYourWallet_qrLabel)}>
            {t('fund-your-wallet.eth-address-label')}
          </p>
          <div className={css(styles.FundYourWallet_ethAddressContainer)}>
            <span className={css(styles.FundYourWallet_ethereumAddressText)}>
              {trimEthAddress(walletAddress)}
            </span>
            {this.renderCopyAddress()}
          </div>
          <button
            className={css(styles.FundYourWallet_toggleQRCodeButton)}
            type="button"
            onClick={this.toggleQrCode}
          >
            {isQRCodeOpen ? t('fund-your-wallet.hide-qr-code') : t('fund-your-wallet.show-qr-code')}
          </button>
          {isQRCodeOpen && (
            <QRCode
              className={css(styles.FundYourWallet_qrImage)}
              bgColor="#FFFFFF"
              fgColor="#000000"
              level="Q"
              value={walletAddress}
            />
          )}
        </div>
        <div className={css(styles.FundYourWallet_walletContainer)}>
          <span className={css(styles.Onboarding_sectionSubtitle)}>
            {t('fund-your-wallet.your-wallet-balance')}
          </span>{' '}
          <div>
            <span className={css(styles.Onboarding_text, styles.FundYourWallet_walletBalance)}>
              {truncateTokenAmount(ethBalance)}
            </span>{' '}
            <span className={css(styles.Onboarding_text, styles.FundYourWallet_walletCurrency)}>
              ETH
            </span>
          </div>
          <p className={css(styles.FundYourWallet_footerText)}>
            ({t('fund-your-wallet.add-more-funds-later')})
          </p>
        </div>
      </div>
    );
  }
}

export default withTranslation('onboarding')(FundYourWallet);
