import BigNumber from './bigNumber';
import { IToken, IZeroMappings } from '../typings/index';
import { extractNumberFromDollarAmount, trimPercentSign } from './formatUtils';
import validate from 'validate.js';
import { ethers } from 'ethers';

export const MAX_INPUT = new BigNumber(10000000.0); // 10,000,000
export const MIN_INPUT = new BigNumber(0);
export const MAX_INTEGERS = 8;
export const MAX_DECIMALS = 3;

const blackList = /[^\w\s]/gi;
const zeroMappings: IZeroMappings = {
  '0': true,
  '00': true,
  '000': true,
  '0000': true,
  '00000': true,
  '000000': true,
  '0000000': true,
};

export const validateEmail = (email: string) => {
  // eslint-disable-next-line no-useless-escape
  const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (emailRegex.test(email)) return true;

  return false;
};

/**
 * Validates the username is 3 - 25 characters long, allows letters, numbers, and dashes
 * @param text - text to validate
 */
export const validateUsername = (username: string) => {
  const usernameRegex = /^[a-zA-Z0-9-_]{3,25}$/;

  return usernameRegex.test(username);
};

/**
 * Returns true if the input string is a valid URL.
 * @param websiteUrl - String to check URL validity of.
 */
export const validateURL = (websiteUrl: string) => {
  return validate.single(websiteUrl, { url: true }) === undefined;
};

export const validateText = (text: string) =>
  text && typeof text === 'string' && !blackList.test(text) && text.trim() && text.length > 0;

export const validateTokenQuantity = (quantity: string, tokenMax: string) => {
  if (quantity === undefined) return false;
  if (quantity === '') return false;

  const quantityFloat = parseFloat(quantity);
  if (quantityFloat <= 0) return false;
  if (quantityFloat > parseFloat(tokenMax)) return false;

  return true;
};

export const validateCreateSetTokensQuantity = (tokens: IToken[]) => {
  if (tokens.length === 0) return false;

  for (const token of tokens) {
    if (token.quantity === undefined) return false;
    if (token.quantity === '') return false;
  }

  return true;
};

export const validateQuantityInput = (
  input: string,
  prevInput = '',
  maxDecimals: number = MAX_DECIMALS,
  maxInput: BigNumber = MAX_INPUT,
  maxIntegers: number = MAX_INTEGERS,
) => {
  if (input === '.') return '0.';
  if (input.indexOf('+') !== -1) return prevInput;
  if (input.split('.').length > 2) return prevInput;
  if (isNaN(Number(input))) return '0';
  if (zeroMappings[input]) return '0';

  const isValidInput = !!parseFloat(input);
  const validatedInput = new BigNumber(isValidInput ? input : 0);
  const inputSplit = input.split('.');
  const prevInputSplit = prevInput ? prevInput.split('.') : ['', ''];
  const prevIntegers = prevInputSplit[0];
  const prevDecimals = prevInputSplit[1];
  let integers = inputSplit[0];
  let decimals = inputSplit[1];
  let quantity;
  // If under min or over max, return prevInput
  if (validatedInput.lt(MIN_INPUT) || validatedInput.gt(maxInput)) return prevInput;
  // Integer check
  if (inputSplit[0].length > maxIntegers) integers = prevIntegers;
  // Decimal check
  if (decimals && decimals.length > maxDecimals) {
    decimals = decimals.length < prevDecimals.length ? decimals : prevDecimals;
  }
  // Clean - Remove leading zeros for integers
  if (integers[0] === '0' && integers.length > 1) integers = integers.replace(/^0+/, '');
  // Join
  if (decimals || (integers !== '' && decimals === '')) {
    quantity = integers.concat('.').concat(decimals);
  } else {
    quantity = integers;
  }
  return quantity ? quantity.toString() : '';
};

export const validateNonZeroPercent = (percent: string) => {
  if (!percent) return false;

  const fee = trimPercentSign(percent);
  const hasValue = new BigNumber(fee).gt(0);
  return hasValue;
};

export const validateNonZeroDollars = (dollarAmount: string) => {
  if (!dollarAmount) return false;

  const fee = extractNumberFromDollarAmount(dollarAmount);
  const hasValue = new BigNumber(fee).gt(0);
  return hasValue;
};

export const validatePercentage = (percent: string, lowerBound: number, upperBound: number) => {
  if (!percent) return false;

  const fee = trimPercentSign(percent);
  const withinUpperBound = new BigNumber(fee).lte(upperBound);
  const withinLowerBound = new BigNumber(fee).gte(lowerBound);

  return withinUpperBound && withinLowerBound;
};

export const validateEthereumAddress = (address: string): boolean => {
  try {
    // Throws if an address is invalid.
    ethers.utils.getAddress(address);

    return true;
  } catch {
    return false;
  }
};
