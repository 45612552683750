import { IAction, IToken } from '../typings/index';
import {
  SET_EXPLORE_TABLE_CATEGORY_FILTER,
  CHANGE_NETWORK_FILTER,
} from '../actions/exploreActions';

export enum SetCategory {
  THEMATIC_EXPOSURE = 'thematic',
  LEVERAGED_EXPOSURE = 'leverage',
  YIELD_BEARING = 'yield',
  UNCATEGORIZED = 'uncategorized',
}

export interface IFilters {
  category: SetCategory;
  selectedNetworks: string[];
}

interface IExploreState {
  filters: IFilters;
}

const initialState: IExploreState = {
  filters: {
    category: SetCategory.THEMATIC_EXPOSURE,
    selectedNetworks: null,
  },
};

/**
 * Takes in an existing filter and token, and creates a new filter depending
 * on if the token should be added or removed from the new asset filter array.
 */
export const createNewAssetFilter = (assetsFilter: IToken[], token: IToken) => {
  const shouldRemoveFilter = !!assetsFilter.find((asset: IToken) => asset.id === token.id);

  let newAssetsFilter;
  if (shouldRemoveFilter) {
    newAssetsFilter = assetsFilter.filter((asset: IToken) => asset.id !== token.id);
  } else {
    newAssetsFilter = assetsFilter.slice();
    newAssetsFilter.push(token);
  }

  return newAssetsFilter;
};

const exploreReducer = (state = initialState, action: IAction): IExploreState => {
  switch (action.type) {
    case SET_EXPLORE_TABLE_CATEGORY_FILTER: {
      const { filters } = state;

      return {
        ...state,
        filters: {
          ...filters,
          category: action.payload,
        },
      };
    }

    case CHANGE_NETWORK_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedNetworks: action.payload,
        },
      };

    default:
      return state;
  }
};

export default exploreReducer;
