import { gql } from '@apollo/client';

export const CORE_SET_TOKEN_FIELDS = gql`
  fragment CoreSetTokenFields on SetToken {
    id
    name
    symbol
    inception
  }
`;

export const OWNED_AND_OPERATED_SETS_FOR_ACCOUNT_QUERY = gql`
  ${CORE_SET_TOKEN_FIELDS}

  query ManagedSets($account: String!) {
    manager(id: $account) {
      id
      setTokens {
        ...CoreSetTokenFields
      }
    }

    owner(id: $account) {
      id
      setTokens {
        ...CoreSetTokenFields
      }
    }

    operator(id: $account) {
      id
      setTokens {
        ...CoreSetTokenFields
      }
    }
  }
`;
