import keyMirror from 'keymirror';

const DATA_SELECTS = keyMirror({
  NAVBAR_BODY: null,
  NAVBAR_HOME: null,
  NAVBAR_EXPLORE: null,
  NAVBAR_CREATOR: null,
  NAVBAR_ACCOUNT: null,
});

export default DATA_SELECTS;
