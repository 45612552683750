import { connect } from 'react-redux';

import IssuanceApproval from './IssuanceApproval';
import {
  setDetailsCurrentSetAddressSelector,
  windowDimensionSelector,
} from '../../selectors/baseSelectors';
import { currentSetComponentsSelector } from '../../selectors/setDetailsSelectors';
import {
  approveAllListTokensForCurrentSet,
  approveListTokenAllowance,
} from '../../actions/approvalActions';
import {
  isAnyApprovalPendingSelector,
  allApprovalStatusByTokenIdSelector,
} from '../../selectors/issuanceV2Selectors';
import { customV2SetPathPrefixSelector } from '../../selectors/web3Selectors';

const mapStateToProps = (state: any) => {
  const isAnyApprovalPending = isAnyApprovalPendingSelector(state);
  const allApprovalStatusesByTokenId = allApprovalStatusByTokenIdSelector(state);
  const requiredComponents = currentSetComponentsSelector(state);
  const windowDimensions = windowDimensionSelector(state);
  const customV2SetPathPrefix = customV2SetPathPrefixSelector(state);
  const setAddress = setDetailsCurrentSetAddressSelector(state);

  return {
    customV2SetPathPrefix,
    setAddress,
    isAnyApprovalPending,
    allApprovalStatusesByTokenId,
    requiredComponents,
    windowDimensions,
  };
};

const mapDispatchToProps = {
  approveAllListTokensForCurrentSet,
  approveListTokenAllowance,
};

export default connect(mapStateToProps, mapDispatchToProps)(IssuanceApproval);
