import {
  EditorState,
  AtomicBlockUtils,
  SelectionState,
  Modifier,
  CompositeDecorator,
  convertFromRaw,
} from 'draft-js';

import { CUSTOM_MEDIA_RENDER_TYPES } from './enums';
import LinkComponent from './components/LinkComponent';

// Sort of a regex looking for link entities in content blocks.
export const matchLinkEntities = (contentBlock: any, callback: any, contentState: any) => {
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
};

/**
 * Matches Link entities in EditorContent.
 * When a match is found, renders a LinkComponent instead of editor text default span.
 */

export const createDecorator = () => {
  return new CompositeDecorator([{ strategy: matchLinkEntities, component: LinkComponent }]);
};

/**
 * Load initial editor state.
 * 1. If the initial editor state is a valid editor state, parse it and initialize editor with it.
 * 2. If the initial editor state is legacy (plain text) initialize a new editor and insert the string
 * 3. If the initial editor state is invalid, initialize a clean EditorState;
 */
export const loadInitialEditorState = (rawState?: string): EditorState => {
  const decorator = createDecorator();

  let parsedEditorState;
  try {
    parsedEditorState = JSON.parse(rawState);
  } catch {}

  if (parsedEditorState) {
    const editorState = convertFromRaw(parsedEditorState);
    return EditorState.createWithContent(editorState, decorator);
  }

  if (rawState) {
    const newEditorState = EditorState.createEmpty(decorator);
    const emptyEditorContent = newEditorState.getCurrentContent();
    const emptyTextSelection = newEditorState.getSelection();

    const editorStateWithInsertedText = Modifier.insertText(
      emptyEditorContent,
      emptyTextSelection,
      rawState,
    );

    return EditorState.createWithContent(editorStateWithInsertedText, decorator);
  }

  return EditorState.createEmpty(decorator);
};

/**
 * Adds an image to the text editor.
 * @param editorState - Old editor state to be updated.
 * @param imageURL - image URL to be displayed in the editor.
 */
export const addImageToEditorState = (editorState: EditorState, imageURL: string): EditorState => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    CUSTOM_MEDIA_RENDER_TYPES.SIMPLE_IMAGE,
    'IMMUTABLE',
    {
      url: imageURL,
    },
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });

  return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, 'image');
};

/** Create selector for newly inserted text, depending on if the user highlighted to text from the left or from the right */
const getLinkTextSelection = (currentTextSelection: SelectionState, linkText: string) => {
  const isBackwards = currentTextSelection.getIsBackward();

  if (isBackwards) {
    const focusKey = currentTextSelection.getFocusKey();
    const focusOffset = currentTextSelection.getFocusOffset();
    return new SelectionState({
      anchorKey: focusKey,
      anchorOffset: focusOffset,
      focusKey,
      focusOffset: focusOffset + linkText.length,
    });
  }

  const anchorKey = currentTextSelection.getAnchorKey();
  const anchorOffset = currentTextSelection.getAnchorOffset();
  return new SelectionState({
    anchorKey,
    anchorOffset,
    focusKey: anchorKey,
    focusOffset: anchorOffset + linkText.length,
  });
};

/**
 * Creates a new updated editor state with included link text + url.
 * @param editorState - Old editor state to be updated.
 * @param linkText - Link description text that will be clickable.
 * @param linkURL - Link URL that user will be redirected to.
 */
export const addLinkToEditorState = (
  editorState: EditorState,
  linkText: string,
  linkURL: string,
): EditorState => {
  const currentContent = editorState.getCurrentContent();
  const currentTextSelection = editorState.getSelection();

  let contentWithLink;
  if (!currentTextSelection.isCollapsed()) {
    // Created new Content with selected text replaced with description text
    contentWithLink = Modifier.replaceText(currentContent, currentTextSelection, linkText);
  } else {
    // Create new Content with link description text
    contentWithLink = Modifier.insertText(currentContent, currentTextSelection, linkText);
  }

  // Select newly inserted text for binding.
  const newSelection = getLinkTextSelection(currentTextSelection, linkText);

  // Add Link URL to entities map
  const contentWithURLEntity = contentWithLink.createEntity('LINK', 'IMMUTABLE', {
    url: linkURL,
  });
  const entityKey = contentWithURLEntity.getLastCreatedEntityKey();

  // Apply/match the Link URL entity to the new text selection
  const contentWithBoundLinkAndURL = Modifier.applyEntity(
    contentWithURLEntity,
    newSelection,
    entityKey,
  );

  // Create new EditorState with link text description + it's matched url entity
  const withLinkText = EditorState.push(
    editorState,
    contentWithBoundLinkAndURL,
    'insert-characters',
  );

  // Create new EditorState with cursor position to the right of inserted link
  const withProperCursor = EditorState.forceSelection(
    withLinkText,
    contentWithLink.getSelectionAfter(),
  );

  return withProperCursor;
};

/**
 * Returns the content length of a stringified RichText state
 * @param richTextString - Stringified RichText state
 */
export const getRichTextCharacterLength = (richTextString: string): number | null => {
  try {
    const rawState = JSON.parse(richTextString);
    const richText = convertFromRaw(rawState);

    return richText.getPlainText().length;
  } catch {
    return null;
  }
};
