/**
 *  Taken from set-models
 *  https://github.com/SetProtocol/set-models/blob/master/app/models/rebalancing_set.rb
 */

export default {
  DEFAULT: 'default',
  INITIAL_CONFIRMATION: 'initial_confirmation',
  PROPOSAL: 'proposal',
  REBALANCE: 'rebalance',
  DRAWDOWN: 'drawdown',
};
