import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { COLORS } from '../../constants/index';

const styles = StyleSheet.create({
  Line: {
    borderTop: `1px solid ${COLORS.blueGray2}`,
  },
  LineContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '1em',
    marginBottom: '1em',
  },
});

interface ThinLineProps {
  width?: string;
  style?: any;
}

export default function ThinLine({ width, style }: ThinLineProps) {
  return (
    <div style={style} className={css(styles.LineContainer)}>
      <div className={css(styles.Line)} style={{ width: width ? width : '100%' }} />
    </div>
  );
}
