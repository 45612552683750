import React, { useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { WINDOW_DIMENSIONS, COLORS } from '../../constants/index';
import TertiaryButton from '../../components/TertiaryButton/';
import x from '../../img/icons/x-close.svg';

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  FormContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  Input: {
    border: `1px solid ${COLORS.blueGray}`,
    height: '38px',
    borderRadius: '5px',
    marginBottom: 0,
    display: 'flex',
    flexDirection: 'row',
  },
  DefaultInnerInput: {
    width: '100%',
    height: '36px',
    paddingTop: '3px',
    lineHeight: '36px',
    paddingLeft: '0.5em',
    border: 0,
  },
  InnerInputWithButton: {
    width: '70%',
    [MOBILE_MEDIA_QUERY]: {
      width: '100%',
    },
    border: 0,
    height: '36px',
    paddingTop: '3px',
    lineHeight: '36px',
    paddingLeft: '0.5em',
  },
  ButtonContainer: {
    width: '30%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    [MOBILE_MEDIA_QUERY]: {
      display: 'none',
    },
  },
  hover: {
    ':hover': {
      border: `1px solid ${COLORS.lightBlue6}`,
    },
  },
  focus: {
    boxShadow: `0px 0px 0px 2px ${COLORS.blueShadow}`,
    border: `1px solid ${COLORS.lightBlue6}`,
  },
  focusError: {
    boxShadow: `0px 0px 0px 2px ${COLORS.redShadow}`,
    border: `1px solid ${COLORS.red2}`,
  },
  HelperText: {
    color: COLORS.lightGray5,
  },
  error: {
    backgroundColor: COLORS.pink2,
    border: `1px solid ${COLORS.red2}`,
  },
  errorText: {
    color: COLORS.red2,
    fontWeight: 500,
  },
  disabled: {
    ':hover': {
      cursor: 'pointer',
      backgroundColor: COLORS.pink2,
      boxShadow: `0px 0px 0px 2px ${COLORS.redShadow}`,
      border: `1px solid ${COLORS.red2}`,
    },
  },
  inputDisabled: {
    ':hover': {
      cursor: 'pointer',
      backgroundColor: COLORS.pink2,
    },
    backgroundColor: COLORS.lightGray7,
  },
  HoverStyle: {
    backgroundColor: COLORS.pink2,
  },
  Img: {
    backgroundColor: COLORS.lightGray7,
    paddingRight: '1em',
    borderRadius: '5px',
  },
});

interface TextFieldProps {
  disabled?: boolean;
  removable?: boolean;
  name: string;
  placeholder?: string;
  label?: string;
  helperText?: string;
  onChange: any;
  value: any;
  error?: boolean;
  errorText?: string;
  width?: string;
  handleClick?: (...args: any[]) => any;
  handleRemove?: (...args: any[]) => any;
}

const TextField = ({
  disabled,
  removable = true,
  name,
  placeholder = 'Placeholder',
  label = 'Field label',
  helperText = '',
  onChange,
  value,
  error,
  errorText,
  width,
  handleClick,
  handleRemove,
}: TextFieldProps) => {
  const [inFocus, setInFocus] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const handleOnFocus = () => setInFocus(true);
  const handleOnBlur = () => setInFocus(false);
  const showHover = () => setIsHovering(true);
  const hideHover = () => setIsHovering(false);

  const showRemoveStyle = disabled && removable;

  return (
    <div
      style={{
        width: width ? width : '305px',
      }}
      className={css(styles.FormContainer)}
    >
      <label>{label}</label>
      <div
        className={css(
          styles.Input,
          styles.hover,
          inFocus && (error ? styles.focusError : styles.focus),
          error && styles.error,
          showRemoveStyle && styles.disabled,
        )}
        onMouseEnter={showHover}
        onMouseLeave={hideHover}
      >
        <input
          className={css(
            handleClick ? styles.InnerInputWithButton : styles.DefaultInnerInput,
            showRemoveStyle && styles.inputDisabled,
          )}
          type={'text'}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onClick={handleRemove}
        />
        {handleClick && (
          <div className={css(styles.ButtonContainer)}>
            <TertiaryButton label={'+ add operator'} onClick={handleClick} />
          </div>
        )}
        {showRemoveStyle && (
          <img className={css(styles.Img, isHovering && styles.HoverStyle)} src={x} />
        )}
      </div>

      {(helperText || errorText) && (
        <label className={css(errorText ? styles.errorText : styles.HelperText)}>
          {errorText ? errorText : helperText}
        </label>
      )}
    </div>
  );
};

export default TextField;
