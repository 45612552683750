/* eslint-disable import/prefer-default-export */
import BigNumber from './bigNumber';
import createCss from './createCss';
import { userAgent, isChrome, isBrave } from './browserUtils';
import {
  darken,
  setAlpha,
  getPriceTextColor,
  generatePendingAnimationStyle,
  generatePendingAnimationHoverStyle,
} from './colorUtils';
import { extractErrorMessage, extractAPIData } from './apiUtils';
import {
  getEthFromWei,
  truncateEthAddress,
  truncateTokenAmount,
  truncateString,
  formatToDecimals,
  formatTokenUnit,
  getTransactionStatusFormat,
  calculateComponentsRequired,
  getRebalancingStatusProperties,
  shortenedDollarAmount,
  titleCase,
  trimDollarSign,
  trimPercentSign,
  trimDecimals,
  toFixed2,
  getListTokenImage,
  chunkArray,
  getPaymentCurrencyTokenSymbol,
} from './formatUtils';
import {
  MAX_INPUT,
  MIN_INPUT,
  MAX_INTEGERS,
  MAX_DECIMALS,
  validateQuantityInput,
  validateEmail,
  validateNonZeroPercent,
  validateNonZeroDollars,
  validateText,
  validateTokenQuantity,
  validateCreateSetTokensQuantity,
} from './formValidations';
import { fm, fmProvider, magic, magicProvider } from './fortmatic';
import getClassNames from './getClassNames';
import routerHistory from './history';
import {
  getTOSStatus,
  acceptTOS,
  getBundleInfographicStatus,
  confirmBundleInfographicStatus,
  getV2BannerDismissal,
  dismissV2Banner,
} from './localStorageUtils';
import { emptyActionGenerator, payloadActionGenerator } from './reduxHelpers';
import {
  detectOperaWallet,
  getWeb3,
  getAccount,
  getMessageSignature,
  getNetworkId,
  getWeb3Instance,
  getLedgerWeb3,
  ledgerEngine,
  login,
  userRejectedMetamaskTransaction,
} from './web3Utils';

import promiseRetry from 'promise-retry';
import { RetryOptions } from '../typings/index';

async function faultTolerantPromise<T>(
  promise: Promise<T>,
  options: RetryOptions = {},
): Promise<T> {
  return promiseRetry(
    (retry: any, _number: number) => {
      return promise.catch(e => {
        // console.log(`Retrying because of ${e?.message}`);
        retry(e);
      });
    },
    { retries: 5, ...options },
  ).then(
    function (value: T) {
      return value;
    },
    function (err: any) {
      // console.log(`Final error: ${err?.message}`);
      throw err;
    },
  );
}

export {
  // retry
  faultTolerantPromise,
  //api utils
  extractAPIData,
  extractErrorMessage,
  // bigNumber
  BigNumber,
  // browserUtils
  userAgent,
  isChrome,
  isBrave,
  // colorUtils
  darken,
  setAlpha,
  getPriceTextColor,
  generatePendingAnimationStyle,
  generatePendingAnimationHoverStyle,
  // createCss
  createCss,
  // formatUtils
  getEthFromWei,
  truncateEthAddress,
  truncateTokenAmount,
  truncateString,
  formatToDecimals,
  formatTokenUnit,
  getTransactionStatusFormat,
  calculateComponentsRequired,
  getRebalancingStatusProperties,
  shortenedDollarAmount,
  titleCase,
  trimDollarSign,
  trimPercentSign,
  trimDecimals,
  toFixed2,
  getListTokenImage,
  chunkArray,
  getPaymentCurrencyTokenSymbol,
  // formValidations
  MAX_INPUT,
  MIN_INPUT,
  MAX_INTEGERS,
  MAX_DECIMALS,
  validateQuantityInput,
  validateEmail,
  validateNonZeroPercent,
  validateNonZeroDollars,
  validateText,
  validateTokenQuantity,
  validateCreateSetTokensQuantity,
  // fortmatic
  fm,
  fmProvider,
  magic,
  magicProvider,
  // getClassNames
  getClassNames,
  // history
  routerHistory,
  // localStorageUtils
  getTOSStatus,
  acceptTOS,
  getBundleInfographicStatus,
  confirmBundleInfographicStatus,
  getV2BannerDismissal,
  dismissV2Banner,
  // reduxHelpers
  emptyActionGenerator,
  payloadActionGenerator,
  // web3Utils
  detectOperaWallet,
  getAccount,
  getMessageSignature,
  getNetworkId,
  getWeb3Instance,
  getWeb3,
  getLedgerWeb3,
  login,
  ledgerEngine,
  userRejectedMetamaskTransaction,
};
