import React, { PureComponent } from 'react';
import { Container, Header, Icon, Placeholder, Dropdown } from 'semantic-ui-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { css, StyleSheet } from 'aphrodite';

import IssueRedeemButtons from './IssueRedeemButtons';
import {
  ISetDetails,
  IWindowDimensions,
  IHistory,
  CustomSetDetailsResponse,
  IListToken,
} from '../../typings/index';
import {
  WINDOW_DIMENSIONS,
  COLORS,
  DEFAULT_SET_UNITS,
  NETWORK_CONSTANTS,
} from '../../constants/index';
import { BigNumber, truncateEthAddress } from '../../utils/index';
import { generateColorFromString } from '../../utils/colorUtils';
import { defaultIssuanceIsOpen, closeDefaultIssuance } from '../../utils/localStorageUtils';
import { makeBlockExplorerContractLink, urlIntendedChain } from '../../utils/web3Utils';
import { toFixed2Locale } from 'utils/formatUtils';

const { TABLET_MEDIA_QUERY, MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  CustomSetHeader_container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  CustomSetHeader_titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '25px',
    marginTop: '25px',
    [TABLET_MEDIA_QUERY]: {
      flexDirection: 'column',
      justifyContent: 'center',
      marginBottom: '15px',
      marginTop: '0',
    },
  },
  CustomSetHeader_exploreLinkContainer: {
    marginTop: '20px',
    paddingBottom: '1rem',
    [TABLET_MEDIA_QUERY]: {
      marginTop: '0',
    },
  },
  CustomSetHeader_header: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '12px',
    [TABLET_MEDIA_QUERY]: {
      alignItems: 'flex-start',
      marginBottom: '12px',
      marginRight: '0px',
    },
  },
  CustomSetHeader_networkBadge: {
    width: '90px',
    height: '20px',
  },
  CustomSetHeader_setIcon: {
    height: '50px',
    width: '50px',
    marginRight: '15px',
    borderRadius: '50%',
    paddingRight: '1px',
  },
  CustomSetHeader_editIcon: {
    height: '30px',
    width: '30px',
    fontSize: '0.75em',
    marginLeft: '10px',
    cursor: 'pointer',
  },
  CustomSetHeader_setNameContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  CustomSetHeader_setName: {
    fontSize: '34px',
    fontWeight: 500,
    lineHeight: 1.2,
    [TABLET_MEDIA_QUERY]: {
      fontSize: '28px',
      fontWeight: 600,
    },
  },
  CustomSetHeader_setSymbol: {
    color: COLORS.darkGray,
    fontSize: '20px',
    fontWeight: 500,
    [TABLET_MEDIA_QUERY]: {
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: '0',
    },
  },
  CustomSetHeader_buttonsContainer: {
    display: 'flex',
  },
  CustomSetHeader_managerControls: {
    display: 'flex',
    [TABLET_MEDIA_QUERY]: {
      width: '100%',
      justifyContent: 'flex-start',
      marginLeft: '0px',
      borderLeft: 'none',
    },
  },
  CustomSetHeader_button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    fontSize: '16px',
    fontWeight: 500,
    padding: '0 20px',
    margin: '0px',
    marginLeft: '15px',
    borderRadius: '4px',
    [TABLET_MEDIA_QUERY]: {
      width: '200px',
      marginLeft: '0px',
    },
    [MOBILE_MEDIA_QUERY]: {
      width: '50%',
    },
  },
  CustomSetHeader_followButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    border: `1px solid ${COLORS.gray}`,
    width: 'initial',
    ':hover': {
      backgroundColor: COLORS.lightGray,
    },
    [MOBILE_MEDIA_QUERY]: {
      width: '100%',
    },
  },
  CustomSetHeader_followIcon: {
    marginRight: '15px',
    [TABLET_MEDIA_QUERY]: {
      marginRight: '10px',
      height: '17px',
    },
  },
  CustomSetHeader_followText: {
    paddingTop: '1px',
    lineHeight: 1,
  },
  CustomSetHeader_chevronDown: {
    width: '10px',
    pointerEvents: 'none',
  },
  CustomSetHeader_managerButton: {
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: 600,
    width: '150px',
    height: '50px',
    backgroundColor: COLORS.darkBlue,
    color: COLORS.white,
    ':hover': {
      boxShadow: `0 8px 14px ${COLORS.blueAlpha25}`,
      transform: 'scale(1.025)',
    },
  },
  CustomSetHeader_dropdownContainer: {
    zIndex: 998,
    [MOBILE_MEDIA_QUERY]: {
      width: '100%',
    },
  },
  CustomSetHeader_dropdownAnchorButton: {
    backgroundColor: COLORS.white,
    border: `1px solid ${COLORS.darkBlue}`,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '15px',
    height: '50px',
    padding: '0 10px',
    borderRadius: '8px',
    transition: '0.2s',
    ':hover': {
      backgroundColor: COLORS.lightGray,
    },
    [TABLET_MEDIA_QUERY]: {
      marginLeft: '0px',
      border: `1px solid ${COLORS.lightBlue2}`,
    },
  },
  CustomSetHeader_dropdownIcon: {
    margin: '0',
  },
  CustomSetHeader_dropdownButton: {
    backgroundColor: COLORS.white,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '0px',
    height: '50px',
    padding: '0 20px',
    textAlign: 'left',
    ':hover': {
      backgroundColor: COLORS.lightGray,
    },
    [TABLET_MEDIA_QUERY]: {
      width: '200px',
    },
    [MOBILE_MEDIA_QUERY]: {
      width: '100%',
    },
  },
  CustomSetHeader_dropdownText: {
    color: COLORS.darkBlue,
  },
  CustomSetHeader_disabled: {
    opacity: 1,
    cursor: 'not-allowed',
    color: COLORS.darkGray,
    border: `1px solid ${COLORS.darkGray}`,
    backgroundColor: COLORS.white,
  },

  // Set Meta Stats
  CustomSetHeader_metaInformationContainer: {
    display: 'flex',
  },
  CustomSetHeader_metaUserImage: {
    width: '38px',
    height: '38px',
    marginRight: '12px',
    borderRadius: '50%',
    paddingTop: '1px',
  },
  CustomSetHeader_metaInformation: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '40px',
    marginBottom: '20px',
  },
  CustomSetHeader_metaInformationLabel: {
    fontSize: '15px',
    color: COLORS.darkGray,
    marginTop: '5px',
  },
  CustomSetHeader_metaInformationContent: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '20px',
    fontWeight: 500,
    color: COLORS.darkBlue,
  },
  CustomSetHeader_apy: {
    color: COLORS.green,
  },
  // Placeholders
  CustomSetHeader_placeholderUserImage: {
    width: '38px',
    height: '38px',
    marginTop: '5px',
  },
  CustomSetHeader_placeholderMarginBottom: {
    marginBottom: '15px',
  },
  CustomSetHeader_placeholderMarginTop: {
    marginTop: '25px',
  },
  CustomSetHeader_placeholderRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  CustomSetHeader_placeholderRowSpaceBetween: {
    justifyContent: 'space-between',
  },
  CustomSetHeader_placeholderSetIcon: {
    borderRadius: '50%',
    overflow: 'hidden',
  },
  CustomSetHeader_placeholderSetHeader: {
    overflow: 'hidden',
    height: '50px',
    width: '300px',
    [TABLET_MEDIA_QUERY]: {
      width: '300px',
    },
    [MOBILE_MEDIA_QUERY]: {
      height: '40px',
      width: '200px',
    },
  },
  CustomSetHeader_placeholderButtonContainer: {
    width: '100%',
    overflow: 'hidden',
  },
  CustomSetHeader_placeholderButton: {
    height: '57px',
    width: '150px',
    [MOBILE_MEDIA_QUERY]: {
      height: '40px',
      width: '1000px',
    },
  },
  CustomSetHeader_placeholderStat: {
    width: '120px',
  },
  CustomSetHeader_placeholderInline: {
    display: 'inline',
  },
  CustomSetHeader_placeholderMarginRight: {
    marginRight: '15px',
  },
  CustomSetHeader_uniswapIcon: {
    position: 'relative',
    bottom: '2px',
    marginRight: '8px',
  },
  CustomSetHeader_uniswapLabel: {
    marginTop: '0px',
  },
  CustomSetHeader_link: {
    color: COLORS.blue,
  },
});

interface ICustomSetHeaderProps {
  isWarningBannerDisplayed: boolean;
  isLoading: boolean;
  isSetOperator: boolean;
  isUserValidOperator: boolean;
  isBasicIssuanceEnabled: boolean;
  setDetails: ISetDetails;
  customSetDetails: CustomSetDetailsResponse;
  setAddress: string;
  setOwnerAddress: string;
  setSymbol: string;
  setBalance: BigNumber;
  account: string;
  history: IHistory;
  windowDimension?: IWindowDimensions;
  tokenSetsTokenListByAddress: { [address: string]: IListToken };
  networkBadge: string;
  currentChain: string;
  onOpenEditDetailsModal?: (...args: any[]) => any;
  onNavToIssuance: (...args: any[]) => any;
  onNavToRedemption: (...args: any[]) => any;
  accrueStreamingFee: (...args: any[]) => any;
}

/**
 * @title CustomSetHeader
 * @author Set Labs
 *
 * CustomSetHeader displays information about the Social Set, it's assets under vault and number of users following the set.
 */
class CustomSetHeader extends PureComponent<ICustomSetHeaderProps & WithTranslation> {
  public onHoverBanner = () => {
    this.setState({ isHoveringOnBanner: true });
  };

  public onRemoveHoverBanner = () => {
    this.setState({ isHoveringOnBanner: false });
  };

  public renderMetadata = () => {
    const {
      setDetails: { streamingFeePercentage, totalSupply },
      windowDimension: { isTablet },
      account,
      setSymbol,
      setAddress,
      setOwnerAddress,
      setBalance,
      customSetDetails,
      networkBadge,
      t,
    } = this.props;

    if (isTablet) return;

    let setCreatorIcon = 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/assets/portfolio-creator.svg';
    let managerName = truncateEthAddress(setOwnerAddress);
    if (customSetDetails?.setDetails) {
      setCreatorIcon = customSetDetails?.setDetails.managerIcon;
      managerName = customSetDetails?.setDetails.managerName;
    }

    const derivedManagerColor = generateColorFromString(setOwnerAddress || '');

    const intendedChain = urlIntendedChain();

    const isEthereum = intendedChain === NETWORK_CONSTANTS.ETHEREUM_CHAIN;

    return (
      <div className={css(styles.CustomSetHeader_metaInformationContainer)}>
        <a href={makeBlockExplorerContractLink(setOwnerAddress)} target="_blank">
          <img
            src={setCreatorIcon}
            className={css(styles.CustomSetHeader_metaUserImage)}
            style={{
              ...(!customSetDetails?.setDetails && { backgroundColor: `#${derivedManagerColor}` }),
            }}
          />
        </a>
        <div className={css(styles.CustomSetHeader_metaInformation)}>
          <a href={makeBlockExplorerContractLink(setOwnerAddress)} target="_blank">
            <span className={css(styles.CustomSetHeader_metaInformationContent)}>
              {managerName}
            </span>
          </a>
          <span className={css(styles.CustomSetHeader_metaInformationLabel)}>
            {t('header.set-creator')}
          </span>
        </div>

        <div className={css(styles.CustomSetHeader_metaInformation)}>
          <span className={css(styles.CustomSetHeader_metaInformationContent)}>
            {totalSupply
              ? `${toFixed2Locale(new BigNumber(totalSupply?.div(DEFAULT_SET_UNITS)))}`
              : '--'}
          </span>
          <span className={css(styles.CustomSetHeader_metaInformationLabel)}>Total Supply</span>
        </div>

        <div className={css(styles.CustomSetHeader_metaInformation)}>
          <span className={css(styles.CustomSetHeader_metaInformationContent)}>
            {Number(
              new BigNumber(streamingFeePercentage || 0)
                .mul(100)
                .div(DEFAULT_SET_UNITS)
                .toNumber()
                .toFixed(18),
            )}
            %
          </span>
          <span className={css(styles.CustomSetHeader_metaInformationLabel)}>
            {t('header.streaming-fee')}
          </span>
        </div>

        {isEthereum && (
          <div className={css(styles.CustomSetHeader_metaInformation)}>
            <a
              href={`https://app.zerion.io/invest/asset/${setSymbol?.toUpperCase()}-${setAddress?.toLowerCase()}`}
              target="_blank"
            >
              <span
                className={css(
                  styles.CustomSetHeader_metaInformationContent,
                  styles.CustomSetHeader_link,
                )}
              >
                {t('header.zerion')}
              </span>
            </a>
            <span className={css(styles.CustomSetHeader_metaInformationLabel)}>
              {t('header.performance')}
            </span>
          </div>
        )}

        <div className={css(styles.CustomSetHeader_metaInformation)}>
          <span className={css(styles.CustomSetHeader_metaInformationContent)}>
            <img
              className={css(styles.CustomSetHeader_networkBadge)}
              src={networkBadge}
              style={{}}
            />
          </span>
          <span className={css(styles.CustomSetHeader_metaInformationLabel)}>Network</span>
        </div>

        {account && account.length > 0 && (
          <div className={css(styles.CustomSetHeader_metaInformation)}>
            <span className={css(styles.CustomSetHeader_metaInformationContent)}>
              {setBalance?.toFixed(8)}
            </span>
            <span className={css(styles.CustomSetHeader_metaInformationLabel)}>
              Current Balance
            </span>
          </div>
        )}
      </div>
    );
  };

  public renderSetName = () => {
    const {
      isSetOperator,
      onOpenEditDetailsModal,
      windowDimension: { isTablet },
      setDetails: { name, symbol },
      customSetDetails,
      tokenSetsTokenListByAddress,
      setAddress,
    } = this.props;

    const setIcon =
      customSetDetails?.setDetails?.setIcon ||
      tokenSetsTokenListByAddress[setAddress.toLowerCase()]?.logoURI ||
      'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/assets/tokensets/logos/default-set-token-icon.svg';
    const setSymbol = customSetDetails?.setDetails?.setSymbol || symbol;

    if (isTablet) {
      return (
        <div className={css(styles.CustomSetHeader_header)}>
          <img className={css(styles.CustomSetHeader_setIcon)} src={setIcon} />
          <div className={css(styles.CustomSetHeader_setNameContainer)}>
            <Header
              as="h1"
              className={css(styles.CustomSetHeader_setName)}
              style={{ color: isSetOperator ? COLORS.blue : COLORS.black }}
            >
              {customSetDetails?.setDetails?.setName ||
                tokenSetsTokenListByAddress[setAddress.toLowerCase()]?.name ||
                name}
              {isSetOperator && (
                <Icon
                  onClick={onOpenEditDetailsModal}
                  className={css(styles.CustomSetHeader_editIcon)}
                  name="pencil"
                />
              )}
            </Header>
            <Header as="h2" className={css(styles.CustomSetHeader_setSymbol)}>
              {setSymbol}
            </Header>
          </div>
        </div>
      );
    }

    return (
      <div className={css(styles.CustomSetHeader_header)}>
        <img className={css(styles.CustomSetHeader_setIcon)} src={setIcon} />
        <div>
          <Header
            as="h1"
            className={css(styles.CustomSetHeader_setName)}
            style={{ color: isSetOperator ? COLORS.blue : COLORS.black }}
          >
            {customSetDetails?.setDetails?.setName ||
              tokenSetsTokenListByAddress[setAddress.toLowerCase()]?.name ||
              name}
            {isSetOperator && (
              <Icon
                onClick={onOpenEditDetailsModal}
                className={css(styles.CustomSetHeader_editIcon)}
                name="pencil"
              />
            )}
          </Header>
          <h3 className={css(styles.CustomSetHeader_setSymbol)}>{setSymbol}</h3>
        </div>
      </div>
    );
  };

  public renderAdditionalOptionsDropdown = () => {
    const { isWarningBannerDisplayed, windowDimension, accrueStreamingFee, t } = this.props;

    if (windowDimension?.isTablet) {
      return (
        <div className={css(styles.CustomSetHeader_managerControls)}>
          <Dropdown
            floating
            icon={null}
            trigger={<div>{t('fund-details:action-dropdown.title')}</div>}
            className={css(styles.CustomSetHeader_dropdownAnchorButton)}
          >
            <Dropdown.Menu className={css(styles.CustomSetHeader_dropdownContainer)}>
              <Dropdown.Item
                className={css(styles.CustomSetHeader_dropdownButton)}
                text={
                  <span className={css(styles.CustomSetHeader_dropdownText)}>
                    {t('fund-details:action-dropdown.accrue-fees')}
                  </span>
                }
                onClick={() => accrueStreamingFee()}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    }

    const isIssuanceDropdownDefaultOpen = defaultIssuanceIsOpen();

    return (
      <div className={css(styles.CustomSetHeader_managerControls)}>
        <Dropdown
          defaultOpen={isIssuanceDropdownDefaultOpen}
          closeOnBlur={!isIssuanceDropdownDefaultOpen}
          floating
          icon={
            <Icon className={css(styles.CustomSetHeader_dropdownIcon)} name="ellipsis vertical" />
          }
          className={css(styles.CustomSetHeader_dropdownAnchorButton)}
          onClose={() => {
            !isWarningBannerDisplayed && isIssuanceDropdownDefaultOpen && closeDefaultIssuance();
          }}
        >
          <Dropdown.Menu className={css(styles.CustomSetHeader_dropdownContainer)}>
            <Dropdown.Item
              className={css(styles.CustomSetHeader_dropdownButton)}
              text={
                <span className={css(styles.CustomSetHeader_dropdownText)}>
                  {t('fund-details:action-dropdown.accrue-fees')}
                </span>
              }
              onClick={() => accrueStreamingFee()}
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  renderDesktopPlaceholder = () => {
    return (
      <Container>
        <div
          className={css(
            styles.CustomSetHeader_placeholderMarginTop,
            styles.CustomSetHeader_placeholderMarginBottom,
            styles.CustomSetHeader_placeholderRow,
            styles.CustomSetHeader_placeholderRowSpaceBetween,
          )}
        >
          <div className={css(styles.CustomSetHeader_placeholderRow)}>
            <div
              className={css(
                styles.CustomSetHeader_setIcon,
                styles.CustomSetHeader_placeholderSetIcon,
              )}
            >
              <Placeholder>
                <Placeholder.Image className={css(styles.CustomSetHeader_setIcon)} square />
              </Placeholder>
            </div>
            <div className={css(styles.CustomSetHeader_placeholderInline)}>
              <Placeholder>
                <Placeholder.Header className={css(styles.CustomSetHeader_placeholderSetHeader)} />
              </Placeholder>
            </div>
          </div>
          <div className={css(styles.CustomSetHeader_placeholderRow)}>
            <div className={css(styles.CustomSetHeader_placeholderMarginRight)}>
              <Placeholder>
                <Placeholder.Image className={css(styles.CustomSetHeader_placeholderButton)} />
              </Placeholder>
            </div>
            <div className={css(styles.CustomSetHeader_placeholderMarginRight)}>
              <Placeholder>
                <Placeholder.Image className={css(styles.CustomSetHeader_placeholderButton)} />
              </Placeholder>
            </div>
            <div>
              <Placeholder>
                <Placeholder.Image className={css(styles.CustomSetHeader_placeholderButton)} />
              </Placeholder>
            </div>
          </div>
        </div>
        <div
          className={css(
            styles.CustomSetHeader_placeholderMarginBottom,
            styles.CustomSetHeader_placeholderRow,
          )}
        >
          <div className={css(styles.CustomSetHeader_placeholderRow)}>
            <div
              className={css(
                styles.CustomSetHeader_setIcon,
                styles.CustomSetHeader_placeholderSetIcon,
                styles.CustomSetHeader_placeholderUserImage,
              )}
            >
              <Placeholder>
                <Placeholder.Image className={css(styles.CustomSetHeader_setIcon)} square />
              </Placeholder>
            </div>

            <div
              className={css(
                styles.CustomSetHeader_placeholderMarginRight,
                styles.CustomSetHeader_placeholderStat,
              )}
            >
              <div>
                <Placeholder>
                  <Placeholder.Line length="very long" />
                </Placeholder>
              </div>
              <div>
                <Placeholder>
                  <Placeholder.Line length="long" />
                </Placeholder>
              </div>
            </div>
            <div
              className={css(
                styles.CustomSetHeader_placeholderMarginRight,
                styles.CustomSetHeader_placeholderStat,
              )}
            >
              <div>
                <Placeholder>
                  <Placeholder.Line length="very long" />
                </Placeholder>
              </div>
              <div>
                <Placeholder>
                  <Placeholder.Line length="long" />
                </Placeholder>
              </div>
            </div>
            <div
              className={css(
                styles.CustomSetHeader_placeholderMarginRight,
                styles.CustomSetHeader_placeholderStat,
              )}
            >
              <div>
                <Placeholder>
                  <Placeholder.Line length="very long" />
                </Placeholder>
              </div>
              <div>
                <Placeholder>
                  <Placeholder.Line length="long" />
                </Placeholder>
              </div>
            </div>
            <div
              className={css(
                styles.CustomSetHeader_placeholderMarginRight,
                styles.CustomSetHeader_placeholderStat,
              )}
            >
              <div>
                <Placeholder>
                  <Placeholder.Line length="very long" />
                </Placeholder>
              </div>
              <div>
                <Placeholder>
                  <Placeholder.Line length="long" />
                </Placeholder>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  };

  renderMobilePlaceholder = () => {
    return (
      <Container>
        <div
          className={css(
            styles.CustomSetHeader_placeholderMarginBottom,
            styles.CustomSetHeader_placeholderSetHeader,
          )}
        >
          <Placeholder>
            <Placeholder.Header className={css(styles.CustomSetHeader_placeholderSetHeader)} />
          </Placeholder>
        </div>
        <div className={css(styles.CustomSetHeader_placeholderMarginBottom)}>
          <div className={css(styles.CustomSetHeader_placeholderButtonContainer)}>
            <Placeholder>
              <Placeholder.Image className={css(styles.CustomSetHeader_placeholderButton)} />
            </Placeholder>
          </div>
        </div>
      </Container>
    );
  };

  renderIssueRedeemButtons = () => {
    const {
      isLoading,
      windowDimension: { isTablet },
      onNavToRedemption,
      onNavToIssuance,
    } = this.props;

    if (isTablet) return null;

    return (
      <div>
        <IssueRedeemButtons
          isLoading={isLoading}
          onNavToIssuance={onNavToIssuance}
          onNavToRedemption={onNavToRedemption}
        />
      </div>
    );
  };

  public render() {
    const {
      isLoading,
      setDetails,
      windowDimension: { isTablet },
    } = this.props;

    if ((isLoading || !setDetails?.name) && !isTablet) {
      return this.renderDesktopPlaceholder();
    }

    if ((isLoading || !setDetails?.name) && isTablet) {
      return this.renderMobilePlaceholder();
    }

    return (
      <Container>
        <div className={css(styles.CustomSetHeader_container)}>
          <div className={css(styles.CustomSetHeader_titleContainer)}>
            {this.renderSetName()}
            <div className={css(styles.CustomSetHeader_buttonsContainer)}>
              {this.renderIssueRedeemButtons()}
              {this.renderAdditionalOptionsDropdown()}
            </div>
          </div>
          {this.renderMetadata()}
        </div>
      </Container>
    );
  }
}

export default withTranslation('set-details-v2')(CustomSetHeader);
