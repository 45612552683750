import { connect } from 'react-redux';

import RedemptionForm from './RedemptionForm';
import {
  updateRedemptionQuantity,
  setMaxRedeemableQuantity,
  redeemCurrentSet,
} from '../../actions/redemptionV2Actions';
import { resetTransactionWatcher } from '../../actions/transactionWatcherActions';
import { fetchERC20Balance } from '../../actions/balanceActions';
import { userHasSufficientFundsForRedemptionQuantity } from '../../selectors/redemptionV2Selectors';
import {
  currentSetDetailsSelector,
  currentSetComponentsSelector,
} from '../../selectors/setDetailsSelectors';
import {
  erc20BalancesSelector,
  setDetailsCurrentSetAddressSelector,
  isSubmittingRedemptionTransactionV2Selector,
  redemptionInputQuantityV2Selector,
  transactionIdSelector,
  windowDimensionSelector,
  customV2SetPathPrefixSelector,
} from '../../selectors/index';

const mapStateToProps = (state: any) => {
  const setDetails = currentSetDetailsSelector(state);
  const setAddress = setDetailsCurrentSetAddressSelector(state);
  const isRedemptionPermitted = userHasSufficientFundsForRedemptionQuantity(state);
  const requiredComponents = currentSetComponentsSelector(state);
  const isSubmittingRedemptionTransaction = isSubmittingRedemptionTransactionV2Selector(state);
  const redemptionQuantity = redemptionInputQuantityV2Selector(state);
  const currentTransactionId = transactionIdSelector(state);
  const erc20BalancesByAddress = erc20BalancesSelector(state);
  const windowDimensions = windowDimensionSelector(state);
  const customV2SetPathPrefix = customV2SetPathPrefixSelector(state);

  return {
    customV2SetPathPrefix,
    address: setAddress,
    name: setDetails?.name,
    symbol: setDetails?.symbol,
    requiredComponents,
    isRedemptionPermitted,
    isSubmittingRedemptionTransaction,
    redemptionQuantity,
    erc20BalancesByAddress,
    currentTransactionId,
    windowDimensions,
  };
};

const mapDispatchToProps = {
  fetchERC20Balance,
  updateRedemptionQuantity,
  setMaxRedeemableQuantity,
  redeemCurrentSet,
  resetTransactionWatcher,
};

export default connect(mapStateToProps, mapDispatchToProps)(RedemptionForm);
