import axios from 'axios';
import { toChecksumAddress } from 'tochecksum';
import { toast } from 'react-toastify';
import { emptyActionGenerator, payloadActionGenerator } from '../utils/reduxHelpers';

import {
  readOnlySetJSInstanceSelector,
  setJSInstanceSelector,
  setDetailsCurrentSetAddressSelector,
} from '../selectors/baseSelectors';
import { ISetDetails } from '../typings/index';
import { transactionCallerSelector } from '../selectors/transactionSelectors';
import { currentChainOrDefaultSelector } from '../selectors/web3Selectors';
import {
  debtIssuanceModuleV1AddressSelector,
  debtIssuanceModuleV2AddressSelector,
  perpIssuanceModuleAddressSelector,
} from '../selectors/protocolAddressSelector';
import { basicIssuanceModuleAddressSelector } from '../selectors/issuanceV2Selectors';
import {
  aaveLeverageModuleAddressSelector,
  allModulesSelector,
  compoundLeverageModuleAddressSelector,
  perpV2BasisTradingModuleAddressSelector,
  perpV2LeverageModuleAddressSelector,
} from '../selectors/setDetailsSelectors';
import { NETWORK_CONSTANTS } from '../constants/index';
import { faultTolerantPromise } from '../utils/index';
import { tokenFromBaseUnits } from '../utils/formatUtils';
import { getWeb3Instance } from '../utils/web3Utils';
import debtIssuanceModuleABI from '../constants/abis/debtIssuanceModuleABI';

export const REQUEST_SET_DETAILS = 'REQUEST_SET_DETAILS';
export const RECEIVE_SET_DETAILS = 'RECEIVE_SET_DETAILS';
export const REQUEST_SET_DETAILS_ERROR = 'REQUEST_SET_DETAILS_ERROR';

export const RECEIVE_EMPTY_SET_DETAILS = 'RECEIVE_EMPTY_SET_DETAILS';
export const BATCH_RECEIVE_SET_DETAILS = 'BATCH_RECEIVE_SET_DETAILS';
export const BATCH_REQUEST_SET_DETAILS_ERROR = 'BATCH_REQUEST_SET_DETAILS_ERROR';

export const SET_CURRENTLY_VIEWING_SET_ADDRESS = 'SET_CURRENTLY_VIEWING_SET_ADDRESS';

export const OPEN_EDIT_SET_DETAILS_MODAL = 'OPEN_EDIT_SET_DETAILS_MODAL';
export const CLOSE_EDIT_SET_DETAILS_MODAL = 'CLOSE_EDIT_SET_DETAILS_MODAL';

export const REQUEST_CUSTOM_SET_DETAILS = 'REQUEST_CUSTOM_SET_DETAILS';
export const RECEIVE_CUSTOM_SET_DETAILS = 'RECEIVE_CUSTOM_SET_DETAILS';
export const REQUEST_CUSTOM_SET_DETAILS_ERROR = 'REQUEST_CUSTOM_SET_DETAILS_ERROR';

export const LOADING_MODULE_STATUSES = 'LOADING_MODULE_STATUSES';
export const RECEIVED_MODULE_STATUSES = 'RECEIVED_MODULE_STATUSES';

export const FETCH_PRIMARY_APY_CALCULATIONS = 'FETCH_PRIMARY_APY_CALCULATIONS';
export const RECEIVE_PRIMARY_APY_CALCULATIONS = 'RECEIVE_PRIMARY_APY_CALCULATIONS';

export const RECEIVE_ISSUANCE_REDEMPTION_FEES = 'RECEIVE_ISSUANCE_REDEMPTION_FEES';

export const ADD_SET_TO_CONFIRMED_WARNING_LIST = 'ADD_SET_TO_CONFIRMED_WARNING_LIST';

export const addSetToConfirmedWarningList = payloadActionGenerator(
  ADD_SET_TO_CONFIRMED_WARNING_LIST,
);
export const openEditSetDetailsModal = payloadActionGenerator(OPEN_EDIT_SET_DETAILS_MODAL);
export const closeEditSetDetailsModal = emptyActionGenerator(CLOSE_EDIT_SET_DETAILS_MODAL);

export const requestSetDetails = payloadActionGenerator(REQUEST_SET_DETAILS);
export const receiveSetDetails = payloadActionGenerator(RECEIVE_SET_DETAILS);
export const requestSetDetailsError = payloadActionGenerator(REQUEST_SET_DETAILS_ERROR);

export const requestCustomSetDetails = payloadActionGenerator(REQUEST_CUSTOM_SET_DETAILS);
export const receiveCustomSetDetails = payloadActionGenerator(RECEIVE_CUSTOM_SET_DETAILS);
export const requestCustomSetDetailsError = payloadActionGenerator(
  REQUEST_CUSTOM_SET_DETAILS_ERROR,
);
export const receiveEmptySetDetails = payloadActionGenerator(RECEIVE_EMPTY_SET_DETAILS);
export const batchReceiveSetDetails = payloadActionGenerator(BATCH_RECEIVE_SET_DETAILS);
export const batchRequestSetDetailsError = payloadActionGenerator(BATCH_REQUEST_SET_DETAILS_ERROR);

export const setCurrentlyViewingSetAddress = payloadActionGenerator(
  SET_CURRENTLY_VIEWING_SET_ADDRESS,
);

export const loadingModuleStatuses = payloadActionGenerator(LOADING_MODULE_STATUSES);
export const receivedModuleStatuses = payloadActionGenerator(RECEIVED_MODULE_STATUSES);

export const fetchPrimaryApyCalculations = payloadActionGenerator(FETCH_PRIMARY_APY_CALCULATIONS);
export const receivePrimaryApyCalculations = payloadActionGenerator(
  RECEIVE_PRIMARY_APY_CALCULATIONS,
);

export const receiveIssuanceRedemptionFees = payloadActionGenerator(
  RECEIVE_ISSUANCE_REDEMPTION_FEES,
);

export const batchFetchSetDetails = (addresses: String[]) => async (
  dispatch: any,
  getState: any,
) => {
  if (!addresses.length) {
    return;
  }

  const state = getState();
  const readOnlySetJS = readOnlySetJSInstanceSelector(state);
  const caller = transactionCallerSelector(state);

  try {
    dispatch(requestSetDetails(true));

    const moduleAddresses = allModulesSelector(state);

    const batchFetchSetDetailsPromise: Promise<
      ISetDetails[]
    > = readOnlySetJS.setToken.batchFetchSetDetailsAsync(
      addresses.map(address => toChecksumAddress(address)),
      moduleAddresses,
      caller,
    );
    const setDetailsList: ISetDetails[] = await faultTolerantPromise<ISetDetails[]>(
      batchFetchSetDetailsPromise,
    );

    dispatch(
      batchReceiveSetDetails({
        addresses,
        setDetailsList,
      }),
    );

    return setDetailsList;
  } catch (e) {
    if (
      e?.data?.details?.includes('Try again after some time') ||
      e?.message === 'header not found'
    ) {
      window.location.reload();
    } else {
      toast.warn('Sorry, something went wrong when fetching set details.', {
        toastId: 'batch-fetch-set-details',
      });
      console.log('could not batch fetch set details', e, addresses);
      dispatch(batchRequestSetDetailsError(true));
      return [];
    }
  }
};

export const fetchSetDetails = (address: String) => async (dispatch: any, getState: any) => {
  const state = getState();
  const readOnlySetJS = readOnlySetJSInstanceSelector(state);
  const caller = transactionCallerSelector(state);

  try {
    dispatch(requestSetDetails(true));

    // Note this order is important. See currentSetModuleStatuses in setDetailsSelectors.ts
    const moduleAddresses = allModulesSelector(state);

    const setDetailsPromise: Promise<ISetDetails> = readOnlySetJS.setToken.fetchSetDetailsAsync(
      address,
      moduleAddresses,
      caller,
    );

    const setDetails: ISetDetails = await faultTolerantPromise<ISetDetails>(setDetailsPromise);

    dispatch(
      receiveSetDetails({
        address,
        setDetails,
      }),
    );

    return setDetails;
  } catch (e) {
    if (
      e?.data?.details?.includes('Try again after some time') ||
      e?.message === 'header not found'
    ) {
      window.location.reload();
    } else {
      toast.warn('Sorry, something went wrong when fetching set details.', {
        toastId: 'fetch-set-details',
      });
      console.log('could not fetch set details', e);
      dispatch(requestSetDetailsError(true));
    }
  }
};

export const fetchCustomSetDetails = (setAddress: string) => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const currentChain = currentChainOrDefaultSelector(state);

  let path;
  switch (currentChain) {
    case NETWORK_CONSTANTS.POLYGON_CHAIN:
      path = 'set-details-polygon';
      break;
    case NETWORK_CONSTANTS.ARBITRUM_CHAIN:
      path = 'set-details-arbitrum';
      break;
    case NETWORK_CONSTANTS.OPTIMISM_CHAIN:
      path = 'set-details-optimism';
      break;
    case NETWORK_CONSTANTS.AVALANCHE_CHAIN:
      path = 'set-details-avalanche';
      break;
    default:
      path = 'set-details';
      break;
  }

  const url = `https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/${path}/${setAddress}.setdetails.json`;

  try {
    dispatch(requestCustomSetDetails(true));

    const response = await axios.get(url, {
      transformRequest: (_: any, headers) => {
        delete headers['X-SET-USER'];
      },
    });

    const customSetDetails = response.data;

    dispatch(
      receiveCustomSetDetails({
        setAddress,
        customSetDetails,
      }),
    );

    return customSetDetails;
  } catch (error) {
    dispatch(requestCustomSetDetailsError(true));
  }
};

export const fetchEnabledModules = (setAddress: string, onFetchEnabledModules?: any) => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  dispatch(loadingModuleStatuses(true));
  const setJSInstance = setJSInstanceSelector(state);

  // Issuance Modules
  const basicIssuanceModuleAddress = basicIssuanceModuleAddressSelector(state);
  const debtIssuanceModuleV1Address = debtIssuanceModuleV1AddressSelector(state);
  const debtIssuanceModuleV2Address = debtIssuanceModuleV2AddressSelector(state);
  const perpIssuanceModuleAddress = perpIssuanceModuleAddressSelector(state);

  // Leverage Modules
  const perpV2BasisTradingModuleAddress = perpV2BasisTradingModuleAddressSelector(state);
  const perpV2LeverageModuleAddress = perpV2LeverageModuleAddressSelector(state);
  const compoundLeverageModuleAddress = compoundLeverageModuleAddressSelector(state);
  const aaveLeverageModuleAddress = aaveLeverageModuleAddressSelector(state);

  const caller = transactionCallerSelector(state);

  try {
    const enabledModulesPromise: Promise<string[]> = setJSInstance.setToken.getModulesAsync(
      setAddress,
      caller,
    );

    const enabledModules: string[] = await faultTolerantPromise<string[]>(enabledModulesPromise);

    const moduleStatuses = {
      setAddress,
      isBasicIssuanceEnabled: enabledModules.includes(basicIssuanceModuleAddress),
      isDebtIssuanceEnabled: enabledModules.includes(debtIssuanceModuleV1Address),
      isDebtIssuanceV2Enabled: enabledModules.includes(debtIssuanceModuleV2Address),
      isPerpIssuanceEnabled: enabledModules.includes(perpIssuanceModuleAddress),
      isCompoundLeverageEnabled: enabledModules.includes(compoundLeverageModuleAddress),
      isAaveLeverageEnabled: enabledModules.includes(aaveLeverageModuleAddress),
      isPerpV2LeverageEnabled: enabledModules.includes(perpV2LeverageModuleAddress),
      isPerpV2BasisTradingEnabled: enabledModules.includes(perpV2BasisTradingModuleAddress),
    };

    dispatch(receivedModuleStatuses(moduleStatuses));
    return moduleStatuses;
  } catch (e) {
    console.log('could not fetch enabled leverage modules: ', e);

    toast.warn('Sorry there was an issue with fetching the enabled modules for this Set.', {
      toastId: 'fetch-enabled-modules',
    });

    if (onFetchEnabledModules) {
      const moduleStatuses = await faultTolerantPromise(onFetchEnabledModules(setAddress));
      return moduleStatuses;
    } else {
      return {
        setAddress,
        isBasicIssuanceEnabled: false,
        isDebtIssuanceEnabled: false,
        isDebtIssuanceV2Enabled: false,
        isPerpIssuanceEnabled: false,
        isCompoundLeverageEnabled: false,
        isAaveLeverageEnabled: false,
        isPerpV2LeverageEnabled: false,
        isPerpV2BasisTradingEnabled: false,
      };
    }
  }
};

export const fetchDebtIssuanceV2Fees = (addressOverride?: string) => async (
  dispatch: any,
  getState: any,
) => {
  const state = getState();
  const web3Instance = await getWeb3Instance();
  const currentSetAddress = addressOverride || setDetailsCurrentSetAddressSelector(state);
  const debtIssuanceModuleV2Address = debtIssuanceModuleV2AddressSelector(state);

  if (!web3Instance) return;

  const debtIssuanceContract = new web3Instance.eth.Contract(
    debtIssuanceModuleABI as any,
    debtIssuanceModuleV2Address,
  );

  try {
    const feeSettings = await debtIssuanceContract.methods
      .issuanceSettings(addressOverride || currentSetAddress)
      .call();

    const issuanceRedemptionFees = {
      address: currentSetAddress,
      issuanceRedemptionFees: {
        issuanceFeePercentage: tokenFromBaseUnits(feeSettings?.managerIssueFee).mul(100).toString(),
        redemptionFeePercentage: tokenFromBaseUnits(feeSettings?.managerRedeemFee)
          .mul(100)
          .toString(),
      },
    };

    dispatch(receiveIssuanceRedemptionFees(issuanceRedemptionFees));
  } catch {}
};

export const agreeToExperimentalWarning = (setAddress: string) => async (dispatch: any) => {
  if (!setAddress) return;
  dispatch(addSetToConfirmedWarningList(setAddress));
};
